import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Nav, Tab, Container } from "react-bootstrap";
import ContactDetails from "./contactDetails";
import NotificationSettings from "./notificationSettings";
import Education from "./Education";
import _ from "lodash";
import { fetchUser, fetchEducation, updateContact } from "../../actions";
import { connect } from "react-redux";
import SavToast from '../../components/SavToast'

const HeaderDiv = styled.div`
  background-color: #3f3f3f;
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Gotham-Medium";
  padding: 40px;
`;

const linkStyle = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#fafaf9",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const linkStyleActive = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#dbd9d6",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const itemStyle = {
  marginBottom: 8
};

const UserPreferences = props => {
  const [key, setKey] = useState("contactDetails");
  const { user, education, fetchEducation, updateContact } = props;

  useEffect(() => {
    document.title = "Savant Community | User Preferences"
  },[]);

  useEffect(() => {
    if (_.isEmpty(education)) {
      fetchEducation();
    }
  }, [education]);

  if (user ) {
    return (
      <div>
        <HeaderDiv>MY SETTINGS</HeaderDiv>
        <SavToast/>
        <Container style={{maxWidth:800, marginTop:50}}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Row>
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item style={itemStyle}>
                    <Nav.Link
                      style={
                        key === "contactDetails" ? linkStyleActive : linkStyle
                      }
                      eventKey="contactDetails"
                    >
                      Details & Permissions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={itemStyle}>
                    <Nav.Link
                      style={key === "education" ? linkStyleActive : linkStyle}
                      eventKey="education"
                    >
                      Education
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item style={itemStyle}>
                    <Nav.Link
                      style={
                        key === "notifications" ? linkStyleActive : linkStyle
                      }
                      eventKey="notifications"
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="contactDetails">
                    <ContactDetails user={user} fetchContacts={updateContact} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="education">
                    <Education user={user} education={education} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notifications">
                    <NotificationSettings user={user} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    user: state.user.data,
    education: state.education.data,

  };
};

export default connect(mapStateToProps, {
  fetchUser,
  fetchEducation,
  updateContact
})(UserPreferences);

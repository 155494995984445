import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import {Link} from "react-router-dom";
import {FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex, FaCaretDown, FaMinus, FaEquals} from "react-icons/fa";
import {AiOutlineWarning} from 'react-icons/ai';
import {fetchInvoices, fetchCredits, fetchCreditCards, fetchBankAccounts, fetchPayments, fetchAccount, resolveOrder, createCreditCard, createPayment, fetchPayment, createPaymentPDF, updateContact, fetchUser, createFeedback} from "../../../actions";
import {parseCurrency} from "../../../components/Utilities"
import { SavRow, SavInput, ZeroStateText } from "../../../components/Styled";
import PaymentPDF from "./PaymentPDF";
import PaymentFeedbackModal from "./PaymentFeedbackModal";
import SavCheck from "../../../components/SavCheck";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import SavBanner from "../../../components/SavBanner";
import NewCreditCardModal from "../../../components/NewCreditCardModal";
import SavToast from "../../../components/SavToast";
import SavToastSuccess from "../../../components/SavToastSuccess";
import SavToastFail from "../../../components/SavToastFail";
import SavModalWarning from "../../../components/SavModalWarning";
import { createLoadingSelector, visibilitySelector } from "../../../selectors";

const InvoiceGridItem = styled.div`
  padding: 10px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Book";
  cursor: help;
`;
const GridItemContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 18px;
`;
const SavDropToggle = styled.div`
  background-color: #fff;
  padding: ${(props) => (props.style ? props.style.padding : "8px")}
  color: ${(props) => (props.style ? props.style.color : "#f9f9f9")};
  width: 100%;
  border: .5px solid #b4b4b4;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height:28px;
`;
const DropLabel = styled.div`
  font-size:12px;
  padding-bottom: 5px;
  color: #565551;
  font-family: Gotham-Medium;
  // text-decoration: underline;
`;
const hidden = {
  display: 'none'
}
const BottomButtons = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 30px 20px;
`
const ListHeaderDiv = styled.div`
  text-align:center;
  text-transform: uppercase;
  color: #fff;
  background-color: #3f3f3f;
  font-size: 14px;
  font-family: "Gotham-Medium";
  position: relative;
  padding: 15px 0px;
  margin: 0px -15px;
`;
const ListHeaderRow = styled(Row)`
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Book";
  margin-bottom: 20px;
`;
const SummaryItem = styled.div`
  padding: 4px;
  // border: 1px solid #b4b4b4;
  // border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;
const SummaryTotal = styled.div`
  padding: 12px;
  border-top: 1px solid #565551;
  width: 100%;
  text-center;
  font-size: 16px;
  font-family: "Gotham-Medium";
  @media (max-width: 767px){
    border-top: none;
  }
`;

const activePageStyle = {
  textDecoration: 'underline',
  color: '#ff5f00',
  fontFamily: "Gotham-Medium",
};
const CurrencyInput = styled.div`
  position: relative;
  span {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-size:12px;
  }
  input {
    padding-left:17px!important;
  }
`;

const MakeAPayment = ({user, account, creditCards, bankAccounts, orders, payments, invoices, credits, resolveOrder, errors, fetchCreditCards, fetchPayments, fetchCredits, fetchInvoices, fetchAccount, fetchBankAccounts, createPayment, loadingPayment, loadingPayments, loadingAccount, loadingCreditCards, loadingBank, loadingCredits, loadingInvoices, fetchPayment, createPaymentPDF, loadingPaymentPDF, updateContact, fetchUser, createFeedback}) => {
  const [step, setStep] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeCredits, setPageSizeCredits] = useState(10);
  const [currentPageCredits, setCurrentPageCredits] = useState(1);

  // Data
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceFilter, setInvoiceFilter] = useState([]);
  const [creditMemoList, setCreditMemoList] = useState([]);
  const [creditMemoFilter, setCreditMemoFilter] = useState([]);
  // Invoice Selection
  const [selected, setSelected] = useState({});
  const [selectedAllInvoices, setSelectedAllInvoices] = useState(false);
  const [selectedPastDue, setSelectedPastDue] = useState(false);
  // Credit Memo Selection
  const [selectedCredit, setSelectedCredit] = useState({});
  //Display Only
  const [totalDue, setTotalDue] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  //User Input
  const [activeCard, setActiveCard] = useState({});
  const [activeBank, setActiveBank] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("Select...");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [editPaymentAmount, setEditPaymentAmount] = useState(false);
  //Calculated
  const [totalCreditUsed, setTotalCreditUsed] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  //Error/Response Handling
  const [submitError, setSubmitError] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [inputOverWarning, setInputOverWarning] = useState(false);
  const [invoiceWarnings, setInvoiceWarnings] = useState([]);
  const [warningText, setWarningText] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [paymentPDFString, setPaymentPDFString] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const showBilling = useSelector(visibilitySelector('account_billing'));
  
  const pageOpts = [10, 25, 50, 100];

  useEffect(() => {
    if (step === 1){
      setActiveCard({});
      setActiveBank({});
      setSelected({});
      setSelectedCredit({});
      setSelectedAllInvoices(false);
      setSelectedPastDue(false);
      setTotalCreditUsed(0);
      setSelectedAmount(0);
      setPaymentAmount(0);
      setEditPaymentAmount(false);
      setPaymentMethod("Select...");
      setSubmitError(null);
      setPaymentResponse({});
      setInputOverWarning(false);
    }
  },[step]);

  useEffect(() => {
    if (_.isEmpty(creditCards) && !loadingCreditCards){
      fetchCreditCards();
    };
    if (_.isEmpty(credits) && !loadingCredits){
      fetchCredits();
    };
    if (_.isEmpty(invoices) && !loadingInvoices){
      fetchInvoices();
    };
    if (_.isEmpty(account) && !loadingAccount){
      fetchAccount();
    };
    if (_.isEmpty(payments)  && !loadingPayments){
      fetchPayments();
    };
    if (_.isEmpty(bankAccounts) && !loadingBank){
      fetchBankAccounts();
    }
  },[creditCards, bankAccounts, credits, invoices, payments, account]);

  // Sets Invoice list to those with canPay === true && not closed
  useEffect(() => {
    if (!_.isEmpty(invoices)) {
      const invoiceResults = invoices.data.filter(invoice =>invoice.age !== "Closed" && invoice.canPay);
      setInvoiceList(invoiceResults);
      const invoiceErrors = invoices.data.filter(invoice => !invoice.canPay);
      if (!_.isEmpty(invoiceErrors)){
        setInvoiceWarnings(invoiceErrors);
      }
    }
  },[invoices]);

  // Sets totalCredit and memo list to those with open balance > 0
  useEffect(() => {
    if (!_.isEmpty(credits)){
      const results = credits.data.filter(credit => credit.open > 0);
      setCreditMemoList(results);
      let count = 0;
      results.forEach((r) => {
        count += r.open;
      });
      setTotalCredit(count);
    }
  },[credits]);

  // Sets totalDue and chunks invoiceList into invoiceFilter
  useEffect(() => {
    if (invoiceList) {
      let index = 0;
      const temp = [];
      for (index = 0; index < invoiceList.length; index += pageSize) {
        let chunk = invoiceList.slice(index, index + pageSize);
        temp.push(chunk);
      }
      setInvoiceFilter(temp);
      let tempCount = 0;
      invoiceList.forEach((i) => {
        tempCount += i.balance;
      });
      setTotalDue(tempCount.toFixed(2));
    }
  }, [invoiceList, pageSize]);

  //Chunks creditMemoList into creditMemoFilter
  useEffect(() => {
    if (creditMemoList) {
      let index = 0;
      const temp = [];
      for (index = 0; index < creditMemoList.length; index += pageSizeCredits) {
        let chunk = creditMemoList.slice(index, index + pageSizeCredits);
        temp.push(chunk);
      }
      setCreditMemoFilter(temp);
    }
  }, [creditMemoList, pageSizeCredits]);

  useEffect(() => {
    if (!_.isEmpty(activeCard)){
      setPaymentMethod(activeCard.name);
    } 
  },[activeCard]);

  useEffect(() => {
    if (!_.isEmpty(activeBank)){
      setPaymentMethod(activeBank.name);
    } 
  },[activeBank]);

  useEffect(() => {
    if (selectedAllInvoices){
      setSelectedPastDue(false);
      const temp = {...selected};
      invoiceList.forEach((invoice) => {
        if (!temp[invoice.sfid]){
          temp[invoice.sfid] = invoice;
        }
      });
      setSelected(temp);
      setSelectedCredit({});
    }

    if (!selectedAllInvoices && !selectedPastDue){
      setSelected({});
      setSelectedCredit({});
    }
  },[selectedAllInvoices]);

  useEffect(() => {
    if (selectedPastDue){
      setSelectedAllInvoices(false);
      const temp = {};
      invoiceList.forEach((invoice) => {
        if (invoice.pastDue){
          temp[invoice.sfid] = invoice;
        }
      });
      setSelected(temp);
      setSelectedCredit({});
    }

    if (!selectedPastDue && !selectedAllInvoices){
      setSelected({});
      setSelectedCredit({});
    }
  },[selectedPastDue]);

  useEffect(() => {
    if (editPaymentAmount){
    }
  },[editPaymentAmount]);

  useEffect(() => {
    //Calculates SELECTED TOTAL
    let count = 0;
    Object.keys(selected).map((k, v) => {
      const item = selected[k];
      if (item){
        count = count + item.balance;
      }
    });
    setSelectedAmount(parseFloat(count).toFixed(2));
  },[selected]);

  useEffect(() => {
    // Calculates CREDIT USED
    let creditUsed = 0;
    Object.keys(selectedCredit).map((k, v) => {
      const item = selectedCredit[k];
      if (item){
        creditUsed += item.open;
      }
    });

    if (selectedAmount - creditUsed < 0){
      setTotalCreditUsed(parseFloat(selectedAmount).toFixed(2));
    } else {
      setTotalCreditUsed(parseFloat(creditUsed));
    }

  },[selectedCredit]);

  useEffect(() => {
    if ( (selectedAmount === 0 && totalCreditUsed === 0) || (selectedAmount - totalCreditUsed < 0) ){
      setPaymentAmount(0);
    } else {
      const total = selectedAmount - totalCreditUsed;
      const totalRounded = (Math.round(total * 100) / 100).toFixed(2);
      setPaymentAmount(totalRounded);
    }
  },[totalCreditUsed, selectedAmount]);
  
  useEffect(() => {
    if (!_.isEmpty(paymentPDFString) && !loadingPaymentPDF){
      const pdfJSON = {
        body: paymentPDFString,
        title: `Payment Receipt ${paymentResponse.name}`
      };
      createPaymentPDF(paymentResponse.sfid, pdfJSON);
    }
  },[paymentPDFString]);

  

  const handleCheckInvoice = (invoice) => {
    // HANDLE ADD
    if (!selected[invoice.sfid]){
      setSelected({...selected, [invoice.sfid] : invoice });
      setSelectedCredit({});
    }

    // HANDLE REMOVE
    if (selected[invoice.sfid]){
      if (selectedAmount - invoice.balance < totalCreditUsed){
        setSelectedCredit({});
      }
      const temp = {...selected};
      delete temp[invoice.sfid];
      setSelected(temp);
    }
    setInputOverWarning(false);

  };
  const handleCheckCredit = (credit) => {
    if (selectedAmount === '0.00'){
      return;
    }
    
    // HANDLE ADD
    if (!selectedCredit[credit.sfid]){

      if (totalCreditUsed === selectedAmount){
        return;
      }
      const tempCredit = {...credit};

      if (totalCreditUsed === 0 && credit.open > selectedAmount){
        tempCredit.tempApplied = selectedAmount;
        setSelectedCredit({...selectedCredit, [tempCredit.sfid] : tempCredit });
      }

      if (
        (totalCreditUsed === 0 && credit.open < selectedAmount) ||
        (totalCreditUsed !== 0 && (totalCreditUsed + credit.open) <= selectedAmount)
        ){
        tempCredit.tempApplied = credit.open;
        setSelectedCredit({...selectedCredit, [tempCredit.sfid] : tempCredit});
      }

      if (totalCreditUsed !== 0 && (totalCreditUsed + credit.open) > selectedAmount){
        const temp = {...credit};
        temp.tempApplied = parseFloat((selectedAmount - totalCreditUsed).toFixed(2));
        setSelectedCredit({...selectedCredit, [temp.sfid] : temp });
      }

    }
    // HANDLE REMOVE
    if (selectedCredit[credit.sfid]){

      if (totalCreditUsed === selectedAmount){

        const tempSelected = {...selectedCredit};
        Object.keys(tempSelected).forEach((t) => {
          const item = tempSelected[t];
          if (item.tempApplied < item.open){
            if (selectedCredit[credit.sfid].tempApplied <= totalCreditUsed - item.tempApplied){
              if (item.tempApplied + selectedCredit[credit.sfid].tempApplied <= item.open){
                item.tempApplied = item.tempApplied + selectedCredit[credit.sfid].tempApplied;
              } else {
                item.tempApplied = item.open;
              }
            }
          }
        });
        setSelectedCredit(tempSelected);
      }

      const temp = {...selectedCredit};
      delete temp[credit.sfid];
      setSelectedCredit(temp);
      setInputOverWarning(false);

    }
  };
  const handleDropClickBank = (bank) => {
    setActiveBank(bank);
    setActiveCard({});
  };
  const handleDropClickCard = (card) => {
    setActiveCard(card);
    setActiveBank({});
  };
  const handleDropClickNone = () => {
    setActiveCard({});
    setActiveBank({});
    setPaymentMethod("Select...")
  };
  const handleSubmitPayment = () => {
    if (paymentMethod === 'Select...' && totalCreditUsed === 0){
      setWarningText("You must select a payment method");
      setShowWarning(true);
      return;
    }
    if (paymentMethod === 'Select...' && parseFloat(paymentAmount) !== 0 && paymentAmount !== '' && totalCreditUsed !== 0){
      setWarningText("You must select a payment method");
      setShowWarning(true);
      return;
    }
    if (parseFloat(paymentAmount + totalCreditUsed) === 0 || parseFloat(paymentAmount +totalCreditUsed) < 0){
      setWarningText("Payment amount must be greater than $0.00");
      setShowWarning(true);
      return;
    }
    if (parseFloat(paymentAmount) > parseFloat(selectedAmount)){
      setWarningText(`Payment of ${parseCurrency(parseFloat(paymentAmount))} is greater than the selected invoice total of ${parseCurrency(parseFloat(selectedAmount))} `);
      setShowWarning(true);
      return;
    }
    if (!_.isEmpty(activeCard) && parseFloat(paymentAmount) > 100000){
      setWarningText("Credit card payments cannot be greater than $100,000. Please reduce the amount of invoices selected or contact the Savant Finance department.");
      setShowWarning(true);
      return;
    }
    if (!_.isEmpty(activeBank) && parseFloat(paymentAmount) > 300000){
      setWarningText("ACH payments cannot be greater than $300,000. Please reduce the amount of invoices selected or contact the Savant Finance department.");
      setShowWarning(true);
      return;
    }

    const paymentJSON = {
      invoices: [],
      paymentMethods: []
    };

    const invoiceList = [];
    const paymentList = [];

    if (!_.isEmpty(activeCard) && parseFloat(paymentAmount) > 0 ){
      const tempCard = {
        type: "Credit Card",
        amount: parseFloat(paymentAmount),
        sfid: activeCard.sfid,
      };
      paymentList.push(tempCard);
    }
    if (!_.isEmpty(activeBank) && parseFloat(paymentAmount) > 0 ){
      const tempBank = {
        type: "ACH",
        amount: parseFloat(paymentAmount),
        sfid: activeBank.sfid,
      };
      paymentList.push(tempBank);
    }

    Object.keys(selected).forEach((k,v) => {
      const billingItem = selected[k];
      if (billingItem.name){
        invoiceList.push(billingItem.sfid);
      }
    });

    Object.keys(selectedCredit).forEach((c) => {
      const creditItem = selectedCredit[c];
      const tempMemo = {
        type: "Credit Memo",
        amount: creditItem.tempApplied || creditItem.open,
        sfid: creditItem.sfid,
      };
      paymentList.push(tempMemo);
    });

    paymentJSON.invoices = invoiceList;
    paymentJSON.paymentMethods = paymentList;

    createPayment(paymentJSON).then((res) => {
      if (res.status === 'Complete'){
        if (!_.isEmpty(res.response.data.payload)){
          setPaymentResponse(res.response.data.payload);
          setStep(2);
          if (!user.contact.ignorePaymentMessage){
            setShowFeedbackModal(true);
          }
        }
      }
      if (res.status === 'Errored'){
        setSubmitError(`Error Processing Payment. Please contact the Savant Finance Department @ accountsreceivable@savant.com with reference #${res.response.data.payload.name}`);
        setStep(2);
      }
      if (res.status === 'Declined'){
        setSubmitError(`Error Processing Payment. The payment method was declined. Please click back and try again.`);
        setStep(2);
      }
      window.scrollTo(0, 0);
    });

  };
  const handleSetPaymentAmount = (value) => {
    if (value > (selectedAmount - totalCreditUsed)){
      setInputOverWarning(true);
    } else {
      setInputOverWarning(false);
    }
    if (value <= (selectedAmount - totalCreditUsed) ){
      var t = value;
      value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
      setPaymentAmount(value);
    }
    
  };
  const handleFocus = (e) => {
    e.target.select();
  };
  const getIcon = (type) => {
    switch (type) {
      case "Visa" :
        return <FaCcVisa size={30} />
      case "Mastercard" :
        return <FaCcMastercard size={30} />
      case "Discover" :
        return <FaCcDiscover size={30} />
      case "American Express" :
        return <FaCcAmex size={30} />
      default:
        return null;
    }
  };
  const renderBackButton = () => {
    if (step === 1){
      return (
        <Link className="hov oj med f12" to="/account?tab=invoices#billing">
          <button className="sav-btn__orange">Back</button>
        </Link>
      )
    }
    if (step === 2){
      return (
        <button className="sav-btn__orange" onClick={() => setStep(1)}>Back</button>
      )
    }
  };
  const renderCardOne = () => {
    if (!_.isEmpty(activeCard)){
      return (
        <SummaryItem>
          <div className="f12 book" style={{display:'flex', alignItems:'center', justifyContent: 'center', width: '100%'}}>
            {getIcon(activeCard.type)}
            <div className="book f12 m-l-5">ending in {activeCard.lastfour}</div>
          </div>
        </SummaryItem>
      )
    }
    if (!_.isEmpty(activeBank)){
      return (
        <SummaryItem>
          <div className="f12 book" style={{display:'flex', alignItems:'center', justifyContent: 'center', width: '100%'}}>
            {activeBank.name} {activeBank.type}
            <div className="book f12 m-l-5">ending in {activeBank.accountLast4}</div>
          </div>
        </SummaryItem>
      )
    }
    return (
      <div className='text-center book f12 light-gray m-b-10' style={{padding: 4}}>
        No Payment Method Chosen
      </div>
    );
  };
  const renderCredits = () => {
    
    if (!_.isEmpty(creditMemoFilter)){
      let page = currentPageCredits - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > creditMemoFilter.length) {
        page = 0;
        setCurrentPageCredits(1);
      }
      return creditMemoFilter[page].map((memo) => {
        return (
            <SavRow onClick={() => handleCheckCredit(memo)} key={memo.sfid} className="vertical-align" style={selected[memo.sfid] ? {padding: '10px 0px', margin: '3px 0px', border: "1px solid #4cd964", cursor: 'pointer'} : {padding: '10px 0px', margin: '3px 0px', cursor: 'pointer'}}>
              <Col xs={4} md={4} className="savFlexAround truncate">
                <span data-tip={selectedAmount === 0 ? 'You must first select 1 or more invoices to apply this credit to' : ''}>
                  <SavCheck checked={selectedCredit[memo.sfid]} click={() => handleCheckCredit(memo)} bg="#4cd964" />
                </span>
                <div className="truncate">{memo.creditMemoNumber}</div>
              </Col>
              {/* <Col md={3} className="d-none d-md-block truncate">{memo.orderNumber || "N/A"}</Col> */}
              {/* <Col md={2} className="d-none d-md-block truncate">{memo.creditMemoType}</Col> */}
              <Col xs={4} md={4} className="truncate">{memo.creditDate}</Col>
              <Col xs={4} md={4} className="truncate green med">({parseCurrency(memo.open)})</Col>
              {/* <Col xs={3} md={2} className="truncate">({parseCurrency(Math.abs(memo.total))})</Col> */}
            </SavRow>
        )
      })
    }
    return (
      <ZeroStateText>You have no Credit Memos available.</ZeroStateText>
    )

  };
  const renderInvoices = () => {
    const renderRMALabel = (invoice) => {
      if (invoice.orderType === 'RMA'){
        return (
          <span className='med f10 m-l-5 oj'>RMA</span>
        )
      }
      return null;
    }
    if (!_.isEmpty(invoiceFilter)){
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page >= invoiceFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return invoiceFilter[page].map(invoice => {
        return (
          <div key={invoice.sfid}>
            <SavRow onClick={() => handleCheckInvoice(invoice)} key={invoice.sfid} className="vertical-align" style={selected[invoice.sfid] ? {padding: '10px 0px', margin: '3px 0px', border: "1px solid #ff5f00", cursor: 'pointer'} : {padding: '10px 0px', margin: '3px 0px', cursor: 'pointer'}}>
              <Col xs={4} md={3} className="savFlexAround truncate">
                <SavCheck checked={selected[invoice.sfid]} click={() => handleCheckInvoice(invoice)} />
                <div className="truncate">{invoice.name}</div>
              </Col>
              <Col md={3} className="d-none d-md-block truncate">
                {invoice.orderNumber} 
                {renderRMALabel(invoice)}
              </Col>
              {/* <Col md={2} className="d-none d-md-block truncate">{invoice.billingDate}</Col> */}
              <Col xs={4} md={3} className={invoice.pastDue ? "red med truncate" : "truncate"}>{invoice.dueDate}</Col>
              <Col xs={4} md={3} className={invoice.pastDue ? "red med" : ""}>{parseCurrency(invoice.balance,2)}</Col>
              {/* <Col xs={3} md={2}>{parseCurrency(invoice.total,2)}</Col> */}
            </SavRow>
          </div>
        )
      });
    }
    return (
      <ZeroStateText>You have no Invoices to pay.</ZeroStateText>
    )
  };
  const renderSubmitError = () => {
    if (submitError){
      return <ZeroStateText className="red f12">{submitError}</ZeroStateText>
    }
    return null;
  };
  const renderPDF = () => {
    if (step === 2 && !_.isEmpty(paymentResponse) && !_.isEmpty(account) && !_.isEmpty(invoices) && !_.isEmpty(creditCards) && !_.isEmpty(bankAccounts) && !_.isEmpty(credits)){
      return (
        <PDFDownloadLink
          className="med f12 oj"
          document={<PaymentPDF paymentResponse={paymentResponse} account={account} invoices={invoices.data} creditCards={creditCards.data} bankAccounts={bankAccounts.data} credits={credits.data} />}
          fileName={`Savant_Payment_Receipt_${paymentResponse.name}`}
        >
          {({ blob, url, loading, error }) => {
            if (blob && !loading){
              var base64String;
              var reader = new FileReader(); 
              reader.readAsDataURL(blob); 
              reader.onloadend = function () { 
                base64String = reader.result; 
                const text = base64String.substr(base64String.indexOf(',') + 1);
                setPaymentPDFString(text)
              } 

            }
            return loading ? 'Generating...' : paymentResponse.name
          }}
        </PDFDownloadLink>
      )
    }
    return null;
  };
  const renderPDFTest = () => {
    if (step === 2 && !_.isEmpty(paymentResponse) && !_.isEmpty(account) && !_.isEmpty(invoices) && !_.isEmpty(creditCards) && !_.isEmpty(bankAccounts) && !_.isEmpty(credits)){
      return (
        <PDFViewer width="100%" height={800} showToolbar>
          <PaymentPDF paymentResponse={paymentResponse} account={account} invoices={invoices.data} creditCards={creditCards.data} bankAccounts={bankAccounts.data} credits={credits.data} />
        </PDFViewer>

      )
    }
  };
  const renderInvoiceWarning = () => {
    if (!_.isEmpty(invoiceWarnings)){
      return(
        <div className="savFlexAround">
          <AiOutlineWarning fill="#ff5f00" />
          <div className="book f12 light-gray">
            {invoiceWarnings.length} of your invoice(s) have a payment with an error. Click <Link to={`/account?tab=history#billing`} className="med f12 oj">HERE</Link> to resolve.
          </div>
        </div>
      )
    }
    return null;
  };
  const renderInputWarning = () => {
    if (inputOverWarning){
      return (
        <div className="book f12 red">Must be less than {parseCurrency(selectedAmount - totalCreditUsed)}.</div>
      )
    }
    return null;
  };

  const renderStepOne = () => {
    if (step === 1 && !_.isEmpty(account) && !_.isEmpty(creditCards) &&!_.isEmpty(bankAccounts)){
      return (
        <Fragment>
          <SavModalWarning
            show={showWarning}
            hide={() => setShowWarning(false)}
            center
            title="Warning"
            text={warningText}
          />
          <Container>
            
            <ReactTooltip className="tooltip-issuetype" effect="solid" place="bottom" />
            <Row style={{border: '1px solid #d8d8d8'}}>
              <Col md={6} style={{paddingBottom: 60}}>
                <ListHeaderDiv>
                  1. Select Invoices To Pay
                </ListHeaderDiv>

                <ListHeaderRow>
                  <Col sm={4} data-tip="Total balance of invoices selected">
                    <InvoiceGridItem>
                      <div className="f14">
                        Selected
                      </div>
                      <GridItemContent className="truncate">
                        {parseCurrency(selectedAmount)}
                      </GridItemContent>
                    </InvoiceGridItem>
                  </Col>
                  <Col sm={4}>
                  <InvoiceGridItem data-tip="Total balance of open invoices that are past due">
                    <div className="f14">
                      Past Due
                    </div>
                    <GridItemContent className="red truncate">
                      {parseCurrency(account.pastDue)}
                    </GridItemContent>
                  </InvoiceGridItem>
                  </Col>
                  <Col sm={4}>
                  <InvoiceGridItem data-tip="Total balance of all open invoices">
                    <div className="f14">
                      Total Due
                    </div>
                    <GridItemContent className="truncate">
                      {parseCurrency(totalDue)}
                    </GridItemContent>
                  </InvoiceGridItem>
                  </Col>
                </ListHeaderRow>
                <div className="f12 book m-b-10">
                  Select
                  <span className="onHov book u f12 m-lr-5" style={selectedAllInvoices ? activePageStyle : null} onClick={() => setSelectedAllInvoices(!selectedAllInvoices)}>All</span>
                  <span className="onHov book u f12 m-lr-5" style={selectedPastDue ? activePageStyle : null} onClick={() => setSelectedPastDue(!selectedPastDue)}>Past Due</span>
                </div>

                {renderInvoiceWarning()}
                
                <SavColumnSort
                  data={invoiceList}
                  setData={setInvoiceList}
                  columns={[
                    { text: "Invoice", size: 3, sizeXS: 4, value: "name" },
                    { text: "Order", size: 3, hideMobile: true, value: "orderNumber" },
                    { text: "Due Date", size: 3, sizeXS: 4, value: "dueDate"},
                    { text: "Balance", size: 3, sizeXS: 4, value: "balance"},
                  ]}
                />
                
                {renderInvoices()}
                <Row className="vertical-align" style={{position:'absolute', height: 60, bottom: 0, width: '100%'}}>
                  <Col xs={12} className="savFlexBetweenCenter">
                    <div className="f12 book m-t-5">
                      Show
                      <span className="onHov book u f12 m-lr-5" style={pageSize === 10 ? activePageStyle : null} onClick={() => setPageSize(10)}>10</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSize === 25 ? activePageStyle : null} onClick={() => setPageSize(25)}>25</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSize === 50 ? activePageStyle : null} onClick={() => setPageSize(50)}>50</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSize === 100 ? activePageStyle : null} onClick={() => setPageSize(100)}>100</span>
                    </div>
                    <SavPagination
                      data={invoiceFilter}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      dataLength={invoiceList.length}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={6} style={{borderLeft: '1px solid #d8d8d8', paddingBottom: 60}}>
                <ListHeaderDiv>
                  2. Apply Credit Memos
                </ListHeaderDiv>
                <ListHeaderRow style={{marginBottom: 48}}>
                  <Col xs={6}>
                    <InvoiceGridItem data-tip="Total balance of all credit memos">
                      <div className="f14">
                        Open Credit
                      </div>
                      <GridItemContent className="green">
                        {parseCurrency(totalCredit)}
                      </GridItemContent>
                    </InvoiceGridItem>
                  </Col>
                  <Col xs={6}>
                    <InvoiceGridItem data-tip="Total balance of all credit memos selected to apply to this payment">
                      <div className="f14">
                        Credit Used
                      </div>
                      <GridItemContent className="green">
                        {parseCurrency(totalCreditUsed)}
                      </GridItemContent>
                    </InvoiceGridItem>
                  </Col>
                </ListHeaderRow>
                <SavColumnSort
                  data={creditMemoList}
                  setData={setCreditMemoList}
                  columns={[
                    { text: "Memo", size: 4, sizeXS: 4, value: "creditMemoNumber" },
                    { text: "Issued", size: 4, sizeXS: 4, value: "creditDate"},
                    { text: "Balance", size: 4, sizeXS: 4, value: "open"},
                  ]}
                />
                {renderCredits()}
                <Row style={{position:'absolute', height: 60, bottom: 0, width: '100%', }}>
                  <Col xs={12} className="savFlexBetweenCenter">
                    <div className="f12 book m-t-5">
                      Show
                      <span className="onHov book u f12 m-lr-5" style={pageSizeCredits === 10 ? activePageStyle : null} onClick={() => setPageSizeCredits(10)}>10</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSizeCredits === 25 ? activePageStyle : null} onClick={() => setPageSizeCredits(25)}>25</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSizeCredits === 50 ? activePageStyle : null} onClick={() => setPageSizeCredits(50)}>50</span>
                      <span className="onHov book u f12 m-lr-5" style={pageSizeCredits === 100 ? activePageStyle : null} onClick={() => setPageSizeCredits(100)}>100</span>
                    </div>
                    <SavPagination
                      data={creditMemoFilter}
                      pageSize={pageSizeCredits}
                      currentPage={currentPageCredits}
                      setCurrentPage={setCurrentPageCredits}
                      dataLength={creditMemoList.length}
                    />
                  </Col>
                </Row>
                

              </Col>
            </Row>

            <Row style={{border: '1px solid #d8d8d8'}}>

              <Col md={12}>
                <ListHeaderDiv>
                  3. Select Payment Method
                </ListHeaderDiv>

                <Row style={{alignItems: 'end'}} className="m-b-20 m-t-20">
                  <Col sm={4}>
                    <DropLabel>Method</DropLabel>
                    <Dropdown className="savDrop">
                      <Dropdown.Toggle id="dropdown-basic" as="div">
                        <SavDropToggle>
                          <span>{paymentMethod}</span>
                          <FaCaretDown />
                        </SavDropToggle>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "100%" }}>
                        <Dropdown.Item
                          as="div"
                          href="#"
                          onClick={() => handleDropClickNone()}
                          className="onHov"
                          style={{ borderBottom: "1px solid #DBD9D6", width: "100%" }}
                        >
                          <span style={{ display: "inline-flex" }}>None</span>
                        </Dropdown.Item>
                        {creditCards.data.map((o) => {
                          return (
                            <Dropdown.Item
                              as="div"
                              href="#"
                              key={o.sfid}
                              name={o.name}
                              onClick={() => handleDropClickCard(o)}
                              className="onHov"
                              style={{ borderBottom: "1px solid #DBD9D6", width: "100%" }}
                            >
                              <span style={{ display: "inline-flex" }}>Credit Card - {o.type} ({o.lastfour})</span>
                            </Dropdown.Item>
                          );
                        })}
                        {bankAccounts.data.map((b) => {
                          return (
                            <Dropdown.Item
                              as="div"
                              href="#"
                              key={b.sfid}
                              onClick={() => handleDropClickBank(b)}
                              className="onHov"
                              style={{ borderBottom: "1px solid #DBD9D6", width: "100%" }}
                            >
                              <span style={{ display: "inline-flex" }}>ACH - {b.name || b.bankName} ({b.accountLast4})</span>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col sm={2}>
                    <NewCreditCardModal buttonText="+ Add Payment Method" buttonClass="sav-btn__orange--text" buttonStyle={{width: '100%'}} />
                  </Col>
                  <Col sm={4}>
                    <DropLabel>Amount</DropLabel>
                    {renderInputWarning()}
                    <CurrencyInput>
                      <span>$</span>
                      <SavInput autoFocus disabled={!editPaymentAmount} style={{padding: 8}} value={paymentAmount} onChange={(e) => handleSetPaymentAmount(e.target.value)} onClick={handleFocus} type="number" pattern='d\+\.\d\d$' />
                    </CurrencyInput>
                    
                  </Col>
                  <Col sm={2}>
                    <button className="sav-btn__orange--text" style={editPaymentAmount ? hidden : {width: '100%'}} onClick={() => setEditPaymentAmount(!editPaymentAmount)}>
                      Custom Amount
                    </button>
                    <button className="sav-btn__orange--fill" style={editPaymentAmount ? {width: '100%'} : hidden} onClick={() => setEditPaymentAmount(!editPaymentAmount)}>
                      Save
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{border: '1px solid #d8d8d8', paddingBottom: '20px', borderTop:'none'}}>
              <Col xs={12}>
                <ListHeaderDiv className="m-b-20">
                  4. Summary
                </ListHeaderDiv>
              </Col>
              <Col md={3} className="text-center">
                <DropLabel>Invoices To Pay</DropLabel>
                {Object.keys(selected).map((k, v) => {
                  const item = selected[k];
                  if (item.balance){
                    return (
                      <SummaryItem key={item.sfid}>
                        <div className="med f12">{item.name}</div>
                        <div className="red f12 med">{parseCurrency(item.balance)}</div>
                      </SummaryItem>
                    )
                  }
                })}
                <div className='text-center book f12 light-gray' style={selectedAmount !== 0 ? hidden : {padding: 4}}>
                  No Invoices Selected
                </div>
                
              </Col>
              <Col md={1} className="text-center" style={{alignSelf: 'center'}}>
                <FaMinus />
              </Col>
              <Col md={3} className="text-center" style={{padding: 0}}>
                <DropLabel>Credit Memos Applied</DropLabel>
                  {Object.keys(selectedCredit).map((k, v) => {
                    const item = selectedCredit[k];
                    if (item.open){
                      return (
                        <SummaryItem key={item.sfid}>
                          <div className="med f12">
                          {item.creditMemoNumber}
                        </div>
                        <div className="f12">
                          <span className="med green f12">
                            {parseCurrency(item.tempApplied || item.open)}
                          </span>
                          &nbsp;of &nbsp;
                          <span className="med green f12">
                            {parseCurrency(item.open)}
                          </span>
                        </div>
                        </SummaryItem>
                      )
                    }
                  })}
                  <div className='text-center book f12 light-gray' style={totalCreditUsed !== 0 ? hidden : {padding: 4}}>
                    No Credit Memos Applied
                  </div>
              </Col>
              <Col md={1} className="text-center" style={{alignSelf: 'center'}}>
                <FaEquals />
              </Col>

              <Col md={4} className="text-center">
                <div style={{display:'grid', minHeight: 100, position: 'relative'}}>
                  <DropLabel style={{padding: 0, width: '100%', position:'absolute'}}>Selected Balance Due</DropLabel>
                  <div className="med f32 text-center" style={{alignSelf: 'center', width: '100%'}}>
                    {parseCurrency(selectedAmount - totalCreditUsed)}
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={3} className="text-center">
                <SummaryTotal >
                  {parseCurrency(selectedAmount)}
                </SummaryTotal>
              </Col>
              <Col xs={12} sm={1} className="text-center" style={{alignSelf: 'center'}}>
              </Col>
              <Col xs={12} sm={3} className="text-center">
                <SummaryTotal>
                  {parseCurrency(totalCreditUsed)}
                </SummaryTotal>
              </Col>
              <Col xs={12} sm={1} className="text-center" style={{alignSelf: 'center'}}>
              </Col>
              <Col xs={12} sm={4} className="text-center">
                <DropLabel>Payment Method</DropLabel>
                {renderCardOne()}
                <DropLabel style={{padding: 0}} className="m-t-20">Amount to be Charged</DropLabel>
                <div className="med f32 text-center m-b-20">{parseCurrency(paymentAmount || 0)}</div>
                
              </Col>
              <Col xs={12} className="text-center">
              </Col>
            </Row>
            

          </Container>
          {renderSubmitError()}
          <div className="text-center book f12 light-gray m-t-20 m-b-20">
            By clicking "Process Payment" below you are authorizing Savant to charge your bank account/credit card.
          </div>
          <BottomButtons>
            <button
              className="sav-btn__orange--fill"
              disabled={selectedAmount === 0 ? true : false}
              onClick={() => handleSubmitPayment()}
            >
              Process Payment
            </button>
          </BottomButtons>
        </Fragment>
      )
    }
    return null;
  };
  const renderStepTwo = () => {

    if (step === 2 && !submitError){
      return (
        <Fragment>
          <div className="text-center m-20">
            <div className="med f20">
              THANK YOU FOR YOUR PAYMENT!
            </div>
            <div className="book f14">
              Your payment confirmation is {renderPDF()}
            </div>
          </div>
          {renderPDFTest()}
          <BottomButtons>
            <button
              className="sav-btn__orange m-1"
              onClick={() => setStep(1)}
              style={{width: 160}}
            >
              Make another Payment
            </button>
            <Link className="hov oj med f12" to="/account?tab=invoices#billing">
              <button className="sav-btn__gray m-1">Done</button>
            </Link>
            <Link className="hov oj med f12" to="/account?tab=history#billing">
              <button className="sav-btn__gray--fill m-1">Payment History</button>
            </Link>
          </BottomButtons>
        </Fragment>
      )
    }
    if (step === 2 && submitError){
      return (
        <Fragment>
          {renderSubmitError()}
        </Fragment>
      )
    }
    return null;
  };
  const renderLoader = () => {
    if (loadingPayment) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
          <div className="loaderText" style={{position: 'absolute', width: 'inherit', top: '15%'}}>Submitting your payment. Please do not click back or refresh the page and allow up to 60 seconds for payment processing to complete.</div>
        </div>
      );
    }
  };

  if (showBilling){
    return (
      <div>
        <PaymentFeedbackModal show={showFeedbackModal} hide={() => setShowFeedbackModal(false)} user={user} updateContact={updateContact} fetchUser={fetchUser} createFeedback={createFeedback} />
        {renderLoader()}
        <SavBanner text="Make a Payment" padding="40px" />
        <SavToast />
        <SavToastFail />
        <SavToastSuccess />
        <Container style={{minHeight: 800}}>
          <div style={{margin: '30px 0px'}}>
            {renderBackButton()}
          </div>
          {renderStepOne()}
          {renderStepTwo()}
          
        </Container>
      </div>
    )
  } else {
    return (
      <ZeroStateText margin="200px">
        You do not have permission to view this page.
        <div className="text-center m-t-20">
          <Link to="/">
            <button className="sav-btn__orange">Return Home</button>
          </Link>
        </div>
      </ZeroStateText>
    )
  }

  
}

const submitSelector = createLoadingSelector(["CREATE_PAYMENT"]);
const loadingAccount = createLoadingSelector(["FETCH_ACCOUNT"]);
const loadingInvoices = createLoadingSelector(["FETCH_INVOICES"]);
const loadingCredits = createLoadingSelector(["FETCH_CREDITS"]);
const loadingOrders = createLoadingSelector(["FETCH_ORDERS"]);
const loadingPayments = createLoadingSelector(["FETCH_PAYMENTS"]);
const loadingBank = createLoadingSelector(["FETCH_BANK"]);
const loadingCreditCards = createLoadingSelector(["FETCH_CREDIT_CARDS"]);
const loadingPaymentPDF = createLoadingSelector(["CREATE_PAYMENT_PDF"]);

const mapStateToProps = state => {
  return {
    user: state.user.data,
    errors: state.errors,
    account: state.account.data,
    invoices: state.invoices,
    credits: state.credits,
    orders: state.orders.data,
    payments: state.payments,
    bankAccounts: state.bankAccounts,
    creditCards: state.creditCards,
    loadingPayment: submitSelector(state),
    loadingAccount: loadingAccount(state),
    loadingInvoices: loadingInvoices(state),
    loadingCredits: loadingCredits(state),
    loadingOrders: loadingOrders(state),
    loadingPayments: loadingPayments(state),
    loadingBank: loadingBank(state),
    loadingCreditCards: loadingCreditCards(state),
    loadingPaymentPDF: loadingPaymentPDF(state),
  };
};
export default connect(mapStateToProps, {fetchCreditCards, fetchBankAccounts, fetchPayments, fetchCredits, fetchInvoices, fetchAccount, resolveOrder, createCreditCard, createPayment, fetchPayment, createPaymentPDF, updateContact, fetchUser, createFeedback})(MakeAPayment);
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import _ from "lodash";
import SavDatePicker from "../../../components/SavDatePicker";
import {
  SubHeader,
  SavSearch,
  SavRow,
  SavLabel
} from "../../../components/Styled";
import SavPagination from "../../../components/SavPagination";
import SavDrop from "../../../components/SavDrop";
import SavColumnSort from "../../../components/SavColumnSort";
import {eliteSearchAlgorithm} from "../../../components/Utilities";

const GridItem = styled.div`
  min-height: 400px;
  background-color: #fafaf9;
`;

const InvoiceGridItem = styled.div`
  min-height: 175px;
  padding: 15px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Medium";
`;
const GridItemContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 24px;
`;

const pageOpts = [10, 25, 50, 100];
const FilterOpts=['All', 'Advanced Replacements'];

const Orders = ({orders}) => {

  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [ordersList, setOrdersList] = useState([]);
  const [ordersFilter, setOrdersFilter] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetChar, setAlphabetChar] = useState("All");
  const [activeFilter, setActiveFilter] = useState("All");

  const startDateDefault = new Date(2012, 12, 1);
  const endDateDefault = new Date();
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);

  

  useEffect(() => {
    document.title = "Savant Community | Orders"
  },[])

  useEffect(() => {
    if (orders){
      setOrdersList(orders.orders);
    }
  }, [orders]);

  //Pagination
  useEffect(() => {
    if (ordersList) {
      let index = 0;
      const tempOrders = [];
      for (index = 0; index < ordersList.length; index += ordersPerPage) {
        let chunk = ordersList.slice(index, index + ordersPerPage);
        tempOrders.push(chunk);
      }
      setOrdersFilter(tempOrders);
    }
  }, [ordersList, ordersPerPage]);

  // //date stuff
  useEffect(() => {
    if (!_.isEmpty(orders)) {
      const results = orders.orders.filter(
        order =>
          Date.parse(order.orderDate) >= startDate &&
          Date.parse(order.orderDate) <= endDate
      );
      setOrdersList(results);
    }
  }, [orders, startDate, endDate]);

  // Searching
  useEffect(() => {
    if (!_.isEmpty(orders)) {
      setCurrentPage(1);
      let tempOrders = orders.orders.filter(
        order => eliteSearchAlgorithm(searchTerm, order)
      );
      setOrdersList(tempOrders);
      if (searchTerm === "") {
        setOrdersList(orders.orders);
      }
    }
    
  }, [searchTerm]);

  // Alphabet sorting
  useEffect(() => {
    if (!_.isEmpty(orders)) {
      if (alphabetChar !== "All") {
        setCurrentPage(1);
        let tempOrders = orders.orders.filter(
          order =>
            order.orderStatus.startsWith(alphabetChar) ||
            order.orderStatus.startsWith(alphabetChar.toUpperCase()) ||
            (order.customerPO !== null && order.customerPO.startsWith(alphabetChar)) ||
            (order.customerPO !== null && order.customerPO.startsWith(alphabetChar.toUpperCase()))
        );
        setOrdersList(tempOrders);
      } else {
        setOrdersList(orders.orders);
      }
    }
  }, [alphabetChar]);

  useEffect(() => {
    if (!_.isEmpty(orders)) {
      if (activeFilter !== "All") {
        setCurrentPage(1);
        if (activeFilter === "Advanced Replacements") {
          let tempOrders = orders.orders.filter(
            order => order.rmaNumber !== null
          );
          setOrdersList(tempOrders);
        }
      } else {
        setOrdersList(orders.orders);
      }
    }
  }, [activeFilter, orders]);

  // Reset option
  const handleClearFilters = () => {
    setAlphabetChar("All");
    setSearchTerm("");
    setStartDate(startDateDefault);
    setEndDate(endDateDefault);
  };

  const renderHeaders = () => {
    if (!_.isEmpty(orders)) {

      const renderShipment = () => {
        if (orders.mostRecentShipment){
          return (
            <a href={orders.mostRecentShipment.url} className="oj linkHover" target="_blank" rel="noopener noreferrer">{orders.mostRecentShipment.trackingNumber}</a>
          )
        }
        return null
      }
      return (
        <Row>
          <Col sm={4} style={{padding:"15px"}}>
            <InvoiceGridItem>
              Orders Needing Attention
              <div style={{ paddingTop: "40px" }}>
                <GridItemContent>{orders.countNeedsReview}</GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
          <Col sm={4} style={{padding:"15px"}}>
            <InvoiceGridItem>
              Orders Pending Shipment
              <div style={{ paddingTop: "40px" }}>
                <GridItemContent>{orders.countPending}</GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
          <Col sm={4} style={{padding:"15px"}}>
            <InvoiceGridItem >
              Most Recent Shipment
              <div style={{ paddingTop: "40px" }}>
                <GridItemContent>
                  {renderShipment()}
                </GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderOrders = () => {
    if (!_.isEmpty(ordersFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > ordersFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return ordersFilter[page].map(order => {
        return (
          <SavRow key={order.sfid}>
              <Col xs={4} sm={2}>
                <Link className="f12 oj u linkHover truncate" to={`/orders/${order.sfid}`}>{order.orderNumber}</Link>
              </Col>
              <Col xs={4} sm={2}>
                <span className="f12 black"> {order.customerPO} </span>
              </Col>
              <Col sm={2} className="d-none d-md-block">
                <span className="f12 black"> {order.orderDate} </span>
              </Col>
              <Col xs={4} sm={2}>
                <span className="f12 black"> {order.orderStatus} </span>
              </Col>
              <Col sm={2}>
                <span className="f12 black"> ${order.orderTotal} </span>
              </Col>
              <Col sm={2} className="d-none d-md-block">
                <span className="f12 black">{order.billingTerms}</span>
              </Col>
            </SavRow>
        )
      })
    }
    if (ordersList) {
      return (
        <Row>
          <Col
            xs={12}
            style={
              ordersList.length > 0
                ? { display: "none" }
                : { textAlign: "center", marginTop: 30 }
            }
          >
            No Results found.
            <br />
            <br />
            <button
              className="sav-btn__orange--text"
              onClick={() => handleClearFilters()}
            >
              Clear Filters
            </button>
          </Col>
        </Row>
      );
    }
  };

  if (ordersFilter && ordersList) {
    return (
      <div>
        <SubHeader>Orders</SubHeader>
        <Container>
          {renderHeaders()}
          <Row style={{ marginTop: 30, marginBottom: 30 }}>
            <Col sm={12} style={{ paddingBottom: 20 }}>
              <GridItem
                style={{ minHeight: 700, backgroundColor: "transparent" }}
              >
                <Row style={{ alignItems: "flex-end" }}>
                  <Col sm={6}>
                    <SavSearch
                      style={{ padding: "0px" }}
                      type="search"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Col>
                  <Col sm={6} className="d-none d-md-block">
                    <SavDatePicker
                      clickStart={setStartDate}
                      clickEnd={setEndDate}
                      start={startDate}
                      end={endDate}
                      style={{ float: "right" }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }} className="vertical-align">
                  <Col sm={2} className="d-none d-md-block">
                    <SavLabel>Page Size</SavLabel>
                    <SavDrop
                      opts={pageOpts}
                      val={ordersPerPage}
                      click={setOrdersPerPage}
                    />
                  </Col>
                  <Col sm={10} className="d-flex justify-content-lg-end justify-content-center">
                    <div style={{float:"right", display:'flex', alignItems: "center", justifyContent:'space-between', width:250}}>
                      <span className="light-gray f12 med">FILTER</span>
                      <SavDrop
                        opts={FilterOpts}
                        val={activeFilter}
                        click={setActiveFilter}
                        style={{
                          border: "1px solid #b4b4b4",
                          backgroundColor: "#fff",
                          float:"right",
                          width:200
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <hr style={{ color: "#D8D8D8" }} />
                <div style={{ textAlign: "center", padding:"10px 20px" }}>
                  <SavColumnSort
                    data={ordersList}
                    setData={setOrdersList}
                    columns={[
                      { text: "Order Number", textXS: "Order", size: 2, sizeXS: 4, value: "orderNumber" },
                      { text: "Purchase Order", textXS: "P.O.", size: 2, sizeXS: 4, value: "customerPO" },
                      { text: "Order Date", size: 2, value: "orderDate", hideMobile: true },
                      { text: "Order Status", textXS: "Status", size: 2, sizeXS: 4, value: "orderStatus"},
                      { text: "Order Total", size: 2, value: "orderTotal", hideMobile: true },
                      { text: "Payment Method", size: 2, value: "billingTerms", hideMobile: true }
                    ]}
                  />
                  {renderOrders()}
                </div>
                <SavPagination
                  data={ordersFilter}
                  pageSize={ordersPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  dataLength={ordersList.length}
                />
              </GridItem>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return null;
};

export default Orders;




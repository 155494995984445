import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal, Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { FaCaretDown } from "react-icons/fa";
import {resolveOrder, createCreditCard, setSuccessToast} from "../../../actions";
import SavCheck from "../../../components/SavCheck";
import SavDropFilter from "../../../components/SavDropFilter";
import {SavTable2} from "../../../components/Styled";
import {parseCurrency} from "../../../components/Utilities";
import Countries from "../../../components/Utilities/countries";

const SavDropToggle = styled.div`
  background-color: ${(props) =>
    props.style ? props.style.backgroundColor : "#f9f9f9"};
  padding: ${(props) => (props.style ? props.style.padding : "8px")}
  color: ${(props) => (props.style ? props.style.color : "#f9f9f9")};
  width: 200px;
  border: ${(props) => (props.style ? props.style.border : "none")}
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height:28px;
  padding:5px;
`;

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});
const SavDropFilterForm = React.forwardRef((props, ref) => {
  return (
    <SavDropFilter val={props.val} opts={props.opts} click={props.click} clickKey="code" />
  )
});

const UpdatePaymentModal = ({order, creditCards, resolveOrder, createCreditCard, errors}) => {
  const [show, setShow] = useState(false);
  const [activeCard, setActiveCard] = useState({});
  const [showNewCard, setShowNewCard] = useState(false);
  const [validated, setValidated] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [ccType, setCcType] = useState("Visa");
  const [ccCode, setCcCode] = useState("");
  const [expMonth, setExpMonth] = useState("1");
  const [expYear, setExpYear] = useState("2021");
  const [cardError, setCardError] = useState("");
  const [addressStreet, setAddressStreet] = useState('');
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressCountry, setAddressCountry] = useState('US');
  const [addressContactLast, setAddressContactLast] = useState("");
  const [addressContactFirst, setAddressContactFirst] = useState("");

  useEffect(() => {
    if (!_.isEmpty(errors["CREATE_CREDIT_CARD"])){
      if (errors["CREATE_CREDIT_CARD"].length !== 0) {
        setCardError(errors["CREATE_CREDIT_CARD"][0]);
        console.log(errors["CREATE_CREDIT_CARD"][0]);
      }
    }else {
      setCardError("");
    }
  },[errors]);

  useEffect(() => {
    setValidated(false);
    setCreditCardNumber("");
    setCcCode("");
    setActiveCard({});
  },[show])

  useEffect(() => {
    if (showNewCard) {
      setActiveCard({});
    }
  },[showNewCard]);

  const handleProcessPayment = () => {
    if (!_.isEmpty(activeCard)){
      resolveOrder(order.sfid, activeCard.sfid).then(() => {
        setShow(false);
      });
    }
  }
  const handleProcessNewCard = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const cardJSON = {
      cardNumber: creditCardNumber,
      expYear: expYear,
      expMonth: expMonth,
      cvv: ccCode,
      type: ccType,
      isDefault: false
    }
    resolveOrder(order.sfid, null, cardJSON).then(() => {
      setShow(false);
    });
    // createCreditCard(cardJSON).then((val) => {      
    //   console.log(val);
    //   setSuccessToast(true);
    //   if (cardError === "") {
    //   }
    // });

  }


  const renderNewCard = () => {
    if (showNewCard) {
      return (
        <Fragment>
        <Form onSubmit={handleProcessNewCard} noValidate validated={validated} className="sav-form">
          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setCreditCardNumber(e.target.value)}
                  value={creditCardNumber}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a credit card number.
                </Form.Control.Feedback>
                <label className={creditCardNumber && 'filled'}>Credit Card Number</label>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="input-container">
                <Form.Control as="select" required value={expMonth} onChange={(e) => setExpMonth(e.target.value)}>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter an expiration month.
                </Form.Control.Feedback>
                <label className={expMonth && 'filled'}>Expiration Month</label>
              </div>
            </Form.Group>
            <Form.Group as={Col} xs={6}>
              {/* <Form.Label>Expiration Year</Form.Label> */}
              <div className="input-container">
                <Form.Control as="select" required value={expYear} onChange={(e) => setExpYear(e.target.value)}>
                  <option>2021</option>
                  <option>2022</option>
                  <option>2023</option>
                  <option>2024</option>
                  <option>2025</option>
                  <option>2026</option>
                  <option>2027</option>
                  <option>2028</option>
                  <option>2029</option>
                  <option>2030</option>
                  <option>2031</option>
                  <option>2032</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter an expiration year.
                </Form.Control.Feedback>
                <label className={expYear && 'filled'}>Expiration Year</label>
              </div>
            </Form.Group>
          </Form.Row>
          
          <Form.Row>
            <Form.Group as={Col} xs={6}>
            <div className="input-container">
              {/* <Form.Label>Card Type</Form.Label> */}
              <Form.Control as="select" required value={ccType} onChange={(e) => setCcType(e.target.value)}>
                <option>Visa</option>
                <option>Mastercard</option>
                <option>Discover</option>
                <option>American Express</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a card type.
              </Form.Control.Feedback>
              <label className={ccType && 'filled'}>Card Type</label>
              </div>
            </Form.Group>
            <Form.Group as={Col} xs={6}>
              <div className="input-container">
              <Form.Control type="text" required onChange={(e) => setCcCode(e.target.value)} value={ccCode}>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter a security code.
              </Form.Control.Feedback>
              <label className={ccCode && 'filled'}>Security Code</label>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={6}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressContactFirst(e.target.value)}
                  value={addressContactFirst}
                />
                <label className={addressContactFirst && 'filled'}>First Name</label>
              </div>
            </Form.Group>
            <Form.Group as={Col} xs={6}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressContactLast(e.target.value)}
                  value={addressContactLast}
                />
                <label className={addressContactLast && 'filled'}>Last Name</label>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressStreet(e.target.value)}
                  value={addressStreet}
                />
                <label className={addressStreet && 'filled'}>Street Address</label>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={4}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressCity(e.target.value)}
                  value={addressCity}
                />
                <label className={addressCity && 'filled'}>City</label>
              </div>
            </Form.Group>
            <Form.Group as={Col} xs={4}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressState(e.target.value)}
                  value={addressState}
                />
                <label className={addressState && 'filled'}>State/Province/Region</label>
              </div>
            </Form.Group>
            <Form.Group as={Col} xs={4}>
              <div className="input-container">
                <Form.Control
                  required
                  type="text"
                  onChange={(e) => setAddressZip(e.target.value)}
                  value={addressZip}
                />
                <label className={addressZip && 'filled'}>Zip/Postal Code</label>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <div className="input-container">
                <Form.Control as={SavDropFilterForm}  val={addressCountry} opts={Countries} click={setAddressCountry} />
                <label className={addressCountry && 'filled'}>Country</label>
              </div>
            </Form.Group>
          </Form.Row>

          <div className="m-t-10 text-center error med f12">
            {cardError}
          </div>
          <div className="m-t-10 text-center">
            <button className="sav-btn__orange--fill" type="submit">Process Payment</button>
          </div>
        </Form>
        
        </Fragment>
      )
    }
    return null;
  }

  if (!_.isEmpty(creditCards)) {
    let totalDue = 0;
    return (
      <Fragment>
        <button className="sav-btn__red" onClick={() => setShow(true)}>
          Resolve
        </button>
        <Modal show={show} onHide={() =>setShow(false)} animation={false} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Update Payment Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{minHeight:200}}>
            <Container style={{padding: "0px 10%"}}>
              <div className="m-b-20"> 
                <span className="med f14">Order Number: </span>
                <span className="book f14">{order.orderNumber}</span>
              </div>
              <SavTable2 striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Amount Due</th>
                  </tr>
                </thead>
                <tbody>
                  {order.lines.map((ol) => {
                    if (ol.status === "Credit Card Decline") {
                      return (
                        <tr key={ol.sfid} className="book f12">
                          <td>
                            {ol.sku}
                          </td>
                          <td>
                            {ol.qty}
                          </td>
                          <td>
                            {parseCurrency(ol.unitPrice, 2)}
                          </td>
                        </tr>
                      )
                    }
                  })}
                  <tr>
                    <td></td>
                    <td className="book">
                      Total Declined Balance:
                      <br />
                      (Includes tax, shipping, fees)
                    </td>
                    <td>{parseCurrency(order.totalDeclinedBalance, 2)}</td>
                  </tr>
                </tbody>
              </SavTable2>
              <Row className="m-t-20">
                <Col xs={12} className="text-center">
                  <div className="med f12 text-center">
                    Select Saved Credit Card
                  </div>
                  <Dropdown className="savDrop" style={{width:200, margin: "0 auto"}}>
                    <Dropdown.Toggle id="dropdown-basic" as="div">
                      <SavDropToggle>
                        <span>{activeCard.name}</span>
                        <FaCaretDown />
                      </SavDropToggle>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: 200}}>
                      {creditCards.data.map((o) => {
                        return (
                          <Dropdown.Item
                            as="div"
                            href="#"
                            key={o.sfid}
                            name={o.name}
                            onClick={() => setActiveCard(o)}
                            className="onHov"
                            style={{ borderBottom: "1px solid #DBD9D6" }}
                          >
                            <span style={{ display: "inline-flex" }}>{o.name}</span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <Row className="m-t-20">
                <Col xs={12} className="text-center">
                  <div>--</div>
                  <button className={showNewCard ? "sav-btn__gray" : "sav-btn__orange"} onClick={() => setShowNewCard(!showNewCard)}>
                    {showNewCard ? "Cancel" : "New Credit Card"}
                  </button>
                </Col>
              </Row>
              <Row className="m-t-20">
                <Col xs={12}>
                  {renderNewCard()}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button className="sav-btn__orange--fill" disabled={_.isEmpty(activeCard) ? true : false} style={showNewCard ? {display:'none'} : null } onClick={() => handleProcessPayment()}>
              Process Payment
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );

  }
  return null;
  
};

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};
export default connect(mapStateToProps, {resolveOrder, createCreditCard})(UpdatePaymentModal);
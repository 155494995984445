import React from "react";
import styled from "styled-components";

const StyledRadio = styled.svg`
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin-right: 4px;
`;
const Label = styled.div`
  font-family: "Gotham-Medium";
  font-size: 12px;
  margin-top: 10px;
`;
const RadioRow = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonLabel = styled.div`
  font-family: "Gotham-Book";
  font-size: 12px;
  cursor: pointer;
`;
const SavRadio = (props) => {
  const { options, label, click } = props;

  const renderLabel = () => {
    if (label) {
      return <Label>{label}</Label>;
    }
    return null;
  };
  return (
    <React.Fragment>
      {renderLabel()}
      {options.map((o) => {
        return (
          <RadioRow>
            <StyledRadio onClick={() => click(o.text)}>
              <circle
                stroke={o.checked ? "#ff5f00" : "#696057"}
                fill={o.checked ? "#ff5f00" : "transparent"}
                strokeWidth="1"
                r="5.5"
                cx="6"
                cy="6"
              />
              <polyline
                stroke="#fff"
                // points="18 8 9 17 4 12"
                points="9 4 4 8 2 6"
                strokeWidth="1"
                fill="transparent"
                visibility={o.checked ? "visibile" : "hidden"}
              />
            </StyledRadio>
            <ButtonLabel onClick={() => click(o.text)}>{o.text}</ButtonLabel>
          </RadioRow>
        );
      })}
    </React.Fragment>
  );
};

export default SavRadio;

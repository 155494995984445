import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import styled from "styled-components";
import {
  Row,
  Col,
  Container,
  Tab,
  Nav,
  Dropdown,
  Collapse
} from "react-bootstrap";
import _ from "lodash";
import ReactGA from 'react-ga'
import SavBanner from "../../components/SavBanner";
import { fetchArticles, updateSavedArticles } from "../../actions";
import { createLoadingSelector } from "../../selectors";
import { SavSearch, SavLabel } from "../../components/Styled";
import SavDrop from "../../components/SavDrop";
import {
  FaBook,
  FaRegBookmark,
  FaCaretDown,
  FaCaretRight,
  FaBookmark,
  FaFilePdf
} from "react-icons/fa";
import SavPagination from "../../components/SavPagination";
import SavCheck from "../../components/SavCheck";

const SectionHeader = styled.div`
  margin-bottom: 15px;
  font-size: 16px;
  font-family: "Gotham-Medium";
  padding-bottom: 8px;
  border-bottom: 0.5px solid #d4d4d4;
`;

const ArticleRowSmall = styled(Row)`
  padding: 8px;
  margin-bottom: 12px;
  font-size: 12px;
  & :hover {
    cursor: pointer;
    color: #ff5f00;
    background-color: #fafaf9;
    text-decoration: none!important;
  }
`;

const ArticleRow = styled(Row)`
  padding: 8px;
  margin-bottom: 12px;
  font-size: 12px;
  & :hover {
    color: #ff5f00;
    text-decoration: none!important;
  }
`;

const DefaultPill = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  background-color: #fafaf9;
  color:#9b9b9b;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 150px;
  text-align:center;
  text-transform:uppercase;
  cursor: pointer;
  margin: 5px;
  min-height: 34px;
`;
const OrangePIll = styled(DefaultPill)`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #fff;
  color:#ff5f00;
  border: 1px solid #ff5f00;
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 150px;
  text-align:center;
  text-transform:uppercase;
  cursor: pointer;
`;

const SavDropToggle = styled.div`
  background-color: ${(props) =>
    props.style ? props.style.backgroundColor : "#f9f9f9"};
  padding: ${(props) => (props.style ? props.style.padding : "8px")}
  color: ${(props) => (props.style ? props.style.color : "#f9f9f9")};
  width: ${(props) => (props.style ? props.style.width : "100%")};
  height: ${(props) => (props.style ? props.style.height : "auto")}
  border: ${(props) => (props.style ? props.style.border : "none")}
  cursor: pointer;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items:center;
  min-height:28px;
  padding:5px;
  width:100%;
`;
const tabActive = {
  color: "#ff5f00"
};
const hide = {
  display: "none"
};
const dropStyle = {
  border: "1px solid #b4b4b4",
  backgroundColor: "#fff",
  float:"right",
}
const pageOpts = [10, 25, 50, 100];

const Knowledge = props => {
  const {
    articles,
    fetchArticles,
    isLoading,
    updateSavedArticles,
    updatingSavedArticles
  } = props;
  const [tabKey, setTabKey] = useState("articles");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [articleList, setArticleList] = useState([]);
  const [articlePagination, setArticlePagination] = useState([]);
  const [filterOpen, setFilterOpen] = useState(true);
  const [filterArchived, setFilterArchived] = useState(true);

  const [articleFilter, setArticleFilter] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [categoryFilter, setCategoryFilter] = useState("All");
  const [activeCategory, setActiveCategory] = useState("All");

  const [subCatFilter, setSubCatFilter] = useState("All");
  const [activeSubCat, setActiveSubCat] = useState("All");

  const normalize = data => {
    return String(data)
      .toLowerCase()
      .trim()
      .replace(/-|_|#|\s|\.|\,|\?/gi, "");
  };

  const eliteSearchAlgorithm = (article, searchString) => {
    
    const searchReg = normalize(searchString);

    const title = normalize(article.title);
    const desc = normalize(article.description);
    const sku = normalize(article.skus);
    const date = normalize(article.publishDate);
    const searchTerms = normalize(article.searchTerms);
    const url = String(article.url)
      .toLowerCase()
      .trim()
      .replace(/-|_|#|\s|%20/gi, "");

    return (
      title.includes(searchReg) ||
      desc.includes(searchReg) ||
      sku.includes(searchReg) ||
      url.includes(searchReg) ||
      date.includes(searchReg) ||
      searchTerms.includes(searchReg)
    );
  };
  useEffect(() => {
    document.title = "Savant Community | Knowledge"
  },[]);

  useEffect(() => {
    if (_.isEmpty(articles)) {
      fetchArticles();
    } else {
      if (articles.data) {
        setArticleList(articles.data.articles);
        setArticleFilter(articles.data.articles);
      }
    }
  }, [articles]);

  useEffect(() => {
    if (!_.isEmpty(articleList)) {
      setCurrentPage(1);

      let tempArticles;

      if (filterArchived){
        tempArticles = articleList.filter(article => article.isArchived === false);
      } else {
        tempArticles = articleList;
      }

      //Handle Category filtering
      if (categoryFilter !== "All") {
        if (subCatFilter !== "All") {
          //if both cat and subcat have values, filter by them
          tempArticles = articleList.filter(
            article =>
              article.categoryIds.includes(categoryFilter) &&
              article.categoryIds.includes(subCatFilter)
          );
          setArticleFilter(tempArticles);
        } else {
          //if subcat does NOT have a filter but cat does, filter by cat
          tempArticles = articleList.filter(article =>
            article.categoryIds.includes(categoryFilter)
          );
          setArticleFilter(tempArticles);
        }
      } else {
        //if neither categories have a value, return full list.
        setArticleFilter(tempArticles);
      }

      let tempArticles2;

      if (filterArchived){
        tempArticles2 = articleList.filter(article => article.isArchived === false);
      } else {
        tempArticles2 = articleList;
      }

      if (searchString !== "") {
        tempArticles2 = tempArticles.filter(article =>
          eliteSearchAlgorithm(article, searchString)
        );
        tempArticles2.sort((a, b) => {
          if (normalize(a.title).includes(normalize(searchString)) && !normalize(b.title).includes(normalize(searchString))){
            return -1;
          } else {
            return 1
          }
        });
        setArticleFilter(tempArticles2);
      } else {
        setArticleFilter(tempArticles);
      }

      const catName = articles.data.categories.find(
        a => a.id === categoryFilter
      );
      if (catName) {
        setActiveCategory(catName.title);
      } else {
        setActiveCategory("All");
      }

      let subCatName;
      articles.data.categories.forEach(cat => {
        cat.children.forEach(subCat => {
          if (subCat.id === subCatFilter) {
            subCatName = subCat.title;
          }
        });
      });
      if (subCatName && catName) {
        setActiveSubCat(subCatName);
      } else {
        setActiveSubCat("All");
      }
    }
  }, [searchString, categoryFilter, subCatFilter, filterArchived, articleList]);

  useEffect(() => {
    if (articleFilter) {
      let index = 0;
      const tempArticles = [];
      for (index = 0; index < articleFilter.length; index += pageSize) {
        let chunk = articleFilter.slice(index, index + pageSize);
        tempArticles.push(chunk);
      }
      setArticlePagination(tempArticles);
    }
  }, [articleFilter, pageSize]);

  const renderBookmark = sfid => {
    if (articles.data.favorites.indexOf(sfid) !== -1) {
      return <FaBookmark fill="#ff5f00" size={20} />;
    }
    return <FaRegBookmark size={20} className="icon-hover" />;
  };

  const renderArticleIcon = type => {
    if (type === "doc") {
      return <FaFilePdf size={20} />;
    }
    return <FaBook size={20} />;
  };

  const renderArticleCategories = catList => {
    const tempCats = [];
    catList.forEach(cat => {
      const catObj = articles.data.categories.find(a => a.id === cat);
      if (catObj) {
        tempCats.push(catObj.title);
      }

      let subCatName;
      articles.data.categories.forEach(cat2 => {
        cat2.children.forEach(subCat => {
          if (subCat.id === cat) {
            subCatName = subCat.title;
          }
        });
      });
      if (subCatName) {
        tempCats.push(subCatName);
      }
    });

    return tempCats.map((cat, i) => {
      return (
        <span key={i} style={{ marginRight: 8 }}>
          {cat}
        </span>
      );
    });
  };

  const renderAllArticles = () => {
    if (!_.isEmpty(articlePagination)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > articlePagination.length) {
        page = 0;
        setCurrentPage(1);
      }
      return articlePagination[page].map(a => {
        return (
          <ArticleRow key={a.sfid} className="vertical-align">
            <Col xs={1}>{renderArticleIcon(a.type)}</Col>
            
            <Col xs={10}>
              <Link to={a.type === 'article' ? `/knowledge/${a.url}` : `/knowledge/${a.sfid}`}>
                <Row className="f12 med" style={{ marginBottom: 4 }}>
                  <Col xs={12} className="truncate">
                    {a.title}
                    {/* <Link to={a.type === 'article' ? `/knowledge/${a.url}` : `/knowledge/${a.sfid}`}>
                      {a.title}
                    </Link> */}
                  </Col>
                </Row>
                <Row className="f10 book light-gray">
                  <Col xs={12}>
                    <span style={{ marginRight: 8 }}>
                      <span className="med">Published:</span> {a.publishDate}
                    </span>
                    <span className="med">Categories:</span>{" "}
                    {renderArticleCategories(a.categoryIds)}
                  </Col>
                </Row>
                </Link>
            </Col>
            

            <Col
              xs={1}
              onClick={() => handleUpdateSavedArticles(a.sfid, a.title)}
              className="onHov"
            >
              {renderBookmark(a.sfid)}
            </Col>
          </ArticleRow>
        );
      });
    }
    return null;
  };

  const renderSavedArticles = () => {
    if (!_.isEmpty(articles)) {
      const savedArticleList = [];
      articles.data.articles.forEach(art => {
        if (articles.data.favorites.indexOf(art.sfid) >= 0) {
          savedArticleList.push(art);
        }
      });
      return savedArticleList.map(a => {
        return (
          <ArticleRow key={a.sfid} className="vertical-align">
            <Col xs={1}>{renderArticleIcon(a.type)}</Col>
            <Col xs={10}>
              <Row className="f12 med" style={{ marginBottom: 4 }}>
                <Col xs={12} className="truncate">
                <Link to={a.type === 'article' ? `/knowledge/${a.url}` : `/knowledge/${a.sfid}`}>
                  {a.title}
                </Link>
                </Col>
              </Row>
              <Row className="f10 book light-gray">
                <Col xs={12}>
                  <span style={{ marginRight: 8 }}>
                    <span className="med">Published:</span> {a.publishDate}
                  </span>
                  <span className="med">Categories:</span>{" "}
                  {renderArticleCategories(a.categoryIds)}
                </Col>
              </Row>
            </Col>
            <Col
              xs={1}
              onClick={() => handleRemoveSavedArticle(a.sfid)}
              className="onHov"
            >
              {renderBookmark(a.sfid)}
            </Col>
          </ArticleRow>
        );
      });
    }
    return null;
  };

  const renderLatest = () => {
    if (!_.isEmpty(articles)) {
      const latest = articles.data.articles.slice(0, 5);
      return latest.map(a => {
        return (
          <ArticleRowSmall key={a.sfid} className="vertical-align">
            <Col xs={2}>{renderArticleIcon(a.type)}</Col>
            <Col xs={10}>
              <Row className="f12 med" style={{ marginBottom: 4 }}>
                <Col xs={12} className="truncate">
                  <Link to={a.type === 'article' ? `/knowledge/${a.url}` : `/knowledge/${a.sfid}`}>
                    {a.title}
                  </Link>
                </Col>
              </Row>
              <Row className="f10 book light-gray">
                <Col xs={12}>
                  <span style={{ marginRight: 8 }}>
                    <span className="med">Published:</span> {a.publishDate}
                  </span>
                  <br />
                  <span className="med">Categories:</span>{" "}
                  {renderArticleCategories(a.categoryIds)}
                </Col>
              </Row>
            </Col>
          </ArticleRowSmall>
        );
      });
    }
    return null;
  };

  const renderFeatured = () => {
    if (!_.isEmpty(articles)) {
      const featured = _.filter(articles.data.articles, "isFeatured");
      return featured.map(a => {
        return (
          <ArticleRowSmall key={a.sfid} className="vertical-align">
            <Col xs={2}>{renderArticleIcon(a.type)}</Col>
            <Col xs={10}>
              <Row className="f12 med" style={{ marginBottom: 4 }}>
                <Col xs={12} className="truncate">
                  <Link to={a.type === 'article' ? `/knowledge/${a.url}` : `/knowledge/${a.sfid}`}>
                    {a.title}
                  </Link>
                </Col>
              </Row>
              <Row className="f10 book light-gray">
                <Col xs={12}>
                  <span style={{ marginRight: 8 }}>
                    <span className="med">Published:</span> {a.publishDate}
                  </span>
                  <br />
                  <span className="med">Categories:</span>{" "}
                  {renderArticleCategories(a.categoryIds)}
                </Col>
              </Row>
            </Col>
          </ArticleRowSmall>
        );
      });
    }
    return null;
  };

  const renderActiveFilters = () => {
    return (
      <div style={{display: 'flex', alignItems:'center'}}>
        <div style={searchString.length < 1 ? hide : null}>
          <DefaultPill onClick={() => setSearchString("")} className="truncate">
            "{searchString}"
            <span>&times;</span>
          </DefaultPill>
        </div>
        <div style={filterArchived ? hide : null}>
          <DefaultPill onClick={() => setFilterArchived(true)}>
            Showing Archived Articles
            <span>&times;</span>
          </DefaultPill>
        </div>
        <div style={activeCategory === "All" ? hide : null}>
          <DefaultPill onClick={() => handleCategoryClick("All")}>
            {activeCategory}
            <span>&times;</span>
          </DefaultPill>
        </div>
        <div style={activeSubCat === "All" ? hide : null}>
          <DefaultPill onClick={() => handleSubCatClick("All")}>
            {activeSubCat}
            <span>&times;</span>
          </DefaultPill>
        </div>
      </div>
    );
  };

  const handleClearFilters = () => {
    setSearchString("");
    setCategoryFilter("All");
    setSubCatFilter("All");
  };

  const handleUpdateSavedArticles = (articleId, title) => {
    const articleJSON = articles.data.favorites;
    if (articleJSON.indexOf(articleId) === -1) {
      articleJSON.push(articleId);
      ReactGA.event({
        category: 'Knowledge',
        action: 'Bookmarked Article',
        label: title
      });
    } else {
      _.remove(articleJSON, id => id === articleId);
    }
    updateSavedArticles(articleJSON);
  };

  const handleRemoveSavedArticle = articleId => {
    const favorites = articles.data.favorites;
    const articleJSON = _.remove(favorites, id => id === articleId);
    updateSavedArticles(favorites);
  };

  const handleCategoryClick = catId => {
    if (catId === "All") {
      setCategoryFilter("All");
    } else {
      setCategoryFilter(catId);
    }
  };

  const handleSubCatClick = catId => {
    if (catId === "All") {
      setSubCatFilter("All");
    } else {
      setSubCatFilter(catId);
    }
  };

  const renderLoader = loadingState => {
    if (loadingState) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }
  };

  const handleDropClick = (catId, childId) => {
    handleCategoryClick(catId);
    handleSubCatClick(childId);
  }

  const renderOpts = (catTitle) => {

    const category = _.find(articles.data.categories, (cat) => cat.title === catTitle);

    if (category){
      return (
        <Fragment>
          <Dropdown.Item
            as="div"
            href="#"
            onClick={() => handleDropClick(category.id, 'All')}
            className="onHov"
            style={{ borderBottom: "1px solid #DBD9D6", whiteSpace: "pre-wrap" }}
          >
            <span style={{ display: "inline-flex" }}>
              All {category.title}
            </span>
          </Dropdown.Item>
          {category.children.map((childCat) => {
            return (
              <Dropdown.Item
                as="div"
                href="#"
                key={childCat.id}
                onClick={() => handleDropClick(category.id, childCat.id)}
                className="onHov"
                style={{ borderBottom: "1px solid #DBD9D6", whiteSpace: "pre-wrap" }}
              >
                <span style={{ display: "inline-flex" }}>
                  {childCat.title}
                </span>
              </Dropdown.Item>
            )
          })
          }
        </Fragment>
      )

    }
    return null;
  }

  const renderArticles = () => {

    if (isLoading) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }

    if (!_.isEmpty(articles)) {
      return (
        <Container style={{ marginTop: 30 }}>
          {renderLoader(updatingSavedArticles)}
          <Row>
            <Col md={8} style={{ borderRight: ".5px solid #d4d4d4" }}>

              <Row>
                <Col sm={6}>
                  <SavSearch
                    placeholder="Search..."
                    type="search"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                  />
                </Col>
                <Col sm={6}></Col>
              </Row>

              <div style={{ marginBottom: 20 }}>
                <button
                  className="sav-btn__orange--text m-t-10"
                  style={{ paddingLeft: 0, textAlign: "left" }}
                  onClick={() => setFilterOpen(!filterOpen)}
                  aria-controls="filter-collapse"
                  aria-expanded={filterOpen}
                >
                  {filterOpen ? "Hide Filters" : "Show Filters"}
                  &nbsp;&nbsp;
                  <FaCaretRight className={filterOpen ? "sav-hide" : null} />
                  <FaCaretDown className={!filterOpen ? "sav-hide" : null} />
                </button>

                <Collapse in={filterOpen}>
                  <div id="filter-collapse">
                    <Row>
                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>Field Impact Notices</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>

                            {renderOpts('Field Impact Notices')}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>Product Documents</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>
                            {renderOpts('Product Documents')}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>Release Documents</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>
                            {renderOpts('Release Documents')}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>Reference Guides</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>
                            {renderOpts('Reference Guides')}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>Troubleshooting</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>
                            {renderOpts('Troubleshooting')}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>

                      <Col md={4}>
                        <Dropdown className="savDrop m-t-10">
                          <Dropdown.Toggle id="dropdown-basic" as="div">
                            <SavDropToggle style={dropStyle}>
                              <span>{filterArchived ? 'Archived Articles Hidden' : 'Archived Articles Shown'}</span>
                              <FaCaretDown />
                            </SavDropToggle>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{width: '100%'}}>
                            <Dropdown.Item
                              as="div"
                              href="#"
                              onClick={() => setFilterArchived(false)}
                              className="onHov"
                              style={{ borderBottom: "1px solid #DBD9D6" }}
                            >
                              <span style={{ display: "inline-flex" }}>
                                Show Archived Articles
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="div"
                              href="#"
                              onClick={() => setFilterArchived(true)}
                              className="onHov"
                              style={{ borderBottom: "1px solid #DBD9D6" }}
                            >
                              <span style={{ display: "inline-flex" }}>
                                Hide Archived Articles
                              </span>
                            </Dropdown.Item>
                            
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>

                    <Row className="f10 light-gray book m-t-10">
                      <Col xs={12}>
                        Filtering By: 
                        {renderActiveFilters()}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>

              <Tab.Container
                id="article-tabs"
                activeKey={tabKey}
                onSelect={k => setTabKey(k)}
              >
                <Nav style={{ borderBottom: ".5px solid #d4d4d4" }}>
                  <Nav.Item>
                    <Nav.Link
                      className="tab-link-articles"
                      eventKey="articles"
                      style={tabKey === "articles" ? tabActive : null}
                    >
                      Articles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="tab-link-articles"
                      eventKey="saved"
                      style={tabKey === "saved" ? tabActive : null}
                    >
                      My Saved Articles
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="articles">
                    {renderAllArticles()}
                    <Row>
                      <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{width: 200}}>
                          <SavLabel>Page Size</SavLabel>
                          <SavDrop
                            opts={pageOpts}
                            val={pageSize}
                            click={setPageSize}
                          />
                        </div>
                        <SavPagination
                          data={articlePagination}
                          pageSize={pageSize}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          dataLength={articleFilter.length}
                        />
                      
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col
                        xs={12}
                        style={
                          articleFilter.length > 0
                            ? { display: "none" }
                            : { textAlign: "center", marginTop: 30 }
                        }
                      >
                        No Results found.
                        <br />
                        <br />
                        <button
                          className="sav-btn__orange--text"
                          onClick={() => handleClearFilters()}
                        >
                          Clear Filters
                        </button>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="saved">{renderSavedArticles()}</Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
            <Col md={4}>
              <SectionHeader>Featured Articles</SectionHeader>
              {renderFeatured()}
              <SectionHeader>Latest Articles</SectionHeader>
              {renderLatest()}
              <div style={{ height: 200 }} />
            </Col>
          </Row>
        </Container>
      );
    }
  };

  return (
    <div>
      <SavBanner text="Savant Knowledge" padding="40px" />
      {renderArticles()}
    </div>
  );
};

const loadingSelector = createLoadingSelector(["FETCH_ARTICLES"]);
const loadingSelectorSavedArticles = createLoadingSelector([
  "UPDATE_SAVED_ARTICLES"
]);

const mapStateToProps = state => {
  return {
    articles: state.knowledge,
    isLoading: loadingSelector(state),
    updatingSavedArticles: loadingSelectorSavedArticles(state)
  };
};

export default connect(mapStateToProps, { fetchArticles, updateSavedArticles })(
  Knowledge
);

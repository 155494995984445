import styled from "styled-components";
import { Link } from "react-router-dom";

//Overview Dashboard
export const ItemHeader = styled.div`
  padding: 12px;
  color: #565551;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Gotham-Medium";
  text-align: left;
`;
export const GridItem = styled.div`
  min-height: 400px;
  background-color: #fafaf9;
  padding: 15px;
`;
export const DetailButtonDiv = styled.div`
  background-color: #fafaf9;
`;
export const DetailButton = styled(Link)`
  float: right;
`;
export const DetailLabel = styled.div`
  font-size: 12px;
  color: #b4b4b4;
`;
export const Congrats = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 30px 0px;
  text-transform: uppercase;
  color: #3f3f3f;
  font-family:"Gotham-Medium";
`;
export const AwardRowAmb = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 15% 85%;
  padding: 8px;
  font-size:12px;
`;
export const AmbassadorBadge = styled.img`
  cursor:pointer;
  width: ${props => props.width ? props.width : '150px'};
  border: ${props => props.active ? '5px solid #4cd964' : '5px solid #9b9b9b'};
  border-radius: 4px;
  z-index:-1000;
`;
export const AmbassadorParent = styled.div`
  position:relative;
  cursor:pointer;
  text-align:center;
  width:${props => props.width ? props.width : '150px'};
  margin: 0 auto;
`;
export const AmbassadorOpacity = styled.div`
  background:#fff;
  height:${props => props.height ? props.height : '150px'};
  width: ${props => props.width ? props.width : '150px'};
  position:absolute;
  opacity:0.7;
`;
export const AmbassadorBottom = styled.div`
  position:relative;
  font-size:12px;
  font-family:"Gotham-Book";
  display: flex;
  justify-content: center;
  text-align:center;
  align-items: center;
`;
export const StatusRow = styled.div`
  display:grid;
  grid-template-columns: 25% 75%;
  align-items:center;
  padding:25px 0px;
  width:90%;
  margin: 0 auto;
`;
export const AmbassadorPercent = styled.div`
  position:absolute;
  width: ${props => props.width ? props.width : '150px'};
  text-align:center;
  bottom:10px;
  font-family:"Gotham-Medium";
  color:#fff;
`;
export const ProgramIconDiv = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:center;
  width:100%;
  flex-wrap: wrap;
`;

//Overview Ambassador

export const Header = styled.div`
  font-family: "Gotham-Medium";
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
  font-size:20px;
`;
export const SubHeader = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-family: "Gotham-Medium";
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #b4b4b452;
`;
export const GoalHeader = styled(SubHeader)`
  border-bottom:none;
  font-size: 10px;
  padding: 10px 0px 0px;
`;
export const AmbCardBottomGrid = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 15% 85%;
  padding: 10px;
  font-size:10px;
  > div {
    font-size:10px;
  }
`;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import {setSuccessToast, clearErrors} from "../../actions";

const ToastDiv = styled.div`
  background-color:#ffcc5f;
  color:#fff;
  position: fixed;
  left:50%;
  transform: translate(-50%, -175px);
  width:100%
  padding:27px;
  font-family:"Gotham-Medium";
  font-size:14px;
  text-align:center;
  z-index:9999;
  line-height:10px;
  opacity:.9;
`;

const ToastClose = styled.span`
  color: white;
  float: right;
  cursor: pointer;
  font-size: 20px;
`;

const SavToast = props => {
  const { errors, success, loading, clearErrors } = props;
  
  const [show, setShow] = useState(false);

  useEffect(() => {
    let isLoading = false;
    Object.keys(loading).forEach(l => {
      const loader = loading[l];
      if (loader){
        const isUpdating = /(UPDATE|DELETE|CREATE)_(.*)/.exec(l);
        if (isUpdating){
          isLoading = true;
        }
      }
    });
    if (isLoading){
      setShow(true);
    }else{
      setShow(false);
    }
  },[loading]);

if (show) {
    return (
      <ToastDiv>
        LOADING...
        <ToastClose onClick={() => setShow(false)}>&times;</ToastClose>
      </ToastDiv>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    errors: state.errors,
    success: state.success,
    loading: state.loading,
  };
};

export default connect(mapStateToProps, {setSuccessToast, clearErrors})(SavToast);

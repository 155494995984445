import React, { useState, useEffect, Fragment } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled from "styled-components";
import _ from "lodash";
import SavDropFilter from "../SavDropFilter";
import Countries from "../Utilities/countries.json";

const DialCode = styled.div`
  font-size: 12px;
  padding: 10.5px;
  border: 1px solid #979797;
  border-left:none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
  width: -moz-available;
  text-align:left;
`;
const PhoneInput = styled.input`
  outline: 0 none;
  padding: 10.5px;
  border: 1px solid #979797;
  border-top:none;
  font-size: 12px;
  width: ${(props) => props.width || "100%"};
`;
const PhoneGroup = styled.div`
  display: flex;
`;

const SavInputPhone = ({phoneNumber, setPhoneNumber}) => {
  const [countryCode, setCountryCode] = useState('US');
  const [countryName, setCountryName] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [phoneOnly, setPhoneOnly] = useState('');
  // +9613368777

  //populates dialCode, countryCode, phoneOnly from phoneNumber prop
  useEffect(() => {
      let dialCodeFound;
      //if phone number isn't empty, find a country object's dial code that the phone number starts with.
      if (!_.isEmpty(phoneNumber)){
        dialCodeFound = _.find(Countries, (c) => phoneNumber.startsWith(c.dial_code));
      }
      if (dialCodeFound) {
        const dialCodeTemp = phoneNumber.slice(0, dialCodeFound.dial_code.length);
        const number = phoneNumber.slice(dialCodeFound.dial_code.length, phoneNumber.length);
        
        if (!_.isEmpty(dialCodeTemp) && !_.isEmpty(number)){
          setPhoneOnly(number.trim());
          setDialCode(dialCodeTemp.trim());

          if (dialCodeFound.dial_code === '+1'){
            setCountryCode('US');
          } else if (dialCodeFound.dial_code === '+44'){
            setCountryCode('GB')

          } else {
            setCountryCode(dialCodeFound.code);
          }
        }

      }else {
        setPhoneOnly(phoneNumber);
      }
  },[phoneNumber]);

  //handles user selecting different country from dropdown
  useEffect(() => {
    if (!_.isEmpty(countryCode)){
      const countryFound = _.find(Countries, (c) => c.code === countryCode);
      if (countryFound){
        setDialCode(countryFound.dial_code);
        setCountryName(countryFound.name);
      }
    }
  },[countryCode]);

  //runs everytime dialCode changes, does the same thing as handleSetPhone.
  useEffect(() => {
    var clearedMobile = ("" + phoneOnly).replace(/\D/g, "");
    setPhoneNumber(dialCode+clearedMobile);

  },[dialCode]);


  //runs on input blur, strips out any EXISTING non numeric input. Sets phoneNumber to dialCode + phoneOnly.
  const handleSetPhone = () => {
    if(!_.isEmpty(phoneOnly)){
      var clearedMobile = ("" + phoneOnly).replace(/\D/g, "");
      setPhoneNumber(dialCode+clearedMobile);
    }
  };

  //only runs on user input, prevents any NEW non numeric input. Sets phoneOnly.
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/[^0-9]+/g, '');
    setPhoneOnly(value);
  }

  return (
    <Fragment>
      <PhoneGroup>
        <SavDropFilter val={countryCode} opts={Countries} click={setCountryCode} clickKey="code" flag>
          <ReactCountryFlag countryCode={countryCode} svg style={{height:15, width:20}} />
        </SavDropFilter>
        <DialCode>{dialCode} {countryName}</DialCode>
      </PhoneGroup>
      <PhoneInput value={phoneOnly} onChange={handlePhoneChange} onBlur={() => handleSetPhone()} />
    </Fragment>
  )

}

export default SavInputPhone;
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import styled from 'styled-components';
import { Modal, Form, Col, Container } from 'react-bootstrap';
import _ from "lodash";
import { createFeedback } from "../../actions";
import SavFormInput from '../SavFormInput';
import SavFormDrop from '../SavFormDrop';
import SavFormInputArea from '../SavFormInputArea';

const ModalBody = styled.div`
  padding:20px;
`;
const FeedbackModal = (props) => {
  const {createFeedback, user, location} = props;
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("Feedback");
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(1);
  const [ctf, setCtf] = useState("");

  useEffect(() => {
    if (user){
      setEmail(user.username);
    }
  },[user])

  useEffect(() => {
    setMessage("");
    setType("Feedback");
    setStep(1);
    setValidated(false);
    setCtf("");
    setEmail(user.username);
  },[show])

  const handleSave = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const feedbackJSON = {
      message: message,
      uri: location.pathname,
      contactEmail: email,
      type: type,
      userAgent:navigator.userAgent,
      debugInfo:"",
    };
    createFeedback(feedbackJSON).then((res) => {
      setCtf(res);
      setStep(2);
    })
  }

  const typeOpts = ['Feedback', 'Bug'];

  const renderBody = () => {
    if (step === 1){
      return (
        <Container>
          <Form className="sav-form" onSubmit={handleSave} noValidate validated={validated}>
            <Form.Row>
              <Form.Group as={Col} xs={6}>
                <SavFormInput value={email} setValue={setEmail} label="Email" />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <SavFormDrop value={type} setValue={setType} label="Type" opts={typeOpts} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <SavFormInputArea value={message} setValue={setMessage} label="Message"/>
              </Form.Group>
            </Form.Row>
            <div className="m-t-20 text-center">
              <button className="sav-btn__orange--fill" type="submit">
                Submit
              </button>
            </div>
          </Form>
        </Container>
      )
    }else {
      return (
        <Fragment>
        <div className="text-center f12 book">
          Your feedbaack has been submitted to Savant.
        </div>
        <div className="m-t-20 text-center">
        <button className="sav-btn__orange--fill" type="text" onClick={() => setShow(false)}>
          Close
        </button>
      </div>
      </Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <div className="book" onClick={() => setShow(true)}>Submit Feedback</div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {step === 1 ? 'Submit Feedback' : 'Thank You!'}
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          {renderBody()}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {createFeedback})(FeedbackModal));
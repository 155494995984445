import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container, Collapse } from "react-bootstrap";
import _ from "lodash";
import SavCheck from "../../../../components/SavCheck";
import SavDropFilter from "../../../../components/SavDropFilter";
import {SavInput, SavInputDisabled} from "../../../../components/Styled";
import {SettingRow} from "../../../../components/Styled";
import Countries from "../../../../components/Utilities/countries";
import SavModalWarning from "../../../../components/SavModalWarning";

const SavLabel = styled.div`
  font-family:"Gotham-Book";
  font-size:10px;
  color:#979797;
`;

const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;

const SideHeader = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  padding: 10px;
  background-color: #3f3f3f;
  position: absolute;
  height: 75px;
  left: -15px;
  top: -20px;
  > div {
    color: #fff;
    font-family: 'Gotham-Medium';
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
`;


const Address = ({addr, contacts, updateAddress, setSuccessToast, createTask, account, isNDD}) => {
  const [open, setOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [validation, setValidation] = useState(null);
  const [showListingWarning, setShowListingWarning] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addressStreet, setAddressStreet] = useState(addr.addressStreet || '');
  const [addressCity, setAddressCity] = useState(addr.addressCity || '')
  const [addressState, setAddressState] = useState(addr.addressState || '');
  const [addressZip, setAddressZip] = useState(addr.addressZip || '');
  const [addressCountry, setAddressCountry] = useState(addr.addressCountry || '');
  const [addressCompanyName, setAddressCompanyName] = useState(addr.companyNameOverride || '');
  const [addressPhone, setAddressPhone] = useState(addr.phoneOverride || "");
  const [addressEmail, setAddressEmail] = useState(addr.emailOverride || "");
  const [addressContactLast, setAddressContactLast] = useState(addr.overrideLastName || "");
  const [addressContactFirst, setAddressContactFirst] = useState(addr.overrideFirstName || "");
  const [showRoom, setShowRoom] = useState(addr.showroom);
  const [signatureRequired, setSignatureRequired] = useState(addr.signatureRequired);
  const [savantListing, setSavantListing] = useState(addr.isSavantListing);
  const [savantListingName, setSavantListingName] = useState(addr.savantListingNameOverride || "");
  const [savantListingPhone, setSavantListingPhone] = useState(addr.savantListingPhoneOverride || "");
  const REF_URL = localStorage.getItem("ref");

  useEffect(() => {
      setAddressStreet(addr.addressStreet || '');
      setAddressCity(addr.addressCity || '');
      setAddressState(addr.addressState || '');
      setAddressZip(addr.addressZip || '');
      setAddressCountry(addr.addressCountry || '');
      setAddressPhone(addr.phoneOverride || '');
      setAddressEmail(addr.emailOverride || '');
      setAddressContactLast(addr.overrideLastName || '');
      setAddressContactFirst(addr.overrideFirstName || '');
      setShowRoom(addr.showroom);
      setSignatureRequired(addr.signatureRequired);
      setSavantListing(addr.isSavantListing);
      setSavantListingName(addr.savantListingNameOverride || '');
      setSavantListingPhone(addr.savantListingPhoneOverride || '');
      setValidation(null);
  }, [open]);

  const contactOpts = [];
  if(!_.isEmpty(contacts)){
    contacts.forEach((c) => {
      const contactObj = {
        id:'',
        text:''
      }
      if(c.status === "Active"){
        contactObj.text = c.name;
        contactObj.id = c.sfid;
        contactOpts.push(contactObj);
      }
    })
  }

  const handleClickListing = (listingIn) => {
    if (addr.isSavantListing && listingIn === false){
      setSavantListing(listingIn);
      setShowListingWarning(true);
    } else {
      setSavantListing(listingIn)
    }
  }

  

  const handleSave = () => {
    const addrJSON = {...addr};
    addrJSON.addressStreet = addressStreet;
    addrJSON.addressCity = addressCity;
    addrJSON.addressState = addressState;
    addrJSON.addressZip = addressZip;
    addrJSON.addressCountry = addressCountry;
    addrJSON.companyNameOverride = addressCompanyName;
    addrJSON.phoneOverride = addressPhone;
    addrJSON.emailOverride = addressEmail;
    //addrJSON.contactOverride = addressContact;
    addrJSON.overrideFirstName = addressContactFirst;
    addrJSON.overrideLastName = addressContactLast;
    addrJSON.showroom = showRoom;
    addrJSON.signatureRequired = signatureRequired;
    addrJSON.isSavantListing = savantListing;
    addrJSON.savantListingNameOverride = savantListingName;
    addrJSON.savantListingPhoneOverride = savantListingPhone;

    if (_.isEmpty(addressStreet)){
      setValidation('Address cannot be blank.');
      return;
    } else if(_.isEmpty(addressCity)){
      setValidation('City cannot be blank.');
      return;
    } else if(_.isEmpty(addressState) && isNDD && (addressCountry === 'US' || addressCountry === 'CA') ){
      setValidation('State/Province/Region cannot be blank.');
      return;
    } else if(_.isEmpty(addressState) && !isNDD){
      setValidation('State/Province/Region cannot be blank.');
      return;
    } else if(_.isEmpty(addressZip) && isNDD && (addressCountry === 'US' || addressCountry === 'CA') ){
      setValidation('Zip/Postal Code cannot be blank.');
      return;
    } else if(_.isEmpty(addressZip) && !isNDD ){
      setValidation('Zip/Postal Code cannot be blank.');
      return;
    } else if(_.isEmpty(addressCountry)){
      setValidation('Country cannot be blank.');
      return;
    } else {
      setValidation(null);
    }

      if(addrJSON.isSavantListing) {
        if (isNDD){
          addrJSON.pendingFindaDealerRequest = true;
        } else {
          addrJSON.pendingFindaDealerRequest = false;
        }
      };
      if (addrJSON.isSavantListing && !addr.isSavantListing) {
        addrJSON.pendingFindaDealerRequest = true;
      }
  
      updateAddress(addrJSON).then((res) => {
        if (res.success && addrJSON.isSavantListing && !addr.isSavantListing){
          const taskJSON = {
            taskType: `Find a Dealer Listing Address Update for ${addrJSON.addressStreet.slice(0, 200)}`,
            comments: `User requests address: https://savantsystems.my.salesforce.com/${addr.sfid} be added to their Find a Dealer listing`
          };
          createTask(taskJSON);
        }
        setSuccessToast(true);
        setOpen(false);
      });
    
  }

  const handleDelete = () => {
    const addrJSON = {...addr};
    addrJSON.noLongerUsed = true;
    updateAddress(addrJSON).then(() => {
      setSuccessToast(true);
    });
  }

  const renderDefaultShipLabel = () => {
    if (account.defaultShipAddr === addr.sfid && account.defaultBillAddr === addr.sfid) {
      return (
        <SideHeader>
          <div>Default</div>
        </SideHeader>
      )
    }else if (account.defaultShipAddr === addr.sfid) {
      return (
        <SideHeader>
          <div>Shipping</div>
        </SideHeader>
      )
    } else if (account.defaultBillAddr === addr.sfid) {
      return (
        <SideHeader>
          <div>Billing</div>
        </SideHeader>
      )
    } else {
      return null;
    }
    
  }

  const renderCheck = () => {
    if (addr.pendingFindaDealerRequest){
      return (
        <div className="wip" style={{marginTop: 8 }}>
          Pending
        </div>
      )
    }
    if (open){
      return <SavCheck center bigCheck checked={savantListing} click={() => handleClickListing(!savantListing)} />
    }
    return <SavCheck checked={savantListing} center padTop cursor="default" />;
  };

  const renderClosed = () => {
    return (
      <Fragment>
        <Col xs={11}>
          <Row>
            <Col xs={8} style={{paddingTop: 5}}>
              <SavLabel>Address</SavLabel>
            </Col>
            <Col xs={2} style={{paddingTop: 5, textAlign: 'center'}}>
              <SavLabel>Showroom</SavLabel>
            </Col>
            <Col xs={2} style={{paddingTop: 5, textAlign: 'center'}}>
              <SavLabel>Savant.com</SavLabel>
            </Col>
            <Col xs={8}>
              {renderDefaultShipLabel()}
              {addr.addressStreet}
              <br />
              {addr.addressCity}, {addr.addressState} {addr.addressZip}
              <br />
              {addr.addressCountry}
            </Col>
            <Col xs={2} className="text-center">
              <SavCheck checked={showRoom} center padTop cursor="default" />
            </Col>
            <Col xs={2} className="text-center">
              {renderCheck()}
            </Col>
          </Row>
        </Col>
      
        <Col xs={1} className="text-right">
          <EditSpan onClick={() => setOpen(!open)}>
            Edit
          </EditSpan>
        </Col>
      </Fragment>
    )
  };

  const renderOpen = () => {
    return (
      <Fragment>
        <Col xs={10}>
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={() => setOpen(!open)}>
            Close
          </EditSpan>
        </Col>
      </Fragment>
    )
  };

  const renderSavantListingFields = () => {
    if (savantListing) {
      return (
        <Fragment>
          <Col xs={5}>
            <SavLabel>Displayed Company Name</SavLabel>
            <SavInput value={savantListingName} onChange={(e) => setSavantListingName(e.target.value)} />
          </Col>
          <Col xs={5}>
            <SavLabel>Displayed Company Phone</SavLabel>
            <SavInput value={savantListingPhone} onChange={(e) => setSavantListingPhone(e.target.value)} />
          </Col>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Col xs={5}>
          <SavLabel>Displayed Company Name</SavLabel>
          <SavInputDisabled style={{padding: 10 }} disabled value={savantListingName} onChange={(e) => setSavantListingName(e.target.value)} />
        </Col>
        <Col xs={5}>
          <SavLabel>Displayed Company Phone</SavLabel>
          <SavInputDisabled style={{padding: 10 }} disabled value={savantListingPhone} onChange={(e) => setSavantListingPhone(e.target.value)} />
        </Col>
      </Fragment>
    )
  };
  
  const renderContactOverrideFields = () => {
    if (!isNDD){
      return (
        <Fragment>
          <Row className="m-t-20">
            <Col xs={12} className="book f10 light-gray">
              *Complete fields below if information differs from your current default settings
            </Col>
          </Row>
          <Row className="m-t-5">
            <Col xs={12}>
              <SavLabel>Company</SavLabel>
              <SavInput value={addressCompanyName} onChange={(e) => setAddressCompanyName(e.target.value)} placeholder="Enter Company Name..." />
            </Col>
          </Row>
          <Row className="m-t-5">
            <Col xs={6}>
              <SavLabel>First Name</SavLabel>
              <SavInput value={addressContactFirst} onChange={(e) => setAddressContactFirst(e.target.value)} placeholder="Enter First..." />
            </Col>
            <Col xs={6}>
              <SavLabel>Last Name</SavLabel>
              <SavInput value={addressContactLast} onChange={(e) => setAddressContactLast(e.target.value)} placeholder="Enter Last..." />
            </Col>
          </Row>
          <Row className="m-t-10">
            <Col xs={6}>
              <SavLabel>Phone</SavLabel>
              <SavInput value={addressPhone} onChange={(e) => setAddressPhone(e.target.value)} placeholder="Enter Phone number..." />
            </Col>
            <Col xs={6}>
              <SavLabel>Email</SavLabel>
              <SavInput value={addressEmail} onChange={(e) => setAddressEmail(e.target.value)} placeholder="Email Address..." />
            </Col>
          </Row>
        </Fragment>
      )
    }
    return null;
  };

  const renderValidation = () => {
    if (validation){
      return (
        <div className="book f12 text-center m-t-20 red">{validation}</div>
      )
    }
    return null;
  };

  const renderListingWarning = () => {
    if (savantListing || addr.isSavantListing) {
      return (
        <Row className="m-t-20">
          <Col xs={12} className="text-center dark-gray f12">
            {isNDD ? "*A request to show/update this savant.com listing will be initiated with your distributor. Please allow 5 business days for changes to take into effect." : "*Changes to this address may take time to reflect on savant.com. The Savant.com checkbox will show unchecked until this address is actually being displayed on the find-a-dealer page."}
          </Col>
        </Row>
      )
    }
    return null;
  }

  const renderAddress = () => {
    if (addr.pendingFindaDealerRequest && isNDD){
      return (
        <Container>
          <Row className="m-t-10">
            <Col xs={12}>
              <SavLabel>Address</SavLabel>
              <SavInputDisabled disabled style={{padding: 10 }} value={addressStreet} onChange={(e) => setAddressStreet(e.target.value)}  />
            </Col>
          </Row>
          <Row className="m-t-10"> 
            <Col xs={4}>
              <SavLabel>City</SavLabel>
              <SavInputDisabled disabled style={{padding: 10 }} value={addressCity} onChange={(e) => setAddressCity(e.target.value)} />
            </Col>
            <Col xs={4}>
              <SavLabel>State/Province/Region</SavLabel>
              <SavInputDisabled disabled style={{padding: 10 }} value={addressState} onChange={(e) => setAddressState(e.target.value)} />
            </Col>
            <Col xs={4}>
              <SavLabel>Zip/Postal Code</SavLabel>
              <SavInputDisabled disabled style={{padding: 10 }} value={addressZip} onChange={(e) => setAddressZip(e.target.value)} />
            </Col>
          </Row>
          <Row className="m-t-10">
            <Col xs={8}>
              <SavLabel>Country</SavLabel>
              <SavInputDisabled disabled style={{padding: 10 }} value={addressCountry} />
            </Col>
            <Col xs={isNDD ? 4 : 2} className="text-center">
              <SavLabel>Showroom</SavLabel>
              <SavCheck bigCheck center cursor="default" checked={showRoom} />
            </Col>
          </Row>
          
          <Row className="m-t-10">
            <Col xs={2} className="text-center">
              <SavLabel>Savant.com</SavLabel>
              {renderCheck()}
            </Col>
            {renderSavantListingFields()}
          </Row>
          {renderContactOverrideFields()}
          
          <Row className="m-t-20">
            <Col xs={12} className="text-center dark-gray f12">
              *Address cannot be edited while a Savant.com listing request is pending.
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <Container>
        <Row className="m-t-10">
          <Col xs={12}>
            <SavLabel>Address</SavLabel>
            <SavInput value={addressStreet} onChange={(e) => setAddressStreet(e.target.value)}  />
          </Col>
        </Row>
        <Row className="m-t-10"> 
          <Col xs={4}>
            <SavLabel>City</SavLabel>
            <SavInput value={addressCity} onChange={(e) => setAddressCity(e.target.value)} />
          </Col>
          <Col xs={4}>
            <SavLabel>State/Province/Region</SavLabel>
            <SavInput value={addressState} onChange={(e) => setAddressState(e.target.value)} />
          </Col>
          <Col xs={4}>
            <SavLabel>Zip/Postal Code</SavLabel>
            <SavInput value={addressZip} onChange={(e) => setAddressZip(e.target.value)} />
          </Col>
        </Row>
        <Row className="m-t-10">
          <Col xs={8}>
            <SavLabel>Country</SavLabel>
            <SavDropFilter val={addressCountry} opts={Countries} click={setAddressCountry} clickKey="code" />
          </Col>
          <Col xs={isNDD ? 4 : 2} className="text-center">
            <SavLabel>Showroom</SavLabel>
            <SavCheck bigCheck center checked={showRoom} click={() => setShowRoom(!showRoom)} />
          </Col>
          <Col xs={2} className={isNDD ? "d-none" : "text-center"}>
            <SavLabel>Signature Req</SavLabel>
            <SavCheck bigCheck center checked={signatureRequired} click={() => setSignatureRequired(!signatureRequired)} />
          </Col>
        </Row>
        
        <Row className="m-t-10">
          <Col xs={2} className="text-center">
            <SavLabel>Savant.com</SavLabel>
            {renderCheck()}
          </Col>
          {renderSavantListingFields()}
        </Row>
        {renderContactOverrideFields()}
        {renderValidation()}
        {renderListingWarning()}
        <Row className="m-t-20">
          <Col xs={12} className="text-center">
            <button className="sav-btn__gray--fill" onClick={() => setShowDeleteModal(true)}>Delete</button>
            &nbsp;&nbsp;
            {/* <button className="sav-btn__orange--fill" onClick={savantListing ? () => setShowWarning(true) : () => handleSave()}>Save</button> */}
            <button className="sav-btn__orange--fill" onClick={() => handleSave()}>Save</button>
          </Col>
        </Row>
      </Container>
    )
  };

  return (
    <Fragment>
      {/* <SavModalWarning
        show={showWarning}
        hide={() => setShowWarning(false)}
        title="Savant.com Listing Update"
        text2={isNDD ? "A request to show this listing will be initiated with your distributor. Please allow 5 business days for changes to take into effect." : "Changes to this address may take time to reflect on savant.com. The Savant.com checkbox will show unchecked until this address is actually being displayed on the find-a-dealer page."}
        click={() => setShowWarning(false)}
        center
      /> */}
      <SavModalWarning
        show={showListingWarning}
        hide={() => setShowListingWarning(false)}
        title="Savant.com Listing Update"
        text2={"Unchecking this box will remove this address from its current listing on savant.com."}
        click={() => setShowListingWarning(false)}
        center
      />
      <SavModalWarning 
          show={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          title="Delete Address"
          text2="Are you sure you want to delete this address?"
          buttonText="delete"
          center
          click={handleDelete}
        />
      <SettingRow style={open ? {borderLeft:"4px solid #ff5f00"}: {alignItems:'center', padding: '0px 15px'}}>
        {open ? renderOpen() : renderClosed()}
        <Collapse in={open}>
          {renderAddress()}
        </Collapse>
      </SettingRow>
    </Fragment>
  )
}

export default Address;
import {FETCH_NOTIFICATIONS_SUCCESS} from "../actions/types";
import { success } from "./utlities";

const notifications = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default notifications;

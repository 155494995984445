import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { FaPlus } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import SavSearchB from "../../../components/SavSearch";
import SavDrop from "../../../components/SavDrop";
import {
  SavRow,
  SavSearch,
  HeaderBlock,
  HeaderBlockContent,
  HeaderBlockTitle,
  StatusPill
} from "../../../components/Styled";
import {createNotification, fetchNotifications} from "../../../actions";
import construction from "../Overview/construction.png"

const Education = props => {
  const { education, user, createNotification, notifications, fetchNotifications, success } = props;

  const [educationList, setEducationList] = useState([]);
  const [educationFilter, setEducationFilter] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [alphabetChar, setAlphabetChar] = useState("All");
  const [activeFilter, setActiveFilter] = useState("All");
  const [isNDD, setIsNDD] = useState(false);
  const [shadeTotal, setShadeTotal] = useState(0);
  const [studioTotal, setStudioTotal] = useState(0);
  const [blueprintTotal, setBlueprintTotal] = useState(0);
  const [powerTotal, setPowerTotal] = useState(0);
  const SFTOKEN = localStorage.getItem("sfsid");

  const columns = [
    { text: "Name", size: 3,sizeXS: 3, value: "contactName" },
    { text: "Blueprint", size: 2, sizeXS: 2, value: "statusBlueprint" },
    { text: "Studio", size: 2, sizeXS:2, value: "statusStudio" },
    { text: "Shade", size: 2, sizeXS:2, value: "statusShade" },
    { text: "Power System", size: 2, sizeXS: 2, value: "statusShade" },
    { text: "Classroom", size: 1, value: "completedCore", hideMobile:true,}
  ];
  const columnsNDD = [
    { text: "Name", size: 3,sizeXS: 3, value: "contactName" },
    { text: "Studio", size: 3, sizeXS: 3, value: "statusStudio" },
    { text: "Shade", size: 3, sizeXS: 3, value: "statusShade" },
    { text: "Power System", size: 3, sizeXS: 3, value: "statusPower" },
  ]

  useEffect(() => {
    if (!_.isEmpty(user)){
      if(user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'US Distribution'){
        setIsNDD(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!_.isEmpty(education)) {    
      let countShade = 0;
      let countStudio = 0;
      let countBlueprint = 0;
      let countPower = 0;
      education.forEach(e => {
        if (e.completedStudio) {
          countStudio += 1;
        }
        if (e.completedShade) {
          countShade += 1;
        }
        if (e.completedBlueprint){
          countBlueprint +=1;
        }
        if (e.completedPower){
          countPower +=1;
        }
      });
      setShadeTotal(countShade);
      setStudioTotal(countStudio);
      setBlueprintTotal(countBlueprint);
      setPowerTotal(countPower);
      setEducationList(education);
    }
  }, [education]);

  useEffect(() => {
    if (!_.isEmpty(education)){
      if (activeFilter !== "All"){
        setCurrentPage(1);
        if (activeFilter === "Untrained Blueprint"){
          let temp = education.filter(
            e => e.completedBlueprint === false
          );
          setEducationList(temp);
        }
        if (activeFilter === "Untrained Studio"){
          let temp = education.filter(
            e => e.completedStudio === false
          );
          setEducationList(temp);
        }
        if (activeFilter === "Untrained Shades"){
          let temp = education.filter(
            e => e.completedShade === false
          );
          setEducationList(temp);
        }
        if (activeFilter === "Untrained Power System"){
          let temp = education.filter(
            e => e.completedPower === false
          );
          setEducationList(temp);
        }
      }else {
        setEducationList(education);
      }
    }
  },[activeFilter]);

  useEffect(() => {
    if (educationList) {
      let index = 0;
      const temp = [];
      for (index = 0; index < educationList.length; index += pageSize) {
        let chunk = educationList.slice(index, index + pageSize);
        temp.push(chunk);
      }
      setEducationFilter(temp);
    }
  }, [educationList, pageSize]);

  useEffect(() => {
    if(searchString !== ""){
      let temp = education.filter(contact => 
        SavSearchB(contact, ["contactName"], searchString)
      );
      setEducationList(temp);
    } else {
      setEducationList(education)
    }
  }, [searchString])

  useEffect(() => {
    if (!_.isEmpty(education)) {
      if (alphabetChar !== "All") {
        setCurrentPage(1);
        let temp = education.filter(
          contact =>
            contact.contactName.startsWith(alphabetChar) ||
            contact.contactName.startsWith(alphabetChar.toUpperCase())
        );
        setEducationList(temp);
      } else {
        setEducationList(education);
      }
    }
  }, [alphabetChar]);

  const handleNotification = (contact, course) => {
    let notificationJSON = [{}];
    if (course === 'studio'){
      notificationJSON = [
        {
          isRead: false,
          contactId: contact.contactId,
          message:user.contact.firstName + ' ' + user.contact.lastName + ' would like you to enroll in Savant Studio and Savant Central Management in Savant University.',
          course:'Savant Studio and Savant Central Management',
          target: 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=savantstudio',
          isExternal: true,
        }
      ];
    } else if (course === 'shade'){
      notificationJSON = [
        {
          isRead: false,
          contactId: contact.contactId,
          message:user.contact.firstName + ' ' + user.contact.lastName + ' would like you to enroll in Savant Shades in Savant University.',
          course:'Savant Shades',
          target: 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=shadecert',
          isExternal: true,
        }
      ];
    } else if (course === 'power' && !isNDD){
      notificationJSON = [
        {
          isRead: false,
          contactId: contact.contactId,
          message:user.contact.firstName + ' ' + user.contact.lastName + ' would like you to enroll in Savant Power System Certification with Blueprint in Savant University.',
          course:'Savant Power System Certification',
          target: 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=powercert',
          isExternal: true,
        }
      ];
    } else if (course === 'power' && isNDD){
      notificationJSON = [
        {
          isRead: false,
          contactId: contact.contactId,
          message:user.contact.firstName + ' ' + user.contact.lastName + ' would like you to enroll in Savant Power System Certification with Power & Light App in Savant University.',
          course:'Savant Power System w/ Power & Light App',
          target: 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=powercertspl',
          isExternal: true,
        }
      ];
    } else if (course === 'blueprint'){
      notificationJSON = [
        {
          isRead: false,
          contactId: contact.contactId,
          message:user.contact.firstName + ' ' + user.contact.lastName + ' would like you to enroll in Savant Fundamentals with Blueprint in Savant University.',
          course:'Savant Fundamentals with Blueprint',
          target: 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=savantfundamentals',
          isExternal: true,
        }
      ];
    }
    createNotification(notificationJSON).then(() => {
      fetchNotifications();
    })
  }

  const renderPill = (e, course) => {
    if (
      (course === 'blueprint' && e.completedBlueprint) || 
      (course === 'studio' && e.completedStudio) || 
      (course === 'shade' && e.completedShade) || 
      (course === 'core' && e.completedCore) ||
      (course === 'power' && e.completedPower)
      ){
        return <StatusPill bg="#4cd964" color="#fff" border="1px solid #4cd964">Complete</StatusPill>
    }

    if (course === 'blueprint' && e.statusBlueprint === "TO-DO"){
      return <StatusPill style={{cursor:'pointer'}} onClick={() => handleNotification(e, 'blueprint')}>Enroll&nbsp;&nbsp;<FaPlus /></StatusPill>
    }
    if (course === 'blueprint' &&  e.statusBlueprint === "WIP"){
      return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>
    }

    if (course === 'shade' && e.statusShade === "TO-DO"){
      return <StatusPill style={{cursor:'pointer'}} onClick={() => handleNotification(e, 'shade')}>Enroll&nbsp;&nbsp;<FaPlus /></StatusPill>
    }
    if (course === 'shade' &&  e.statusShade === "WIP"){
      return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>
    }

    if (course === 'studio' && e.statusStudio === "TO-DO"){
      return <StatusPill style={{cursor:'pointer'}} onClick={() => handleNotification(e, 'studio')}>Enroll&nbsp;&nbsp;<FaPlus /></StatusPill>
    }
    if (course === 'studio' &&  e.statusStudio === "WIP"){
      return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>
    }

    if (course === 'power' && e.statusPower === "TO-DO"){
      return <StatusPill style={{cursor:'pointer'}} onClick={() => handleNotification(e, 'power')}>Enroll&nbsp;&nbsp;<FaPlus /></StatusPill>
    }
    if (course === 'power' &&  e.statusPower === "WIP"){
      return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>
    }
    return null;
  }

  const renderList = () => {
    if (!_.isEmpty(educationFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > educationFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return educationFilter[page].map(e => {
        if (isNDD){
          return (
            <SavRow key={e.contactId}>
              <Col xs={3}><Link to={`/education/${e.contactId}`} className="u truncate">{e.contactName}</Link></Col>
              <Col xs={3}>{renderPill(e, "studio")}</Col>
              <Col xs={3}>{renderPill(e, "shade")}</Col>
              <Col xs={3}>{renderPill(e, "power")}</Col>
            </SavRow>
          )
        } else {
          return (
            <SavRow key={e.contactId}>
              <Col xs={3} md={3} className="truncate"><Link to={`/education/${e.contactId}`} className="u truncate">{e.contactName}</Link></Col>
              <Col xs={3} md={2}>{renderPill(e, "blueprint")}</Col>
              <Col xs={3} md={2}>{renderPill(e, "studio")}</Col>
              <Col xs={3} md={2}>{renderPill(e, "shade")}</Col>
              <Col xs={3} md={2}>{renderPill(e, "power")}</Col>
              <Col md={1} className="d-none d-md-block">{renderPill(e, "core")}</Col>
            </SavRow>
          )
        }
      });
    }
    return null;
  };

  if (educationList && user) {
    let filterOpts = [];
    if (isNDD){
      filterOpts = ["All", "Untrained Studio", "Untrained Shades", "Untrained Power System" ];
    }else{
      filterOpts = ["All", "Untrained Blueprint", "Untrained Studio", "Untrained Shades", "Untrained Power System" ];
    }
    return (
      <div>
        <Container>
        <img src={construction} style={{width: '100%'}} />
          <Row style={{ marginTop: 40, marginBottom: 40 }}>
            <Col md={3} style={isNDD ? {display:'none'} : null}>
              <HeaderBlock>
                <HeaderBlockTitle className="f14">Total Trained Blueprint</HeaderBlockTitle>
                <HeaderBlockContent className="oj f24">{blueprintTotal}</HeaderBlockContent>
              </HeaderBlock>
            </Col>
            <Col md={isNDD ? 4 : 3}>
              <HeaderBlock>
                <HeaderBlockTitle className="f14">Total Trained Studio</HeaderBlockTitle>
                <HeaderBlockContent className="oj f24">{studioTotal}</HeaderBlockContent>
              </HeaderBlock>
            </Col>
            <Col md={isNDD ? 4 : 3}>
              <HeaderBlock>
                <HeaderBlockTitle className="f14">Total Trained Shades</HeaderBlockTitle>
                <HeaderBlockContent className="oj f24">{shadeTotal}</HeaderBlockContent>
              </HeaderBlock>
            </Col>
            <Col md={isNDD ? 4 : 3}>
              <HeaderBlock>
                <HeaderBlockTitle className="f14">Total Trained Power</HeaderBlockTitle>
                <HeaderBlockContent className="oj f24">{shadeTotal}</HeaderBlockContent>
              </HeaderBlock>
            </Col>
          </Row>

          <Row className="vertical-align">
            <Col md={8} className="d-none d-md-block">
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
            </Col>
            <Col sm={12} md={4} className="d-flex justify-content-lg-end justify-content-center mt-3 mt-md-0 ">
              <div className="d-flex align-items-center" style={{width:250}}>
                <div className="light-gray f12 med" style={{marginRight:5}}>FILTER</div>
                <SavDrop
                  opts={filterOpts}
                  val={activeFilter}
                  click={setActiveFilter}
                  style={{
                    border: "1px solid #b4b4b4",
                    backgroundColor: "#fff",
                    width:200
                  }}
                />
              </div>
            </Col>
          </Row>

          <hr />
          <SavColumnSort
            data={educationList}
            setData={setEducationList}
            columns={isNDD ? columnsNDD : columns}
          />
          {renderList()}
          <SavPagination
            data={educationFilter}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataLength={educationList.length}
          />
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    education: state.education.data,
    notifications: state.notifications.items,
    user: state.user.data
  };
};

export default connect(mapStateToProps, { createNotification,fetchNotifications })(Education);
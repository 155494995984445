import React, { useState } from "react";
import { Table, Container, Row, Col, Collapse } from "react-bootstrap";
import styled from "styled-components";

const SavTable = styled(Table)`
  font-size: 12px;
  text-align: center;
  th {
    font-size: 12px;
    background-color: #3f3f3f;
    color: #fff;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle!important;
  }
  td {
    vertical-align: middle;
  }
`;

const Header = styled.div`
  font-size: 16px;
  margin-top: 30px;
  font-family: "Gotham-Medium";
  text-align: center;
`;
const PowerFinancingTable = props => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
    window.scrollTo({
      bottom: 0,
      behavior: "smooth"
    });
  };
  return (
    <React.Fragment>
      <Header>
        Power Financing Program Package Details & Pricing Information
        <br />
        <span
          onClick={() => handleClick()}
          aria-controls="collapse-table"
          aria-expanded={open}
          className="f12 onHov oj"
        >
          {open ? "Hide Details" : "Show Details"}
        </span>
      </Header>
        <Collapse in={open}>
        <div id="collapse-table">
          <div style={{ marginTop: 30 }}>
            <SavTable bordered hover striped >

              <thead>
                <tr>
                  <th>Description</th>
                  <th>Battery & Inverter<br />Partner</th>
                  <th className="d-none d-md-table-cell">Service Size</th>
                  <th>Inverter Output<br /> (OFF-GRID)</th>
                  <th>Storage</th>
                  <th>MSRP</th>
                  {/* <th>Dealer Price <sup className="wht">1</sup></th> */}
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>200A STORAGE SYSTEM<br />SCHNEIDER 6.8KW- EFLEX 10.8KWH</td>
                  <td>FORTRESS POWER<br />SCHNEIDER ELECTRIC</td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>6.8kW</td>
                  <td>10.8kWh</td>
                  <td>$18,500</td>
                  {/* <td>$12,950</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM<br />SCHNEIDER 13.6KW- EFLEX 21.6KWH</td>
                  <td>FORTRESS POWER<br />SCHNEIDER ELECTRIC</td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>13.6kW</td>
                  <td>21.6kWh</td>
                  <td>$25,000</td>
                  {/* <td>$17,500</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM<br />SCHNEIDER 6.8KW- EVAULT 18.5KWH</td>
                  <td>FORTRESS POWER<br />SCHNEIDER ELECTRIC</td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>6.8kW</td>
                  <td>18.5kWh</td>
                  <td>$35,000</td>
                  {/* <td>$24,500</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM<br />SCHNEIDER 13.6KW- EVAULT 37KWH</td>
                  <td>FORTRESS POWER<br />SCHNEIDER ELECTRIC</td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>13.6KW</td>
                  <td>37kWh</td>
                  <td>$49,000</td>
                  {/* <td>$34,300</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM<br />SOL-ARK 12KW - HOMEGRID 19.2 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>12kW</td>
                  <td>19.2kWh</td>
                  <td>$26,800</td>
                  {/* <td>$21,520</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM<br />INTEGRATED SOL-ARK 12KW - HOMEGRID 19.2 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">200As</td>
                  <td>12kW</td>
                  <td>19.2kWh</td>
                  <td>$28,500</td>
                  {/* <td>$20,160</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM <b>NO ATS</b><br />INTEGRATED SOL-ARK 12KW - HOMEGRID 19.2 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>12kW</td>
                  <td>19.2kWh</td>
                  <td>$25,700</td>
                  {/* <td>$44,720</td> */}
                </tr>
                <tr>
                  <td>200A STORAGE SYSTEM <b>NO ATS</b><br />SOL-ARK 12KW - HOMEGRID 19.2 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">200A</td>
                  <td>12kW</td>
                  <td>19.2kWh</td>
                  <td>$24,000</td>
                  {/* <td>$39,200</td> */}
                </tr>
                <tr>
                  <td>400A STORAGE SYSTEM<br />SOL-ARK 24KW - HOMEGRID 38.4 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">400A</td>
                  <td>24kW</td>
                  <td>38.4kWh</td>
                  <td>$52,300</td>
                  {/* <td>$70,480</td> */}
                </tr>
                <tr>
                  <td>600A STORAGE SYSTEM<br />SOL-ARK 36KW - HOMEGRID 76.8 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">600A</td>
                  <td>36kW</td>
                  <td>76.8kWh</td>
                  <td>$92,900</td>
                  {/* <td>$90,160</td> */}
                </tr>
                <tr>
                  <td>800A STORAGE SYSTEM<br />SOL-ARK 48KW - HOMEGRID 76.8 KWH</td>
                  <td>SAVANT<br /><i>IN PARTNERSHIP WITH SOL-ARK<br /> & HOMEGRID</i></td>
                  <td className="d-none d-md-table-cell">800A</td>
                  <td>48kW</td>
                  <td>76.8kWh</td>
                  <td>$104,800</td>
                  {/* <td>$105,200</td> */}
                </tr>
  
              </tbody>
            </SavTable>
          </div>
          <div className="m-t-10 f12 light-gray text-left">
            {/* 1 - Freight not included in Dealer Price.<br />
            2 - Savant is Partnered with Homegrid<br /> */}
            Packages invoiced over a period of 7 months (210 days)
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export default PowerFinancingTable;
import React, {useState, Fragment} from 'react';
import { Modal, Image } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash';

const ModalBody = styled.div`
  padding: 50px;
`;

const SavImageZoom = ({ title, image, className}) => {
  const [showModal, setShowModal] = useState(false);
  const renderModal = () => {
    if (showModal){
      return (
        <Modal className="modal-100w" show={showModal} onHide={ () => setShowModal(false) } size="xl" animation={false} centered style={{width: '100%'}}>
          <Modal.Header closeButton>
            <Modal.Title>
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{cursor: 'zoom-out'}} onClick={() => setShowModal(false)}>
            <img src={image} width="100%" alt={title} />
          </Modal.Body>
        </Modal>
      )
    }
    return null;
  }
  return (
    <Fragment>
      {renderModal()}
      <Image src={image} className={className} fluid style={{cursor: 'zoom-in'}} onClick={() => setShowModal(true)} />
    </Fragment>
  );
};
export default SavImageZoom;
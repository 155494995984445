import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom";
import { connect } from "react-redux";
import { FiRefreshCcw } from "react-icons/fi";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../../components/SavBanner";
import SavColumnSort from "../../../components/SavColumnSort";
import {fetchEducationDetail} from "../../../actions";
import {
  SavRow,
  SubHeader,
  StatusPill
} from "../../../components/Styled";

const EducationDetail = props => {
  const contactId = props.match.params.contactId;
  const {activeEducation, fetchEducationDetail} = props;
  const [contactDetail, setContactDetail] = useState(null);
  const [enrollments, setEnrollments] = useState(null);

  useEffect(() => {
    document.title = "Savant Community | Education Detail"
  },[])

  useEffect(() => {
    if (_.isEmpty(activeEducation)) {
      fetchEducationDetail(contactId);
    } 
    else {
      if (activeEducation[0].contactId !== contactId) {
        fetchEducationDetail(contactId);
      }
      setContactDetail(activeEducation[0])
      setEnrollments(activeEducation[0].enrollments);
    }
  }, [activeEducation]);

  const parseDate = date => {
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleDateString();
  };

  const renderPill = (e) => {
    if (e.completed){
      return <StatusPill bg="#4cd964" color="#fff" border="1px solid #4cd964">Complete</StatusPill>;
    }else{
      return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>;
    }
  }

  const renderEnrollments = () => {
    if(enrollments){
      if (enrollments.length < 1){
        return (
          <div className='text-center f20 m-t-40 book'>No enrollment information to display.</div>
        )
      }
      return enrollments.map((e) => {
        return (
          <SavRow key={e.sfid}>
            <Col className="truncate" xs={3} md={6}>
              <a href={`https://community.savant.com/Customers/Savant_University?Destination=/savant/s/coursedetail/${e.courseId}`} target="_blank">
              {e.title}
              </a>
            </Col>
            <Col xs={3} md={2}>{parseDate(e.enrolled)}</Col>
            <Col xs={3} md={2}>{renderPill(e)}</Col>
            <Col xs={3} md={2}>{e.completed? parseDate(e.completed) : "In Progress"}</Col>
          </SavRow>
        )
      })  
    }
  }

  if(!_.isEmpty(contactDetail)){
    return (
      <div>
        <SavBanner text="Education" padding="40px" />
        <Container>
          <div style={{ marginTop: 30 }}>
            <Link className="hov oj med f12" to="/account?tab=education#contacts">
              &lt; BACK TO EDUCATION MANAGEMENT
            </Link>
          </div>
          <SubHeader>{contactDetail.contactName} Enrollment Detail</SubHeader>
          <SavColumnSort
            data={enrollments}
            setData={setEnrollments}
            columns={[
              { text: "Course", size: 6, sizeXS: 3,  value: "title" },
              { text: "Enrolled", size: 2, sizeXS: 3, value: "enrolled" },
              { text: "Status", size: 2, sizeXS: 3, value: "completed"},
              { text: "Completed", size: 2, sizeXS: 3, value: "completed" }
            ]}
          />
          {renderEnrollments()}

        </Container>
      </div>
    );
  }
  
  return null;
  
};

const mapStateToProps = state => {
  return {
    activeEducation: state.activeEducation.data
  };
};
export default connect(mapStateToProps, {fetchEducationDetail})(EducationDetail);
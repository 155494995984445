import React from "react";
import { Container, } from "react-bootstrap";
import styled from "styled-components";

const links = {
  color: "#696057",
  cursor: "pointer",
  fontFamily: "Gotham-Medium",
  letterSpacing: 1,
};
const links2 = {
  color: "#696057",
  cursor: "pointer",
  fontFamily: "Gotham-Light",
  letterSpacing: 1,
};


const FooterDiv = styled.div`
  background: linear-gradient(180deg, #EEEFED 0%, #DCDCDC 100%);
  color: #696057;
  font-family:"Gotham-Book";
  font-size: 18px;
  padding: 0px 40px;
  width: 100%;
`;

const LinkRow = styled.div`
  display:flex;
  justify-content:start;
  margin-top: 90px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;
const LinkDiv = styled.div`
  padding: 0px 30px;
  text-align:center;
  border-left: ${(props) => props.line ? "0.75px solid #696057" : "none"};
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0px 15px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0px 15px;
    text-align: left;
    margin-bottom: 12px;
  }
`;
const LinkDivFirst = styled.div`
  padding: 0px 30px;
  text-align:center;
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0px 15px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0px 15px;
    text-align: left;
    margin-bottom: 12px;
    border-left: 0.75px solid #696057;
  }
`;
const CopyRow = styled.div`
  font-family: "Gotham-Light";
  margin-top: 70px;
  padding-left: 30px;
  font-size: 16px;
  @media (max-width: 1024px) {
    padding-left: 15px;
    text-align: left;
    margin-top: 50px;
    font-size: 12px;
  }
  @media (max-width: 768px) {
    padding-left: 0px;
    text-align: left;
    margin-top: 20px;
    font-size: 12px;
  }
`;

const Footer = props => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterDiv className="print-hide">
      <Container fluid >
        <LinkRow>
          <LinkDivFirst>
            <a href={'https://savant.com'} style={links}>
              ABOUT SAVANT
            </a> 
          </LinkDivFirst>
          <LinkDiv line>
           <a href="/support" style={links}>
              CONTACT US
            </a>
          </LinkDiv>
          <LinkDiv line>
            <a href="https://www.savant.com/press" style={links}>
              PRESS
            </a>
          </LinkDiv>
          <LinkDiv line>
            <a href="https://www.savant.com/news" style={links}>
              IN THE NEWS
            </a>
          </LinkDiv>
          <LinkDiv line>
            <a href="https://www.savant.com/careers" style={links}>
              CAREERS
            </a>   
          </LinkDiv>
        </LinkRow>
        <CopyRow>
          &copy;Copyright {year} Savant Systems, Inc | <a style={links2} href="https://www.savant.com/company-info#legal">Legal</a>
        </CopyRow>
      </Container>
    </FooterDiv>
  );
};

export default Footer;

import { combineReducers } from "redux";
import notifications from "./notifications";
import account from "./account";
import grid from "./grid";
import carousel from "./carousel";
import feed from "./feed";
import activeNews from "./activeNews";
import orders from "./orders";
import activeOrder from "./activeOrder";
import addresses from "./addresses";
import contacts from "./contacts";
import activeContact from "./activeContact";
import serials from "./serials";
import credits from "./credits";
import invoices from "./invoices";
import documents from "./documents";
import loading from "./loading";
import server from "./server";
import errors from "./errors";
import knowledge from "./knowledge";
import activeArticle from "./activeArticle";
import user from "./user";
import agreements from "./agreements";
import events from "./events";
import activeEvent from "./activeEvent";
import marketing from "./marketing";
import cases from "./cases";
import activeCase from "./activeCase";
import education from "./education";
import activeEducation from "./activeEducation";
import success from "./success";
import creditCards from "./creditcards";
import rma from "./rma";
import latestSoftware from "./latestSoftware";
import beta from './beta';
import profileRequests from './profileRequests';
import activeOrderPDF from './activeOrderPDF';
import software from './software';
import toast from './toast';
import content from './content';
import payments from "./payments";
import bankAccounts from "./bankAccounts";
import tradeshowSessions from "./tradeshowSessions";
import tradeshowVirtual from "./tradeshowVirtual";
import activeSession from "./activeSession";
import tasks from "./tasks";
import products from "./products";
import distributors from "./distributors";
import services from "./services";
import subscriptions from "./subscriptions";
import homes from "./homes";

export default combineReducers({
  user,
  agreements,
  education,
  activeEducation,
  notifications,
  grid,
  carousel,
  feed,
  activeNews,
  events,
  activeEvent,
  marketing,
  account,
  subscriptions,
  homes,
  distributors,
  creditCards,
  bankAccounts,
  orders,
  payments,
  tasks,
  products,
  services,
  activeOrder,
  activeOrderPDF,
  addresses,
  contacts,
  activeContact,
  serials,
  credits,
  invoices,
  documents,
  knowledge,
  activeArticle,
  rma,
  cases,
  content,
  activeCase,
  latestSoftware,
  software,
  beta,
  profileRequests,
  tradeshowSessions,
  activeSession,
  tradeshowVirtual,
  loading,
  errors,
  success,
  toast,
  server,
});

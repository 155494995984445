import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaCaretDown } from "react-icons/fa";
import styled from "styled-components";

const SavDropToggle = styled.div`
  background-color: ${(props) =>
    props.style ? props.style.backgroundColor : "#f9f9f9"};
  padding: ${(props) => (props.style ? props.style.padding : "8px")}
  color: ${(props) => (props.style ? props.style.color : "#f9f9f9")};
  width: ${(props) => (props.style ? props.style.width : "100%")};
  height: ${(props) => (props.style ? props.style.height : "auto")}
  border: ${(props) => (props.style ? props.style.border : "none")}
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height:28px;
  padding:5px;
`;

const SavDrop = (props) => {
  const { val, opts, click, style } = props;
  return (
    <Dropdown className="savDrop">
      <Dropdown.Toggle id="dropdown-basic" as="div">
        <SavDropToggle style={style}>
          <span>{val}</span>
          <FaCaretDown />
        </SavDropToggle>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "100%" }}>
        {opts.map((o) => {
          return (
            <Dropdown.Item
              as="div"
              href="#"
              key={o}
              name={o}
              onClick={() => click(o)}
              className="onHov"
              style={{ borderBottom: "1px solid #DBD9D6" }}
            >
              <span style={{ display: "inline-flex" }}>{o}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SavDrop;

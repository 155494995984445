import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import book from '../../../fonts/GothamBook.ttf';
import med from '../../../fonts/GothamMedium.woff';
import {parseCurrency} from "../../../components/Utilities"
import _ from "lodash";
Font.register({ family: 'book', src: book });
Font.register({ family: 'med', src: med });

const styles = StyleSheet.create({
  page: {
    padding: '5%',
    fontSize: 10,
    color: '#565551',
  },
  pageTitle: {
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'med',
    fontSize: 14,
    textTransform: 'uppercase',
    letterSpacing: '.5pt',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 20,
    right: '5%',
    fontSize: 10,
    fontFamily: 'book',
  },
  logoBottom: {
    width: 120,
    height: 20,
    marginBottom: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    fontSize: 26,
    fontFamily: 'med',
    textTransform: 'uppercase',
    color: '#696057',
    textAlign: 'center',
    letterSpacing: 1,
    // position: 'absolute',
    // top: '5%',
    // left: '5%',
  },
  headerRight: {
    fontSize: 26,
    fontFamily: 'med',
    textTransform: 'uppercase',
    color: '#696057',
    alignSelf: 'flex-end'
    // position: 'absolute',
    // top: '5%',
    // right: '5%',
  },
  headerText: {
    fontSize: 10,
    fontFamily: 'book',
    color: '#565551',
    textAlign: 'center',
    marginTop: 3,
  },
  headerTextLeft: {
    fontSize: 10,
    fontFamily: 'book',
    color: '#565551',
    textAlign: 'left'
  },
  headerTextRight: {
    fontSize: 10,
    fontFamily: 'book',
    color: '#565551',
    textAlign: 'right'
  },
  headerText2: {
    fontSize: 10,
    fontFamily: 'book',
    color: '#565551',
  },
  centerImage: {
    width: '100%',
    alignItems: 'center',
    flexGrow: 1,
    position: 'absolute',
    bottom: 20,
    marginTop:10,
  },
  topHeader: {
    fontFamily: 'book',
    fontSize: 10,
    position: 'absolute',
    top: 20,
    width: '100%',
  },
  topLeft: {
    position: 'absolute',
    left: '5%',
  },
  topRight: {
    position: 'absolute',
    right: '5%',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  th: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    fontFamily: 'med',
    fontSize: 9,
    textTransform: 'uppercase',
    padding: '5pt',
    border: '.5pt',
    borderColor: '#fff',
  },
  th2: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    fontFamily: 'med',
    fontSize: 6,
    padding: '4pt',
    border: '.5pt',
    borderColor: '#fff',
  },
  td: {
    padding: '5pt',
    border: '.5pt',
    borderColor: '#3f3f3f',
    fontFamily: 'book',
    color: '#565551'
  },

})

const PaymentPDF = ({paymentResponse, invoices, account, creditCards, bankAccounts, credits}) => {
  const todayDate = new Date().toLocaleDateString();

  const renderLastFour = (method) => {
    const cardFound = _.find(creditCards, (c) => c.sfid === method.sfid);
    const bankFound = _.find(bankAccounts, (b) => b.sfid === method.sfid);
    const creditFound = _.find(credits, (c) => c.sfid === method.sfid);

    if (cardFound){
      return cardFound.lastfour;
    }
    if (bankFound){
      return bankFound.accountLast4;
    }
    if (creditFound){
      return creditFound.creditMemoNumber; 
    }
  } 

  const renderMethods = () => {
    return paymentResponse.paymentMethods.map((method) => {
      return (
        <View key={method.sfid}>
          <Text>{method.type} ({renderLastFour(method)})</Text>
        </View>
      )
    });
  };
  const renderAmounts = () => {
    return paymentResponse.paymentMethods.map((method) => {
      return (
        <View key={method.sfid}>
          <Text>{parseCurrency(method.amount)}</Text>
        </View>
      )
    });
  };

  const renderAmountPaid = (invoice) => {
    if (paymentResponse.invoices.length === 1){
      if (paymentResponse.paymentMethods.length > 0){
        return parseCurrency(paymentResponse.paymentMethods[0].amount);
      }
    }
    return "PLACEHOLDER"
  }

  const renderInvoices = () => {
    return paymentResponse.invoiceData.map((invoice) => {
        return (
          <View style={styles.tableHeader} key={invoice.sfid}>
            <View style={[styles.td, {width: '40%'}]}>
              <Text>#{invoice.invoiceName}</Text>
            </View>
            <View style={[styles.td, {width: '20%'}]}>
              <Text>{parseCurrency(invoice.balance)}</Text>
            </View>
            <View style={[styles.td, {width: '40%'}]}>
              <Text>{invoice.note}</Text>
            </View>
          </View>
        )
    })
  }

  return (
    <Document title={`Payment Receipt ${paymentResponse.name}`}>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>

          <View style={{width: 120}}>
            <Text style={styles.headerLeft}>
              Savant
            </Text>
            <Text style={[styles.headerText, {marginTop: 10}]}>
              45 Perserverance Way
            </Text>
            <Text style={styles.headerText}>
              Hyannis, MA 02601
            </Text>
            <Text style={styles.headerText}>
              (508) 683-2500
            </Text>
            <Text style={styles.headerText}>
              www.savant.com
            </Text>
          </View>

          <View style={{width: 260, display: 'flex'}}>

            <Text style={styles.headerRight}>
              Payment Receipt
            </Text>

            <View style={{width: 120, alignSelf: 'flex-end', marginTop: 5}}>
              <View style={styles.th}>
                <Text>Date Received</Text>
              </View>
              <View style={styles.td}>
                <Text>{todayDate}</Text>
              </View>
            </View>
            <View style={{width: 120, alignSelf: 'flex-end'}}>
              <View style={styles.th}>
                <Text>Receipt Number</Text>
              </View>
              <View style={styles.td}>
                <Text>{paymentResponse.name}</Text>
              </View>
            </View>
          </View>

        </View>

        <View style={{marginTop: 20}}>
          <View style={styles.tableHeader}>
            <View style={[styles.th, {width: '40%'}]}>
              <Text>Received From</Text>
            </View>
            <View style={[styles.th, {width: '30%'}]}>
              <Text>Payment Method</Text>
            </View>
            <View style={[styles.th, {width: '30%'}]}>
              <Text>Payment Amount</Text>
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={[styles.td, {width: '40%'}]}>
              <Text>{account.dba || account.name}</Text>
              <Text>{account.phone || ''}</Text>
            </View>
            <View style={[styles.td, {width: '30%'}]}>
              {renderMethods()}
            </View>
            <View style={[styles.td, {width: '30%'}]}>
              {renderAmounts()}
            </View>
          </View>
        </View>

        <View style={{marginTop: 20}}>
          <View style={styles.tableHeader}>
            <View style={[styles.th, {width: '40%'}]}>
              <Text>Invoice</Text>
            </View>
            <View style={[styles.th, {width: '20%'}]}>
              <Text>Balance</Text>
            </View>
            <View style={[styles.th, {width: '40%'}]}>
              <Text>Amount Paid</Text>
            </View>
          </View>
          {renderInvoices()}
        </View>

      </Page>
    </Document>
  )
  

}

export default PaymentPDF;
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import SavColumnSort from "../../components/SavColumnSort";
import SavPagination from "../../components/SavPagination";
import SavDrop from "../../components/SavDrop";
import {
  SubHeader,
  SavSearch,
  SavRow,
  SavSpanName,
  SavLink,
  SavLabel,
} from "../../components/Styled";
import { fetchFeed } from "../../actions";

const HeaderTextOverlay = styled.div`
  position: absolute;
  width: 95%;
  bottom: 0;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
`;


const NewsDetail = ({feed, fetchFeed, match}) => {
  const [newsId, setNewsId] = useState(match.params.newsId);
  const [news, setNews] = useState(null);

  useEffect(() => {
    document.title = "Savant Community | News Detail";
    window.scrollTo(0, 0);
  },[])

  useEffect(() => {
    if (_.isEmpty(feed)) {
      fetchFeed();
    } else {
      feed.forEach((f) => {
        if (f.sfid === newsId) {
          setNews(f);
        }
      });
    }
  }, [feed, newsId]);

  const createMarkup = richText => {
    if (richText) {
      return { __html: richText };
    }
  };


  if (news){
    return (
      <div>
        <SavBanner text="Savant News" padding="40px" />
        <Container style={{ marginTop: 40 }}>
          <div style={{ marginTop: 20 }}>
            <Link className="hov oj med f12 linkHover" to="/news">
              &lt; BACK TO NEWS
            </Link>
          </div>
          <div
            className="m-t-30"
            style={{maxWidth: 800, margin: '30px auto 0px'}}
          >
            <div className="med f24 text-center m-b-20">{news.title}</div>
            <img src={news.image} width="100%" />
            {/* <div className="event-img-container" style={{cursor:"default", height: 300}}>
              <span

                style={{
                  backgroundImage: `url(${news.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  display: "block",
                }}
              />
            </div> */}
            {/* <HeaderTextOverlay className="f24 med text-center text-uppercase wht">
              {news.headerText}
            </HeaderTextOverlay> */}
          </div>
          <div
            style={{maxWidth: 800, margin: '20px auto'}}
            dangerouslySetInnerHTML={createMarkup(
              news.bodyText
            )}
          />
        </Container>
      </div>
    )
  }
  return null;
  
}

const mapStateToProps = (state) => {
  return {
    feed: state.feed.data,
    activeNews: state.activeNews,
  };
};
export default connect(mapStateToProps, {fetchFeed})(NewsDetail);
import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineWifi} from 'react-icons/ai';
import { MdHelp} from "react-icons/md";
import ReactTooltip from "react-tooltip";
import {parseCurrency} from "../../../components/Utilities";
import essentials from "../../../images/SCS/essential-plan.svg";
import {DataCard, DataCardHeader, DataCardFooter, DataCardNumber} from "./styles"
const hidden = {display: 'none'};

const SubscriptionCards = ({account, activeEssentials, activeNetwork, expiredSubs, totalSales, virInc, activeLegacy }) => {

  let subsTotal = 0;
  account.salesProgramData.forEach((p) => {
    subsTotal += p.subscriptionSales;
  })

  return (
    <Row className="m-b-20">
      <Col md={6} lg={account.type === 'Non-Direct' ? 6 : 4}>
        <DataCard>
          <DataCardHeader>
            <Image src={essentials} height={30} width={30} style={{marginRight: 5}} />
            Active Savant Essentials
          </DataCardHeader>
          <div style={{display:'flex', justifyContent: 'space-evenly', paddingTop: 10}}>
          <DataCardNumber className="oj">
            <div className="med oj" style={{lineHeight: 1}}>
              {activeEssentials.length}
            </div>
            
            <div className="book f12 ">Renewing</div>
          </DataCardNumber>
          <DataCardNumber className="oj">
            <div className="med oj" style={{lineHeight: 1}}>
              {activeLegacy.length}
            </div>
            <div className="book f12 ">Non-Renewing</div>
          </DataCardNumber>
          </div>
          
          <DataCardFooter style={{paddingTop: 0}}>
            Homes
          </DataCardFooter>
        </DataCard>
      </Col>

      <Col md={6} lg={account.type === 'Non-Direct' ? 6 : 4}>
        <DataCard>
          <DataCardHeader>
            <AiOutlineWifi size={30} style={{fill: '#4cd964', marginRight: 5}} />
            Active Smart Network
          </DataCardHeader>
          <DataCardNumber className="oj">
            {activeNetwork.length}
          </DataCardNumber>
          <DataCardFooter>
            Homes
          </DataCardFooter>
        </DataCard>
      </Col>

      <Col md={6} lg={account.type === 'Non-Direct' ? 6 : 4}>
        <DataCard>
          <DataCardHeader>
            Expired
            <ReactTooltip id="inactive" className="tooltip-issuetype" effect='solid'>
              <p className="wht" style={{paddingTop: 15}}>Homes with previously active subscriptions that have expired.</p>
            </ReactTooltip>
            <MdHelp size={25}
              style={{ color: "#fff", position: 'absolute', right: 0, top: -5, textTransform: 'none', cursor: 'help' }}
              data-tip data-for="inactive"
            />
          </DataCardHeader>
          <DataCardNumber className={expiredSubs.length > 0 ? 'red' : 'light-gray'}>
            {expiredSubs.length} 
          </DataCardNumber>
          <DataCardFooter>
            Homes
          </DataCardFooter>
        </DataCard>
      </Col>

      <Col md={6} lg={account.type === 'Non-Direct' ? 6 : 4}>
        <DataCard>
          <DataCardHeader style={{fontSize: 14, paddingBottom: 16 }}>
            Renewing Subscription Sales
          </DataCardHeader>
          <DataCardNumber className="green">
            {account.type === 'Non-Direct' ? parseCurrency(subsTotal) : parseCurrency(totalSales)}
          </DataCardNumber>
          <DataCardFooter>
            All Time
          </DataCardFooter>
        </DataCard>
      </Col>

      <Col md={6} lg={4} style={account.type === 'Non-Direct' ? hidden : null}>
        <DataCard>
          <DataCardHeader style={{fontSize: 14, paddingBottom: 16 }}>
            Renewing Subscription Rebate
            <ReactTooltip id="rebate" className="tooltip-issuetype" effect='solid'>
              <p className="wht" style={{paddingTop: 15}}>Includes both rebates paid and pending.</p>
            </ReactTooltip>
            <MdHelp size={25}
              style={{ color: "#fff", position: 'absolute', right: 0, top: -5, textTransform: 'none', cursor: 'help' }}
              data-tip data-for="rebate"
            />
          </DataCardHeader>
          <DataCardNumber className="green">
            {parseCurrency(virInc)}
          </DataCardNumber>
          <DataCardFooter>
            All Time
          </DataCardFooter>
        </DataCard>
      </Col>

      <Col md={6} lg={4} style={account.type === 'Non-Direct' ? hidden : null}>
        <DataCard>
          <DataCardHeader style={{fontSize: 14, paddingBottom: 16 }}>
            Renewing Rebate Percentage
            <ReactTooltip id="ambassador" className="tooltip-issuetype" delayHide={500} delayUpdate={500} effect='solid'>
              <p className="wht">This could be higher!</p>
              <p className="wht">Savant Ambassadors qualify for a 30% rebate on all subscriptions.</p>
              <p className="text-center">
                <Link to="/account#ambassador" className="oj med u">Click here to learn more</Link>
              </p>
            </ReactTooltip>
          {account.ambassadorActive ? null : <MdHelp size={25}
              style={{ color: "#fff", position: 'absolute', right: 0, top: -5, textTransform: 'none', cursor: 'help' }}
              data-tip data-for="ambassador"
            />}
          </DataCardHeader>
          <DataCardNumber className={account.ambassadorActive ? 'green' : 'wip'}>
            {account.ambassadorActive ? '30%' : '20%'}
          </DataCardNumber>
          <DataCardFooter>
            Current 
          </DataCardFooter>
        </DataCard>
      </Col>
    </Row>
  )

}

export default SubscriptionCards;
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import _ from "lodash";
import { fetchArticle } from "../../actions";
import { Link } from "react-router-dom";
import SavBanner from "../../components/SavBanner";

const Article = props => {
  const { article, fetchArticle } = props;
  const articleId = props.match.params.articleId;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("frame")){
    window.scroll(0, 220);
  }

  useEffect(() => {
    document.title = "Savant Community | Knowledge Article"
    fetchArticle(articleId);
  },[])

  const createMarkup = richText => {
    if (richText) {
      return { __html: richText};
    }
  };

  const renderArticle = () => {
    if (!_.isEmpty(article)) {
      if (article.data.articles[0].body) {
        return (
          <Col
            className="articleStyle"
            xs={12}
            dangerouslySetInnerHTML={createMarkup(
              article.data.articles[0].body
            )}
          ></Col>
        );
      }
      if (article.data.articles[0].url) {
        return (
          <div>
            <a href={article.data.articles[0].url} target="_blank">
              Click here to View/Download PDF
            </a>
          </div>
        );
      }
    }
    return null;
  };

  if (!_.isEmpty(article)) {
    return (
      <div>
        <SavBanner text="Savant Knowledge" padding="40px" />
        <Container>
          <div style={{ marginTop: 30 }}>
            <Link className="hov oj med f12" to="/knowledge/">
              &lt; BACK TO KNOWLEDGE
            </Link>
          </div>
          <Row style={{ marginTop: 30 }}>{renderArticle()}</Row>
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    article: state.activeArticle
  };
};

export default connect(mapStateToProps, { fetchArticle })(Article);

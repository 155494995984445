import {
  FETCH_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS
} from "../actions/types";
import { success } from "./utlities";

const account = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SUCCESS:
      return success(state, action);
    case UPDATE_ACCOUNT_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default account;

import React from "react";
import SavBanner from "../../components/SavBanner";
import { Nav } from "react-bootstrap";
import { useRouteMatch, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {visibilitySelector} from "../../selectors";
import {
  FaFileInvoiceDollar,
  FaUsersCog,
  FaChartBar
} from "react-icons/fa";
import {GoPackage} from 'react-icons/go'
import { MdVerifiedUser} from 'react-icons/md'
import {IoMdSettings, IoMdDocument} from 'react-icons/io';

const tabsStyle = {
  fontSize: 14,
  backgroundColor: "#3f3f3f",
  textAlign: "center",
};
const tabActive = {
  color: "#ff5f00"
};
const tabActiveMobile = {
  color: "#ff5f00",
  paddingLeft: 15,
  paddingRight: 15,
}
const tabInactiveMobile = {
  paddingLeft: 15,
  paddingRight: 15,
}
const iconActive = {
  fill:"#ff5f00",
  marginBottom: 10,
};
const iconWhite = {
  fill:'#fff',
  marginBottom: 10,
};
const hidden = {
  display:'none'
}

const AccountNav = ({hash, location, history}) => {
  let { path } = useRouteMatch();
  const refURL = localStorage.getItem("ref");

  const showOrders = useSelector(visibilitySelector('account_orders'));
  const showSubscriptions = useSelector(visibilitySelector('account_subscriptions'));
  const showContacts = useSelector(visibilitySelector('account_contacts_personnel'));
  const showBilling = useSelector(visibilitySelector('account_billing'));
  const showDocuments = useSelector(visibilitySelector('account_documents'));
  const showDashboard = useSelector(visibilitySelector('account_dashboard'));
  const showWarranty = useSelector(visibilitySelector('account_assets'));

  //const showSettings = useSelector(visibilitySelector('account_settings_details_company'));
  const showSettingsDetailsCompany = useSelector(visibilitySelector('account_settings_details_company'));
  const showSettingsDetailsStatus = useSelector(visibilitySelector('account_settings_details_status'));
  const showSettingsSettings = useSelector(visibilitySelector('account_settings_settings'));
  const showSettingsAddresses = useSelector(visibilitySelector('account_settings_addresses'));
  const showSettingsCreditCards = useSelector(visibilitySelector('account_settings_creditCards'));
  const showSettingsScm = useSelector(visibilitySelector('account_settings_scm'));
  const showSettingsListings = useSelector(visibilitySelector('account_settings_savant_com_listings'));
  const showTax = useSelector(visibilitySelector('account_documents_tax'));
  const showAgreements = useSelector(visibilitySelector('account_documents_agreements'));

  const hideSettings = !showSettingsDetailsCompany && !showSettingsDetailsStatus && !showSettingsSettings && !showSettingsAddresses && !showSettingsCreditCards && !showSettingsScm && !showSettingsListings;

  
  
  const overviewHashes = ["", "#integrator", "#vir", "#ambassador"];

  if (hash=== "" && !showDashboard && !hideSettings){
    history.push('/account#settings')
  }
  if (hash=== "" && !showDashboard && hideSettings && showWarranty){
    history.push('/account#warranty')
  }
  if (hash=== "" && !showDashboard && hideSettings && !showWarranty && showContacts){
    history.push('/account?tab=personnel#contacts')
  }

  return (
    <div>
      <SavBanner text="My Account" />
      <Nav style={tabsStyle} className=" justify-content-between justify-content-md-center">

        <Nav.Item style={showDashboard ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account#overview`}
            style={hash === "#overview" ? tabActive : null}
          >
            Overview
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account#overview`}
            style={hash === "#overview" ? tabActiveMobile : tabInactiveMobile }
          >
            <FaChartBar style={hash === "#overview" ? iconActive: iconWhite} size={18} />
          </NavLink>
        </Nav.Item>

        

        <Nav.Item style={showContacts ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account?tab=personnel#contacts`}
            style={hash === "#contacts" ? tabActive : null}
          >
            Contacts
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account?tab=personnel#contacts`}
            style={hash === "#contacts" ? tabActiveMobile : tabInactiveMobile}
          >
            <FaUsersCog style={hash === "#contacts" ?iconActive:iconWhite} size={18} />
          </NavLink>
        </Nav.Item>

        <Nav.Item style={showSubscriptions ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account#subscriptions`}
            style={hash === "#subscriptions" ? tabActive : null}
          >
            Subscriptions
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account#subscriptions`}
            style={hash === "#subscriptions" ? tabActiveMobile : tabInactiveMobile}
          >
            <FaUsersCog style={hash === "#subscriptions" ?iconActive:iconWhite} size={18} />
          </NavLink>
        </Nav.Item>

        

        <Nav.Item style={showOrders ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account#orders`}
            style={hash === "#orders" ? tabActive : null}
            exact
          >
            Orders
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account#orders`}
            style={hash === "#orders" ? tabActiveMobile : tabInactiveMobile}
            exact
          >
            <GoPackage style={hash === "#orders" ? iconActive: iconWhite} size={18} />
          </NavLink>
        </Nav.Item>

        <Nav.Item style={showBilling ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account?tab=invoices#billing`}
            style={hash === "#billing" ? tabActive : null}
          >
            Billing
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account?tab=invoices#billing`}
            style={hash === "#billing" ? tabActiveMobile : tabInactiveMobile}
          >
            <FaFileInvoiceDollar size={18} style={hash === "#billing" ?iconActive:iconWhite} />
          </NavLink>
        </Nav.Item>

        <Nav.Item style={showDocuments ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={showAgreements ? `/account?tab=agreements#documents` : `/account?tab=taxcerts#documents`}
            style={hash === "#documents" ? tabActive : null}
          >
            Documents
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={showAgreements ? `/account?tab=agreements#documents` : `/account?tab=taxcerts#documents`}
            style={hash === "#documents" ? tabActiveMobile : tabInactiveMobile}
          >
            <IoMdDocument size={18} style={hash === "#documents" ?iconActive:iconWhite} />
          </NavLink>
        </Nav.Item>

        <Nav.Item style={showWarranty ? null : hidden}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account#warranty`}
            style={hash === "#warranty" ? tabActive : null}
          >
            Warranty
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account#warranty`}
            style={hash === "#warranty" ? tabActiveMobile : tabInactiveMobile}
          >
            <MdVerifiedUser size={18} style={hash === "#warranty" ?iconActive:iconWhite} />
          </NavLink>
        </Nav.Item>
        <Nav.Item style={hideSettings ? hidden : null}>
          <NavLink
            className="tab-link d-none d-md-block"
            to={`/account?tab=details#settings`}
            style={hash === "#settings" ? tabActive : null}
          >
            Settings
          </NavLink>
          <NavLink
            className="tab-link d-block d-md-none"
            to={`/account#settings`}
            style={hash === "#settings" ? tabActiveMobile : tabInactiveMobile}
          >
            <IoMdSettings size={18} style={hash === "#settings" ?iconActive:iconWhite} />
          </NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default AccountNav;

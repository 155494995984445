import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Container, Popover, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import styled from "styled-components";
import moment from 'moment';
import { Link } from "react-router-dom";
import {FiAward} from 'react-icons/fi';
import {FaLevelUpAlt, FaHandsHelping, FaUniversity} from 'react-icons/fa';
import { MdArrowUpward, MdInsertInvitation} from 'react-icons/md';
import {AiOutlinePicture} from 'react-icons/ai';
import {GiReceiveMoney} from 'react-icons/gi';
import {TbDiscount2} from 'react-icons/tb';
import AnnualPerformance from "../../../components/AccountCharts/AnnualPerformance";
import SalesYearToDate from "../../../components/AccountCharts/SalesYearToDate";
import VIRProgressChart from "../../../components/AccountCharts/VIRProgress";
import IntegratorProgress from "../../../components/AccountCharts/IntegratorProgress";
import AdoptionProgress from "../../../components/AccountCharts/AdoptionProgress";
import AdoptionProgressQual from "../../../components/AccountCharts/AdoptionProgressQual";
import DoneIcon from "../../../images/Orders/done.svg";
import WipIcon from "../../../images/Orders/wip.svg";
import ErrorIcon from "../../../images/Orders/error.svg";
import {createMarkup, parseCurrency} from "../../../components/Utilities";
import { fakeQuarter } from "./placeholders";
import { ItemHeader, GridItem, DetailButton, DetailButtonDiv, DetailLabel, Congrats, AmbassadorBadge, AmbassadorOpacity, AmbassadorBottom, StatusRow, AmbassadorPercent, ProgramIconDiv, GoalHeader, AmbCardBottomGrid, } from "../styles";
import Azione from "../../../images/Programs/Azione.png";
import Bravas from "../../../images/Programs/Bravas.png";
import Guild from "../../../images/Programs/Guild.png";
import HTA from "../../../images/Programs/HTA.png";
import ProSource from "../../../images/Programs/ProSource.png";
import Ambassador from "../../../images/AmbassadorBadges/Ambassador.png";
import Council from "../../../images/Programs/council.png";
import Contractor from "../../../images/AmbassadorBadges/Contractor.svg";
import IntegratorHistory from "../../../components/AccountCharts/IntegratorHistory";
import Authorized from "../../../images/AmbassadorBadges/Authorized.png";
import Silver from "../../../images/AmbassadorBadges/Silver.png";
import Gold from "../../../images/AmbassadorBadges/Gold.png";
import Platinum from "../../../images/AmbassadorBadges/Platinum.png";
import PlatinumElite from "../../../images/AmbassadorBadges/PlatinumElite.png";
import AmbassadorIcon from "../../../images/AmbassadorBadges/Ambassador.png";


const Header = styled.div`
  font-family: "Gotham-Medium";
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
  font-size:20px;
`;
const SubHeader = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-family: "Gotham-Medium";
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #b4b4b452;
`;
const CurrentBadge = styled.img`
  width: 150px;
  border: 5px solid #9b9b9b;
  border-radius: 4px;
`;
const AwardRow = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 10% 90%;
  border-top:1px solid #b4b4b452;
  padding: 12px 8px;
`;
const AwardRowAmb = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 15% 85%;
  padding: 10px;
  font-size:12px;
  border-bottom:1px solid #b4b4b452;
`;
const AmbassadorParent = styled.div`
  position:relative;
  // cursor:pointer;
  text-align:center;
  width:150px;
  margin: 0 auto;
`;
const Card = styled.div`
  background-color:#f9f9f9;
  padding:10px;
`;
const adoptBorder = {
  borderLeft: "2px solid #fff",
  borderRight:"2px solid #fff"
}

const NewDashboard = ({account, integratorCurrent, ambassadorCurrent}) => {

  const [qualCount, setQualCount] = useState(0);
  const [betaParticipation, setBetaParticipation] = useState(0);
  const [leadsWithSavant, setLeadsWithSavant] = useState(0);
  const [tier, setTier] = useState(0);
  const [avsTotal, setAvsTotal] = useState(0);
  const [elscTotal, setElscTotal] = useState(0);
  const [uiTotal, setUiTotal] = useState(0);
  const [opacityHeight, setOpacityHeight] = useState(0);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if(!_.isEmpty(ambassadorCurrent)){
      const today = new Date();
      const quarter = Math.floor(today.getMonth() / 3) + 1;
      const startDateMoment = moment();
      const firstQuarter = startDateMoment.clone().subtract(3, 'quarters');
      const year = today.getFullYear();
      let qualCountTemp = 0;
      let betaParticipationTemp = 0;
      let leadsWithSavantTemp = 0;
      let tierTemp = 0;

      if (ambassadorCurrent.leadsWithSavant){
        qualCountTemp += 1;
        leadsWithSavantTemp = 100;
      };
      if (ambassadorCurrent.isBeta) {
        qualCountTemp += 1;
        betaParticipationTemp = 100;
      };
      if (eligibleTiers.includes(ambassadorCurrent.tierEarned)) {
        qualCountTemp +=1;
        tierTemp = 100;
      };
      if (ambassadorCurrent.adoptionAudioVideoSpeaker >= 20000) {
        qualCountTemp +=1;
      };
      if (ambassadorCurrent.adoptionELSClimate >= 20000) {
        qualCountTemp +=1;
      };
      if (ambassadorCurrent.adoptionUiHstLicCtrl >= 10000) {
        qualCountTemp +=1;
      };
      setQualCount(qualCountTemp);
      setBetaParticipation(betaParticipationTemp);
      setLeadsWithSavant(leadsWithSavantTemp);
      setTier(tierTemp);
      setAvsTotal(ambassadorCurrent.adoptionAudioVideoSpeaker);
      setElscTotal(ambassadorCurrent.adoptionELSClimate);
      setUiTotal(ambassadorCurrent.adoptionUiHstLicCtrl);
      const warnings = [];

      if (!ambassadorCurrent.leadsWithSavant){
        const awardRow = (
          <AwardRowAmb key="leads">
            <img src={ErrorIcon} width="25px" height="25px" alt="error" />
            <div className="f12">
              Speak with your Savant Sales Representative for ways to Lead with Savant.
            </div>
          </AwardRowAmb>
        )
        warnings.push(awardRow);
      }
      if (!ambassadorCurrent.isBeta){
        const awardRow = (
          <AwardRowAmb key="beta">
            <img src={ErrorIcon} width="25px" height="25px" alt="error" />
            <div className="f12">
              Active hardware or software beta participation is required for Ambassador qualification.
            </div>
          </AwardRowAmb>
        )
        warnings.push(awardRow);
      }
      if (!eligibleTiers.includes(ambassadorCurrent.tierEarned)){
        const awardRow = (
          <AwardRowAmb key="tier">
            <img src={ErrorIcon} width="25px" height="25px" alt="error" />
            <div className="f12">
              Speak with your <Link to='/support'>Savant Sales Representative for effective ways to grow your Integrator Tier.</Link>
            </div>
          </AwardRowAmb>
        )
        warnings.push(awardRow);
      }
      setMessages(warnings);


    }
  },[ambassadorCurrent]);


  const getTierBadge = (program) => {
    if (program.tierEarned.toUpperCase() === 'AUTHORIZED') {
      return Authorized;
    }
    if (program.tierEarned.toUpperCase() === 'SILVER') {
      return Silver;
    }
    if (program.tierEarned.toUpperCase() === 'GOLD') {
      return Gold;
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM') {
      return Platinum;
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE') {
      return PlatinumElite;
    }
  };
  const getAccountTierShip = (program) => {
    if (program.tierEarned.toUpperCase() === 'AUTHORIZED') {
      return "Standard shipping rates";
    }
    if (program.tierEarned.toUpperCase() === 'SILVER') {
      return "Free US ground freight on all hardware orders over $5,000";
    }
    if (program.tierEarned.toUpperCase() === 'GOLD') {
      return 'Free US ground freight on all hardware orders over $2,000';
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM' || program.tierEarned.toUpperCase() === 'PLATINUM ELITE') {
      return 'Free US ground freight on all shipments';
    }
  };

  const eligibleTiers = ['Gold', 'Silver', 'Platinum', 'GOLD', 'SILVER', 'PLATINUM', 'Platinum Elite', 'PLATINUM ELITE'];
  const today = new Date();
  const year = today.getFullYear();

  const renderEliteRewards = (program) => {
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE'){
      return (
        <Fragment>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Sponsored Specifier Event</div>
            </div>
          </AwardRow>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Demo Product Provision for your Team<br />$7,500 @ Dealer Cost</div>
            </div>
          </AwardRow>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Premier Savant.com Listing (Starting in mid-2023)</div>
            </div>
          </AwardRow>
        </Fragment>
      )
    }
    return null;
  }

  const renderEliteDownload = (program) => {
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE'){
      return (
        <Row className="m-t-40">
          <Col xs={12} className="text-center">
            <a href="https://sweb-img.s3.amazonaws.com/Community/PDFs/Platinum%20Elite%20One%20Sheet%202022.pdf" target="_blank" rel="noreferrer">
              <button className="sav-btn__orange--fill">Download Platinum Elite Onesheet</button>
            </a>
            
          </Col>
        </Row>
      )
    }
    return null;
  }

  const renderTierRow = (program) => {
    return (
      <Row className="m-t-20">
        <Col md={4}>
          <Card style={{minHeight:437}}>
            {/* <SubHeader>{program.year > year ? "Projected" : "Active" } {program.year} Tier</SubHeader> */}
            <SubHeader>Active Tier</SubHeader>
            <div className="text-center m-b-30 m-t-30">
              <CurrentBadge src={getTierBadge(program)} />
            </div>
            <SubHeader>Rewards</SubHeader>
            <AwardRow style={{borderTop:"none"}}>
              <FiAward size={20} fill="#4cd964" />
              <div>
                <div className="f12 med">{program.tierEarned.toUpperCase() === 'AUTHORIZED' ? '40%' : program.tierEarned.toUpperCase() === 'PLATINUM ELITE' ? '50%' : '45%'} off MSRP</div>
                <div className="light-gray f10">
                  *Excluding non-discountable or promotional products
                </div>
              </div>
            </AwardRow>
            <AwardRow style={program.tierEarned.toUpperCase === 'AUTHORIZED' ? {display: 'none'} : null}>
              <FiAward size={20} fill="#4cd964" />
              <div>
                <div className="f12 med">Quarterly VIR Eligibility</div>
              </div>
            </AwardRow>
            <AwardRow>
              <FiAward size={20} fill="#4cd964" />
              <div className="f12 med">{getAccountTierShip(program)}</div>
            </AwardRow>
            {renderEliteRewards(program)}
          </Card>
        </Col>
        <Col md={8}>
          <Row>
            <Col xs={12}>
              <Card>
                <SubHeader>Progress</SubHeader>
                <IntegratorProgress account={account} programResult={program} />
              </Card>
            </Col>
          </Row>

          <Row className="m-t-20">
            <Col xs={12}>
              <Card>
                <SubHeader>Annual Performance</SubHeader>
                <AnnualPerformance account={account} programResult={program} />
              </Card>
            </Col>
          </Row>

          {renderEliteDownload(program)}
        </Col>
      </Row>
    )
  }

  const renderIcon = (type) => {
    if (ambassadorCurrent.earnedAmbassador){
      return <img src={DoneIcon} style={{width:25, height:25}} />;
    }
    if (type === 'status') {
      if (eligibleTiers.includes(ambassadorCurrent.tierEarned)) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={ErrorIcon} style={{width:25, height:25}} />;
      }
    }
    if (type === 'beta') {
      if (ambassadorCurrent.isBeta) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={ErrorIcon} style={{width:25, height:25}} />;
      }
    }
    if (type === 'leads') {
      if (ambassadorCurrent.leadsWithSavant) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={ErrorIcon} style={{width:25, height:25}} />;
      }
    }
    if (type === 'avs') {
      if (avsTotal >= 20000) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={WipIcon} style={{width:25, height:25}} />;
      }
    }
    if (type === 'elsc') {
      if (elscTotal >= 20000) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={WipIcon} style={{width:25, height:25}} />;
      }
    }
    if (type === 'ui') {
      if (uiTotal >= 10000) {
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }else {
        return <img src={WipIcon} style={{width:25, height:25}} />;
      }
    }
    return null;
  };
  const renderPercent = (total, goal) => {
    const returnVal = Math.floor((total / goal) * 100);
    if (returnVal > 100){
      return 100;
    }else {
      return returnVal;
    }
  };


  if (!_.isEmpty(account) && !_.isEmpty(integratorCurrent)){

    let tierTemp = tier;
    let betaTemp = betaParticipation;
    let leadsTemp = leadsWithSavant;
    if (ambassadorCurrent.earnedAmbassador){
      tierTemp = 100;
      betaTemp = 100;
      leadsTemp = 100;
    };

    return (
      <Fragment>
        <Header>Integrator Program</Header>

        <Container>
          {renderTierRow(integratorCurrent)}

          <Row className="m-t-20">
            <Col xs={12}>
              <Card>
                <SubHeader>Qualifications</SubHeader>
                <Row className="">
                  <Col lg={3} md={12} style={adoptBorder}>
                    <GoalHeader>Beta Participation</GoalHeader>
                    <AdoptionProgressQual progress={betaTemp} toGo={Math.abs(100 - betaTemp)} text={betaTemp> 0 ? "Complete!" : "Incomplete"} />
                    <AmbCardBottomGrid>
                      <div>
                        {renderIcon('beta')}
                      </div>
                      <div className="f12" style={{paddingLeft:10}}>
                        Hardware or software beta participation in current year.
                      </div>
                    </AmbCardBottomGrid>
                  </Col>
                  <Col lg={3} md={12} style={adoptBorder}>
                    <GoalHeader>Leads With Savant</GoalHeader>
                    <AdoptionProgressQual progress={leadsTemp} toGo={Math.abs(100 - leadsTemp)} text={leadsTemp> 0 ? "Complete!" : "Incomplete"} />
                    <AmbCardBottomGrid>
                      <div>
                        {renderIcon('leads')}
                      </div>
                      <div className="f12" style={{paddingLeft:10}}>
                        Leads with Savant as the primary source of Smart Home control.
                      </div>
                    </AmbCardBottomGrid>
                  </Col>
                  <Col lg={3} md={12} style={adoptBorder}>
                      <GoalHeader>Savant Control</GoalHeader>
                      <AdoptionProgress
                        program={ambassadorCurrent}
                        type="adoptionUiHstLicCtrl"
                        goal={10000}
                        isFuture={false}
                        // text={Math.floor((uiTotal / 10000) * 100) < 100 ? `${Math.floor((uiTotal / 10000) * 100)}%` : "Complete!"}
                        text={parseCurrency(ambassadorCurrent.adoptionUiHstLicCtrl)}
                      />
                      <AmbCardBottomGrid>
                      <div>
                        {renderIcon('ui')}
                      </div>
                      <div className="f12 text-center">
                        {renderPercent(uiTotal, 10000)}% of $10,000 Goal
                      </div>
                    </AmbCardBottomGrid>
                    </Col>
                    <Col lg={3} md={12} style={adoptBorder}>
                      <GoalHeader>Audio, Video & Speakers</GoalHeader>
                      <AdoptionProgress
                        program={ambassadorCurrent}
                        type="adoptionAudioVideoSpeaker"
                        goal={20000}
                        isFuture={false}
                        text={parseCurrency(ambassadorCurrent.adoptionAudioVideoSpeaker)}
                        />
                      <AmbCardBottomGrid>
                      <div>
                        {renderIcon('avs')}
                      </div>
                      <div className="f12 text-center">
                        {renderPercent(avsTotal, 20000)}% of $20,000 Goal
                      </div>
                    </AmbCardBottomGrid>
                    </Col>
                </Row>
              </Card>
            </Col>
          </Row>



            <Row className="m-t-20">
              <Col xs={12}>
                <Card>
                  <SubHeader>History</SubHeader>
                  <IntegratorHistory programData={account.salesProgramData} programResult={integratorCurrent} />
                </Card>
              </Col>
            </Row>
        </Container>
          <div className="text-center light-gray m-t-20 f12">
            *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
          </div>
      </Fragment>
    )
  }
  return null;
    

};

export default NewDashboard;
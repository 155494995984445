import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Container, Collapse, Modal, Form, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import { FaCaretDown } from "react-icons/fa";
import {SavInput} from "../../../components/Styled";
import {parseCurrency, phoneOutput} from "../../../components/Utilities";
import {updateAccount, setSuccessToast, fetchDistributors, updateDistributor, createDistributor, deleteDistributor, createTask, fetchAccount} from "../../../actions";
import {visibilitySelector, createLoadingSelector} from "../../../selectors";
import SavInputPhone from "../../../components/SavInputPhone";
import SavModalWarning from "../../../components/SavModalWarning";
import RequestOpenTermsModal from "./RequestOpenTermsModal";
import SavFormInput from "../../../components/SavFormInput";
import SavFormDrop from "../../../components/SavFormDrop";

const DetailContainer = styled(Container)`
`;

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
`;

const SavLabel = styled.span`
  font-family:"Gotham-Book";
  font-size:12px;
  color:#b4b4b4;
`;

const SettingRow = styled(Row)`
  background-color:#fafaf9;
  padding:15px;
  font-size:12px;
  border-bottom:1px solid rgba(0,0,0,.1);
`;

const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;

const SavLink = styled(Link)`
font-size:10px;
text-transform:uppercase;
color:#ff5f00;
cursor:pointer;
&:hover{
  color:#ff5f00;
}
`;
const SavDropToggle = styled.div`
  background-color: #fff;
  color: #979797;
  width: 100%;
  height: 42px;
  border: 1px solid #979797;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding:12px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const hidden = {
  display:'none'
}
const cancelStyle = {
  width: '100%',
  border: '1px solid #696057',
  marginTop: 5,
}

const DistroEdit = ({distro, updateDistributor, setSuccessToast, deleteDistributor, subType}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [accountNumber, setAccountNumber] = useState(distro.accountNumber || "");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const handleUpdateDistributor = () => {
    const distroJSON = {...distro};
    distroJSON.accountNumber = accountNumber;
    updateDistributor(distroJSON);
    // .then((val) => {
    //   setSuccessToast(true);
    // })
    setOpenEdit(false);
  }

  const handleClickDeleteInModal = () => {
    const distroJSON = {...distro};
    deleteDistributor(distroJSON.sfid);
    setShowDeleteModal(false);
    setOpenEdit(false);
  }

  const handleDeleteDistributor = () => {
    const distroJSON = {...distro};
    deleteDistributor(distroJSON.sfid);
    // .then((val) => {
    //   setSuccessToast(true);
    // })
    setOpenEdit(false);
  }
  return (
    <Fragment>
      <SavModalWarning 
          show={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          title="Delete Distrubutor"
          text="Are you sure you want to delete this distributor?"
          buttonText="delete"
          center
          click={handleClickDeleteInModal}
        />
        <SettingRow style={openEdit ? {borderLeft:"4px solid #ff5f00"}: null}>
          <Col xs={4}>
            <SavLabel>{distro.name}</SavLabel>
          </Col>
          <Col xs={6}>
            {openEdit ? null : `Account Number: ${distro.accountNumber || "Not Provided"}`}
            <Collapse in={openEdit}>
              <div id="openEdit" className="text-center">
                <SavInput value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateDistributor()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenEdit(false)}>Cancel</button>
                <div className="m-t-20 red" style={subType === "International Distribution" ? hidden : null}>
                  <EditSpan onClick={() => setShowDeleteModal(true)}>Delete</EditSpan>
                </div>
              </div>
              
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenEdit(!openEdit)} aria-controls="openDBA" aria-expanded={openEdit}>{openEdit ? "Close" : "Edit"}</EditSpan>
          </Col>
        </SettingRow>
    </Fragment>
    
  )
}


const AccountDetails = props => {
  const { account, user, updateAccount, setSuccessToast, distributors, fetchDistributors, fetchingDistributors, updateDistributor, createDistributor, deleteDistributor, createTask, fetchAccount } = props;

  const [openDBA, setOpenDBA] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [accountDBA, setAccountDBA] = useState(account.dba || "");
  const [accountPhone, setAccountPhone] = useState(account.phone || "");
  const [newDistroNumber, setNewDistroNumber] = useState("");
  const [defDist, setDefDist] = useState("WAVE");
  const [showWaveModal, setShowWaveModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [validated, setValidated] = useState(false);
  const showInfo = useSelector(visibilitySelector('account_settings_details_company'));
  const showStatus = useSelector(visibilitySelector('account_settings_details_status'));
  const showDistributors = useSelector(visibilitySelector('account_settings_details_distributors'));

  useEffect(() => {
    if (_.isEmpty(distributors) && !fetchingDistributors) {
      fetchDistributors();
    }
  }, [distributors]);

  useEffect(() => {
    setValidated(false);
  },[showWaveModal]);

  useEffect(() => {
    if (openDBA){
      setAccountDBA(account.dba);
    }
  },[openDBA]);
  useEffect(() => {
    if (openPhone){
      setAccountPhone(account.phone);
    }
  },[openPhone])
  
  const handleCloseWaveModal = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    const distJSON = {
      name: defDist.name,
      isDefault:true,
      accountNumber: newDistroNumber
    }
    const distId = defDist.sfid
    createDistributor(distJSON, distId);
    setShowWaveModal(false);
  }

  const handleSubmitCreditRequest = () => {
    const taskJSON = {
      taskType: "Credit Limit Increase",
      comments: "User requests a credit limit increase"
    }
    createTask(taskJSON).then(() => {
      fetchAccount();
      setShowWarning(true);
    })
  }

  const handleUpdateAccountDBA = () => {
    const accJSON = {...account};
    accJSON.dba = accountDBA;
    updateAccount(accJSON);
    setOpenDBA(false);
  }

  const handleUpdateAccountPhone = () => {
    const accJSON = {...account};
    accJSON.phone = accountPhone;
    updateAccount(accJSON);
    setOpenPhone(false);
  }

  const renderInformation = () => {
    if (showInfo) {
      return (
        <Fragment>
          <SavModalWarning
            show={showWarning}
            center
            hide={() => setShowWarning(false)}
            title="Credit Limit Increase Request"
            text2="A request to increase your credit limit has been submitted to Savant’s finance team. A representative will be in touch shortly."
            click={() => setShowWarning(false)}
          />
          <SettingRow>
            <Col xs={12}>
              <Header>COMPANY INFORMATION</Header>
            </Col>
          </SettingRow>
          <SettingRow>
            <Col xs={4}>
              <SavLabel>Account Name</SavLabel>
            </Col>
            <Col xs={6}>
              {account.name}
            </Col>
            <Col xs={2}>
            </Col>
          </SettingRow>
          <SettingRow style={openDBA ? {borderLeft:"4px solid #ff5f00"}: null}>
            <Col xs={4}>
              <SavLabel>DBA</SavLabel>
            </Col>
            <Col xs={6}>
              {openDBA ? null : account.dba}
              <Collapse in={openDBA}>
                <div id="openDBA" className="text-center">
                  <SavInput value={accountDBA} onChange={(e) => setAccountDBA(e.target.value)} />
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccountDBA()}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenDBA(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenDBA(!openDBA)} aria-controls="openDBA" aria-expanded={openDBA}>{openDBA ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>
          <SettingRow>
            <Col xs={4}>
            <SavLabel>Account Number</SavLabel>
            </Col>
            <Col xs={6}>
            {account.customerNumber}
            </Col>
            <Col xs={2}>
            </Col>
          </SettingRow>
          <SettingRow style={openPhone ? {borderLeft:"4px solid #ff5f00"}: null}>
            <Col xs={4}>
              <SavLabel>Phone</SavLabel>
            </Col>
            <Col xs={6}>
              {openPhone ? null :  (phoneOutput(account.phone))}
              <Collapse in={openPhone}>
                <div id="openPhone" className="text-center">
                  <SavInput value={accountPhone} onChange={(e) => setAccountPhone(e.target.value)} />
                  {/* <SavInputPhone phoneNumber={accountPhone} setPhoneNumber={setAccountPhone} open={openPhone} /> */}
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccountPhone()}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenPhone(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenPhone(!openPhone)} aria-controls="openPhone" aria-expanded={openPhone}>{openPhone ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>
        </Fragment>
      )
    }
    return null;
  }
  const renderDistributors = (subType) => {
    return account.distributors.map((d) => {
      return (
        <DistroEdit subType={subType} distro={d} key={d.sfid} updateDistributor={updateDistributor} deleteDistributor={deleteDistributor} setSuccessToast={setSuccessToast} />
      )
    })
  }
  const renderWaveModal = () => {

    const disOpts =["WAVE"];
    return (
      <Fragment>
        <button style={{padding: 0, fontSize:30}} className="sav-btn__orange--text" onClick={() => setShowWaveModal(true)}>+</button>
        <Modal show={showWaveModal} onHide={() =>setShowWaveModal(false)} animation={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              Add Distributor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center" style={{margin: "0 auto", width:"80%"}}>
              
              <Form onSubmit={handleCloseWaveModal} noValidate validated={validated}>
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                  <div className="input-container-drop-nofilter">
                    <Dropdown className="savDrop">
                      <Dropdown.Toggle id="dropdown-basic" as="div">
                        <SavDropToggle className={validated && _.isEmpty(defDist) ? "savDrop-invalid" : validated && !_.isEmpty(defDist) ? "savDrop-valid" : null}>
                          <div style={{marginTop:10}}>{defDist['name']}</div>
                          <FaCaretDown />
                        </SavDropToggle>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "100%", transform: "translate(0px,60px)" }}>
                        {distributors.data.map((o) => {
                          return (
                            <Dropdown.Item
                              as="div"
                              href="#"
                              key={o.sfid}
                              name={o.name}
                              onClick={() => setDefDist(o)}
                              className="onHov"
                              style={{ borderBottom: "1px solid #DBD9D6" }}
                            >
                              <span style={{ display: "inline-flex" }}>{o.name}</span>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <label className={defDist && 'filled'}>Distributor Account</label>
                  </div>
                    {/* <SavFormDrop value={defDist} setValue={setDefDist} label="Distributor Account" opts={disOpts} /> */}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={newDistroNumber} setValue={setNewDistroNumber} label="Distributor Account Number"/>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="sav-btn__gray" onClick={() => setShowWaveModal(false)}>
              Close
            </button>
            <button className="sav-btn__orange--fill" onClick={handleCloseWaveModal}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
  const renderDistributorSection = () => {
    if (user && showDistributors){
      if (user.contact.accSubType === "International Distribution"){
        return (
          <Fragment>
            <SettingRow style={{marginTop:20}}>
              <Col xs={12}>
                <Header>DISTRIBUTORS</Header>
              </Col>
            </SettingRow>
            {renderDistributors(user.contact.accSubType)}
          </Fragment>
        )
      }
      return (
        <Fragment>
          <SettingRow style={{marginTop:20}}>
            <Col xs={12}>
              <Header>DISTRIBUTORS</Header>
            </Col>
          </SettingRow>
          {renderDistributors(user.contact.accSubType)}
          <SettingRow style={{padding:0, justifyContent:'center'}}>
            {renderWaveModal()}
          </SettingRow>
        </Fragment>
      )
    }
    return null;
  }

  const renderStatus = () => {
    if (showStatus) {
      const renderRequest = () => {
        if (!account.pendingCreditLineRequest){
          return (
            <RequestOpenTermsModal account={account} createTask={createTask} setSuccessToast={setSuccessToast} fetchAccount={fetchAccount} />
          )
        }
        return <i className="light-gray f10">Request Pending...</i>
      }
      return (
        <Fragment>
          <SettingRow style={{marginTop:20}}>
            <Col xs={12}>
              <Header>ACCOUNT STATUS</Header>
            </Col>
          </SettingRow>
          <SettingRow>
            <Col xs={4}>
              <SavLabel>Account Status</SavLabel>
            </Col>
            <Col xs={6}>
              {account.status=== "Authorized" ? "Certified" : "Not Trained" }
            </Col>
            <Col xs={2}></Col>
          </SettingRow>

          <SettingRow>
            <Col xs={4}>
              <SavLabel>Billing Terms</SavLabel>
            </Col>
            <Col xs={4}>
              {account.defaultBillingTerms}
            </Col>
            <Col xs={4} className="text-right">
              {renderRequest()}
            </Col>
          </SettingRow>
          <SettingRow style={!account.creditLimit ? hidden : null}>
            <Col xs={4}>
              <SavLabel>Credit Limit</SavLabel>
            </Col>
            <Col xs={4}>
              {parseCurrency(account.creditLimit,2)}
            </Col>
            <Col xs={4} className="text-right">
              <EditSpan style={account.canRequestCreditIncrease ? null : {display:'none'}} onClick={() => handleSubmitCreditRequest()}>Request Increase</EditSpan>
            </Col>
          </SettingRow>
          <SettingRow style={{alignItems:"center"}}>
            <Col xs={4}>
              <SavLabel>Sales Tax Certificates</SavLabel>
            </Col>
            <Col xs={6} className="truncate">
              {String(account.activeTaxCertStates)}
            </Col>
            <Col xs={2} className="text-right">
              <SavLink to="/account?tab=taxcerts#documents">MANAGE</SavLink>
            </Col>
          </SettingRow>
        </Fragment>
      )
    }
    return null;
  }

  if (!_.isEmpty(distributors)){
    return (
      <DetailContainer>
        {renderInformation()}
        {renderStatus()}
        {renderDistributorSection()}
      </DetailContainer>
    );
  }
  return null;
  
};

const fetchingDistributors = createLoadingSelector(["FETCH_DISTRIBUTORS"]);

const mapStateToProps = state => {
  return {
    distributors: state.distributors,
    fetchingDistributors: fetchingDistributors(state)
  }
}

export default connect(mapStateToProps, {updateAccount, updateDistributor, setSuccessToast, createDistributor, deleteDistributor, fetchDistributors, createTask, fetchAccount})(AccountDetails);

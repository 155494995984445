import React,{useState, useEffect, Fragment} from "react";
import _ from "lodash";
import { Row, Col, Form } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ReferenceLine } from 'recharts';
import { SubHeader, SavLabel } from "../../../components/Styled";
import {parseCurrency, kFormatter} from "../../../components/Utilities";
import {DataCard} from "./styles"
import SavDrop from "../../../components/SavDrop";
import SavFormDrop from '../../../components/SavFormDrop';

const hidden = {display: 'none'};

const SubscriptionHistory = ({account, historyData, virQ1, virQ1Data, virQ1Network, virQ2, virQ2Data, virQ2Network, virQ3, virQ3Data, virQ3Network, virQ4, virQ4Data, virQ4Network }) => {

  const [year, setYear] = useState("2024");
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [showRebates, setShowRebates] = useState(false);
  const [rebateFilter, setRebateFilter] = useState("Hide");
  const [subsFilter, setSubsFilter] = useState("All")


  const YEARS = ["All", "2023", "2024", "2025"];
  const REBATES = ["Show", "Hide"];
  const SUB_TYPES = ['All', "Savant Essentials", "Smart Network"];

  useEffect(() => {
    setData(historyData);
    setDataFiltered(historyData)
  },[historyData]);

  useEffect(() => {
    if (rebateFilter === 'Show'){
      setShowRebates(true)
    } else {
      setShowRebates(false);
    }
  },[rebateFilter])

  useEffect(() => {
    if (year === "All"){
      setDataFiltered(historyData);
    } else {
      const newData = _.filter(data, (d) => d.year === year);
      setDataFiltered(newData);
    }
  },[year, data]);

  const CustomAxisTickHistory = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-25} y={y+20} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan y={y+4} dx="0.71em" >${kFormatter(payload.value)}</tspan>
      </text>
    )
  }
  const CustomXAxisTickHistory = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-4} y={y} size="10px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:10}}>
        <tspan x={x} dy="0.71em" >{payload.value}</tspan>
      </text>
    )
  }
  const CustomTooltipHistory = ({ active, payload, label }) => {
    if (active) {

      if (subsFilter === 'Savant Essentials'){
        return (
          <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
            <div>
              <span className="book f12">
                New Essentials Subscriptions:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.salesEssential, 2)}
              </span>
            </div>
            <div>
              <span className="book f12">
                Renewal Essentials Subscriptions:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.renewalEssential, 2)}
              </span>
            </div>
            <div>
              <span className="book f12">
                Essentials Attrition:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.expiredEssentials, 2)}
              </span>
            </div>
          </div>
        )
      }
      if (subsFilter === "Smart Network"){
        return (
          <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
            <div>
              <span className="book f12">
                New Network Subscriptions:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.salesNetwork, 2)}
              </span>
            </div>
            <div>
              <span className="book f12">
                Renewal Network Subscriptions:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.renewalNetwork, 2)}
              </span>
            </div>
            <div>
              <span className="book f12">
                Network Attrition:&nbsp;
              </span>
              <span className="med f12">
                {parseCurrency(payload[0].payload.expiredNetwork, 2)}
              </span>
            </div>
          </div>
        )
      }
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <div>
            <span className="book f12">
              New Essentials Subscriptions:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.salesEssential, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              Renewal Essentials Subscriptions:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.renewalEssential, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              New Network Subscriptions:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.salesNetwork, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              Renewal Network Subscriptions:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.renewalNetwork, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              Essentials Attrition:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.expiredEssentials, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              Network Attrition:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.expiredNetwork, 2)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderLines = () => {
    if (showRebates){
      return (
        <Fragment>
          <ReferenceLine x="04 '23" stroke="#4cd964" isFront={true} strokeWidth={2} style={showRebates ? null : hidden}>
            <Label
              position="bottom"
              value={virQ1.virEarned > 0 ? parseCurrency(virQ1.virEarned + virQ1Network.virEarned) : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="07 '23" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ2.virEarned > 0 ? parseCurrency(virQ2.virEarned + virQ2Network.virEarned) : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="10 '23" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ3.virEarned > 0 ? parseCurrency(virQ3.virEarned + virQ3Network.virEarned) : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="01 '24" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ4.virEarned > 0 ? `${parseCurrency(virQ4.virEarned + virQ4Network.virEarned)} (est)` : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="04 '24" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ1.virEarned > 0 ? `${parseCurrency(virQ1.virEarned + virQ1Network.virEarned)} (est)` : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="07 '24" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ2.virEarned > 0 ? `${parseCurrency(virQ2.virEarned + virQ2Network.virEarned)} (est)` : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
          <ReferenceLine x="10 '24" stroke="#4cd964" isFront={true} strokeWidth={2}>
            <Label
              position="bottom"
              value={virQ3.virEarned > 0 ? `${parseCurrency(virQ3.virEarned + virQ3Network.virEarned)} (est)` : "$0" }
              offset={20}
              fontSize={12}
              fill="#4cd964"
            />
          </ReferenceLine>
        </Fragment>
      )
    }
    return null;
  }

  const renderBars = () => {
    if (subsFilter === 'Savant Essentials'){
      return (
        <Fragment>
          <Bar name="New Essentials" dataKey="salesEssential" stackId="b" fill="#3f3f3f" />
          <Bar name="Essentials Renewal" dataKey="renewalEssential" stackId="b" fill="#979797" />
          <Bar name="Essentials Attrition" dataKey="expiredEssentials" stackId="b" fill="#d0021b" />
        </Fragment>
      )
    }
    if (subsFilter === 'Smart Network'){
      return (
        <Fragment>
          <Bar name="New Network" dataKey="salesNetwork" stackId="b" fill="#00C7CC" />
          <Bar name="Network Renewal" dataKey="renewalNetwork" stackId="b" fill="#2E4057" />
          <Bar name="Network Attrition" dataKey="expiredNetwork" stackId="b" fill="#E08F25" />
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Bar name="New Essentials" dataKey="salesEssential" stackId="b" fill="#3f3f3f" />
        <Bar name="Essentials Renewal" dataKey="renewalEssential" stackId="b" fill="#979797" />
        <Bar name="New Network" dataKey="salesNetwork" stackId="b" fill="#00C7CC" />
        <Bar name="Network Renewal" dataKey="renewalNetwork" stackId="b" fill="#2E4057" />
        <Bar name="Essentials Attrition" dataKey="expiredEssentials" stackId="b" fill="#d0021b" />
        <Bar name="Network Attrition" dataKey="expiredNetwork" stackId="b" fill="#E08F25" />
      </Fragment>
    )
  }

  return (
    <Row className="m-b-20">
      <Col xs={12}>
        <SubHeader>Renewing Subscription Purchase History</SubHeader>
      </Col>
      <Col xs={12}>
        <DataCard>
          <Form className="sav-form" style={{padding: 10}}>
            <Form.Row>
              <Form.Group as={Col} xs={4}>
                <SavFormDrop
                  opts={YEARS}
                  value={year}
                  setValue={setYear}
                  label="Year"
                />
              </Form.Group>
              <Form.Group as={Col} xs={4} style={account.type === 'Non-Direct' ? hidden : null}>
                <SavFormDrop
                  opts={REBATES}
                  value={rebateFilter}
                  setValue={setRebateFilter}
                  label="Rebate Markers"
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <SavFormDrop
                  opts={SUB_TYPES}
                  value={subsFilter}
                  setValue={setSubsFilter}
                  label="Subscription Type"
                />
              </Form.Group>
            </Form.Row>
          </Form>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              stackOffset="sign"
              key={Math.random()}
                width={500}
                height={300}
                data={dataFiltered}
                label={{
                  fill: "white",
                  position: "center",
                  zIndex: 9999,
                  fontSize: 10
                }}
                margin={{
                  top: 0,
                  right: 10,
                  bottom: 20
                }}
              >
                {renderLines()}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={CustomXAxisTickHistory} interval={0} />
                <YAxis tick={CustomAxisTickHistory} />
                <Tooltip content={<CustomTooltipHistory />} />
                {renderBars()}
                <Legend iconType="rect" wrapperStyle={{fontSize: 12, bottom: 10}} />
              </BarChart>
            </ResponsiveContainer>
        </DataCard>
      
      </Col>  
    </Row>
  )

}

export default SubscriptionHistory;
import React, { useState, useEffect } from "react";
// import Calendar from "react-calendar/dist/entry.nostyle";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import CalIcon from "../../images/calendarIcon.png";

const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 25px 200px;
  align-items: center;
`;
const CalContainer = styled.div`
  width: 200px;
  display: grid;
  grid-template-columns: 159px 25px;
  border: 1px solid #979797;
  padding: 8px;
  background-color: #fff;
`;

const CalInput = styled.input`
  border: none;
  &:focus {
    outline: 0 none;
  }
`;
const DateStyle = styled.div`
padding: .375rem .75rem;
border: 1px solid #ced4da;
border-radius: .25rem;
max-height: 38px;
`;

const SavDatePicker = (props) => {
  const { clickStart, clickEnd, style, start, end } = props;
  // const [startDate, setStartDate] = useState(start);
  // const [endDate, setEndDate] = useState(end);
  const [showCalStart, setShowCalStart] = useState(false);
  const [showCalEnd, setShowCalEnd] = useState(false);

  // useEffect(() => {
  //   setStartDate(start);
  //   setEndDate(end);
  // },[start, end])

  const handleStartInputClick = () => {
    setShowCalStart(!showCalStart);
  };

  const handleEndInputClick = () => {
    setShowCalEnd(!showCalEnd);
  };

  const handleStartDayClick = (value) => {
    clickStart(value);
    //setStartDate(value);
    setShowCalStart(false);
  };
  const handleEndDayClick = (value) => {
    clickEnd(value);
    //setEndDate(value);
    setShowCalEnd(false);
  };

  const renderCalStart = () => {
    if (showCalStart) {
      return (
        <Calendar
          // onChange={date => setStartDate(date)}
          value={start}
          showNeighboringMonth={false}
          onClickDay={(value) => handleStartDayClick(value)}
        />
      );
    }
    return null;
  };
  const renderCalEnd = () => {
    if (showCalEnd) {
      return (
        <Calendar
          // onChange={date => setEndDate(date)}
          value={end}
          showNeighboringMonth={false}
          onClickDay={(value) => handleEndDayClick(value)}
        />
      );
    }
    return null;
  };

  return (
    <DatePickerWrapper style={style ? style : null}>
      <div>
        <CalContainer key={start}>
          <CalInput
            type="text"
            defaultValue={start.toLocaleDateString()}
            onClick={() => handleStartInputClick()}
          />
          <img
            src={CalIcon}
            width={25}
            alt="cal"
            onClick={() => handleStartInputClick()}
          />
        </CalContainer>
        {renderCalStart()}
      </div>
      <div className="text-center f16 med">&gt;</div>
      <div>
        <CalContainer key={end}>
          <CalInput
            type="text"
            defaultValue={end.toLocaleDateString()}
            onClick={() => handleEndInputClick()}
          />
          <img
            src={CalIcon}
            width={25}
            alt="cal"
            onClick={() => handleEndInputClick()}
          />
        </CalContainer>
        {renderCalEnd()}
      </div>
    </DatePickerWrapper>
  );
};

export default SavDatePicker;

/* eslint-disable max-nested-callbacks */
import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash';

const ModalBody = styled.div`
  padding: 50px;
`;

const SavModalWarning = ({show, hide, title, text, text2, center, click, buttonText, size}) => {
  return (
    <Modal show={show} onHide={hide} size={size} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <ModalBody style={center ? { textAlign: 'center', fontSize:14, padding: 20 } : {textAlign: 'left', fontSize:14, padding: 20, whiteSpace:'pre-line'}}>
        <div style={text ? {marginBottom: 10} : null}>{text}</div>
        {text2}
      </ModalBody>
      <Modal.Footer>
        <button type="button" className="sav-btn__orange--fill" onClick={click ? () => click() : () => hide()}>
          {buttonText? buttonText : 'Okay'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default SavModalWarning;
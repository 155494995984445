import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import { save } from 'save-file'
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import sfAPI from "../../../api/sfAPI";
import {fetchOrder, fetchOrders, fetchAccount, fetchCreditCards, fetchOrderPDF} from "../../../actions";
import SavBanner from "../../../components/SavBanner";
import DoneIcon from "../../../images/Orders/done.svg";
import WipIcon from "../../../images/Orders/wip.svg";
import ErrorIcon from "../../../images/Orders/error.svg";
import {parseCurrency} from "../../../components/Utilities";
import UpdatePaymentModal from "./UpdatePaymentModal";
import SavToast from '../../../components/SavToast';
import SavToastSuccess from "../../../components/SavToastSuccess";
import SavToastFail from "../../../components/SavToastFail";
import { createLoadingSelector } from "../../../selectors";

const TopButtonDiv = styled.div`
  margin-top: 30px;
  display:flex;
  justify-content: space-between;
  align-items:center;
  width:100%;
`;
const BottomButtonDiv = styled.div`
  margin: 60px auto;
  display:flex;
  justify-content: space-between;
  align-items:center;
  width:80%;
`;
const Icon = styled.img`
  height:50px;
  width:50px;
  margin-top:10px;
`;
const StatusCard = styled.div`
  height: 200px
  display:grid;
  grid-template-rows: 50% 50%;
  text-align:center;
  text-decoration:uppercase;
  justify-content:center;
  border-bottom: ${props => (props.bottom ? props.bottom : "10px solid #D8D8D8")};
`;
const TopRow = styled(Row)`
  border-bottom: 1px solid #D4D4D4;
  padding:10px;
  font-size: 14px;
  text-align:center;
`;
const OrderLineRow = styled(Row)`
  border: 1px solid #d4d4d4;
  // padding:40px 0px;
  align-items:center;
  margin-bottom: 10px;
`;

const OrderDetails = (props) => {
  const {fetchOrderPDF, fetchOrder, fetchOrders, activeOrderState, orders, fetchAccount, fetchCreditCards, account, creditCards, loadingPDF} = props;
  const orderId = props.match.params.orderId;
  const [activeOrder, setActiveOrder] = useState(null);

  useEffect(() => {
    document.title = "Savant Community | Order Details"
  },[])

  useEffect(() => {
    fetchAccount();
    fetchCreditCards();
  },[]);

  useEffect(() => {
    if (_.isEmpty(activeOrderState)) {
      fetchOrder(orderId);
    } else {
      setActiveOrder(activeOrderState.data);
    } 
  },[activeOrderState]);

  useEffect(() => {
    if (activeOrder){
      if (activeOrder.sfid !== orderId){
        fetchOrder(orderId);
      }
    }
  },[activeOrder])

  const handleDownloadPDF = () => {
    fetchOrderPDF(orderId).then((res) => {
      const data = res.payload; 
     save(data, `Order ${activeOrder.orderNumber}.pdf`);
    });
    
  }

  const createMarkup = richText => {
    if (richText) {
      return { __html: richText };
    }
  };

  const renderResolve = () => {
    if (activeOrder.orderStatus === "Credit Card Decline" && !activeOrder.isProcessingDeclineResolution){
      return (
        <UpdatePaymentModal creditCards={creditCards} account={account} order={activeOrder} />
      )
    }
    return null;
  }

  const renderIcon = (step) => {
    if (step === 1) {
      if (
        activeOrder.orderStatus === "In Process" ||
        activeOrder.orderStatus === "Complete" ||
        activeOrder.orderStatus === "Accepted"
      ) {
        return <Icon src={DoneIcon} />
      } else if (
        activeOrder.orderStatus === "Needs Review" ||
        activeOrder.orderStatus === "Canceled" ||
        activeOrder.orderStatus === "Credit Card Decline"
        ) {
        return <Icon src={ErrorIcon} />
      } else {
        return <Icon src={WipIcon} />
      }
    }
    if (step === 2) {
      if (activeOrder.creditCardDeclineLineCount > 0) {
        return <Icon src={ErrorIcon} />
      }
      if (activeOrder.orderStatus === "In Process") {
        if (activeOrder.inProcessLineCount === activeOrder.lineCount){
          return <Icon src={DoneIcon} />
        }
        return <Icon src={WipIcon} />
      }
      if (activeOrder.orderStatus === "Complete") {
        return <Icon src={DoneIcon} />
      }
    }
    if (step === 3) {
      if (activeOrder.orderStatus === "Complete") {
        return <Icon src={DoneIcon} />
      }
      if (activeOrder.orderStatus === "In Process") {
        if (activeOrder.completedLineCount !== activeOrder.lineCount){
          return <Icon src={WipIcon} />
        }
      }
    }
  }

  const renderCount = (step) => {
    const status = activeOrder.orderStatus;

    if (step === 1) {
      if (activeOrder.orderStatus === "Complete") {
        return (
          <div className="book f14">
            {activeOrder.completedLineCount} OF {activeOrder.lineCount}
          </div>
        )
      }
      if (status === "Accepted") {
        return (
          <div className="book f14">
            {activeOrder.acceptedLineCount} OF {activeOrder.lines.length}
          </div>
        )
      }
      if (status === "In Process") {
        return (
          <div className="book f14">
            {activeOrder.lines.length} OF {activeOrder.lines.length}
          </div>
        )
      }
      if (status === "Needs Review") {
        return (
          <div className="book f14">
          </div>
        )
      }
      return (
        <div className="book f14">
          {activeOrder.acceptedLineCount} OF {activeOrder.lineCount}
        </div>
      )
    }

    if (step === 2) {
      if (activeOrder.orderStatus === "Canceled") {
        return (
          <div className="book f14">
            0 OF {activeOrder.lines.length}
          </div>
        )
      }
      if (activeOrder.orderStatus === "Accepted") {
        return (
          <div className="book f14">
            0 OF {activeOrder.lines.length}
          </div>
        )
      }
      if (activeOrder.orderStatus === "In Process") {
        return (
          <div className="book f14">
            {activeOrder.inProcessLineCount} OF {activeOrder.lineCount}
          </div>
        )
      }
      if (status === "Needs Review") {
        return (
          <div className="book f14">
          </div>
        )
      }
      return (
        <div className="book f14">
          {activeOrder.completedLineCount} OF {activeOrder.lineCount}
        </div>
      )
    }

    if (step === 3) {
      if (activeOrder.orderStatus === "Canceled") {
        return (
          <div className="book f14">
            0 OF {activeOrder.lines.length}
          </div>
        )
      }
      if (status === "Needs Review") {
        return (
          <div className="book f14">
          </div>
        )
      }
      return (
        <div className="book f14">
          {activeOrder.completedLineCount} OF {activeOrder.lineCount}
        </div>
      )
    }
    return null;
  }

  const getBottomBorder = (step) => {
    if (step === 1) {
      if (activeOrder.orderStatus === "In Process") {
        return "10px solid #4CD964"
      }
      if (activeOrder.orderStatus === "Complete" || activeOrder.orderStatus === "Accepted") {
        return "10px solid #4CD964"
      }
      if (activeOrder.orderStatus === "Needs Review" || activeOrder.orderStatus === "Canceled" || activeOrder.orderStatus === "Credit Card Decline") {
        return "10px solid #FF4220"
      }
    }
    if (step === 2) {
      if (activeOrder.creditCardDeclineLineCount > 0) {
        return "10px solid #FF4220"
      }
      if (activeOrder.orderStatus === "In Process") {
        if (activeOrder.inProcessLineCount === activeOrder.lineCount){
          return "10px solid #4CD964"
        }
        return "10px solid #FFCC5F"
      }
      if (activeOrder.orderStatus === "Complete") {
        return "10px solid #4CD964"
      }
    }
    if (step === 3) {
      if (activeOrder.orderStatus === "In Process") {
        if (activeOrder.completedLineCount !== activeOrder.lineCount){
          return "10px solid #FFCC5F"
        }
        return "10px solid #D8D8D8"
      }
      if (activeOrder.orderStatus === "Complete") {
        return "10px solid #4CD964"
      }
    }
  }

  const renderLines = () => {

    const getKitLines = (headerKey) => {
      let kitLines = activeOrder.lines.filter(
        line => line.kitPartKey === headerKey
      );
      return kitLines;
    }

    return activeOrder.lines.map((l) => {
      
      if (!l.isKitPart && !l.isKitHeader) {

        const renderShipDate = () => {
          if (l.status !== 'Shipped' && l.estShipDate !== null && l.status !== 'Complete' && l.status !== 'Canceled'){
            return (
              <div className="m-t-10">
                <div className="light-gray f10">
                  Estimated Ship Date
                </div>
                <div className="f12">
                    {l.estShipDate || "TBD"}
                  <span style={!l.estShipDateDelay ? {display:'none'} : {marginLeft: 5}} data-tip="We may be having issues with this product">
                  <img src={ErrorIcon} height="15px" width="15px"></img>
                </span>
                </div>
                
              </div>
            )
          }
          return null;
        }
        const renderOversized = () => {
          if (l.isOversized){
            return (
              <div className="m-t-10 light-gray f10">
                Not eligible for free shipping (<a href="https://beta-community.savant.com/knowledge/Savant-Shipping-Policy" target="_blank" rel="noreferrer" className="med oj u f10">LEARN MORE</a>)
              </div>
            )
          }
          return null;
        }
        const renderTracking = () => {
          if (l.trackingNumbers.length > 0) {
            return l.trackingNumbers.map((t) => {
              if (t.url){
                return (
                  <div key={t.trackingNumber}>
                    <div className="book f12">{t.shippingCarrier}</div>
                    <a href={t.url} target="_blank" rel="noreferrer" className="med oj u f10">{t.trackingNumber}</a>
                  </div>
                )
              }
              return (
                <div key={t.trackingNumber}>
                  <span className="med u f10">Tracking Unavailable</span>
                </div>
              )
              
            })
          }
          return null;
        }
        return (
          <OrderLineRow key={l.sfid}>
            <Col sm={4}>
              <Row style={{alignItems:'center', padding: "15px 0px", borderRight: "1px solid #d4d4d4"}}>
                <Col xs={6} className="text-center">
                  <img src={l.img} alt="prodImg" height={100} width={100} />
                </Col>
                <Col xs={6} style={{paddingLeft:0}}>
                  <div className="med f12">{l.sku}</div>
                  <div className="book f10">{l.name}</div>
                  {renderShipDate()}
                  {renderOversized()}
                </Col>
              </Row>
            </Col>
            <Col sm={2} className="text-center">
              <span className="book f12">{parseCurrency(l.unitPrice, 2)}</span>
            </Col>
            <Col sm={2} className="text-center">
              <span className="book f12">{l.qty}</span>
            </Col>
            <Col sm={2} className="text-center">
              <span className="book f12">{parseCurrency(l.total, 2)}</span>
            </Col>
            <Col sm={2} className="text-center">
              <div className="book f12">
                {renderTracking()}
                {l.status}
              </div>
            </Col>
          </OrderLineRow>
        )
      }

      if (l.isKitHeader) {
        return (
          <OrderLineRow key={l.sfid}>
            <Col xs={12}>
              <Row style={{alignItems:'center'}}>
                <Col sm={4}>
                  <Row style={{alignItems:'center', padding: "15px 0px", borderRight: "1px solid #d4d4d4"}}>
                    <Col xs={6} className="text-center">
                      <img src={l.img} alt="prodImg" height={100} width={100} />
                    </Col>
                    <Col xs={6} style={{paddingLeft:0}}>
                      <div className="med f12">{l.sku}</div>
                      <div className="book f10">{l.name}</div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={2} className="text-center">
                  <span className="book f12">{parseCurrency(l.unitPrice, 2)}</span>
                </Col>
                <Col sm={2} className="text-center">
                  <span className="book f12">{l.qty}</span>
                </Col>
                <Col sm={2} className="text-center">
                  <span className="book f12">{parseCurrency(l.total, 2)}</span>
                </Col>
                <Col sm={2}>
                </Col>
              </Row>
              {getKitLines(l.kitHeaderKey).map((kl) => {

                const renderTracking = () => {
                  if (kl.trackingNumbers.length > 0) {
                    return kl.trackingNumbers.map((t) => {
                      if (t.url){
                        return (
                          <div key={t.trackingNumber}>
                            <div className="book f12">{t.shippingCarrier}</div>
                            <a href={t.url} target="_blank" rel="noreferrer" className="med oj u f10">{t.trackingNumber}</a>
                          </div>
                        )
                      }
                      return (
                        <div key={t.trackingNumber}>
                          <span className="med u f10">Tracking Unavailable</span>
                        </div>
                      )
                      
                    })
                  }
                  return null;
                }
                const renderKitShip = () => {
                  if (kl.status !=='Complete' && kl.status !== 'Shipped' && kl.estShipDate !== null){
                    return (
                      <div style={{marginTop:5}}>
                        <div className="light-gray f10">
                          Estimated Ship Date
                        </div>
                        <div className="f12">
                          {kl.estShipDate || "TBD"}
                          <span style={!l.estShipDateDelay ? {display:'none'} : {marginLeft: 5}} data-tip="We may be having issues with this product">
                            <img src={ErrorIcon} height="15px" width="15px"></img>
                          </span>
                        </div>
                      </div>
                    )
                  }
                  return null;
                  
                }

                return (
                  <Row key={kl.sfid} style={{alignItems:'center'}}>
                    <Col xs={4}>
                      <Row style={{alignItems:'center', padding: "5px 0px", borderRight: "1px solid #d4d4d4"}}>
                        <Col xs={6} className="text-center">
                          <img src={kl.img} alt="prodImg" height={50} width={50} />
                        </Col>
                        <Col xs={6}>
                          <div className="med f12">{kl.sku}</div>
                          {renderKitShip()}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={2} className="text-center">
                      <div className="book f12">---</div>
                    </Col>
                    <Col xs={2} className="text-center">
                      <div className="book f12">{kl.qty}</div>
                    </Col>
                    <Col xs={2} className="text-center">
                      <div className="book f12">---</div>
                    </Col>
                    <Col xs={2} className="text-center">
                      {renderTracking()}
                      <div className="book f12">{kl.status}</div>
                    </Col>
                  </Row>
                )
              })}
            </Col>
          </OrderLineRow>
        )
      }
      return null;
      
    });
  }

  const renderOrder = () => {
    if (!_.isEmpty(activeOrder) && activeOrder.sfid === orderId) {
      return (
        <Fragment>
          <ReactTooltip />
          <Container style={{marginTop:60}}>
            <Row>
              <Col sm={4}>
                <StatusCard bottom={getBottomBorder(1)}>
                  <div className="text-center">
                  {renderIcon(1)}
                  </div>
                  <div>
                    <div className="med f16 text-uppercase">
                      Step 1
                    </div>
                    <div className="book f14 text-uppercase">
                      Order Accepted
                    </div>
                    {renderCount(1)}
                  </div>
                </StatusCard>
              </Col>
              <Col sm={4}>
                <StatusCard bottom={getBottomBorder(2)}>
                  <div className="text-center">
                    {renderIcon(2)}
                    </div>
                    <div>
                      <div className="med f16 text-uppercase">
                        Step 2
                      </div>
                      <div className="book f14 text-uppercase">
                        Order Processed
                      </div>
                      {renderCount(2)}
                    </div>
                </StatusCard>
              </Col>
              <Col sm={4}>
              <StatusCard bottom={getBottomBorder(3)}>
              <div className="text-center">
                {renderIcon(3)}
              </div>
              <div>
                <div className="med f16 text-uppercase">
                  Step 3
                </div>
                <div className="book f14 text-uppercase">
                  Completed
                </div>
                {renderCount(3)}
              </div>
              </StatusCard>
              </Col>
            </Row>
          </Container>
          <Container style={{border: "1px solid #d4d4d4", marginTop:60}}>
            <TopRow>
              <Col xs={12}>
                Purchase Order: <span className="med">{activeOrder.customerPO}</span>&nbsp;&nbsp;
                Order Number: <span className="med">{activeOrder.orderNumber}</span>&nbsp;&nbsp;
                Order Date: <span className="med">{activeOrder.orderDate}</span>
              </Col>
            </TopRow>
            <Row>
              <Col sm={4} style={{height:250, padding:20, borderRight:"1px solid #D4D4D4"}}>
                <div className="book light-gray f12">
                  Shipping Address
                </div>
                <div
                  className="book f14"
                  dangerouslySetInnerHTML={createMarkup(activeOrder.shipAddr)}
                />
                <div className="book light-gray f12" style={{marginTop:8}}>
                  Shipping Method
                </div>
                <div className="book f14">
                  {activeOrder.shippingBilling}
                  <br />
                  {activeOrder.shippingCarrier} {activeOrder.shippingMethod}
                  <br />
                  {activeOrder.shippingBilling !== "Via Savant" ? activeOrder.shippingAccountNum : null}
                </div>
              </Col>
              <Col sm={4} style={{height:250, padding:20, borderRight:"1px solid #D4D4D4"}}>
                <div className="book light-gray f12">
                  Billing Address
                </div>
                <div
                  className="book f14"
                  dangerouslySetInnerHTML={createMarkup(activeOrder.billAddr)}
                />
                <div className="book light-gray f12" style={{marginTop:8}}>
                  Payment Method
                </div>
                <div className="book f14">
                  {activeOrder.billingTerms}
                </div>
                <div className="book f14">
                </div>
              </Col>
              <Col sm={4} style={{height:250, padding:20}}>
                <div className="book light-gray f12">
                  Order Summary
                </div>
                <div className="book f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Product(s) Subtotal: <span className="med">{parseCurrency(activeOrder.subTotal,2)}</span>
                </div>
                <div className="book f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Tariff: <span className="med">{parseCurrency(activeOrder.orderTariff,2)}</span>
                </div>
                <div className="book f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Tax: <span className="med">{parseCurrency(activeOrder.orderTax,2)}</span>
                </div>
                <div className="book f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Shipping &amp; Handling: <span className="med">{parseCurrency(activeOrder.orderShipping,2)}</span>
                </div>
                <div className="book f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Credit Applied: <span className="med">{parseCurrency(activeOrder.requestedCreditApplied,2)}</span>
                </div>
                <div className="book oj f14 m-t-10" style={{display:"flex", justifyContent:"space-between"}}>
                  Order Total: <span className="med oj">{parseCurrency(activeOrder.orderTotal,2)}</span>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="m-t-20 text-right">
              <Col xs={12}>
                {renderResolve()}
              </Col>
            </Row>
            <Row className="f12 med text-uppercase" style={{margin:"40px -15px 10px"}}>
              <Col sm={4} className="light-gray--2 med d-none d-sm-block">
                Product
              </Col>
              <Col sm={2} className="light-gray--2 med d-none d-sm-block text-center">
                My Price
              </Col>
              <Col sm={2} className="light-gray--2 med d-none d-sm-block text-center">
                Quantity
              </Col>
              <Col sm={2} className="light-gray--2 med d-none d-sm-block text-center">
                Subtotal
              </Col>
              <Col sm={2} className="light-gray--2 med d-none d-sm-block text-center">
                Status
              </Col>
            </Row>
            {renderLines()}
          </Container>
          {/* <BottomButtonDiv>
            <button className="sav-btn__orange--fill">Return/Exchange</button>
            <button className="sav-btn__gray">Problem With Order</button>
            <button className="sav-btn__gray">Reorder</button>
            <button className="sav-btn__gray">Get RMA</button>
          </BottomButtonDiv> */}
        </Fragment>
      )
    }
    return null;
  }

  const renderDownloadButton =() => {
    if (!_.isEmpty(activeOrder)){
      if (activeOrder.orderStatus !== "Needs Review"){
        return (
          <button className="sav-btn__orange" onClick={() => handleDownloadPDF()}>
            Download PDF
          </button>
        )
      }
      return null;
    }
    return null;
  };

  const renderLoader = () => {
    if (loadingPDF) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }
  };
  
  return (
    <div>
      {renderLoader()}
      <SavBanner text="Order Detail" padding="40px" />
      <SavToast />
      <SavToastFail />
      <SavToastSuccess />
      <Container>
        <TopButtonDiv>
          <Link className="hov oj med f12" to="/account#orders">
            <button className="sav-btn__orange">Back</button>
          </Link>
          {renderDownloadButton()}
          
        </TopButtonDiv>
        {renderOrder()}
      </Container>
    </div>
  )
};

const loadingPDF = createLoadingSelector(["FETCH_ORDER_PDF"]);

const mapStateToProps = state => {
  return {
    activeOrderState: state.activeOrder,
    orderPDF: state.activeOrderPDF,
    orders: state.orders,
    account: state.account,
    creditCards: state.creditCards,
    loadingPDF: loadingPDF(state),
  };
};

export default connect(mapStateToProps, {fetchOrder, fetchOrders, fetchAccount, fetchCreditCards, fetchOrderPDF})(OrderDetails);
import React, { useEffect, useState,  } from 'react';
import { Container, Row, Col, Collapse } from "react-bootstrap";
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {useGA4React} from "ga-4-react";
import { FaCloudDownloadAlt, FaApple } from "react-icons/fa";
import {MdAndroid} from "react-icons/md"
import _ from 'lodash';
import {SavLabel, SettingRow, SavInput} from "../../components/Styled";
import {parseDateShort} from '../../components/Utilities';
import SavCheck from "../../components/SavCheck";
import Host from "../../images/host.svg"
import ReactGA from 'react-ga';
import SavBanner from "../../components/SavBanner";
import servicesTemp from "./servicesTemp.png";

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
  align-items:center;
  background-color: #979797;
  border-radius: 25px;
`;
const ProgressDiv = styled.div`
  height:65px;
  text-align:center;
  color:#fff;
  background-color:${props => (props.bg ? props.bg : "#979797")}
  border-right: ${props => (props.edge === 1 ? "3px solid white" : null)};
  border-right: ${props => (props.edge === 2 ? "3px solid white" : null)};
  border-right: ${props => (props.edge === 3 ? "3px solid white" : null)};
  border-right: ${props => (props.edge === 4 ? "3px solid white" : null)};
  padding:20px;
  border-top-left-radius: ${props => (props.edge === 1 ? "25px" : null)};
  border-bottom-left-radius: ${props => (props.edge === 1 ? "25px" : null)};
  border-top-right-radius: ${props => (props.edge === 5 ? "25px" : null)};
  border-bottom-right-radius: ${props => (props.edge === 5 ? "25px" : null)};;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:"Gotham-Medium";
`;
const Header = styled.div` 
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-transform:uppercase;
  text-align: center;
`;
const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;
const ReleaseHeader = styled.div`
  font-size:24px;
  font-family:"Gotham-Medium";
`;
const ReleaseSubHeader = styled.div`
  font-size:18px;
  font-family:"Gotham-Medium";
  margin-top:20px;
  text-transform:uppercase;
`;
const AppIcon = styled.img`
  border: 1px solid #fff;
  border-radius: 4px;
  height:50px;
`;
const StepDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 117px;
`;

const MyProjects = ({}) => {

  const [step, setStep] = useState(1);

  const renderBetaStep = () => {
    if (step === 0){
      return (
        <StepDiv className="book text-center ">
          Opt in to the Savant Beta Program via the checkbox under "Beta Information"
        </StepDiv>
      )
    }
    if (step === 1){
      return (
        <StepDiv className="book text-center ">
          <div>
            2.  Populate your Beta host UID under "Beta Information"
          </div>
        </StepDiv>
      )
    }
    if (step === 2){
      return (
        <StepDiv className="book text-center ">
          <div>
            <span className="red">Host UID not found</span><br />
            • Ensure the host is running Savant Beta software and that the host has WAN access.<br />

          </div>
        </StepDiv>
      )
    }
    if (step === 3){
      return (
        <StepDiv className="book text-center ">
          You are registered for the Savant Beta Program!
        </StepDiv>
      )
    }
  };

  return (
    <div>
      <SavBanner text="Savant Projects" padding="40px" />
      <Container>
        <div className="m-t-20 m-b-20 med f24 text-center">
          Avix Project
        </div>
        <Row className="m-t-20">
          <Col xs={12}>
          <ProgressBar>
            <ProgressDiv edge={1} bg={step > 0 ? "#4cd964" : null}>
              Milestone 1<br />
              Approve Quote
            </ProgressDiv>
            <ProgressDiv edge={2} bg={step > 1 ? "#4cd964" : null}>
            Milestone 2<br />
              Order Products
            </ProgressDiv>
            <ProgressDiv edge={3} bg={step > 2 ? "#4cd964" : null}>
              Milestone 3<br />
              Consultation
            </ProgressDiv>
            <ProgressDiv edge={4} bg={step > 3 ? "#4cd964" : null}>
              Milestone 4<br />
              Install
            </ProgressDiv>
            <ProgressDiv edge={5} bg={step > 4 ? "#4cd964" : null}>
              Milestone 5<br />
              Complete
            </ProgressDiv>
          </ProgressBar>
          </Col>
        </Row>

        <img src={servicesTemp} width="100%" style={{marginTop:50}} />

      </Container>
    </div>
  )

}

export default MyProjects;
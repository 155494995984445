import React, {Fragment} from "react";
import styled from "styled-components";
import moment from 'moment';
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Text
} from "recharts";
import {parseCurrency, kFormatter} from "../Utilities";

const AdoptionProgressQual = ({progress, toGo, text}) => {

  const data = [
    { name: 'Progress', value: progress, color:"#4cd964"},
    { name: 'To Go', value: toGo, color:"#979797"}
  ]

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart width={200} height={200}>
        <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label value={text} position="center" />
        </Pie>
        {/* <Text x={20} y={50} dy={8} textAnchor="middle">{text}</Text> */}
        {/* <Tooltip /> */}
      </PieChart>
    </ResponsiveContainer>
  )

}

export default AdoptionProgressQual;
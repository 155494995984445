import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  fill: none;
  stroke: #fff;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  background: ${props => (props.checked && !props.bg ? "#ff5f00" : props.checked && props.bg ? props.bg : "#D8D8D8")};
  border: ${props => (props.checked && !props.bg ? "1px solid #ff5f00" : props.checked && props.bg ? props.bg : "1px solid #979797")}
  transition: all 150ms;
  cursor:${props => props.cursor ? props.cursor : "pointer"}
  margin: ${props => (props.center? "0 auto" : null)};
  margin-top: ${props => props.padTop ? "8px" : null};

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")}
  }
`;


const StyledCheckboxBig = styled(StyledCheckbox)`
  width: 24px;
  height: 24px;
  margin-top: 8px;
`;

const SavCheck = props => {
  const { checked, click, form, center, cursor, bg, bigCheck, padTop } = props;

  if (bigCheck) {
    return (
      <StyledCheckboxBig checked={checked} onClick={click} isForm={form} center={center} cursor={cursor} bg={bg} >
      <Icon viewBox="0 0 24 24" style={{strokeWidth: 4}}>
        <polyline points="20 6 10 16 4 10" />
      </Icon>
    </StyledCheckboxBig>
    )
  };

  return (
    <StyledCheckbox checked={checked} onClick={click} isForm={form} center={center} cursor={cursor} bg={bg} padTop={padTop} >
      <Icon viewBox="0 0 24 32">
        <polyline points="20 2 9 13 4 8" />
      </Icon>
    </StyledCheckbox>
  );
};

export default SavCheck;

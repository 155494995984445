import { FETCH_NEWS_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const activeNews = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NEWS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default activeNews;
import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal, Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import {AiOutlineBank, AiOutlineCreditCard} from "react-icons/ai"
import {createCreditCard, createBankAccount, setSuccessToast} from "../../actions";
import SavCheck from "../SavCheck";
import Countries from "../Utilities/countries";
import SavFormInput from "../SavFormInput";
import SavFormDrop from "../SavFormDrop";
import SavDropFilter from "../SavDropFilter";

const TypeCard = styled.div`
  margin-top:30px;
  padding: 36px 12px;
  background-color: #fafaf9;
  display: grid;
  grid-template-rows: 25px 100px 25px;
  cursor: pointer;
  justify-items: center;
  text-align: center;
  border: 1px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  &:hover {
    text-decoration:none;
    color:unset;
    border: 1px solid #f9f9f9;
    opacity: 0.7;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const CardHeader = styled.div`
  color: #565551;
  font-family: "Gotham-Medium";
  font-size: 16px;
  text-transform: uppercase;
  a {
    color: #565551;
    font-family: "Gotham-Medium";
  }
`;

const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const years = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"];
const cardTypes = ["Visa", "Mastercard", "Discover", "American Express"];

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});
const SavDropFilterForm = React.forwardRef((props, ref) => {
  return (
    <SavDropFilter val={props.val} opts={props.opts} click={props.click} clickKey="code" />
  )
});

const NewCreditCardModal = ({createCreditCard, createBankAccount, buttonText, buttonClass, buttonStyle}) => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [ccType, setCcType] = useState("Visa");
  const [ccCode, setCcCode] = useState("");
  const [expMonth, setExpMonth] = useState("1");
  const [expYear, setExpYear] = useState("2022");
  const [cardError, setCardError] = useState("");
  const [addressStreet, setAddressStreet] = useState('');
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressCountry, setAddressCountry] = useState('US');
  const [addressContactLast, setAddressContactLast] = useState("");
  const [addressContactFirst, setAddressContactFirst] = useState("");
  const [isDefault, setIsDefault] = useState(false);

  const [step, setStep] = useState(1);
  const [type, setType] = useState('');

  const [validatedBank, setValidatedBank] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankContactName, setBankContactName] = useState("Username");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankRouting, setBankRouting] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");

  useEffect(() => {
    //Credit Card
    setValidated(false);
    setCreditCardNumber("");
    setCcCode("");
    setAddressContactLast("");
    setAddressContactFirst("");
    setExpYear("2022");
    setExpMonth("1");
    setIsDefault(false);
    // ACH
    setValidatedBank(false);
    setBankAccountNumber("")
    setBankName("");
    setBankContactName("");
    setBankAccountType("Checking");
    setBankRouting("");
    setBankAccountName("");
    // visual
    setStep(1);
    setType("");
  },[show]);

  const handleCloseNewBankModal = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidatedBank(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const bankJSON = {
      type: bankAccountType,
      bankName: bankName,
      bankContactName: bankContactName,
      accountNumber: bankAccountNumber,
      routingNumber: bankRouting,
      name: bankAccountName
    }
    createBankAccount(bankJSON).then((val) => {
      setSuccessToast(true);
    });
    setShow(false);
  } 


  const handleCloseNewCCModal = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const cardJSON = {
      cardNumber: creditCardNumber,
      expYear: expYear,
      expMonth: expMonth,
      cvv: ccCode,
      type: ccType,
      isDefault: isDefault,
      billingAddress: addressStreet,
      billingCity: addressCity,
      billingCountry: addressCountry,
      billingFirst: addressContactFirst,
      billingLast: addressContactLast,
      billingState: addressState,
      billingZip: addressZip
    }

    createCreditCard(cardJSON).then((val) => {
      setSuccessToast(true);
    });
    setShow(false);
  }

  const handleClickCard = (step, type) => {
    setStep(step);
    setType(type);
  };

  const renderContent = () => {
    if (step === 1){
      return (
        <Container style={{padding: '0px 50px 50px 50px'}}>
          <Row>
            <Col sm={6}>
              <TypeCard onClick={() => handleClickCard(2, 'ach')}>
                <CardHeader>
                  Bank Account
                </CardHeader>
                <AiOutlineBank size={100} />
              </TypeCard>
            </Col>
            <Col sm={6}>
              <TypeCard onClick={() => handleClickCard(2, 'card')}>
                <CardHeader>
                  Credit Card
                </CardHeader>
                <AiOutlineCreditCard size={100} />
              </TypeCard>
            </Col>
          </Row>
        </Container>
      )
    }
    if (step === 2){
      if (type === "ach"){
        return (
          <div className="" style={{margin: "0 auto", width:"65%"}}>
            <Form onSubmit={handleCloseNewBankModal} noValidate validated={validatedBank} className="sav-form">

                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={bankAccountName} setValue={setBankAccountName} label="Bank Account Nickname"/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={bankContactName} setValue={setBankContactName} label="Contact Name on Bank Account"/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankName} setValue={setBankName} label="Name of Bank"/>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <SavFormDrop value={bankAccountType} setValue={setBankAccountType} label="Type" opts={["Checking", "Savings"]} />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankAccountNumber} setValue={setBankAccountNumber} label="Bank Account #"/>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankRouting} setValue={setBankRouting} label="Bank Routing #"/>
                  </Form.Group>
                </Form.Row>
              <div className="m-t-20 text-center">
                <button className="sav-btn__gray m-r-10" onClick={() => handleClickCard(1, "")}>
                  Back
                </button>
                <button className="sav-btn__orange--fill" type="submit">
                  Save
                </button>
              </div>
              </Form>
            </div>
        )
      }
      if (type === 'card'){
        return (
          <div className="" style={{margin: "0 auto", width:"65%"}}>
            <Form onSubmit={handleCloseNewCCModal} noValidate validated={validated} className="sav-form">
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInput value={creditCardNumber} setValue={setCreditCardNumber} label="Credit Card Number"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={expMonth} setValue={setExpMonth} label="Expiration Month" opts={months} />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={expYear} setValue={setExpYear} label="Expiration year" opts={years} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={ccType} setValue={setCcType} label="Card Type" opts={cardTypes} />
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={ccCode} setValue={setCcCode} label="Security Code"/>
                </Form.Group>
                <Form.Group as={Col} sm={2} className="text-center">
                  <Form.Label>Default</Form.Label>
                  <Form.Control as={SavCheckForm} checked={isDefault} click={() => setIsDefault(!isDefault)} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={addressContactFirst} setValue={setAddressContactFirst} label="First Name"/>
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={addressContactLast} setValue={setAddressContactLast} label="Last Name"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInput value={addressStreet} setValue={setAddressStreet} label="Street Address"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={addressCity} setValue={setAddressCity} label="City"/>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={addressState} setValue={setAddressState} label="State/Province"/>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={addressZip} setValue={setAddressZip} label="Zip/Postal Code"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Control as={SavDropFilterForm}  val={addressCountry} opts={Countries} click={setAddressCountry} />
                </Form.Group>
              </Form.Row>
              <div className="m-t-20 text-center">
                <button className="sav-btn__gray m-r-10" onClick={() => handleClickCard(1, "")}>
                  Back
                </button>
                <button className="sav-btn__orange--fill" type="submit">
                  Save
                </button>
              </div>
            </Form>
          </div>
        )
      }
    }
    return null;
  }


  return (
    <Fragment>
        <button style={buttonStyle} className={buttonClass || "sav-btn__orange--text"} onClick={() => setShow(true)}>{buttonText || "+ New Credit Card"}</button>
        <Modal show={show} onHide={() =>setShow(false)} animation={false} centered size="lg">
          
          <Modal.Header closeButton>
            <Modal.Title>
              Add New {type === "" ? "Payment Method" : type === "ach" ? "Bank Account" : "Credit Card"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderContent()}
          </Modal.Body>
        </Modal>
      </Fragment>
  )
}

export default connect(null, {createCreditCard, createBankAccount, setSuccessToast})(NewCreditCardModal);
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Tab, Nav, Dropdown, Table } from "react-bootstrap";
import { save } from 'save-file'
import _ from "lodash";
import {AiOutlineCloudDownload} from "react-icons/ai";
import {
  SubHeader,
  SavLabel,
  SavOutputField,
  SavInput,
  SavRow
} from "../../../components/Styled";

export const SavTable = styled(Table)`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 4px;
  th {
    background-color: #fff;
    color: #9b9b9b;
    padding: 10px 10px;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
    border-top:unset;
    border-bottom:unset!important;
  }
`;

const SavTd = styled.td`
  vertical-align: middle!important;
  max-width: 200px;
  border: 1px solid #d4d4d4;
  border-style: solid none;
  padding: 20px 10px!important;

  &:first-child {
    border-left-style: solid;
    border-top-left-radius: 4px; 
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 4px; 
    border-top-right-radius: 4px;
  }
`;


const SavantAgreements = ({documents, fetchDocument, setSuccessToast}) => {

  const [document, setDocument] = useState(null);
  const [docList, setDocList] = useState([]);

  useEffect(() => {
    const taxCerts = _.filter(documents, (d) => d.docType === 'agreement');
    setDocList(taxCerts);
  },[documents]);

  const handleFetchDoc = (docId, title) => {
    fetchDocument(docId, "agreement").then((res) => {
      if (res.success) {
        const data = res.payload; 
        save(data, `Order ${title}.pdf`);
      }
      setSuccessToast(true);
    })
  }

  const renderAgreements = () => {
    return documents.map((doc) => {
      if (doc.docType === "agreement") {
        return (
          <SavRow key={doc.recordId} className="vertical-align">
            <Col xs={5} md={4}>
              {doc.title}
            </Col>
            <Col xs={4} md={3} className="d-none d-md-block">
              {doc.documentProperties.dateSigned}
            </Col>
            <Col xs={5} md={3}>
              {doc.documentProperties.signedBy}
            </Col>
            <Col xs={2} md={2} className="text-right">
              <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} size={25} onClick={() => handleFetchDoc(doc.recordId, doc.title)} />
            </Col>
          </SavRow>
          // <tr key={doc.recordId}>
          //   <SavTd>{doc.title}</SavTd>
          //   <SavTd>{doc.documentProperties.dateSigned}</SavTd>
          //   <SavTd>{doc.documentProperties.signedBy}</SavTd>
          //   <SavTd className="text-center">
          //     <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} size={25} onClick={() => handleFetchDoc(doc.recordId, doc.title)} />
          //   </SavTd>
          // </tr>
        )
      }
    })
  }

  if (documents) {
    if (docList.length <1) {
      return (
        <div className="text-center m-t-20">
          You currently do not have any Savant Agreements.
        </div>
      )
    }
    
    return (
      <Container>
        <hr />
        <Row className="f10 text-uppercase m-b-10 med light-gray">
          <Col xs={5} md={4}>Document</Col>
          <Col xs={4} md={3} className="d-none d-md-block">Date Signed</Col>
          <Col xs={5} md={3}>Signee</Col>
          <Col xs={2} md={2} className="text-center"></Col>
        </Row>
        {renderAgreements()}
        {/* <SavTable>
          <thead>
          <tr style={{border:'none'}}>
            <th>Document</th>
            <th>Date Signed</th>
            <th>Signee</th>
            <th className="text-center">Download</th>
          </tr>
          </thead>
          <tbody>
            {renderAgreements()}
          </tbody>
        </SavTable> */}
      </Container>
    )
  }
  
  return null;
  
}

export default SavantAgreements;
import { FETCH_BETA_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const beta = (state = {}, action) => {
  switch (action.type) {
    case FETCH_BETA_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default beta;
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import {createLoadingSelector, visibilitySelector} from "../../selectors";
import { ZeroStateText } from "../../components/Styled";
import Overview from "./Overview";
import Orders from "./Orders/Orders";
import Contacts from "./Contacts/Contacts";
import Billing from "./Billing/Invoices";
import Documents from "./Documents/Documents";
import Warranty from "./Warranty";
import Settings from "./Settings";
import Homes from "./Subscriptions";

import {
  fetchAccount,
  fetchSubs,
  fetchOrders,
  fetchAddresses,
  fetchContacts,
  updateContact,
  fetchEducation,
  fetchSerials,
  fetchCredits,
  fetchInvoices,
  fetchCreditCards,
  fetchBankAccounts,
  fetchPayments,
  fetchDocuments,
  fetchUser,
  fetchTasks,
  setSuccessToast
} from "../../actions";
import AccountNav from "./AccountNav";


export const AccountContext = React.createContext();

const Account = props => {
  const {
    account,
    fetchAccount,
    subscriptions,
    fetchSubs,
    orders,
    fetchOrders,
    addresses,
    fetchAddresses,
    contacts,
    updatingContact,
    fetchContacts,
    education,
    fetchEducation,
    fetchSerials,
    fetchCredits,
    credits,
    serials,
    invoices,
    fetchInvoices,
    fetchCreditCards,
    fetchBankAccounts,
    fetchPayments,
    payments,
    bankAccounts,
    creditCards,
    documents,
    fetchDocuments,
    fetchUser,
    tasks,
    fetchTasks,
    user,
    location,
    history,
    setSuccessToast,
    fetchingAccount,
    fetchingSubs,
    fetchingAddresses,
    fetchingContacts,
    fetchingCreditCards,
    fetchingPayments,
    fetchingCredits,
    fetchingDocuments,
    fetchingEducation,
    fetchingInvoices,
    fetchingOrders,
    fetchingSerials,
    fetchingBank,
    fetchingUser,
    fetchingTasks,

  } = props;

  let hash = location.hash;
  const showDashboard = useSelector(visibilitySelector('account_dashboard'));
  const showOrders = useSelector(visibilitySelector('account_orders'));
  const showContacts = useSelector(visibilitySelector('account_contacts_personnel'));
  const canMakePayments = useSelector(visibilitySelector('account_billing_payment'));
  const showBilling = useSelector(visibilitySelector('account_billing'));
  const showDocuments = useSelector(visibilitySelector('account_documents'));
  const showSettingsDetailsCompany = useSelector(visibilitySelector('account_settings_details_company'));
  const showSettingsDetailsStatus = useSelector(visibilitySelector('account_settings_details_status'));
  const showSettingsSettings = useSelector(visibilitySelector('account_settings_settings'));
  const showSettingsAddresses = useSelector(visibilitySelector('account_settings_addresses'));
  const showSettingsCreditCards = useSelector(visibilitySelector('account_settings_creditCards'));
  const showSettingsScm = useSelector(visibilitySelector('account_settings_scm'));
  const showSettingsListings = useSelector(visibilitySelector('account_settings_savant_com_listings'));
  const hideSettings = !showSettingsDetailsCompany && !showSettingsDetailsStatus && !showSettingsSettings && !showSettingsAddresses && !showSettingsCreditCards && !showSettingsScm && !showSettingsListings;

  useEffect(() => {
    if (_.isEmpty(account) && !fetchingAccount) {
      fetchAccount();
    }
  }, [account]);

  useEffect(() => {
    if (_.isEmpty(subscriptions) && !fetchingSubs) {
      fetchSubs();
    }
  }, [subscriptions]);

  useEffect(() => {
    if(_.isEmpty(documents) && !fetchingDocuments){
      fetchDocuments();
    }
  },[documents]);

  useEffect(() => {
    if(_.isEmpty(creditCards) && !fetchingCreditCards){
      fetchCreditCards();
    }
  },[creditCards])

  useEffect(() => {
    if(_.isEmpty(bankAccounts) && !fetchingBank && canMakePayments){
      fetchBankAccounts();
    }
  },[bankAccounts]);

  useEffect(() => {
    if (_.isEmpty(orders) && !fetchingOrders) {
      fetchOrders();
    }
  }, [orders]);
  useEffect(() => {
    if (_.isEmpty(addresses) && !fetchingAddresses) {
      fetchAddresses();
    }
  }, [addresses]);

  useEffect(() => {
    if (_.isEmpty(contacts) && !fetchingContacts) {
      fetchContacts();
    }
  }, [contacts]);

  useEffect(() => {
    if (_.isEmpty(education) && !fetchingEducation) {
      fetchEducation();
    }
  }, [education]);

  useEffect(() => {
    if (_.isEmpty(serials) && !fetchingSerials) {
      fetchSerials();
    }
  }, [serials]);

  useEffect(() => {
    if (_.isEmpty(credits) && !fetchingCredits) {
      fetchCredits();
    }
  }, [credits]);

  useEffect(() => {
    if (_.isEmpty(invoices) && !fetchingInvoices) {
      fetchInvoices();
    }
  }, [invoices]);

  useEffect(() => {
    if (_.isEmpty(payments) && !fetchingPayments && canMakePayments) {
      fetchPayments();
    }
  }, [payments]);

  useEffect(() => {
    if (_.isEmpty(tasks) && !fetchingTasks) {
      fetchTasks();
    }
  }, [tasks]);

  const renderPages = () => {
    const overviewHashes = ["", "#integrator", "#vir", "#ambassador", "#new"];
    if ((hash === "#overview" || hash === "") && showDashboard ){
      return <Overview account={account.data} addresses={addresses.data} contacts={contacts.data} user={user.data} creditCards={creditCards.data} orders={orders.data} location={location} />
    }
    if (hash === "#subscriptions"){
      return (
        <Homes location={location} subscriptions={subscriptions.data} account={account.data} contacts={contacts.data} fetchSubs={fetchSubs} fetchingSubs={fetchingSubs}  />
      )
    }
    if (hash === "#orders"){
      if (showOrders){
        return (
          <Orders orders={orders.data} showOrders={showOrders} />
        )
      } else {
        return (
          <ZeroStateText margin="200px">
            You do not have permission to view this page.
            <div className="text-center m-t-20">
              <Link to="/">
                <button className="sav-btn__orange">Return Home</button>
              </Link>
            </div>
          </ZeroStateText>
        )
      }
      
    }
    if (hash === "#contacts"){
      if (showContacts){
        return (
          <Contacts contacts={contacts.data} updateContact={updateContact} loading={updatingContact} fetchUser={fetchUser} setSuccessToast={setSuccessToast} education={education.data} user={user.data} location={location} showContacts={showContacts} />
        )
      } else {
        return (
          <ZeroStateText margin="200px">
            You do not have permission to view this page.
            <div className="text-center m-t-20">
              <Link to="/">
                <button className="sav-btn__orange">Return Home</button>
              </Link>
            </div>
          </ZeroStateText>
        )
      }
      
    }
    if (hash === "#billing"){
      if (showBilling){
        return (
          <Billing location={location} showBilling={showBilling} fetchingUser={fetchingUser} />
        )
      } else {
        return (
          <ZeroStateText margin="200px">
            You do not have permission to view this page.
            <div className="text-center m-t-20">
              <Link to="/">
                <button className="sav-btn__orange">Return Home</button>
              </Link>
            </div>
          </ZeroStateText>
        )
      }
      
    }
    if (hash === "#documents"){
      if (showDocuments){
        return (
          <Documents user={user.data} location={location} />
        )
      } else {
        return (
          <ZeroStateText margin="200px">
            You do not have permission to view this page.
            <div className="text-center m-t-20">
              <Link to="/">
                <button className="sav-btn__orange">Return Home</button>
              </Link>
            </div>
          </ZeroStateText>
        )
      }
      
    }
    if (hash === "#warranty"){
      return (
        <Warranty />
      )
    }
    if (hash === "#settings"){
      if (hideSettings){
        return (
          <ZeroStateText margin="200px">
            You do not have permission to view this page.
            <div className="text-center m-t-20">
              <Link to="/">
                <button className="sav-btn__orange">Return Home</button>
              </Link>
            </div>
          </ZeroStateText>
        )
      } else {
        return <Settings location={location} />
      }
      
    }
    return null;
  };

  return (
    <div>
      <AccountContext.Provider value={props}>
        <AccountNav hash={hash} location={location} history={history} />
        {renderPages()}
      </AccountContext.Provider>
    </div>
  );
};



const fetchingAccount = createLoadingSelector(["FETCH_ACCOUNT"]);
const fetchingOrders = createLoadingSelector(["FETCH_ORDERS"]);
const fetchingAddresses = createLoadingSelector(["FETCH_ADDRESSES"]);
const fetchingContacts = createLoadingSelector(["FETCH_CONTACTS"]);
const fetchingEducation = createLoadingSelector(["FETCH_EDUCATION"]);
const fetchingSerials = createLoadingSelector(["FETCH_SERIALS"]);
const fetchingCredits = createLoadingSelector(["FETCH_CREDITS"]);
const fetchingInvoices = createLoadingSelector(["FETCH_INVOICES"]);
const fetchingPayments = createLoadingSelector(["FETCH_PAYMENTS"]);
const fetchingCreditCards = createLoadingSelector(["FETCH_CREDIT_CARDS"]);
const fetchingDocuments = createLoadingSelector(["FETCH_DOCUMENTS"]);
const fetchingUser = createLoadingSelector(["FETCH_USER"]);
const updatingContact = createLoadingSelector(["UPDATE_CONTACT"]);
const fetchingBank = createLoadingSelector(["FETCH_BANK"]);
const fetchingTasks = createLoadingSelector(["FETCH_TASKS"]);
const fetchingSubs = createLoadingSelector(["FETCH_SUBS"]);

const mapStateToProps = state => {
  return {
    account: state.account,
    subscriptions: state.subscriptions,
    orders: state.orders,
    addresses: state.addresses,
    contacts: state.contacts,
    education: state.education,
    serials: state.serials,
    credits: state.credits,
    invoices: state.invoices,
    payments: state.payments,
    creditCards: state.creditCards,
    bankAccounts: state.bankAccounts,
    documents: state.documents,
    tasks: state.tasks,
    user: state.user,
    updatingContact: updatingContact(state),
    fetchingAccount: fetchingAccount(state),
    fetchingOrders: fetchingOrders(state),
    fetchingAddresses: fetchingAddresses(state),
    fetchingContacts: fetchingContacts(state),
    fetchingEducation: fetchingEducation(state),
    fetchingSerials: fetchingSerials(state),
    fetchingCredits: fetchingCredits(state),
    fetchingInvoices: fetchingInvoices(state),
    fetchingPayments: fetchingPayments(state),
    fetchingCreditCards: fetchingCreditCards(state),
    fetchingBank: fetchingBank(state),
    fetchingDocuments: fetchingDocuments(state),
    fetchingUser: fetchingUser(state),
    fetchingTasks: fetchingTasks(state),
    fetchingSubs: fetchingSubs(state),
  };
};

export default connect(mapStateToProps, {
  fetchAccount,
  fetchSubs,
  fetchOrders,
  fetchAddresses,
  fetchContacts,
  updateContact,
  fetchEducation,
  fetchSerials,
  fetchCredits,
  fetchInvoices,
  fetchPayments,
  fetchCreditCards,
  fetchBankAccounts,
  fetchDocuments,
  fetchUser,
  fetchTasks,
  setSuccessToast
})(Account);

import {React, Fragment} from 'react';
import styled from "styled-components";
import { Row, Col, Container, Table, Popover, OverlayTrigger  } from "react-bootstrap";
import {Link} from "react-router-dom";
import {AiOutlineInfoCircle} from 'react-icons/ai';

export const SubHeader = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  padding: 30px;
  font-family: "Gotham-Medium";
`;
// Used on home page Dashbaord, Events, News
 export const SectionHeader = styled.div`
  font-family: "Gotham-Medium";
  border-bottom: 0.5px solid #b4b4b4;
  margin-top: 20px;
  text-transform: uppercase;
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

export const SavSearch = styled.input`
  background: transparent;
  border: none;
  border-bottom: ${(props) => props.borderBottom || "1px solid #979797"};
  font-size: 12px;
  width: ${(props) => props.width || "100%"}
  padding: 6px;
  &:focus {
    outline: 0 none;
  }
  color: ${(props) => props.color || "inherit%"}
`;

export const SavRow = styled(Row)`
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  padding: 20px 10px;
  font-size: 12px;
  margin-bottom: 6px;
`;
export const SavRowDiv = styled.div`
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  padding: 20px 10px;
  font-size: 12px;
  margin-bottom: 6px;
`;

export const SavSpanName = styled.span`
  color: #ff5f00;
  text-decoration: underline;
  cursor: pointer;
`;

export const SavLink = styled.a`
  font-size: 12px;
  color: #ff5f00;
  text-decoration: underline;
  &:hover {
    color: #979797;
  }
`;

export const SavRouterLink = styled(Link)`
  font-size: 10px;
  color: #ff5f00;
  text-decoration: none;
  &:hover {
    color: #3f3f3f;
    text-decoration: none;
  }
`;

export const SavLabel = styled.div`
  font-family: "Gotham-Book";
  font-size: 12px;
  color: #b4b4b4;
`;

export const SavOutputField = styled.div`
  font-family: "Gotham-Book";
  font-size: 12px;
  color: #565551;
  margin: 6px 0px;
`;

export const SavInput = styled.input`
  outline: 0 none;
  padding: 10px;
  border: 1px solid #979797;
  font-size: 12px;
  width: ${(props) => props.width || "100%"};
`;
export const SavInputDisabled = styled.input`
  outline: 0 none;
  padding: 4px;
  background-color:#e4e4e4;
  border: 1px solid #979797;
  font-size: 12px;
  cursor:not-allowed;
  width: ${(props) => props.width || "100%"};
`;

export const SavInput2 = styled.input`
  outline: 0 none;
  padding: 8px;
  border: none;
  background-color:#F9F9F9
  font-size: 12px;
  width: ${(props) => props.width || "100%"};
`;

export const SettingRow = styled(Row)`
  background-color: #fafaf9;
  padding: 15px;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const PlaceholderText = styled.div`
  background-color:#979797;
  height:16px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 4px;
  opacity: .5;
`;

export const SavTable = styled(Table)`
  font-size: 12px;
  text-align: center;
  th {
    background-color: #979797;
    color: #fff;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
  }
  td {
    vertical-align: middle;
    max-width: 200px;
  }
`;
export const SavTable2 = styled(Table)`
  font-size: 12px;
  text-align: center;
  th {
    background-color: #3f3f3f;
    color: #fff;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
  }
  td {
    vertical-align: middle;
    max-width: 200px;
    background-color:#fff;
  }
`;

export const SavTableList = styled(Table)`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 4px;
  th {
    background-color: #fff;
    color: #9b9b9b;
    padding: 10px 10px;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
    border-top:unset;
    border-bottom:unset!important;
  }
`;

export const SavTd = styled.td`
  vertical-align: middle!important;
  max-width: 200px;
  border: 1px solid #d4d4d4;
  border-style: solid none;
  padding: 20px 10px!important;

  &:first-child {
    border-left-style: solid;
    border-top-left-radius: 4px; 
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 4px; 
    border-top-right-radius: 4px;
  }
`;

export const SavFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Hide = {
  display: 'none'
};

export const HeaderBlock = styled.div`
  min-height: 175px;
  padding: 50px 15px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  font-family: "Gotham-Book";
`;
export const HeaderBlockTitle = styled.div`
  font-size: 18px;
  margin-bottom:10px;
`;
export const HeaderBlockContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 30px;
`;

export const StatusPill = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:${(props) => props.bg || "#fafaf9"};
  color:${(props) => props.color || "#9b9b9b"};
  border:${(props) => props.border || "1px solid #9b9b9b"};
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 80px;
  text-align:center;
  text-transform:uppercase;
`;

export const ZeroStateText = styled.div`
  text-align: center;
  font-family: "Gotham-Book";
  margin: ${(props) => props.margin || "50px"}
  font-size: ${(props) => props.size || "12px"}
  color:  #979797;
`;

export const DropTriangleGray = styled.div`
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 7px solid #dcdcdc !important;
  border-bottom-color: #dcdcdc !important;
  position: absolute;
  top: -6px;
  left: 2px;
`;

export const InfoPop = ({placement, title, text}) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement || 'left'}
      overlay={
        <Popover className="sav-pop" id={title}>
          <Popover.Title className="f12 med bg-white text-center">
            {title}
          </Popover.Title>
          <Popover.Content className="p-10">
            {text}
          </Popover.Content>
        </Popover>
      }
    >
      <div>
      <AiOutlineInfoCircle size={25} style={{cursor: 'help'}} />
      </div>
     
    </OverlayTrigger>
  )
}

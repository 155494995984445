import {
  FETCH_CONTACTS_SUCCESS,
  UPDATE_CONTACT_SUCCESS
} from "../actions/types";
import { success } from "./utlities";

const contacts = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_SUCCESS:
      return success(state, action);
    case UPDATE_CONTACT_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default contacts;

import React, { useEffect, useState,  } from 'react';
import { Container, Row, Col, Collapse } from "react-bootstrap";
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {useGA4React} from "ga-4-react";
import { FaCloudDownloadAlt, FaApple } from "react-icons/fa";
import {MdAndroid} from "react-icons/md"
import _ from 'lodash';
import {SavLabel, SettingRow, SavInput} from "../../components/Styled";
import {parseDateShort} from '../../components/Utilities';
import SavCheck from "../../components/SavCheck";
import Host from "../../images/host.svg"
import BetaDeployModal from './BetaDeployModal';
import ReactGA from 'react-ga';


const ProgressBar = styled.div`
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  align-items:center;
  background-color: #979797;
  border-radius: 25px;
`;
const ProgressDiv = styled.div`
  height:65px;
  text-align:center;
  color:#fff;
  background-color:${props => (props.bg ? props.bg : "#979797")}
  border-right: ${props => (props.edge === 1 ? "3px solid white" : null)};
  border-right: ${props => (props.edge === 2 ? "3px solid white" : null)};
  padding:20px;
  border-top-left-radius: ${props => (props.edge === 1 ? "25px" : null)};
  border-bottom-left-radius: ${props => (props.edge === 1 ? "25px" : null)};
  border-top-right-radius: ${props => (props.edge === 3 ? "25px" : null)};
  border-bottom-right-radius: ${props => (props.edge === 3 ? "25px" : null)};;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:"Gotham-Medium";
`;
const Header = styled.div` 
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-transform:uppercase;
  text-align: center;
`;
const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;
const ReleaseHeader = styled.div`
  font-size:24px;
  font-family:"Gotham-Medium";
`;
const ReleaseSubHeader = styled.div`
  font-size:18px;
  font-family:"Gotham-Medium";
  margin-top:20px;
  text-transform:uppercase;
`;
const AppIcon = styled.img`
  border: 1px solid #fff;
  border-radius: 4px;
  height:50px;
`;
const StepDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 117px;
`;

const MyBeta = ({account, user, updateContact, fetchUser, setSuccessToast, beta, software, latestSoftware, sam, content}) => {

  const [step, setStep] = useState(1);
  const [openUid, setOpenUid] = useState(false);
  const [betaUID, setBetaUID] = useState("")
  const [latestBeta, setLatestBeta] = useState({});
  const [showDeployModal, setShowDeployModal] = useState(false);
  const ga = useGA4React();

  useEffect(() => {
    if (software){
      const prodSoftware = _.filter(software, (s) => s.type === "daVinci Beta");
      if (prodSoftware){
        setLatestBeta(prodSoftware[0]);
      }
    }
  },[software]);

  useEffect(() => {
    if (user && account){
      let tempStep = 0;
      setBetaUID(user.contact.betaHostUid);

      if (user.contact.betaAccess && _.isEmpty(user.contact.betaHostUid)){
        tempStep = 1;
      }
      if (!_.isEmpty(user.contact.betaHostUid) && user.contact.betaAccess && user.contact.betaHostStatus !== 'Found Beta Host'){
        tempStep = 2;
      }
      if (!_.isEmpty(user.contact.betaHostUid) && user.contact.betaAccess && user.contact.betaHostStatus === 'Found Beta Host'){
        tempStep = 3;
      }
      setStep(tempStep)
    }
  },[user, account]);

  const handleUpdateBetaAccess = () => {
    const userJSON = {...user.contact};

    if (!userJSON.betaAccess === true){
      ReactGA.event({
        category: 'Beta',
        action: 'Signed Up for Beta'
      });
    }
    
    userJSON.betaAccess = !userJSON.betaAccess;
    updateContact(userJSON).then( () => {
      fetchUser();
    });

    
    
  };
  const handleUpdateUser = () => {
    const userJSON = {...user.contact};
    userJSON.betaHostUid = betaUID;
    updateContact(userJSON).then((val) => {
      fetchUser();
      setTimeout(() => {
        fetchUser()
      },5000)
    })
    setOpenUid(false);
  };
  const createMarkup = richText => {
    return { __html: richText };
  };

  const renderHostStatus = () => {
    if (user.contact.betaHostStatus === 'Querying Cloud... Please wait and refresh.'){
      return (
        <div className="wip f18 med">
          Syncing...
        </div>
      )
    }else if (user.contact.betaHostStatus === 'Found Beta Host'){
      return (
        <div className="green2 f18 med">
          Host Found
        </div>
      )
    }else {
      return (
        <div className="red f18 med">
          Host Not Found
        </div>
      )
    }
  };

  const renderBetaStep = () => {
    if (step === 0){
      return (
        <StepDiv className="book text-center ">
          Opt in to the Savant Beta Program via the checkbox under "Beta Information"
        </StepDiv>
      )
    }
    if (step === 1){
      return (
        <StepDiv className="book text-center ">
          <div>
            1.  Follow instructions in the <span className="med oj onHov" onClick={() =>setShowDeployModal(true)}>Savant Beta Host Deployment Guide.</span><br /><br />
            2.  Populate your Beta host UID under "Beta Information"
          </div>
        </StepDiv>
      )
    }
    if (step === 2 && user.contact.betaHostStatus !== 'Querying Cloud... Please wait and refresh.'){
      return (
        <StepDiv className="book text-center ">
          <div>
            <span className="red">Host UID not found</span><br />
            • Ensure the host is running Savant Beta software and that the host has WAN access.<br />
            • Follow instructions in the <span className="med oj onHov" onClick={() =>setShowDeployModal(true)}>Savant Beta Host Deployment Guide.</span><br />
          </div>
        </StepDiv>
      )
    }
    if (step === 2 && user.contact.betaHostStatus === 'Querying Cloud... Please wait and refresh.'){
      return (
        <StepDiv className="book text-center ">
          Syncing UID with Cloud...
        </StepDiv>
      )
    }
    if (step === 3){
      return (
        <StepDiv className="book text-center ">
          You are registered for the Savant Beta Program!
        </StepDiv>
      )
    }
  };

  const renderAppDownloads = () => {

    const renderDownloadIcon = (s) => {
      if (s.playDownload){
        return (
          <a href={`${s.playDownload}`} target="_blank" rel="noopener noreferrer">
            <MdAndroid size="40px" className="fillHover" />
          </a>
        )
      }
      if (s.appleDownload){
        return (
          <a href={`${s.appleDownload}`} target="_blank" rel="noopener noreferrer">
            <FaApple size="45px" className="fillHover" />
          </a>
        )
      }
      if (s.computerDownload){
        return (
          <a href={`${s.computerDownload}`} target="_blank" rel="noopener noreferrer">
          <FaCloudDownloadAlt size="45px"className="fillHover"  />
          </a>
        )
      }
      return null;
    }
    return latestSoftware.map((s) => {
      if (s.isBeta){
        return (
          <SettingRow className="vertical-align" key={s.sfid}>
            <Col xs={2}>
              <AppIcon src={s.img} style={!s.img ? {display:'none'} : null} />
            </Col>
            <Col xs={8}>
              <div className="med f12">{s.name}</div>
              <div className="book f12 lightGray m-t-5">Version: {s.vers}</div>
              <div className="book f12 lightGray ">Released: {parseDateShort(s.release)}</div>
            </Col>
            <Col xs={2}>
              {renderDownloadIcon(s)}
            </Col>
          </SettingRow>
        )
      }
      return null;
    })
  }

  if (!_.isEmpty(user) && software && latestSoftware && content){
    return (
      <Container className="m-t-20">
        <BetaDeployModal show={showDeployModal} hide={() => setShowDeployModal(false)} content={content} />
        <Row>

          <Col md={7}>

            <Container className="m-b-20">
              <SettingRow>
                <Col xs={12}>
                  <Header>Beta Registration Status</Header>
                </Col>
              </SettingRow>
              <SettingRow style={{padding: '18px'}}>
                <Col xs={12}>
                  <ProgressBar>
                    <ProgressDiv edge={1} bg={step > 0 ? "#4cd964" : null}>
                      Step 1<br />
                      Opt in to Beta
                    </ProgressDiv>
                    <ProgressDiv edge={2} bg={step > 1 ? "#4cd964" : null}>
                      Step 2<br />
                      Register Host UID
                    </ProgressDiv>
                    <ProgressDiv edge={3} bg={step > 2 ? "#4cd964" : null}>
                      Step 3<br />
                      Complete
                    </ProgressDiv>
                  </ProgressBar>
                </Col>
              </SettingRow>
              <SettingRow>
                <Col xs={12}>
                  {renderBetaStep()}
                </Col>
              </SettingRow>
            </Container>

            <Container className="m-b-20" style={{backgroundColor:"#fafaf9"}}>
              <SettingRow>
                <Col xs={12}>
                  <Header>Latest Beta Release Info</Header>
                </Col>
              </SettingRow>
              <Row className="vertical-align m-t-20">
                <Col sm={12}>
                  <ReleaseHeader>{latestBeta.name}</ReleaseHeader>
                  <span className="book f12 light-gray">Released {latestBeta.releaseDate}</span><br />
                  <a className="oj linkHover f12" href={latestBeta.readMe} target="_blank">
                    Release ReadMe
                  </a>
                </Col>
              </Row>
              <hr />
              <div className="m-t-20" dangerouslySetInnerHTML={createMarkup(latestBeta.description)} />
              <hr />
              <ReleaseSubHeader>New Features</ReleaseSubHeader>
              <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(latestBeta.newFeatures || "No new features in this release")} />
              <hr />
              <ReleaseSubHeader className="red">Known Issues</ReleaseSubHeader>
              <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(latestBeta.betaKnownIssues || "No known issues in this release")} />
              <hr />
              <ReleaseSubHeader>Enhancements</ReleaseSubHeader>
              <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(latestBeta.betaEnhancements || "No enhancements in this release")} />
              <hr />
              <ReleaseSubHeader>Bug Fixes</ReleaseSubHeader>
              <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(latestBeta.betaBugFixes || "No bug fixes in this release")} />
            </Container>
          </Col>

          <Col md={5}>

            <Container className="m-b-20">
              <SettingRow>
                <Col xs={12}>
                  <Header>Beta Information</Header>
                </Col>
              </SettingRow>
              <SettingRow className="vertical-align">
                <Col sm={6}>
                  <img src={Host} width={100} alt="host" />
                </Col>
                <Col sm={6} className="text-right">
                  {renderHostStatus()}
                </Col>
              </SettingRow>
              <SettingRow>
                <Col xs={4}>
                  <SavLabel>Last Updated</SavLabel>
                </Col>
                <Col xs={8} className="text-right">
                  {user.contact.betaHostLastUpdated ? parseDateShort(user.contact.betaHostLastUpdated) : "N/A"}
                </Col>
              </SettingRow>
              <SettingRow>
                <Col xs={10}>
                  <SavLabel>Beta Software Access</SavLabel>
                </Col>
                <Col xs={2}>
                  <SavCheck checked={user.contact.betaAccess} click={handleUpdateBetaAccess} style={{float:'right'}} center />
                </Col>
              </SettingRow>
              <SettingRow style={openUid ? {borderLeft:"4px solid #ff5f00"}: null}>
                <Col xs={4}>
                  <SavLabel>Beta Host UID</SavLabel>
                </Col>
                <Col xs={6}>
                  {openUid ? null : user.contact.betaHostUid}
                  <Collapse in={openUid}>
                    <div id="openOrderContact" className="text-center">
                      <SavInput value={betaUID || ""} onChange={(e) => setBetaUID(e.target.value)} />
                      <br /><br />
                      <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateUser()}>Save</button>
                      <br />
                      <button className="sav-btn__gray" style={{border:"none", width:"100%"}} onClick={() => setOpenUid(false)}>Cancel</button>
                    </div>
                  </Collapse>
                </Col>
                <Col xs={2} className="text-center">
                  <EditSpan onClick={()=> setOpenUid(!openUid)} aria-controls="openDBA" aria-expanded={openUid}>{openUid ? "Close" : "Edit"}</EditSpan>
                </Col>
              </SettingRow>
            </Container>

            <Container className="m-b-20" style={step !== 3 ? null : null}>
              <SettingRow>
                <Col xs={12}>
                  <Header>Applications</Header>
                </Col>
              </SettingRow>
              {renderAppDownloads()}
            </Container>
            <Container className="m-t-20">
              <SettingRow>
                <Col xs={12}>
                  <Header>BETA TESTING BEST PRACTICES</Header>
                </Col>
              </SettingRow>
              <SettingRow>
                <Col xs={12} className="f14">
                  <ol>
                    <li>
                    Always update to the latest software as soon as it is available. Several beta 
                    software "drops" will be available prior to an official release,  each considering 
                    tester feedback and including new features that are under development.
                    </li>
                    <br />
                    <li>
                    Read the appropriate Beta topic on the SavantProgrammers Forum to join in on the discussion – report bugs, suggest features and learn from other beta testers.  
                    </li>
                    <br />
                    <li>
                    Post to the appropriate topic for the software feature currently being tested. 
                    Access will be given to a specific hardware beta board if needed.
                    </li>
                    <br />
                    <li>
                    The Savant Engineering team may request a Beta Support Case be created when
                    more information is needed to diagnose and resolve an issue. Submit a Beta 
                    Support Case and include steps to reproduce the issue, Host logs, and a copy of 
                    the configuration where the issue arose.
                    </li>
                    <br />
                    <li>
                    Beta surveys provide Savant with important information about the beta 
                    experience. Please complete these as soon as possible. 
                    </li>
                    <br />
                    <li>
                    Note on Confidentiality: Please do not comment on or share details publicly (including social media) about upcoming, unreleased Savant software or hardware.  
                    </li>
                  </ol>
                </Col>
              </SettingRow>
            </Container>

          </Col>
        </Row>
        
  
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col md={7}>
          <Skeleton count={1} height={250} />
          <Skeleton count={40} />
        </Col>
        <Col md={5}>
          <Skeleton count={3} height={250} />
        </Col>  
      </Row>
    </Container>
  )

}

export default MyBeta;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import {createLoadingSelector, visibilitySelector} from "../../selectors";
import {ZeroStateText} from "../../components/Styled";
import {fetchAccount, createTask, setSuccessToast} from "../../actions";
import SavBanner from "../../components/SavBanner";
import SavFormDrop from "../../components/SavFormDrop";
import SavFormInput from "../../components/SavFormInput";
import SavFormInputArea from "../../components/SavFormInputArea";
import SavCheck from "../../components/SavCheck";
import states from "../../components/Utilities/states.json";
import provinces from "../../components/Utilities/provinces.json";
import SavToast from "../../components/SavToast";
import SavToastSuccess from "../../components/SavToastSuccess";
import SavToastFail from "../../components/SavToastFail";

const SectionHeader = styled.h2`
  margin: 40px 0px 20px;
  text-align:center;
  text-transform: uppercase;
`;
const checkGroup = {display: 'flex', justifyContent:'flex-start'};
const hidden = {
  display: 'none'
}

const countries = [
  "United States",
  "Canada",
];
const selectPeople = [
  "2-5",
  "5-10",
  "10-25",
  "25-50",
  "50+",
]
const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form  />
  )
});



const DirectDealerPromotion = ({user, account, fetchAccount, fetchingAccount, fetchingUser, createTask}) => {
  const [validated, setValidated] = useState(false);
  // const [formState, dispatch] = useReducer(formReducer, initFormState);
  const [businessName, setBusinessName] = useState("");
  const [businessDBA, setBusinessDBA] = useState("");
  const [businessYear, setBusinessYear] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressCountry, setAddressCountry] = useState("");
  const [addressZip, setAddressZip] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactTitle, setContactTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [integratorBool, setIntegratorBool] = useState("");
  const [integratorBrands, setIntegratorBrands] = useState([]);
  const [integratorBrandsOther, setIntegratorBrandsOther] = useState([]);
  const [integratorBrandsBattery, setIntegratorBrandsBattery] = useState("");
  const [integratorSales, setIntegratorSales] = useState("");
  const [businessRevenue, setBusinessRevenue] = useState("");
  const [businessTarget, setBusinessTarget] = useState("");
  const [businessShowroom, setBusinessShowroom] = useState("");
  const [businessMarkets, setBusinessMarkets] = useState("");
  const [businessNewMarkets, setBusinessNewMarkets] = useState("");
  const [teamTotal, setTeamTotal] = useState("");
  const [teamInstallers, setTeamInstallers] = useState("");
  const [teamSales, setTeamSales] = useState("");
  const [teamSavantInstallers, setTeamSavantInstallers] = useState("");
  const [teamElectricians, setTeamElectricians] = useState("");
  const [jobsPerYear, setJobsPerYear] = useState("");
  const [jobAverageQuote, setJobAverageQuote] = useState("");
  const [jobAverageHome, setJobAverageHome] = useState("");
  const [percentageDistro, setPercentageDistro] = useState("");
  const [percentageDirect, setPercentageDirect] = useState("");
  const [percentageRetail, setPercentageRetail] = useState("");
  const [percentageBattery, setPercentageBattery] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [signed, setSigned] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [hasOpenRequest, setHasOpenRequest] = useState(false);

  useEffect(() => {
    if (_.isEmpty(account) && !fetchingAccount) {
      fetchAccount();
    } else {
      setBusinessName(account.name);
      setBusinessDBA(account.dba || '');
      setBusinessPhone(account.phone || '');
      setBusinessWebsite(account.website || '');

      let tempReqBool = false;
      account.communityRequests.forEach((r) => {
        if (r.taskType.includes('Electrical Contractor')){
          tempReqBool = true;
        }
      });
      setHasOpenRequest(tempReqBool);
    }
  },[account]);


  const showPage = useSelector(visibilitySelector('marketing_dd_promotion'));
  if (!showPage && !fetchingUser){
    return (
      <ZeroStateText margin="200px">
        You do not have permission to view this page.
        <div className="text-center m-t-20">
          <Link to="/">
            <button className="sav-btn__orange">Return Home</button>
          </Link>
        </div>
      </ZeroStateText>
    )
  };
  if (fetchingUser){
    return (
      <div>
        <Skeleton count={1} height={120} />
        <Container>
          <Skeleton count={20} height={50} style={{marginTop: 50}} />
        </Container>
      </div>
      
    )
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const message = `
      --BUSINESS INFO--

      Account Name:
      ${businessName}

      DBA:
      ${businessDBA}

      Year Started:
      ${businessYear}

      Address:
      ${addressStreet}
      ${addressCity}, ${addressState}, ${addressZip}
      ${addressCountry}

      Business Phone:
      ${businessPhone}

      Website:
      ${businessWebsite}


      --MAIN CONTACT INFO--

      First Name:
      ${contactFirstName}

      Last Name:
      ${contactLastName}

      Title:
      ${contactTitle}

      Email:
      ${contactEmail}

      Mobile Phone:
      ${contactPhone}


      --SALES & PRODUCT OFFERING--

      Do you currently re-sell energy solutions, icnluding solar, generate, Battery Energy Storage Systems, and/or flexible load management?:
      ${integratorBool}

      Top Focus Areas:
      ${integratorBrands}
      ${integratorBrandsOther}

      What brands of batteries and inverters do you currently sell?
      ${integratorBrandsBattery}

      What volume of energy/power business did your company do in the past 12 months?
      ${integratorSales}


      --Business Overview--

      What was your total business revenue last year?
      ${businessRevenue}

      What is your target number of net new customers planned over the next 12 months?
      ${businessTarget}

      Do you own and operate a displaying location or showroom?
      ${businessShowroom}

      List the geographic market(s) that your business is currently serving.
      ${businessMarkets}

      How do you currently go to market and create new business?
      ${businessNewMarkets}


      --Staff/Team Profile--

      How many total employees are on staff?
      ${teamTotal}

      How many installers are on staff?
      ${teamInstallers}

      How many sales people are staff?
      ${teamSales}

      Do you have any Savant Certified Smart Home installers on staff?
      ${teamSavantInstallers}

      How many electricians are on staff?
      ${teamElectricians}


      --Project Profile--

      How many jobs do you average per year?
      ${jobsPerYear}

      What is your average job/project quote value?
      ${jobAverageQuote}

      What is the average home value your company targets?
      ${jobAverageHome}

      What percentage of your projects have battery storage?
      ${percentageBattery}%


      --Purchase Model--

      Please indicate what percentage of your product solutions you currently purchase through:
      ${percentageDistro}% Distributor
      ${percentageDirect}% Direct
      ${percentageRetail}% Retail
      


      --Referral Code--
      ${referralCode}
    `;

    const taskJSON = {
      taskType: "Electrical Contractor - Direct Request",
      comments: message
    }
    createTask(taskJSON).then((val) => {
      fetchAccount();
      //setSuccessToast(true);
    });
  }

  const handleCheckBrands = (brand) => {
    const brandFound = _.find(integratorBrands, (b) => b === brand);
    const brandsCopy = _.cloneDeep(integratorBrands);

    if (brandFound){
      const brandsRemoved = _.remove(brandsCopy, (b) => b === brand);
      setIntegratorBrands(brandsCopy);
    } else {
      brandsCopy.push(brand);
      setIntegratorBrands(brandsCopy);
    }
  }

  if (showPage && !_.isEmpty(account)){

    if (!hasOpenRequest){
      return (
        <div>
          <SavBanner text="Join our team" padding="40px" />
          <SavToast />
          <SavToastFail />
          <SavToastSuccess />
          <Container style={{maxWidth: 1000}}>
            <div className="text-center m-b-40 m-t-40">
              Interested in a wider array of energy solutions for your clients, including pre-configured BESS packages directly from Savant? Tell us more about your business below and apply to be a Savant Authorized Contractor.
            </div>
            <Form onSubmit={handleSubmit} noValidate validated={validated} className="sav-form m-t-20">
  
              <SectionHeader>Business Info</SectionHeader>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={businessName} setValue={setBusinessName} label="Legal Company Name" />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <SavFormInput value={businessDBA} setValue={setBusinessDBA} label="DBA" notRequired />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <SavFormInput value={businessYear} setValue={setBusinessYear} label="Year Started" type="number" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={12}>
                  <SavFormInput value={addressStreet} setValue={setAddressStreet} label="Mailing Address" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={3}>
                  <SavFormInput value={addressCity} setValue={setAddressCity} label="City" />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <SavFormDrop required validated={validated} value={addressState} setValue={setAddressState} label={addressCountry === 'Canada' ? 'Province' : 'State'} opts={addressCountry === 'Canada' ? provinces : states} objectOpts={true} />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <SavFormDrop required validated={validated} value={addressCountry} setValue={setAddressCountry} label="Country" opts={countries} />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <SavFormInput value={addressZip} setValue={setAddressZip} label="Zip Code" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={businessPhone} setValue={setBusinessPhone} label="Business Phone No." />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput notRequired value={businessWebsite} setValue={setBusinessWebsite} label="Website" />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Main Contact Info</SectionHeader>
              <Form.Row>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={contactFirstName} setValue={setContactFirstName} label="First Name" />
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={contactLastName} setValue={setContactLastName} label="Last Name" />
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={contactTitle} setValue={setContactTitle} label="Title" notRequired />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={contactEmail} setValue={setContactEmail} label="Email" />
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={contactPhone} setValue={setContactPhone} label="Mobile Phone No." />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Sales & Product Offering</SectionHeader>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  Do you currently re-sell energy solutions, including solar, generate, Battery Energy Storage Systems, and/or flexible load management?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={integratorBool} setValue={setIntegratorBool} label="Select yes or No" opts={['Yes', 'No']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  If yes, please indicate your top focus areas:
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Group as={Col} sm={12} style={checkGroup}>
                    <Form.Control as={SavCheckForm} required checked={integratorBrands.includes('Battery Energy Storage Systems')} click={() => handleCheckBrands('Battery Energy Storage Systems')} />
                    <Form.Label onClick={() => handleCheckBrands('Battery Energy Storage Systems')} style={{marginLeft: 10}}>Battery Energy Storage Systems</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} style={checkGroup}>
                    <Form.Control as={SavCheckForm} required checked={integratorBrands.includes('PV Systems')} click={() => handleCheckBrands('PV Systems')} />
                    <Form.Label onClick={() => handleCheckBrands('PV Systems')} style={{marginLeft: 10}}>PV Systems</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} style={checkGroup}>
                    <Form.Control as={SavCheckForm} required checked={integratorBrands.includes('EV Charging')} click={() => handleCheckBrands('EV Charging')} />
                    <Form.Label onClick={() => handleCheckBrands('EV Charging')} style={{marginLeft: 10}}>EV Charging</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} style={checkGroup}>
                    <Form.Control as={SavCheckForm} required checked={integratorBrands.includes('Flexibile Load Management')} click={() => handleCheckBrands('Flexibile Load Management')} />
                    <Form.Label onClick={() => handleCheckBrands('Flexibile Load Management')} style={{marginLeft: 10}}>Flexibile Load Management</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm={12} style={checkGroup}>
                    <Form.Control as={SavCheckForm} required checked={integratorBrands.includes('Back-up Generators')} click={() => handleCheckBrands('Back-up Generators')} />
                    <Form.Label onClick={() => handleCheckBrands('Back-up Generators')} style={{marginLeft: 10}}>Back-up Generators</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <Form.Label>Other (please list below)</Form.Label>
                    <SavFormInputArea notRequired value={integratorBrandsOther} setValue={setIntegratorBrandsOther} />
                  </Form.Group>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={12}>
                  What brands of batteries and inverters do you currently sell?
                  <SavFormInputArea notRequired={integratorBool === 'No' ? true : false } value={integratorBrandsBattery} setValue={setIntegratorBrandsBattery} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={6}>
                  What volume of energy/power business did your company do in the past 12 months?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={integratorSales} setValue={setIntegratorSales} label="Select One" opts={[' < $25,000','$25,000 - $100,000', '$100,000 - $250,000', '$250,000 - $750,000']} required validated={validated} />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Business Overview</SectionHeader>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  What was your total business revenue last year?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={businessRevenue} setValue={setBusinessRevenue} label="Select One" opts={[' < $250,000','$250,000 - $500,000', '$500,000 - $1M', '$1M - $2.5M', '$2.5M+']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  What is your target number of net new customers planned over the next 12 months?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={businessTarget} setValue={setBusinessTarget} label="Select One" opts={[' < 100','100 - 500', '500 - 1000', '1000+']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                Do you own and operate a displaying location or showroom?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={businessShowroom} setValue={setBusinessShowroom} label="Select yes or No" opts={['Yes', 'No']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={12}>
                  List the geographic market(s) that your business is currently serving.
                  <SavFormInputArea notRequired value={businessMarkets} setValue={setBusinessMarkets} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={12}>
                  How do you currently go to market and create new business?
                  <SavFormInputArea notRequired value={businessNewMarkets} setValue={setBusinessNewMarkets} />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Staff/Team Profile</SectionHeader>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  How many total employees are on staff?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={teamTotal} setValue={setTeamTotal} label="Select One" opts={selectPeople} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  How many installers are on staff?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={teamInstallers} setValue={setTeamInstallers} label="Select One" opts={selectPeople} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  How many sales people are staff?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={teamSales} setValue={setTeamSales} label="Select One" opts={selectPeople} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  Do you have any Savant Certified Smart Home installers on staff?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={teamSavantInstallers} setValue={setTeamSavantInstallers} label="Select yes or No" opts={['Yes', 'No']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  How many electricians are on staff?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={teamElectricians} setValue={setTeamElectricians} label="Select One" opts={selectPeople}required validated={validated} />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Project Profile</SectionHeader>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  How many jobs do you average per year?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={jobsPerYear} setValue={setJobsPerYear} label="Select One" opts={['< 50', '50 - 100', '100-250', '250-500', '500+']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  What is your average job/project quote value?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={jobAverageQuote} setValue={setJobAverageQuote} label="Select One" opts={['$0 - $5k', '$5k - $15k', '$15k - $50k', '$50k - $250k', '$250k+']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  What is the average home value your company targets?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormDrop value={jobAverageHome} setValue={setJobAverageHome} label="Select One" opts={['$150k - $250k', '$250k - $500k', '$500k - $1M', '$1M - $5M', '$5M+']} required validated={validated} />
                </Form.Group>
              </Form.Row>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  What percentage of your projects have battery storage?
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput value={percentageBattery} setValue={setPercentageBattery} label="% Battery Storage" />
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Purchase Model</SectionHeader>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  Please indicate what percentage of your product solutions you currently purchase through:
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <Form.Group as={Col} sm={12}>
                    <SavFormInput value={percentageDistro} setValue={setPercentageDistro} label="% Distributor" />
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <SavFormInput value={percentageDirect} setValue={setPercentageDirect} label="% Direct from Manufacturer" />
                  </Form.Group>
                  <Form.Group as={Col} sm={12}>
                    <SavFormInput value={percentageRetail} setValue={setPercentageRetail} label="% Retail" />
                  </Form.Group>
                </Form.Group>
              </Form.Row>
  
              <SectionHeader>Referral Code</SectionHeader>
              <Form.Row className="vertical-align">
                <Form.Group as={Col} sm={6}>
                  If you have received a referral code, please enter it here:
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                  <SavFormInput notRequired value={referralCode} setValue={setReferralCode} label="Referral Code" />
                </Form.Group>
              </Form.Row>
  
              <div className="m-t-20 text-center">
                <button className="sav-btn__orange--fill" type="submit">
                  Submit
                </button>
              </div>
  
            </Form>
  
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <SavBanner text="Application Submitted!" padding="40px" />
          <Container>
            <div className="m-t-40 text-center">
              <h1>THANK YOU</h1>
              We've received your information and will be in touch shortly with an update on your application.
            </div>
          </Container>
        </div>
        
      )
    }
    
  }
  return (
    <div>
      <Skeleton count={1} height={120} />
      <Container>
        <Skeleton count={20} height={50} style={{marginTop: 50}} />
      </Container>
    </div>
  )
};

const fetchingUser = createLoadingSelector(["FETCH_USER"]);
const fetchingAccount = createLoadingSelector(["FETCH_ACCOUNT"]);
const mapStateToProps = state => {
  return {
    user: state.user,
    account: state.account.data,
    fetchingUser: fetchingUser(state),
    fetchingAccount: fetchingAccount(state),
  };
};
export default connect(mapStateToProps, {fetchAccount, createTask})(DirectDealerPromotion);
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const Loader = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} className="text-center">
          <div className="loader"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Loader;
import React, { useState, useEffect } from "react";
import {Nav, Tab } from "react-bootstrap";
import SavBanner from "../../components/SavBanner";
import { fetchLatestSoftware, fetchSoftware, fetchAccount, setSuccessToast, updateContact, fetchUser, fetchBeta, fetchContent } from "../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import BetaReleaseHistory from "./BetaReleaseHistory";
import MyBeta from "./MyBeta";
import SavToast from '../../components/SavToast'

const tabActive = {
  color: "#ff5f00"
};

const Beta = ({latestSoftware, fetchLatestSoftware, software, fetchSoftware, account, fetchAccount, user, setSuccessToast, updateContact, fetchUser, fetchBeta, beta,fetchContent, content}) => {

  const [tabKey, setTabKey] = useState("myBeta");
  const [sam, setSam] = useState(null);
  const [hideSAM, setHideSAM] = useState(false);

  useEffect(() => {
    document.title = "Savant Community | Beta"
  },[]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (user.data.contact.accType === 'Non-Direct' && user.data.contact.accSubType === 'US Distribution'){
        setHideSAM(true);
      }
    }
  },[user]);

  useEffect(() => {
    if (_.isEmpty(software)) {
      fetchSoftware();
    }
    if (_.isEmpty(latestSoftware)) {
      fetchLatestSoftware();
    }
    if (_.isEmpty(account)){
      fetchAccount();
    }
    if (_.isEmpty(beta)){
      fetchBeta();
    }
    if (_.isEmpty(content)){
      fetchContent();
    }
  },[]);

  useEffect(() => {
    if (latestSoftware){
      const temp = _.find(latestSoftware.data, (s) => s.name === "Savant Application Manager");
      setSam(temp);
    }
  },[latestSoftware]);

  

  return (
    <div>
      <SavBanner text="Savant Beta" padding="40px" />
      <SavToast />
      <Tab.Container
          id="software-tabs"
          activeKey={tabKey}
          onSelect={k => setTabKey(k)}
        >
          <Nav className="justify-content-center m-t-20">
            <Nav.Item>
              <Nav.Link
                className="tab-link-overview"
                eventKey="myBeta"
                style={tabKey === "myBeta" ? tabActive : null}
              >
                My Beta
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="tab-link-overview"
                eventKey="history"
                style={tabKey === "history" ? tabActive : null}
              >
                Beta Release History
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="myBeta">
              <MyBeta account={account} user={user.data} fetchUser={fetchUser} setSuccessToast={setSuccessToast} updateContact={updateContact} beta={beta.data} software={software.data} sam={sam} latestSoftware={latestSoftware.data} content={content.data} />
            </Tab.Pane>
            <Tab.Pane eventKey="history">
              <BetaReleaseHistory software={software.data} sam={sam} hideSAM={hideSAM} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
  );
};

const mapStateToProps = state => {
  return {
    latestSoftware: state.latestSoftware,
    software: state.software,
    account: state.account,
    user: state.user,
    beta: state.beta,
    content: state.content
  };
};

export default connect(mapStateToProps, {
  fetchLatestSoftware, fetchSoftware, fetchAccount, setSuccessToast, updateContact, fetchUser, fetchBeta, fetchContent
})(Beta);


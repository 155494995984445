import { FETCH_CASE_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const activeCase = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CASE_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default activeCase;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Dropdown, Table, Modal, Form } from "react-bootstrap";
import SavDrop from "../SavDrop";
import { FaCaretDown } from "react-icons/fa";
import _ from "lodash";

const SavDropToggle = styled.div`
  background-color: #fff;
  color: #979797;
  width: 100%;
  height: 42px;
  border: 1px solid #979797;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding:12px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SavFormDrop = ({value, setValue, label, opts, validated, required, objectOpts }) => {
  if (!objectOpts){
    return (
      <div className="input-container-drop-nofilter">
        <Dropdown className="savDrop">
          <Dropdown.Toggle id="dropdown-basic" as="div">
            <SavDropToggle className={validated && required && _.isEmpty(value) ? "savDrop-invalid" : validated && required && !_.isEmpty(value) ? "savDrop-valid" : null}>
              <div style={{marginTop:10}}>{value}</div>
              <FaCaretDown />
            </SavDropToggle>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%", transform: "translate(0px,60px)" }}>
            {opts.map((o) => {
              return (
                <Dropdown.Item
                  as="div"
                  href="#"
                  key={o}
                  name={o}
                  onClick={() => setValue(o)}
                  className="onHov"
                  style={{ borderBottom: "1px solid #DBD9D6" }}
                >
                  <span style={{ display: "inline-flex" }}>{o}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <label className={value && 'filled'}>{label}</label>
      </div>
    )
  }
  return (
    <div className="input-container-drop-nofilter">
      <Dropdown className="savDrop">
        <Dropdown.Toggle id="dropdown-basic" as="div">
          <SavDropToggle className={validated && required && _.isEmpty(value) ? "savDrop-invalid" : validated && required && !_.isEmpty(value) ? "savDrop-valid" : null}>
            <div style={{marginTop:10}}>{value}</div>
            <FaCaretDown />
          </SavDropToggle>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: "100%", transform: "translate(0px,60px)" }}>
          {opts.map((o) => {
            return (
              <Dropdown.Item
                as="div"
                href="#"
                key={o.code}
                name={o.name}
                onClick={() => setValue(o['code'])}
                className="onHov"
                style={{ borderBottom: "1px solid #DBD9D6" }}
              >
                <span style={{ display: "inline-flex" }}>{o.name}</span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <label className={value && 'filled'}>{label}</label>
    </div>
  )
  
}

export default SavFormDrop
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import _ from "lodash";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaYoutube,
} from "react-icons/fa";

const rowStyle = {
  backgroundColor: "#f9f9f9",
  borderTop: "1px solid #979797",
  borderBottom: "1px solid #979797",
  padding: "10px 0px",
  marginTop: "-1px",
};

const SocialContainer = styled(Container)`
  padding: 30px 30px;
  // background-color: #f9f9f9;
`;

const SocialComponent = (props) => {
  return (
      <SocialContainer>
        <Row className="text-center">
          <Col className="text-center">
            <a href="https://www.facebook.com/OfficialSavant/" target="_blank">
              <FaFacebookSquare fill="#3b5998" size="2em" />
            </a>
          </Col>
          <Col>
            <a href="https://www.instagram.com/savantsystems" target="_blank">
              <FaInstagram fill="#bc2a8d" size="2em" />
            </a>
          </Col>
          <Col>
            <a href="https://twitter.com/savanthome" target="_blank">
            <FaTwitterSquare fill="#00aced" size="2em" />
            </a>
          </Col>
          <Col>
            <a href="https://www.youtube.com/channel/UCKdfbTQU6WcWxBpKC-NasNw" target="_blank">
              <FaYoutube fill="#ff0000" size="2em" />
            </a>
          </Col>
        </Row>
      </SocialContainer>
  )

}

export default SocialComponent;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight
} from "react-icons/fa";

const PageButton = styled.button`
  font-family: "Gotham-Medium";
  color: #979797;
  background-color: #FFFFFF;
  padding: 6px;	
  font-size: 14px;
  border: none;
  min-width: 25px;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #ff5f00;
  }
`;

const SavPagination = props => {
  const { data, dataLength, currentPage, setCurrentPage } = props;

  const [pageNumbers, setPageNumbers] = useState([]);

  // useEffect(() => {
  //   const tempPages = [];
  //   if (data) {
  //     if (currentPage < 6) {
  //       let max = 5;
  //       if (data.length < max) {
  //         if (data.length === 2){
  //           max = 1;
  //         } else {
  //           max = data.length;
  //         }
  //       }
  //       for (let i = 1; i <= max; i++) {
  //         tempPages.push(i);
  //       }
  //     } else {
  //       let max = currentPage + 2;
  //       let min = currentPage - 2;
  //       if (max > data.length) {
  //         min = currentPage - (2 + max - data.length);
  //         max = data.length;
  //       }
  //       for (let i = min; i <= max; i++) {
  //         tempPages.push(i);
  //       }
  //     }
  //   }
  //   setPageNumbers(tempPages);
  // }, [currentPage, data]);

  useEffect(() => {
    const tempPages = [];
    if (data) {
      if (currentPage < 6) {
        let max = 5;
        if (data.length < max) {
          if (data.length === 2){
            max = 2;
          } else {
            max = data.length;
          }
        }
        for (let i = 1; i <= max; i++) {
          tempPages.push(i);
        }
      } else {
        let max = currentPage + 2;
        let min = currentPage - 2;
        if (max > data.length) {
          min = currentPage - (2 + max - data.length);
          max = data.length;
        }
        for (let i = min; i <= max; i++) {
          tempPages.push(i);
        }
      }
    }
    setPageNumbers(tempPages);
  }, [currentPage, data]);


  if (data) {
    if (data.length > 1) {
      return (
        <Container>
          <Row>
            <Col xs={12} className="text-right">
              <div style={data.length < 1 ? { display: "none" } : null}>
                <PageButton onClick={() => setCurrentPage(1)}>
                  <FaAngleDoubleLeft />
                </PageButton>
                <PageButton
                  onClick={
                    currentPage !== 1
                      ? () => setCurrentPage(currentPage - 1)
                      : () => setCurrentPage(1)
                  }
                >
                  <FaAngleLeft />
                </PageButton>
                {pageNumbers.map((s, index) => {
                  return (
                    <PageButton
                      className="med  pad4 onHov f14"
                      key={index}
                      onClick={() => setCurrentPage(s)}
                      style={
                        currentPage === s
                          ? {
                              textDecoration: "underline",
                              fontSize: 16,
                              color: "#ff5f00"
                            }
                          : null
                      }
                    >
                      {s}
                    </PageButton>
                  );
                })}
                <PageButton>
                  <FaAngleRight
                    onClick={
                      currentPage !== data.length
                        ? () => setCurrentPage(currentPage + 1)
                        : () => setCurrentPage(data.length)
                    }
                  />
                </PageButton>
                <PageButton onClick={() => setCurrentPage(data.length)}>
                  <FaAngleDoubleRight />
                </PageButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-right book  f10">
              {dataLength} total results
            </Col>
          </Row>
        </Container>
      );
    }
  }
  return null;
};

export default SavPagination;

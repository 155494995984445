import { FETCH_USER_SUCCESS, UPDATE_AGREEMENT_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const user = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return success(state, action);
    case UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.user.payload,
        success: action.payload.user.success,
        errors: action.payload.user.errors,
        messages: action.payload.user.messages
      };
    default:
      return state;
  }
};

export default user;

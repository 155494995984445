import {CREATE_CONTACT_SUCCESS, CREATE_NOTIFICATION_SUCCESS} from "../actions/types";


export const success = (state = {}, action) => {

  const { type, payload } = action;
  const matches = /(.*)_(SUCCESS)/.exec(type);
  if (!matches) return state;

  if (action.type === CREATE_CONTACT_SUCCESS){
    return {
      ...state,
      CREATE_CONTACT_SUCCESS: 'Success! Contact has been added and a notification will be sent.'
      
    }
  }
  if (action.type === CREATE_NOTIFICATION_SUCCESS){

    return {
      ...state,
      CREATE_NOTIFICATION_SUCCESS: `Success!  A notification to enroll in ${payload} has been sent`
      
    }
  }

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving _FAILURE
    //      else clear errorMessage when receiving _REQUEST
    [requestName]: requestState === "SUCCESS" ? "SUCCESS!" : ""
  };
};
  
  export default success;
  
import React from "react";
import styled from "styled-components";
import _ from 'lodash';
import {parseCurrency, kFormatter} from "../Utilities";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label
} from "recharts";

const VIRProgressMini = props => {
  const { account } = props;
  const today = new Date();
  const quarter = Math.floor(today.getMonth() / 3) + 1;
  const virSales = `salesQ${quarter}`;
  const year = today.getFullYear();
  let currentQuarter;
  if (!_.isEmpty(account)){
    currentQuarter = _.find(account.salesProgramResults, (q) => q.programQuarter === quarter && q.year === year && q.type === 'VIR' );
  }
  let fakeQuarter = {
    year: year,
    virPercentEarned: 0,
    virNextTierPercent: 0,
    virNextTierGoal: 0,
    virEarned: 0,
    validTier: null,
    type: 'VIR',
    tierEarned: null,
    summaryStart: '2021-04-01',
    summaryEnd: '2021-06-30',
    revenuePriorYear: 0,
    revenue: 0,
    programQuarter: quarter,
    program: 'VIR 2021',
    message: '$25,000 to go till next tier!',
    leadsWithSavant: null,
    isBeta: null,
    isActive: false,
    inGoodStanding: false,
    earnedAmbassador: null,
    coreTrainings: null,
    adoptionUiHstLicCtrl: 0,
    adoptionQuarters: null,
    adoptionELSClimate: 0,
    adoptionAudioVideoSpeaker: 0
  }
  if (!currentQuarter){
    currentQuarter = fakeQuarter;
  }

  const data = [
    {
      Sales: currentQuarter.revenue,
      name: `Q${quarter}`
    }
  ];


  const XaxisTick = (props) => {
    const {x,y,payload} = props;
    return (
      <g>
        <text fill="#3f3f3f" fontSize={10} x={x} y={y} dy={10} textAnchor="middle">
          ${kFormatter(payload.value)}
        </text>
      </g>
    )
  };
  const CustomYAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-25} y={y+20} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan y={y+4} dx="1.5em" >{payload.value}</tspan>
      </text>
    )
  }

  const BarLabel = (props) => {
    const {x,y,stroke,value} = props;
    return (
      <text x={x} y={y} dy={10} dx={10} fill="#fff" fontSize={10} textAnchor="middle">${value}</text>
    )
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="book f12">
            Q{quarter} Sales:&nbsp;
          </span>
          <span className="book f12">{parseCurrency(payload[0].value, 2)}</span>
        </div>
      );
    }
    return null;
  };

  const getDomainMax = () => {
    if (currentQuarter.revenue < 50000) {
      return 50000;
    }
    if (currentQuarter.revenue >= 50000 && currentQuarter.revenue < 100000) {
      return 100000;
    }
    if (currentQuarter.revenue >= 100000 && currentQuarter.revenue < 200000) {
      return 200000;
    }
    if (currentQuarter.revenue >= 200000 && currentQuarter.revenue < 250000) {
      return 250000;
    }
    if (currentQuarter.revenue > 250000) {
      return currentQuarter.revenue;
    }
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={100}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 40,
            right: 10,
            left: -30,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" domain={[0, getDomainMax()]} tickCount={2} tick={<XaxisTick />} />
          <YAxis type="category" dataKey="name" tick={<CustomYAxisTick />} />
          <Tooltip content={<CustomTooltip />} />

          <Bar
            dataKey="Sales"
            fill="#565551"
          />
          <ReferenceLine x={50000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={currentQuarter.virAmbassadorQualified? "5%" : "3%"}
              offset={5}
              fontSize={10}
              fill={
                currentQuarter.revenue >= 50000 && currentQuarter.revenue < 100000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
          </ReferenceLine>
          <ReferenceLine x={100000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={currentQuarter.virAmbassadorQualified? "7%" : "5%"}
              offset={5}
              fontSize={10}
              fill={
                currentQuarter.revenue >= 100000 && currentQuarter.revenue < 200000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
          </ReferenceLine>
          <ReferenceLine x={200000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={currentQuarter.virAmbassadorQualified? "10%" : "7%"}
              offset={5}
              fontSize={10}
              fill={
                currentQuarter.revenue >= 200000 && currentQuarter.revenue < 250000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
          </ReferenceLine>
          <ReferenceLine x={250000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={currentQuarter.virAmbassadorQualified? "12%" : "10%"}
              offset={5}
              fontSize={10}
              fill={currentQuarter.revenue >= 250000 ? "#ff5f00" : "#979797"}
            />
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default VIRProgressMini;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import SavOutput from "../../components/SavOutput";
import SavCheck from "../../components/SavCheck";
import SavToggle from "../../components/SavToggle";
import ReactGA from "react-ga";
import { updateContact, setSuccessToast, fetchUser } from "../../actions";
import { connect } from "react-redux";
import { createLoadingSelector } from "../../selectors";

const DetailContainer = styled(Container)`
  background-color: #fafaf9;
  padding: 30px 20px;
`;

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  color: #565551;
  text-transform: uppercase;
`;

const Notifications = props => {
  const { user, updateContact, loading, setSuccessToast, fetchUser } = props;

  const [commSettings, setCommSettings] = useState([]);
  const [isNDD, setIsNDD] = useState(false);
  const [isOrder, setIsOrder] = useState(user.contact.isDefaultOrder);
  const [isBill, setIsBill] = useState(user.contact.isDefaultBilling);

  useEffect(() => {
    if (user) {
      setCommSettings(user.contact.communicationSettings);
      if (user.contact.accType === 'Non-Direct'){
        setIsNDD(true);
      }
      setIsOrder(user.contact.isDefaultOrder);
      setIsBill(user.contact.isDefaultBilling);
    }
  }, [user]);

  const handleClick = (checkboxValue, checkboxName) => {
    const userJSON = user.contact;
    switch (checkboxName) {
      default:
        userJSON.communicationSettings.orderNotification = !checkboxValue;
        break;
      case "orderNotification":
        userJSON.communicationSettings.orderNotification = !checkboxValue;
        break;
      case "orderEmail":
        if(isOrder && commSettings.orderEmail) return;
        userJSON.communicationSettings.orderEmail = !checkboxValue;
        if (checkboxValue === false){
          ReactGA.event({
            category: 'Settings',
            action: 'Order Email Opt Out'
          });
        }
        break;
      case "billingNotification":
        userJSON.communicationSettings.billingNotification = !checkboxValue;
        break;
      case "billingEmail":
        if(isBill && commSettings.billingEmail) return; 
        userJSON.communicationSettings.billingEmail = !checkboxValue;
        if (checkboxValue === false){
          ReactGA.event({
            category: 'Settings',
            action: 'Billing Email Opt Out'
          });
        }
        break;
      case "caseNotification":
        userJSON.communicationSettings.caseNotification = !checkboxValue;
        break;
      case "caseEmail":
        userJSON.communicationSettings.caseEmail = !checkboxValue;
        if (checkboxValue === false){
          ReactGA.event({
            category: 'Settings',
            action: 'Case Email Opt Out'
          });
        }
        break;
      case "salesNotification":
        userJSON.communicationSettings.salesNotification = !checkboxValue;
        break;
      case "salesEmail":
        userJSON.communicationSettings.salesEmail = !checkboxValue;
        if (checkboxValue === false){
          ReactGA.event({
            category: 'Settings',
            action: 'Sales Email Opt Out'
          });
        }
        break;
        case "scmNotification":
          userJSON.communicationSettings.scmPurchasingNotification = !checkboxValue;
          break;
        case "scmEmail":
          userJSON.communicationSettings.scmPurchasingEmail = !checkboxValue;
          if (checkboxValue === false){
            ReactGA.event({
              category: 'Settings',
              action: 'SCM Purchasing Email Opt Out'
            });
          }
          break;



      case "bulletinNotification":
        userJSON.communicationSettings.bulletinNotification = !checkboxValue;
        break;
      case "emailOptOut":
        if (!checkboxValue){
          ReactGA.event({
            category: 'Settings',
            action: 'Email Opt Out'
          });
          userJSON.communicationSettings.caseEmail = false;
          userJSON.communicationSettings.salesEmail = false;
          if (!isOrder) userJSON.communicationSettings.orderEmail = false;
          if (!isBill) userJSON.communicationSettings.billingEmail = false;
        }
        userJSON.emailOptOut = !checkboxValue;
        break;
    }
    if (
      userJSON.communicationSettings.billingEmail &&
      userJSON.communicationSettings.caseEmail &&
      userJSON.communicationSettings.orderEmail &&
      userJSON.communicationSettings.salesEmail &&
      userJSON.emailOptOut
      ){
        userJSON.emailOptOut = false;
      }
    updateContact(userJSON).then(() => {
      setSuccessToast(true);
      fetchUser();
    });
  };

  const renderLoader = loading => {
    if (loading === true) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }
    return null;
  };

  if (user) {
    return (
      <DetailContainer>
        <ReactTooltip className="tooltip-issuetype" />
        {renderLoader(loading)}
        <Row className="vertical-align m-b-20">
          <Col xs={12} style={{display:'flex', alignItems:'center'}}>
            <SavCheck
              checked={user.contact.emailOptOut}
              click={e => handleClick(user.contact.emailOptOut, "emailOptOut")}
              style={{marginRight: 10}}
            />
            <span className="book f12 b4 m-l-5">Opt-Out of All Email</span>
          </Col>
        </Row>
        <Row className="vertical-align">
          <Col xs={4}>
            <Header>Notifications</Header>
          </Col>
          <Col xs={4}>
            <Header>Web</Header>
          </Col>
          <Col xs={4}>
            <Header>Email</Header>
          </Col>
        </Row>
        
        <Row className="vertical-align">
          <Col xs={4}>
            <SavOutput label="Billing" />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.billingNotification} click={() => handleClick(commSettings.billingNotification,"billingNotification")}  />
          </Col>
          <Col xs={4}>
          <span data-multiline={true} data-tip={isBill && commSettings.billingEmail ? 'You are the default billing contact for your account and cannot disable billing emails. <br />The default billing contact can be changed by your admin under Account Settings.' : ''}>
            <SavToggle disabled={isBill && commSettings.billingEmail} checked={commSettings.billingEmail} click={() => handleClick(commSettings.billingEmail,"billingEmail")}  />
            </span>
          </Col>
        </Row>
        <Row className="vertical-align">
          <Col xs={4}>
            <SavOutput label="Cases" />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.caseNotification} click={() => handleClick(commSettings.caseNotification,"caseNotification")}  />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.caseEmail} click={() => handleClick(commSettings.caseEmail,"caseEmail")}  />
          </Col>
        </Row>
        <Row className="vertical-align">
          <Col xs={4}>
            <SavOutput label="Orders" />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.orderNotification} click={() => handleClick(commSettings.orderNotification,"orderNotification")} />
          </Col>
          <Col xs={4}>
          <span data-multiline={true} data-tip={isOrder && commSettings.orderEmail ? 'You are the default order contact for your account and cannot disable order emails. <br />The default order contact can be changed by your admin under Account Settings.' : ''}>
          
            <SavToggle
              checked={commSettings.orderEmail}
              click={() => handleClick(commSettings.orderEmail,"orderEmail")}
              disabled={isOrder && commSettings.orderEmail }
            />
            </span>
          </Col>
        </Row>

        <Row className="vertical-align">
          <Col xs={4}>
           <SavOutput label="Marketing" />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.salesNotification} click={() => handleClick(commSettings.salesNotification,"salesNotification")}  />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.salesEmail} click={() => handleClick(commSettings.salesEmail,"salesEmail")}  />
          </Col>
        </Row>

        <Row className="vertical-align">
          <Col xs={4}>
           <SavOutput label="SCM Purchasing" />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.scmPurchasingNotification} click={() => handleClick(commSettings.scmPurchasingNotification,"scmNotification")}  />
          </Col>
          <Col xs={4}>
            <SavToggle checked={commSettings.scmPurchasingEmail} click={() => handleClick(commSettings.scmPurchasingEmail,"scmEmail")}  />
          </Col>
        </Row>


        <div className="book f10 gray m-t-20">
          *Some transactional email notifications, such as those related to Cases or Orders that you own, cannot be turned off.
        </div>
      </DetailContainer>
    );
  }
  return null;
};

const loadingSelector = createLoadingSelector(["UPDATE_CONTACT"]);

const mapStateToProps = state => {
  return {
    loading: loadingSelector(state)
  };
};

export default connect(mapStateToProps, { updateContact, setSuccessToast, fetchUser })(
  Notifications
);

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import ReactPlayer from "react-player";
import {FaHouseUser, FaRegLightbulb} from 'react-icons/fa'
import {MdSupportAgent, MdNetworkCheck, MdSchool, MdOutlineRollerShadesClosed} from 'react-icons/md';
import {AiOutlineStock, AiOutlineFundProjectionScreen, AiOutlineProject, AiOutlineShoppingCart, AiOutlineCloud} from 'react-icons/ai';
import {GiReceiveMoney, GiArchiveResearch, GiCommercialAirplane, GiPencilRuler, GiWindow} from 'react-icons/gi';
import {FaBookReader, FaPeopleArrows, FaNetworkWired, FaMobileAlt, FaUniversity, FaDesktop, FaPlug, FaReceipt} from 'react-icons/fa';
import {SiJunipernetworks, SiAudiomack} from 'react-icons/si';
import {BsPencilSquare, BsDiagram3, BsGearFill} from 'react-icons/bs';
import {RiRedPacketLine, RiSpeakerLine} from 'react-icons/ri';
import {
  fetchAccount,
  fetchContacts,
  createForm,
} from '../../actions'
import {createLoadingSelector} from "../../selectors";
import ProjectRequestModal from "./ProjectRequestModal";
import SavLogo from "../../images/savant-white.png";
import {Media, Receiver, Music, Camera, Shade, Security, Lighting, Climate, Power, Energy, SavantMusic, Fixture} from '../../components/Styled/icons';
import FixtureIcon from "../../images/fixture.svg"

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
`;
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ServiceIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & svg {
    width: 50px;
  }
`;
const TabCol = styled(Col)`
  background-color: #2A2F3C;
  color: #fff;
  // min-height: 800px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-bottom: 20px;
  > div {
    color: #fff;
  }
`;
const TabColSection = styled.div`
  border: 1px solid #ffff;
  background-color: #262b32;
  color: #fff;
  border-radius: 8px;
  color: #fff;
  min-height: 800px;
  & div {
    color: #fff;
  }
`;
const TabColHeader = styled.div`
  font-family: "Gotham-Medium";
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 0px 15px;
  text-align:center;
  text-transform: uppercase;
  font-size: 24px;
  min-height: 108px;
`;
const TabColDetailSection = styled.div`
  min-height: 250px;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const TabColDetailPrice = styled.div`
  min-height: 85px;
  color: #fff;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TabColDetailIcons = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-start;
  align-items:center;
  padding: 5px;
  & svg {
    fill: #fff;
  }
  & div {
    padding-left: 15px;
    color: #fff;
    width: fit-content;
  }
`;

const ImageCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 800px;
  background-size: 100% 100%;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)), url(${(props) => props.src});
`;

const Projects = ({account, user, contacts, createForm, fetchAccount, fetchContacts, fetchingAccount, fetchingContacts, loadingForm }) => {
  const [tabKey, setTabKey] = useState("power");
  const [selectedService, setSelectedService] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (_.isEmpty(account) && !fetchingAccount) {
      fetchAccount();
    }
  }, [account]);

  useEffect(() => {
    if (_.isEmpty(contacts) && !fetchingContacts) {
      fetchContacts();
    }
  }, [contacts]);

  const handleClickCard = (service) => {
    setSelectedService(service);
    setShowModal(true);
  }

  const renderTab = () => {
    if (tabKey === 'power'){
      return (
        <Container fluid>
          <Row style={{margin: '0px'}}>
            <TabCol xs={12}>

              <Row>
                <Col xs={12} className="med wht f24 text-uppercase text-center m-b-40 m-t-40">
                  MAKE SMART ENERGY A CORE OFFERING, EVEN WITHOUT IN-HOUSE EXPERTISE
                </Col>
              </Row>

              <Row>

                <Col md={6} lg={4} className="m-t-10">
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyHood.jpeg">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          Remote Consultation & Design x10, Power
                        </TabColHeader>

                        <TabColDetailSection>
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              10 Hours of Smart Power Design
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <GiArchiveResearch size={30} />
                            <div>
                              Client/Project Discovery
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <BsDiagram3 size={30} />
                            <div>
                              System Design w/ Single Line Diagram
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <BsPencilSquare size={30} />
                            <div>
                              Consumer Facing Proposal
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          $2,500 per project, up to 10 hours<br />
                          $1,000 extension, 4 hour block
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />

                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            Onsite travel available, billed separately if required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Remote Design & Consultation x10, Power')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

                <Col md={6} lg={4} className="m-t-10">
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyWind.jpeg">
                      <div className="med pad-20 f14 wht" style={{width: '100%'}}>
                        <TabColHeader>
                          Remote Consultation & Design x10, Power + Engineering Support
                        </TabColHeader>

                        <TabColDetailSection>

                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              10 hours of Smart Power Design & Consultation, Client/Project Discovery, Single Line Diagram and Consumer Facing Proposal 
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <RiRedPacketLine size={30} />
                            <div>
                              PE stamped submittal package
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaPeopleArrows size={30} />
                            <div>
                            Interconnection coordination
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          $5,500 per project, 200A Service<br />
                          $12,000 per project, 400A-600A Service<br />
                          Custom quote, 800A Services, starting at $20,000<br />
                          
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            Onsite travel available, billed separately if required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Remote Design & Consultation x10, Power + Engineering Support')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

                <Col md={6} lg={4} className="m-t-10">
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyWorkers.jpeg">
                      <div className="med pad-20 f14 wht" style={{width: '100%'}}>
                        <TabColHeader>
                          Full Service
                        </TabColHeader>

                        <TabColDetailSection>

                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              10 hours of Smart Power Design & Consultation, Client/Project Discovery, Single Line Diagram and Consumer Facing Proposal 
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <RiRedPacketLine size={30} />
                            <div>
                              PE stamped submittal package
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaPeopleArrows size={30} />
                            <div>
                            Interconnection coordination
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <AiOutlineProject size={30} />
                            <div>
                              Local permitting support, project management, commissioning, installation & two-year support subscription
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          Starting @ <br />
                          $20,000, 200A Service.<br />
                          $35,000, 400A+ Service.<br />
                          Hardware & Labor quoted separately.
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                            <GiReceiveMoney size={30} />
                            <div>
                              Savant will contract directly with the consumer.<br />
                              15% Authorized Integrator / Contractor referral fee calculated off consumer invoice.
                            </div>
                          </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Full Service')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

              </Row>


                <div className="m-t-40 m-b-40 text-center wht">
                  Click GET STARTED to connect with the Savant Services Team to explore what Service Package its right for your project.<br />International Pricing and Product availability may vary.
                </div>



                <Row className='vertical-align'>
                  <Col md={6} className="text-center">
                    
                    <ReactPlayer url="https://cdn.savant.com/video/SavantPower2.m4v" width="100%" playing muted loop />
                    {/* <ReactPlayer url="https://cdn.savant.com/video/companion_panel.mp4" width="100%" playing loop /> */}
                  </Col>
                  <Col md={6}>
                    <div className="text-center wht f20 med">
                      INTRODUCING THE FUTURE OF ENERGY
                    </div>
                    <div className="text-center wht f14 m-t-10">
                      Scalable Savant Power Systems paired with the Savant app, delivers you full control over time-of-use pricing, off-grid energy independence, and backup power sources including automatic generators. Savant Power Systems make it all easier.
                    </div>
                    <div className="m-t-20 text-center">
                      <a href="https://savant.com/power" target="_blank" rel="noreferrer">
                        <button className="sav-btn__white">
                          Learn More
                        </button>
                      </a>
                    </div>
                  </Col>
                </Row>

                <Row className='vertical-align'>
                  <Col md={6}>
                    <div className="text-center wht f20 med">
                      SMART ENERGY FOR EVERY HOME
                    </div>
                    <div className="text-center wht f14 m-t-10">
                      Savant Power Systems are designed to scale, delivering the benefits of smart power to every home. Monitor a few main, power-hungry circuits or monitor every circuit in the home - the choice is yours.<br />
                      Best of all, Savant Power System Modules are installed in standard electrical panels, no hard-to-find proprietary panel required.
                    </div>

                    <div className="m-t-20 text-center">
                      <a href="https://store.savant.com/DefaultStore/ccrz__Products?categoryId=a9N70000000kKB5EAM" target="_blank" rel="noreferrer">
                        <button className="sav-btn__white">
                          Visit the store
                        </button>
                      </a>
                    </div>
                  </Col>
                  <Col md={6} className="text-center">
                    <Image src="https://savant.com/static/9c6d206ecb70406e7c21855bf8374d3a/c32de/updated-history.png" height={500} />
                  </Col>
                  
                </Row>

            </TabCol>
          </Row>

          
          
        </Container>
      )
    }
    if (tabKey === 'smart'){
      return (
        <Container fluid>
          <Row style={{margin: '0px'}}>
            <TabCol xs={12} style={{backgroundColor: '#696057'}}>

              <Row>
                <Col xs={12} className="med wht f24 text-uppercase text-center m-b-40 m-t-40">
                  Savant Support on every project
                </Col>
              </Row>

              <Row>

                <Col lg={{ span: 4, offset: 2 }} md={6}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Home5.jpeg">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          Remote Design & Consultation x10
                        </TabColHeader>
                        <TabColDetailSection style={{padding: 0}}>
                          <TabColDetailIcons>
                            <FaNetworkWired size={20} />
                            <div>
                              10 hours of of smart AV, speakers, wired and wireless networking and lighting control design
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaDesktop size={20} />
                            <div>
                              AV design and BOM creation for all project sizes and types
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <RiSpeakerLine size={20} />
                            <div>
                              Speaker design and specification with heatmap diagrams
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <GiWindow size={20} />
                            <div>
                              Shade design for complex and large scale requirements
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <SiJunipernetworks size={20} />
                            <div>
                              Wi-Fi heat mapping for optimum coverage with Juniper Wireless networks
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaRegLightbulb size={20} />
                            <div>
                              Lighting control design for retrofit & new construction; keypads, fixtures, DMX & 0-10V control
                            </div>
                          </TabColDetailIcons>

                        </TabColDetailSection>


                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          $2,500 per project, up to 10 hours<br />
                          $1,000 extension, 4 hour block
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            Onsite travel available, billed separately if required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Remote Design & Consultation x10, Network')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

                {/* <Col lg={4} md={6}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Audio1.png">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          Onsite Service x8<br />
                          Audio Tuning
                        </TabColHeader>
                        <TabColDetailSection>
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              8 hours of on-site services
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <SiAudiomack size={30} />
                            <div>
                              Blueprint/Configuration optimizations + Savant Entertainment UI educations
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <SiAudiomack size={30} />
                            <div>
                              On-site EQ adjustments + creation of volume presents
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          Starting at $3,500 First Day <br />
                          $1,200 on-site extension, 4 hour block
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            US travel included.  Minimum of 2 weeks notice required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Onsite Service x8 Audio Tuning')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col> */}

                <Col lg={4} md={6}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/LightingLayout.jpeg">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          Lighting Layout Service
                        </TabColHeader>

                        <TabColDetailSection>
                          <TabColDetailIcons>
                            {/* <GiPencilRuler size={30} /> */}
                            <Fixture height={30} fill="#fff" />
                            <div>
                              Basic Layout of USAI & GE/Savant Lighting Fixtures
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              Layout Service will provide a Reflected Ceiling Plan (RCP),<br />
                              Fixture Schedule and Zoning Schedule
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <FaReceipt size={30} />
                            <div>
                              Service will also provide an itemized quote of the specified fixtures
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          Lighting Layout for up to 3 rooms = $750<br />
                          Lighting Layout for 4+ rooms = $1.50 per sq. ft.<br />
                          Prices include 1 revision - additional revisions $120/hr
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        {/* <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                           US travel included.  Minimum of 2 weeks notice required.
                          </div>
                        </TabColDetailIcons> */}
                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Any recommended lighting fixtures will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Lighting Layout Service')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

              </Row>
                <div className="m-t-20 m-b-20 text-center wht">
                  Click GET STARTED to connect with the Savant Services Team to explore what Service Package its right for your project.<br />
                  International Pricing and Product availability may vary.
                </div>

            </TabCol>
          </Row>
        </Container>
      )
    }
    if (tabKey === 'training'){
      return (
        <Container fluid>
          <Row style={{margin: '0px'}}>
            <TabCol xs={12} style={{backgroundColor: '#979797'}}>

              <Row>
                <Col xs={12} className="med wht f24 text-uppercase text-center m-b-40 m-t-40">
                  Personalized, Hands-on Training and Onsite Services
                </Col>
              </Row>

              <Row>

                <Col md={6} lg={4}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Rack1.jpeg">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          Advanced Training x4 Remote
                        </TabColHeader>
                        <TabColDetailSection>
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              4 Hours of remote advanced training
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <BsDiagram3 size={30} />
                            <div>
                              Blueprint advanced programming & configuration
                            </div>
                          </TabColDetailIcons>
                          <TabColDetailIcons>
                            <AiOutlineCloud size={30} />
                            <div>
                            Savant Central Management intitial setup support
                            </div>
                          </TabColDetailIcons>

                        </TabColDetailSection>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          $500 per project, up to 4 hours<br />
                          Personal screen-share session<br />or <br />
                          Invite your whole team
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            Onsite travel available, billed separately if required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('Advanced Training x4 Remote')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

                <Col md={6} lg={4}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/HostFamily.png">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                          New Integrator/Contractor Onsite Ride-Along
                        </TabColHeader>
                        <TabColDetailSection>
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              8 hours of on-site services
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <GiArchiveResearch size={30} />
                            <div>
                              Great for new hires, team development
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <BsDiagram3 size={30} />
                            <div>
                              New category deployments
                            </div>
                          </TabColDetailIcons>
                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          Starting at $3,500 first day<br />
                          $1,200 on-site extension, 4 hour block
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            US travel included.  Minimum of 2 weeks notice required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('New Integrator/Contractor Onsite Ride-Along')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>

                <Col md={6} lg={4}>
                  <TabColSection>
                    <ImageCard src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Home3.png">
                      <div className="med pad-20 f14 wht">
                        <TabColHeader>
                         Onsite Service x8<br />
                         Audio Tuning & Personalization
                        </TabColHeader>
                        <TabColDetailSection>
                          
                          <TabColDetailIcons>
                            <GiPencilRuler size={30} />
                            <div>
                              8 hours of on-site services
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <AiOutlineFundProjectionScreen size={30} />
                            <div>
                              Blueprint/Configuration optimizations + Savant Entertainment UI education
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <SiAudiomack size={30} />
                            <div>
                             On-site EQ adjustments + creation of volume presets
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaMobileAlt size={30} />
                            <div>
                              Personal client education
                            </div>
                          </TabColDetailIcons>

                          <TabColDetailIcons>
                            <FaUniversity size={30} />
                            <div>
                              Savant App Personalizations, scene creation, room photos service order, daylight mode setup, spotify end-point creation, etc.
                            </div>
                          </TabColDetailIcons>

                        </TabColDetailSection>

                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailPrice>
                          Starting at $3,500 first day<br />
                          $1,200 on-site extension, 4 hour block
                        </TabColDetailPrice>
                        <hr style={{borderTop: '1px solid #fff'}} />
                        <TabColDetailIcons>
                          <GiCommercialAirplane size={30} />
                          <div>
                            US travel included.  Minimum of 2 weeks notice required.
                          </div>
                        </TabColDetailIcons>

                        <TabColDetailIcons>
                          <AiOutlineShoppingCart size={30} />
                          <div>
                            Hardware not included. Any recommended system hardware will be purchased by Savant Integrator or Authorized Contractor via the Savant Store.
                          </div>
                        </TabColDetailIcons>
                      </div>
                      <div className="text-center pad-20" style={{width: '100%', alignSelf: 'flex-end'}}>
                        <button className="sav-btn__white" onClick={() => handleClickCard('New Integrator/Contractor Onsite Ride-Along')}>Get Started</button>
                      </div>
                    </ImageCard>
                  </TabColSection>
                </Col>


              </Row>
                <div className="m-t-20 m-b-20 text-center wht">
                  Click GET STARTED to connect with the Savant Services Team to explore what Service Package its right for your project.  
                </div>
            </TabCol>
          </Row>
        </Container>
      )
    }
    return null;

  }

  return (
    <div>
      <HeroContainer>
        <Image
          src="https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/ProjectRegistration.png"
          style={{width: '100%', objectFit: 'cover', maxHeight: 600}}
        />
        <HeroOverlay>
          <div className="book text-center">
            <div style={{width: '100%', margin: '0px auto 10px'}}>
              <Image src={SavLogo} fluid width={300} />
            </div>
            <div className="wht med text-uppercase f20" style={{marginTop: '-25px', letterSpacing: '15px'}}>
              Services
            </div>
            <div className="book wht f20 text-uppercae text-center">
              INTRODUCING ANOTHER LAYER OF DEALER SUPPORT
            </div>
            <div className="m-t-10">
              <ProjectRequestModal show={showModal} hide={() => setShowModal(false)} account={account.data} user={user} contacts={contacts.data} createForm={createForm} selectedService={selectedService} loadingForm={loadingForm} />
              <button className="sav-btn__white" onClick={() => handleClickCard('None')}>Get Started</button>
            </div>
          </div>
        </HeroOverlay>
      </HeroContainer>
      <Container fluid style={{backgroundColor: "#3f3f3f", color: '#fff'}}>
        <Row style={{paddingTop: 20}}>
          <Col md={3} className="wht">
            <div className="med f14 text-center text-uppercase wht">
              <FaHouseUser size={20} style={{fill: '#fff', marginRight: 10}} />Emerging Technology
            </div>
            <div className="book f12 text-center wht m-t-10 m-b-20">
              With Savant design, installation and commissioning support you can offer your clients the latest innovations with confidence. 
            </div>
          </Col>
          <Col md={3} className="wht">
            <div className="med f14 text-center text-uppercase wht">
              <MdSupportAgent size={20} style={{fill: '#fff', marginRight: 10}} /> SUPPORT WHERE YOU NEED IT
            </div>
            <div className="book f12 text-center wht m-t-10 m-b-20">
              Schedule on-site services to support your team with a complex or first-time Savant deployment.
            </div>
          </Col>
          <Col md={3} className="wht">
            <div className="med f14 text-center text-uppercase wht">
              <AiOutlineStock size={20} style={{fill: '#fff', marginRight: 10}} /> Deliver More Value
            </div>
            <div className="book f12 text-center wht m-t-10 m-b-20">
              Add even more value to your clients with personalized, on-site service from Savant.
            </div>
          </Col>
          <Col md={3} className="wht">
          <div className="med f14 text-center text-uppercase wht">
              <GiReceiveMoney size={20} style={{fill: '#fff', marginRight: 10}} /> Add Revenue
            </div>
            <div className="book f12 text-center wht m-t-10 m-b-20">
              Amplify your revenue without significant in-house investment in education, resources, and costly certifications.
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid style={{padding: '20 px 20px 0px'}}>
        <Row className="m-t-20">

          <Col xs={4} style={{cursor: 'pointer'}} onClick={() => setTabKey('power')}>
            <div style={{minHeight: 120, border: '1px solid #2A2F3C', borderRadius: 6, backgroundColor: (tabKey === 'power' ? '#2A2F3C' : '#fff'), borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none'}}>
              <div
                className="med wht f18 text-uppercase text-center"
                style={{
                  backgroundColor: (tabKey === 'power' ? '#2A2F3C' : '#fff'),
                  padding: 20,
                  color: (tabKey === 'power' ? '#fff' : '#2A2F3C')
                  }}
              >
                Power
              </div>
              <HeroContainer>
                <ServiceIcons>
                  <Climate className="d-none d-md-block" fill={tabKey === 'power' ? "#fff" : "#262b32"} height="30px" />
                  <Energy fill={tabKey === 'power' ? "#fff" : "#262b32"} height="30px" />
                  <FaPlug size={30} style={tabKey === 'power' ? {fill: "#fff"} : {fill: "#262b32"}} />
                </ServiceIcons>
              </HeroContainer>
            </div>
          </Col>

          <Col xs={4} style={{cursor: 'pointer'}} onClick={() => setTabKey('smart')}>
            <div style={{minHeight: 120, border: '1px solid #696057', borderRadius: 6, backgroundColor: (tabKey === 'smart' ? '#696057' : '#fff'), borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none'}}>
              <div
                className="med wht f18 text-uppercase text-center"
                style={{
                  backgroundColor: (tabKey === 'smart' ? '#696057' : '#fff'),
                  padding: 20,
                  color: (tabKey === 'smart' ? '#fff' : '#696057')
                  }}>
                Smart & Lighting
              </div>
              <HeroContainer>
                <ServiceIcons>
                  {/* <Media fill={tabKey === 'smart' ? "#fff" : "#696057"} height="30px" /> */}
                  <Fixture height={30} fill={tabKey === 'smart' ? "#fff" : "#696057"} />
                  <SavantMusic className="d-none d-md-block" fill={tabKey === 'smart' ? "#fff" : "#696057"} height="40px" />
                  <Receiver className="d-none d-md-block" fill={tabKey === 'smart' ? "#fff" : "#696057"} height="30px" />
                </ServiceIcons>
              </HeroContainer>
            </div>
          </Col>

          <Col xs={4} style={{cursor: 'pointer'}} onClick={() => setTabKey('training')}>
            <div style={{minHeight: 120, border: '1px solid #979797', borderRadius: 6, backgroundColor: (tabKey === 'training' ? '#979797' : '#fff'), borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none'}}>
              <div className="med f18 text-uppercase text-center"
              style={{
                backgroundColor: (tabKey === 'training' ? '#979797' : '#fff'),
                padding: 20,
                color: (tabKey === 'training' ? '#fff' : '#979797')
                }}>
                Onsite & Remote Services
              </div>
              <HeroContainer>
                <ServiceIcons>
                  <FaBookReader size={30} style={tabKey === 'training' ? {fill: "#fff"} : {fill: "#979797"}} />
                  <MdSchool className="d-none d-md-block" size={35} style={tabKey === 'training' ? {fill: "#fff"} : {fill: "#979797"}} />
                  {/* <Shade fill={tabKey === 'training' ? "#fff" : "#979797"} height="30px" /> */}
                  {/* <Security className="d-none d-md-block" fill={tabKey === 'training' ? "#fff" : "#979797"} height="30px" /> */}
                  <BsGearFill style={tabKey === 'training' ? {fill: "#fff"} : {fill: "#979797"}} size={30} />
                </ServiceIcons>
              </HeroContainer>
            </div>
          </Col>

        </Row>
      </Container>
      {renderTab()}
    </div>
  );
};

const fetchingAccount = createLoadingSelector(["FETCH_ACCOUNT"]);
const fetchingContacts = createLoadingSelector(["FETCH_CONTACTS"]);
const loadingForm = createLoadingSelector(["CREATE_FORM"]);

const mapStateToProps = state => {
  return {
    account: state.account,
    contacts: state.contacts,
    user: state.user,
    fetchingAccount: fetchingAccount(state),
    fetchingContacts: fetchingContacts(state),
    loadingForm: loadingForm(state),
  };
};
export default connect(mapStateToProps, {
  fetchAccount,
  fetchContacts,
  createForm
})(Projects);
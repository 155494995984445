import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import styled from "styled-components";

export const HomeRow = styled(Row)`
  background-color: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 6px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  align-items:center;
  font-size: 14px;
`;

export const DataCard = styled.div`
  background-color: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: rgb(0 0 0 / 2%) 10px 10px 10px;
`;
export const DataContainer = styled(Container)`
  background-color: #fff;
  padding: 0px;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: rgb(0 0 0 / 2%) 10px 10px 10px;
`;
export const DataCardHeader = styled.div`
  font-family:"Gotham-Medium";
  font-size: 18px;
  padding-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  >div {
    text-transform: none;
  }
`;
export const DataCardFooter = styled.div`
  font-family:"Gotham-Medium";
  font-size: 18px;
  padding-top: 10px;
  text-align: center;
  text-transform: uppercase;
`;
export const DataCardNumber = styled(DataCardHeader)`
  font-size: 36px;
  color: #ff5f00;
  position: relative;
`;
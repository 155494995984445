import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import _ from "lodash";
import {SavRouterLink} from "../../components/Styled";
import { Link, useHistory } from "react-router-dom";

const NewsRow = styled(Row)`
  margin-bottom:20px;
`;

const NewsContainer = styled(Container)`
  padding: 15px 30px;
`;
const HeaderTextOverlay = styled.div`
  position:absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display:flex;
  height: 100%;
  align-items:center;
  justify-content:center;
  padding: 10px;
  z-index: 9999;

`;
const NewsLink = styled(Link)`
  &:hover {
    text-decoration: none;
    background-color: #fafaf9;
  }
`;

const NewsComponent = (props) => {
  const { feed } = props;

  const createMarkup = (richText) => {
    return { __html: richText };
  };

  const renderNewsFeed = () => {
    if (!_.isEmpty(feed)) {
      let newsSliced = feed.data.slice(0, 3);
      return newsSliced.map((feedItem, index) => {
        const date = new Date(
          JSON.parse(JSON.stringify(feedItem.postDate))
        ).toLocaleDateString();
        return (
          <NewsLink to={`/news/${feedItem.sfid}`} key={index}>
            <NewsRow >
              <Col>
                <Row className="m-b-5">
                  <Col style={{padding: 0}}>
                    <div className="event-img-container home-shadow" style={{height:150}}>
                      <HeaderTextOverlay className="f16 med text-center text-uppercase headerTextOverlay" style={{color:feedItem.textColor}}>
                        {feedItem.headerText}
                      </HeaderTextOverlay>
                      <span
                        className="event-img-zoom"
                        style={{
                          backgroundImage: `url(${feedItem.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          display: "block",
                          height: "150px",
                          maxHeight: "150px",
                        }}
                      />
                      
                      <span className="f12 book text-uppercase" style={{position:"absolute", left: "5%", bottom: 10, color: feedItem.textColor }}>
                        {feedItem.type}
                      </span>
                      <span className="f10 book text-uppercase" style={{position:"absolute", right: "5%", bottom: 10, color: feedItem.textColor }}>
                        {date}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </NewsRow>
          </NewsLink>
        );
      });
    }
  };

  

  if (!_.isEmpty(feed)){
    return (
      <NewsContainer style={{marginTop:15}}>
        {renderNewsFeed()}
      </NewsContainer>
    );
  }
  return(
    <Skeleton count={3} height={150} />
  );
  
};

export default NewsComponent;

import React, {useEffect, useState, Fragment} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

const SavButtonLoader = ({loading, buttonText, className, handleSave, type}) => {

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let isLoading = false;
    Object.keys(loading).forEach(l => {
      const loader = loading[l];
      if (loader){
        const isUpdating = /(UPDATE|DELETE|CREATE)_(.*)/.exec(l);
        if (isUpdating){
          isLoading = true;
        }
      }
    });
    if (isLoading){
      setShowLoader(true);
    }else{
      setShowLoader(false);
    }
  },[loading]);

  const renderLoader = () => {
    return (
      <div className="loader--button" />
    )
  }

  return (
    <button className={className || "sav-btn__orange--fill"} type={type || "submit"} disabled={showLoader ? true : false}>
      {showLoader ? renderLoader() : (buttonText || "Save")}
    </button>
  )

  // if (showLoader){
  //   return <div className="loader--button" />
  // } else {
  //   return <Fragment>{buttonText || "Save"}</Fragment>
  // }
}


const mapStateToProps = state => {
  return {
    loading: state.loading,
  };
};
export default connect(mapStateToProps, null)(SavButtonLoader)
import _ from "lodash";

export const createLoadingSelector = actions => state => {
  return _(actions).some(action => _.get(state, `loading.${action}`));
};

export const createSuccessSelector = actions => state => {
  return _(actions).some(action => _.get(state, `success.${action}`));
};


export const invoiceCardSelector = state => {
  return (
    state.invoices.data.filter((invoice) => invoice.balance > 0)
  )
}

export const selectAddress = addressId => state => {
  return (
    _.find(state.addresses.data, (address) => address.sfid === addressId)
  )
}

export const isInternalUser = state => {
  if (state.user.data){
   return state.user.data.contact.accountId === '0017000001EU7E9AAL' ? true : false;
  }
}

export const visibilitySelector = (page) => state => {

  let returnVal = false;
  if (state.user.data){
    
    const accType = state.user.data.contact.accType;
    const accSubType = state.user.data.contact.accSubType;
    const storePricing = state.user.data.contact.permissions.storePricing;
    const ordersInvoices = state.user.data.contact.permissions.ordersInvoices;
    const contactManagement = state.user.data.contact.permissions.contactManagement;
    const accountAdmin = state.user.data.contact.permissions.accountAdmin;

    const pageMapIntegrator = {
      account_dashboard : ['accountAdmin', 'ordersInvoices'],
      account_dashboard_programDetails: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: ['accountAdmin','ordersInvoices'],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapMagnolia = {
      account_dashboard : ['accountAdmin', 'ordersInvoices'],
      account_dashboard_programDetails: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapDistributor = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapVAD = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin' ,'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapVadDealer = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin'],
      account_settings_details_status: [''],
      account_settings_details_distributors: ['accountAdmin'],
      account_settings_settings: [''],
      account_settings_addresses: ['accountAdmin'],
      account_settings_creditCards: [''],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: [''],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: ['accountAdmin'],
      account_billing: ['accountAdmin'],
      account_billing_payment: [''],
      account_assets: [''],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: [''],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: [''],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: [''],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapNDD = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin'],
      account_settings_details_status: [''],
      account_settings_details_distributors: ['accountAdmin'],
      account_settings_settings: [''],
      account_settings_addresses: ['accountAdmin'],
      account_settings_creditCards: [''],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: [''],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: ['accountAdmin'],
      account_billing: ['accountAdmin'],
      account_billing_payment: [''],
      account_assets: [''],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: [''],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: [''],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: [''],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: [''],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapElectrical = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin'],
      account_settings_details_status: [''],
      account_settings_details_distributors: ['accountAdmin'],
      account_settings_settings: [''],
      account_settings_addresses: ['accountAdmin'],
      account_settings_creditCards: [''],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: [''],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: [''],
      account_billing_payment: [''],
      account_assets: [''],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: [''],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: [''],
      latestSoftware: [''],
      cases: [''],
      knowledge: [''],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: ['accountAdmin'],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: [''],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: [''],
      tools_menu: [''],
    }
    const pageMapElectricalDirect = {
      account_dashboard : ['accountAdmin'],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: [''],
      latestSoftware: [''],
      cases: [''],
      knowledge: [''],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: [''],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: [''],
      tools_menu: [''],
    }
    const pageMapRep = {
      account_dashboard : [''],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: ['accountAdmin'],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      latestSoftware: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      cases: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }
    const pageMapWindow = {
      account_dashboard : ['accountAdmin', 'ordersInvoices'],
      account_dashboard_programDetails: [''],
      account_settings_details_company: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_status: ['accountAdmin', 'ordersInvoices'],
      account_settings_details_distributors: ['accountAdmin', 'ordersInvoices'],
      account_settings_settings: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_addresses: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_creditCards: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_settings_scm: [''],
      account_settings_savant_com_listings: ['accountAdmin'],
      account_orders: ['accountAdmin', 'ordersInvoices', 'storePricing'],
      account_contacts_personnel: ['accountAdmin', 'contactManagement'],
      account_contacts_education: ['accountAdmin', 'contactManagement'],
      account_subscriptions: [''],
      account_billing: ['accountAdmin', 'ordersInvoices'],
      account_billing_payment: ['accountAdmin', 'ordersInvoices'],
      account_assets: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      account_documents: ['accountAdmin', 'storePricing'],
      account_documents_agreements: ['accountAdmin'],
      account_documents_tax: ['accountAdmin', 'storePricing'],
      news: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      events: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      savantBeta: [''],
      latestSoftware: [''],
      cases: [''],
      knowledge: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      marketing_dd_promotion: [''],
      scheduler: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      profileInfo: [''],
      university: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
      rma: [''],
      tools_menu: ['accountAdmin', 'ordersInvoices', 'contactManagement', 'storePricing'],
    }

    if (accType === "Integrator" && accSubType !== "Retail") {
      if (_.isEmpty(pageMapIntegrator[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapIntegrator[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapIntegrator[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapIntegrator[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapIntegrator[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapIntegrator[page].includes('accountAdmin') &&
          pageMapIntegrator[page].includes('contactManagement') &&
          pageMapIntegrator[page].includes('ordersInvoices') &&
          pageMapIntegrator[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
      
    }

    if (accType === "Integrator" && accSubType === "Retail") {
      if (_.isEmpty(pageMapMagnolia[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapMagnolia[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapMagnolia[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapMagnolia[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapMagnolia[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapMagnolia[page].includes('accountAdmin') &&
          pageMapMagnolia[page].includes('contactManagement') &&
          pageMapMagnolia[page].includes('ordersInvoices') &&
          pageMapMagnolia[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Distributor") {
      if (_.isEmpty(pageMapDistributor[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapDistributor[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapDistributor[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapDistributor[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapDistributor[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapDistributor[page].includes('accountAdmin') &&
          pageMapDistributor[page].includes('contactManagement') &&
          pageMapDistributor[page].includes('ordersInvoices') &&
          pageMapDistributor[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "VAD") {
      if (_.isEmpty(pageMapVAD[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapVAD[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapVAD[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapVAD[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapVAD[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapVAD[page].includes('accountAdmin') &&
          pageMapVAD[page].includes('contactManagement') &&
          pageMapVAD[page].includes('ordersInvoices') &&
          pageMapVAD[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Mfg Rep") {
      if (_.isEmpty(pageMapRep[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapRep[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapRep[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapRep[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapRep[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapRep[page].includes('accountAdmin') &&
          pageMapRep[page].includes('contactManagement') &&
          pageMapRep[page].includes('ordersInvoices') &&
          pageMapRep[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Non-Direct" && (accSubType === "US Distribution" || accSubType === "US Distribution Pro") ) {
      if (_.isEmpty(pageMapNDD[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapNDD[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapNDD[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapNDD[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapNDD[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapNDD[page].includes('accountAdmin') &&
          pageMapNDD[page].includes('contactManagement') &&
          pageMapNDD[page].includes('ordersInvoices') &&
          pageMapNDD[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Non-Direct" && accSubType === "International Distribution") {
      if (_.isEmpty(pageMapVadDealer[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapVadDealer[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapVadDealer[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapVadDealer[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapVadDealer[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapVadDealer[page].includes('accountAdmin') &&
          pageMapVadDealer[page].includes('contactManagement') &&
          pageMapVadDealer[page].includes('ordersInvoices') &&
          pageMapVadDealer[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }
    if (accType === "Non-Direct" && accSubType === "Electrical Contractor") {
      if (_.isEmpty(pageMapElectrical[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapElectrical[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapElectrical[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapElectrical[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapElectrical[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapElectrical[page].includes('accountAdmin') &&
          pageMapElectrical[page].includes('contactManagement') &&
          pageMapElectrical[page].includes('ordersInvoices') &&
          pageMapElectrical[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }
    if (accType === "Contractor" && accSubType === "Electrical/Solar") {
      if (_.isEmpty(pageMapElectricalDirect[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapElectricalDirect[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapElectricalDirect[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapElectricalDirect[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapElectricalDirect[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapElectricalDirect[page].includes('accountAdmin') &&
          pageMapElectricalDirect[page].includes('contactManagement') &&
          pageMapElectricalDirect[page].includes('ordersInvoices') &&
          pageMapElectricalDirect[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Non-Direct" && accSubType === "Electrical Contractor") {
      if (_.isEmpty(pageMapElectrical[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapElectrical[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapElectrical[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapElectrical[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapElectrical[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapElectrical[page].includes('accountAdmin') &&
          pageMapElectrical[page].includes('contactManagement') &&
          pageMapElectrical[page].includes('ordersInvoices') &&
          pageMapElectrical[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    if (accType === "Dealer" && (accSubType === "Window Treatment" || "Window Treatment - Full Line") ) {
      if (_.isEmpty(pageMapWindow[page])) {
        returnVal = false;
      }
      if (storePricing) {
        if (pageMapWindow[page].includes('storePricing')) {
          returnVal = true;
        }
      }
      if (ordersInvoices) {
        if (pageMapWindow[page].includes('ordersInvoices')) {
          returnVal = true;
        }
      }
      if (contactManagement) {
        if (pageMapWindow[page].includes('contactManagement')) {
          returnVal = true;
        }
      }
      if (accountAdmin) {
        if (pageMapWindow[page].includes('accountAdmin')) {
          returnVal = true;
        }
      }
      if (!storePricing && !ordersInvoices && !contactManagement && !accountAdmin){
        if (
          pageMapWindow[page].includes('accountAdmin') &&
          pageMapWindow[page].includes('contactManagement') &&
          pageMapWindow[page].includes('ordersInvoices') &&
          pageMapWindow[page].includes('storePricing')
          ) {
          returnVal = true;
        }
      }
    }

    // if (accType === "Rep") {
    //   if (_.isEmpty(pageMapRep[page])) {
    //     returnVal = false;
    //   }
    // }

  
    return returnVal;
  }
  return (
    null
  )
}
import { FETCH_AGREEMENTS_SUCCESS, UPDATE_AGREEMENT_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const agreements = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AGREEMENTS_SUCCESS:
      return success(state, action);
    case UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload.agreements.payload,
        success: action.payload.agreements.success,
        errors: action.payload.agreements.errors,
        messages: action.payload.agreements.messages
      };
    default:
      return state;
  }
};

export default agreements;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Container, Row, Col, Dropdown, Table } from "react-bootstrap";
import { save } from 'save-file'
import _ from "lodash";
import moment from 'moment';
import {AiOutlineCloudDownload} from "react-icons/ai";
import {SavRow} from "../../../components/Styled";
import SavModalWarning from "../../../components/SavModalWarning";
import SavPagination from "../../../components/SavPagination";
import EditTaxCertModal from "./EditTaxCertModal";
import NewTaxCertModal from "./NewTaxCertModal";

export const SavTable = styled(Table)`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 4px;
  th {
    background-color: #fff;
    color: #9b9b9b;
    padding: 10px 10px;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
    border-top:unset;
    border-bottom:unset!important;
  }
`;

const SalesTaxCerts = ({documents, user, fetchDocument, updateTaxCert, setSuccessToast, deleteTaxCert, createTaxCert}) => {

  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeCert, setActiveCert] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [docList, setDocList] = useState([]);
  const [taxCertList, setTaxCertList] = useState([]);
  const today = moment();

  useEffect(() => {
    const taxCerts = _.filter(documents, (d) => d.docType === 'taxCert');
    setDocList(taxCerts);
  },[documents, pageSize]);

  useEffect(() => {
    if (docList) {
      let index = 0;
      const tempDocs = [];
      for (index = 0; index < docList.length; index += pageSize) {
        let chunk = docList.slice(index, index + pageSize);
        tempDocs.push(chunk);
      }
      setTaxCertList(tempDocs);
    }
  },[docList, pageSize]);

  const handleFetchDoc = (doc) => {
    fetchDocument(doc.recordId, "taxCert").then((res) => {
      if (res.success) {
        const data = res.payload; 
        save(data, `${doc.documentProperties.attachmentName}.pdf`);
      }
      setSuccessToast(true);
    })
  };

  const handleClickDelete = (doc) => {
    setActiveCert(doc);
    setShowDeleteModal(true);
  }

  const handleDelete = () => {
    deleteTaxCert(activeCert.recordId).then(() => {
      setSuccessToast(true);
      setShowDeleteModal(false);
    })
  }

  const handleSetEdit = (doc) => {
    setActiveCert(doc);
    setShowEditModal(true);
  }

  const renderCerts = () => {

    if (!_.isEmpty(taxCertList)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > taxCertList.length) {
        page = 0;
        setCurrentPage(1);
      }

      return taxCertList[page].map((doc) => {
        const docMoment = new moment(doc.documentProperties.expirationDate);
        return (
          <SavRow key={doc.recordId} className="vertical-align">
            <Col xs={3} md={3}>{doc.documentProperties.exemptNumber}</Col>
            <Col md={1} className="d-none d-md-block">{doc.documentProperties.state}</Col>
            <Col md={2} className="d-none d-md-block">{doc.documentProperties.effectiveDate}</Col>
            <Col xs={3} md={2} className={docMoment.isBefore(today) ? "red med" : ""}>{doc.documentProperties.expirationDate}</Col>
            <Col xs={3} md={2} className="text-center">
              <AiOutlineCloudDownload style={doc.documentProperties.attachmentId ? {fill:"#ff5f00", cursor:"pointer"} : {display:'none'}} size={25} onClick={() => handleFetchDoc(doc)} />
            </Col>
            <Col xs={3} md={2} className="text-center">
              <Dropdown className="savDrop">
                  <Dropdown.Toggle as="span">
                    <span className="med onHov">• • •</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="f10">
                    <Dropdown.Item
                      as="div"
                      className="f10"
                      style={{ borderBottom: "1px solid #fff" }}
                      onClick={() => handleSetEdit(doc)}
                    >
                      <span>Edit</span>
                    </Dropdown.Item>
                    <Dropdown.Item as="div" className="f10" onClick={() => handleClickDelete(doc)}>
                      <span>Delete</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </Col>
          </SavRow>
        )
      })
    }
    return null;
  }

  if (documents) {
    if (docList.length <1) {
      return (
        <div>
          <NewTaxCertModal show={showNewModal} hide={() => setShowNewModal(false)} setSuccessToast={setSuccessToast} createTaxCert={createTaxCert} user={user} />
          <div className="m-t-20 text-center">
            <button className="sav-btn__orange--fill" onClick={() => setShowNewModal(true)}>New Tax Certificate</button>
          </div>
          <div className="text-center m-t-20">
            You currently do not have any Sales Tax Certificates.
          </div>
        </div>
      )
    }
    return (
      <Container>
        <SavModalWarning
          center
          show={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          title="Warning"
          text2="Are you sure you want to delete this tax certificate?"
          click={handleDelete}
          buttonText="delete"
        />
        <EditTaxCertModal show={showEditModal} hide={() => setShowEditModal(false)} taxCert={activeCert} updateTaxCert={updateTaxCert} setSuccessToast={setSuccessToast} />
        <NewTaxCertModal show={showNewModal} hide={() => setShowNewModal(false)} setSuccessToast={setSuccessToast} createTaxCert={createTaxCert} user={user} />
        <div className="m-t-20 text-center">
          <button className="sav-btn__orange--fill" onClick={() => setShowNewModal(true)}>New Tax Certificate</button>
        </div>
        <hr />
        <Row className="f10 text-uppercase m-b-10 med light-gray">
          <Col xs={3} md={3}>Tax #</Col>
          <Col xs={2} md={1} className="d-none d-md-block">State</Col>
          <Col xs={2} md={2} className="d-none d-md-block">Effective Date</Col>
          <Col xs={3} md={2}>Expiration Date</Col>
          <Col xs={3} md={2} className="text-center">Attachment</Col>
          <Col xs={3} md={2} className="text-center">Actions</Col>
        </Row>
        {renderCerts()}
        <SavPagination
          data={taxCertList}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataLength={docList.length}
        />
      </Container>
    )
  }
  return null;
}

export default SalesTaxCerts;

import { FETCH_TRADESHOW_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const tradeshowSessions = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TRADESHOW_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default tradeshowSessions;
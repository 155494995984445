import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import {setSuccessToast, setFailToast} from "../../actions";

const ToastDiv = styled.div`
  background-color:#d0021b;
  color:#fff;
  position: fixed;
  left:50%;
  transform: translate(-50%, -175px);
  width:100%
  padding:27px;
  font-family:"Gotham-Medium";
  font-size:14px;
  text-align:center;
  z-index:9999;
  line-height:10px;
  opacity:.9;
`;

const ToastClose = styled.span`
  color: white;
  float: right;
  cursor: pointer;
  font-size: 20px;
`;

const SavToastFail = ({toast, setFailToast, message, success, errors}) => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (toast){
      setShow(true);
      setMsg(message);
    }else{
      setShow(false);
      setMsg(null);
    }
  },[toast, message]);

  // useEffect(() => {
  //   console.log('timer out')
  //   let timer = setTimeout(() => setSuccessToast(false), 3000);
  //   return () => clearTimeout(timer);
  // },[]);

  // useEffect(() => {
  //   let isFail = false;
  //   let type = '';
  //   Object.keys(errors).forEach(e => {
  //     const error = errors[e];
  //     if (error){
  //       const isError = /(UPDATE|DELETE|CREATE)_(.*)/.exec(e);
  //       if (isError){
  //         isFail = true;
  //         type = e;
  //         setMsg(error);
          
  //       }
  //     }
  //   });
  //   if (isFail){
  //     setShow(true);
  //     console.log(type)
  //     clearErrors(type);
  //   }else{
  //     setShow(false);
  //   }
  // },[errors]);

  // useEffect(() => {
    
  //   let error;
  //   const temp = _.forEach(errors, (k, v) => {
  //     const matches = /(UPDATE|DELETE|CREATE)_(.*)/.exec(k);
  //     if (matches && !_.isEmpty(v)){
  //       setMsg(v);
  //       setShow(true);
  //     }
  //   });
    
  // },[errors]);



  if (show){
    return (
      <ToastDiv>
        {msg || 'Error'}
        <ToastClose onClick={() => setFailToast(false)}>&times;</ToastClose>
      </ToastDiv>
    )
  }
  return null;
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    success: state.success,
    loading: state.loading,
    toast: state.toast.showFail,
    message:state.toast.message
  };
};

export default connect(mapStateToProps, {setSuccessToast, setFailToast})(SavToastFail);
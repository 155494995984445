import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import styled from "styled-components";
import _ from "lodash";
import "react-calendar/dist/Calendar.css";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {parseDateInt} from "../../components/Utilities";
import SavCheck from "../../components/SavCheck";
import SavFormInput from "../../components/SavFormInput";
import SavFormInputArea from "../../components/SavFormInputArea";
import SavFormDrop from "../../components/SavFormDrop";
import {Media, Camera, Shade, Security, Lighting, Climate, Power, Tiling, Pool, Entry, Energy, SavantMusic} from '../../components/Styled/icons';
import {FaLaptopHouse, FaLaptop} from 'react-icons/fa';
import {BsFillHouseDoorFill} from 'react-icons/bs';
import { MdWifi } from "react-icons/md";

const SavModalClose = styled.div`
  font-size: 36px;
  padding-top: 15px;
  padding-right: 15px;
  text-align: right;
  color: #979797;
  line-height: 0.5;
  cursor: pointer;
  position: absolute;
  font-family: "Gotham-Light";
  right: 0;
`;
const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  background-color: #3f3f3f;
`;
const ProgressDiv = styled.div`
  background-color:${(props) => (props.bg ? props.bg : "#979797")}
  text-align:center;
  color: ${(props) => (props.color ? props.color : "#fff")}
  font-size:8px;
  border-radius:3px;
  width: ${(props) => (1 / props.totalSteps) * 100}%;
  border-bottom: ${(props) => (props.border ? props.border : "none")};
  padding:20px;
`;
const StepDiv = styled.div`
  font-family: "Gotham-Medium";
  font-size: 12px;
  color: #fff;
`;
const StepText = styled.div`
  font-family: "Gotham-Book";
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
`;
const SectionHeader = styled.div`
  font-size:14px;
  font-family:"Gotham-Medium";
  margin: 10px 0px;
`;

const hidden = {
  display: 'none'
};
const selected = {
  border: "4px solid #ff5f00",
  // backgroundColor: "#ff5f00"
};
const PackageDiv = styled.div`
  border-radius: 6px;
  border: solid 4px #fff;
  background-color: ${(props) => (props.category === 'Power Services' ? "#262b32" : props.category === 'Smart Services' ? "#696057" : props.category === 'Training Services' ? "#979797" : '#fff')}
  padding: 10px;
  font-size: 14px;
  margin-bottom: 30px;
  // display: grid;
  // grid-template-rows: auto 100px auto;
  // justify-content: flex-start;
  // align-items: center;
  // flex-wrap: wrap;
  cursor: pointer;
  &:hover {
    border: 4px solid #ff5f00;
    // background-color: #fafaf9;
  }
`;
const PackageDivImage = styled.div`
  width: 100%;
  height: 130px;
  position: relative;
  > img {
    width: 100%;
    object-fit: cover;
    height: 130px;
  }
`;
const PackageDivOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items:center;
`;
const DateStyle = styled.div`
  padding: .375rem .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  max-height: 38px;
`;

const CardContainer = styled.div`
  margin: 15px 0px;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-wrap: wrap;
`;
const CardBox = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  height: 95px;
  width: 95px;
  border: 1px solid #979797;
  display: grid;
  grid-template-rows: 80% 20%;
  cursor: ${(props) => props.cursor || 'pointer'};
  &:hover {
    // border: 1px solid #ff5f00;
    background-color: #f9f9f9;
  }
`;
const CardBoxText = styled.div`
  font-size: 12px;
  text-align: center;
  color: #3f3f3f;
`;
const CardBoxImg = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ActiveCard = {
  border: `2px solid #ff5f00`,
  // backgroundColor: "#ff5f00"
};
const ButtonDiv = styled.div`
  text-align: center;
  // margin-top: 3rem;
`;
const products = [
  {
    name: 'Remote Design & Consultation x10, Power',
    category: 'Power Services',
    sku:'123-123-00',
    msrp: 2500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyHood.jpeg',
    desc: '• Client/Project Discovery + System Design, + Consumer-Facing Proposal'
  },
  {
    name: 'Remote Design & Consultation x10, Power + Engineering Support',
    category: 'Power Services',
    sku:'123-123-00',
    msrp: 5500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyWind.jpeg',
    desc: '• 10 Hours Design & Consultation Services + PE Stamped Submittal Package'
  },
  {
    name: 'Full Service',
    category: 'Power Services',
    sku:'123-123-00',
    msrp: 20000,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/EnergyWorkers.jpeg',
    desc: '• Local Permitting Support, Project Management, Commissioning Installation'
  },
  {
    name: 'Remote Design & Consultation x10',
    category: 'Smart Services',
    sku:'123-123-00',
    msrp: 2500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Home5.jpeg',
    desc: '• 10 Hours of Smart Network Design, Wired & Wireless'
  },
  {
    name: 'Lighting Layout Service',
    category: 'Smart Services',
    sku:'123-123-00',
    msrp: 750,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/LightingLayout.jpeg',
    desc: '• Basic Layout of USAI & GE/Savant Lighting Fixtures'
  },
  {
    name: 'Advanced Training x4 Remote',
    category: 'Training Services',
    sku:'123-123-00',
    msrp: 500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Training1.jpeg',
    desc: '• Blueprint Advanced Programming + Savant Central Management Initial Set-Up'
  },
  {
    name: 'New Integrator/Contractor Onsite Ride-Along',
    category: 'Training Services',
    sku:'123-123-00',
    msrp: 3500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/HostFamily.png',
    desc: '• 8 Hours of Onsite Services • Team Development • New Category Deployments'
  },
  {
    name: 'Onsite Service x8 Audio Tuning & Personalization',
    category: 'Training Services',
    sku:'123-123-00',
    msrp: 3500,
    image: 'https://sweb-img.s3.amazonaws.com/Community/Pro%20Services/Home3.png',
    desc: '• 8 Hours of Onsite Services • Team Development • New Category Deployments'
  },
];

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});
const SavDateForm = React.forwardRef((props, ref) => {
  return (
    <DateStyle >
      <DatePicker
        onChange={props.onChange}
        value={props.value}
        format="y-MM-dd"
        minDate={new Date()}
      />
    </DateStyle>
  )
});

const ProjectRequestModal = ({show, hide, account, user, contacts, createForm, selectedService, loadingForm}) => {
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(4);
  const [validated, setValidated] = useState(false);
  const [smartTypes, setSmartTypes] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [powerType, setPowerType] = useState("");
  const [trainingType, setTrainingType] = useState("");
  const [projectName, setProjectName] = useState('');
  const [projectDate, setProjectDate] = useState('');
  const [projectLocation, setProjectLocation] = useState('');
  const [projectCity, setProjectCity] = useState('');
  const [clientDate, setClientDate] = useState('');
  const [projectLead, setProjectLead] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [projectBudget, setProjectBudget] = useState('');
  const [contactOpts, setContactOpts] = useState([]);
  const [isPower, setIsPower] = useState(false);
  const [isSmart, setIsSmart] = useState(false);
  const [isTraining,setIsTraining] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [isLighting, setIsLighting] = useState(false);

  const [trainingTopic, setTrainingTopic] = useState('');
  const [trainingDate, setTrainingDate] = useState('');
  const [trainingCoordinator, setTrainingCoordinator] = useState('');
  const [trainingPhone, setTrainingPhone] = useState('');
  const [trainingEmail, setTrainingEmail] = useState('');
  const [trainingLocation, setTrainingLocation] = useState('');
  const [trainingCity, setTrainingCity] = useState('');

  const [lightingCeilingInfo, setLightingCeilingInfo] = useState('');
  const [lightingSpacePurpose, setLightingSpacePurpose] = useState('');
  const [lightingTech, setLightingTech] = useState('');
  const [lightingAperture, setLightingAperture] = useState('');
  const [lightingTrimShape, setLightingTrimShape] = useState('');
  const [lightingTrimDesign, setLightingTrimDesign] = useState('');
  const [lightingBudget, setLightingBudget] = useState('');
  const [lightingOther, setLightingOther] = useState('');

  const [otherText, setOthertext] = useState("");
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(contacts)){
      let contactsTemps = [];
      contacts.forEach((c) => {
        const contactObj = {
          name: c.name,
          code: c.sfid,
        }
        contactsTemps.push(contactObj);
      });
      setContactOpts(contactsTemps);
    }
  },[contacts]);

  useEffect(() => {
    if (!_.isEmpty(projectLead)){
      const contactFound = _.find(contacts, (c) => c.sfid === projectLead);
      setContactEmail(contactFound.email);
      setContactPhone(contactFound.mobile);
    }
  },[projectLead]);

  useEffect(() => {
    if (!_.isEmpty(trainingCoordinator)){
      const contactFound = _.find(contacts, (c) => c.sfid === trainingCoordinator);
      setTrainingEmail(contactFound.email);
      setTrainingPhone(contactFound.mobile);
    }
  },[trainingCoordinator]);

  useEffect(() => {
    const productFound = _.find(products, (p) => p.name.includes(selectedService))
    if (!_.isEmpty(productFound)){
      const temp = [];
      temp.push(productFound.name);
      setProjectTypes(temp);
    }

    

  }, [selectedService]);

  useEffect(() => {
    if (
      projectTypes.includes('Design & Consultation Services x10') ||
      projectTypes.includes('Engineering Support') ||
      projectTypes.includes('Full Service') 
      ){
        setIsPower(true);
      }

  },[projectTypes])

  useEffect(() => {
    if (!show){
      setStep(1);
      setIsPower(false);
      setIsSmart(false);
      setIsTraining(false);
      setIsLighting(false);
      setIsOther(false);
      setOthertext('');
      setSmartTypes([]);
      setProjectTypes([]);
      setTrainingType('');
      setPowerType('')
      setProjectName('');
      setProjectDate('');
      setClientDate('');
      setProjectLead('');
      setContactEmail('');
      setContactPhone('');
      setProjectLocation('');
      setProjectCity('');
      setProjectBudget('');
      setTrainingTopic('');
      setTrainingCoordinator('');
      setTrainingDate('');
      setTrainingEmail('');
      setTrainingPhone('');
      setTrainingLocation('');
      setTrainingCity('');
      setValidated(false);
      setErrorText(null);
    } else {
      if (selectedService === 'Lighting Layout Service'){
        setStep(3);
        setIsLighting(true);
      } else {
        setStep(1);
      }
    }
  },[show]);

  const getProjectLead = () => {
    const contactFound = _.find(contacts, (c) => c.sfid === projectLead);
    if (contactFound){
      return contactFound.name;
    } else {
      return "";
    }
  }
  const getTrainingLead = () => {
    const contactFound = _.find(contacts, (c) => c.sfid === trainingCoordinator);
    if (contactFound){
      return contactFound.name;
    } else {
      return "";
    }
  }


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const formObject = {
      projectName: projectName,
      serviceCategory: projectTypes,
      projOtherDetail: otherText,
      projectLocation: projectLocation,
      projectCity: projectCity,
      estStartDate: parseDateInt(projectDate) === "Invalid Date" ? null : moment(parseDateInt(projectDate)).format('M/DD/YYYY'),
      clientPresentationDate: parseDateInt(clientDate) === "Invalid Date" ? null : moment(parseDateInt(clientDate)).format('M/DD/YYYY'),
      budget: _.isEmpty(projectBudget) ? 0 : projectBudget,
      projectLeadId: projectLead,
      projIncludesPowerMgmt: isPower,
      projIncludesSmartHome: isSmart,
      projIncludesOnsiteTraining: isTraining,
      projIncludesLighting: isLighting,
      electricalServiceSize: powerType,
      smartTechnologies: smartTypes,
      desiredTraining: trainingType,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
      trainingTopic: trainingTopic,
      trainingDate: parseDateInt(trainingDate) === "Invalid Date" ? null : moment(parseDateInt(trainingDate)).format('M/DD/YYYY'),
      trainingCoordinatorId: trainingCoordinator,
      trainingCoordinatorPhone: trainingPhone,
      trainingCoordinatorEmail: trainingEmail,
      trainingLocation: trainingLocation,
      trainingCity:trainingCity,
      lightingTrimShape: lightingTrimShape,
      lightingTrimDesign: lightingTrimDesign,
      lightingTechnology: lightingTech,
      lightingSpacePurpose: lightingSpacePurpose,
      lightingCeilingInfo: lightingCeilingInfo,
      lightingBudget: lightingBudget,
      lightingApertureSize: lightingAperture,
      lightingInfo: lightingOther
    }
    createForm(formObject).then((val) => {
      if (val.data.success){
        setErrorText(null);
        setStep(4);
      } else {
        if (val.data.errors.length > 0){
          if (val.data.errors[0].includes('INVALID_EMAIL_ADDRESS')){
            setErrorText("Invalid Email Address")
          } else {
            setErrorText("Something went wrong, please try again.")
          }
          
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
      
    })
    return;
  }
  const handleNext = () => {
    if (step + 1 <= totalSteps) {
      setStep(step + 1);
    }
  };
  const handleBack = () => {
    if (step - 1 !== 0) {
      setStep(step - 1);
    }
  };
  const handleClickService = (type) => {
    const typeCopy = _.filter(projectTypes, (t) => t === type);
    const temp = [...projectTypes];

    if (!_.isEmpty(typeCopy)){
      setProjectTypes(_.filter(projectTypes, (t) => t !== type));
    } else {
      temp.push(type);
      setProjectTypes(temp);
    }
  };
  const handleClickIcon = (type) => {
    const typeCopy = _.filter(smartTypes, (t) => t === type);
    const temp = [...smartTypes];

    if (!_.isEmpty(typeCopy)){
      setSmartTypes(_.filter(smartTypes, (t) => t !== type));
    } else {
      temp.push(type);
      setSmartTypes(temp);
    }
  }
  const handleClickPowerType = (type) => {
    if (powerType === type){
      setPowerType("");
    } else {
      setPowerType(type);
    }
  }
  const handleClickTrainingType = (type) => {
    if (trainingType === type){
      setTrainingType("");
    } else {
      setTrainingType(type);
    }
  }


  const renderProgress = () => {
    return (
      <ProgressBar>
        {_.times(totalSteps, (i) => {
          return (
            <ProgressDiv
              key={i}
              bg="#3f3f3f"
              border={step === i + 1 ? "5px solid #ff5f00" : null}
              color={step >= i + 1 ? "#ff5f00" : "#fff"}
              totalSteps={totalSteps}
            >
              <StepDiv>STEP {i + 1}</StepDiv>
              <StepText>{renderStepText(i + 1)}</StepText>
            </ProgressDiv>
          );
        })}
      </ProgressBar>
    );
  };
  const renderStepText = (currentStep) => {
    if (currentStep === 1) {
      return "Service Info";
    }
    if (currentStep === 2) {
      return "Technology Info";
    }
    if (currentStep === 3) {
      return "Project Info";
    }
    if (currentStep === 4) {
      return "Complete";
    }
  };
  const renderServices = () => {

    const fraction = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return products.map((p) => {
      return (
        <Col xl={3} lg={4} md={6} sm={6} xs={6} key={p.name}>
          <PackageDiv
            category={p.category}
            style={projectTypes.includes(p.name) ? selected : null}
            onClick={() => handleClickService(p.name)}
          >
            <PackageDivImage>
                <img src={p.image} alt="pkgIcon" />
                <PackageDivOverlay>
                  <div style={{width: '100%'}} className="med f14 text-center text-uppercase wht">{p.name}</div>
                  <div style={{position: 'absolute', bottom: 15, left: 10}} className="book f10 wht">Starting At</div>
                  <div style={{position: 'absolute', bottom: 0, left: 10}} className="med f12 oj">{fraction.format(p.msrp)}</div>
                </PackageDivOverlay>
              </PackageDivImage>

          </PackageDiv>
        </Col>
      )
    })
  };
  const renderStepOne = () => {
    if (step === 1){
      return (
        <Fragment>
          <SectionHeader>Select one or more of the services below that you are interested in or click "Next" to skip.</SectionHeader>
          <Row>
            {renderServices()}
          </Row>
        </Fragment>
      )
    }

  }
  const renderStepThree =() => {
    if (loadingForm){
      return (
        <div className="savFlexBetweenCenter" style={{height: 400}}>
          <div className="loader" />
        </div>
      )
    }

    if (step === 3 && isTraining && !isSmart && !isPower && !loadingForm){
      return (
        <Fragment>
          <SectionHeader>Training Information</SectionHeader>
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={trainingTopic} label="Training Topic" setValue={setTrainingTopic}/>
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <div className="input-container-date">
                <Form.Control
                  as={SavDateForm}
                  onChange={setTrainingDate}
                  value={trainingDate}
                />
                <label className={trainingDate}>Target Training Date</label>
              </div>
              </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={4}>
              <SavFormDrop value={getTrainingLead()} setValue={setTrainingCoordinator} label="Training Coordinator" opts={contactOpts} objectOpts required validated={validated} />
            </Form.Group>
            <Form.Group as={Col} sm={4}>
                <SavFormInput value={trainingPhone || ''} label="Contact Phone" setValue={setTrainingPhone} />
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <SavFormInput value={trainingEmail || ''} label="Contact Email" setValue={setTrainingEmail} type="email" />
              </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={trainingLocation} label="Training Location Details" setValue={setTrainingLocation} notRequired />
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={trainingCity} label="Training City" setValue={setTrainingCity}/>
            </Form.Group>
          </Form.Row>
          <div className="m-t-20 text-center red">{errorText}</div>
        </Fragment>
      )
    }

    if (step === 3  && !loadingForm){

      const renderLightingForm = () => {
        if (isLighting){
          return (
            <Fragment>
              <SectionHeader>Lighting Layout Information</SectionHeader>

              <Form.Row>
                <Form.Group as={Col} lg={3} md={12}>
                  <SavFormDrop value={lightingAperture} setValue={setLightingAperture} label="Desired Aperture Size" opts={['1"', '2"', '3"', '4.5"', 'Other']} required validated={validated} />
                </Form.Group>

                <Form.Group as={Col} lg={3} md={12}>
                  <SavFormDrop value={lightingTrimShape} setValue={setLightingTrimShape} label="Trim Shape" opts={['Round', 'Square']} required validated={validated} />
                </Form.Group>

                <Form.Group as={Col} lg={3} md={12}>
                  <SavFormDrop value={lightingTrimDesign} setValue={setLightingTrimDesign} label="Trim Design" opts={['Trimmed (flanged)', 'Trimless', 'Millwork']} required validated={validated} />
                </Form.Group>

                <Form.Group as={Col} lg={3} md={12}>
                  <SavFormDrop value={lightingBudget} setValue={setLightingBudget} label="Estimated Fixture Budget" opts={['Tier 1 [economy]', 'Tier 2 [standard]', 'Tier 3 [premium]']} required validated={validated} />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} lg={4} md={12}>
                  <SavFormInputArea value={lightingCeilingInfo} setValue={setLightingCeilingInfo} label="Ceiling Information" notRequired />
                  <Form.Text muted>
                    Provide ceiling height / configuration (pitched, hip, coffered, soffit, beams, etc.) as well as plenum height restrictions. Ceiling material and color.
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} lg={4} md={12}>
                  <SavFormInputArea value={lightingSpacePurpose} setValue={setLightingSpacePurpose} label="Space Purpose" notRequired />
                  <Form.Text muted>
                    Purposes/priority of the space: primary, secondary, tertiary.
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} lg={4} md={12}>
                  <SavFormInputArea value={lightingTech} setValue={setLightingTech} label="Lighting Technology" notRequired />
                  <Form.Text muted>
                    Desired type of lighting technology/dimming technology. Examples: static white (2700K, 3000K), warm dimming/glow, tunable white, full color (WRGB). Phase dimming, 0-10V, DMX, etc.
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInputArea value={lightingOther} setValue={setLightingOther} label="Additional Lighting Project Information" notRequired />
                  <Form.Text muted>
                    Please provide dropbox or links to any available site photos or renderings, full CD set (construction documents), and any other accompanying design documentation, including Millwork Elevations/Plans, Details, and call out specifically the spaces you are requesting Lighting Layout services for.
                  </Form.Text>
                </Form.Group>
              </Form.Row>


            </Fragment>
          )
        }
        return null;
      }
      
      return (
        <Fragment>
          
          <SectionHeader>Project Information</SectionHeader>
          <Form.Row>
            <Form.Group as={Col} lg={4} md={12}>
              <SavFormInput value={projectName} label="Project Name" setValue={setProjectName}/>
            </Form.Group>
            <Form.Group as={Col} lg={4} md={6}>
              <div className="input-container-date">
                <Form.Control
                  as={SavDateForm}
                  onChange={setProjectDate}
                  value={projectDate}
                />
                <label className={projectDate}>Est. Install Date</label>
              </div>
              </Form.Group>
              <Form.Group as={Col} lg={4} md={6}>
              <div className="input-container-date">
                <Form.Control
                  as={SavDateForm}
                  onChange={setClientDate}
                  value={clientDate}
                />
                
                <label className={clientDate}>Est. Client Presentation Date</label>
              </div>
              </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={4}>
              <SavFormDrop value={getProjectLead()} setValue={setProjectLead} label="Project Lead" opts={contactOpts} objectOpts required validated={validated} />
            </Form.Group>
            <Form.Group as={Col} md={4}>
                <SavFormInput value={contactPhone || ''} label="Contact Phone" setValue={setContactPhone} type="tel" />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <SavFormInput value={contactEmail || ''} label="Contact Email" setValue={setContactEmail} type="email" />
              </Form.Group>
          </Form.Row>

          {/* <SectionHeader>When are you presenting to client?</SectionHeader> */}
          <Form.Row>
            <Form.Group as={Col} md={4}>
              <SavFormInput value={projectLocation} setValue={setProjectLocation} label="Project Location Details" notRequired />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <SavFormInput value={projectCity} setValue={setProjectCity} label="Project City" />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <SavFormInput value={projectBudget} setValue={setProjectBudget} label="Est. Project Budget (USD)" type="number" notRequired />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <SavFormInputArea value={otherText} setValue={setOthertext} label="Project Description" notRequired />
              <Form.Text muted style={!isLighting ? {display: 'none'} : null}>
                Please list the specific rooms or spaces for which you require Savant Layout Services
              </Form.Text>
            </Form.Group>
          </Form.Row>
          {renderLightingForm()}
          <div className="m-t-20 text-center red">{errorText}</div>
        </Fragment>
      )
    }
    return null;
  }

  const renderStepTwo = () => {

    const renderSmart = () => {
      if (isSmart){
        return (
          <Fragment>
            <SectionHeader>What type of smart technology does the project involve? (select all that apply)</SectionHeader>
            <CardContainer>

              <CardBox
                onClick={() => handleClickIcon('entertainment')}
                style={smartTypes.includes('entertainment') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Media fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Entertainment</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('lighting')}
                style={smartTypes.includes('lighting') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Lighting fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Lighting</CardBoxText>
              </CardBox>


              <CardBox
                onClick={() => handleClickIcon('shades')}
                style={smartTypes.includes('shades') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Shade fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Shades</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('climate')}
                style={smartTypes.includes('climate') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Climate fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Climate</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('ipCameras')}
                style={smartTypes.includes('ipCameras') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Camera fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>IP Cameras</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('music')}
                style={smartTypes.includes('music') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <SavantMusic fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Music</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('security')}
                style={smartTypes.includes('security') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Security fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Security</CardBoxText>
              </CardBox>


              <CardBox
                onClick={() => handleClickIcon('tiling')}
                style={smartTypes.includes('tiling') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Tiling fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Tiling</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('pool')}
                style={smartTypes.includes('pool') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Pool fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Pool & Spa</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('entry')}
                style={smartTypes.includes('entry') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Entry fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Entry</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('energy')}
                style={smartTypes.includes('energy') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Energy fill="#3f3f3f" height="50px" />
                </CardBoxImg>
                <CardBoxText>Energy</CardBoxText>
              </CardBox>

              <CardBox
                onClick={() => handleClickIcon('network')}
                style={smartTypes.includes('network') ? ActiveCard : null}
              >
                <CardBoxImg>
                  <MdWifi size={50} style={{fill: '#3f3f3f'}} />
                </CardBoxImg>
                <CardBoxText>Network AI</CardBoxText>
              </CardBox>

            </CardContainer>
          </Fragment>
        )
      }
    }
    const renderEnergy = () => {
      if (isPower){
        return (
          <Fragment>
            <SectionHeader>Electrical Service Size (select one)</SectionHeader>
            <CardContainer>
              <CardBox 
                onClick={() => handleClickPowerType('200A')}
                style={powerType === '200A' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Power fill="#262b32" height="30px" />
                </CardBoxImg>
                <CardBoxText>200A</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickPowerType('400A')}
                style={powerType === '400A' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Power fill="#ffcc5f" height="30px" />
                </CardBoxImg>
                <CardBoxText>400A</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickPowerType('600A')}
                style={powerType === '600A' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Power fill="#4cd964" height="30px" />
                </CardBoxImg>
                <CardBoxText>600A</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickPowerType('800A')}
                style={powerType === '800A' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <Power fill="#d0021b" height="30px" />
                </CardBoxImg>
                <CardBoxText>800A</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickPowerType('Not Sure')}
                style={powerType === 'Not Sure' ? ActiveCard : null}
              >
                <CardBoxImg>
                  {/* <Lighting fill="#979797" height="30px" /> */}
                </CardBoxImg>
                <CardBoxText>Not Sure</CardBoxText>
              </CardBox>
            </CardContainer>
          </Fragment>
        )
      }

    }
    const renderTraining = () => {
      if (isTraining){
        return (
          <Fragment>
            <SectionHeader>Are you interested in Virtual or On-Site Training?</SectionHeader>
            <CardContainer>
              <CardBox 
                onClick={() => handleClickTrainingType('Virtual')}
                style={trainingType === 'Virtual' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <FaLaptop size={30} style={{fill:"#3f3f3f"}} />
                </CardBoxImg>
                <CardBoxText>Virtual</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickTrainingType('On-Site')}
                style={trainingType === 'On-Site' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <BsFillHouseDoorFill size={30} style={{fill:"#3f3f3f"}} />
                </CardBoxImg>
                <CardBoxText>On-Site</CardBoxText>
              </CardBox>
              <CardBox
                onClick={() => handleClickTrainingType('Both')}
                style={trainingType === 'Both' ? ActiveCard : null}
              >
                <CardBoxImg>
                  <FaLaptopHouse size={30} style={{fill:"#3f3f3f"}} />
                </CardBoxImg>
                <CardBoxText>Both</CardBoxText>
              </CardBox>

            </CardContainer>
          </Fragment>
        )
      }

    }

    const renderOther = () => {
      if (isOther){
        return (
          <Fragment>
            <SectionHeader>Briefly describe your project. Please be as detailed as possible.</SectionHeader>
            <SavFormInputArea value={otherText} setValue={setOthertext} label="Project Description" />
          </Fragment>
          
        )
      }
      return null;
    }
    if (step === 2){
      return (
        <Fragment>
          <SectionHeader>Which of the following best describes your project? (select all that apply)</SectionHeader>
          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="savFlexStart" onClick={() => setIsPower(!isPower)} style={{cursor:'pointer'}}>
                <div style={{width: 25, display:'flex', alignItems:'center'}}>
                  <Form.Control as={SavCheckForm} checked={isPower} click={() => setIsPower(!isPower)} />
                </div>
                <div>This project includes Savant Power Management System and/or Energy Storage.</div>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="savFlexStart" onClick={() => setIsSmart(!isSmart)} style={{cursor:'pointer'}}>
                <div style={{width: 25, display:'flex', alignItems:'center'}}>
                  <Form.Control as={SavCheckForm} checked={isSmart} click={() => setIsSmart(!isSmart)} />
                </div>
                <div>This project includes a Savant Smart Home System.</div>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="savFlexStart" onClick={() => setIsLighting(!isLighting)} style={{cursor:'pointer'}}>
                <div style={{width: 25, display:'flex', alignItems:'center'}}>
                  <Form.Control as={SavCheckForm} checked={isLighting} click={() => setIsLighting(!isLighting)} />
                </div>
                <div>This project includes USAI or Savant/GE Lighting fixtures.</div>
              </div>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="savFlexStart" onClick={() => setIsTraining(!isTraining)} style={{cursor:'pointer'}}>
                <div style={{width: 25, display:'flex', alignItems:'center'}}>
                  <Form.Control as={SavCheckForm} checked={isTraining} click={() => setIsTraining(!isTraining)} />
                </div>
                <div>I am looking for advanced or onsite training for my team.</div>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="savFlexStart" onClick={() => setIsOther(!isOther)} style={{cursor:'pointer'}}>
                <div style={{width: 25, display:'flex', alignItems:'center'}}>
                  <Form.Control as={SavCheckForm} checked={isOther} click={() => setIsOther(!isOther)} />
                </div>
                <div>Other</div>
              </div>
            </Form.Group>
          </Form.Row>
          {renderOther()}
          {renderEnergy()}
          {renderSmart()}
          {renderTraining()}
          
          
        </Fragment>
        
      )
    }
    return null;
  }

  const renderStepFour = () => {
    if (step === 4){
      return (
        <div>
          <div className="text-center f24 m-t-20 m-b-20">
            Thank you!
          </div>
          <div className="book text-center f14 m-t-20 m-b-20">
           A Savant Services Team Member will be in touch within the next 2 business days.<br />
          </div>
        </div>
      )
    }
  }
  
  if (!_.isEmpty(account) && !_.isEmpty(contacts)){
    return (
      <Fragment>
        <Modal show={show} onHide={hide} animation={false} centered size="xl">
          {renderProgress()}
          <SavModalClose onClick={hide}>&times;</SavModalClose>
          <Form onSubmit={handleSubmit} noValidate validated={validated} className="sav-form">
          <Modal.Body>
            <Container style={{minHeight: 400}}>
                {renderStepOne()}
                {renderStepTwo()}
                {renderStepThree()}
                {renderStepFour()}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <ButtonDiv>
              <button
                className="sav-btn__gray"
                onClick={hide}
                style={step === 4 ? null : {display: 'none'}}
                type="button"
              >
                Done
              </button>
              &nbsp;&nbsp;
              <button
                className="sav-btn__gray"
                onClick={() => handleBack()}
                style={step !== 1 && step !== 4 && !loadingForm ? null : {display: 'none'}}
                type="button"
              >
                Back
              </button>
              &nbsp;&nbsp;
              <button
                className="sav-btn__orange"
                onClick={() => handleNext()}
                style={step !== 3 && step !== 4 ? null : {display: 'none'}}
                type="button"
                // disabled={issueType === "" ? true : false}
                // style={issueType === "" ? { cursor: "not-allowed" } : null}
              >
                Next
              </button>
              &nbsp;&nbsp;
              <button
                className="sav-btn__orange--fill"
                type="submit"
                style={step === 3 && !loadingForm ? null : {display: 'none'}}
              >
                Submit Now
              </button>
            </ButtonDiv>
          </Modal.Footer>
          </Form>
        </Modal>
      </Fragment>
    )
  }
  return null;

}

export default ProjectRequestModal;
import { FETCH_ORDERS_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const orders = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default orders;

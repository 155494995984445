import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Row, Col, Container, Tab, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import {SubHeader} from "../../../components/Styled";
import SavToast from "../../../components/SavToast";
import SavToastSuccess from "../../../components/SavToastSuccess";
import SavToastFail from "../../../components/SavToastFail";
import AccountDetails from "./AccountDetails";
import AccountSettings from "./AccountSettings";
import AccountSavantSCM from "./AccountSavantSCM/AccountSavantSCM";
import AccountSavedCredit from "./AccountSavedCredit";
import AccountAddresses from "./AccountAddresses/AccountAddresses";
import {visibilitySelector} from "../../../selectors";
import Skeleton from "react-loading-skeleton";
import bankAccounts from "../../../reducers/bankAccounts";

const linkStyle = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#fafaf9",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const linkStyleActive = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#dbd9d6",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const itemStyle = {
  marginBottom: 8
};
const hidden = {
  display:'none'
}

const Settings = props => {
  const [key, setKey] = useState("accountDetails");
  const { account, contacts, addresses, creditCards,bankAccounts, location, user } = props;

  const showInfo = useSelector(visibilitySelector('account_settings_details_company'));
  const showStatus = useSelector(visibilitySelector('account_settings_details_status'));
  const showSettings = useSelector(visibilitySelector('account_settings_settings'));
  const showScm = useSelector(visibilitySelector('account_settings_scm'));
  const showCc = useSelector(visibilitySelector('account_settings_creditCards'));
  const showAddresses = useSelector(visibilitySelector('account_settings_addresses'));
  let param = location.search;

  useEffect(() => {
    if (param === '?tab=details'){
      setKey('accountDetails');
    }
    if (param === '?tab=credit'){
      setKey('creditCards');
    }
    document.title = "Savant Community | Settings"
  },[]);

  useEffect(() => {
    if (!showInfo && !showStatus && showSettings) {
      setKey("accountSettings");
    }
    if (!showSettings && showScm && !showInfo) {
      setKey("savantSCM");
    }
    if (!showSettings && showScm && showInfo) {
      setKey("accountDetails");
    }
    if (!showScm && !showSettings && showCc) {
      setKey("creditCards")
    }
  },[showInfo, showStatus, showSettings, showScm, showCc, showAddresses ]);

  if (account && contacts && addresses && creditCards && user){
    let isElectrical = false;
    if (
      (user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'Electrical Contractor') || 
      (user.contact.accType === 'Contractor' && user.contact.accSubType === 'Electrical/Solar')
    ){
      isElectrical = true;
    }
    
    return (
      <div>
        <SavToast />
        <SavToastSuccess />
        <SavToastFail />
        <SubHeader>Settings</SubHeader>
        <Container style={{maxWidth:1000}}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Row>
              <Col md={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item style={showStatus || showInfo ? itemStyle : hidden}>
                    <Nav.Link
                      style={key === "accountDetails" ? linkStyleActive : linkStyle}
                      eventKey="accountDetails"
                      to={`/account?tab=details#settngs`}
                    >
                      Account Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={showSettings ? itemStyle : hidden}>
                    <Nav.Link
                      style={
                        key === "accountSettings" ? linkStyleActive : linkStyle
                      }
                      eventKey="accountSettings"
                    >
                      Account Settings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={showAddresses ? itemStyle : hidden}>
                    <Nav.Link
                      style={
                        key === "accountAddresses" ? linkStyleActive : linkStyle
                      }
                      eventKey="accountAddresses"
                    >
                      Account Addresses
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={showScm ? itemStyle : hidden}>
                    <Nav.Link
                      style={key === "savantSCM" ? linkStyleActive : linkStyle}
                      eventKey="savantSCM"
                    >
                      {isElectrical ? 'SAVANT.COM' : 'SAVANT.COM & SCM'}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item style={showCc ? itemStyle : hidden}>
                    <Nav.Link
                      style={key === "creditCards" ? linkStyleActive : linkStyle}
                      eventKey="creditCards"
                    >
                      Saved Payment Methods
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="accountDetails">
                    <AccountDetails account={account} user={user} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="accountSettings">
                    <AccountSettings
                      account={account}
                      addresses={addresses}
                      contacts={contacts}
                      user={user}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="accountAddresses">
                    <AccountAddresses
                      account={account}
                      addresses={addresses}
                      contacts={contacts}
                      user={user}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="savantSCM">
                    <AccountSavantSCM account={account} addresses={addresses} isElectrical={isElectrical} user={user} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="creditCards">
                    <AccountSavedCredit account={account} creditCards={creditCards} addresses={addresses} bankAccounts={bankAccounts} user={user} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    );
  }
  return (
    <Container style={{maxWidth:1000, marginTop: 100}}>
      <Row>
        <Col md={4}>
          <Skeleton height={50} count={3} />
        </Col>
        <Col md={8}>
          <Skeleton height={200} count={3} />
        </Col>
      </Row>
    </Container>
  )
  
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    contacts: state.contacts.data,
    addresses: state.addresses.data,
    creditCards: state.creditCards.data,
    bankAccounts: state.bankAccounts.data,
    user: state.user.data,
  };
};

export default connect(mapStateToProps, null)(Settings);

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Table } from "react-bootstrap";
import _ from "lodash";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import SavDrop from "../../../components/SavDrop";
import { SavSearch, SavRow, SavLabel } from "../../../components/Styled";
import {parseCurrency, eliteSearchAlgorithm} from "../../../components/Utilities"
import SavDatePicker from "../../../components/SavDatePicker";
import {AiOutlineCloudDownload} from "react-icons/ai";
import { save } from 'save-file'
import {Link} from "react-router-dom";

const TabHeader = styled.div`
  margin: 30px 0px;
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 14px;
`;
const InvoiceGridItem = styled.div`
  min-height: 175px;
  padding: 50px 15px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Book";
`;

const GridItemContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 30px;
`;
const FilterSpan = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline
  color: #9b9b9b;
  font-family: "Gotham-Medium";
  padding: 0px 8px;
  cursor: pointer;
  &:hover {
    color: #ff5f00;
  }
`;

const pageOpts = [10, 25, 50, 100];

const CreditMemosTab = props => {
  const { credits, account, fetchDocument, setSuccessToast } = props;

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchString, setSearchString] = useState("");
  const [creditList, setCreditList] = useState([]);
  const [creditFilter, setCreditFilter] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");

  const startDateDefault = new Date(2012, 12, 1);
  const endDateDefault = new Date();
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);

  useEffect(() => {
    if (!_.isEmpty(credits)) {
      setCreditList(credits);
    }
  }, [credits]);

  useEffect(() => {
    if (!_.isEmpty(credits)) {
      setCurrentPage(1);
      if (activeFilter === "Open"){
        const results = credits.filter(
          credit =>
            Date.parse(credit.creditDate) >= startDate &&
            Date.parse(credit.creditDate) <= endDate &&
            eliteSearchAlgorithm(searchString, credit) &&
            Math.abs(credit.total) - Math.abs(credit.appliedAmount) > 0
        );
        setCreditList(results);
      } else if (activeFilter === "Used"){
        const results = credits.filter(
          credit =>
            Date.parse(credit.creditDate) >= startDate &&
            Date.parse(credit.creditDate) <= endDate &&
            eliteSearchAlgorithm(searchString, credit) &&
            Math.abs(credit.total) - Math.abs(credit.appliedAmount) === 0
        );
        setCreditList(results);
      } else {
        const results = credits.filter(
          credit =>
            Date.parse(credit.creditDate) >= startDate &&
            Date.parse(credit.creditDate) <= endDate &&
            eliteSearchAlgorithm(searchString, credit)
        );
        setCreditList(results);
      }
    }
  },[searchString, startDate, endDate, activeFilter]);

  useEffect(() => {
    if (creditList) {
      let index = 0;
      const tempCredits = [];
      for (index = 0; index < creditList.length; index += pageSize) {
        let chunk = creditList.slice(index, index + pageSize);
        tempCredits.push(chunk);
      }
      setCreditFilter(tempCredits);
    }
  }, [creditList, pageSize]);

  const handleFetchPDF = (id, number) => {

    document.body.style.cursor = 'wait'
    fetchDocument(id, "creditMemo").then((res) => {
      document.body.style.cursor = 'default'
      if (res.success) {
        const data = res.payload; 
        save(data, `Savant Credit Memo - ${number}.pdf`);
      }
      setSuccessToast(true);
    })
  };

  const handleClearFilters = () => {
    setActiveFilter("All");
    setSearchString("");
  };
  const renderCredits = () => {
    if (!_.isEmpty(creditFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > creditFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return creditFilter[page].map(credit => {
        return (
          <SavRow key={credit.sfid} className="vertical-align" style={{padding: '20px 0px'}}>
            <Col md={2} xs={4}>{credit.creditMemoNumber}</Col>
            <Col md={2} className="d-none d-md-block">{credit.creditDate}</Col>
            <Col md={2} className="d-none d-md-block">{credit.orderNumber}</Col>
            <Col md={2} xs={4}>{credit.poNumber}</Col>
            <Col md={1} xs={3}>{parseCurrency(credit.total,2)}</Col>
            {/* <Col md={1} className={(Math.abs(credit.total) - Math.abs(credit.appliedAmount)) > 0 ? "green med d-none d-md-block" : "d-none d-md-block"}> */}
            <Col md={1} className={credit.open > 0 ? "green med d-none d-md-block" : credit.reservedCredit > 0 ? "wip med d-none d-md-block" : "d-none d-md-block"}>
              {/* {parseCurrency(Math.abs(credit.total - credit.appliedAmount),2)} */}
              {parseCurrency(credit.open)}
            </Col>
            <Col md={1} className="d-none d-md-block">{credit.creditMemoType}</Col>
            <Col md={1} xs={1} className="text-center" style={{padding: 0}}>
              <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} onClick={() => handleFetchPDF(credit.sfid, credit.creditedBillingName)} size={25} />
            </Col>
          </SavRow>
        )
      });
    }

  };

  const renderTable = () => {
    if (creditList.length > 0){
      return (
        renderCredits()
      )
    }
    return (
      <Row>
        <Col
          xs={12}
          style={
            creditList.length > 0
              ? { display: "none" }
              : { textAlign: "center", marginTop: 30 }
          }
        >
          No Results found.
          <br />
          <br />
          <button
            className="sav-btn__orange--text"
            onClick={() => handleClearFilters()}
          >
            Clear Filters
          </button>
        </Col>
      </Row>
    );

  }

  const renderHeaders = () => {
    if (!_.isEmpty(account)) {
      return (
        <Row>
          <Col sm={4}>
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Current Balance
              </div>
              <GridItemContent className="truncate">
                {parseCurrency(account.arBalance, 2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
          
          <Col sm={4} >
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Past Due Balance
              </div>
              <GridItemContent className={account.pastDue === 0 ? "gray truncate" : "red truncate"}>
                {parseCurrency(account.pastDue, 2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
          
          <Col sm={4}>
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Available Credit
              </div>
              <GridItemContent className={account.availableCredit === 0 ? "gray truncate" : "green truncate"}>
                {parseCurrency(account.availableCredit,2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
        </Row>
      );
    }
    return null;
  };

  if (credits || account) {
    return (
      <Container>
        {renderHeaders()}
        <Row className="m-t-10">
          <Col xs={6} md={4}>
            <SavSearch
              type="search"
              placeholder="Search.."
              width="100%"
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </Col>
          <Col xs={6} md={8} className="text-right">
            <FilterSpan onClick={() => setActiveFilter('All')} className={activeFilter === 'All' ? 'oj' : null}>All</FilterSpan>
            <FilterSpan onClick={() => setActiveFilter('Open')} className={activeFilter === 'Open' ? 'oj' : null}>Open</FilterSpan>
            <FilterSpan onClick={() => setActiveFilter('Used')} className={activeFilter === 'Used' ? 'oj' : null}>Used</FilterSpan>
          </Col>
        </Row>
        <Row className="m-t-20 vertical-align">
          <Col sm={2} className="d-none d-md-block">
          <SavLabel>Page Size</SavLabel>
            <SavDrop
              opts={pageOpts}
              val={pageSize}
              click={setPageSize}
            />
          </Col>
          <Col sm={10} className="d-none d-md-block">
            <SavDatePicker
              clickStart={setStartDate}
              clickEnd={setEndDate}
              start={startDate}
              end={endDate}
              style={{ float: "right" }}
            />
          </Col>
        </Row>
        <hr />
        <SavColumnSort
          data={creditList}
          setData={setCreditList}
          columns={[
            { text: "Credit Memo #", size: 2, sizeXS: 4, value: "creditedBillingName" },
            { text: "Date", size: 2, value: "creditDate", hideMobile: true },
            { text: "Order #", size: 2, sizeXS: 4, value: "orderNumber", hideMobile: true },
            { text: "Purchase Order #", textXS: "P.O. #", size: 2, sizeXS: 4, value: "poNumber" },
            { text: "Total", size: 1, sizeXS: 4, value: "total"},
            { text: "Open", size: 1, value: "open", hideMobile: true  },
            { text: "Type", size: 2, value: "creditMemoType", hideMobile: true },
          ]}
        />
        {renderTable()}
        <SavPagination
          data={creditFilter}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataLength={creditList.length}
        />
      </Container>
    );
  }
  return <div>loading...</div>;
};

export default CreditMemosTab;

import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import _ from "lodash";
import {SectionHeader} from '../../../components/Styled';
import CustomizeModal from './CustomizeModal';
import Card from './Card';


const Dashboard = ({grid, updateGrid, invoices, loading, account, user}) => {

  const [gridItemsMax, setGridItemsMax] = useState(null);
  const [gridItems, setGridItems] = useState(
    _.orderBy(grid, ["sequence"], ["asc"])
  );
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (grid.data) {
      setGridItems(grid.data);
      setGridItemsMax(grid.data.slice(0, 9));
    }
  }, [grid]);

  useEffect(() => {
    if (grid.data) {
      if (viewMore) {
        setGridItemsMax(grid.data);
      } else {
        setGridItemsMax(grid.data.slice(0, 9));
      }
    }
  }, [viewMore]);


  const renderCards = () => {
    if (gridItemsMax && !_.isEmpty(user)) {
      return (
        <Fragment>        
          <Row>
            {gridItemsMax.map((card) => {
              return (
                <Col key={card.sfid} lg={4} md={6}>
                  <Card card={card} account={account} user={user} />
                </Col>
              );
            })}
          </Row>
          <div style={gridItemsMax.length < 9 ? { display: 'none'} : null}>
            <span
              className="sav-btn__orange--text"
              style={viewMore ? { display: "none" } : null}
              onClick={() => setViewMore(true)}
            >
              View More
            </span>
            <span
              className="sav-btn__orange--text"
              style={!viewMore ? { display: "none" } : null}
              onClick={() => setViewMore(false)}
            >
              View Less
            </span>
          </div>
        </Fragment>
      );
    }
  };

  if (loading) {
    return (
      <Fragment>
        <SectionHeader>Dashboard</SectionHeader>
        <Skeleton count={3} height={225} />
      </Fragment>
      
    );
  }

  return (
    <Fragment>
      <SectionHeader>
        Dashboard
        <div>
          <CustomizeModal grid={gridItems} updateGrid={updateGrid} />
        </div>
      </SectionHeader>
      <Container style={{ textAlign: "right", marginBottom: 40 }}>
        
        {renderCards()}
        
      </Container>
    </Fragment>
  );


}

export default Dashboard;
import React from "react";
import { Row, Col } from "react-bootstrap";
import Ambassador from "../../../../images/AmbassadorBadges/Ambassador.svg";
import Certified from "../../../../images/AmbassadorBadges/Certified.svg";
import Authorized from "../../../../images/AmbassadorBadges/Authorized.svg";
import Platinum from "../../../../images/AmbassadorBadges/Platinum.svg";
import Gold from "../../../../images/AmbassadorBadges/Gold.svg";
import Silver from "../../../../images/AmbassadorBadges/Silver.svg";
import Contractor from "../../../../images/AmbassadorBadges/Contractor.svg";
import Check from "../../../../images/check.svg";

const hidden = {
  display: "none"
};

const SavantListingAddress = ({addr, account, isNDD, idx}) => {

  const renderTier = () => {
    if (addr.findADealerDisplayTier === "Custom" || addr.findADealerDisplayTier === "Certified Reseller"){
      return <img src={Certified} width={80} alt="certified" />
    }
    if (addr.findADealerDisplayTier === "Authorized"){
      return <img src={Authorized} width={80} alt="Authorized" />
    }
    if (addr.findADealerDisplayTier === "Silver"){
      return <img src={Silver} width={80} alt="Silver" />
    }
    if (addr.findADealerDisplayTier === "Platinum"){
      return <img src={Platinum} width={80} alt="Platinum" />
    }
    if (addr.findADealerDisplayTier === "Gold"){
      return <img src={Gold} width={80} alt="Gold" />
    }
    if (addr.findADealerDisplayTier === "Savant Contractor"){
      return <img src={Contractor} width={80} alt="Contractor" />
    }
    return null;
  };

  const renderAmbass = () => {
    if (addr.findADealerAmbassador){
      return <img src={Ambassador} width={80} alt="Ambassador" />
    }
    return null;
  };

  return (
    <Row className="vertical-align" style={idx % 2 === 0 ? {borderBottom: "1px solid rgba(0, 0, 0, 0.1)",backgroundColor: "#fafaf9"} : {borderBottom: "1px solid rgba(0, 0, 0, 0.1)"} }>
      <Col sm={4} className="savFlexBetweenCenter p-t-10 p-b-10">
        {renderAmbass()}
        {renderTier()}
      </Col>
      <Col sm={5} className="p-t-10 p-b-10">
        <div className="med f12 m-b-5">
          {addr.savantListingNameOverride || account.dba}
        </div>
        <div className="book f12 m-b-5">
          {addr.addressStreet}<br />
          {addr.addressCity}, {addr.addressState}
        </div>
        <div className="med f12 m-b-5">
          1.5 mi 
        </div>
      </Col>
      <Col sm={3} className="p-t-10 p-b-10">
        <button className="sav-btn__orange--fill sav-btn__orange--nohover" style={{width: 70, cursor: 'default' }}>Contact</button>
        <div className="book f12 m-t-5" style={addr.findADealerIsHTA ? null : hidden}>
          <img src={Check} alt="check" /> HTA Certified
        </div>
        <div className="book f12 m-t-5" style={addr.findADealerShowroom ? null : hidden}>
          <img src={Check} alt="check" /> Savant Showroom
        </div>
      </Col>
    </Row>
  )
}

export default SavantListingAddress;
import React from "react";
import styled from "styled-components";

const Banner = styled.div`
  font-size: 22px;
  font-family: "Gotham-Medium";
  color: #fff;
  background-color: #3f3f3f;
  padding: ${props => props.padding || "40px 0px 20px 0px"};
  text-transform: uppercase;
  text-align: center;
`;

const SavBanner = ({ text, padding }) => {
  return <Banner padding={padding}>{text}</Banner>;
};

export default SavBanner;

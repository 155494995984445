import React, {Fragment} from "react";
import moment from 'moment';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {parseCurrency, kFormatter} from "../Utilities";

const SalesYearToDate = ({programData, programResult}) => {

  const data = [];

  programData.forEach((p) => {
    const obj = {
      name: `${p.month} '${p.year.toString().substr(-2)}`,
      Sales: p.adjustedRevenue
    }
    const date = new Date();
    const year = date.getFullYear();
    const startDate = moment(`${year}-01-01`).subtract(1, 'months');
    const endDate = moment(`${year}-12-01`).add(1, "months");
    const dataDate = moment(p.periodDate);
    if (dataDate.isBetween(startDate, endDate)){
      data.push(obj);
    }
  })

  const CustomAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-25} y={y+20} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan y={y+4} dx="0.71em" >${kFormatter(payload.value)}</tspan>
      </text>
    )
  }
  const CustomXAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-4} y={y} size="10px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:10}}>
        <tspan x={x} dy="0.71em" >{payload.value}</tspan>
      </text>
    )
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="book f12">
            {payload[0].payload.year} Sales:&nbsp;
          </span>
          <span className="med f12">
            {parseCurrency(payload[0].payload.Sales, 2)}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
        key={Math.random()}
          width={500}
          height={300}
          data={data.splice(0,24)}
          label={{
            fill: "white",
            position: "center",
            zIndex: 9999,
            fontSize: 10
          }}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={CustomXAxisTick} interval={0} />
          <YAxis tick={CustomAxisTick} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="Sales" fill="#3f3f3f" />
        </BarChart>
      </ResponsiveContainer>
  )

}

export default SalesYearToDate;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import SavBanner from "../../components/SavBanner";
import { fetchFeed } from "../../actions";
import _ from "lodash";
import SavDrop from "../../components/SavDrop";
import SavPagination from "../../components/SavPagination";
import SavSearchFunc from "../../components/SavSearch";
import { parseDate } from "../../components/Utilities";
import { SavSearch } from "../../components/Styled";
import { ButtonGroup, Button } from "react-bootstrap";
import { FaList } from "react-icons/fa";
import { FiGrid } from "react-icons/fi";

const NewsHeader = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  padding: 30px;
  font-family: "Gotham-Medium";
`;
const NewsHeaderButtons = styled(ButtonGroup)`
  position:absolute;
  right: 15px;
  top: 27px;
`;

const NewsRow = styled.div`
  display: grid;
  height: 100%;
  align-content:space-between;
  padding: 10px 20px;
`;
const NewsRowGrid = styled.div`
  display: grid;
  align-content:space-between;
  height: 150px;
  padding: 10px;
  background-color:#f9f9f9;
`;
const NewsLink = styled(Link)`
  &:hover {
    text-decoration:none;
    background-color:#fafaf9;
  }
`;
const btnActive = {
  background: "#ff5f00",
  borderColor: "#ff5f00"
};
const btnInactive = {
  background: "#fff",
  borderColor: "#ff5f00"
};

const News = props => {
  const { feed, fetchFeed } = props;

  const [feedFilter, setFeedFilter] = useState("All News");
  const [newsView, setNewsView] = useState("list");

  const [feedItems, setFeedItems] = useState(feed);
  const [feedItemsFiltered, setFeedItemsFiltered] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [pageSize, setPageSize] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  

  const filterOpts = [
    "All News",
    "Beta",
    "Bulletins",
    "Education",
    "Field Impact Notices",
    "Partner News",
    "Products & Pricing",
    "Sales & Marketing",
    "Savant in the News",
  ];

  useEffect(() => {
    document.title = "Savant Community | News"
  },[])

  useEffect(() => {
    if (_.isEmpty(feed)) {
      fetchFeed();
    } else {
      setFeedItems(feed);
    }
  }, [feed]);

  useEffect(() => {
    if (searchString !== "") {
      let tempFeeds = feed.filter(feedItem =>
        SavSearchFunc(feedItem, ["title", "headerText"], searchString)
      );
      setCurrentPage(1);
      setFeedFilter("All News");
      setFeedItems(tempFeeds);
    } else {
      setFeedItems(feed);
    }
  }, [searchString]);

  useEffect(() => {
    if (!_.isEmpty(feed)) {
      if (feedFilter === "All News") {
        setFeedItems(feed);
      } else {
        let feedList = [];
        feed.forEach(f => {
          if (f.type === feedFilter) {
            feedList.push(f);
          }
        });
        setFeedItems(feedList);
      }
    }
  }, [feedFilter]);

  useEffect(() => {
    if (feedItems) {
      let index = 0;
      const tempFeeds = [];
      for (index = 0; index < feedItems.length; index += pageSize) {
        let chunk = feedItems.slice(index, index + pageSize);
        tempFeeds.push(chunk);
      }
      setFeedItemsFiltered(tempFeeds);
    }
  }, [feedItems, pageSize]);

  const createMarkup = richText => {
    return { __html: richText };
  };

  const setGrid = () => {
    setNewsView("grid");
  };
  const setList = () => {
    setNewsView("list");
  };

  const stripHtml = (stringIn) => {
    return stringIn.replace(/(<([^>]+)>)/gi, "");
  }
  const truncateString = (stringIn, num) => {
    return stringIn.slice(0, num) + '...';
  }

  const renderFeedItems = () => {
    if (!_.isEmpty(feedItemsFiltered)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > feedItemsFiltered.length) {
        page = 0;
        setCurrentPage(1);
      }
      if (newsView === "list") {
        return feedItemsFiltered[page].map(f => {
          return (
            <NewsLink to={`/news/${f.sfid}`} key={f.sfid}>
              <Row>
                <Col md={4} className="pr-md-0">
                  <div className="event-img-container news-image">
                    <span
                      className="event-img-zoom"
                      style={{
                        backgroundImage: `url(${f.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        display: "block",
                        height: "200px",
                        maxHeight: "200px",
                      }}
                    />
                    <span className="f12 med wht text-uppercase" style={{position:"absolute", left: "5%", bottom: 10 }}>
                      {f.type}
                    </span>
                  </div>
                </Col>
                <Col md={8} className="pl-md-0">
                  <NewsRow style={{borderBottom: "1px solid #D8D8D8", height: '100%', backgroundColor:"#fafaf9"}}> 
                    <div className="f14 med" style={{ paddingTop: "10px" }}>
                      {f.title}
                    </div>
                    <div
                      className="f12 book"
                    >
                      {truncateString(stripHtml(f.bodyText), 300)}
                    </div>
                    <div className="f10 light-gray book">
                      Posted on {parseDate(f.postDate)}
                    </div>
                  </NewsRow>
                </Col>

                
              </Row>
            </NewsLink>
          );
        });
      }
      if (newsView === "grid"){
        return (
          <Row>
            {
              feedItemsFiltered[page].map((f) => {
                return (
                  
                  <Col xs={12} md={6} lg={4} style={{marginTop:10}} key={f.sfid}>
                    <NewsLink to={`/news/${f.sfid}`}>
                    <div className="event-img-container">
                      <span
                        className="event-img-zoom"
                        style={{
                          backgroundImage: `url(${f.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          display: "block",
                          height: "200px",
                          maxHeight: "200px",
                          borderBottom: "1px solid #b4b4b4"
                        }}
                      />
                      <span className="f12 book wht text-uppercase" style={{position:"absolute", left: "5%", bottom: 10 }}>
                        {f.type}
                      </span>
                    </div>
                    <NewsRowGrid>
                      <div className="f14 med truncate">{f.title}</div>
                      <div
                        className="f12 book"
                      >
                        {truncateString(stripHtml(f.bodyText), 150)}
                      </div>
                      <div
                        className="f10 light-gray book"
                      >
                        Posted on {parseDate(f.postDate)}
                      </div>
                      
                    </NewsRowGrid>
                    </NewsLink>
                  </Col>
                )
              })
            }
            
          </Row>
        )
      }
    }
    return <div className="text-center">No Articles Posted.</div>;
  };

  if(feedItems){
    return (
      <div>
        <SavBanner text="Savant News" padding="40px" />
        <Container style={{padding:'0px'}}>
          <Row>
            <Col xs={12} className="text-center" style={{position:'relative'}}> 
              <NewsHeader>{feedFilter}</NewsHeader>
              <NewsHeaderButtons size="sm">
                <Button
                  onClick={() => setList()}
                  style={newsView === "list" ? btnActive : btnInactive}
                >
                  <FaList
                    style={
                      newsView === "list"
                        ? { fill: "white" }
                        : { fill: "#ff5f00" }
                    }
                  ></FaList>
                </Button>
                <Button
                  onClick={() => setGrid()}
                  style={newsView === "grid" ? btnActive : btnInactive}
                >
                  <FiGrid
                    style={
                      newsView === "grid"
                        ? { fill: "white", stroke: "white" }
                        : { fill: "#ff5f00", stroke: "#ff5f00" }
                    }
                  ></FiGrid>
                </Button>
              </NewsHeaderButtons>
            </Col>
          </Row>
        </Container>
  
        <Container style={{ marginTop: 40 }}>
          <Row>
            <Col xs={6}>
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
            </Col>
            <Col xs={6}>
              <Row style={{ alignItems: "center" }}>
                <Col
                  xs={3}
                  sm={6}
                  style={{ textAlign: "right", paddingRight: 0 }}
                >
                  <span className="light-gray f12 med">FILTER</span>
                </Col>
                <Col xs={9} sm={6}>
                  <SavDrop
                    val={feedFilter}
                    opts={filterOpts}
                    click={setFeedFilter}
                    style={{
                      border: "1px solid #b4b4b4",
                      backgroundColor: "#fff"
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="m-t-40">
          {renderFeedItems()}
          <SavPagination
            data={feedItemsFiltered}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataLength={feedItems.length}
          />
        </Container>
      </div>
    );
  }
  return null;
  
};

const mapStateToProps = state => {
  return {
    feed: state.feed.data
  };
};
export default connect(mapStateToProps, { fetchFeed })(News);

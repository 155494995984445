import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Tab, Nav, Image, Popover, OverlayTrigger } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import moment from "moment";
import {AiOutlineCheck, AiOutlineCloseCircle, AiOutlineWifi} from "react-icons/ai"
import {FiExternalLink} from 'react-icons/fi';
import {FaBriefcase} from 'react-icons/fa';
import {RiAwardFill} from 'react-icons/ri'
import {BsShieldCheck, BsShieldX} from 'react-icons/bs';
import {MdArrowDropDown, MdArrowDropUp, MdUpgrade} from 'react-icons/md';

import SavBanner from "../../components/SavBanner";
import {visibilitySelector, createLoadingSelector} from "../../selectors";
import { fetchHomes, fetchAccount, fetchSubs, fetchHome, fetchHomeHistory, fetchHomeCases, fetchHomeSerial } from "../../actions";
import {SubHeader, SavRow, SavSearch, ZeroStateText, SavLabel, SavLink} from "../../components/Styled";
import {HomeRow, DataContainer, DataCard, DataCardHeader, DataCardFooter, DataCardNumber} from "./styled";
import proHost from "../../images/SCS/pro-host.svg";
import smartHost from "../../images/SCS/smart-host.svg";
import shr2000 from "../../images/SCS/SHR-2000-00.svg";
import director from "../../images/SCS/HST-Director.svg";
import directorLite from "../../images/SCS/HST-DirectorLite.svg";
import sipa from "../../images/SCS/HST-SIPA1SM.svg";
import soundbar from "../../images/SCS/HST-SOUNDBAR.svg";
import essentials from "../../images/SCS/essential-plan.svg";
import expiring from "../../images/SCS/essential-plan-expiring.svg";
import superPro from "../../images/SCS/superPro.svg";
import license from "../../images/SCS/license.svg"
import fake from "./fake.json";
import { parseCurrency } from "../../components/Utilities";



const linkStyle = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#fafaf9",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const linkStyleActive = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#dbd9d6",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12
};
const itemStyle = {
  marginBottom: 8
};
const hidden = {
  display:'none'
}
const Pill = styled.div`
  display: flex;
  height: 40px;
  padding: 13px 16px;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  background: ${props => props.online ? "#f4fcf3" : "#db542A1A" }
`;

const DataCardFlex = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  text-align:center;
`;

const SCSHomes = ({account, fetchAccount, fetchingAccount, homes, fetchHomes, fetchingHomes, subscriptions, fetchSubs, fetchingSubs, fetchHome, fetchHomeHistory, fetchHomeCases, fetchHomeSerial}) => {

  const [activeHome, setActiveHome] = useState(null);
  const [activeHomeData, setActiveHomeData] = useState(null);
  const [activeHomeCases, setActiveHomeCases] = useState([]);
  const [activeHomeSerial, setActiveHomeSerial] = useState(null);
  const [homeList, setHomeList] = useState([]);
  const [homeFilter, setHomeFilter] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [networkSub, setNetworkSub] = useState(null);
  const [essentialsSub, setEssentialsSub] = useState(null)

  console.log(fake.payload)

  useEffect(() => {
    if (_.isEmpty(account) && !fetchingAccount) {
      fetchAccount();
    }
    if (_.isEmpty(homes) && !fetchingHomes && !_.isEmpty(account)) {
      fetchHomes(account.data.scsDomainId);
    }
    console.log('HOMES----',homes);
  }, [account, homes]);

  useEffect(() => {
    if (_.isEmpty(subscriptions) && !fetchingSubs) {
      fetchSubs();
    }
  }, [subscriptions]);

  useEffect(() => {
    if (!_.isEmpty(homes)){
      const temp = [];
      homes.data.payload.forEach((p) => {
        p.homes.forEach((h) => {
          temp.push(h);
        })
      });
      setHomeList(temp);
    }
  },[homes]);

  useEffect(() => {
    if (!_.isEmpty(homeList)){
      let index = 0;
      const temp = [];
      for (index = 0; index < homeList.length; index += pageSize) {
        let chunk = homeList.slice(index, index + pageSize);
        temp.push(chunk);
      }
      setHomeFilter(temp);
    }
  },[homeList]);

  useEffect(() => {
    if (activeHome){
      fetchHome(activeHome.id).then((res) => {
        if (!_.isEmpty(res.payload)){
          console.log(res.payload.payload)
          setActiveHomeData(res.payload.payload)
        }
      });
      fetchHomeHistory(activeHome.uid).then((res) => {
        if (res.success){
          console.log(res.payload);
        }
      });
      fetchHomeSerial(activeHome.uid).then((res) => {
        if (res.success && !_.isEmpty(res.payload)){
          setActiveHomeSerial(JSON.parse(res.payload));
          console.log(JSON.parse(res.payload));
        }
      });

      fetchHomeCases(activeHome.uid).then((res) => {
        if (res.success && !_.isEmpty(res.payload)){
          setActiveHomeCases(JSON.parse(res.payload));
        }
      })

      if (!_.isEmpty(subscriptions)){
        setEssentialsSub(_.find(subscriptions.data, (s) => moment(new Date()).isBetween(s.startDate, s.endDate) && s.isEssentials && s.homeId === activeHome.id));
        setNetworkSub(_.find(subscriptions.data, (s) => moment(new Date()).isBetween(s.startDate, s.endDate) && s.isNetwork && s.homeId === activeHome.id));
      }
    }

  },[activeHome]);

  const handleClickBack =() => {
    setActiveHome(null);
    setActiveHomeData(null);
  }

  const getIcon = (hostType) => {
    if (hostType === 'Pro Host'){
      return proHost
    } else if ( hostType === 'SHR-2000 Host' || hostType === 'S2SM Host'){
      return shr2000
    } else if (hostType === 'HST-DIR Director'){
      return director
    } else if (hostType === 'HST-STUDIO55WS-SUR' || hostType === 'HST-STUDIO'){
      return soundbar
    } else if (hostType === 'Smart Host with Control'){
      return smartHost
    } else if (hostType === 'HST-SIPA1SM'){
      return sipa
    } else if (hostType === 'Super Pro Host'){
      return superPro
    }
    return proHost
  }

  const renderLicenses = () => {
    return activeHomeData.licenses.map((l) => {
      if (l.category !== 'NETWORK' && l.category !=='ESSENTIALS'){
        return (
          <HomeRow style={{borderTop: '1px solid #e9ecef', minHeight: 75}} key={l.id}>
            <Col xs={1}>
              <RiAwardFill size={25} />
            </Col>
            <Col sm={5}>
              <div className="med f12">
                {l.description}
              </div>
              <div className="book f12">
                {l.name}
              </div>
              
            </Col>
            <Col sm={4}>
              {l.code}
            </Col>
            <Col sm={2}>
              {moment(l.activated).format('MM-DD-YYYY')}
            </Col>
          </HomeRow>
        )
      }
    })
  }

  const renderSubCard = () => {
    return (
      <DataCard style={{minHeight: 180}}> 
        <DataCardHeader>
          Subscription Status
        </DataCardHeader>
        <DataCardFlex>
          {essentialsSub ? <Image src={essentials} height="25px" /> : <Image src={expiring} height={30} width={20} />}
          <div className="book f16 m-l-10">
            Savant Essentials {essentialsSub ? 'Active' : 'Inactive'}
          </div>
        </DataCardFlex>
        <DataCardFlex>
          {networkSub ? <AiOutlineWifi size={20} fill="#4cd964" /> : <Image src={expiring} height={30} width={25} />}
          <div className="book f16 m-l-10">
            Smart Network {networkSub ? 'Active' : 'Inactive'}
          </div>
        </DataCardFlex>
        <div className="book text-center f12 light-gray">
          Expires
        </div>
        <DataCardFooter style={{paddingTop: 0}}>
          {essentialsSub ? moment(essentialsSub.endDate).format('MM-DD-YYYY') : networkSub ? moment(networkSub.endDate).format('MM-DD-YYYY') : 'N/A'}
        </DataCardFooter>
      </DataCard>
    )
  };
  const renderWarrantyInfo = () => {
    if (!_.isEmpty(activeHomeSerial)){
      return (
        <DataCard style={{minHeight: 180}}>
          <DataCardHeader>Warranty</DataCardHeader>
          <DataCardNumber>
            {
              activeHomeSerial.Customer_Warranty_Status__c === 'Expired' ?
              <BsShieldX size={60} /> :
              <BsShieldCheck size={60} style={{fill: '#4cd964'}} />

            }
          </DataCardNumber>
          <div className="book text-center f12 light-gray">
            Expires
          </div>
          <DataCardFooter style={{paddingTop: 0}}>
            {moment(activeHomeSerial.Customer_Warranty_Expiration_Date__c).format("MM-DD-YYYY")}
          </DataCardFooter>
        </DataCard>
      )
    }
    return null;
  };
  const renderCases = () => {
    if (!_.isEmpty(activeHomeCases) && activeHome){
      return activeHomeCases.map((c) => {
        return (
          <HomeRow style={{borderTop: '1px solid #e9ecef', minHeight: 75}} key={c.Id}>
            <Col xs={1}>
              <FaBriefcase size={20} />
            </Col>
            <Col sm={3}>
              {c.CaseNumber}
            </Col>
            <Col sm={4}>
              {c.Subject}
            </Col>
            <Col sm={4}>
              {c.Status}
            </Col>
          </HomeRow>
        )
      })
    }
    return null;
  };

  const renderComponents = () => {
    const temp = _.filter(fake.payload.components, (d) => d.manufacturer === 'Savant');


    const devices = [];
    temp.forEach((t) => {
      const obj = {...t};
      obj.needsUpgrade = false;
      obj.upgradeModel = null;
      obj.upgradePrice = 0;
      if (obj.model === "SLN-84BT"){
        obj.needsUpgrade = true;
        obj.upgradeModel = 'SLN-44VLC4K';
        obj.upgradePrice = 1500.00
      }
      if (obj.model === "SUR1000"){
        obj.needsUpgrade = true;
        obj.upgradeModel = "REM-4000"
        obj.upgradePrice = 250.00
      }
      devices.push(obj);

    })

    return (
      <DataCard>
        <DataCardHeader>Savant Devices</DataCardHeader>
        <HomeRow style={{paddingBottom: 0, borderBottom: '1px solid #e9ecef'}}>
          <Col xs={4}>
            Name
            <MdArrowDropDown
              style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
            <MdArrowDropUp
              style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
          </Col>
          <Col xs={3}>
            Model
            <MdArrowDropDown
              style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
            <MdArrowDropUp
              style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
          </Col>
          <Col xs={3}>
            Upgradeable
            <MdArrowDropDown
              style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
            <MdArrowDropUp
              style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
          </Col>
          <Col xs={2}>
            Cost
            <MdArrowDropDown
              style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
            <MdArrowDropUp
              style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
              size="2em"
            />
          </Col>
        </HomeRow>
        {devices.map((d, idx) => {
          return (
            <HomeRow>
              <Col xs={4}>
                {d.name}
              </Col>
              <Col xs={3}>
                {d.model}
              </Col>
              {/* <Col xs={2}>
                {d.deviceType}
              </Col> */}
              <Col xs={3}>
                {d.needsUpgrade ? <MdUpgrade size={20} style={{fill: '#ff5f00', marginRight: 5}} /> : <AiOutlineCheck size={20} style={{fill: '#4cd964', marginRight: 5}} />}
                {d.needsUpgrade ? <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={
                    <Popover id={`popover-${idx}`} className="sav-pop">
                      <Popover.Title
                        as="div"
                        className="f12 med bg-white text-center"
                      >
                        {d.upgradeModel}
                      </Popover.Title>
                      <Popover.Content>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 12
                          }}
                        >
                          <img src={d.upgradeModel === "REM-4000" ? "https://sweb-img.s3.amazonaws.com/ecom_Images/Pro_Remote_X2_3D_InBase_1.png" : "https://sweb-img.s3.amazonaws.com/ecom_Images/SLN-88VLC4K_rightangle.png"} width={100} />
                          <br />
                          {d.upgradeModel === "REM-4000" ? "SAVANT PRO REMOTE X2" : "4K HDR HDBASET MATRIX SWITCHER 8 X 8"}
                          <br />
                          MSRP: <b>$1500.00</b><br />
                          Upgrade Cost: <b>$300.00</b>
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <SavLink 
                  href={`https://store.savant.com/DefaultStore/ccrz__ProductDetails?viewState=DetailView&cartID=&sku=REM-4000SG-00&portalUser=&store=&cclcl=en_US`}
                  target="_blank"
                  >
                    Upgrade Available
                  </SavLink>
                </OverlayTrigger> : <span className="book f12">Up to date</span>}

                
              </Col>
              <Col xs={2}>
                {d.needsUpgrade ? parseCurrency(d.upgradePrice) : '--'}
              </Col>
            </HomeRow>
          )
        })}
      </DataCard>
    )
  }

  const renderActiveHome = () => {
    if (activeHomeData && activeHome){
      const updated = activeHomeData.softwareInfo.created
      return (
        <Fragment>
          <Row>
            <Col xs={12}>
              <div className="savFlexBetweenCenter">
                <button className="sav-btn__orange" onClick={() => handleClickBack()}>Back</button>
                <a href={`https://cdev2-rms-v2.savantcs.com/${account.data.scsDomainId}/home/${activeHomeData.id}/status`} target="_blank" className="med oj u f14" rel="noreferrer">
                  <button className="sav-btn__orange">View in SCM <FiExternalLink size={12} style={{stroke:'#ff5f00', marginLeft: 5}} /></button>
                </a>
              </div>
            </Col>
          </Row>

          <Row className="m-t-20">
            <Col xs={12}>
              <DataCard>
                <DataCardHeader>
                  <div className="savFlexBetweenCenter">
                    <div className="savFlexStart f32">
                      <Image style={{marginRight: 20}} src={getIcon(activeHomeData.hostInfo.hostProfileModel)} height="40px" width="75px" />
                      {activeHomeData.name}
                    </div>
                    <Pill online={activeHome.online}>
                      {activeHome.online ? <AiOutlineCheck size={16} style={{fill: '#4cd964', marginRight: 10}} /> : <AiOutlineCloseCircle size={16} style={{fill: '#d0021b', marginRight: 10}} />}
                      {activeHomeData.online ? "Home Online" : "Home Offline"}
                    </Pill>
                  </div>
                </DataCardHeader>
                <Container style={{padding: 0}}>
                  <Row>
                    <Col md={2}>
                      <div className="book f12 light-gray">UID</div>
                      <div className="med f14">{activeHomeData.uid}</div>
                    </Col>
                    <Col md={2}>
                      <div className="book f12 light-gray">Host</div>
                      <div className="med f14">{activeHomeData.hostInfo.hostProfileModel}</div>
                    </Col>
                    <Col md={2}>
                      <div className="book f12 light-gray">Host OS</div>
                      <div className="med f12">{activeHomeData.hostInfo.OSVersion}</div>
                    </Col>
                    <Col md={2}>
                      <div className="book f12 light-gray">da Vinci Version</div>
                      <div className="med f14">{activeHomeData.softwareInfo.version.name}</div>
                    </Col>
                    <Col md={2}>
                      <div className="book f12 light-gray">Last Update</div>
                      <div className="med f14">{moment(activeHomeData.lastConfig).format('MM-DD-YYYY')}</div>
                    </Col>
                    <Col md={2}>
                      <div className="book f12 light-gray">Last Contact</div>
                      <div className="med f14">{moment(activeHomeData.lastContact).format('MM-DD-YYYY')}</div>
                    </Col>
                  </Row>
                </Container>
              </DataCard>
            </Col>
          </Row>

          <Row className="m-t-10 m-b-10">
            <Col md={4}>
              {renderSubCard()}
            </Col>
            {/* <Col md={4}>
              <DataCard style={{minHeight: 180}}>
                <DataCardHeader>
                  Home Users
                </DataCardHeader>
                <DataCardFlex style={{justifyContent: 'space-around', paddingTop: 15}}>
                  <div>
                    Admins
                    <DataCardNumber>
                      {activeHomeData.adminCount}
                    </DataCardNumber>
                  </div>
                  <div>
                    Total
                    <DataCardNumber>
                      {activeHomeData.userCount}
                    </DataCardNumber>
                  </div>
                </DataCardFlex>
                
              </DataCard>
            </Col> */}
            <Col md={4}>
              <DataCard style={{minHeight: 180}}>
                <DataCardHeader>
                  Subscription Sales
                </DataCardHeader>
                <DataCardNumber className="green">
                  $150.00
                </DataCardNumber>
                <DataCardFooter>
                  This Home
                </DataCardFooter>
                

                
              </DataCard>
            </Col>
            <Col md={4}>
              {renderWarrantyInfo()}
            </Col>
            {/* <Col md={4}>
              <DataCard style={{height: 123}}>
                <DataCardHeader>
                  System Information
                </DataCardHeader>
                <div className="book f18 text-center m-t-10">
                <a href={`https://cdev2-rms-v2.savantcs.com/${account.data.scsDomainId}/home/${activeHomeData.id}/status`} target="_blank" className="med oj u f14" rel="noreferrer">
                  <button className="sav-btn__orange--text" style={{fontSize: 14}}>View in SCM <FiExternalLink size={18} style={{stroke:'#ff5f00', marginLeft: 5}} /></button>
                </a>

                </div>
              </DataCard>
            </Col> */}
          </Row>

          <DataCard style={{paddingLeft: 0, paddingRight: 0}}>
            <DataCardHeader>Cases</DataCardHeader>
            <HomeRow style={{paddingBottom: 0}}>
              <Col xs={4}>
                Case Number
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
              <Col xs={4}>
                Subject
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
              <Col xs={4}>
                Status
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
            </HomeRow>
            {renderCases()}
          </DataCard>

          {renderComponents()}

          <DataCard style={{paddingLeft: 0, paddingRight: 0}}>
            <DataCardHeader>Licenses</DataCardHeader>
            <HomeRow style={{paddingBottom: 0}}>
              <Col xs={6}>
                Name
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
              <Col xs={4}>
                Code
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
              <Col xs={2}>
                Activated
                <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
                />
                <MdArrowDropUp
                  style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                  size="2em"
                />
              </Col>
            </HomeRow>
            {renderLicenses()}
          </DataCard>

          
          

        </Fragment>
      )
    }
    return null;
  }

  const renderHomes = () => {
    if (!activeHome && !_.isEmpty(homeFilter)){
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > homeFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return homeFilter[page].map((home) => {
        return (
          <HomeRow style={{borderTop: '1px solid #e9ecef', minHeight: 75}} key={home.id}>
            <Col xs={1} className="d-none d-md-block">
              <Image src={getIcon(home.hostInfo.hostProfileModel)} height="30px" width="100%" />
            </Col>
            <Col xs={4} onClick={() => setActiveHome(home)}>
              {home.name}
            </Col>
            <Col xs={2}>
              {home.uid}
            </Col>
            <Col xs={2}>
              {home.softwareInfo.version.name}
            </Col>
            <Col xs={2}>
              LICENSES
            </Col>
            <Col xs={1}>
            <a href={`https://cdev2-rms-v2.savantcs.com/${account.data.scsDomainId}/home/${home.id}/status`} target="_blank" className="med oj u f14" rel="noreferrer">
              <button className="sav-btn__orange--text" style={{width: '100%'}}>SCM</button>
              </a>
            </Col>
          </HomeRow>
        )
      })
    }
    return null;
  };

  
  


  return (
    <div style={{backgroundColor: '#f8f8f8', minHeight: 1100, paddingBottom: 200}}>
      <SavBanner text="HOMES" padding="40px" />
      <Container className="m-t-40">
        {renderHomes()}
        {renderActiveHome()}

      </Container>
      
    </div>
  );
};

const fetchingAccount = createLoadingSelector(["FETCH_ACCOUNT"]);
const fetchingHomes = createLoadingSelector(["FETCH_HOMES"]);
const fetchingSubs = createLoadingSelector(["FETCH_SUBS"]);

const mapStateToProps = state => {
  return {
    account: state.account,
    homes: state.homes,
    subscriptions: state.subscriptions,
    fetchingAccount: fetchingAccount(state),
    fetchingHomes: fetchingHomes(state),
    fetchingSubs: fetchingSubs(state),
  };
};
export default connect(mapStateToProps, {fetchAccount, fetchHomes, fetchSubs, fetchHome, fetchHomeHistory, fetchHomeCases, fetchHomeSerial})(SCSHomes);

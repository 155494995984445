import React from "react";
import { Row, Col } from "react-bootstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { SubHeader} from "../../../components/Styled";
import {parseCurrency, kFormatter} from "../../../components/Utilities";
import {DataCard, DataCardHeader} from "./styles"
const hidden = {
  display: 'none'
}
const SubscriptionQuarters = ({
  account, virQ1, virQ1Data, virQ1Network, virQ2, virQ2Data, virQ2Network, virQ3, virQ3Data, virQ3Network, virQ4, virQ4Data, virQ4Network
}) => {

  const today = new Date();
  const year = today.getFullYear();

  const CustomAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-25} y={y+20} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan y={y+4} dx="0.71em" >${kFormatter(payload.value)}</tspan>
      </text>
    )
  }
  const CustomXAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x-4} y={y} size="10px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:10}}>
        <tspan x={x} dy="0.71em" >{payload.value}</tspan>
      </text>
    )
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <div>
            <span className="book f12">
              Essentials Rebate:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.virEssentials, 2)}
            </span>
          </div>
          <div>
            <span className="book f12">
              Network Rebate:&nbsp;
            </span>
            <span className="med f12">
              {parseCurrency(payload[0].payload.virNetwork, 2)}
            </span>
          </div>
        </div>
      );
    }
    return null;
  };
  

  return (
    <Row className="m-b-20" style={account.type === 'Non-Direct' ? hidden : null}>
      <Col xs={12}>
        <SubHeader>{year} Quarterly Subscription Rebates</SubHeader>
      </Col>

      <Col md={6} lg={3}>
        <DataCard>
          <DataCardHeader>
            Q1
          </DataCardHeader>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
            key={Math.random()}
              width={500}
              height={300}
              data={virQ1Data}
              label={{
                fill: "white",
                position: "center",
                zIndex: 9999,
                fontSize: 10
              }}
              margin={{
                top: 0,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={CustomXAxisTick} interval={0} />
              <YAxis tick={CustomAxisTick} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="virEssentials" stackId="a" fill="#3f3f3f" />
              <Bar dataKey="virNetwork" stackId="a" fill="#00C7CC" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{padding:'0px 15px 10px' }}>
            <span className="f12 light-gray">Subscription Sales: </span><span className="med f12">{parseCurrency(virQ1.revenue + virQ1Network.revenue)}</span>
            <br />
            <span className="f12 light-gray">Rebate Percentage: </span><span className="med f12">{virQ1.virPercentEarned}%</span>
            <br />
            <span className="f12 light-gray">Subscription Rebate: </span><span className="med f12">{parseCurrency(virQ1.virEarned + virQ1Network.virEarned)}</span>
            <br />
            
            <span className="f12 light-gray">Est. Payout: </span><span className="med f12">April 1</span>
          </div>
        </DataCard>
      </Col>

      <Col md={6} lg={3}>
        <DataCard>
          <DataCardHeader>
            Q2
          </DataCardHeader>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
            key={Math.random()}
              width={500}
              height={300}
              data={virQ2Data}
              label={{
                fill: "white",
                position: "center",
                zIndex: 9999,
                fontSize: 10
              }}
              margin={{
                top: 0,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={CustomXAxisTick} interval={0} />
              <YAxis tick={CustomAxisTick} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="virEssentials" stackId="b" fill="#3f3f3f" />
              <Bar dataKey="virNetwork" stackId="b" fill="#00C7CC" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{padding:'0px 15px 10px' }}>
            <span className="f12 light-gray">Subscription Sales: </span><span className="med f12">{virQ2 && virQ2Network ? parseCurrency(virQ2.revenue + virQ2Network.revenue) : "$0"}</span>
            <br />
            <span className="f12 light-gray">Rebate Percentage: </span><span className="med f12">{virQ2 ? virQ2.virPercentEarned : "---"}%</span>
            <br />
            <span className="f12 light-gray">Subscription Rebate: </span><span className="med f12">{virQ2 && virQ2Network ? parseCurrency(virQ2.virEarned + virQ2Network.virEarned) : "$0"}</span>
            <br />
            <span className="f12 light-gray">Est. Payout: </span><span className="med f12">Jul 1</span>
          </div>
        </DataCard>
      </Col>

      <Col md={6} lg={3}>
        <DataCard>
          <DataCardHeader>
            Q3
          </DataCardHeader>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
            key={Math.random()}
              width={500}
              height={300}
              data={virQ3Data}
              label={{
                fill: "white",
                position: "center",
                zIndex: 9999,
                fontSize: 10
              }}
              margin={{
                top: 0,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={CustomXAxisTick} interval={0} />
              <YAxis tick={CustomAxisTick} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="virEssentials" stackId="c" fill="#3f3f3f" />
              <Bar dataKey="virNetwork" stackId="c" fill="#00C7CC" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{padding:'0px 15px 10px' }}>
            <span className="f12 light-gray">Subscription Sales: </span><span className="med f12">{parseCurrency(virQ3.revenue + virQ3Network.revenue)}</span>
            <br />
            <span className="f12 light-gray">Rebate Percentage: </span><span className="med f12">{virQ3.virPercentEarned}%</span>
            <br />
            <span className="f12 light-gray">Subscription Rebate: </span><span className="med f12">{parseCurrency(virQ3.virEarned + virQ3Network.virEarned)}</span>
            <br />
            <span className="f12 light-gray">Est. Payout: </span><span className="med f12">Oct 1</span>
          </div>
        </DataCard>
      </Col>

      <Col md={6} lg={3}>
        <DataCard>
          <DataCardHeader>
            Q4
          </DataCardHeader>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
            key={Math.random()}
              width={500}
              height={300}
              data={virQ4Data}
              label={{
                fill: "white",
                position: "center",
                zIndex: 9999,
                fontSize: 10
              }}
              margin={{
                top: 0,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={CustomXAxisTick} interval={0} />
              <YAxis tick={CustomAxisTick} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="virEssentials" stackId="d" fill="#3f3f3f" />
              <Bar dataKey="virNetwork" stackId="d" fill="#00C7CC" />
            </BarChart>
          </ResponsiveContainer>
          <div style={{padding:'0px 15px 10px' }}>
            <span className="f12 light-gray">Subscription Sales: </span><span className="med f12">{parseCurrency(virQ4.revenue + virQ4Network.revenue)}</span>
            <br />
            <span className="f12 light-gray">Rebate Percentage: </span><span className="med f12">{virQ4.virPercentEarned}%</span>
            <br />
            <span className="f12 light-gray">Subscription Rebate: </span><span className="med f12">{parseCurrency(virQ4.virEarned + virQ4Network.virEarned)}</span>
            <br />
            <span className="f12 light-gray">Est. Payout: </span><span className="med f12">Jan 1</span>
          </div>
        </DataCard>
      </Col>
    </Row>
  )

}

export default SubscriptionQuarters;
import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button
} from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import { save } from 'save-file'
import { fetchEvents } from "../../actions";
import { FaList, FaCalendarAlt } from "react-icons/fa";
import { FiGrid } from "react-icons/fi";
import ReactGA from 'react-ga'

const EventContainer = styled(Container)`
  margin-top: 40px;
  font-size: 12px;
  font-family: "Gotham-Book";
`;
const GridContainer = styled(Container)`
  font-size: 12px;
  font-family: "Gotham-Book";
`;
const EventRow = styled(Row)`
  margin-bottom: 20px;
  &:hover {
    background-color: #fafaf9;
  }
`;
const EventDay = styled.div`
  font-size: 36px;
  font-family: "Gotham-Medium";
  text-align: center;
  color: #fff;
`;
const EventMonth = styled.div`
  font-size: 20px;
  font-family: "Gotham-Medium";
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;
const EventLink = styled.a`
  &:hover {
    text-decoration: none;
    background-color: #fafaf9;
  }
`;
const infoStyle = {
  padding: 8,
  borderRight: ".5px solid #f3f3f3",
  textAlign: "center"
};
const btnActive = {
  background: "#ff5f00",
  borderColor: "#ff5f00"
};
const btnInactive = {
  background: "#fff",
  borderColor: "#ff5f00"
};
const hidden = {
  display: 'none'
}
const schedulerStyle = { height: 56, alignItems:'center', display: 'flex', justifyContent:'center'};
const eventStyle = { height: 90, alignItems:'center', display: 'flex', justifyContent:'center' };

const ics = require("ics");

const Events = props => {
  const { events, fetchEvents, user } = props;
  const [eventsFilter, setEventsFilter] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [eventView, setEventView] = useState("list");
  const [startDateDefault, setStartDateDefault] = useState(new Date());
  const endDateDefault = new Date(2022, 1, 1);
  const [bannerText, setBannerText] = useState("Upcoming");
  const history = useHistory();

  const refURL = localStorage.getItem("ref");

  useEffect(() => {
    document.title = "Savant Community | Events"
  },[])

  useEffect(() => {
    if (_.isEmpty(events)) {
      fetchEvents();
    } else {
      setEventsFilter(events);
    }
  }, [events]);

  const handleClickMonth = useCallback(
    sfid => history.push(`/events/${sfid}`),
    [history]
  );
  const parseDate = date => {
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleString();
  };
  const parseDateNoTime = date => {
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleDateString();
  };
  const getMonth = date => {
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleString(
      "default",
      { month: "short" }
    );
  };
  const getDay = date => {
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleString(
      "default",
      { day: "2-digit" }
    );
  };
  const exportIcal = eventIn => {
    const startDate = new Date(JSON.parse(JSON.stringify(eventIn.startDate)));
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();
    const hour = startDate.getHours();
    const minute = startDate.getMinutes();
    let file;
    const event = {
      start: [year, month, day, hour, minute],
      duration: { hours: 6, minutes: 30 },
      title: eventIn.title,
      description: "",
      location: "",
      url: "",
      geo: "",
      status: "CONFIRMED",
      busyStatus: "BUSY"
    };
    ics.createEvent(event, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      save(value, `${__dirname}/event.ics`);
    });
  };
  const getEventAtDate = date => {
    let bool = false;
    
    events.forEach(e => {
      if (parseDateNoTime(e.startDate) === date.toLocaleDateString()) {
        bool = true;
      }
    });
    return bool;
  };
  const getEventTitle = date => {
    let title = "";
    events.forEach(e => {
      if (parseDateNoTime(e.startDate) === date.toLocaleDateString()) {
        title = e.title;
      }
    });
    return title;
  };
  const getEventId = date => {
    let sfid = "";
    events.forEach(e => {
      if (parseDateNoTime(e.startDate) === date.toLocaleDateString()) {
        sfid = e.sfid;
      }
    });
    return sfid;
  };
  const getEventType = date => {
    let type = "";
    events.forEach(e => {
      if (parseDateNoTime(e.startDate) === date.toLocaleDateString()) {
        type = e.type;
      }
    });
    return type;
  };
  const getEventImg = date => {
    let img = "";
    events.forEach(e => {
      if (parseDateNoTime(e.startDate) === date.toLocaleDateString()) {
        img = e.img;
      }
    });
    return img;
  };
  const handleClickNew = () => {
    ReactGA.event({
      category: 'Events',
      action: 'New Appointment Click'
    });
  }

  const renderEventList = () => {
    if (!_.isEmpty(events) && eventView === "list") {
      return (
        <EventContainer>
          {events.map(e => {
            return (
                <EventRow key={e.sfid}>
                  <Col
                    md={2}
                    style={{
                      padding: "20px 0px",
                      backgroundColor: "#3f3f3f",
                      color: "#fff",
                      height: 124
                    }}
                  >
                    <EventLink
                      href={
                        e.type === "Scheduler Event"
                          ? `${refURL}/Customers/apex/AppointmentDetail?svcApptId=${e.sfid}`
                          : `/events/${e.sfid}`
                      }
                      
                    >
                      <EventDay>{getDay(e.startDate)}</EventDay>
                      <EventMonth>{getMonth(e.startDate)}</EventMonth>
                    </EventLink>
                  </Col>

                  <Col md={4} style={{ padding: 0 }}>
                    <EventLink
                      href={
                        e.type === "Scheduler Event"
                          ? `${refURL}/Customers/apex/AppointmentDetail?svcApptId=${e.sfid}`
                          : `/events/${e.sfid}`
                      }
                      key={e.sfid}
                    >
                      <div className="event-img-container" style={{height:124}}>
                        <span
                          // className="event-img-zoom"
                          style={{
                            backgroundImage: `url(${e.img})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            display: "block",
                            height: "124px",
                            maxHeight: "124px"
                          }}
                        />
                      </div>
                    </EventLink>
                  </Col>
                  <Col md={6} style={{ border: ".5px solid #f3f3f3", height: 124 }}>

                    <Row style={{ borderBottom: ".5px solid #f3f3f3" }}>
                      <Col sm={4} style={infoStyle} className="truncate">
                        {e.localStartTime}
                      </Col>
                      <Col sm={4} className="green pad8" style={infoStyle}>
                        {e.locationType}
                      </Col>
                      <Col sm={4} style={infoStyle}>
                        <span
                          className="oj onHov pad8"
                          onClick={() => exportIcal(e)}
                        >
                          + iCal
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} style={e.type === 'Scheduler Event' ? schedulerStyle : eventStyle} className="truncate">
                        <EventLink
                          href={
                            e.type === "Scheduler Event"
                              ? `${refURL}/Customers/apex/AppointmentDetail?svcApptId=${e.sfid}`
                              : `/events/${e.sfid}`
                          }
                          key={e.sfid}
                        >
                          <div>
                            <div className="text-center med f16" >
                              {e.title}
                            </div>
                            <div
                              className="text-center book f12"
                              style={
                                e.type !== "Scheduler Event"
                                  ? { display: "none" }
                                  : null
                              }
                            >
                              {e.appointmentAttendee.length} Attendee(s)
                            </div>
                          </div>
                        </EventLink>
                      </Col>
                    </Row>

                    <Row style={e.type === 'Scheduler Event' ? { borderTop: ".5px solid #f3f3f3" } : hidden}>
                      <Col sm={6} style={infoStyle} className="truncate">
                        Status: <span className="med">{e.status}</span>
                      </Col>
                      <Col sm={6} className="pad8" style={infoStyle}>
                        Host: <span className="med">{e.tourHost || "Pending"}</span>
                      </Col>
                    </Row>

                  </Col>
                </EventRow>
            );
          })}
        </EventContainer>
      );
    }
    return null;
  };

  const renderEventGrid = () => {
    if (!_.isEmpty(events) && eventView === "grid") {
      return (
        <EventContainer>
          <EventRow>
            {events.map(e => {
              return (
                <Col xs={12} md={6} lg={4} className="onHov" key={e.sfid}>
                  <EventLink
                    href={
                      e.type === "Scheduler Event"
                        ? `${refURL}/Customers/apex/AppointmentDetail?svcApptId=${e.sfid}`
                        : `/events/${e.sfid}`
                    }
                  >
                    <GridContainer>
                      <div className="event-img-container" style={{height: 200}}>
                        <span
                          className="event-img-zoom"
                          style={{
                            backgroundImage: `url(${e.img})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            display: "block",
                            height: "200px",
                            maxHeight: "200px"
                          }}
                        />
                      </div>
                      <div className="text-center">
                        <div className="pad4 med f16 text-center">
                          {e.title}
                        </div>
                      </div>
                      <div className="pad4 book f12 text-center">
                      {e.localStartTime}
                      </div>
                    </GridContainer>
                  </EventLink>
                </Col>
              );
            })}
          </EventRow>
        </EventContainer>
      );
    }
    return null;
  };

  const renderEventCal = () => {
    if (!_.isEmpty(events) && eventView === "cal") {
      return (
        <EventContainer>
          <Calendar
            className="event-cal"
            minDetail="month"
            value={startDateDefault}
            showNeighboringMonth={false}
            onActiveStartDateChange={({ activeStartDate, view }) => {
              setBannerText(
                activeStartDate.toLocaleString("default", { month: "long" })
              );
              setStartDateDefault(activeStartDate);
            }}
            // onChange={(value, event) => handleClickMonth(getEventId(value))}
            tileContent={({ activeStartDate, date, view }) =>
              view === "month" && getEventAtDate(date) ? (
                <div className="f10 med">
                  <EventLink
                    href={
                      getEventType(date) === "Scheduler Event"
                        ? `${refURL}/Customers/apex/AppointmentDetail?svcApptId=${getEventId(
                            date
                          )}`
                        : `/events/${getEventId(date)}`
                    }
                  >
                    {getEventTitle(date)}
                  </EventLink>
                </div>
              ) : // <div className="f10">{getEventTitle(date)}</div>
              null
            }
          />
        </EventContainer>
      );
    }
  };

  const setCal = () => {
    setEventView("cal");
    setBannerText(
      startDateDefault.toLocaleString("default", { month: "long" })
    );
  };
  const setGrid = () => {
    setEventView("grid");
    setBannerText("Upcoming");
  };
  const setList = () => {
    setEventView("list");
    setBannerText("Upcoming");
  };

  if (!_.isEmpty(user)){
    let isElectrical = false;
    if (
      (user.contact.accType === 'Non-Direct' && user.contact.accSubType === "Electrical Contractor") || 
      (user.contact.accType === 'Contractor' && user.contact.accSubType === "Electrical/Solar")
      ){
        isElectrical = true;
    }
    return (
      <div>
        <SavBanner text={`${bannerText} Events`} padding="40px" />
        <Container style={{ marginTop: 40 }}>
          <Row>
            <Col xs={6}>
              <a href={`${refURL}/Customers/AppointmentScheduling`} onClick={() => handleClickNew()} style={isElectrical ? hidden : null}>
                <button className="sav-btn__orange--fill">
                  Schedule a new Appointment
                </button>
              </a>
            </Col>
            <Col xs={6} className="text-right">
              <ButtonGroup>
                <Button
                  onClick={() => setList()}
                  style={eventView === "list" ? btnActive : btnInactive}
                >
                  <FaList
                    style={
                      eventView === "list"
                        ? { fill: "white" }
                        : { fill: "#ff5f00" }
                    }
                  />
                </Button>
                <Button
                  onClick={() => setGrid()}
                  style={eventView === "grid" ? btnActive : btnInactive}
                >
                  <FiGrid
                    style={
                      eventView === "grid"
                        ? { fill: "white", stroke: "white" }
                        : { fill: "#ff5f00", stroke: "#ff5f00" }
                    }
                  />
                </Button>
                <Button
                  onClick={() => setCal()}
                  style={eventView === "cal" ? btnActive : btnInactive}
                >
                  <FaCalendarAlt
                    style={
                      eventView === "cal"
                        ? { fill: "white" }
                        : { fill: "#ff5f00" }
                    }
                  />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Container>
        {renderEventList()}
        {renderEventGrid()}
        {renderEventCal()}
  
        {/* <EventContainer>
          {renderEventList()}
          {renderEventGrid()}
          {renderEventCal()}
        </EventContainer> */}
      </div>
    );
  }
  return null;

  
};

const mapStateToProps = state => {
  return {
    events: state.events.data,
    user: state.user.data
  };
};
export default connect(mapStateToProps, { fetchEvents })(Events);

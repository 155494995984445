import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Collapse, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import {AiFillWarning} from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { updateContact, setSuccessToast, fetchUser } from "../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import SavCheck from "../../components/SavCheck";
import { FaCaretDown } from "react-icons/fa";
import { SavInput, SavLabel } from "../../components/Styled";
import SavInputPhone from "../../components/SavInputPhone";
import { createLoadingSelector } from "../../selectors";
import { phoneOutput } from "../../components/Utilities";
import timezones from "../../components/Utilities/timezones.json";

const DetailContainer = styled(Container)``;

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  color: #565551;
`;

const SettingRow = styled(Row)`
  background-color: #fafaf9;
  padding: 15px;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items:center;
`;

const EditSpan = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #ff5f00;
  cursor: pointer;
`;
const PermList = styled.ul`
  margin-bottom:0px;
  padding-left:15px;
`;
const borderOpen = {
  borderLeft: "4px solid #ff5f00",
};
const cancelStyle = {
  width: '100%',
  border: '1px solid #696057',
  marginTop: 5,
}
const inputStyle = {
  marginTop:5,
}
const hidden = {
  display: 'none'
}
const SavDropToggle = styled.div`
  background-color: #fff;
  width: ${(props) => (props.style ? props.style.width : "100%")};
  height: ${(props) => (props.style ? props.style.height : "auto")}
  border:  1px solid #979797;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height:28px;
  padding:10px;
`;

const ContactDetails = props => {
  const { user, updateContact, loading, fetchUser, errors } = props;
  const [openName, setOpenName] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openAltEmail, setOpenAltEmail] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [openTimezone, setOpenTimezone] = useState(false);

  const [contactFirstName, setContactFirstName] = useState(user.contact.firstName);
  const [contactLastName, setContactLastName] = useState(user.contact.lastName);
  const [contactTitle, setContactTitle] = useState(user.contact.title || "");
  const [contactMobilePhone, setContactMobilePhone] = useState(user.contact.mobile || "");
  const [contactEmail, setContactEmail] = useState(user.contact.email || "");
  const [contactAlternateEmail, setContactAlternateEmail] = useState("");
  const [contactTimezone, setContactTimezone] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [isNDD, setIsNDD] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (user.contact.accType === 'Non-Direct'){
        setIsNDD(true);
      }
      if (!_.isEmpty(user.contact.priorEmail)){
        setIsPending(true);
      } else {
        setIsPending(false);
      }
    }
    
  },[user]);

  useEffect(() => {
    if (!openName && !loading){
      setContactFirstName(user.contact.firstName);
      setContactLastName(user.contact.lastName);
      setContactTitle(user.contact.title);
    }
    if (!openEmail && !loading){
      setContactEmail(user.contact.email);
    }
    if (!openAltEmail && !loading){
      setContactAlternateEmail(user.contact.altEmail || "");
    }
    if (!openMobile && !loading){
      setContactMobilePhone(user.contact.mobile);
    }
    if (!openTimezone && !loading){
      setContactTimezone(user.contact.timezone || '');
    }
  },[openName, openEmail, openAltEmail, openMobile, openTimezone]);


  const renderTimeLabel = (value) => {
    const labelFound = _.find(timezones, (t) => t.value === value );
    if (labelFound){
      return labelFound.label;
    }
    return null;
  }



  const renderLoader = loading => {
    if (loading === true) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }
    return null;
  };

  const handleClickSave = () => {
    const contactJSON = _.clone(user.contact);
    contactJSON.firstName = contactFirstName;
    contactJSON.lastName = contactLastName;
    contactJSON.title = contactTitle;
    contactJSON.email = contactEmail;
    contactJSON.altEmail = contactAlternateEmail;
    contactJSON.mobile = contactMobilePhone;
    contactJSON.timezone = contactTimezone;
    updateContact(contactJSON, user.contact.sfid).then(() => {
      fetchUser();
    });
    setOpenName(false);
    setOpenEmail(false);
    setOpenAltEmail(false);
    setOpenMobile(false);
    setOpenTimezone(false);
  }

  const renderName = () => {
    return (
      <Fragment>
        {user.contact.firstName} {user.contact.lastName}
        <br />
        {user.contact.title}
      </Fragment>
    )
  }
  
  const renderUserInfo = () => {
    return (
      <DetailContainer>
        <ReactTooltip className="tooltip-issuetype" />
        <SettingRow>
          <Col xs={12}>
            <Header>MY DETAILS</Header>
          </Col>
        </SettingRow>
        <SettingRow style={openName ? borderOpen : null}>
          <Col xs={4}>
            <SavLabel>Name & Title</SavLabel>
          </Col>
          <Col xs={6}>
            {openName ? null : renderName()}
            <Collapse in={openName}>
              <div id="openName" className="text-center">
                <SavInput value={contactFirstName} onChange={(e) => setContactFirstName(e.target.value)} />
                <SavInput style={inputStyle} value={contactLastName} onChange={(e) => setContactLastName(e.target.value)} />
                <SavInput style={inputStyle} value={contactTitle} onChange={(e) => setContactTitle(e.target.value)} />
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleClickSave()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenName(false)}>Cancel</button>
              </div>
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenName(!openName)} aria-controls="openName" aria-expanded={openName}>
              {openName ? "Close" : "Edit"}
            </EditSpan>
          </Col>
        </SettingRow>

        <SettingRow style={openEmail ? borderOpen : null}>
          <Col xs={4} style={{display:'flex', alignItems:'center'}}>
            <SavLabel>
              Primary Email
            </SavLabel>
            &nbsp;
            <span data-multiline={true} data-tip={isPending ? `This email is pending review by a member of Savant. <br />Continue to login to the Community via ${user.username}.`  : ''}>
              <AiFillWarning size={20} fill="#ff5f00" style={!isPending ? {display:'none'} : {cursor: 'pointer'}} />
            </span>
          </Col>
          <Col xs={6}>
            {openEmail ? null : user.contact.email}
            <Collapse in={openEmail}>
              <div id="openEmail" className="text-center">
                <SavInput value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleClickSave()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenEmail(false)}>Cancel</button>
              </div>
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenEmail(!openEmail)} aria-controls="openEmail" aria-expanded={openEmail}>
              {openEmail ? "Close" : "Edit"}
            </EditSpan>
          </Col>
        </SettingRow>

        <SettingRow style={openAltEmail ? borderOpen : null}>
          <Col xs={4}>
            <SavLabel>Alternate Email</SavLabel>
          </Col>
          <Col xs={6}>
            {openAltEmail ? null : user.contact.altEmail}
            <Collapse in={openAltEmail}>
              <div id="openAltEmail" className="text-center">
                <SavInput value={contactAlternateEmail} onChange={(e) => setContactAlternateEmail(e.target.value)} />
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleClickSave()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenAltEmail(false)}>Cancel</button>
              </div>
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenAltEmail(!openAltEmail)} aria-controls="openAltEmail" aria-expanded={openAltEmail}>
              {openAltEmail ? "Close" : "Edit"}
            </EditSpan>
          </Col>
        </SettingRow>

        <SettingRow style={openMobile ? borderOpen : null}>
          <Col xs={4}>
            <SavLabel>Mobile Phone</SavLabel>
          </Col>
          <Col xs={6}>
            {openMobile ? null : phoneOutput(user.contact.mobile)}
            <Collapse in={openMobile}>
              <div id="openMobile" className="text-center">
                <SavInput value={contactMobilePhone} onChange={(e) => setContactMobilePhone(e.target.value)} />
                {/* <SavInputPhone phoneNumber={contactMobilePhone} setPhoneNumber={setContactMobilePhone} open={openMobile} /> */}
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleClickSave()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenMobile(false)}>Cancel</button>
              </div>
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenMobile(!openMobile)} aria-controls="openMobile" aria-expanded={openMobile}>
              {openMobile ? "Close" : "Edit"}
            </EditSpan>
          </Col>
        </SettingRow>

        <SettingRow style={openTimezone ? borderOpen : null}>
          <Col xs={4}>
            <SavLabel>Savant Scheduler Timezone</SavLabel>
          </Col>
          <Col xs={6}>
            {openTimezone ? null : renderTimeLabel(user.contact.timezone)}
            <Collapse in={openTimezone}>
              <div id="openTimezone" className="text-center">

              <Dropdown className="savDrop">
                <Dropdown.Toggle as="div" id="dropdown-basic">
                  <SavDropToggle>
                    <span className="truncate">{renderTimeLabel(contactTimezone)}</span>
                    <FaCaretDown />
                  </SavDropToggle>
                  
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {timezones.map((o,i) => {
                    return (
                      <Dropdown.Item
                        as="div"
                        key={o.value}
                        name={o}
                        onClick={() => setContactTimezone(o.value)}
                        className="onHov"
                        style={{ borderBottom: "1px solid #DBD9D6", whiteSpace: 'normal' }}
                      >
                        <span style={{ display: "inline-flex" }}>{o.label}</span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
                <br /><br />
                <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleClickSave()}>Save</button>
                <br />
                <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenTimezone(false)}>Cancel</button>
              </div>
            </Collapse>
          </Col>
          <Col xs={2} className="text-right">
            <EditSpan onClick={()=> setOpenTimezone(!openTimezone)} aria-controls="openTimezone" aria-expanded={openTimezone}>
              {openTimezone ? "Close" : "Edit"}
            </EditSpan>
          </Col>
        </SettingRow>



        <SettingRow className="m-t-20">
          <Col xs={12}>
            <Header>MY COMMUNITY PERMISSIONS</Header>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={true} />
          </Col>
          <Col xs={2}>
            <span className="f12 med text-uppercase">Default</span>
          </Col>
          <Col xs={9}>
            <p className="book f10 dune m-b-0">
              You have access to view and manage (where applicable) product documentation, technical support, Savant University, Savant's Beta program, the Savant Store and Design Tools (without pricing), and user settings such as notifications and email preferences by default.<br /><span className="med">Any permissions awarded below would be additive.</span>
            </p>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.permissions.contactManagement} />
          </Col>
          <Col xs={2}>
            <span className="f12 med text-uppercase">Team</span>
          </Col>
          <Col xs={9} className="book f10 dune">
            <PermList>
              <li>Manage employee setup</li>
              <li>Enroll team members in Savant University.</li>
              <li>Employee deactivation & reactivation</li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow style={isNDD ? hidden : null}>
          <Col xs={1}>
            <SavCheck checked={user.contact.permissions.storePricing} />
          </Col>
          <Col xs={2}>
            <span className="f12 med text-uppercase">Store</span>
          </Col>
          <Col xs={9} className="book f10 dune">
            <PermList>
              <li>Pricing in Savant Store & Design Tools</li>
              <li>Checkout Carts in the Savant Store</li>
              <li>View/Manage Order Defaults</li>
              <li>View/Manage Shipping Address/Methods</li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow style={isNDD ? hidden : null}>
          <Col xs={1}>
            <SavCheck checked={user.contact.permissions.ordersInvoices} style={{cursor:'not-allowed'}} />
          </Col>
          <Col xs={2}>
            <span className="f12 med text-uppercase">Billing</span>
          </Col>
          <Col xs={9} className="book f10 dune">
            <PermList>
              <li>Run account statements & View/Pay Invoices</li>
              <li>Update credit card information</li>
              <li>Includes all permissions in <span className="oj med">TEAM</span></li>
              <li>Includes all permissions in <span className="oj med">STORE</span></li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.permissions.accountAdmin} />
          </Col>
          <Col xs={2}>
            <span className="f12 med text-uppercase">Admin</span>
          </Col>
          <Col xs={9} className="book f10 dune">
            <PermList>
              <li>View applicable sales programs</li>
              <li>Manage savant.com listings</li>
              <li>Savant Central Management settings</li>
              <li>Includes all permissions in <span className="oj med">TEAM</span></li>
              <li>Includes all permissions in <span className="oj med">STORE</span></li>
              <li>Includes all permissions in <span className="oj med">BILLING</span></li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow className="m-t-20">
          <Col xs={12}>
            <Header>MY SCM PERMISSIONS</Header>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.scmAccess === 'None'} />
          </Col>
          <Col xs={3}>
            <span className="f12 med text-uppercase">Default</span>
          </Col>
          <Col xs={8} className="book f10 dune">
            <PermList>
              <li>No access to SCM</li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.scmAccess === 'Access'} />
          </Col>
          <Col xs={3}>
            <span className="f12 med text-uppercase">ACCESS</span>
          </Col>
          <Col xs={8} className="book f10 dune">
            <PermList>
              <li>Grants user access to SCM. All Users can add or modify Jobs;
                only Admin users can add, modify, or remove Domains or other Users.
              </li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.scmAccess === 'Admin'} />
          </Col>
          <Col xs={3}>
            <span className="f12 med text-uppercase">Admin</span>
          </Col>
          <Col xs={8} className="book f10 dune">
            <PermList>
              <li>
                Admin users can add, remove and manage users and jobs, as well as manage Profiles and Templates on SCM.
              </li>
            </PermList>
          </Col>
        </SettingRow>

        <SettingRow>
          <Col xs={1}>
            <SavCheck checked={user.contact.permissions.scmPurchasing} />
          </Col>
          <Col xs={3}>
            <span className="f12 med text-uppercase">Subscription Management</span>
          </Col>
          <Col xs={8} className="book f10 dune">
            <PermList>
              <li>
                Users with this permission enabled will be able to purchase subscriptions within SCM for any home in the domain.
              </li>
            </PermList>
          </Col>
        </SettingRow>


        <div className="book f10 gray m-t-20">
          *Permissions above are for informational purposes only. Contact your account admin if you require additional permissions.
        </div>

      </DetailContainer>
    )
  }

  return (
    <Fragment>
      {renderUserInfo()}
      {renderLoader(loading)}
    </Fragment>
  );
};

const loadingSelector = createLoadingSelector(["UPDATE_CONTACT"]);

const mapStateToProps = state => {
  return {
    loading: loadingSelector(state),
    errors: state.errors
  };
};

export default connect(mapStateToProps, { updateContact, setSuccessToast, fetchUser })(
  ContactDetails
);


const today = new Date();
const year = today.getFullYear();
const quarter = Math.floor(today.getMonth() / 3) + 1;

export const fakeQuarter = {
  year: year,
  virPercentEarned: 0,
  virNextTierPercent: 0,
  virNextTierGoal: 0,
  virEarned: 0,
  validTier: null,
  virAmbassadorQualified: false,
  type: 'VIR',
  tierEarned: null,
  summaryStart: '2021-04-01',
  summaryEnd: '2021-06-30',
  revenuePriorYear: 0,
  revenue: 0,
  programQuarter: quarter,
  program: 'VIR 2021',
  message: '$25,0000 to go till next tier!',
  leadsWithSavant: null,
  isBeta: null,
  isActive: false,
  inGoodStanding: false,
  earnedAmbassador: null,
  coreTrainings: null,
  adoptionUiHstLicCtrl: 0,
  adoptionQuarters: null,
  adoptionELSClimate: 0,
  adoptionAudioVideoSpeaker: 0
};

export const fakeIntegrator = {
  year: year,
  virPercentEarned: null,
  virNextTierPercent: null,
  virNextTierGoal: null,
  virEarned: null,
  validTier: true,
  type: 'Integrator Tier',
  tierEarned: 'Authorized',
  summaryStart: '2021-01-01',
  summaryEnd: '2021-12-31',
  revenuePriorYear: 0,
  revenue: 0,
  programQuarter: null,
  program: 'Integrator 2021',
  message: '',
  leadsWithSavant: null,
  isBeta: false,
  isActive: true,
  inGoodStanding: true,
  earnedAmbassador: false,
  coreTrainings: 0,
  adoptionUiHstLicCtrl: 0,
  adoptionQuarters: null,
  adoptionELSClimate: 0,
  adoptionAudioVideoSpeaker: 0
};

export const fakeAmbassador = {
  year: year,
  virPercentEarned: null,
  virNextTierPercent: null,
  virNextTierGoal: null,
  virEarned: null,
  validTier: true,
  type: 'Ambassador',
  tierEarned: 'Authorized',
  summaryStart: '2020-07-01',
  summaryEnd: '2021-06-30',
  revenuePriorYear: 0,
  revenue: 0,
  programQuarter: null,
  program: 'Ambassador 2021',
  message: 'You are getting close to earning Savant Ambassador benefits',
  leadsWithSavant: null,
  isBeta: false,
  isActive: true,
  inGoodStanding: true,
  earnedAmbassador: false,
  coreTrainings: null,
  adoptionUiHstLicCtrl: 0,
  adoptionQuarters: {
    finalQuarterMinusThree: {
      adoptionUiHstLicCtrl: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionELSClimate: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionAudioVideoSpeaker: {
        value: 0,
        message: '',
        isRisk: true
      }
    },
    finalQuarterMinusTwo: {
      adoptionUiHstLicCtrl: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionELSClimate: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionAudioVideoSpeaker: {
        value: 0,
        message: '',
        isRisk: false
      }
    },
    finalQuarterMinusOne: {
      adoptionUiHstLicCtrl: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionELSClimate: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionAudioVideoSpeaker: {
        value: 0,
        message: '',
        isRisk: false
      }
    },
    finalQuarter: {
      adoptionUiHstLicCtrl: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionELSClimate: {
        value: 0,
        message: '',
        isRisk: false
      },
      adoptionAudioVideoSpeaker: {
        value: 0,
        message: '',
        isRisk: false
      }
    }
  },
  adoptionELSClimate: 0,
  adoptionAudioVideoSpeaker: 0
};
/* eslint-disable max-nested-callbacks */
import React, {useState, useEffect} from 'react';
import { Modal,Container, Row, Col, Nav, Tab, } from 'react-bootstrap';
import styled from 'styled-components';
import _ from 'lodash';

const ModalBody = styled.div`
  padding: 50px 25px;
`;
const itemStyle = {
  marginBottom: 8
};
const linkStyle = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#fafaf9",
  color: "#fff",
  borderRadius: "unset",
  fontSize: 12
};
const linkStyleActive = {
  padding: 16,
  textTransform: "uppercase",
  fontFamily: "Gotham-Medium",
  backgroundColor: "#979797",
  color: "#ff5f00",
  borderRadius: "unset",
  fontSize: 12
};
const LinkDiv = styled.div`
  display:grid;
  grid-template-columns: 10% 90%;
  align-items:center;
  > div{
    color:${(props) => props.wht ? '#fff' : null}
  }
`;

const BetaDeployModal = ({show, hide, content}) => {
  const [key, setKey] = useState("");
  const contentArticle = _.find(content, (c) => c.title === 'Savant Beta Host Deployment Guide');

  useEffect(() => {
    if (content){
      const contentArticle = _.find(content, (c) => c.title === 'Savant Beta Host Deployment Guide');
      setKey(contentArticle.contentBlocks[0].sequence.toString());
    }
  },[content]);

  const handleClickNext = () => {
    if (parseInt(key) < contentArticle.contentBlocks.length){
      let keyInt = parseInt(key) +1;
      setKey(keyInt.toString());
    }else{
      hide();
      setKey('1');
    }
  }
  
  const createMarkup = richText => {
    return { __html: richText };
  };

  const renderNavItems = () => {
    return contentArticle.contentBlocks.map((c, idx) => {
      return (
        <Nav.Item key={c.sfid} style={itemStyle}>
          <Nav.Link
            style={key === c.sequence.toString() ? linkStyleActive : linkStyle}
            eventKey={c.sequence.toString()}
          >
            <LinkDiv wht={key === c.sequence.toString() ? true : false}>
              <div className="f32 med">{c.sequence}</div>
              <div className="book f14 text-center">{c.title}</div>
            </LinkDiv>
          </Nav.Link>
        </Nav.Item>
      )
    })
  }
  
  const renderTabPanes = () => {
    return contentArticle.contentBlocks.map((c, idx) => {
      return (
        <Tab.Pane eventKey={c.sequence.toString()} key={c.sfid}>
          <Container>
            <div dangerouslySetInnerHTML={createMarkup(c.content)}></div>
          </Container>
        </Tab.Pane>
      )
    })
  }
  
  if (!_.isEmpty(content)){
    return (
      <Modal show={show} onHide={hide} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Beta Host Deployment Guide
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
        <Tab.Container
          id="left-tabs-beta"
          activeKey={key}
          onSelect={k => setKey(k)}
        >
          <Row>
            <Col sm={4}>
              <Nav variant="pills" className="flex-column">
                {renderNavItems()}
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                {renderTabPanes()}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
  
        </ModalBody>
        <Modal.Footer>
          <button type="button" className="sav-btn__gray" onClick={() => handleClickNext()}>
            {key !== '5' ? 'Next' : 'Close'}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
  
};
export default BetaDeployModal;
import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const Label = styled.div`
  font-family: "Gotham-Book";
  font-size: 12px;
  color: #b4b4b4;
  padding-bottom: 6px;
  margin-top: 12px;
`;
const Text = styled.div`
  font-family: "Gotham-Book";
  font-size: 12px;
  color: #565551;
  padding-bottom: 6px;
`;

const HelpDiv = styled.div`
  font-size: 10px;
  color: #ff5f00;
  border: 1px solid #ff5f00;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-align: center;
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
`;

const SavOutput = props => {
  const { label, text, help } = props;

  const renderHelp = () => {
    if (help) {
      return (
        <HelpDiv data-class="tooltip" data-tip={help}>
          ?
        </HelpDiv>
      );
    }
  };

  return (
    <React.Fragment>
      <ReactTooltip />
      <Label>
        {label}
        {renderHelp()}
      </Label>
      <Text>{text}</Text>
    </React.Fragment>
  );
};

export default SavOutput;

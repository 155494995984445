import React, { useState, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {FiAward} from 'react-icons/fi';
import styled from "styled-components";
import _ from "lodash";
import AnnualPerformance from "../../../components/AccountCharts/AnnualPerformance";
import IntegratorProgress from "../../../components/AccountCharts/IntegratorProgress";
import IntegratorHistory from "../../../components/AccountCharts/IntegratorHistory";
import Authorized from "../../../images/AmbassadorBadges/Authorized.png";
import Silver from "../../../images/AmbassadorBadges/Silver.png";
import Gold from "../../../images/AmbassadorBadges/Gold.png";
import Platinum from "../../../images/AmbassadorBadges/Platinum.png";
import PlatinumElite from "../../../images/AmbassadorBadges/PlatinumElite.png";

const Header = styled.div`
  font-family: "Gotham-Medium";
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
  font-size:20px;
`;
const SubHeader = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  font-family: "Gotham-Medium";
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #b4b4b452;
`;
const CurrentBadge = styled.img`
  width: 150px;
  border: 5px solid #9b9b9b;
  border-radius: 4px;
`;
const AwardRow = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 10% 90%;
  border-top:1px solid #b4b4b452;
  padding: 12px 8px;
`;
const Card = styled.div`
  background-color:#f9f9f9;
  padding:10px;
`;

const Integrator = ({account, currentProgram, nextProgram}) => {

  const getTierBadge = (program) => {
    if (program.tierEarned.toUpperCase() === 'AUTHORIZED') {
      return Authorized;
    }
    if (program.tierEarned.toUpperCase() === 'SILVER') {
      return Silver;
    }
    if (program.tierEarned.toUpperCase() === 'GOLD') {
      return Gold;
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM') {
      return Platinum;
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE') {
      return PlatinumElite;
    }
  }

  const getAccountTierShip = (program) => {
    if (program.tierEarned.toUpperCase() === 'AUTHORIZED') {
      return "Standard shipping rates";
    }
    if (program.tierEarned.toUpperCase() === 'SILVER') {
      return "Free US ground freight on all hardware orders over $5,000";
    }
    if (program.tierEarned.toUpperCase() === 'GOLD') {
      return 'Free US ground freight on all hardware orders over $2,000';
    }
    if (program.tierEarned.toUpperCase() === 'PLATINUM' || program.tierEarned.toUpperCase() === 'PLATINUM ELITE') {
      return 'Free US ground freight on all shipments';
    }
  }

  const today = new Date();
  const year = today.getFullYear();

  const renderEliteRewards = (program) => {
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE'){
      return (
        <Fragment>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Sponsored Specifier Event</div>
            </div>
          </AwardRow>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Demo Product Provision for your Team<br />$7,500 @ Dealer Cost</div>
            </div>
          </AwardRow>
          <AwardRow>
            <FiAward size={20} fill="#4cd964" />
            <div>
              <div className="f12 med">Premier Savant.com Listing (Starting in mid-2023)</div>
            </div>
          </AwardRow>
        </Fragment>
      )
    }
    return null;
  }

  const renderEliteDownload = (program) => {
    if (program.tierEarned.toUpperCase() === 'PLATINUM ELITE'){
      return (
        <Row className="m-t-40">
          <Col xs={12} className="text-center">
            <a href="https://sweb-img.s3.amazonaws.com/Community/PDFs/Platinum%20Elite%20One%20Sheet%202022.pdf" target="_blank" rel="noreferrer">
              <button className="sav-btn__orange--fill">Download Platinum Elite Onesheet</button>
            </a>
            
          </Col>
        </Row>
      )
    }
    return null;
  }

  const renderTierRow = (program) => {
    return (
      <Row className="m-t-20">
        <Col md={4}>
          <Card style={{minHeight:437}}>
            {/* <SubHeader>{program.year > year ? "Projected" : "Active" } {program.year} Tier</SubHeader> */}
            <SubHeader>Active Tier</SubHeader>
            <div className="text-center m-b-30 m-t-30">
              <CurrentBadge src={getTierBadge(program)} />
            </div>
            <SubHeader>Rewards</SubHeader>
            <AwardRow style={{borderTop:"none"}}>
              <FiAward size={20} fill="#4cd964" />
              <div>
                <div className="f12 med">{program.tierEarned.toUpperCase() === 'AUTHORIZED' ? '40%' : program.tierEarned.toUpperCase() === 'PLATINUM ELITE' ? '50%' : '45%'} off MSRP</div>
                <div className="light-gray f10">
                  *Excluding non-discountable or promotional products
                </div>
              </div>
            </AwardRow>
            <AwardRow style={program.tierEarned.toUpperCase === 'AUTHORIZED' ? {display: 'none'} : null}>
              <FiAward size={20} fill="#4cd964" />
              <div>
                <div className="f12 med">Quarterly VIR Eligibility</div>
              </div>
            </AwardRow>
            <AwardRow>
              <FiAward size={20} fill="#4cd964" />
              <div className="f12 med">{getAccountTierShip(program)}</div>
            </AwardRow>
            {renderEliteRewards(program)}
          </Card>
        </Col>
        <Col md={8}>
          <Row>
            <Col xs={12}>
              <Card>
                <SubHeader>Progress</SubHeader>
                <IntegratorProgress account={account} programResult={program} />
              </Card>
            </Col>
          </Row>

          <Row className="m-t-20">
            <Col xs={12}>
              <Card>
                <SubHeader>Annual Performance</SubHeader>
                <AnnualPerformance account={account} programResult={program} />
              </Card>
            </Col>
          </Row>

          {renderEliteDownload(program)}
        </Col>
      </Row>
    )
  }

  if (!_.isEmpty(account) && !_.isEmpty(currentProgram)){
    return (
      <Fragment>
        <Header>Integrator Program</Header>

        <Container>
          {renderTierRow(currentProgram)}
            <Row className="m-t-20">
              <Col xs={12}>
                <Card>
                  <SubHeader>History</SubHeader>
                  <IntegratorHistory programData={account.salesProgramData} programResult={currentProgram} />
                </Card>
              </Col>
            </Row>
        </Container>
          <div className="text-center light-gray m-t-20 f12">
            *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
          </div>
      </Fragment>
    )
  }
  return null;
}

export default Integrator;
import {SET_TOAST_STATUS, SET_FAIL_STATUS} from "../actions/types";


export const toast = (state = {showSuccess:false, showFail:false, message:''}, action) => {

  const { type, payload } = action;


  switch(type){
    case SET_TOAST_STATUS:
      return {
        ...state,
        showSuccess:payload
      }
    case SET_FAIL_STATUS:
      return {
        ...state,
        showFail:payload.show,
        message:payload.message
      }
    default:
      return state;
  }

};

export default toast;
import React, { useState, useEffect, useRef, Fragment } from "react";
import { Container, Row, Col, Carousel, Image, Dropdown, Popover, OverlayTrigger } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import {fetchContent, fetchMarketing, fetchDocument} from "../../actions";
import { HashLink } from 'react-router-hash-link';
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'
import { FaCaretDown } from "react-icons/fa";
import {AiOutlineSearch, AiOutlineMenu, AiOutlineFilter, AiOutlineClose, AiOutlineFilePdf, AiOutlineDownload, AiOutlineFileZip} from "react-icons/ai"
import {BiChevronRightCircle} from "react-icons/bi";
import { save } from 'save-file'
import { createLoadingSelector } from "../../selectors";
import useSticky from "../../hooks/useSticky";
import SavSearch from "../../components/SavSearch";
import { ZeroStateText } from "../../components/Styled";

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
`;
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderTextOverlay = styled.div`
  position:absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display:flex;
  height: 100%;
  align-items:center;
  justify-content:center;
  padding: 10px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.1);
`;

const SectionHeader = styled.div`
  font-size:24px;
  text-transform: uppercase;
  margin: 40px 0px 0px;
  font-family: "Gotham-Medium";
`;

const ItemBottom = styled.div`
  background-color: #3f3f3f;
  color: #fff;
  padding: 15px;
  height: 102px;
  font-size: 14px;
  text-align: center;
`;

const PageNav = styled(Row)`
  font-size: 14px;
  // background: linear-gradient(180deg,#EEEFED 0%,#DCDCDC 100%);
  background: #3f3f3f;
  display:flex;
  align-items:center;
  justify-content: space-between;
  z-index: 1;
  // width: 100%
  min-height: 50px;
  color: white;
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 0px;
`;
const PageNavItem = styled.div`
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  > a:hover {
    color:#ff5f00;
    cursor: pointer;
  }
  &:hover{
    color:#ff5f00;
    cursor: pointer;
  }
`;
const SavSearchInput = styled.input`
  background: #4f4f4f;
  border-radius: 4px;
  border: none;
  // border-bottom: ${(props) => props.borderBottom || ".5px solid #fff"};
  font-size: 12px;
  width: ${(props) => props.width || "100%"}
  padding: 6px;
  &:focus {
    outline: 0 none;
  }
  color: white;
`;
const ContentLink = styled(Link)`
  &:hover {
    text-decoration:none;
    background-color:#fafaf9;
  }
`;
const ContentA = styled.a`
  z-index: 9999;
  &:hover {
    text-decoration:none;
    background-color:#fafaf9;
  }
`;
const HeroLink = styled(Link)`
  &:hover {
    text-decoration:underline;
    color: #fff;
  }
`;

const truncateString = (stringIn, num) => {
  if (stringIn.length >= num){
    return stringIn.slice(0, num) + '...';
  } else {
    return stringIn
  }
  
}
const stripHtml = (stringIn) => {
  return stringIn.replace(/(<([^>]+)>)/gi, "");
}

const Sales = ({content, fetchContent, marketing, fetchMarketing, fetchDocument, user, fetchingContent, fetchingMarketing}) => {
  const [page, setPage] = useState('specialty-sales-juniper');
  const { isSticky, element } = useSticky();

  const [specialtyData, setSpecialtyData] = useState([]);

  const [programData, setProgramData] = useState([]);

  const [resourceData, setResourceData] = useState([]);
  const [resourceDataFiltered, setResourceDataFiltered] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All Content')
  const [searchString, setSearchString] = useState('');

  const defaultImage = "https://sweb-img.s3.amazonaws.com/Carousel/New_USAI_Lighting_Carousel_Image_v4.png";
  const filterOpts = [
    'All Content',
    'Price Sheets',
    'Sales Promotions',
    'Digital Brochures',
    'Imagery',
    'Videos',
    'Print-Ready',
    'Sales Tools',
    'AIA Courses',
  ];

  useEffect(() => {
    if (_.isEmpty(content) && !fetchingContent){
      fetchContent();
    } else {
      const specialtyFound = _.filter(content.data, (c) => c.page.includes('specialty-sales'));
      setSpecialtyData(specialtyFound);
    }
  },[content]);

  useEffect(() => {
    if (_.isEmpty(marketing)) {
      fetchMarketing();
    } else {
      setResourceData(marketing.marketingContent);
      setResourceDataFiltered(marketing.marketingContent);
      setProgramData(marketing.salesProgramContent);
    }
  }, [marketing]);

  useEffect(() => {
    if (activeFilter !== 'All Content'){
      let tempResources = resourceData.filter(resource =>
        SavSearch(resource, ["title", "description"], searchString) &&
        resource.category === activeFilter
      );
      setResourceDataFiltered(tempResources)
    } else if(!_.isEmpty(searchString)) {
      let tempResources = resourceData.filter(resource =>
        SavSearch(resource, ["title", "description"], searchString)
      );
      console.log(tempResources);
      setResourceDataFiltered(tempResources)
    } else {
      setResourceDataFiltered(resourceData);
    }

  },[searchString, activeFilter, resourceData]);

  const getURL = (url) => {
    if (!_.isEmpty(user)){
      const sesh = localStorage.getItem('sfsid');
      const username = user.data.username;
      if (url.startsWith('https://auth.savantsystems')){
        return `${url}&username=${username}&sessionid=${sesh}`;
      }
      return url;
    }
    return null;
  };

  const renderCarousel = () => {
    const hasFeatured = _.find(specialtyData, (s) => s.isFeatured);

    if ((_.isEmpty(specialtyData) || _.isEmpty(hasFeatured)) && !fetchingContent){
      return (
        <HeroContainer>
            <Image src={"https://sweb-img.s3.amazonaws.com/Community/Sales/MarketingDefault.png"} style={{width: '100%', objectFit: 'cover', maxHeight: 400}} />
            <HeroOverlay>
              <div className="book text-center">
                <div style={{width: '100%', margin: '0px auto 10px'}} className="wht med text-uppercase f32 m-t-10">
                  Sales & Marketing
                </div>
                <div style={{width: '100%', margin: '0px auto 10px'}} className="wht book text-uppercase f14 m-t-5">
                  Explore Specialty Sales, Marketing Resources and more!
                </div>
              </div>
            </HeroOverlay>
          </HeroContainer> 
      )
    }


    return (
      <Carousel>
        {specialtyData.map((c) => {
          if (c.isFeatured){
            return (
              <Carousel.Item interval={7000} key={c.sfid}>
                <HeroContainer>
                  <Image src={c.image || "https://sweb-img.s3.amazonaws.com/Community/Sales/MarketingDefault.png"} style={{width: '100%', objectFit: 'cover', maxHeight: 400}} />
                  <HeroOverlay>
                    <div className="book text-center">
                      <div style={{width: '100%', margin: '0px auto 10px'}} className="wht med text-uppercase f32 m-t-10">
                        {c.title}
                      </div>
                      <div style={{width: '100%', margin: '0px auto 10px'}} className="wht book text-uppercase f14 m-t-5">
                        <HeroLink className="wht onHov" to={`/marketing/${c.page}`}>
                          {c.subtitle || "Learn More"}
                          <BiChevronRightCircle fill="#fff" size={20} style={{marginLeft: 10}} />
                        </HeroLink>
                        
                      </div>
                    </div>
                  </HeroOverlay>
                </HeroContainer>
              </Carousel.Item>
            )
          }
        })}
      </Carousel>
    )

  };

  const renderSpecialtySales = () => {
    if (!_.isEmpty(specialtyData)){
      return (
        <Fragment>
          <SectionHeader>Specialty Sales</SectionHeader>
          <Row>
            {specialtyData.map((c) => {
              return (
                <Col md={6} lg={4} xl={3} style={{marginTop: 30}} key={c.sfid}>
                  <ContentLink to={`/marketing/${c.page}`}>
                  <div
                    className="event-img-container home-shadow"
                    style={{height:200}}
                    onClick={() => setPage(c.page)}
                  >
                    <HeaderTextOverlay className="f24 med text-center text-uppercase headerTextOverlay wht">
                      {c.title}
                    </HeaderTextOverlay>
                    <span
                      className="event-img-zoom"
                      style={{
                        backgroundImage: `url(${c.thumbnail})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        display: "block",
                        height: "200px",
                        maxHeight: "200px",
                      }}
                    />
                  </div>
                  <ItemBottom className="home-shadow">
                    {truncateString(stripHtml(c.description || `Click here for more information`), 130)}
                  </ItemBottom>
                  </ContentLink>
                </Col>
              )
            })}
          </Row>
        </Fragment>
      )
    }
    return null;
  };

  const renderPrograms = () => {
    if (!_.isEmpty(programData)){
      return (
        <Fragment>
          <SectionHeader>Sales Programs</SectionHeader>
          <Row>
            {programData.map((p) => {
              return (
                <Col md={6} lg={4} xl={3} style={{marginTop: 30}} key={p.sfid}>
                  <ContentA
                    href={p.type === 'URL Navigation' ? `${p.url}` : null}
                    target="_blank"
                    rel="noreferrer"
                  > 
                  <div
                    className="event-img-container home-shadow"
                    style={{height:200}}
                  >
                    <HeaderTextOverlay className="f24 med text-center text-uppercase headerTextOverlay wht">
                      {p.title}
                    </HeaderTextOverlay>
                    <span
                      className="event-img-zoom"
                      style={{
                        backgroundImage: `url(${p.img})`,
                        // backgroundColor: "#000",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        display: "block",
                        height: "200px",
                        maxHeight: "200px",
                      }}
                    />
                  </div>
                  <ItemBottom>
                    {truncateString(stripHtml(p.description), 130)}
                  </ItemBottom>
                  </ContentA>
                </Col>
              )
            })}
          </Row>
        </Fragment>
      )
    }
    return null;
  };

  const renderResources = () => {


    if (!_.isEmpty(resourceData)){

      const renderImage = (title) => {
        if (title.includes('Remote')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Remote.jpeg";
        }
        if (title.includes('Audio') || title.includes('Artisan')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Artison.png";
        }
        if (title.includes('Cync')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/LightingCync.png";
        }
        if (title.includes('USAI') || title.includes('Fixtures')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/LightingKitchen.png";
        }
        if (title.includes('Next Generation')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/LightingAscend.png";
        }
        if (title.includes('Shades')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Shades.png";
        }
        if (title.includes('Apple')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Apple.png";
        }
        if (title.includes('App')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/App.png";
        }
        if (title.includes('Entry')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Security.png";
        }
        if (title.includes('Climate')){
          return "https://sweb-img.s3.amazonaws.com/Community/Sales/Climate.png";
        }
        return "https://sweb-img.s3.amazonaws.com/Community/Sales/App.png";
      };
      const renderIcon = (type) => {

        if (type === 'pdf'){
          return (
            <span className="f12 book wht text-uppercase" style={{position:"absolute", right: "5%", bottom: 10, zIndex: 9999 }}>
              <AiOutlineFilePdf size={20} fill="#fff" />
            </span>
          )
        }
        if (type === 'download'){
          return (
            <span className="f12 book wht text-uppercase" style={{position:"absolute", right: "5%", bottom: 10, zIndex: 9999 }}>
              <AiOutlineDownload size={20} fill="#fff" />
            </span>
          )
        }
        if (type === 'zip'){
          return (
            <span className="f12 book wht text-uppercase" style={{position:"absolute", right: "5%", bottom: 10, zIndex: 9999 }}>
              <AiOutlineFileZip size={20} fill="#fff" />
            </span>
          )
        }
        return null;
      }
      if (_.isEmpty(resourceDataFiltered)){
        return (
          <Col xs={12}>
            <ZeroStateText>No resources found matching the specified filter or search terms.</ZeroStateText>
          </Col>
        )
      };

      return resourceDataFiltered.map((p, idx) => {

        if (p.category === 'Videos'){
          return (
            <Col md={6} lg={4} xl={3} style={{marginTop: 30}} key={p.sfid}>
              <ReactPlayer
                url={p.url}
                controls={p.url.includes('cdn.savant.com') || p.url.includes('sav-marketing-sales') || p.url.includes('media.savant.com') ? true : false}
                light={p.url.includes('cdn.savant.com') || p.url.includes('sav-marketing-sales')|| p.url.includes('media.savant.com') ? false : true}
                height={200}
                width="100%"
                style={{backgroundColor: '#000'}}
              />

              <ItemBottom>
                {truncateString(stripHtml(p.description), 100)}
              </ItemBottom>
            </Col>
          )
        }


        return (
          <Col md={6} lg={4} xl={3} style={{marginTop: 30}} key={p.sfid}>
            <ContentA
              href={getURL(p.url)}
              target="_blank"
              rel="noreferrer"
            > 
            <div
              className="event-img-container home-shadow"
              style={{height:200}}
            >
              <HeaderTextOverlay className="f24 med text-center text-uppercase headerTextOverlay wht">
                {p.title}
              </HeaderTextOverlay>
              <span
                className="event-img-zoom"
                style={{
                  backgroundImage: `url(${p.thumbnail ? p.thumbnail : renderImage(p.title)})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  display: "block",
                  height: "200px",
                  maxHeight: "200px",
                }}
              />
              <span className="f12 book wht text-uppercase" style={{position:"absolute", left: "5%", bottom: 10, zIndex: 9999 }}>
                {p.category}
              </span>
              {renderIcon(p.fileType)}
            </div>
            <ItemBottom>
              {truncateString(stripHtml(p.description), 100)}
            </ItemBottom>
            </ContentA>
          </Col>
        )
      });
    }
    
  };

  const renderSpecialtyDrop = () => {
    return (
      <PageNavItem>
        <Dropdown className="savDrop d-none d-md-block">
          <Dropdown.Toggle as="div" id="dropdown-basic" style={{color: 'white'}}>
            <AiOutlineFilter size={25} fill="#fff" /> <FaCaretDown size={20} fill="#fff" />
          </Dropdown.Toggle>

          <Dropdown.Menu style={{marginTop: 14}}>
            {filterOpts.map((c) => {
              return (
                <Dropdown.Item
                  as="div"
                  key={c}
                  style={{ borderBottom: "1px solid #DBD9D6" }}
                  onClick={() => setActiveFilter(c)}
                >
                  {c}
                </Dropdown.Item>
              )
            })};
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="savDrop d-block d-md-none">
          <Dropdown.Toggle as="div" id="dropdown-basic" style={{color: 'white'}}>
            <AiOutlineFilter size={25} fill="#fff" /><FaCaretDown size={20} fill="#fff" />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{marginTop: 14}}>
            {filterOpts.map((c) => {
              return (
                <Dropdown.Item
                  as="div"
                  key={c}
                  style={{ borderBottom: "1px solid #DBD9D6" }}
                  onClick={() => setActiveFilter(c)}
                >
                  {c}
                </Dropdown.Item>
              )
            })};
          </Dropdown.Menu>
        </Dropdown>
      </PageNavItem>
    )
    
  };
  const renderFilterPill = () => {
    if (activeFilter !== 'All Content'){
      return (
        <PageNavItem>
          <button style={{width: 'unset', minWidth: 150, padding: 4}} className="sav-btn__white savFlexAround" onClick={() => setActiveFilter("All Content")}>
            {activeFilter} <span className="book f12 wht">&times;</span>
          </button>
        </PageNavItem>
      )
    }
    return null;
  };
  const renderSearchPill = () => {
    if (!_.isEmpty(searchString)){
      return (
        <PageNavItem>
          <button style={{width: 'unset', minWidth: 150, padding: 4}} className="sav-btn__white savFlexAround" onClick={() => setSearchString("")}>
            {searchString} <span className="book f12 wht">&times;</span>
          </button>
        </PageNavItem>
      )
    }
    return null;
  };

  if (!_.isEmpty(content)){
    return (
      <div>
        {renderCarousel()}

        <Container fluid style={{maxWidth: 1600}}>
          {renderSpecialtySales()}
          {renderPrograms()}
          <SectionHeader>Marketing Resources</SectionHeader>
          <PageNav className={isSticky ? "nav-sticky" : "m-t-20"} style={isSticky ? {width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0} : null}>

            <Col xs={4} md={2} style={{position: 'relative'}}>
              {renderSpecialtyDrop()}
            </Col>
            <Col xs={{span: 6, order: 3}} md={{span: 2, order:2}}>
              {renderFilterPill()}
            </Col>
            <Col xs={{span: 6, order: 4}} md={{span: 2, order:3}}>
              {renderSearchPill()}
            </Col>
            <Col xs={{span: 8, order: 2}} md={{span: 4, order: 4}} className="text-right">
              <PageNavItem style={{width: 300, float: 'right'}}>
                <AiOutlineSearch fill="#fff" size={20} />
                <SavSearchInput type="search" width="85%" placeholder="Search..." value={searchString} onChange={(e) => setSearchString(e.target.value)} />
              </PageNavItem>
            </Col>

          </PageNav>
          
          <Row>
            <div style={{ position: 'fixed', height:5}} ref={element} />
            {renderResources()}
          </Row>
          
        </Container>
      </div>
    );
  };
  return null;
}

const fetchingMarketing = createLoadingSelector(["FETCH_MARKETING"]);
const fetchingContent = createLoadingSelector(["FETCH_CONTENT"]);

const mapStateToProps = state => {
  return {
    content: state.content,
    marketing: state.marketing.data,
    user: state.user,
    fetchingContent: fetchingContent(state),
    fetchingMarketing: fetchingMarketing(state)
  };
};
export default connect(mapStateToProps, {fetchContent, fetchMarketing, fetchDocument})(Sales);

import {
  FETCH_ARTICLES_SUCCESS,
  UPDATE_SAVED_ARTICLES_SUCCESS
} from "../actions/types";
import { success } from "./utlities";

const knowledge = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_SUCCESS:
      return success(state, action);
    case UPDATE_SAVED_ARTICLES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          favorites: action.payload.payload.favorites
        }
      };
    default:
      return state;
  }
};

export default knowledge;

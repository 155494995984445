import { FETCH_FEED_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const feed = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FEED_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default feed;

import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Col, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import {SettingRow} from "../../../../components/Styled";
import SavPagination from "../../../../components/SavPagination";
import Address from "./Address";
import NewAddressModal from "./NewAddressModal";
import {updateAddress, setSuccessToast, deleteAddress, createTask} from "../../../../actions";

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-transform:uppercase;
`;

const AccountAddresses = ({account, user, contacts, addresses, setSuccessToast, updateAddress, deleteAddress, createTask}) => {
  const [pageSize, setPageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [addressList, setAddressList] = useState([]);
  const [isNDD, setIsNDD] = useState(
    user.contact.accType === 'Non-Direct' && (
      user.contact.accSubType === 'US Distribution' ||
      user.contact.accSubType === 'US Distribution Pro' ||
      user.contact.accSubType === 'International Distribution'
    )
  );
  const [isInternational, setIsInternational] = useState(user.contact.accSubType === 'International Distribution');

  useEffect(() => {
    if (addresses) {
      let index = 0;
      const tempAddrs = [];
      for (index = 0; index < addresses.length; index += pageSize) {
        let chunk = addresses.slice(index, index + pageSize);
        tempAddrs.push(chunk);
      }
      setAddressList(tempAddrs);
    }

  },[addresses, pageSize])

  const renderAddresses = () => {
    if (!_.isEmpty(addressList)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > addressList.length) {
        page = 0;
        setCurrentPage(1);
      }

      return addressList[page].map((addr) => {
        if (!addr.noLongerUsed) {
          return (
            <Fragment key={addr.sfid}>
              <Address 
                addr={addr}
                contacts={contacts}
                key={addr.sfid}
                updateAddress={updateAddress}
                setSuccessToast={setSuccessToast}
                deleteAddress={deleteAddress}
                createTask={createTask}
                account={account}
                isNDD={isNDD}
              />
            </Fragment>
          )
        }
        return null;
      })
    }
  }
  if (addresses) {
    return (
      <Container>
        <SettingRow style={{alignItems:"center"}}>
          <Col xs={8}>
            <Header>Addresses</Header>
          </Col>
          <Col xs={4} className="text-right" style={{paddingRight:0}}>
            <NewAddressModal isNDD={isNDD} isInternational={isInternational} />
          </Col>
        </SettingRow>
        {renderAddresses()}
        <SavPagination
          data={addressList}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataLength={addresses.length}
        />
      </Container>
    )
  }
  return null;
  
}

export default connect(null, {updateAddress, setSuccessToast, deleteAddress, createTask})(AccountAddresses);

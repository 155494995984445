import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _ from "lodash";
import {setSuccessToast, clearErrors} from "../../actions";

const ToastDiv = styled.div`
  background-color:${props => props.success ? "#4cd964" : "#d0021b" };
  color:#fff;
  position: fixed;
  left:50%;
  transform: translate(-50%, -175px);
  width:100%
  padding:27px;
  font-family:"Gotham-Medium";
  font-size:14px;
  text-align:center;
  z-index:9999;
  line-height:10px;
  opacity:.9;
`;

const ToastClose = styled.span`
  color: white;
  float: right;
  cursor: pointer;
  font-size: 20px;
`;

const SavToastSuccess = ({toast, setSuccessToast, message, success}) => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (toast){
      setShow(true);
    }else{
      setShow(false);
    }
  },[toast]);

  useEffect(() => {
    if (message){
      setMsg(success[message]);
    }
  },[success])

  if (show){
    return (
      <ToastDiv success>
        {msg || 'Success!'}
        <ToastClose onClick={() => setSuccessToast(false)}>&times;</ToastClose>
      </ToastDiv>
    )
  }
  return null;
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    success: state.success,
    loading: state.loading,
    toast: state.toast.showSuccess,
  };
};

export default connect(mapStateToProps, {setSuccessToast, clearErrors})(SavToastSuccess);

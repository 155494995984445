import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Row, Col, Container, Image, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import {Link} from "react-router-dom";
import moment from 'moment';
import {parseCurrency} from "../../../components/Utilities"
import { SavRow, ZeroStateText } from "../../../components/Styled";
import FinancingRequest from "./FinancingRequest";
import { fetchProducts } from "../../../actions";
import PowerUp2 from './PowerUp2.png'
import PowerFinancing from "./PowerFinancing.png";
import SavImageZoom from "../../../components/SavImageZoom";
import PowerFinancingTable from "../../../components/AccountCharts/PowerFinancingTable";
import { AccountContext } from "..";
import {BsChevronCompactDown, BsChevronCompactUp} from 'react-icons/bs';


const TabHeader = styled.div`
  // margin: 20px 0px 15px;
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 14px;
  padding: .5rem 0px;
`;
const DefaultPill = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #fafaf9;
  color:#9b9b9b;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 80px;
  text-align:center;
  text-transform:uppercase;
`;
const ActivePill = styled(DefaultPill)`
  background-color:#4cd964;
  color:#fff;
  border:1px solid #4cd964;
`;
const PendingPill = styled(DefaultPill)`
  background-color:#ffcc5f;
  color:#fff;
  border:1px solid #ffcc5f;
  cursor:default;
`;
const InactivePill = styled(DefaultPill)`
  background-color:#d0021b;
  color:#fff;
  border:1px solid #d0021b;
`;

const RequestRow = ({getInvoices, getProdImage, t }) => {
  const [expand, setExpand] = useState(true);
  const renderPill = (status) => {
    if (status === 'Approved'){
      return <ActivePill>{status}</ActivePill>
    } else if (status === 'Pending'){
      return <PendingPill>{status}</PendingPill>
    } else {
      return <DefaultPill>{status}</DefaultPill>
    }
  };
  const renderExpand = (t) => {
    if (t.status === 'Approved' && expand){
      return (
        <Col xs={12} style={{marginTop: 20}}>
          {getInvoices(t.orderId)}
          <div className="m-t-20 text-center">
            <Link to="/payment">
              <button className="sav-btn__orange--fill">Make a Payment</button>
            </Link>
            <Link to={`/orders/${t.orderId}`} style={{marginLeft: 5}}>
              <button className="sav-btn__orange">View Order</button>
            </Link>
          </div>
          <div className="text-center m-t-10 onHov" onClick={() => setExpand(false)}>
            <div className="f10 light-gray" style={{marginBottom:'-10px'}}>COLLAPSE</div>
            <BsChevronCompactUp style={{cursor:'pointer', fill: '#b4b4b4'}} size={30}  />
          </div>
        </Col>
      )
    } else if (t.status === 'Approved' && !expand){
      return (
        <Col xs={12}>
          <div className="text-center onHov" onClick={() => setExpand(true)}>
            <div className="f10 light-gray" style={{marginBottom:'-10px'}}>EXPAND</div>
            <BsChevronCompactDown style={{cursor:'pointer', fill: '#b4b4b4'}} size={30} onClick={() => setExpand(true)} />
          </div>
        </Col>
      )
    } else {
      return null;
    }
    
  };

  return (
    <SavRow style={t.status === 'Approved' ? {marginLeft: 0, marginRight: 0, paddingBottom: 0} : {marginLeft: 0, marginRight: 0}} key={t.sfid}>
      <Col xs={12} className="savFlexBetweenCenter">
        {getProdImage(t.financeSKUs[0])}
        <div style={{marginLeft: 10}}>
          <div className="med f14">{t.projectName}</div>
          <div className="book f12">
            {t.financeSKUs[0]}
          </div>
        </div>
        <div>
          <div className="med m-b-5 text-center" style={t.status === 'Approved' ? {color: '#4cd964'} : t.status === 'Pending' ? {color: '#ffcc5f'} : null}>{parseCurrency(t.requestedFinanceAmount)}</div>
          {renderPill(t.status)}
        </div>
      </Col>
      {renderExpand(t)}
    </SavRow>
  )
}

const FinancingTab = ({account, createTask, fetchTasks, setSuccessToast, fetchProducts, products}) => {

  const accData = useContext(AccountContext);
  
  useEffect(() => {
    if (_.isEmpty(products)){
      fetchProducts();
    }
  },[products]);


  const getInvoices = (orderId) => {
    const invoicesFound = _.filter(accData.invoices.data, (i) => i.orderId === orderId);

    if (invoicesFound){
      return invoicesFound.sort((a, b) => a.dueDate > b.dueDate ? 1 : -1)
      .map((inv) => {
        const dueDate = moment(inv.dueDate).format("MMM D, YYYY")
        return (
          <SavRow style={{padding: 5}} key={inv.sfid}>
            <Col xs={4}>
              <div className="med f12">Invoice</div>
              <div className="book f12 u">{inv.name}</div>
            </Col>
            <Col xs={4}>
              <div className="med f12">Amount</div>
              <div className="book f12">{parseCurrency(inv.total)}</div>
            </Col>
            <Col xs={4}>
              <div className="med f12">Due</div>
              <div className="book f12">{dueDate}</div>
            </Col>
          </SavRow>
        )
      })
    }
  };
  const getProdImage = (sku) => {
    const imageFound = _.find(products.data, (p) => p.sku === sku);
    if (imageFound){
      return <img src={imageFound.img} height={50} alt="prod image" />
    }
    return null;
  }

  const renderRequests = () => {
    return accData.tasks.data.sort((a, b) => (a.status === 'Approved' || a.status === 'Pending') && b.status !== 'Approved' ? -1 : 1).map((t) => {
      return (
        <RequestRow t={t} getInvoices={getInvoices} getProdImage={getProdImage} />
      )
    })
  }

  const renderZero = () => {
    if (_.isEmpty(accData.tasks.data)){
      return (
        <ZeroStateText>
          Savant's 2022 Power Financing Program ended December 31, 2022.<br /> <br />
          If you have a Power opportunity, please reach out to your <Link to="/support" className="med oj">regional sales associate</Link> to discuss options.
        </ZeroStateText>
      )
    }
    return null;
  }

  if (!_.isEmpty(accData) && !_.isEmpty(products)){
    return (
      <Container className="m-t-20">
        {/* <div className="text-center m-t-20 m-b-20">
          <FinancingRequest account={accData.account.data} products={products.data} createTask={createTask} fetchTasks={fetchTasks} tasks={accData.tasks.data} setSuccessToast={setSuccessToast} />
        </div> */}

        <Row>
          <Col md={6}>
          <Tabs defaultActiveKey="power" className="sav-nav--tabs">
            <Tab eventKey="power" title="Power Financing Program">
              <SavImageZoom title="Power Up Program" image={PowerFinancing} className="m-t-20" />
              <TabHeader>
                Extended Terms
              </TabHeader>
              <div className="book f12 m-t-10">
                <p>
                  All Power Storage Packages purchased prior to December 31, 2022 qualify for extended payment terms.
                </p>

                <div>
                  <b>Terms:</b> Packages invoiced over a period of 7 months (210 days)
                  <p style={{marginLeft: 15 }}>
                    Initial invoice due 60 days after order <br />5 additional/equal monthly invoices (net 30)
                  </p>
                </div>
    
                <div>
                  <b>Credit Card:</b> Packages charged over a period of 7 months (210 days) 
                  <p style={{marginLeft: 15 }}>
                    Initial CC payment 60 days after initial order <br />5 additional/equal monthly, reoccurring charges
                  </p>
                </div>
                <p>
                  Purchase a Storage Package for your Showroom and qualify for a free 200A Savant Power System Kit.
                </p>
              </div>
              <SavImageZoom title="Savant Power System" image={PowerUp2} className="m-b-20" />
            </Tab>
          </Tabs>
            
          </Col>
          <Col md={6}>
            <TabHeader>
              Financing Requests
            </TabHeader>
            {renderRequests()}
            {renderZero()}
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="text-center">
            
            <PowerFinancingTable />
            <div className="text-center m-t-20 m-b-20">
              <a href="https://sweb-img.s3.amazonaws.com/DigitalBrochures/Savant%20Power%20Financing.pdf" target="_blank" rel="noreferrer">
                <button className="sav-btn__orange--fill">Download Program OneSheet PDF</button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  return null;
}
const mapStateToProps = state => {
  return {
    products: state.products,
  };
};

export default connect(mapStateToProps, {fetchProducts})(FinancingTab);
import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Modal, Dropdown, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { FaCaretDown } from "react-icons/fa";
import {createCreditCard, setSuccessToast} from "../../../actions";
import {parseCurrency, parseDateShort} from "../../../components/Utilities";
import Years from "../../../components/Utilities/years";
import years from "../../../components/Utilities/years.json";
import SavCheck from "../../../components/SavCheck";
import {selectAddress} from '../../../selectors';
import SavFormInput from "../../../components/SavFormInput";
import SavFormInputArea from "../../../components/SavFormInputArea";
import SavFormDrop from "../../../components/SavFormDrop";

const SectionHeader = styled.div`
  font-size:14px;
  font-family:"Gotham-Medium";
  margin: 10px 0px;
`;

const hidden = {
  display: 'none'
}

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});

const RequestOpenTermsModal = ({account, createTask, setSuccessToast, fetchAccount}) => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const [inBusinessSince, setInBusinessSince] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessForm, setBusinessForm] = useState("");
  const [isDivision, setIsDivision] = useState(false);
  const [parentCompany, setParentCompany] = useState("");
  const [parentCompanyInBusinessSince, setParentCompanyInBusinessSince] = useState("");
  const [parentCompanyPrincipal, setParentCompanyPrincipal] = useState("");
  const [parentCompanyPrincipalTitle, setParentCompanyPrincipalTitle] = useState("");
  const [parentCompanyStreet, setParentCompanyStreet] = useState("");
  const [parentCompanyCity, setParentCompanyCity] = useState("");
  const [parentCompanyState, setParentCompanyState] = useState("");
  const [parentCompanyZip, setParentCompanyZip] = useState("");
  const [bankRefName1, setBankRefName1] = useState("");
  const [bankRefAddress1, setBankRefAddress1] = useState("");
  const [bankRefPhone1, setBankRefPhone1] = useState("")

  const [bankRefName2, setBankRefName2] = useState("");
  const [bankRefAddress2, setBankRefAddress2] = useState("");
  const [bankRefPhone2, setBankRefPhone2] = useState("")

  const [bankRefName3, setBankRefName3] = useState("");
  const [bankRefAddress3, setBankRefAddress3] = useState("");
  const [bankRefPhone3, setBankRefPhone3] = useState("")

  const [tradeRefCompany1, setTradeRefCompany1] = useState("");
  const [tradeRefContact1, setTradeRefContact1] = useState("");
  const [tradeRefContactEmail1, setTradeRefContactEmail1] = useState("");
  const [tradeRefContactPhone1, setTradeRefContactPhone1] = useState("");
  const [tradeRefAddress1, setTradeRefAddress1] = useState("");
  const [tradeRefAccountOpen1, setTradeRefAccountOpen1] = useState("");

  const [tradeRefCompany2, setTradeRefCompany2] = useState("");
  const [tradeRefContact2, setTradeRefContact2] = useState("");
  const [tradeRefContactEmail2, setTradeRefContactEmail2] = useState("");
  const [tradeRefContactPhone2, setTradeRefContactPhone2] = useState("");
  const [tradeRefAddress2, setTradeRefAddress2] = useState("");
  const [tradeRefAccountOpen2, setTradeRefAccountOpen2] = useState("");

  const [tradeRefCompany3, setTradeRefCompany3] = useState("");
  const [tradeRefContact3, setTradeRefContact3] = useState("");
  const [tradeRefContactEmail3, setTradeRefContactEmail3] = useState("");
  const [tradeRefContactPhone3, setTradeRefContactPhone3] = useState("");
  const [tradeRefAddress3, setTradeRefAddress3] = useState("");
  const [tradeRefAccountOpen3, setTradeRefAccountOpen3] = useState("");

  const [signed, setSigned] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const defaultBillingAddr = useSelector(selectAddress(account.defaultBillAddr));
  const today = new Date();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    if (!signed){
      setShowWarning(true);
      return;
    }else{
      setShowWarning(false);
    }

    const message = `
      Account Name:
      ${account.name}

      DBA:
      ${account.dba}

      Address:
      ${defaultBillingAddr.addressStreet}
      ${defaultBillingAddr.addressCity}, ${defaultBillingAddr.addressState}, ${defaultBillingAddr.addressZip}
      ${defaultBillingAddr.addressCountry}


      --COMPANY INFORMATION--

      In Business Since:
      ${inBusinessSince}

      Type of Business:
      ${businessType}

      Legal Form Under Which Business Operates:
      ${businessForm}

      Division/Subsidiary?:
      ${isDivision}

      Name of Parent Company:
      ${parentCompany}

      In Business Since:
      ${parentCompanyInBusinessSince}

      Name of Company Principal Responsible for Business Transactions:
      ${parentCompanyPrincipal}
      
      Principal Title:
      ${parentCompanyPrincipalTitle}

      Parent Company Address:
      ${parentCompanyStreet}
      ${parentCompanyCity}, ${parentCompanyState}, ${parentCompanyZip}


      --BANK REFERENCES--

      Bank Reference #1:
      ${bankRefName1}
      ${bankRefAddress1}
      ${bankRefPhone1}

      Bank Reference #2:
      ${bankRefName2}
      ${bankRefAddress2}
      ${bankRefPhone2}

      Bank Reference #3:
      ${bankRefName3}
      ${bankRefAddress3}
      ${bankRefPhone3}

      --TRADE REFERENCES--

      Trade Reference #1:
      ${tradeRefCompany1}
      ${tradeRefContact1}
      ${tradeRefContactPhone1}
      ${tradeRefContactEmail1}
      ${tradeRefAddress1}
      Account Opened since: ${tradeRefAccountOpen1}

      Trade Reference #2:
      ${tradeRefCompany2}
      ${tradeRefContact2}
      ${tradeRefContactPhone2}
      ${tradeRefContactEmail2}
      ${tradeRefAddress2}
      Account Opened since: ${tradeRefAccountOpen2}

      Trade Reference #3:
      ${tradeRefCompany3}
      ${tradeRefContact3}
      ${tradeRefContactPhone3}
      ${tradeRefContactEmail3}
      ${tradeRefAddress3}
      Account Opened since: ${tradeRefAccountOpen3}

      AGREEEMENT SIGNED: ${parseDateShort(today)}
    `
    const taskJSON = {
      taskType: "Business Credit Application",
      comments: message
    }
    createTask(taskJSON).then((val) => {
      fetchAccount();
      setSuccessToast(true)
      setShow(false);
    });
  }

  const businessTypeOpts = [
    "Sole Proprietorship",
    "Partnership",
    "Limited Partnership",
    "Corporation",
    "LLC",
    "Nonprofit Organization",
    "Cooperative"
  ];
  const businessFormOpts = [
    "Corporation",
    "Partnership",
    "Proprietorship"
  ]

  if (account.canRequestCreditLine){
    return (
      <Fragment>
        <button className="sav-btn__orange--text" style={{width:"100%", padding: 0}} onClick={() => setShow(true)}>
          Request Open Terms
        </button>
        <Modal show={show} onHide={() => setShow(false)} animation={false} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Business Credit Application
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form onSubmit={handleSubmit} noValidate validated={validated} className="sav-form">

                <SectionHeader>Account Default Information</SectionHeader>

                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <SavFormInput value={account.name} label="Account Name" disabled/>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <SavFormInput value={account.dba} label="DBA" disabled/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={12}>
                   <SavFormInput value={defaultBillingAddr.addressStreet} label="Billing Address" disabled/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={3}>
                    <SavFormInput value={defaultBillingAddr.addressCity} label="Billing City" disabled/>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <SavFormInput value={defaultBillingAddr.addressState} label="Billing State" disabled/>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <SavFormInput value={defaultBillingAddr.addressZip} label="Billing Zip" disabled/>
                  </Form.Group>
                  <Form.Group as={Col} sm={3}>
                    <SavFormInput value={defaultBillingAddr.addressCountry} label="Billing Country" disabled/>
                  </Form.Group>
                </Form.Row>

                <hr />

                <SectionHeader>Company Information</SectionHeader>

                <Form.Row>
                  <Form.Group as={Col} sm={4}>
                    <SavFormDrop value={inBusinessSince} setValue={setInBusinessSince} label="In Business Since" opts={years.values} />
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                    <SavFormDrop value={businessType} setValue={setBusinessType} label="Type of Business" opts={businessTypeOpts} />
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                    <SavFormDrop value={businessForm} setValue={setBusinessForm} label="Legal Form of Business" opts={businessFormOpts} />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={12} className="text-center">
                    <Form.Label>Division/Subsidiary?</Form.Label>
                    <Form.Control as={SavCheckForm} checked={isDivision} click={() => setIsDivision(!isDivision)} />
                  </Form.Group>
                </Form.Row>

                <div style={isDivision ? null : hidden}>
                  <Form.Row>
                    <Form.Group as={Col} sm={8}>
                      <SavFormInput value={parentCompany} setValue={setParentCompany} label="Name of Parent Company" notRequired />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <SavFormDrop value={parentCompanyInBusinessSince} setValue={setParentCompanyInBusinessSince} label="In Business Since" opts={years.values} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} sm={9}>
                      <SavFormInput value={parentCompanyPrincipal} setValue={setParentCompanyPrincipal} label="Name of Company Principal Responsible for Business Transactions"  notRequired />
                    </Form.Group>
                    <Form.Group as={Col} sm={3}>
                      <SavFormInput value={parentCompanyPrincipalTitle} setValue={setParentCompanyPrincipalTitle} label="Title"  notRequired />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                     <SavFormInput value={parentCompanyStreet} setValue={setParentCompanyStreet} label="Street" notRequired />
                    </Form.Group>
                    <Form.Group as={Col} sm={3}>
                      <SavFormInput value={parentCompanyCity} setValue={setParentCompanyCity} label="City" notRequired />
                    </Form.Group>
                    <Form.Group as={Col} sm={2}>
                      <SavFormInput value={parentCompanyState} setValue={setParentCompanyState} label="State" notRequired />
                    </Form.Group>
                    <Form.Group as={Col} sm={3}>
                      <SavFormInput value={parentCompanyZip} setValue={setParentCompanyZip} label="Zip" notRequired />
                    </Form.Group>
                  </Form.Row>
                </div>
                <hr />

                <SectionHeader>Bank References</SectionHeader>
                <Form.Row>
                  <Form.Group as={Col} sm={4}>
                    <SavFormInput value={bankRefName1} setValue={setBankRefName1} label="Institution Name" />
                    <SavFormInputArea value={bankRefAddress1} setValue={setBankRefAddress1} label="Institution Address"/>
                    <SavFormInput value={bankRefPhone1} setValue={setBankRefPhone1} label="Institution Phone"/>
                  </Form.Group>

                  <Form.Group as={Col} sm={4} style={{borderLeft:"1px solid #b4b4b452", borderRight: "1px solid #b4b4b452"}}>
                    <SavFormInput value={bankRefName2} setValue={setBankRefName2} label="Institution Name" />
                    <SavFormInputArea value={bankRefAddress2} setValue={setBankRefAddress2} label="Institution Address"/>
                    <SavFormInput value={bankRefPhone2} setValue={setBankRefPhone2} label="Institution Phone"/>
                  </Form.Group>

                  <Form.Group as={Col} sm={4}>
                    <SavFormInput value={bankRefName3} setValue={setBankRefName3} label="Institution Name" />
                    <SavFormInputArea value={bankRefAddress3} setValue={setBankRefAddress3} label="Institution Address"/>
                    <SavFormInput value={bankRefPhone3} setValue={setBankRefPhone3} label="Institution Phone"/>
                  </Form.Group>
                </Form.Row>

                <hr />

                <SectionHeader>Trade References</SectionHeader>
                <Form.Row>
                  <Form.Group as={Col} sm={4}>
                    <SavFormInput value={tradeRefCompany1} setValue={setTradeRefCompany1} label="Company Name" setMargin />
                    <SavFormInput value={tradeRefContact1} setValue={setTradeRefContact1} label="Contact Name" setMargin />
                    <SavFormInput value={tradeRefContactPhone1} setValue={setTradeRefContactPhone1} label="Contact Phone" setMargin />
                    <SavFormInput value={tradeRefContactEmail1} setValue={setTradeRefContactEmail1} label="Contact Email" setMargin />
                    <SavFormInputArea value={tradeRefAddress1} setValue={setTradeRefAddress1} label="Address"/>
                    <SavFormDrop value={tradeRefAccountOpen1} setValue={setTradeRefAccountOpen1} label="Account Opened Since" opts={years.values} />
                  </Form.Group>

                  <Form.Group as={Col} sm={4} style={{borderLeft:"1px solid #b4b4b452", borderRight: "1px solid #b4b4b452"}}>
                    <SavFormInput value={tradeRefCompany2} setValue={setTradeRefCompany2} label="Company Name" setMargin />
                    <SavFormInput value={tradeRefContact2} setValue={setTradeRefContact2} label="Contact Name" setMargin />
                    <SavFormInput value={tradeRefContactPhone2} setValue={setTradeRefContactPhone2} label="Contact Phone" setMargin />
                    <SavFormInput value={tradeRefContactEmail2} setValue={setTradeRefContactEmail2} label="Contact Email" setMargin />
                    <SavFormInputArea value={tradeRefAddress2} setValue={setTradeRefAddress2} label="Address"/>
                    <SavFormDrop value={tradeRefAccountOpen2} setValue={setTradeRefAccountOpen2} label="Account Opened Since" opts={years.values} />
                  </Form.Group>

                  <Form.Group as={Col} sm={4}>
                    <SavFormInput value={tradeRefCompany3} setValue={setTradeRefCompany3} label="Company Name" setMargin />
                    <SavFormInput value={tradeRefContact3} setValue={setTradeRefContact3} label="Contact Name" setMargin />
                    <SavFormInput value={tradeRefContactPhone3} setValue={setTradeRefContactPhone3} label="Contact Phone" setMargin />
                    <SavFormInput value={tradeRefContactEmail3} setValue={setTradeRefContactEmail3} label="Contact Email" setMargin />
                    <SavFormInputArea value={tradeRefAddress3} setValue={setTradeRefAddress3} label="Address"/>
                    <SavFormDrop value={tradeRefAccountOpen3} setValue={setTradeRefAccountOpen3} label="Account Opened Since" opts={years.values} />
                  </Form.Group>
                </Form.Row>
                <hr />

                <SectionHeader>Electronic Signature/Agreement</SectionHeader>
                <div className="book f12">
                  I hereby certify that the information contained herein is complete and accurate. This information has been furnished with the understanding that it is to be used to determine the amount and conditions of the credit to be extended. Furthermore, I hereby authorize the financial institutions listed in this credit application to release necessary information to the company for which credit is being applied for in order to verify the information contained herein.
                </div>
                <Form.Row>
                  <Form.Group as={Col} xs={12} className="text-center">
                    <Form.Label>Check to Agree</Form.Label>
                    <Form.Control as={SavCheckForm} required checked={signed} click={() => setSigned(!signed)} isInvalid={!signed} feedback="bad" />
                  </Form.Group>
                </Form.Row>
                <div style={showWarning && !signed ? null : hidden} className="text-center m-t-10 red f12 med">
                  You must agree to the terms above.
                </div>

                <div className="text-center m-t-10 m-b-10 f12 med">
                  Savant's accounting team will respond to this request within 3-5 business days.
                </div>

                <div className="m-t-20 text-center">
                  <button className="sav-btn__orange--fill" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            </Container>
          </Modal.Body> 
        </Modal>
      </Fragment>
    )
  }
  return null;

}

export default RequestOpenTermsModal;
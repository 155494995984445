import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchAccount } from "../../../actions";
import VIRProgressChart from "../../../components/AccountCharts/VIRProgress";
import ProgramTable from "../../../components/AccountCharts/ProgramTable";
import {parseCurrency} from "../../../components/Utilities";

const Header = styled.div`
  font-family: "Gotham-Medium";
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-top:20px;
  font-size:20px;
`;

const DetailCard = styled.div`
  padding: 20px;
  font-size: 12px;
  min-height:255px;
`;

const DetailHeader = styled.span`
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Gotham-Medium";
`;
const DetailLabel = styled.div`
  font-size: 12px;
  color: #b4b4b4;
  margin-top: 12px;
`;

const Congrats = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 30px 0px;
  text-transform: uppercase;
  color: #ff5f00;
`;

const hide = {display:'none'};

const VIRPerformance = props => {
  const { account } = props;

  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);

  const today = new Date();
  const quarter = Math.floor(today.getMonth() / 3) + 1;
  const year = today.getFullYear();
  let currentQuarter = _.find(account.salesProgramResults, (q) => q.programQuarter === quarter && q.year === year );
  let fakeQuarter = {
    year: year,
    virPercentEarned: 0,
    virNextTierPercent: 0,
    virNextTierGoal: 0,
    virEarned: 0,
    validTier: null,
    type: 'VIR',
    tierEarned: null,
    summaryStart: '2021-04-01',
    summaryEnd: '2021-06-30',
    revenuePriorYear: 0,
    revenue: 0,
    programQuarter: quarter,
    program: 'VIR 2021',
    message: '$25,000 to go till next tier!',
    leadsWithSavant: null,
    isBeta: null,
    isActive: false,
    inGoodStanding: false,
    earnedAmbassador: null,
    coreTrainings: null,
    adoptionUiHstLicCtrl: 0,
    adoptionQuarters: null,
    adoptionELSClimate: 0,
    adoptionAudioVideoSpeaker: 0
  }
  if (!currentQuarter){
    currentQuarter = fakeQuarter;
  }

  useEffect(() => {
    if (account){

      const temp1 = _.find(account.salesProgramResults, (r) => r.type === "VIR" && r.programQuarter === 1 && r.year === year);
      const temp2 = _.find(account.salesProgramResults, (r) => r.type === "VIR" && r.programQuarter === 2 && r.year === year);
      const temp3 = _.find(account.salesProgramResults, (r) => r.type === "VIR" && r.programQuarter === 3 && r.year === year);
      const temp4 = _.find(account.salesProgramResults, (r) => r.type === "VIR" && r.programQuarter === 4 && r.year === year);

      setQ1(temp1 || {
        programQuarter: 1,
        virEarned: 0,
        virPercentEarned: 0,
        virNextTierPercent: 0,
        virNextTierGoal: 0
      });
      setQ2(temp2 || {
        programQuarter: 2,
        virEarned: 0,
        virPercentEarned: 0,
        virNextTierPercent: 0,
        virNextTierGoal: 0
      });
      setQ3(temp3 || {
        programQuarter: 3,
        virEarned: 0,
        virPercentEarned: 0,
        virNextTierPercent: 0,
        virNextTierGoal: 0
      });
      setQ4(temp4 || {
        programQuarter: 4,
        virEarned: 0,
        virPercentEarned: 0,
        virNextTierPercent: 0,
        virNextTierGoal: 0
      });
    }

  },[account]);



  const renderVIRProgress = () => {
    if (!_.isEmpty(account)) {
      return <VIRProgressChart account={account} />;
    }
    return null;
  };

  if (!_.isEmpty(account) && q1 && q2 && q3 && q4) {
    return (
      <Fragment>
        {/* <Header>
          {currentQuarter.virAmbassadorQualified ? "Ambassador VIR Program" : "VIR Program"}
        </Header> */}
          <Row>
            <Col xs={12}>{renderVIRProgress()}</Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col sm={3}>
              <DetailCard>
                <DetailHeader style={quarter === 1 ? {color:'#ff5f00'} : null}>Q1</DetailHeader>
                <br />
                <DetailLabel>VIR Earned</DetailLabel>
                {parseCurrency(q1.virEarned, 2)}
                <DetailLabel>VIR Percentage</DetailLabel>
                {q1.virPercentEarned}%
                <DetailLabel style={quarter  !== 1 ? hide : null}>Next Tier Amount</DetailLabel>
                {quarter > 1 ? null : `${parseCurrency(q1.virNextTierGoal, 2)}`}
                <DetailLabel style={quarter !== 1 ? hide : null}>Next Tier Percentage</DetailLabel>
                {quarter > 1 ? null : `${q1.virNextTierPercent}%`}
              </DetailCard>
            </Col>
            <Col sm={3}>
              <DetailCard>
                <DetailHeader style={quarter === 2 ? {color:'#ff5f00'} : null}>Q2</DetailHeader>
                <br />
                <DetailLabel>VIR Earned</DetailLabel>
                {quarter < 2 ?  '---' : parseCurrency(q2.virEarned, 2)}
                <DetailLabel>VIR Percentage</DetailLabel>
                {q2.virPercentEarned}%
                <DetailLabel style={quarter !== 2 ? hide : null}>Next Tier Amount</DetailLabel>
                {quarter !==2 ? null : `${parseCurrency(q2.virNextTierGoal, 2)}`}
                <DetailLabel style={quarter !== 2 ? hide : null}>Next Tier Percentage</DetailLabel>
                {quarter !== 2 ? null : `${q2.virNextTierPercent}%`}
              </DetailCard>
            </Col>
            <Col sm={3}>
              <DetailCard>
                <DetailHeader style={quarter === 3 ? {color:'#ff5f00'} : null}>Q3</DetailHeader>
                <br />
                <DetailLabel>VIR Earned</DetailLabel>
                {quarter < 3 ? '---' : `${parseCurrency(q3.virEarned, 2)}`}
                <DetailLabel>VIR Percentage</DetailLabel>
                {quarter < 3 ? '---' : `${q3.virPercentEarned}%`}
                <DetailLabel style={quarter !== 3 ? hide : null}>Next Tier Amount</DetailLabel>
                {quarter !== 3 ? null : `${parseCurrency(q3.virNextTierGoal, 2)}`}
                <DetailLabel style={quarter !== 3 ? hide : null}>Next Tier Percentage</DetailLabel>
                {quarter !== 3 ? null : `${q3.virNextTierPercent}%`}
              </DetailCard>
            </Col>
            <Col sm={3}>
              <DetailCard>
                <DetailHeader style={quarter === 4 ? {color:'#ff5f00'} : null}>Q4</DetailHeader>
                <br />
                <DetailLabel>VIR Earned</DetailLabel>
                {quarter < 4 ? '---' : `${parseCurrency(q4.virEarned, 2)}`}
                <DetailLabel>VIR Percentage</DetailLabel>
                {quarter < 4 ? '---' : `${q4.virPercentEarned}%`}
                <DetailLabel style={quarter !== 4 ? hide : null}>Next Tier Amount</DetailLabel>
                {quarter !== 4 ? null : `${parseCurrency(q4.virNextTierGoal, 2)}`}
                <DetailLabel style={quarter !== 4 ? hide : null}>Next Tier Percentage</DetailLabel>
                {quarter !== 4 ? null : `${q4.virNextTierPercent}%`}
              </DetailCard>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Congrats>
                {currentQuarter.message}
              </Congrats>
            </Col>
          </Row>
          {/* <div className="text-center light-gray m-t-20 f12">
            *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
          </div> */}
        <ProgramTable />
      </Fragment>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, { fetchAccount })(VIRPerformance);
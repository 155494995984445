import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import { fetchRMA } from "../../actions";
import { SubHeader, SavRow, SavSearch } from "../../components/Styled";
import SavPagination from "../../components/SavPagination";
import SavColumnSort from "../../components/SavColumnSort";
import SavAlphabetFilter from "../../components/SavAlphabetFilter";
import SavDrop from "../../components/SavDrop";

const FilterOpts = ["All", "Open", "Closed", "Cancelled"];

const RMAs = props => {
  const { rma, fetchRMA, loading } = props;

  const [rmaList, setRMAList] = useState([]);
  const [rmaFilter, setRMAFilter] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [alphabetChar, setAlphabetChar] = useState("All");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState("Open");
  const [isloading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (_.isEmpty(rma)) {
      fetchRMA();
    }
    setRMAList(rma);
    if (searchString !== "") {
      setSearchString("");
    }
  }, [rma]);

  const handleClearFilters = () => {
    setAlphabetChar("All");
    setSearchString("");
  };

  useEffect(() => {
    if (rmaList) {
      let index = 0;
      const tempRMAs = [];
      for (index = 0; index < rmaList.length; index += pageSize) {
        let chunk = rmaList.slice(index, index + pageSize);
        tempRMAs.push(chunk);
      }
      setRMAFilter(tempRMAs);
    }
  }, [rmaList, pageSize]);

  useEffect(() => {
    if (!_.isEmpty(rma)) {
      setCurrentPage(1);
      let tempRMAs = rma.filter(
        rmas =>
          String(rmas.project).includes(searchString) ||
          String(rmas.rmaNumber).includes(searchString) ||
          String(rmas.status).includes(searchString)
      );
      setRMAList(tempRMAs);
    }
    if (searchString === "") {
      setRMAList(rma);
    }
  }, [searchString]);

  useEffect(() => {
    if (!_.isEmpty(rma)) {
      if (alphabetChar !== "All") {
        setCurrentPage(1);
        let tempRMAs = rma.filter(
          contact =>
            contact.lastName.startsWith(alphabetChar) ||
            contact.lastName.startsWith(alphabetChar.toUpperCase())
        );
        setRMAList(tempRMAs);
      } else {
        setRMAList(rma);
      }
    }
  }, [alphabetChar]);

  useEffect(() => {
    if (!_.isEmpty(rma)) {
      if (activeFilter !== "All") {
        setCurrentPage(1);
        if (activeFilter === "Open") {
          let tempRMAs = rma.filter(rmas => rmas.status === "Open");
          setRMAList(tempRMAs);
        } else if (activeFilter === "Closed") {
          let tempRMAs = rma.filter(rmas => rmas.status === "Closed");
          setRMAList(tempRMAs);
        } else if (activeFilter === "Cancelled") {
          let tempRMAs = rma.filter(rmas => rmas.status === "Cancelled");
          setRMAList(tempRMAs);
        }
      } else {
        setRMAList(rma);
      }
    }
  }, [activeFilter, rma]);

  const renderRMAS = () => {
    if (!_.isEmpty(rmaFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > rmaFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return rmaFilter[page].map(rmas => {
        return (
          <SavRow key={rmas.sfid}>
            <Col sm={2} className="truncate">
              {rmas.rmaNumber}
            </Col>
            <Col sm={2} className="truncate">
              {rmas.expirationDate}
            </Col>
            <Col sm={2} className="truncate">
              {rmas.status}
            </Col>
            <Col sm={2} className="truncate">
              {rmas.type}
            </Col>
            <Col sm={1} className="truncate">
              {rmas.countWaitingReturn}
            </Col>
            <Col sm={1} className="truncate">
              {rmas.caseId === null ? "NA" : rmas.caseId}
            </Col>
            <Col sm={2} className="truncate">
              {rmas.project}
            </Col>
          </SavRow>
        );
      });
    }
    if (rmaList) {
      return (
        <Row>
          <Col
            xs={12}
            style={
              rmaList.length > 0
                ? { display: "none" }
                : { textAlign: "center", marginTop: 30 }
            }
          >
            No Results found.
            <br />
            <br />
            <button
              className="sav-btn__orange--text"
              onClick={() => handleClearFilters()}
            >
              Clear Filters
            </button>
          </Col>
        </Row>
      );
    }
  };

  if (rmaList) {
    return (
      <div>
        <SavBanner text="MY RMAS" padding="40px" />
        <SubHeader>RMA</SubHeader>
        <Container>
          <Row style={{ alignItems: "center" }}>
            <Col sm={6}>
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
              <br />
              <br />
              <SavAlphabetFilter
                click={setAlphabetChar}
                activeChar={alphabetChar}
              />
            </Col>
            <Col sm={6}>
              <Row style={{ alignItems: "center" }}>
                <Col
                  xs={2}
                  sm={6}
                  style={{ textAlign: "right", paddingRight: 0 }}
                >
                  <span className="light-gray f12 med">FILTER</span>
                </Col>
                <Col xs={10} sm={6}>
                  <SavDrop
                    opts={FilterOpts}
                    val={activeFilter}
                    click={setActiveFilter}
                    style={{
                      border: "1px solid #b4b4b4",
                      backgroundColor: "#fff"
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <SavColumnSort
              data={rmaList}
              setData={setRMAList}
              columns={[
                { text: "RMA Number", size: 2, value: "rmaNumber" },
                { text: "Due Date", size: 2, value: "expirationDate" },
                { text: "Status", size: 2, value: "status" },
                { text: "Type", size: 2, value: "type" },
                {
                  text: "Items Awaiting Return",
                  size: 1,
                  value: "countWaitingReturn"
                },
                {
                  text: "Originating Case",
                  size: 1,
                  value: "caseId"
                },
                { text: "Project", size: 2, value: "project" }
              ]}
            />
          </div>
          {renderRMAS()}
          <SavPagination
            data={rmaFilter}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataLength={rmaList.length}
          />
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    rma: state.rma.data
  };
};
export default connect(mapStateToProps, { fetchRMA })(RMAs);

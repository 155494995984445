import React from "react";
import styled from "styled-components";

const LetterSpan = styled.span`
  font-size: 12px;
  color: #9b9b9b;
  font-family: "Gotham-Medium";
  padding: 0px 3px;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: #ff5f00;
  }
`;

const SavAlphabetFilter = props => {
  const { style, click, activeChar } = props;
  const letters = [];

  for (let num = 0; num < 26; num++) {
    const letter = (num + 10).toString(36);
    letters.push(letter);
  }
  letters.push("All");

  return (
    <div style={style ? style : null}>
      {letters.map(l => {
        return (
          <LetterSpan
            key={l}
            onClick={() => click(l)}
            className={activeChar === l ? "oj" : null}
          >
            {l}
          </LetterSpan>
        );
      })}
    </div>
  );
};

export default SavAlphabetFilter;

import {React, Fragment} from 'react';
import _ from "lodash";
//Normalizes search string and object fields passed in for better search results. Intended to be used with Array.filter()
export const eliteSearchAlgorithm = (searchString, obj) => {
  const normalize = (data) => {
    return String(data)
      .toLowerCase()
      .trim()
      .replace(/-|_|#|\s|\.|\,|\?|%20/gi, "");
  };

  let predicate = false;
  const searchReg = normalize(searchString);

  Object.keys(obj).forEach((f) => {
    const fieldReg = normalize(obj[f]);
    if (fieldReg.includes(searchReg)) {
      predicate = true;
    }
  });

  return predicate;
};

export const chunkList = (arr, pageSize) => {
  let index = 0;
  const tempArr = [];
  for (index = 0; index < arr.length; index += pageSize) {
    let chunk = arr.slice(index, index + pageSize);
    tempArr.push(chunk);
  }
  return tempArr;
}

// Return rich text field to HTML. Must be invoked within a dangerouslySetInnerHTML attribute
// **EXAMPLE**
// <div dangerouslySetInnerHTML={createMarkup(article.body)} />
export const createMarkup = (richText) => {
  if (richText) {
    return { __html: richText };
  }
};

export const parseDate = (date) => {
  return new Date(JSON.parse(JSON.stringify(date))).toLocaleString('en-US', {hour:'2-digit', minute:'2-digit',year: 'numeric', month: 'short', day: 'numeric'});
};
export const parseDateInt = (date) => {
  return new Date(JSON.parse(JSON.stringify(date))).toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric',timeZone: 'UTC'});
};
export const parseDateShort = (date) => {
  if (date){
    return new Date(JSON.parse(JSON.stringify(date))).toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
  }
  return null;
};

export const parseCurrency = (number, fractionDigits) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractionDigits || 2
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  });
  return formatter.format(number);
}
export const parseCurrencyInput = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    maximumFractionDigits: 2
  });
  return formatter.format(number);
}

export const phoneOutput = (phoneNumber) => {
  if (!_.isEmpty(phoneNumber)){
    if (phoneNumber.startsWith('+1') && phoneNumber.length === 12){
      const number = phoneNumber.slice(2, phoneNumber.length);
      const mobileMatch = number.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (mobileMatch){
        const finalNumber = `(${mobileMatch[2]}) ${mobileMatch[3]}-${mobileMatch[4]}`;
        return finalNumber;
      }
      
    }
    return phoneNumber
  }
  return null;
}

export const kFormatter = (num) => {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export const importImages = (r) => {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

export const paginate = (currentPage, setCurrentPage, data) => {
  let page = currentPage - 1;
  if (page < 1) {
    page = 0;
  }
  if (page > data.length) {
    page = 0;
    setCurrentPage(1);
  }
  return page;
}


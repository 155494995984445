import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import moment from 'moment';
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import {visibilitySelector, createLoadingSelector} from "../../../selectors";
import { SubHeader, ZeroStateText } from "../../../components/Styled";
import SubscriptionCards from "./SubscriptionCards";
import SubscriptionQuarters from "./SubscriptionQuarters";
import SubscriptionHistory from "./SubscriptionHistory";
import SubscriptionHomes from "./SubscriptionHomes";
import { fetchHome, fetchHomes, fetchHomeHistory } from "../../../actions";

const hidden = {
  display: 'none'
}

const Component = ({subscriptions, account, contacts, location, fetchSubs, fetchingSubs, homes, fetchHomes, fetchingHomes, fetchHome, fetchHomeHistory}) => {
  const [totalSales, setTotalSales] = useState(0);
  const [virInc, setVirInc] = useState(0);
  const [virQ1, setVirQ1] = useState({revenue: 0, virEarned: 0, virPercentEarned: 0});
  const [virQ2, setVirQ2] = useState({revenue: 0, virEarned: 0, virPercentEarned: 0});
  const [virQ3, setVirQ3] = useState({revenue: 0, virEarned: 0, virPercentEarned: 0});
  const [virQ4, setVirQ4] = useState({revenue: 0, virEarned: 0, virPercentEarned: 0});
  const [virQ1Network, setVirQ1Network] = useState({});
  const [virQ2Network, setVirQ2Network] = useState({});
  const [virQ3Network, setVirQ3Network] = useState({});
  const [virQ4Network, setVirQ4Network] = useState({});
  const [virQ1Data, setVirQ1Data] = useState({});
  const [virQ2Data, setVirQ2Data] = useState({});
  const [virQ3Data, setVirQ3Data] = useState({});
  const [virQ4Data, setVirQ4Data] = useState({});
  const [activeSubs, setActiveSubs] = useState([]);
  const [expiredSubs, setExpiredSubs] = useState([]);
  const [inactiveHomes, setInactiveHomes] = useState([]);
  const [activeEssentials, setActiveEssentials] = useState([]);
  const [activeNetwork, setActiveNetwork] = useState([]);
  const [activeLegacy, setActiveLegacy] = useState([])
  const [subHistory, setSubHistory] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [subsTotal, setSubsTotal] = useState(0);

  const showPage = useSelector(visibilitySelector('account_subscriptions'));


  useEffect(() => {
    if (!fetchingSubs){
      fetchSubs();
    }
  },[]);
  useEffect(() => {
    if (!_.isEmpty(subscriptions)){
      const subsTemp = subscriptions.reduce((acc, sub) => {
        const { homeId, homeName, hostProfileModel} = sub;

        if (!acc[homeId]) {
          acc[homeId] = {homeName, hostProfileModel, homes: []};
        }
        acc[homeId].homes.push(sub);

        return acc;
      }, {});

      let subsCounter = 0;

      subscriptions.forEach((s) => {
        subsCounter += s.amount;
      });
      setSubsTotal(subsCounter);

      const subsList = [];
      const expiredTemp = [];
      const activeTemp = [];
      const essentialsTemp = [];
      const networkTemp = [];
      const legacyTemp = [];
      Object.keys(subsTemp).forEach((s) => {
        const sub = subsTemp[s];

        const foundActiveEssentials = _.find(sub.homes, (h) => moment(new Date()).isBetween(h.startDate, h.endDate) && (h.isEssentials));
        const foundActiveNetwork = _.find(sub.homes, (h) => moment(new Date()).isBetween(h.startDate, h.endDate) && h.isNetwork);

        const foundExpiredEssentials = _.find(sub.homes, (h) => !moment(new Date()).isBetween(h.startDate, h.endDate) && (h.isEssentials) && h.startDate && h.endDate);
        const foundExpiredNetwork = _.find(sub.homes, (h) => !moment(new Date()).isBetween(h.startDate, h.endDate) && h.isNetwork && h.startDate && h.endDate);

        const foundDomainChange = _.find(sub.homes, (h) => moment(new Date()).isBetween(h.startDate, h.endDate) && h.newDomainOwner);

        const foundActiveLegacy = _.find(sub.homes, (h) => moment(new Date()).isBetween(h.startDate, h.endDate, undefined, '[)') && h.isLegacy);

        sub.isActiveEssentials = false;
        sub.isActiveNetwork = false;
        sub.renewDateEssentials = null;
        sub.renewDateNetwork = null;
        sub.startDateEssentials = null;
        sub.startDateNetwork = null;
        sub.hasExpiredNetwork = false;
        sub.hasExpiredEssentials = false;
        sub.networkMonthly = 0;
        sub.essentialsMonthly = 0;
        sub.versionName = null;
        sub.hostType = null;
        sub.homeId = null;
        sub.isLegacy = false;
        sub.newDomain = false;
        // sub.expiryDateEssentials = null;
        // sub.expiryDateNetwork = null;

        if (foundActiveEssentials){
          sub.isActiveEssentials = foundActiveEssentials.isEssentials;
          sub.isActiveNetwork = foundActiveEssentials.isNetwork;
          sub.startDateEssentials = foundActiveEssentials.startDate;
          sub.renewDateEssentials = foundActiveEssentials.endDate;
          sub.essentialsMonthly = foundActiveEssentials.amount;
          sub.versionName = foundActiveEssentials.versionName;
          sub.hostType = foundActiveEssentials.hostType;
          sub.homeId = foundActiveEssentials.homeId;
          sub.isLegacy = foundActiveEssentials.isLegacy
        }
        if (foundActiveNetwork){
          sub.isActiveEssentials = foundActiveNetwork.isEssentials;
          sub.isActiveNetwork = foundActiveNetwork.isNetwork;
          sub.startDateNetwork = foundActiveNetwork.startDate;
          sub.renewDateNetwork = foundActiveNetwork.endDate;
          sub.networkMonthly = foundActiveNetwork.amount;
          sub.versionName = foundActiveNetwork.versionName;
          sub.hostType = foundActiveNetwork.hostType;
          sub.homeId = foundActiveNetwork.homeId;
          sub.isLegacy = foundActiveNetwork.isLegacy;
        }
        if (foundExpiredEssentials && !foundActiveEssentials){
          sub.isActiveEssentials = false;
          sub.hasExpiredEssentials = true;
          sub.startDateEssentials = foundExpiredEssentials.startDate;
          sub.renewDateEssentials = foundExpiredEssentials.endDate;
          sub.essentialsMonthly =  -Math.abs(foundExpiredEssentials.amount);
          sub.versionName = foundExpiredEssentials.versionName;
          sub.hostType = foundExpiredEssentials.hostType;
          sub.homeId = foundExpiredEssentials.homeId;
          sub.isLegacy = foundExpiredEssentials.isLegacy;
        }
        if (foundExpiredNetwork && !foundActiveNetwork){
          sub.isActiveNetwork = false;
          sub.hasExpiredNetwork = true;
          sub.startDateNetwork = foundExpiredNetwork.startDate;
          sub.renewDateNetwork = foundExpiredNetwork.endDate;
          sub.networkMonthly =  -Math.abs(foundExpiredNetwork.amount);
          sub.versionName = foundExpiredNetwork.versionName;
          sub.hostType = foundExpiredNetwork.hostType;
          sub.homeId = foundExpiredNetwork.homeId;
          sub.isLegacy = foundExpiredNetwork.isLegacy;
        }
        if (foundActiveLegacy && !foundActiveEssentials && !foundActiveNetwork){
          sub.isActiveEssentials = false
          sub.hasExpiredEssentials = false;
          sub.isActiveNetwork = false
          sub.hasExpiredNetwork = false;
          sub.startDateNetwork = foundActiveLegacy.startDate;
          sub.renewDateNetwork = foundActiveLegacy.endDate;
          sub.startDateEssentials = foundActiveLegacy.startDate;
          sub.renewDateEssentials = foundActiveLegacy.endDate;
          sub.versionName = foundActiveLegacy.versionName;
          sub.hostType = foundActiveLegacy.hostType;
          sub.homeId = foundActiveLegacy.homeId;
          sub.isLegacy = foundActiveLegacy.isLegacy;
        }

        if (sub.isActiveEssentials || sub.isActiveNetwork || sub.isLegacy){
          activeTemp.push(sub);
        }
        if (sub.hasExpiredEssentials || sub.hasExpiredNetwork){
          expiredTemp.push(sub)
        }

        if (sub.isActiveEssentials){
          essentialsTemp.push(sub)
        }
        if (sub.isActiveNetwork){
          networkTemp.push(sub);
        }
        if (sub.isLegacy && !sub.isActiveNetwork && !sub.isActiveEssentials){
          legacyTemp.push(sub);
        }
       
      });

      setActiveLegacy(legacyTemp);
      setExpiredSubs(expiredTemp);
      setActiveSubs(activeTemp);
      setSubHistory(subsList);
      setActiveEssentials(essentialsTemp);
      setActiveNetwork(networkTemp);
    }
  },[subscriptions]);

  useEffect(() => {
    if (!_.isEmpty(account)){
      if (!fetchingHomes && _.isEmpty(homes)){
        fetchHomes(account.scsDomainId)
      }
      const temp = _.filter(account.salesProgramResults, (p) => p.type === 'Subscription VIR');
      let salesTemp = 0;
      let virTemp = 0;
      const date = new Date();
      const year = date.getFullYear();
      const quarter = Math.floor(date.getMonth() / 3) + 1;

      temp.forEach((t) => {
        salesTemp += t.revenue;
        virTemp += t.virEarned
      });
      setTotalSales(salesTemp);
      setVirInc(virTemp);

      const data1 = [];
      const data2 = [];
      const data3 = [];
      const data4 = [];
      const defaultObj = {revenue: 0, virEarned: 0, virPercentEarned: 0, summaryStart: '2023-01-01'}

      const q1Result = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 1 && p.subscriptionType === 'Essential');
      const q2Result = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 2 && p.subscriptionType === 'Essential');
      const q3Result = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 3 && p.subscriptionType === 'Essential');
      const q4Result = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 4 && p.subscriptionType === 'Essential');
      const q1ResultNetwork = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 1 && p.subscriptionType === 'Network');
      const q2ResultNetwork = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 2 && p.subscriptionType === 'Network');
      const q3ResultNetwork = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 3 && p.subscriptionType === 'Network');
      const q4ResultNetwork = _.find(account.salesProgramResults, (p) => p.type === 'Subscription VIR' && p.year === year && p.programQuarter === 4 && p.subscriptionType === 'Network');
      setVirQ1(q1Result || defaultObj);
      setVirQ2(q2Result || defaultObj);
      setVirQ3(q3Result || defaultObj);
      setVirQ4(q4Result || defaultObj);
      setVirQ1Network(q1ResultNetwork || defaultObj);
      setVirQ2Network(q2ResultNetwork || defaultObj);
      setVirQ3Network(q3ResultNetwork || defaultObj);
      setVirQ4Network(q4ResultNetwork || defaultObj);

      const allDataTemp = [];

      account.salesProgramData.forEach((p) => {
        const obj = {
          name: `${p.month} '${p.year.toString().substr(-2)}`,
          salesEssential: p.initialEssentialSales,
          salesNetwork: p.initialNetworkSales,
          salesVoice: p.initialVoiceSales,
          renewalEssential: p.renewalEssentialSales,
          renewalNetwork: p.renewalNetworkSales,
          expiredNetwork: getExpiredSubs(p.periodDate, 'network'),
          expiredEssentials: getExpiredSubs(p.periodDate, 'essentials'),
          year: p.year,
        }
        const startDate = moment("2022-12-31");
        const endDate = moment("2025-12-31");
        const dataDate = moment(p.periodDate);
        if (dataDate.isBetween(startDate, endDate)){
          allDataTemp.push(obj);
        }
      });
      if (q1Result){
       
      }
      account.salesProgramData.forEach((p) => {
        const obj = {
          name: `${getMonthName(p.month)}`,
          salesEssential: p.initialEssentialSales,
          salesNetwork: p.initialNetworkSales,
          virEssentials: (p.initialEssentialSales + p.renewalEssentialSales) * (virQ1.virPercentEarned / 100),
          virNetwork: (p.initialNetworkSales + p.renewalNetworkSales) * (virQ1.virPercentEarned / 100) 

        }
        // const startDate = moment(virQ1.summaryStart).subtract(1, 'months');
        // const endDate = moment(virQ1.summaryEnd);
        const startDate = moment('2023-12-31');
        const endDate = moment('2024-04-01');
        const dataDate = moment(p.periodDate);
        if (dataDate.isBetween(startDate, endDate)){
          data1.push(obj);
        }
      });
      account.salesProgramData.forEach((p) => {
        const obj = {
          name: `${getMonthName(p.month)}`,
          salesEssential: p.initialEssentialSales,
          salesNetwork: p.initialNetworkSales,
          salesVoice: p.initialVoiceSales,
          virEssentials: (p.initialEssentialSales + p.renewalEssentialSales) * (virQ2.virPercentEarned / 100),
          virNetwork: (p.initialNetworkSales + p.renewalNetworkSales) * (virQ2.virPercentEarned / 100) 
        }
        const startDate = moment('2024-03-01');
        const endDate = moment('2024-06-30');
        const dataDate = moment(p.periodDate);
        if (dataDate.isBetween(startDate, endDate)){
          data2.push(obj);
        }
      });
      account.salesProgramData.forEach((p) => {
        const obj = {
          name: `${getMonthName(p.month)}`,
          salesEssential: p.initialEssentialSales,
          salesNetwork: p.initialNetworkSales,
          salesVoice: p.initialVoiceSales,
          virEssentials: (p.initialEssentialSales + p.renewalEssentialSales) * (virQ3.virPercentEarned / 100),
          virNetwork: (p.initialNetworkSales + p.renewalNetworkSales) * (virQ3.virPercentEarned / 100) 
        }
        const startDate = moment('2024-06-01');
        const endDate = moment('2024-09-30');
        const dataDate = moment(p.periodDate);
        if (dataDate.isBetween(startDate, endDate)){
          data3.push(obj);
        }
      });
      account.salesProgramData.forEach((p) => {
        const obj = {
          name: `${getMonthName(p.month)}`,
          salesEssential: p.initialEssentialSales,
          salesNetwork: p.initialNetworkSales,
          salesVoice: p.initialVoiceSales,
          virEssentials: (p.initialEssentialSales + p.renewalEssentialSales) * (virQ4.virPercentEarned / 100),
          virNetwork: (p.initialNetworkSales + p.renewalNetworkSales) * (virQ4.virPercentEarned / 100) 
        }
        const startDate = moment('2024-09-01');
        const endDate = moment('2024-12-31');
        const dataDate = moment(p.periodDate);
        if (dataDate.isBetween(startDate, endDate)){
          data4.push(obj);
        }
      });
      setVirQ1Data(data1);
      setVirQ2Data(data2);
      setVirQ3Data(data3);
      setVirQ4Data(data4);
      setHistoryData(allDataTemp);
    }
  },[account, subscriptions, location]);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', { month: 'short' });
  }
  const getExpiredSubs = (periodDate, subType) => {
    if (!_.isEmpty(subHistory)){
      if (subType === 'network'){
        const expiredNetwork = _.find(subHistory, (s) => s.hasExpiredNetwork && moment(s.renewDateNetwork).isBetween(moment(periodDate).subtract(1, 'days'), moment(periodDate).add(1, 'months')));
        if (expiredNetwork){
          return -Math.abs(expiredNetwork.networkMonthly)
        }
      }
      if (subType === 'essentials'){
        
        const expiredEssentials = _.find(subHistory, (s) => s.hasExpiredEssentials && moment(s.renewDateEssentials).isBetween(moment(periodDate).subtract(1, 'days'), moment(periodDate).add(1, 'months')));
        if (expiredEssentials){
          return -Math.abs(expiredEssentials.essentialsMonthly)
        }
      }
    }
    return 0;
  }

  if (fetchingSubs || fetchingHomes){
    return (
      <Container>
        <Row style={{marginTop: 40}}>
          <Col md={4}>
            <Skeleton count={2} height={160} />
          </Col>
          <Col md={4}>
            <Skeleton count={2} height={160} />
          </Col>
          <Col md={4}>
            <Skeleton count={2} height={160} />
          </Col>
        </Row>
        <Row style={{marginTop: 40}}>
          <Col xs={12}>
            <Skeleton count={20} height={50} />
          </Col>
        </Row>
      </Container>
    )
  }

  if (!fetchingSubs && showPage && !_.isEmpty(account)){
    return (
      <div style={{backgroundColor: '#f8f8f8', minHeight: 800, paddingBottom: 200}}>
        <Container>
          <SubHeader>Subscriptions</SubHeader>

          <SubscriptionCards
            account={account}
            activeEssentials={activeEssentials}
            activeNetwork={activeNetwork}
            activeLegacy={activeLegacy}
            expiredSubs={expiredSubs}
            totalSales={totalSales}
            virInc={virInc}
          />

          <SubscriptionQuarters
            account={account}
            virQ1={virQ1}
            virQ1Network={virQ1Network}
            virQ1Data={virQ1Data}
            virQ2={virQ2}
            virQ2Network={virQ2Network}
            virQ2Data={virQ2Data}
            virQ3={virQ3}
            virQ3Network={virQ3Network}
            virQ3Data={virQ3Data}
            virQ4={virQ4}
            virQ4Network={virQ4Network}
            virQ4Data={virQ4Data}
          />

          <SubscriptionHistory
            account={account}
            historyData={historyData}
            virQ1={virQ1}
            virQ1Network={virQ1Network}
            virQ1Data={virQ1Data}
            virQ2={virQ2}
            virQ2Network={virQ2Network}
            virQ2Data={virQ2Data}
            virQ3={virQ3}
            virQ3Network={virQ3Network}
            virQ3Data={virQ3Data}
            virQ4={virQ4}
            virQ4Network={virQ4Network}
            virQ4Data={virQ4Data}
          />
          <SubscriptionHomes
            account={account}
            contacts={contacts}
            activeSubs={activeSubs}
            expiredSubs={expiredSubs}
            homes={homes}
            fetchHome={fetchHome}
            fetchHomeHistory={fetchHomeHistory}
            virInc={virInc}
          />

        </Container>
        <div className="text-center light-gray m-t-20 f12">
          *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
        </div>
        <div className="text-center oj m-t-5 f12">
          <a className="oj u onHov" href="https://savant.com/business-subscription-terms" target="_blank" rel="noreferrer">Business Subscription Terms</a>
        </div>
      </div>
    );
  }

  if (!fetchingSubs && !showPage){
    return (
      <ZeroStateText>
        You do not have permission to view this page.
      </ZeroStateText>
    )
  }
  return null;
  
};

const fetchingHomes = createLoadingSelector(["FETCH_HOMES"]);

const mapStateToProps = state => {
  return {
    homes: state.homes,
    fetchingHomes: fetchingHomes(state)
  };
};
export default connect(mapStateToProps, {fetchHomes, fetchHome, fetchHomeHistory})(Component);

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import _ from "lodash";

const ColumnHeader = styled.span`
  font-size: 10px;
  color: #9b9b9b;
  text-transform: uppercase;
  cursor: pointer;
`;

const SavColumnSort = props => {
  const { data, setData, columns } = props;

  const [order, setOrder] = useState("asc");

  const handleColumnSort = predicate => {
    let tempOrder;
    if (order === "asc") {
      tempOrder = "desc";
    } else {
      tempOrder = "asc";
    }
    setOrder(tempOrder);
    const tempSerials = _.orderBy(data, [predicate], [tempOrder]);
    setData(tempSerials);
  };
  const handleColumnSortMulti = predicate => {
    let tempOrder;
    if (order === "asc") {
      tempOrder = "desc";
    } else {
      tempOrder = "asc";
    }
    setOrder(tempOrder);
    const tempSerials = _.orderBy(data, predicate, [tempOrder, tempOrder, tempOrder, tempOrder]);
    setData(tempSerials);
  };

  const renderColumns = () => {
    if (columns) {
      return columns.map((c, index) => {
        return (
          <Col
            className={c.hideMobile ? 'd-none d-md-block' : null}
            style={c.style ? c.style : null}
            xs={c.sizeXS}
            md={c.size}
            onClick={!c.predicateList ? () => handleColumnSort(c.value) : () => handleColumnSortMulti(c.predicateList)}
            key={index}
          >
            <ColumnHeader className="truncate">
            {c.textXS ? c.textXS : c.text}
              
              <MdArrowDropDown
                style={
                  order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }
                }
                size="2em"
              />
              <MdArrowDropUp
                style={
                  order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }
                }
                size="2em"
              />
            </ColumnHeader>
          </Col>
        );
      });
    }
  };

  return <Row>{renderColumns()}</Row>;
};

export default SavColumnSort;

//style={{ padding: "10px 20px" }}

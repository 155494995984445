import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
  fetchNotifications,
  updateNotification,
  deleteNotification
} from "../../actions";
import _ from "lodash";
import { connect } from "react-redux";
import Loader from "../../components/Utilities/Loader";
import SavPagination from "../../components/SavPagination";
import {
  FaFileInvoiceDollar,
  FaBriefcase,
  FaRegEnvelope
} from "react-icons/fa";
import {AiOutlineCloudSync} from "react-icons/ai";
import {GoPackage} from 'react-icons/go'

const HeaderDiv = styled.div`
  background-color: #3f3f3f;
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Gotham-Medium";
  padding: 40px;
`;
const markAllStyle = {
  textAlign: "right",
  fontSize: 12,
  margin: "20px 0px"
};
const rowNotRead = {
  backgroundColor: "#D8D8D8",
  padding: 20,
  cursor: "pointer",
  borderTop: "1px solid #b4b4b4"
};
const rowRead = {
  padding: 20,
  cursor: "pointer",
  borderTop: "1px solid #b4b4b4"
};

const Notifications = props => {
  const { notifications, fetchNotifications, history } = props;

  const [listOfNotifications, setListOfNotifications] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  
  useEffect(() => {
    document.title = "Savant Community | Notifications"
  },[]);
  
  useEffect(() => {
    if (_.isEmpty(notifications)) {
      fetchNotifications();
    } else {
      setListOfNotifications(notifications.data);
    }
  }, [notifications]);

  useEffect(() => {
    if (listOfNotifications) {
      let index = 0;
      const temp = [];
      for (index = 0; index < listOfNotifications.length; index += pageSize) {
        let chunk = listOfNotifications.slice(index, index + pageSize);
        temp.push(chunk);
      }
      setNotificationFilter(temp);
    }
  }, [listOfNotifications, pageSize]);

  const createMarkup = richText => {
    return { __html: richText };
  };

  const handleUpdateNotification = notification => {
    if (!notification.isRead) {
      let notificationList = [];
      let notificationJSON = {
        isRead: true,
        sfid: notification.sfid
      };
      notificationList.push(notificationJSON);
      props.updateNotification(notificationList);
    }
    if (notification.target){
      if (!notification.isExternal){
        history.push(notification.target);
      }else{
        window.location.href = notification.target;
      }
    }
  };

  const handleDeleteNotification = notification => {
    props.deleteNotification(notification);
  };

  const markAllRead = () => {
    let notificationList = [];
    notifications.data.forEach(n => {
      if (!n.isRead) {
        let notificationJSON = {
          isRead: true,
          sfid: n.sfid
        };
        notificationList.push(notificationJSON);
      }
    });
    props.updateNotification(notificationList);
  };

  const renderIcon = (n) => {
    switch(n.type){
      case 'Order' :
        return <GoPackage size={25} />
      case 'Billing' : 
       return <FaFileInvoiceDollar size={25} />
      case 'Support' :
        return <FaBriefcase size={25} />
      case 'Subscription' :
        return <AiOutlineCloudSync size={25} />
      default :
        return <FaRegEnvelope size={25} />
    }
  }

  const renderNotifications = () => {
    if (!_.isEmpty(notificationFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > notificationFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return notificationFilter[page].map(n => {
        const date = new Date(
          JSON.parse(JSON.stringify(n.dateSent))
        ).toLocaleString();
        return (
          <Row
            className="showdeleteCheckmark notiHov vertical-align"
            style={n.isRead ? rowRead : rowNotRead}
            key={n.sfid}
          >
            <Col xs={1}>
              {renderIcon(n)}
            </Col>
            <Col xs={10} onClick={() => handleUpdateNotification(n)}>
              <div className="f14 med" dangerouslySetInnerHTML={createMarkup(n.message)} />
              {/* <span className="f14 med">{n.message}</span> */}
              {/* <br /> */}
              <small className="light-gray">{date}</small>
            </Col>
            <Col xs={1} style={{ textAlign: "right" }}>
              <button
                className="deleteCheckmark f24"
                onClick={() => handleDeleteNotification(n)}
              >
                &times;
              </button>
            </Col>
          </Row>
        );
      });
    }
  };

  if (notifications) {
    return (
      <div>
        <HeaderDiv>NOTIFICATIONS</HeaderDiv>
        <Container>
          <Row>
            <Col sm={12} style={markAllStyle}>
              <button className="markAllButton" onClick={() => markAllRead()}>
                MARK ALL AS READ
              </button>
            </Col>
          </Row>
          {renderNotifications()}
          <SavPagination
            data={notificationFilter}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataLength={listOfNotifications.length}
          />
        </Container>
      </div>
    );
  }
  return (
    <div>
      <HeaderDiv>NOTIFICATIONS</HeaderDiv>
      <Loader />
    </div>
  );
};

const mapStateToProps = state => ({
  notifications: state.notifications
});

export default connect(mapStateToProps, {
  fetchNotifications,
  updateNotification,
  deleteNotification
})(Notifications);

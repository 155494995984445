import sfAPI from "../api/sfAPI";
import {
  SET_AUTH_ERROR,
  SET_SERVER_ERROR,
  FETCH_GRID_REQUEST,
  FETCH_GRID_SUCCESS,
  FETCH_GRID_FAILURE,
  UPDATE_GRID_SUCCESS,
  UPDATE_GRID_REQUEST,
  UPDATE_GRID_FAILURE,
  FETCH_CAROUSEL_REQUEST,
  FETCH_CAROUSEL_SUCCESS,
  FETCH_FEED_REQUEST,
  FETCH_FEED_SUCCESS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_FAILURE,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_FAILURE,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILURE,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  FETCH_SERIALS_SUCCESS,
  FETCH_SERIALS_REQUEST,
  FETCH_CREDITS_SUCCESS,
  FETCH_CREDITS_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_REQUEST,
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENT_REQUEST,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAILURE,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_REQUEST,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  FETCH_RMA_REQUEST,
  FETCH_RMA_SUCCESS,
  FETCH_RMA_FAILURE,
  UPDATE_SAVED_ARTICLES_REQUEST,
  UPDATE_SAVED_ARTICLES_SUCCESS,
  UPDATE_SAVED_ARTICLES_FAILURE,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_AGREEMENTS_FAILURE,
  FETCH_AGREEMENTS_REQUEST,
  FETCH_AGREEMENTS_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,
  UPDATE_AGREEMENT_REQUEST,
  UPDATE_AGREEMENT_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  FETCH_EVENT_REQUEST,
  FETCH_MARKETING_FAILURE,
  FETCH_MARKETING_REQUEST,
  FETCH_MARKETING_SUCCESS,
  FETCH_CASES_FAILURE,
  FETCH_CASES_REQUEST,
  FETCH_CASES_SUCCESS,
  FETCH_CASE_FAILURE,
  FETCH_CASE_REQUEST,
  FETCH_CASE_SUCCESS,
  FETCH_EDUCATION_FAILURE,
  FETCH_EDUCATION_REQUEST,
  FETCH_EDUCATION_SUCCESS,
  FETCH_EDUCATION_DETAIL_FAILURE,
  FETCH_EDUCATION_DETAIL_REQUEST,
  FETCH_EDUCATION_DETAIL_SUCCESS,
  FETCH_CREDIT_CARDS_FAILURE,
  FETCH_CREDIT_CARDS_REQUEST,
  FETCH_CREDIT_CARDS_SUCCESS,
  SET_TOAST_STATUS,
  SET_FAIL_STATUS,
  FETCH_LATEST_SOFTWARE_REQUEST,
  FETCH_LATEST_SOFTWARE_FAILURE,
  FETCH_LATEST_SOFTWARE_SUCCESS,
  FETCH_BETA_REQUEST,
  FETCH_BETA_FAILURE,
  FETCH_BETA_SUCCESS,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_FAILURE,
  FETCH_PROFILE_SUCCESS,
  FETCH_DISTRIBUTORS_FAILURE,
  FETCH_DISTRIBUTORS_REQUEST,
  FETCH_DISTRIBUTORS_SUCCESS,
  UPDATE_DISTRIBUTOR_REQUEST,
  UPDATE_DISTRIBUTOR_FAILURE,
  UPDATE_DISTRIBUTOR_SUCCESS,
  CREATE_DISTRIBUTOR_FAILURE,
  CREATE_DISTRIBUTOR_REQUEST,
  CREATE_DISTRIBUTOR_SUCCESS,
  DELETE_DISTRIBUTOR_FAILURE,
  DELETE_DISTRIBUTOR_REQUEST,
  DELETE_DISTRIBUTOR_SUCCESS,
  DELETE_CREDIT_CARD_FAILURE,
  DELETE_CREDIT_CARD_REQUEST,
  DELETE_CREDIT_CARD_SUCCESS,
  CREATE_CREDIT_CARD_FAILURE,
  CREATE_CREDIT_CARD_REQUEST,
  CREATE_CREDIT_CARD_SUCCESS,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_REQUEST,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  FETCH_ORDER_PDF_FAILURE,
  FETCH_ORDER_PDF_REQUEST,
  FETCH_ORDER_PDF_SUCCESS,
  CREATE_TAX_CERT_FAILURE,
  CREATE_TAX_CERT_REQUEST,
  CREATE_TAX_CERT_SUCCESS,
  UPDATE_TAX_CERT_FAILURE,
  UPDATE_TAX_CERT_REQUEST,
  UPDATE_TAX_CERT_SUCCESS,
  DELETE_TAX_CERT_FAILURE,
  DELETE_TAX_CERT_REQUEST,
  DELETE_TAX_CERT_SUCCESS,
  CREATE_FEEDBACK_REQUEST,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_FEEDBACK_FAILURE,
  FETCH_SOFTWARE_FAILURE,
  FETCH_SOFTWARE_REQUEST,
  FETCH_SOFTWARE_SUCCESS,
  FETCH_CONTENT_FAILURE,
  FETCH_CONTENT_REQUEST,
  FETCH_CONTENT_SUCCESS,
  FETCH_PAYMENTS_FAILURE,
  FETCH_PAYMENTS_REQUEST,
  FETCH_PAYMENTS_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_REQUEST,
  CREATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SUCCESS,
  CREATE_PAYMENT_PDF_FAILURE,
  CREATE_PAYMENT_PDF_REQUEST,
  CREATE_PAYMENT_PDF_SUCCESS,
  FETCH_PAYMENT_PDF_FAILURE,
  FETCH_PAYMENT_PDF_REQUEST,
  FETCH_PAYMENT_PDF_SUCCESS,
  FETCH_BANK_FAILURE,
  FETCH_BANK_REQUEST,
  FETCH_BANK_SUCCESS,
  CREATE_BANK_FAILURE,
  CREATE_BANK_REQUEST,
  CREATE_BANK_SUCCESS,
  DELETE_BANK_FAILURE,
  DELETE_BANK_REQUEST,
  DELETE_BANK_SUCCESS,
  FETCH_SESSION_COMMENTS_FAILURE,
  FETCH_SESSION_COMMENTS_REQUEST,
  FETCH_SESSION_COMMENTS_SUCCESS,
  CREATE_SESSION_REGISTRATION_FAILURE,
  CREATE_SESSION_REGISTRATION_REQUEST,
  CREATE_SESSION_REGISTRATION_SUCCESS,
  CREATE_SESSION_ATTENDANCE_FAILURE,
  CREATE_SESSION_ATTENDANCE_REQUEST,
  CREATE_SESSION_ATTENDANCE_SUCCESS,
  CREATE_COMMENT_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_REGISTRATION_FAILURE,
  CREATE_REGISTRATION_REQUEST,
  CREATE_REGISTRATION_SUCCESS,
  FETCH_TRADESHOW_FAILURE,
  FETCH_TRADESHOW_REQUEST,
  FETCH_TRADESHOW_SUCCESS,
  FETCH_TRADESHOW_VIRTUAL_FAILURE,
  FETCH_TRADESHOW_VIRTUAL_REQUEST,
  FETCH_TRADESHOW_VIRTUAL_SUCCESS,
  CREATE_FORM_FAILURE,
  CREATE_FORM_REQUEST,
  CREATE_FORM_SUCCESS,
  FETCH_SERVICES_PRODUCTS_FAILURE,
  FETCH_SERVICES_PRODUCTS_REQUEST,
  FETCH_SERVICES_PRODUCTS_SUCCESS,
  FETCH_SUBS_FAILURE,
  FETCH_SUBS_REQUEST,
  FETCH_SUBS_SUCCESS,
  FETCH_HOMES_FAILURE,
  FETCH_HOMES_REQUEST,
  FETCH_HOMES_SUCCESS,
  FETCH_HOME_FAILURE,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
} from "../actions/types";

const formatError = (error) => {
  const errors = [];
  if (error.response) {
    errors.push(error.response.status + " " + error.response.statusText);
    return errors
  }
  errors.push('Unknown exception');
  return errors;
};

// **************** GLOBAL ****************
export const setSuccessToast = (bool) => (dispatch) => {
  dispatch({ type: SET_TOAST_STATUS, payload: bool });
  if (bool){
    setTimeout(() => {
      dispatch({ type: SET_TOAST_STATUS, payload: false })
    }, 3000)
  }
};
export const setFailToast = (bool, error) => (dispatch) => {
  dispatch({ type: SET_FAIL_STATUS, payload: {show:bool, message: error} });
  if (bool){
    setTimeout(() => {
      dispatch({ type: SET_FAIL_STATUS, payload: {show:false, message: ''} })
    }, 5000)
  }
};
export const clearErrors = (actionIn) => (dispatch) => {
  setTimeout(() => {
    dispatch({ type: actionIn, payload: ""})
  }, 5000);
};
export const fetchUser = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_REQUEST });
    const response = await sfAPI.get("/user");
    if (response.data.success){
      dispatch({ type: FETCH_USER_SUCCESS, payload: response.data });
    }else{
      dispatch({ type: FETCH_USER_FAILURE, payload: response.data.errors });
    }
  } catch (error) {
    if (error.response){
      dispatch({ type: FETCH_USER_FAILURE, payload: formatError(error) });
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    } else {
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const fetchDocument = (docId, type) => async (dispatch) => {
  try {
    dispatch({type: FETCH_DOCUMENT_REQUEST});
    const response = await sfAPI.get(`/pdf/${docId}?type=${type}`);
    if (response.data.success) {
      dispatch({
        type: FETCH_DOCUMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else {
      dispatch({
        type: FETCH_DOCUMENT_FAILURE,
        payload: response.data.errors,
      });
      dispatch(setFailToast(true, response.data.errors));
      return response.data
    }
  }catch(error) {
    dispatch({ type: FETCH_DOCUMENT_FAILURE, payload: formatError(error) });
  }
};
export const createTask = (task) => async (dispatch) => {
  try {
    dispatch({type: CREATE_TASK_REQUEST});
    const createResponse = await sfAPI.post('/tasks', task);

    if (createResponse.data.success) {
      dispatch({
        type: CREATE_TASK_SUCCESS,
        payload: true,
      });
      dispatch(setSuccessToast(true));
    } else {
      dispatch({
        type: CREATE_TASK_FAILURE,
        payload: createResponse.data.errors,
      });
      dispatch(setFailToast(true, createResponse.data.errors));
    }
  } catch (error) {
    dispatch({
      type: CREATE_TASK_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchTasks = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_TASKS_REQUEST });
    const response = await sfAPI.get("/tasks?type=power_finance");
    dispatch({ type: FETCH_TASKS_SUCCESS, payload: response.data });

  }catch(error){
    dispatch({ type: FETCH_TASKS_FAILURE, payload: formatError(error) });
  }
};

export const createForm = (form) => async (dispatch) => {
  try {
    dispatch({type: CREATE_FORM_REQUEST});
    const createResponse = await sfAPI.post('/forms', form);

    if (createResponse.data.success) {
      dispatch({
        type: CREATE_FORM_SUCCESS,
        payload: true,
      });
      dispatch(setSuccessToast(true));
    } else {
      dispatch({
        type: CREATE_FORM_FAILURE,
        payload: createResponse.data.errors,
      });
      dispatch(setFailToast(true, createResponse.data.errors));
    }
    return createResponse;
  } catch (error) {
    dispatch({
      type: CREATE_FORM_FAILURE,
      payload: formatError(error),
    });
    return formatError(error);
  }
};

export const fetchProducts = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    const response = await sfAPI.get("/products/finance");
    dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data });
    return response;

  }catch(error){
    dispatch({ type: FETCH_PRODUCTS_FAILURE, payload: formatError(error) });
  }
};
export const fetchServices = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_SERVICES_PRODUCTS_REQUEST });
    const response = await sfAPI.get("/products/services");
    dispatch({ type: FETCH_SERVICES_PRODUCTS_SUCCESS, payload: response.data });
    return response;

  }catch(error){
    dispatch({ type: FETCH_SERVICES_PRODUCTS_FAILURE, payload: formatError(error) });
  }
};


// **************** HOME ****************
export const fetchGrid = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GRID_REQUEST });
    const response = await sfAPI.get("/grid");
    dispatch({ type: FETCH_GRID_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_GRID_FAILURE, payload: formatError(error) });
  }
};
export const updateGrid = (grid) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_GRID_REQUEST });
    const updateResponse = await sfAPI.put("/grid/", grid);

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/grid");
      dispatch({
        type: UPDATE_GRID_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch({
        type: UPDATE_GRID_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_GRID_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchCarousel = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_CAROUSEL_REQUEST });
    const response = await sfAPI.get("/carousel");
    dispatch({ type: FETCH_CAROUSEL_SUCCESS, payload: response.data });
  } catch (error){
  }
  
};
export const fetchFeed = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_FEED_REQUEST });
    const response = await sfAPI.get("/feed");
    dispatch({ type: FETCH_FEED_SUCCESS, payload: response.data });
  } catch (error){
    if (error.response){

    }

  }
  
};
export const fetchNews = (newsId) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_NEWS_REQUEST });
    const response = await sfAPI.get(`/feed/${newsId}`);
    dispatch({ type: FETCH_NEWS_SUCCESS, payload: response.data });
  } catch (error){
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
  
};
export const fetchEvents = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EVENTS_REQUEST });
    const response = await sfAPI.get("/events");
    dispatch({ type: FETCH_EVENTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_EVENTS_FAILURE, payload: formatError(error) });
  }
};
export const fetchEvent = (eventId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EVENT_REQUEST });
    const response = await sfAPI.get(`/events/${eventId}`);
    dispatch({ type: FETCH_EVENT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_EVENT_FAILURE, payload: formatError(error) });
  }
};
export const fetchMarketing = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MARKETING_REQUEST });
    const response = await sfAPI.get("/marketing");
    dispatch({ type: FETCH_MARKETING_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_MARKETING_FAILURE, payload: formatError(error) });
  }
};
export const fetchProfileRequests = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PROFILE_REQUEST });
    const response = await sfAPI.get("/profilerequest");
    dispatch({ type: FETCH_PROFILE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_PROFILE_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** HEADER ****************
export const fetchNotifications = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_NOTIFICATIONS_REQUEST});
    const response = await sfAPI.get("/notifications");
    if (response.data.success){
      dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: response.data });
    } else {
      dispatch({ type: FETCH_NOTIFICATIONS_FAILURE, payload: response.data.errors });
    }
  } catch (error){
    dispatch({ type: FETCH_NOTIFICATIONS_FAILURE, payload: formatError(error)});
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const updateNotification = (notification) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_NOTIFICATION_REQUEST});
    const response = await sfAPI.put("/notifications/", notification);
    if (response.data.success){
      //dispatch(setSuccessToast(true));
      dispatch({type: UPDATE_NOTIFICATION_SUCCESS, payload: response.data});
      const response2 = await sfAPI.get("/notifications");
      if (response2.data.success){
        dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: response2.data });
      }
    } else {
      dispatch({ type: UPDATE_NOTIFICATION_FAILURE, payload: response.data.errors });
    }
  } catch (error) {
    dispatch({ type: UPDATE_NOTIFICATION_FAILURE, payload: formatError(error) });
  }
};
export const deleteNotification = (notification) => async (dispatch) => {
  try {
    dispatch({type: DELETE_NOTIFICATION_REQUEST});
    const response = await sfAPI.delete(`/notifications/${notification.sfid}`);
    if (response.data.success){
      dispatch(setSuccessToast(true));
      dispatch({type: DELETE_NOTIFICATION_SUCCESS, payload: response.data});
      const response2 = await sfAPI.get("/notifications");
      if (response2.data.success){
        dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: response2.data });
      }
    } else {
      dispatch({ type: DELETE_NOTIFICATION_FAILURE, payload: response.data.errors });
    }
  } catch (error) {
    dispatch({ type: DELETE_NOTIFICATION_FAILURE, payload: formatError(error) });
  }
};
export const createNotification = (notification) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_NOTIFICATION_REQUEST });
    const updateResponse = await sfAPI.post(`/notifications`, notification);
    if(updateResponse.data.success){
      const fetchResponse = await sfAPI.get("/notifications");
      dispatch(setSuccessToast(true));
      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        payload:notification[0].course,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: CREATE_NOTIFICATION_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error){
    dispatch({
      type: CREATE_NOTIFICATION_FAILURE,
      payload: formatError(error),
    })
  }
};
export const createFeedback = (feedback) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FEEDBACK_REQUEST });
    const createResponse = await sfAPI.post(`/feedback`, feedback);

    if (createResponse.data.success) {

      dispatch({
        type: CREATE_FEEDBACK_SUCCESS,
        payload: true,
      });
      return createResponse.data.payload;
    } else {
      dispatch({
        type: CREATE_FEEDBACK_FAILURE,
        payload: createResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_FEEDBACK_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** ACCOUNT ****************
export const fetchAccount = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_ACCOUNT_REQUEST });
    const response = await sfAPI.get("/account");
    dispatch({ type: FETCH_ACCOUNT_SUCCESS, payload: response.data });

  }catch(error){
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const updateAccount = (account) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ACCOUNT_REQUEST });
    const updateResponse = await sfAPI.put(`/account/${account.sfid}`, account);

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/account");
      dispatch(setSuccessToast(true));
      dispatch({
        type: UPDATE_ACCOUNT_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: UPDATE_ACCOUNT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ACCOUNT_FAILURE,
      payload: formatError(error),
    });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};

// **************** SUBSCRIPTIONS ****************
export const fetchSubs = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_SUBS_REQUEST });
    const response = await sfAPI.get("/subscriptions");
    dispatch({ type: FETCH_SUBS_SUCCESS, payload: response.data });

  }catch(error){
    dispatch({ type: FETCH_SUBS_FAILURE, payload: formatError(error) });
  }
}

export const fetchHomes = (domainId) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_HOMES_REQUEST });
    const response = await sfAPI.get(`/homes/${domainId}?type=domain`);
    if (response.data.success){
      dispatch({ type: FETCH_HOMES_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_HOMES_FAILURE,
        payload: response.data.errors,
      });
    }
    

  }catch(error){
    dispatch({ type: FETCH_HOMES_FAILURE, payload: formatError(error) });
  }
}
export const fetchHome = (homeId) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_HOME_REQUEST });
    const response = await sfAPI.get(`/homes/${homeId}?type=home`);
    dispatch({ type: FETCH_HOME_SUCCESS, payload: response.data });
    return response.data;

  }catch(error){
    dispatch({ type: FETCH_HOME_FAILURE, payload: formatError(error) });
  }
}
export const fetchHomeHistory = (homeId) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_HOME_REQUEST });
    const response = await sfAPI.get(`/homes/${homeId}?type=history`);
    if (response.data.success){
      dispatch({ type: FETCH_HOME_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_HOME_FAILURE,
        payload: response.data.errors,
      });
    }
    return response.data;
  }catch(error){
    dispatch({ type: FETCH_HOME_FAILURE, payload: formatError(error) });
  }
};
export const fetchHomeCases = (uid) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_HOME_REQUEST });
    const response = await sfAPI.get(`/homes/${uid}?type=cases`);
    if (response.data.success){
      dispatch({ type: FETCH_HOME_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_HOME_FAILURE,
        payload: response.data.errors,
      });
    }
    return response.data;
  }catch(error){
    dispatch({ type: FETCH_HOME_FAILURE, payload: formatError(error) });
  }
};
export const fetchHomeSerial = (uid) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_HOME_REQUEST });
    const response = await sfAPI.get(`/homes/${uid}?type=serial`);
    if (response.data.success){
      dispatch({ type: FETCH_HOME_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_HOME_FAILURE,
        payload: response.data.errors,
      });
    }
    return response.data;
  }catch(error){
    dispatch({ type: FETCH_HOME_FAILURE, payload: formatError(error) });
  }
};


// **************** CONTACTS ****************
export const fetchContacts = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_CONTACTS_REQUEST });
    const response = await sfAPI.get("/contacts");
    dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: response.data });
  }catch(error){
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
  
};
export const fetchContact = (contactId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CONTACT_REQUEST });
    const response = await sfAPI.get(`/contacts/${contactId}`);
    dispatch({ type: FETCH_CONTACT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CONTACT_FAILURE, payload: formatError(error) });
  }
};
export const updateContact = (contact) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CONTACT_REQUEST });
    const updateResponse = await sfAPI.put(
      `/contacts/${contact.sfid}`,
      contact
    );

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/contacts");
      dispatch({
        type: UPDATE_CONTACT_SUCCESS,
        payload: fetchResponse.data,
      });
      dispatch(fetchEducation());
      dispatch(setSuccessToast(true));
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: UPDATE_CONTACT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CONTACT_FAILURE,
      payload: formatError(error),
    });
  }
};
export const createContact = (contact) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CONTACT_REQUEST });
    const updateResponse = await sfAPI.post(`/contacts/`, contact);
    if(updateResponse.data.success){
      dispatch({
        type: CREATE_CONTACT_SUCCESS,
        payload: updateResponse.data,
      });
      dispatch(setSuccessToast(true));
      const fetchResponse = await sfAPI.get("/contacts");
      if (fetchResponse.data.success){
        dispatch({
          type: FETCH_CONTACT_SUCCESS,
          payload: fetchResponse.data,
        });
      } 
      return updateResponse
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: CREATE_CONTACT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_CONTACT_FAILURE,
      payload: formatError(error),
    })
  }
}
export const fetchEducation = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EDUCATION_REQUEST });
    const response = await sfAPI.get("/education");
    dispatch({ type: FETCH_EDUCATION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_EDUCATION_FAILURE, payload: formatError(error) });
  }
};
export const fetchEducationDetail = (contactId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EDUCATION_DETAIL_REQUEST });
    const response = await sfAPI.get(`/education/${contactId}`);
    dispatch({ type: FETCH_EDUCATION_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_EDUCATION_DETAIL_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** ORDERS ****************
export const fetchOrders = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_ORDERS_REQUEST });
    const response = await sfAPI.get("/orders");
    dispatch({ type: FETCH_ORDERS_SUCCESS, payload: response.data });
  }catch(error){

  }
  
};
export const fetchOrder = (orderId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ORDER_REQUEST });
    const response = await sfAPI.get(`/orders/${orderId}`);
    if (response.data.success) {
      dispatch({
        type: FETCH_ORDER_SUCCESS,
        payload: response.data,
      });
    }else {
      dispatch({
        type: FETCH_ORDER_FAILURE,
        payload: response.data.errors,
      });
    }
  } catch(error) {
    dispatch({ type: FETCH_ORDER_FAILURE, payload: formatError(error) });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const resolveOrder = (orderId, cardId, cardJSON) => async (dispatch) => {
  if (cardJSON) {
    try {
      dispatch({ type: CREATE_CREDIT_CARD_REQUEST });
      const updateResponse = await sfAPI.post(`/creditcards?step=stage`, cardJSON);
      if (updateResponse.data.success) {

        const creditCardWithOpp = {...cardJSON};
        creditCardWithOpp.oppId = updateResponse.data.payload;

        const step2response = await sfAPI.post("/creditcards?step=finish", creditCardWithOpp);

        if (step2response.data.success) {
          try {
            dispatch({type: UPDATE_ORDER_REQUEST});
            const res = await sfAPI.put(`/orders/${orderId}?cardid=${step2response.data.payload}`);
        
            if (res.data.success) {
              dispatch({
                type: UPDATE_ORDER_SUCCESS,
                payload: true,
              });
              dispatch(setSuccessToast(true));
            } else {
              dispatch(setFailToast(true, res.data.errors));
              dispatch({
                type: UPDATE_ORDER_FAILURE,
                payload: res.data.errors,
              });
            }
          } catch (error) {
            dispatch({
              type: UPDATE_ORDER_FAILURE,
              payload: formatError(error),
            });
          }
          const fetchResponse = await sfAPI.get("/creditcards");
          dispatch({
            type: FETCH_CREDIT_CARDS_SUCCESS,
            payload: fetchResponse.data,
          });
        } else {
          dispatch(setFailToast(true, step2response.data.errors));
          dispatch({
            type: CREATE_CREDIT_CARD_FAILURE,
            payload: step2response.data.errors,
          });
        }
      } else {
        dispatch(setFailToast(true, updateResponse.data.errors));
        dispatch({
          type: CREATE_CREDIT_CARD_FAILURE,
          payload: updateResponse.data.errors,
        });
      }
    } catch(error) {
      dispatch({
        type: CREATE_CREDIT_CARD_FAILURE,
        payload: formatError(error),
      });
    }
  } else {
    try {
      dispatch({type: UPDATE_ORDER_REQUEST});
      const res = await sfAPI.put(`/orders/${orderId}?cardid=${cardId}`);
  
      if (res.data.success) {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: true,
        });
      } else {
        dispatch({
          type: UPDATE_ORDER_FAILURE,
          payload: res.data.errors,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_FAILURE,
        payload: formatError(error),
      });
    }
  }
};
export const fetchOrderPDF = (orderId) => async (dispatch) => {
  try {
    dispatch({type: FETCH_ORDER_PDF_REQUEST});
    const response = await sfAPI.get(`/pdf/${orderId}?type=orderConfirm`);
    if (response.data.success) {
      dispatch({
        type: FETCH_ORDER_PDF_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else {
      dispatch({
        type: FETCH_ORDER_PDF_FAILURE,
        payload: response.data.errors,
      });
    }
  }catch(error) {
    dispatch({ type: FETCH_ORDER_PDF_FAILURE, payload: formatError(error) });
  }
};

// **************** ADDRESSES ****************
export const fetchAddresses = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_ADDRESSES_REQUEST });
    const response = await sfAPI.get("/address");
    dispatch({ type: FETCH_ADDRESSES_SUCCESS, payload: response.data });
  } catch(error){

  }
  
};
export const createAddress = (address) => async (dispatch) => {
  try {
    dispatch({type: CREATE_ADDRESS_REQUEST});
    const createResponse = await sfAPI.post('/address', address);

    if (createResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: CREATE_ADDRESS_SUCCESS,
        payload: createResponse.data,
      });
      const fetchResponse = await sfAPI.get("/address");
      if (fetchResponse.data.success) {
        dispatch({
          type: FETCH_ADDRESSES_SUCCESS,
          payload: fetchResponse.data,
        });
      }
    } else {
      dispatch(setFailToast(true, createResponse.data.errors));
      dispatch({
        type: CREATE_ADDRESS_FAILURE,
        payload: createResponse.data.errors,
      });
    }
    return createResponse.data;

  } catch (error) {
    dispatch({
      type: CREATE_ADDRESS_FAILURE,
      payload: formatError(error),
    });
  }
};
export const updateAddress = (address) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ADDRESS_REQUEST });
    const updateResponse = await sfAPI.put(`/address/${address.sfid}`, address);

    if (updateResponse.data.success) {
      dispatch({
        type: UPDATE_ADDRESS_SUCCESS,
        payload: updateResponse.data,
      });
      dispatch(setSuccessToast(true));
      const fetchResponse = await sfAPI.get("/address");
      if (fetchResponse.data.success) {
        dispatch({
          type: FETCH_ADDRESSES_SUCCESS,
          payload: fetchResponse.data,
        });
      }
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: UPDATE_ADDRESS_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
    return updateResponse.data;
  } catch (error) {
    dispatch({
      type: UPDATE_ADDRESS_FAILURE,
      payload: formatError(error),
    });
  }
};
export const deleteAddress = (addressId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ADDRESS_REQUEST });
    const updateResponse = await sfAPI.delete(`/address/${addressId}`);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: DELETE_ADDRESS_SUCCESS,
        payload: true,
      });
      const fetchResponse = await sfAPI.get("/address");
      if (fetchResponse.data.success) {
        dispatch({
          type: FETCH_ADDRESSES_REQUEST,
          payload: fetchResponse.data,
        });
      }
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: DELETE_ADDRESS_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_ADDRESS_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** BILLING ****************

export const createPayment = (payment) => async (dispatch) => {
  try{
    dispatch({type: CREATE_PAYMENT_REQUEST});
    const response = await sfAPI.post("/payments", payment);
    let posted = false;
    let status = '';

    if (response.data.success){

      const paymentId = response.data.payload.sfid;
      let isFirst = true;

      while (!posted){
        const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay));
        if (!isFirst){
          await wait(3000);
        } else {
          isFirst = false;
        }

        const response2 = await sfAPI.get(`/payments/${paymentId}`);

        if (response2.data.success){

          if (response2.data.payload[0].postingStatus === 'Complete'){
            posted = true;
            status = 'Complete';
            dispatch({type: CREATE_PAYMENT_SUCCESS, payload: true});
            dispatch(setSuccessToast(true));
            dispatch(fetchPayments());
            dispatch(fetchCredits());
            dispatch(fetchInvoices());
            
          }

          if (response2.data.payload[0].postingStatus === 'Errored'){
            posted = true;
            status = 'Errored';
            dispatch({type: CREATE_PAYMENT_SUCCESS, payload: true});
            dispatch(setFailToast(true, ['Error processing payment']));
            dispatch(fetchPayments());
            dispatch(fetchCredits());
            dispatch(fetchInvoices());
          }
          if (response2.data.payload[0].postingStatus === 'Declined'){
            posted = true;
            status = 'Declined';
            dispatch({type: CREATE_PAYMENT_SUCCESS, payload: true});
            dispatch(setFailToast(true, ['Payment Method Declined']));
            dispatch(fetchPayments());
            dispatch(fetchCredits());
            dispatch(fetchInvoices());
          }

        }
    
      }
    } else {
      dispatch(setFailToast(true, response.data.errors));
      dispatch({type: CREATE_PAYMENT_FAILURE,payload: response.data.errors});
    }

    if (status === 'Complete'){
      return {response: response, status: 'Complete'};
    }
    if (status === 'Errored'){
      return {response: response, status: 'Errored'};
    }
    if (status === 'Declined'){
      return {response: response, status: 'Declined'};
    }

  }catch(error){
    dispatch({type: CREATE_PAYMENT_FAILURE, payload: formatError(error)});
  }
};
export const cancelPayment = (paymentId) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_PAYMENT_REQUEST});
    const response = await sfAPI.put(`/payments/${paymentId}`);
    if (response.data.success){
      dispatch({type: UPDATE_PAYMENT_SUCCESS});
      dispatch(setSuccessToast(true));
      dispatch(fetchPayments());
      dispatch(fetchCredits());
      dispatch(fetchInvoices());
    } else {
      dispatch(setFailToast(true, response.data.errors));
      dispatch({type: UPDATE_PAYMENT_FAILURE, payload: response.data.errors})
    }
  }catch(error){
    dispatch({type: UPDATE_PAYMENT_FAILURE, payload: formatError(error)});
  }
};
export const fetchPayments = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_PAYMENTS_REQUEST });
    const response = await sfAPI.get("/payments");
    dispatch({ type: FETCH_PAYMENTS_SUCCESS, payload: response.data });

  }catch(error){
    dispatch({ type: FETCH_PAYMENTS_FAILURE, payload: formatError(error) });
  }
};
export const fetchPayment = (paymentId) => async (dispatch) => {
  try{
    dispatch({ type: FETCH_PAYMENT_REQUEST });
    const response = await sfAPI.get(`/payments/${paymentId}`);
    dispatch({ type: FETCH_PAYMENT_SUCCESS, payload: response.data });
    return response;

  }catch(error){
    dispatch({ type: FETCH_PAYMENT_FAILURE, payload: formatError(error) });
  }
};
export const createPaymentPDF = (paymentId, pdf) => async (dispatch) => {
  try {
    dispatch({type: CREATE_PAYMENT_PDF_REQUEST});
    const createResponse = await sfAPI.post(`/pdf/${paymentId}?type=payment`, pdf);

    if (createResponse.data.success) {
      dispatch({
        type: CREATE_PAYMENT_PDF_SUCCESS,
        payload: true,
      });
      dispatch(fetchPayments());
    } else {
      dispatch(setFailToast(true, createResponse.data.errors));
      dispatch({
        type: CREATE_PAYMENT_PDF_FAILURE,
        payload: createResponse.data.errors,
      });
    }

  } catch(error) {
    dispatch({
      type: CREATE_PAYMENT_PDF_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchPaymentPDF = (paymentId) => async (dispatch) => {
  try {
    dispatch({type: FETCH_PAYMENT_PDF_REQUEST});
    const response = await sfAPI.get(`/pdf/${paymentId}?type=payment`);
    if (response.data.success) {
      dispatch({
        type: FETCH_PAYMENT_PDF_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else {
      dispatch({
        type: FETCH_PAYMENT_PDF_FAILURE,
        payload: response.data.errors,
      });
      dispatch(setFailToast(true, response.data.errors));
      return response.data
    }
  }catch(error) {
    dispatch({ type: FETCH_PAYMENT_PDF_FAILURE, payload: formatError(error) });
  }
};

export const fetchCredits = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_CREDITS_REQUEST });
    const response = await sfAPI.get("/credits");
    dispatch({ type: FETCH_CREDITS_SUCCESS, payload: response.data });

  }catch(error){
  }
};
export const fetchInvoices = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_INVOICES_REQUEST });
    const response = await sfAPI.get("/invoices");
    dispatch({ type: FETCH_INVOICES_SUCCESS, payload: response.data });

  }catch(error){
  }
};
export const fetchStatement = (accountId, start, end) => async (dispatch) => {
  try {
    dispatch({type: FETCH_DOCUMENT_REQUEST});
    const response = await sfAPI.get(`/pdf/${accountId}?type=activityStatement&start=${start}&end=${end}`);
    if (response.data.success) {
      dispatch({
        type: FETCH_DOCUMENT_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else {
      dispatch({
        type: FETCH_DOCUMENT_FAILURE,
        payload: response.data.errors,
      });
      return response.data
    }
  }catch(error) {
    dispatch({ type: FETCH_DOCUMENT_FAILURE, payload: formatError(error) });
  }
};

// **************** DOCUMENTS ****************
export const fetchDocuments = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DOCUMENTS_REQUEST });
    const response = await sfAPI.get("/documents");
    dispatch({ type: FETCH_DOCUMENTS_SUCCESS, payload: response.data });
  } catch(error) {
    dispatch({ type: FETCH_DOCUMENTS_FAILURE, payload: formatError(error) });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const fetchAgreements = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_AGREEMENTS_REQUEST });
    const response = await sfAPI.get("/agreements");
    dispatch({ type: FETCH_AGREEMENTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_AGREEMENTS_FAILURE, payload: formatError(error) });
  }
};
export const updateAgreement = (agreementId) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_AGREEMENT_REQUEST });
    const updateResponse = await sfAPI.post(`/agreements/${agreementId}`);

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/agreements");
      const fetchResponse2 = await sfAPI.get("/user");

      const responseObject = {
        agreements: fetchResponse.data,
        user: fetchResponse2.data,
      };
      dispatch({
        type: UPDATE_AGREEMENT_SUCCESS,
        payload: responseObject,
      });
    } else {
      dispatch({
        type: UPDATE_AGREEMENT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_AGREEMENT_FAILURE,
      payload: formatError(error),
    });
  }
};
export const updateTaxCert = (taxCert) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TAX_CERT_REQUEST });
    const updateResponse = await sfAPI.put(`/taxcertificates/${taxCert.recordId}`, taxCert);

    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: UPDATE_TAX_CERT_SUCCESS,
        payload: true,
      });

      const fetchResponse = await sfAPI.get("/documents");
      dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        payload: fetchResponse.data,
      });

    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: UPDATE_TAX_CERT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_TAX_CERT_FAILURE,
      payload: formatError(error),
    });
  }
};
export const createTaxCert = (taxCert) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TAX_CERT_REQUEST });
    const createResponse = await sfAPI.post(`/taxcertificates`, taxCert);

    if (createResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: CREATE_TAX_CERT_SUCCESS,
        payload: true,
      });

      const fetchResponse = await sfAPI.get("/documents");
      dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        payload: fetchResponse.data,
      });

    } else {
      dispatch(setFailToast(true, createResponse.data.errors));
      dispatch({
        type: CREATE_TAX_CERT_FAILURE,
        payload: createResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_TAX_CERT_FAILURE,
      payload: formatError(error),
    });
  }
};
export const deleteTaxCert = (certId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TAX_CERT_REQUEST });
    const updateResponse = await sfAPI.delete(`/taxcertificates/${certId}`);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: DELETE_TAX_CERT_SUCCESS,
        payload: true,
      });
      const fetchResponse = await sfAPI.get("/documents");
      dispatch({
        type: FETCH_DOCUMENTS_SUCCESS,
        payload: fetchResponse.data,
      });

    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: DELETE_TAX_CERT_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_TAX_CERT_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** WARRANTY ****************
export const fetchSerials = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_SERIALS_REQUEST });
    const response = await sfAPI.get("/serials");
    dispatch({ type: FETCH_SERIALS_SUCCESS, payload: response.data });
  }catch(error){

  }
};

// **************** SETTINGS ****************
export const fetchDistributors = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DISTRIBUTORS_REQUEST });
    const response = await sfAPI.get(`/distributors/`);
    dispatch({ type: FETCH_DISTRIBUTORS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_DISTRIBUTORS_FAILURE, payload: formatError(error) });
  }
}

export const updateDistributor = (distributor) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTOR_REQUEST });
    const updateResponse = await sfAPI.put(`/distributors/${distributor.sfid}?num=${distributor.accountNumber}`);

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/account");
      dispatch(setSuccessToast(true));
      dispatch({
        type: UPDATE_DISTRIBUTOR_SUCCESS,
        payload: updateResponse.data,
      });
      dispatch({
        type: FETCH_ACCOUNT_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: UPDATE_DISTRIBUTOR_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_DISTRIBUTOR_FAILURE,
      payload: formatError(error),
    });
  }
};
export const createDistributor = (distributor, distributorId) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DISTRIBUTOR_REQUEST });
    const updateResponse = await sfAPI.post(`/distributors?num=${distributor.accountNumber}&distId=${distributorId}`, distributor);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: CREATE_DISTRIBUTOR_SUCCESS,
        payload: updateResponse.data,
      });
      const fetchResponse = await sfAPI.get("/account");
      dispatch({
        type: FETCH_ACCOUNT_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: CREATE_DISTRIBUTOR_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_DISTRIBUTOR_FAILURE,
      payload: formatError(error),
    });
  }
};
export const deleteDistributor = (distributorId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DISTRIBUTOR_REQUEST });
    const updateResponse = await sfAPI.delete(`/distributors/${distributorId}`);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: DELETE_DISTRIBUTOR_SUCCESS,
        payload: true,
      });
      const fetchResponse = await sfAPI.get("/account");
      dispatch({
        type: FETCH_ACCOUNT_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: DELETE_DISTRIBUTOR_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_DISTRIBUTOR_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchCreditCards = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CREDIT_CARDS_REQUEST });
    const response = await sfAPI.get(`/creditcards`);
    dispatch({ type: FETCH_CREDIT_CARDS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CREDIT_CARDS_FAILURE, payload: formatError(error) });
  }
};
export const createCreditCard = (creditCard) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CREDIT_CARD_REQUEST });
    const updateResponse = await sfAPI.post(`/creditcards?step=stage`, creditCard);
    if (updateResponse.data.success) {
      

      const creditCardWithOpp = {...creditCard};
      creditCardWithOpp.oppId = updateResponse.data.payload;

      const step2response = await sfAPI.post("/creditcards?step=finish", creditCardWithOpp);

      if (step2response.data.success) {
        dispatch(setSuccessToast(true));
        dispatch({
          type: CREATE_CREDIT_CARD_SUCCESS,
          payload: true,
        });
        const fetchResponse = await sfAPI.get("/creditcards");
        dispatch({
          type: FETCH_CREDIT_CARDS_SUCCESS,
          payload: fetchResponse.data,
        });
      } else {
        dispatch(setFailToast(true, step2response.data.errors));
        dispatch({
          type: CREATE_CREDIT_CARD_FAILURE,
          payload: step2response.data.errors,
        });
      }

      const fetchResponse = await sfAPI.get("/creditcards");
      dispatch({
        type: FETCH_CREDIT_CARDS_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: CREATE_CREDIT_CARD_FAILURE,
        payload: updateResponse.data.errors,
      });
      return updateResponse.data.errors;
    }
  } catch (error) {
    dispatch({
      type: CREATE_CREDIT_CARD_FAILURE,
      payload: formatError(error),
    });
  }
};
export const deleteCreditCard = (ccId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CREDIT_CARD_REQUEST });
    const updateResponse = await sfAPI.delete(`/creditcards/${ccId}`);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: DELETE_CREDIT_CARD_SUCCESS,
        payload: true,
      });
      const fetchResponse = await sfAPI.get("/creditcards");
      dispatch({
        type: FETCH_CREDIT_CARDS_SUCCESS,
        payload: fetchResponse.data,
      });
      //fetchCreditCards();
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: DELETE_CREDIT_CARD_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_CREDIT_CARD_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchBankAccounts = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BANK_REQUEST });
    const response = await sfAPI.get(`/bankaccounts`);
    if (response.data.success){
      dispatch({ type: FETCH_BANK_SUCCESS, payload: response.data });
    }
  } catch (error) {
    dispatch({ type: FETCH_BANK_FAILURE, payload: formatError(error) });
  }
};
export const createBankAccount = (bankAccount) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BANK_REQUEST });
    const updateResponse = await sfAPI.post(`/bankaccounts?step=stage`, bankAccount);
    if (updateResponse.data.success) {

      const bankAccountWithOpp = {...bankAccount};
      bankAccountWithOpp.oppId = updateResponse.data.payload;
      const step2response = await sfAPI.post("/bankaccounts?step=finish", bankAccountWithOpp);

      if (step2response.data.success) {
        dispatch(setSuccessToast(true));
        dispatch({
          type: CREATE_BANK_SUCCESS,
          payload: true,
        });
      } else {
        dispatch(setFailToast(true, step2response.data.errors));
        dispatch({
          type: CREATE_BANK_FAILURE,
          payload: step2response.data.errors,
        });
      }

      const fetchResponse = await sfAPI.get("/bankaccounts");
      dispatch({
        type: FETCH_BANK_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: CREATE_BANK_FAILURE,
        payload: updateResponse.data.errors,
      });
      return updateResponse.data.errors;
    }
  } catch (error) {
    dispatch({
      type: CREATE_BANK_FAILURE,
      payload: formatError(error),
    });
  }
};
export const deleteBankAccount = (baId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BANK_REQUEST });
    const updateResponse = await sfAPI.delete(`/bankaccounts/${baId}`);
    if (updateResponse.data.success) {
      dispatch(setSuccessToast(true));
      dispatch({
        type: DELETE_BANK_SUCCESS,
        payload: true,
      });
      const fetchResponse = await sfAPI.get("/bankaccounts");
      dispatch({
        type: FETCH_BANK_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch(setFailToast(true, updateResponse.data.errors));
      dispatch({
        type: DELETE_BANK_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_BANK_FAILURE,
      payload: formatError(error),
    });
  }
};

// **************** ARTICLES ****************
export const fetchArticles = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ARTICLES_REQUEST });
    const response = await sfAPI.get("/knowledge");
    dispatch({ type: FETCH_ARTICLES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ARTICLES_FAILURE, payload: formatError(error) });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const fetchArticle = (articleId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ARTICLE_REQUEST });
    const response = await sfAPI.get(`/knowledge/${articleId}`);
    dispatch({ type: FETCH_ARTICLE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_ARTICLE_FAILURE, payload: formatError(error) });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const updateSavedArticles = (articles) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SAVED_ARTICLES_REQUEST });
    const updateResponse = await sfAPI.put("/knowledge/", articles);

    if (updateResponse.data.success) {
      const fetchResponse = await sfAPI.get("/knowledge?favorites=true");
      dispatch({
        type: UPDATE_SAVED_ARTICLES_SUCCESS,
        payload: fetchResponse.data,
      });
    } else {
      dispatch({
        type: UPDATE_SAVED_ARTICLES_FAILURE,
        payload: updateResponse.data.errors,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_SAVED_ARTICLES_FAILURE,
      payload: formatError(error),
    });
  }
};
export const fetchContent = () => async (dispatch) => {
  try{
    dispatch({ type: FETCH_CONTENT_REQUEST });
    const response = await sfAPI.get("/content");
    dispatch({ type: FETCH_CONTENT_SUCCESS, payload: response.data });
  }catch(error){
    dispatch({type: FETCH_CONTENT_FAILURE, payload: formatError(error)});
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
}

// **************** BETA & SOFTWARE ****************
export const fetchLatestSoftware = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_LATEST_SOFTWARE_REQUEST });
    const response = await sfAPI.get("/latestSoftware");
    dispatch({ type: FETCH_LATEST_SOFTWARE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_LATEST_SOFTWARE_FAILURE,
      payload: formatError(error),
    });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};
export const fetchBeta = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BETA_REQUEST });
    const response = await sfAPI.get("/beta");
    dispatch({ type: FETCH_BETA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_BETA_FAILURE, payload: formatError(error) });
  }
};
export const fetchSoftware = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_SOFTWARE_REQUEST});
    const response = await sfAPI.get(`/software`);
    if (response.data.success) {
      dispatch({
        type: FETCH_SOFTWARE_SUCCESS,
        payload: response.data,
      });
      return response.data;
    }else {
      dispatch({
        type: FETCH_SOFTWARE_FAILURE,
        payload: response.data.errors,
      });
    }
  }catch(error) {
    dispatch({ type: FETCH_SOFTWARE_FAILURE, payload: formatError(error) });
    if (!error.response){
      dispatch({ type: SET_AUTH_ERROR, payload: true });
    }
  }
};

// **************** TRADESHOWS ****************
export const fetchTradeshow = (externalId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_TRADESHOW_REQUEST });
    const response = await sfAPI.get(`/tradeshows/${externalId}`);
    dispatch({ type: FETCH_TRADESHOW_SUCCESS, payload: response.data});
  } catch (error) {
    dispatch({type: FETCH_TRADESHOW_FAILURE, payload: formatError(error)});
  }
};
export const fetchTradeshowVirtual = (externalId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_TRADESHOW_VIRTUAL_REQUEST });
    const response = await sfAPI.get(`/tradeshows/${externalId}`);
    dispatch({ type: FETCH_TRADESHOW_VIRTUAL_SUCCESS, payload: response.data});
  } catch (error) {
    dispatch({type: FETCH_TRADESHOW_VIRTUAL_FAILURE, payload: formatError(error)});
  }
};
export const registerTradeshow = (tradeshowId, registrants, externalId) => async (dispatch) => {
  try {
    dispatch({type: CREATE_REGISTRATION_REQUEST});
    const response = await sfAPI.put(`/tradeshows/${tradeshowId}`, registrants);
    if (response.data.success){
      dispatch({ type: CREATE_REGISTRATION_SUCCESS, payload: response.data });
      dispatch(fetchTradeshow(externalId));
    }
  } catch (error) {
    dispatch({type: CREATE_REGISTRATION_FAILURE, payload: formatError(error)});
  }
};
export const registerTradeshowVirtual = (tradeshowId, registrants, externalId) => async (dispatch) => {
  try {
    dispatch({type: CREATE_REGISTRATION_REQUEST});
    const response = await sfAPI.put(`/tradeshows/${tradeshowId}`, registrants);
    if (response.data.success){
      dispatch({ type: CREATE_REGISTRATION_SUCCESS, payload: response.data });
      dispatch(fetchTradeshowVirtual(externalId));
    }
  } catch (error) {
    dispatch({type: CREATE_REGISTRATION_FAILURE, payload: formatError(error)});
  }
};
export const registerSession = (tradeshowId, sessionId, externalId) => async (dispatch) => {
  try {
    dispatch({type: CREATE_SESSION_REGISTRATION_REQUEST});
    const response = await sfAPI.post(`/tradeshows/${tradeshowId}?type=session&sid=${sessionId}`);
    if (response.data.success){
      dispatch({ type: CREATE_SESSION_REGISTRATION_SUCCESS, payload: response.data });
      dispatch(fetchTradeshow(externalId));
    }
  } catch (error) {
    dispatch({type: CREATE_SESSION_REGISTRATION_FAILURE, payload: formatError(error)});
  }
};
export const attendSession = (tradeshowId, sessionId, externalId) => async (dispatch) => {
  try {
    dispatch({type: CREATE_SESSION_ATTENDANCE_REQUEST});
    const response = await sfAPI.post(`/tradeshows/${tradeshowId}?type=attendance&sid=${sessionId}`);
    if (response.data.success){
      dispatch({ type: CREATE_SESSION_ATTENDANCE_SUCCESS, payload: response.data });
      // dispatch(fetchTradeshow(externalId));
    }
  } catch (error) {
    dispatch({type: CREATE_SESSION_ATTENDANCE_FAILURE, payload: formatError(error)});
  }
};
export const fetchSessionComments = (sessionId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SESSION_COMMENTS_REQUEST });
    const response = await sfAPI.get(`/tradeshows/${sessionId}/comments`);
    dispatch({ type: FETCH_SESSION_COMMENTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({type: FETCH_SESSION_COMMENTS_FAILURE, payload: formatError(error)});
  }
};
export const createComment = (sessionId, commentBody) => async (dispatch) => {
  try {
    dispatch({type: CREATE_COMMENT_REQUEST});
    const response = await sfAPI.post(`/tradeshows/${sessionId}/comments`, commentBody);

    if (response.data.success){
      dispatch({ type: CREATE_COMMENT_SUCCESS, payload: response.data });
      dispatch(fetchSessionComments(sessionId));
    }
  } catch (error) {
    dispatch({type: CREATE_COMMENT_FAILURE, payload: formatError(error)});
  }
};


// **************** LATER USE ****************

export const fetchCases = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CASES_REQUEST });
    const response = await sfAPI.get("/cases");
    dispatch({ type: FETCH_CASES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CASES_FAILURE, payload: formatError(error) });
  }
};
export const fetchCase = (caseId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CASE_REQUEST });
    const response = await sfAPI.get(`/cases/${caseId}`);
    dispatch({ type: FETCH_CASE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CASE_FAILURE, payload: formatError(error) });
  }
};
export const fetchRMA = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_RMA_REQUEST });
    const response = await sfAPI.get("/rma");
    dispatch({ type: FETCH_RMA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_RMA_FAILURE, payload: formatError(error) });
  }
};

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import userAvatar from "../../images/user.svg";
import HelpModal from "../../components/HelpModal";
import FeedbackModal from "../../components/FeedbackModal";

const DropLink = styled(Link)`
  font-size: 12px;
  &:hover {
    text-decoration:none!important;
  }
`;
const DropLink2 = styled.a`
  font-size: 12px;
  font-family: "Gotham-Medium";
  &:hover {
    text-decoration:none!important;
  }
`;
const DropTriangle = styled.div`
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 7px solid #fff !important;
  border-bottom-color: #fff !important;
  position: absolute;
  top: -6px;
  right: 10px;
`;

const HeaderSettings = (props) => {
  const refURL = localStorage.getItem('ref');
  const { user } = props;
  if (!_.isEmpty(user)) {
    return (
      <Dropdown className="savHeaderDrop" style={{ marginLeft: 10 }}>
        <Dropdown.Toggle as="span">
          <img src={userAvatar} width={40} className="onHov" alt="user" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="f10">
          <DropTriangle />
          <Dropdown.Item as={DropLink} className="f10" to="/preferences">
            My Settings
          </Dropdown.Item>
          <Dropdown.Item as="div" className="f10">
            <HelpModal />
          </Dropdown.Item>
          <Dropdown.Item as="div" className="f10">
            <FeedbackModal user={user} />
          </Dropdown.Item>
          <Dropdown.Item as={DropLink2} href={`${refURL}/Customers/secur/logout.jsp`}>
            <div>Log out</div>
            <div className="f10 med truncate nou">{user.username}</div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return null;
};
export default HeaderSettings;

import React from 'react';
import styled from 'styled-components';
import {Popover, OverlayTrigger } from "react-bootstrap";
import {FiAward} from 'react-icons/fi';
import {parseCurrency} from "../Utilities";
import Authorized from "../../images/AmbassadorBadges/Authorized.png";
import Silver from "../../images/AmbassadorBadges/Silver.png";
import Gold from "../../images/AmbassadorBadges/Gold.png";
import Platinum from "../../images/AmbassadorBadges/Platinum.png";
import Ambassador from "../../images/AmbassadorBadges/Ambassador.png";
import EliteAmbassador from "../../images/AmbassadorBadges/EliteAmbassador.png"
import PlatinumElite from "../../images/AmbassadorBadges/PlatinumElite.png";
import Certified from "../../images/AmbassadorBadges/Certified.svg";
import DoneIcon from "../../images/Orders/done.svg";

const BadgeRow = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:12px;
`;
const Badge = styled.img`
  cursor:pointer;
  width: 75px;
  border: ${props => props.active ? '5px solid #4cd964' : '5px solid #9b9b9b'};
  border-radius: 50%;
`;
const ProgressBar = styled.div`
  background-color: #9b9b9b;
  height: 10px;
  width: -webkit-fill-available;
  width: -moz-available;

`;
const ProgressBarFill =  styled.div`
  background-color: #4cd964;
  height: 10px;
  width : ${props => props.width};
`;
const PopoverRow = styled.div`
  display:grid;
  grid-template-columns: 34% 33% 33%;
  align-items:center;
  padding:4px;
`;
const PopoverRowElite = styled.div`
  display:grid;
  grid-template-columns: 25% 25% 25% 25%;
  align-items:center;
  padding:4px;
`;
const AwardRow = styled.div`
  display: grid;
  align-items:center;
  grid-template-columns: 10% 90%;
  border-top:1px solid #b4b4b452;
  padding: 8px;
`;

const IntegratorProgress = ({account, programResult}) => {

  let highestSales = 0;
  if (programResult.revenuePriorYear > programResult.revenue) {
    highestSales = programResult.revenuePriorYear;
  }else{
    highestSales = programResult.revenue;
  }

  const getAccountTier = (programResult) => {
    if (account.tierEarned.toUpperCase() === 'AUTHORIZED') {
      return 1;
    }
    if (account.tierEarned.toUpperCase() === 'AMBASSADOR') {
      return 2;
    }
    if (account.tierEarned.toUpperCase() === 'ELITE AMBASSADOR') {
      return 3;
    }
  };

  const getProgressPercent = (step, programResult) => {
    const tier = getAccountTier(programResult);
    if (step === 1){
      if (tier >= 1){
        return "100%"
      } else {
        return '0%'
      }
    }
    if (step === 2){
      if (tier >= 2){
        return '100%'
      } else {
        return '0%'
      }
    }
    if (step === 3){
      if (tier >= 3){
        return "100%"
      } else {
        return '0%'
      }
    }
    if (step === 4){
      if (tier >= 5){
        return "100%"
      } else {
        return '0%'
      }
    }
  }

  const renderPopoverCertified = (title, reqs, percent, shipText, badge, tier) => {
    return (
      <OverlayTrigger
        trigger={['hover','focus']}
        placement="bottom"
        overlay={
          <Popover
            id={`popover-${title}`}
            className="sav-pop"
          >
            <Popover.Title
              as="div"
              className="f12 med bg-white text-center"
            >
              {`${title}`}
            </Popover.Title>
            <Popover.Content>
              <div className="f10 book gray text-center m-b-5">
                Select Product Access through Wave Electronics
              </div>
              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Trainings Complete</div>
                  <div className="f20 oj med text-center">{programResult.coreTrainings}</div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">YTD<br />Sales</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenue)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Prior YTD<br />Sales</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenuePriorYear)}
                  </div>
                </div>
              </PopoverRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier ? '#979797' : '#4cd964'} />
                  <div>
                    <div className="f12 med">{percent} off MSRP</div>
                    <div className="light-gray f10">
                      *Excluding non-discountable or promotional products
                    </div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#979797' : '#4cd964'} />
                  <div>
                    <div className="f12 med">Wave Premier Program</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#979797' : '#4cd964'} />
                  <div>
                  <div className="f12 med">{shipText}</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#979797' : '#4cd964'} />
                  <div>
                    <div className="f12 med">Access to Software Beta</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#979797' : '#4cd964'} />
                  <div>
                    <div className="f12 med">Tier 1 Technical Support through Wave Electronics</div>
                  </div>
                </AwardRow>
            </Popover.Content>
          </Popover>
        }
      >
        {/* <Badge src={badge} active={getAccountTier(programResult) >= tier ? true : false} /> */}
        <Badge src={badge} active={account.tierEarned === 'Certified' ? true : false} />
      </OverlayTrigger>
    )
  }

  const renderPopover = (title, reqs, percent, shipText, badge, tier) => {
    return (
      <OverlayTrigger
        trigger={['hover','focus']}
        placement="bottom"
        overlay={
          <Popover
            id={`popover-${title}`}
            className="sav-pop"
          >
            <Popover.Title
              as="div"
              className="f12 med bg-white text-center"
            >
              {`${title}`}
            </Popover.Title>
            <Popover.Content>
              <div className="f10 book gray text-center m-b-5">
                Volume Requirements: <span className="black med">${reqs}</span>
              </div>
              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Trainings Complete</div>
                  <div className="f20 oj med text-center">{programResult.coreTrainings}</div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">YTD<br />Sales</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenue)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Prior YTD<br />Sales</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenuePriorYear)}
                  </div>
                </div>
              </PopoverRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier ? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">{percent} off MSRP</div>
                    <div className="light-gray f10">
                      *Excluding non-discountable or promotional products
                    </div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Quarterly VIR eligibility</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                  <div className="f12 med">{shipText}</div>
                  <div className="f10 book light-gray">
                  *Does not apply to oversized items.
                  </div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Access to Software Beta</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Initial, Discounted Showroom Order</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Standard Savant Technical Support</div>
                  </div>
                </AwardRow>
            </Popover.Content>
          </Popover>
        }
      >
        {/* <Badge src={badge} active={getAccountTier(programResult) >= tier ? true : false} /> */}
        <Badge src={badge} active={getAccountTier(programResult) >= tier ? true : false} />
      </OverlayTrigger>
    )
  }

  const renderAmbassadorPopover = (title, reqs, percent, shipText, badge, tier) => {
    return (
      <OverlayTrigger
        trigger={['hover','focus']}
        placement="bottom"
        overlay={
          <Popover
            id={`popover-${title}`}
            className="sav-pop"
          >
            <Popover.Title
              as="div"
              className="f12 med bg-white text-center"
            >
              {`${title}`}
            </Popover.Title>
            <Popover.Content>
              <div className="f10 book gray text-center m-b-5">
                Volume Requirements: <span className="black med">${reqs}</span>
              </div>
              <div className="f10 book gray text-center m-b-5">
                Entertainment, Network & UI: <span className="black med">$75,000</span>
              </div>
              <div className="f10 book gray text-center m-b-5">
                Environmental & Power: <span className="black med">$75,000</span>
              </div>

              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Trainings Complete</div>
                  <div className="f20 oj med text-center">{programResult.coreTrainings}</div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">YTD<br />Sales</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenue)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Prior YTD<br />Sales</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenuePriorYear)}
                  </div>
                </div>
              </PopoverRow>


              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Beta Participation</div>
                  <div className="f20 oj med text-center">
                    <img src={DoneIcon} height={25} />
                  </div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">Entertainment,<br />Network & UI</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.adoptionEntertainmentNetworkUI)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Environmental<br />& Power</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.adoptionEnvironmentalPower)}
                  </div>
                </div>
              </PopoverRow>


                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier ? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">{percent} off MSRP</div>
                    <div className="light-gray f10">
                      *Excluding non-discountable or promotional products
                    </div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Quarterly VIR eligibility</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                  <div className="f12 med">{shipText}</div>
                  <div className="f10 book light-gray">
                  *Does not apply to oversized items.
                  </div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Software & Hardware Beta participation required</div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">60% off MSRP Showroom Discount</div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Priority Savant Technical Support</div>
                  </div>
                </AwardRow>
            </Popover.Content>
          </Popover>
        }
      >
        <Badge src={badge} active={getAccountTier(programResult) >= tier ? true : false} />
      </OverlayTrigger>
    )
  }

  const renderElitePopover = (title, reqs, percent, shipText, badge, tier) => {
    return (
      <OverlayTrigger
        trigger={['hover','focus']}
        placement="bottom"
        overlay={
          <Popover
            id={`popover-${title}`}
            className="sav-pop"
          >
            <Popover.Title
              as="div"
              className="f12 med bg-white text-center"
            >
              {`${title}`}
            </Popover.Title>
            <Popover.Content>
              <div className="f10 book gray text-center m-b-5">
                Volume Requirements: <span className="black med">${reqs}</span>
              </div>
              <div className="f10 book gray text-center m-b-5">
                Entertainment, Network & UI: <span className="black med">$150,000</span>
              </div>
              <div className="f10 book gray text-center m-b-5">
                Environmental & Power: <span className="black med">$150,000</span>
              </div>
              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Trainings Complete</div>
                  <div className="f20 oj med text-center">{programResult.coreTrainings}</div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">YTD<br />Sales</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenue)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Prior YTD<br />Sales</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.revenuePriorYear)}
                  </div>
                </div>
              </PopoverRow>


              <PopoverRow style={{borderTop:"1px solid #b4b4b452"}}>
                <div style={{height:70, borderRight: '1px solid #b4b4b452', padding:4}}>
                  <div className="f10 book gray text-center">Beta Participation</div>
                  <div className="f20 oj med text-center">
                    <img src={DoneIcon} height={25} />
                  </div>
                </div>
                <div style={{height:70, padding:4, borderRight: '1px solid #b4b4b452' }}>
                  <div className="f10 book gray text-center">Entertainment,<br />Network & UI</div>
                  <div className="f12 oj med text-center" style={{marginTop:4}}>
                    {parseCurrency(programResult.adoptionEntertainmentNetworkUI)}
                  </div>
                </div>
                <div style={{height:70, padding:4}}>
                  <div className="f10 book gray text-center">Environmental<br />& Power</div>
                  <div className="f12 oj text-center med" style={{marginTop:4}}>
                    {parseCurrency(programResult.adoptionEnvironmentalPower)}
                  </div>
                </div>
              </PopoverRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier ? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">{percent} off MSRP</div>
                    <div className="light-gray f10">
                      *Excluding non-discountable or promotional products
                    </div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Quarterly VIR eligibility</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">{shipText}</div>
                    <div className="f10 book light-gray">
                      *Does not apply to oversized items.
                    </div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Software & Hardware Beta participation required</div>
                  </div>
                </AwardRow>
                <AwardRow>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Sponsored Showroom Power Demo</div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">75% off MSRP Showroom Discount</div>
                  </div>
                </AwardRow>
                <AwardRow style={tier < 2 ? {display: 'none'} : null}>
                  <FiAward size={20} fill={getAccountTier(programResult) >= tier? '#4cd964' : '#979797'} />
                  <div>
                    <div className="f12 med">Priority Savant Technical Support</div>
                  </div>
                </AwardRow>
            </Popover.Content>
          </Popover>
        }
      >
        <Badge src={badge} active={getAccountTier(programResult) >= tier ? true : false} />
      </OverlayTrigger>
    )
  }

  return (
    <BadgeRow>
      {renderPopoverCertified("Certified", "25,000", "40%", "Wave Freight Program", Certified, 1)}
      <ProgressBar>
        <div style={{width: '100%', height: 10, backgroundColor: '#fff'}} />
      </ProgressBar>
      {renderPopover('Authorized', '50,000', '40%', 'Standard shipping rates', Authorized, 1 )}
      <ProgressBar>
        <ProgressBarFill width={getProgressPercent(2, programResult)} />
      </ProgressBar>
      {renderAmbassadorPopover('Ambassador', '150,000', '50%', 'Free US ground freight on all shipments $2000+', Ambassador, 2 )}
      <ProgressBar>
        <ProgressBarFill width={getProgressPercent(3, programResult)} />
      </ProgressBar>
      {renderElitePopover('Elite Ambassador', '300,000', '55%', 'Free US ground freight on all shipments', EliteAmbassador, 3 )}
      
    </BadgeRow>
  );
}

export default IntegratorProgress;
import React, { useState } from "react";
import { Table, Container, Row, Col, Collapse } from "react-bootstrap";
import styled from "styled-components";

const SavTable = styled(Table)`
  font-size: 12px;
  text-align: center;
  th {
    background-color: #979797;
    color: #fff;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    width: 300px;
  }
  td {
    vertical-align: middle;
    width: 300px;
  }
`;

const Header = styled.div`
  font-size: 16px;
  margin-top: 30px;
  font-family: "Gotham-Medium";
  text-align: center;
`;

const ProgramTable = props => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    window.scrollTo({
      bottom: 0,
      behavior: "smooth"
    });
  };
  return (
    <React.Fragment>
      <Header>
        2024 Savant Integrator Program
        <br />
        <div className="f12">
        Savant offers an Integrator program, delivering benefits based on previous and current year's purchase volume. Savant defines 2024 Integrator levels based on 2023 purchase volume. If, at any point in 2024, purchases exceed current tier, integrator will be moved to next tier and become eligible for all benefits.
      </div>
      <br />
        <button
          onClick={() => handleClick()}
          aria-controls="collapse-table"
          aria-expanded={open}
          className="sav-btn__orange"
        >
          {open ? "Hide Details" : "Show Details"}
        </button>
      </Header>
      
        <Collapse in={open}>
        <div id="collapse-table">

          

          <div style={{ marginTop: 30 }}>
            <SavTable bordered hover responsive className="d-none d-md-block ">
              <thead>
                <tr>
                  <th></th>
                  <th>Certified</th>
                  <th>Authorized</th>
                  <th>Ambassador</th>
                  <th>Elite Ambassador</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <th>Annual Volume Requirements</th>
                  <td>---</td>
                  <td>$50k+</td>
                  <td>$150k+</td>
                  <td>$300k+</td>
                </tr>

                <tr>
                  <th>Category Adoption</th>
                  <td>---</td>
                  <td>---</td>
                  <td>
                    $75k Entertainment, Network & UI<br />
                    $75k Environmental & Power
                  </td>
                  <td>
                    $150k Entertainment, Network & UI<br />
                    $150k Environmental & Power
                  </td>
                </tr>

                <tr>
                  <th>Product Access</th>
                  <td>Selected Product Access through Wave Electronics</td>
                  <td colSpan="3">Complete Product Access</td>
                </tr>

                <tr>
                  <th rowSpan="2">2024 Hardware Discount Structure</th>
                  <td>
                    40% off MSRP
                  </td>
                  <td>
                    40% off MSRP
                  </td>
                  <td>
                    50% off MSRP
                  </td>
                  <td>
                    55% off MSRP
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <i>unless otherwise noted as non-discountable or
                    promotional pricing</i>
                  </td>
                </tr>

                <tr>
                  <th>Software License Discount</th>
                  <td>---</td>
                  <td>---</td>
                  <td colSpan={2}>20% Software License Discount</td>
                </tr>

                <tr>
                  <th>Subscription Discount/Rebate</th>
                  <td>---</td>
                  <td>---</td>
                  <td>
                    <b>20% Subscription Discount</b><br />
                    purchased on the Savant Store<br />
                    <b>OR</b><br />
                    <b>20% Subscription Rebate</b><br />
                    purchased via Savant Central Management
                  </td>
                  <td>
                    <b>20% Subscription Discount</b><br />
                    purchased on the Savant Store<br />
                    <b>OR</b><br />
                    <b>30% Subscription Rebate</b><br />
                    purchased via Savant Central Management
                  </td>
                </tr>
                
                <tr>
                  <th rowSpan="2">Quarterly Volume Incentive Rebates</th>
                  <td rowSpan="2">Wave Premier Program</td>
                  <td>
                    <div>
                      1% Quarterly VIR > $50k
                      <br />
                      3% Quarterly VIR > $100k
                      <br />
                      5% Quarterly VIR > $200k
                      <br />
                      7% Quarterly VIR > $250k
                    </div>
                  </td>
                  <td>
                    <div>
                      3% Quarterly VIR > $50k
                      <br />
                      5% Quarterly VIR > $100k
                      <br />
                      7% Quarterly VIR > $200k
                      <br />
                      10% Quarterly VIR > $250k
                    </div>
                  </td>
                  <td>
                    <div>
                      5% Quarterly VIR > $50k
                      <br />
                      7% Quarterly VIR > $100k
                      <br />
                      10% Quarterly VIR > $200k
                      <br />
                      12% Quarterly VIR > $250k
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <i style={{ textAlign: "left" }}>
                      VIR Delivered as Product Credit. Orders placed less than 5 business days prior to the end of the Quarter may not qualify for VIR
                    </i>
                  </td>
                </tr>

                <tr>
                  <th>Lead Generation</th>
                  <td>---</td>
                  <td>---</td>
                  <td>---</td>
                  <td>Eligible for Pre-Qualified Savant Leads</td>
                </tr>

                <tr>
                  <th rowSpan={2}>Freight</th>
                  <td rowSpan={2}>Wave Freight Program</td>
                  <td rowSpan={2}>Standard Rates</td>
                  <td>Free ground freight, shipments $2000+</td>
                  <td>Free ground freight on all shipments</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <i>Free Ground freight does not apply to oversized items, including USAI Lighting fixtures, shade fabric and tubes, custom soundbar grilles, battery storage systems, etc.  Please see Savant Store for details </i>
                  </td>
                </tr>

                <tr>
                  <th>Beta</th>
                  <td>Access to Software Beta</td>
                  <td>Access to Software Beta</td>
                  <td colSpan={2}>Software & Hardware Beta participation required</td>
                </tr>

                <tr>
                  <th>Additional Benefits</th>
                  <td>---</td>
                  <td>Initial, Discounted Showroom order</td>
                  <td>60% off MSRP Showroom Discount</td>
                  <td>
                    75% off MSRP Showroom Discount<br />
                    Sponsored Showroom Power Demo
                  </td>
                </tr>

                <tr>
                  <th>Savant Community Access</th>
                  <td>Savant University, Documentataion, all-new Subscription Status view, more...</td>
                  <td colSpan={3}>
                    Savant University, Documentation, all-new Subscription Status view, Live Chat with Buddy Savant Personal Assistant, Technical Support Case Creation, Order Tracking & Account Tools, and so much more...
                  </td>
                </tr>

                <tr>
                  <th rowSpan={2}>Technical Support</th>
                  <td rowSpan={2}>Tier 1 Technical Support through Wave Electronics</td>
                  <td>Standard Savant Technical Support</td>
                  <td colSpan={2}>Priority Savant Technical Support</td>
                </tr>
                <tr>
                  <td colSpan={3}>Access to Savant Technical Support for all trained employees - Online troubleshooting track required</td>
                </tr>

                <tr>
                  <th>Training Requirements</th>
                  <td colSpan="4">
                    Completion of online Fundamentals or CORE classroom training
                    by at least one employee.
                  </td>
                </tr>

              </tbody>
            </SavTable>
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

export default ProgramTable;

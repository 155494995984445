import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Nav, Tab} from "react-bootstrap";
import { SavSearch } from "../../components/Styled";
import {parseDateShort, eliteSearchAlgorithm, parseDateInt} from "../../components/Utilities";
import styled from "styled-components";
import _ from "lodash";

const linkStyle = {
  padding: 16,
  fontFamily: "Gotham-Medium",
  backgroundColor: "#fafaf9",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12,
  justifyContent:"space-between",
  marginTop:10,
};
const linkStyleActive = {
  padding: 16,
  fontFamily: "Gotham-Medium",
  backgroundColor: "#dbd9d6",
  color: "#3f3f3f",
  borderRadius: "unset",
  fontSize: 12,
  justifyContent:"space-between",
  marginTop:10,
};
const ReleaseHeader = styled.div`
  font-size:24px;
  font-family:"Gotham-Medium";
`;
const ReleaseSubHeader = styled.div`
  font-size:18px;
  font-family:"Gotham-Medium";
  margin-top:20px;
`;

const NewFeaturePill = styled.span`
  background-color: #4cd964;
  color:#fff;
  border: 1px solid #4cd964;
  border-radius: 6px;
  padding: 4px;
  font-size:8px;
  font-family:"Gotham-Book";
  margin: 0px 5px;
`;
const NewProductPill = styled.span`
  background-color: #00C7CC;
  color:#fff;
  border: 1px solid #00C7CC;
  border-radius: 6px;
  padding: 4px;
  font-size:8px;
  font-family:"Gotham-Book";
  margin: 0px 5px;
`;
const CardHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items:center;
  justify-content: center;
  height:30px;
`;
const AppIcon = styled.img`
  border: 1px solid #fff;
  border-radius: 4px;
  height:30px;
`;
const hidden = {
  display:'none'
}



const BetaReleaseHistory = ({software, sam, hideSAM}) => {
  const [key, setKey] = useState("");
  const [softwareList, setSoftwareList] = useState([]);
  const [softwareListFiltered, setSoftwareListFiltered] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!_.isEmpty(software)){
      const betaSoftware = _.filter(software, (s) => s.type === "daVinci Beta");
      setSoftwareList(betaSoftware);
      setSoftwareListFiltered(betaSoftware);
      setKey(betaSoftware[0].sfid);
    }
  },[software]);

  useEffect(() => {
    if(!_.isEmpty(searchString)){
      let tempSoftware = _.filter(
        softwareList, (s) => eliteSearchAlgorithm(searchString, s)
        )
      setSoftwareListFiltered(tempSoftware);
    } else {
      const betaSoftware = _.filter(software, (s) => s.type === "daVinci Beta");
      setSoftwareListFiltered(betaSoftware);
    }
    
  },[searchString]);

  useEffect(() => {
    const findKey = _.find(softwareListFiltered, (s) => s.sfid === key);
    if (_.isEmpty(findKey) && softwareListFiltered.length > 0){
      setKey(softwareListFiltered[0].sfid)
    }

  },[softwareListFiltered])

  const createMarkup = richText => {
    return { __html: richText };
  };

  const renderNavItems = () => {
    if (softwareListFiltered.length === 0){
      return (
        <div className="book f12 light-gray m-t-20 text-center">No software releases found that contain the search term entered above.</div>
      )
    }
    return softwareListFiltered.map((s) => {
      return (
        <Nav.Item key={s.sfid}>
          <Nav.Link className="vertical-align" eventKey={s.sfid} style={key === s.sfid ? linkStyleActive : linkStyle}>
            <div className="med f12 black">
              {s.name}
              <br />
              <span className="book f12 light-gray">{parseDateInt(s.releaseDate)}</span>
            </div>
            
            <div style={{display:"flex", justifyContent:"flex-end"}}>
              <NewFeaturePill style={!s.newFeatures ? hidden : null }>New Features!</NewFeaturePill>
              <NewProductPill style={!s.betaEnhancements ? hidden : null }>Enhancements!</NewProductPill>
            </div>
            
          </Nav.Link>
        </Nav.Item>
      )
    })
  };
  const renderTabPanes = () => {
    return softwareListFiltered.map((s) => {
      let descSearch= s.description;
      let featureSearch = s.newFeatures;
      let knownIssuesSearch = s.betaKnownIssues;
      let enhanceSearch = s.betaEnhancements;
      let bugSearch = s.betaBugFixes;

      if (searchString !== ""){
        if (searchString.length > 1){
          const stringParsed = searchString.replace(/[^\w\s]/gi, "");
          if (s.description){
            descSearch = s.description.replace(
              new RegExp(stringParsed, 'gi'),
              match =>  `<em class='highlighted'>${match}</em>`);
          }
          if (s.newFeatures){
            featureSearch = s.newFeatures.replace(
              new RegExp(stringParsed, 'gi'),
              match =>  `<em class='highlighted'>${match}</em>`);
          }

          if (s.betaKnownIssues){
            knownIssuesSearch = s.betaKnownIssues.replace(
              new RegExp(stringParsed, 'gi'),
              match =>  `<em class='highlighted'>${match}</em>`);
          }
          if (s.betaEnhancements){
            enhanceSearch = s.betaEnhancements.replace(
              new RegExp(stringParsed, 'gi'),
              match =>  `<em class='highlighted'>${match}</em>`);
          }
          if (s.betaBugFixes){
            bugSearch = s.betaBugFixes.replace(
              new RegExp(stringParsed, 'gi'),
              match =>  `<em class='highlighted'>${match}</em>`);
          }
        }
      }

      const renderSAM = () => {
        if (sam){
          return (
            <Fragment>
              <CardHeader style={hideSAM ? hidden : null}>
                <div className="med f12">Savant Application Manager</div>
              </CardHeader>
              <div className="text-center">
                <a href={sam.computerDownload} target="_blank" rel="noopener noreferrer">
                  <AppIcon src={sam.img} />
                </a>
                <div className="lightGray f10 book m-t-5">
                  Version: {sam.vers}
                </div>
                <div className="lightGray f10 book">
                  Released: {parseDateShort(sam.release)}
                </div>
              </div>
            </Fragment>
          )
        }
        return null;
      }


      return (
        <Tab.Pane eventKey={s.sfid} key={s.sfid}>
          <Container>
            <Row className="vertical-align">
              <Col sm={7}>
                <ReleaseHeader>{s.name}</ReleaseHeader>
                <span className="book f12 light-gray">Released {s.releaseDate}</span><br />
              </Col>
              <Col sm={5}>
                {renderSAM()}
              </Col>
            </Row>
            <hr />
            <div className="m-t-20" dangerouslySetInnerHTML={createMarkup(descSearch)} />
            <hr />
            <ReleaseSubHeader>New Features</ReleaseSubHeader>
            <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(featureSearch || "No new features in this release")} />
            <hr />
            <ReleaseSubHeader>Known Issues</ReleaseSubHeader>
            <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(knownIssuesSearch || "No known issues in this release")} />
            <hr />
            <ReleaseSubHeader>Enhancements</ReleaseSubHeader>
            <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(enhanceSearch || "No enhancements in this release")} />
            <hr />
            <ReleaseSubHeader>Bug Fixes</ReleaseSubHeader>
            <div className="m-t-10" dangerouslySetInnerHTML={createMarkup(bugSearch || "No bug fixes in this release")} />
          </Container>
          
        </Tab.Pane>
      )
    })
  }

  if(!_.isEmpty(software)){
    return (
        <Container className="m-t-20">
          <Row className="m-b-20">
            <Col sm={4}>
              <SavSearch value={searchString} onChange={(e) => setSearchString(e.target.value)} placeholder="Search..." type="search" />
            </Col>
            <Col sm={8}></Col>
          </Row>
          <Tab.Container
            id="left-tabs-software"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Row>
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  {renderNavItems()}
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {renderTabPanes()}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
    )
  }
  return <div style={{height:800}} />;
  
}

export default BetaReleaseHistory;
import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Nav, Tab} from "react-bootstrap";
import { FaRegLightbulb, FaDesktop, FaMobileAlt, FaTabletAlt, FaCloudDownloadAlt, FaApple } from "react-icons/fa";
import {MdAndroid} from "react-icons/md"
import SavBanner from "../../components/SavBanner";
import { SubHeader } from "../../components/Styled";
import {parseDateShort} from "../../components/Utilities";
import styled from "styled-components";
import { fetchLatestSoftware, fetchSoftware } from "../../actions";
import { createLoadingSelector } from "../../selectors";
import { connect } from "react-redux";
import _ from "lodash";
import ReleaseHistory from "./ReleaseHistory";

const CardHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items:center;
  justify-content: space-between;
  height:50px;
`;
const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  border-top:1px solid #b4b4b452;
  padding:10px;
  height:50px;
`;
const CardBody = styled.div`
  height: 100px;
  padding: 10px;
`;
const CardBackDetails = styled.div`
  display: flex;
  align-items:center;
  justify-content: space-between;
`;
const CardBackIcons = styled.div`
  display: flex;
  align-items:center;
  justify-content: space-around;
`;
const AppIcon = styled.img`
  border: 1px solid #fff;
  border-radius: 4px;
  height:35px;
`;
const DeviceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25px;
`;
const tabActive = {
  color: "#ff5f00"
};

const SoftwareCard = ({s}) => {

  const renderDesktop = (s) => {
    if (s.computerDownload){
      return <FaDesktop size="10px" fill="#979797" />
    }
    return null;
  }
  const renderMobile = (s) => {
    if (s.playDownload || s.appleDownload){
      return (
        <Fragment>
          <FaTabletAlt size="10px" fill="#979797" />
          <FaMobileAlt size="10px" fill="#979797" />
        </Fragment>
      )
    }
    return null;
  }
  const renderIcons = (s) => {
    if (s.appleDownload && s.playDownload){
      return (
        <Fragment>
          <div className="text-center">
            <a href={`${s.appleDownload}`} target="_blank" rel="noopener noreferrer">
              <FaApple size="45px" className="onHov" />
            </a>
            <div className="lightGray f10 book m-t-5">
              Version: {s.vers}
            </div>
            <div className="lightGray f10 book">
              Released: {parseDateShort(s.release)}
            </div>
          </div>
          <div className="text-center">
            <a href={`${s.playDownload}`} target="_blank" rel="noopener noreferrer">
            <MdAndroid size="45px" className="onHov"  />
            </a>
            <div className="lightGray f10 book m-t-5">
              Version: {s.vers}
            </div>
            <div className="lightGray f10 book">
              Released: {parseDateShort(s.release)}
            </div>
          </div>
        </Fragment>
      )
    }
    if (s.appleDownload && !s.playDownload){
      return (
        <div className="text-center">
          <a href={`${s.appleDownload}`} target="_blank" rel="noopener noreferrer">
            <FaApple size="45px" className="onHov" />
          </a>
          <div className="lightGray f10 book m-t-5">
            Version: {s.vers}
          </div>
          <div className="lightGray f10 book">
            Released: {parseDateShort(s.release)}
          </div>
        </div>
      )
    }
    if (s.computerDownload){
      return (
        <div className="text-center">
          <a href={`${s.computerDownload}`} target="_blank" rel="noopener noreferrer">
          <FaCloudDownloadAlt size="45px"className="onHov"  />
          </a>
          <div className="lightGray f10 book m-t-5">
            Version: {s.vers}
          </div>
          <div className="lightGray f10 book">
            Released: {parseDateShort(s.release)}
          </div>
        </div>
      )
    }
    return null;
  }
  return (
    <Col lg={4} md={6} style={{marginTop:15}}>
      <div style={{border:"1px solid #b4b4b452", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}}>
            <CardHeader>
              <AppIcon src={s.img} style={!s.img ? {display:'none'} : null} />
              <FaRegLightbulb size="35px" style={s.img ? {display:'none'} : null} />
              <div className="med f12">{s.name}</div>
              <DeviceDiv>
                {renderDesktop(s)}
                {renderMobile(s)}
              </DeviceDiv>
            </CardHeader>
            <CardBody>
              <CardBackIcons>
                {renderIcons(s)}
              </CardBackIcons>
              <CardBackDetails>
                
              </CardBackDetails>
            </CardBody>
            <CardFooter>
              <button className="sav-btn__orange--text">
                <a href={`${s.documentationUrl}`} className="oj linkHover">Documentation</a>
              </button>
            </CardFooter>
          </div>
    </Col>
  )
}

const LatestSoftware = ({latestSoftware, fetchLatestSoftware, software, fetchSoftware, fetchingSoftware, user}) => {
  const [tabKey, setTabKey] = useState("software");
  const [sam, setSam] = useState(null);
  const [prodSoftware, setProdSoftware] = useState([]);
  const [hideSAM, setHideSAM] = useState(false);


  useEffect(() => {
    document.title = "Savant Community | Software & Apps"
  },[]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'US Distribution'){
        setHideSAM(true);
      }
    }
  },[user])

  useEffect(() => {
    if (_.isEmpty(software) && !fetchingSoftware) {
      fetchSoftware();
    }
    if (_.isEmpty(latestSoftware)) {
      fetchLatestSoftware();
    }
  },[software, latestSoftware]);

  useEffect(() => {
    if (software){
      setProdSoftware(_.filter(software.data, (s) => s.type === "daVinci"));
    }
  },[software]);

  useEffect(() => {
    if (latestSoftware){
      const temp = _.find(latestSoftware.data, (s) => s.name === "Savant Application Manager");
      setSam(temp);
    }
  },[latestSoftware]);

  const renderCards = () => {
    if (latestSoftware.data){
      return latestSoftware.data.map((s) => {
        if (!s.isLegacy && !s.isBeta){
          return (
            <SoftwareCard s={s} key={s.sfid} />
          )
        }
        
      })
    }
  }
  const renderLegacyCards = () => {
    if (latestSoftware.data){
      return latestSoftware.data.map((s) => {
        if (s.isLegacy && !s.isBeta){
          return (
            <SoftwareCard s={s} key={s.sfid} />
          )
        }
        
      })
    }
  }

  return (
    <div>
      <SavBanner text="Software & Apps" padding="40px" />
      <Tab.Container
          id="software-tabs"
          activeKey={tabKey}
          onSelect={k => setTabKey(k)}
        >
          <Nav className="justify-content-center m-t-20">
            <Nav.Item>
              <Nav.Link
                className="tab-link-overview"
                eventKey="software"
                style={tabKey === "software" ? tabActive : null}
              >
                Runtime Software
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="tab-link-overview"
                eventKey="apps"
                style={tabKey === "apps" ? tabActive : null}
              >
                Apps
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="software">
              <ReleaseHistory software={prodSoftware} sam={sam} hideSAM={hideSAM} />
            </Tab.Pane>
            <Tab.Pane eventKey="apps">
            <Container className="m-t-30">
              <SubHeader>Savant Apps</SubHeader>
              <Row style={{paddingBottom:30}}>
                {renderCards()}
              </Row>
              <hr />
              <SubHeader>Legacy Apps</SubHeader>
              <Row>
                {renderLegacyCards()}
              </Row>
              
            </Container>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
    </div>
  )

}

const fetchingSoftware = createLoadingSelector(["FETCH_SOFTWARE"]);

const mapStateToProps = state => {
  return {
    latestSoftware: state.latestSoftware,
    software: state.software,
    fetchingSoftware: fetchingSoftware(state),
    user: state.user.data
  };
};

export default connect(mapStateToProps, {
  fetchLatestSoftware, fetchSoftware
})(LatestSoftware);
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Dropdown, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import SavColumnSort from "../../components/SavColumnSort";
import SavPagination from "../../components/SavPagination";
import SavDrop from "../../components/SavDrop";
import {
  SubHeader,
  SavSearch,
  SavRow,
  SavSpanName,
  SavLink,
  SavLabel,
} from "../../components/Styled";
import { fetchContacts, fetchCase } from "../../actions";

const CaseContainer = styled(Container)`
  background-color: #fafaf9;
  padding: 20px 30px;
  margin-bottom: 15px;
`;

const SavOutput = styled.div`
  margin-bottom: 15px;
  font-size: 12px;
`;

const SavTable = styled(Table)`
  font-size: 12px;
  //text-align: center;
  th {
    background-color: #f2f2f2;
    color: #3f3f3f;
    font-family: "Gotham-Medium";
    text-transform: uppercase;
    vertical-align: middle;
    max-width: 200px;
  }
  td {
    vertical-align: middle;
    max-width: 200px;
  }
`;

const parseDate = (date) => {
  return new Date(JSON.parse(JSON.stringify(date))).toLocaleString();
};

const CaseDetail = (props) => {
  const { activeCase, fetchCase, contacts, fetchContacts } = props;
  const caseId = props.match.params.caseId;

  useEffect(() => {
    if (_.isEmpty(activeCase)) {
      fetchCase(caseId);
    } else {
      if (activeCase.sfid !== caseId) {
        fetchCase(caseId);
      }
    }
  }, [activeCase]);

  const parseLineBreaks = (string) => {
    if (string) {
      return string.split("\n").map((text, index) => (
        <React.Fragment key={`${text}-${index}`}>
          {text}
          <br />
        </React.Fragment>
      ));
    }
  };

  const renderCaseDetail = () => {
    switch (activeCase.type) {
      case "Pro Savant Software Issue":
        return (
          <CaseContainer>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} className="med f14">
                CASE DETAIL
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <SavOutput>
                  <SavLabel>Case Type</SavLabel>
                  {activeCase.type}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Case Number</SavLabel>
                  {activeCase.caseNumber}
                </SavOutput>
              </Col>
              <Col sm={6}>
                <SavOutput>
                  <SavLabel>Opened</SavLabel>
                  {parseDate(activeCase.created)}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Last Updated</SavLabel>
                  {parseDate(activeCase.modified)}
                </SavOutput>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <SavOutput>
                  <SavLabel>Case Description</SavLabel>
                  {activeCase.subject}
                </SavOutput>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <SavOutput>
                  <SavLabel>Case Detail</SavLabel>
                  {parseLineBreaks(activeCase.description)}
                </SavOutput>
              </Col>
              <Col sm={12}></Col>
            </Row>
            <Row>
              <Col sm={6}>
                <SavOutput>
                  <SavLabel>Project Name</SavLabel>
                  {activeCase.projectName}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Programming Software</SavLabel>
                  {activeCase.systemSoftware}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Programming Software Version</SavLabel>
                  {activeCase.softwareVersion}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Apps in Use</SavLabel>
                  {activeCase.userInterfaces || "N/A"}
                </SavOutput>
                <SavOutput>
                  <SavLabel>UI Devices in Use</SavLabel>
                  Placeholder
                </SavOutput>
              </Col>
              <Col sm={6}>
                <SavOutput>
                  <SavLabel>Host UID</SavLabel>
                  {activeCase.hostUid || "N/A"}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Host Model</SavLabel>
                  Placeholder
                </SavOutput>
                <SavOutput>
                  <SavLabel>Host Serial</SavLabel>
                  Placeholder
                </SavOutput>
                <SavOutput>
                  <SavLabel>Host Licenses</SavLabel>
                  Placeholder
                </SavOutput>
                <SavOutput>
                  <SavLabel>Claimed in SCM by</SavLabel>
                  Placeholder
                </SavOutput>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col xs={12} className="text-center">
                <button className="savBtnDark">Close Case</button>
                &nbsp;&nbsp;
                <button className="sav-btn__orange">Add Attachment</button>
              </Col>
            </Row>
          </CaseContainer>
        );
      default:
        return null;
    }
  };

  const renderArticles = () => {
    if (!_.isEmpty(activeCase.articles)) {
      return (
        <SavTable bordered hover responsive>
          <thead>
            <tr>
              <th>Article Title</th>
              <th>Published</th>
              <th>Category</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {activeCase.articles.map((a) => {
              return (
                <tr key={a.sfid}>
                  <td>
                    <a
                      className="onHov oj med"
                      href={`/knowledge/${a.articleId}`}
                      target="_blank"
                    >
                      {a.title}
                    </a>
                    {/* <a className="onHov oj med" href={`/knowledge`} target="_blank">{a.title}</a> */}
                  </td>
                  <td>placeholder</td>
                  <td>placeholder</td>
                  <td>Agent Attached</td>
                </tr>
              );
            })}
          </tbody>
        </SavTable>
      );
    }
    return <div className="f12">No Articles to display</div>;
  };
  const renderComments = () => {
    if (!_.isEmpty(activeCase.comments)) {
      return (
        <SavTable bordered hover responsive>
          <thead>
            <tr>
              <th width="70%">Body</th>
              <th>Posted By</th>
              <th>Message Date</th>
            </tr>
          </thead>
          <tbody>
            {activeCase.comments.map((c) => {
              return (
                <tr key={c.sfid}>
                  <td>{c.body}</td>
                  <td>{c.ownerName}</td>
                  <td>{parseDate(c.created)}</td>
                </tr>
              );
            })}
          </tbody>
        </SavTable>
      );
    }
    return <div className="f12">No comments to display</div>;
  };

  const renderFiles = () => {
    if (!_.isEmpty(activeCase.attachments)) {
      return (
        <SavTable bordered hover responsive>
          <thead>
            <tr>
              <th width="60%">File Name</th>
              <th>Attached By</th>
              <th>Attached On</th>
              <th>File Size</th>
            </tr>
          </thead>
          <tbody>
            {activeCase.attachements.map((f) => {
              return (
                <tr key={f.sfid}>
                  <td>placeholder</td>
                  <td>placeholder</td>
                  <td>placeholder</td>
                  <td>placeholder</td>
                </tr>
              );
            })}
          </tbody>
        </SavTable>
      );
    }
    return <div className="f12">No files to display</div>;
  };

  if (!_.isEmpty(activeCase)) {
    if (activeCase.sfid === caseId) {
      return (
        <div>
          <SavBanner text="Cases" padding="40px" />
          <Container>
            <SubHeader style={{ position: "relative" }}>
              <Link
                className="hov oj med f12"
                to="/cases/"
                style={{ position: "absolute", left: 0 }}
              >
                &lt; BACK TO CASES
              </Link>
              Case {activeCase.caseNumber}
            </SubHeader>
          </Container>
          {renderCaseDetail()}

          <CaseContainer>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} className="med f14">
                ARTICLES
              </Col>
            </Row>
            {renderArticles()}
          </CaseContainer>

          <CaseContainer>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} className="med f14">
                COMMENTS
              </Col>
            </Row>
            {renderComments()}
          </CaseContainer>

          <CaseContainer>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={12} className="med f14">
                FILES
              </Col>
            </Row>
            {renderFiles()}
          </CaseContainer>
        </div>
      );
    }
    return (
      <div>
        <SavBanner text="Cases" padding="40px" />
        <Container>
          <div style={{ marginTop: 30 }}>
            <Link className="hov oj med f12" to="/cases/">
              &lt; BACK TO CASES
            </Link>
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts,
    activeCase: state.activeCase.data,
  };
};
export default connect(mapStateToProps, { fetchContacts, fetchCase })(
  CaseDetail
);

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Dropdown, Table, Modal, Form } from "react-bootstrap";
import _ from "lodash";

const SavFormInput = ({value, setValue, feedback, label, disabled, setMargin, notRequired, type}) => {
  return (
    <div className="input-container" style={setMargin ? {margin: '10px 0px'} : null}>
      <Form.Control
        required={notRequired ? false : true}
        type={type ? type : "text"}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">
        {feedback}
      </Form.Control.Feedback>
      <label className={value && 'filled'}>{label}</label>
    </div>
  )
}

export default SavFormInput;
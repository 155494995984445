import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Tab, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  fetchDocuments,
  fetchDocument,
  updateTaxCert,
  setSuccessToast,
  deleteTaxCert,
  createTaxCert
} from "../../../actions";
import {SubHeader} from "../../../components/Styled";
import SavToast from "../../../components/SavToast";
import SavToastFail from "../../../components/SavToastFail";
import SavToastSuccess from "../../../components/SavToastSuccess";
import SavantAgreements from "./SavantAgreements"
import SalesTaxCerts from "./SalesTaxCerts";
import {visibilitySelector} from "../../../selectors";

const tabActive = {
  color: "#ff5f00"
};
const hidden = {
  display:'none'
}

const Documents = ({documents, user, fetchDocuments, fetchDocument, updateTaxCert, setSuccessToast,deleteTaxCert, createTaxCert, location}) => {
  const [tabKey, setTabKey] = useState("agreements");

  const showDocuments = useSelector(visibilitySelector('account_documents'));
  const showTax = useSelector(visibilitySelector('account_documents_tax'));
  const showAgreements = useSelector(visibilitySelector('account_documents_agreements'));
  let param = location.search;

  useEffect(() => {
    document.title = "Savant Community | Documents";
  },[]);

  useEffect(() => {
    if (!showAgreements) {
      setTabKey("taxCerts")
    }else {
      setTabKey("agreements")
    }
  },[showAgreements]);

  const renderSubPage = () => {
    if (param === '?tab=agreements'){
      return (
        <SavantAgreements documents={documents.data} fetchDocument={fetchDocument} setSuccessToast={setSuccessToast} />
      );
    }
    if (param === '?tab=taxcerts'){
      return (
        <SalesTaxCerts documents={documents.data} fetchDocument={fetchDocument} updateTaxCert={updateTaxCert} setSuccessToast={setSuccessToast} deleteTaxCert={deleteTaxCert} createTaxCert={createTaxCert} user={user} />
      );
    }
    return null;
  }
    return (
      <div>
        <SavToast />
        <SavToastSuccess />
        <SavToastFail />
        <SubHeader>Documents</SubHeader>
        <Tab.Container
          id="overview-tabs"
          activeKey={tabKey}
          onSelect={k => setTabKey(k)}
        >
          <Nav className="justify-content-center">
            <Nav.Item style={showAgreements ? null : hidden}>
              <NavLink
                className="tab-link-overview "
                to={`/account?tab=agreements#documents`}
                style={param === "?tab=agreements" ? tabActive : null}
              >
                Savant Agreements
              </NavLink>
            </Nav.Item>
            <Nav.Item style={showTax ? null : hidden}>
              <NavLink
                className="tab-link-overview "
                to={`/account?tab=taxcerts#documents`}
                style={param === "?tab=taxcerts" ? tabActive : null}
              >
                Sales Tax Certificates
              </NavLink>
            </Nav.Item>
          </Nav>
          <Container>
            {renderSubPage()}
          </Container>
        </Tab.Container>
      </div>
    );
};

const mapStateToProps = state => {
  return {
    documents: state.documents
  };
};
export default connect(mapStateToProps, {
  fetchDocuments, fetchDocument, updateTaxCert, setSuccessToast, deleteTaxCert, createTaxCert
})(Documents);

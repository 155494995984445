import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container, Collapse, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";
import {SavInput, InfoPop} from "../../../../components/Styled";
import {updateAccount, setSuccessToast, createTask} from "../../../../actions";
import { connect, useSelector } from "react-redux";
import SavInputPhone from "../../../../components/SavInputPhone";
import SavPagination from "../../../../components/SavPagination";
import {visibilitySelector} from "../../../../selectors";
import { phoneOutput } from "../../../../components/Utilities";
import NewAddressModal from "../AccountAddresses/NewAddressModal";
import SavModalWarning from "../../../../components/SavModalWarning";
import SavantListingAddress from "./SavantListingAddress";
import SavantPro from "../../../../images/SavantPro.png"
import { MdTrendingUp } from "react-icons/md";
import {BiHelpCircle} from 'react-icons/bi';
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight
} from "react-icons/fa";

const PageButton = styled.button`
  font-family: "Gotham-Medium";
  color: #979797;
  background-color: #FFFFFF;
  padding: 6px;	
  font-size: 14px;
  border: none;
  min-width: 25px;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: #ff5f00;
  }
`;

const DetailContainer = styled(Container)`
`;

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
`;

const SavLabel = styled.span`
  font-family:"Gotham-Book";
  font-size:12px;
  color:#b4b4b4;
`;

const SettingRow = styled(Row)`
  background-color:#fafaf9;
  padding:15px;
  font-size:12px;
  border-bottom:1px solid rgba(0,0,0,.1);
`;

const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;
const cancelStyle = {
  width: '100%',
  border: '1px solid #696057',
  marginTop: 5,
}
const hidden = {
  display: 'none'
};

const borderLeft = {borderLeft:"4px solid #ff5f00"};

const AccountSavantSCM = props => {
  const { account, user, addresses, updateAccount, setSuccessToast, createTask, isElectrical } = props;

  const [openScmName, setOpenScmName] = useState(false)
  const [openScmPhone, setOpenScmPhone] = useState(false);
  const [openScmEmail, setOpenScmEmail] = useState(false);
  const [openWebsite, setOpenWebsite] = useState(false);
  const [openLeadEmail, setOpenLeadEmail] = useState(false);
  
  const [scmName, setScmName] = useState(account.dba || "");
  const [scmPhone, setScmPhone] = useState(account.scmPhone || "");
  const [scmEmail, setScmEmail] = useState(account.scmEmail || "");
  const [website, setWebsite] = useState(account.website || "");
  const [defaultLeadEmail, setDefaultLeadEmail] = useState(account.defaultLeadEmail || "");

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [addressListingsTotal, setAddressListingsTotal] = useState([])
  const [addressList, setAddressList] = useState([]);
  const showListings = useSelector(visibilitySelector('account_settings_savant_com_listings'));
  const [showWarning, setShowWarning] = useState(false);
  const [isNDD, setIsNDD] = useState(user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'US Distribution');
  const accType = user.contact.accType;

  useEffect(() => {
    if (openLeadEmail){
      setDefaultLeadEmail(account.defaultLeadEmail)
    }
  },[openLeadEmail]);

  useEffect(() => {
    if (openWebsite){
      setWebsite(account.website)
    }
  },[openWebsite]);

  useEffect(() => {
    if (openScmName){
      setScmName(account.dba)
    }
  },[openScmName]);

  useEffect(() => {
    if (openScmPhone){
      setScmPhone(account.scmPhone)
    }
  },[openScmPhone]);

  useEffect(() => {
    if (openScmEmail){
      setScmEmail(account.scmEmail)
    }
  },[openScmEmail]);

  useEffect(() => {
    const addrListings = [];
      addresses.forEach((a) => {
        if(a.isSavantListing){
          addrListings.push(a);
        }
      });
      setAddressListingsTotal(addrListings);

  },[addresses, pageSize]);

  useEffect(() => {
    if (addressListingsTotal) {
      let index = 0;
      const tempAddrs = [];
      for (index = 0; index < addressListingsTotal.length; index += pageSize) {
        let chunk = addressListingsTotal.slice(index, index + pageSize);
        tempAddrs.push(chunk);
      }
      setAddressList(tempAddrs);
    }

  },[addressListingsTotal, pageSize]);

  const handleUpdateAccount = (field) => {
    const accJSON = {...account};

    if (field === 'scmPhone') {
      accJSON.scmPhone = scmPhone;
    }
    if (field === 'scmEmail') {
      accJSON.scmEmail = scmEmail;
    }
    if (field === 'website') {
      accJSON.website = website;
    }
    if (field === 'defaultLeadEmail') {
      accJSON.defaultLeadEmail = defaultLeadEmail;
    }
    if (field === 'dba') {
      accJSON.dba = scmName;
    }
    updateAccount(accJSON)
    .then((val) => {
      setSuccessToast(true);
    });
    setOpenScmEmail(false);
    setOpenScmPhone(false);
    setOpenWebsite(false);
    setOpenLeadEmail(false);
    setOpenScmName(false);
  };

  const renderListings = () => {
    if (!_.isEmpty(addressList) && showListings) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > addressList.length) {
        page = 0;
        setCurrentPage(1);
      }

      return addressList[page].map((addr, idx) => {
        return (
          <SavantListingAddress account={account} addr={addr} idx={idx} key={addr.sfid} />
        )
      });
    }
    return null;
  };
  function paginator(items, current_page, per_page_items) {
    let page = current_page || 1,
    per_page = per_page_items || 10,
    offset = (page - 1) * per_page,
  
    paginatedItems = items.slice(offset).slice(0, per_page_items),
    total_pages = Math.ceil(items.length / per_page);
  
    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  };

  const renderPagination = () => {
    let pagination = paginator(addressListingsTotal, currentPage, pageSize);
    if (pagination.total_pages > 1){
      return (
        <Container>
          <Row>
            <Col xs={12} className="text-right">
                <PageButton onClick={() => setCurrentPage(1)}>
                  <FaAngleDoubleLeft />
                </PageButton>
                <PageButton
                  onClick={
                    currentPage !== 1
                      ? () => setCurrentPage(currentPage - 1)
                      : () => setCurrentPage(1)
                  }
                >
                  <FaAngleLeft />
                </PageButton>
                {[...Array(pagination.total_pages)].map((s, index) => {
                  return (
                    <PageButton
                      className="med pad4 onHov f14"
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      style={
                        currentPage === index + 1
                          ? {
                              textDecoration: "underline",
                              fontSize: 16,
                              color: "#ff5f00"
                            }
                          : null
                      }
                    >
                      {index + 1}
                    </PageButton>
                  );
                })}
                <PageButton>
                  <FaAngleRight
                    onClick={
                      currentPage !== pagination.total_pages
                        ? () => setCurrentPage(currentPage + 1)
                        : () => setCurrentPage(pagination.total_pages)
                    }
                  />
                </PageButton>
                <PageButton onClick={() => setCurrentPage(pagination.total_pages)}>
                  <FaAngleDoubleRight />
                </PageButton>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-right book  f10">
              {pagination.total} total results
            </Col>
          </Row>
        </Container>
      )
      
    }
    return null;
  }

  const renderLeadsAndListings = () => {
    if (showListings) {
      return (
        <Fragment>
          <SettingRow>
            <Col xs={12}>
              <Header>SAVANT.COM LEAD INQUIRIES SETTINGS</Header>
            </Col>
          </SettingRow>

          <SettingRow style={openLeadEmail ? borderLeft: null}>
            <Col xs={4}>
              <SavLabel>Email</SavLabel>
            </Col>
            <Col xs={6} className="truncate">
              {openLeadEmail ? null : (account.defaultLeadEmail || "N/A")}
              <Collapse in={openLeadEmail}>
                <div id="openLeadEmail" className="text-center">
                  <SavInput value={defaultLeadEmail} onChange={(e) => setDefaultLeadEmail(e.target.value)} />
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('defaultLeadEmail')}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenLeadEmail(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenLeadEmail(!openLeadEmail)} aria-controls="openLeadEmail" aria-expanded={openLeadEmail}>{openLeadEmail ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>

          <SettingRow style={openWebsite ? borderLeft: null}>
            <Col xs={4}>
              <SavLabel>Website</SavLabel>
            </Col>
            <Col xs={6} className="truncate">
              {openWebsite ? null : (account.website || "N/A")}
              <Collapse in={openWebsite}>
                <div id="openWebsite" className="text-center">
                  <SavInput value={website} onChange={(e) => setWebsite(e.target.value)} />
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('website')}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenWebsite(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenWebsite(!openWebsite)} aria-controls="openWebsite" aria-expanded={openWebsite}>{openWebsite ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>

          <SettingRow className="m-t-20">
            <Col xs={12} className="savFlexBetweenCenter">
              <Header>SAVANT.COM LISTINGS</Header>
              <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">Information below is representative of your current listing(s) on savant.com/find-a-dealer</Tooltip>}>
                <BiHelpCircle size={21} style={{cursor: 'help'}} />
              </OverlayTrigger>
            </Col>
          </SettingRow>
            {renderListings()}
            {renderPagination()}
            {/* <SavPagination
              data={addressList}
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataLength={addressListingsTotal.length}
              originalData={addressListingsTotal}
            /> */}
            <div className="book f12 text-center m-t-20">
              *Updates to the information shown here can be made in the 'Account Addresses' tab
            </div>
        </Fragment>

      )
    }
    return null;
  }

  const renderSCM = () => {
    if (!isElectrical){
      return (
        <Fragment>
          <SettingRow style={{marginTop:20}}>
            <Col xs={12}>
              <Header>SAVANT CENTRAL MANAGEMENT</Header>
            </Col>
          </SettingRow>

          <SettingRow style={openScmName ? borderLeft: null}>
            <Col xs={4}>
              <SavLabel>Company Name</SavLabel>
            </Col>
            <Col xs={6}>
              {openScmName ? null : (account.dba || "N/A")}
              <Collapse in={openScmName}>
                <div id="openScmPhone" className="text-center">
                  <SavInput value={scmName} onChange={(e) => setScmName(e.target.value)} />
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('dba')}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenScmName(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenScmName(!openScmName)} aria-controls="openScmName" aria-expanded={openScmName}>{openScmName ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>

          <SettingRow style={openScmPhone ? borderLeft: null}>
            <Col xs={4}>
              <SavLabel>Phone</SavLabel>
            </Col>
            <Col xs={6}>
              {openScmPhone ? null : (phoneOutput(account.scmPhone))}
              <Collapse in={openScmPhone}>
                <div id="openScmPhone" className="text-center">
                  <SavInput value={scmPhone} onChange={(e) => setScmPhone(e.target.value)} />
                  {/* <SavInputPhone phoneNumber={scmPhone} setPhoneNumber={setScmPhone} open={openScmPhone} /> */}
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('scmPhone')}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenScmPhone(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenScmPhone(!openScmPhone)} aria-controls="openScmPhone" aria-expanded={openScmPhone}>{openScmPhone ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>


          <SettingRow style={openScmEmail ? borderLeft: null}>
            <Col xs={4}>
              <SavLabel>Email</SavLabel>
            </Col>
            <Col xs={6}>
              {openScmEmail ? null : (account.scmEmail || "N/A")}
              <Collapse in={openScmEmail}>
                <div id="openScmEmail" className="text-center">
                  <SavInput value={scmEmail} onChange={(e) => setScmEmail(e.target.value)} />
                  <br /><br />
                  <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('scmEmail')}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenScmEmail(false)}>Cancel</button>
                </div>
              </Collapse>
            </Col>
            <Col xs={2} className="text-right">
              <EditSpan onClick={()=> setOpenScmEmail(!openScmEmail)} aria-controls="openScmEmail" aria-expanded={openScmEmail}>{openScmEmail ? "Close" : "Edit"}</EditSpan>
            </Col>
          </SettingRow>
        </Fragment>
      )
    }
    return null;
  }

  return (
    <DetailContainer>
      <SavModalWarning
        show={showWarning}
        hide={() => setShowWarning(false)}
        title="Address Submitted"
        text2="This address may take some time to reflect on savant.com. Please allow up to 48 hours for changes to take effect."
        click={() => setShowWarning(false)}
      />
      {renderLeadsAndListings()}
      {renderSCM()}
      
    </DetailContainer>
  );
};

export default connect(null, {updateAccount, setSuccessToast, createTask})(AccountSavantSCM);

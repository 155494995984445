import { FETCH_DISTRIBUTORS_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const distributors = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DISTRIBUTORS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default distributors;

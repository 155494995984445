import React, { useState, useEffect, Fragment } from "react";
import ReactCountryFlag from 'react-country-flag';
import styled from "styled-components";
import { Row, Col, Container, Modal, Form, InputGroup } from "react-bootstrap";
import {MdWarning} from 'react-icons/md';
import { connect } from "react-redux";
import _ from "lodash";
import SavCheck from "../../../components/SavCheck";
import {
  createContact,
  fetchAccount,
  fetchUser,
  setSuccessToast,
  fetchContacts,
  updateContact
} from "../../../actions";
import SavDropFilter from "../../../components/SavDropFilter";
import Countries from "../../../components/Utilities/countries.json";
import ReactTooltip from "react-tooltip";

const StyledRadio = styled.svg`
  height: 18px;
  width: 18px;
  cursor: pointer;
`;

const PermList = styled.ul`
  margin-bottom:0px;
  padding-left:15px;
`;

const dialCodeStyle = {
  fontSize:12,
  padding: 4,
  borderRadius: 'unset',
  border:'unset',
  borderTop: '1px solid #979797',
  borderBottom: '1px solid #979797',
  minWidth:42,
  justifyContent:'center'
};
const hidden = {
  display:'none'
};

const SavCheckForm = React.forwardRef((props, ref) => {
  return <SavCheck checked={props.checked} click={props.click} />;
});

const SavCheckRadioForm = React.forwardRef((props, ref) => {
  const { checked, click } = props;
  return (
    <StyledRadio onClick={() => click()}>
      <circle
        stroke={checked ? "#ff5f00" : "#979797"}
        fill={checked ? "#ff5f00" : "#D8D8D8"}
        strokeWidth="1"
        r="8"
        cx="9"
        cy="9"
      />
      <polyline
        stroke="#fff"
        points="13 6 7 12 4 9"
        strokeWidth="2"
        fill="transparent"
        visibility={checked ? "visibile" : "hidden"}
      ></polyline>
    </StyledRadio>
  )
});

const EditContactModal = props => {
  const {
    activeContact,
    account,
    user,
    fetchContacts,
    updateContact,
    show,
    setShow,
    isElectrical
  } = props;
  // const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [contactFirstName, setContactFirstName] = useState(activeContact.firstName || "");
  const [contactLastName, setContactLastName] = useState(activeContact.lastName || "");
  const [contactTitle, setContactTitle] = useState(activeContact.title || "");
  const [contactEmail, setContactEmail] = useState(activeContact.email || '');
  const [contactAltEmail, setContactAltEmail] = useState(activeContact.altEmail || '');
  const [contactMobile, setContactMobile] = useState('');
  const [contactMobileCountry, setContactMobileCountry] = useState("US");
  const [contactMobileDialCode, setContactMobileDialCode] = useState('');
  const [contactBetaAccess, setContactBetaAccess] = useState(activeContact.betaAccess);
  const [contactBetaUID, setContactBetaUID] = useState(activeContact.betaHostUid || '');

  const [nddPerms, setNddPerms] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isStore, setIsStore] = useState(false);
  const [isScmAdmin, setIsScmAdmin] = useState(false);
  const [isScmAccess, setIsScmAccess] = useState(false);
  const [isScmNone, setIsScmNone] = useState(false);
  const [isScmPurchasing, setIsScmPurchasing] = useState(false);
  const [isScmNoPurchasing, setIsScmNoPurchasing] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(activeContact)){
      let perms = activeContact.permissions;
      setContactFirstName(activeContact.firstName || '');
      setContactLastName(activeContact.lastName || '');
      setContactTitle(activeContact.title || '');
      setContactEmail(activeContact.email || '');
      setContactAltEmail(activeContact.altEmail || '');
      setContactBetaAccess(activeContact.betaAccess);
      setContactBetaUID(activeContact.betaHostUid || '');
      setIsAdmin(perms.accountAdmin);
      setIsBilling(perms.ordersInvoices);
      setIsStore(perms.storePricing);
      setIsTeam(perms.contactManagement);
      setIsScmPurchasing(perms.scmPurchasing);

      if (perms.scmPurchasing === true){
        setIsScmNoPurchasing(false);
      }

      if (activeContact.scmAccess === 'Admin'){
        setIsScmAdmin(true);
        setIsScmAccess(false);
        setIsScmNone(false);
      }
      if (activeContact.scmAccess === 'Access'){
        setIsScmAccess(true);
        setIsScmAdmin(false);
        setIsScmNone(false);
      }
      if (activeContact.scmAccess === 'None'){
        setIsScmNone(true);
        setIsScmAdmin(false);
        setIsScmAccess(false);
      }

      let dialCodeFound;
      if (!_.isEmpty(activeContact.mobile)){
        dialCodeFound = _.find(Countries, (c) => activeContact.mobile.startsWith(c.dial_code));
      }
      if (dialCodeFound){
        
        const dialCode = activeContact.mobile.slice(0, dialCodeFound.dial_code.length);
        const number = activeContact.mobile.slice(dialCodeFound.dial_code.length, activeContact.mobile.length);

        if (!_.isEmpty(dialCode) && !_.isEmpty(number)){
          setContactMobile(number.trim());
          if (dialCodeFound.dial_code === '+1'){
            setContactMobileCountry('US');
          } else if (dialCodeFound.dial_code === '+44'){
            setContactMobileCountry('GB')
          } else {
            setContactMobileCountry(dialCodeFound.code);
          }
        }
      } else{
        setContactMobile(activeContact.mobile);
      }
    }
    
  },[activeContact, show]);

  useEffect(() => {
    if (contactMobileCountry){
      const countryFound = _.find(Countries, (c) => c.code === contactMobileCountry);
      if (countryFound){
        setContactMobileDialCode(countryFound.dial_code);
      }
    }
  },[contactMobileCountry]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (user.data.contact.accType === 'Non-Direct'){
        setNddPerms(true);
      }
    }
  },[user])

  const handleClose = () => {
    setShow(false);
  }

  const handleCloseEditContactModal = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const contactJSON = {
      firstName: contactFirstName,
      lastName : contactLastName,
      sfid: activeContact.sfid,
      title : contactTitle,
      email : contactEmail,
      altEmail : contactAltEmail,
      scmAccess : 'None',
      betaAccess : contactBetaAccess,
      betaHostUid : contactBetaUID,
      emailOptOut : false,
      communityAccess : true,
      ignoreCommWarning : true,
      communicationSettings : {
        caseNotification: activeContact.communicationSettings.caseNotification,
        caseEmail: activeContact.communicationSettings.caseEmail,
        orderNotification: activeContact.communicationSettings.orderNotification,
        orderEmail: activeContact.communicationSettings.orderEmail,
        salesNotification: activeContact.communicationSettings.salesNotification,
        salesEmail: activeContact.communicationSettings.salesEmail,
        bulletinNotification:activeContact.communicationSettings.bulletinNotification,
        billingNotification:activeContact.communicationSettings.billingNotification,
        billingEmail: activeContact.communicationSettings.billingEmail
      },
      permissions : {
        storePricing: isStore,
        ordersInvoices: isBilling,
        contactManagement: isTeam,
        accountAdmin: isAdmin,
        scmPurchasing: isScmPurchasing,
      },
    };

    if(contactMobile){
      var clearedMobile = ("" + contactMobile).replace(/\D/g, "");
      contactJSON.mobile = contactMobileDialCode + clearedMobile;      
    }
    if (isScmAdmin){
      contactJSON.scmAccess = 'Admin'
    } else if (isScmAccess){
      contactJSON.scmAccess = 'Access'
    } else {
      contactJSON.scmAccess = 'None'
    }
    updateContact(contactJSON, activeContact.sfid).then((val) => {
      fetchContacts();
    });
    setShow(false);
  };
  const handleClickPerm = (perm, val) => {
    if (perm === 'admin' && !isAdmin){
      setIsAdmin(true);
      setIsBilling(true);
      setIsStore(true);
      setIsTeam(true);
    }
    if (perm === 'admin' && isAdmin){
      setIsAdmin(false);
    }
    if (perm === 'billing' && !isBilling){
      setIsBilling(true);
      setIsStore(true);
      setIsTeam(true);
    }
    if (perm === 'billing' && isBilling && !isAdmin){
      setIsBilling(false);
    }
    if (perm === 'team' && !isTeam){
      setIsTeam(true);
    }
    if (perm === 'team' && isTeam && !isBilling && !isAdmin){
      setIsTeam(false);
    }
    if (perm === 'store' && !isStore){
      setIsStore(true);
    }
    if (perm === 'store' && isStore && !isBilling && !isAdmin){
      setIsStore(false);
    }
  };
  const handleClickScm = (perm) => {
    if (perm === 'admin'){
      setIsScmAdmin(true);
      setIsScmAccess(false);
      setIsScmNone(false);
    }
    if (perm === 'access'){
      setIsScmAdmin(false);
      setIsScmAccess(true);
      setIsScmNone(false);
    }
    if (perm === 'none'){
      setIsScmAdmin(false);
      setIsScmAccess(false);
      setIsScmNone(true);
      setIsScmPurchasing(false);
      setIsScmNoPurchasing(true);
    }
    if (perm === 'purchasing'){
      setIsScmPurchasing(true);
      setIsScmNoPurchasing(false);
    }
    if (perm === 'noPurchasing'){
      setIsScmPurchasing(false);
      setIsScmNoPurchasing(true);
    }
  };
  const renderMessage = () => {
    if (
      (isAdmin && !activeContact.permissions.accountAdmin && !activeContact.permissions.ordersInvoices) ||
      (isBilling && !activeContact.permissions.ordersInvoices)
      ){
      return (
        <div className="med red text-center f12 m-t-20">
          <MdWarning size={20} style={{fill:'#d0021b'}} /> Enabling this elevated permission will require the user to set a new password.
        </div>
      )
    }
    if (
      (!isAdmin && !isBilling && activeContact.permissions.accountAdmin ) ||
      (!isBilling && activeContact.permissions.ordersInvoices)
      ){
        return (
          <div className="med red text-center f12 m-t-20">
            <MdWarning size={20} style={{fill:'#d0021b'}} /> Removing this elevated permission will require the user to set a new password.
          </div>
        )
      }
      if (activeContact.status === 'Inactive'){
        return (
          <div className="med red text-center f12 m-t-20">
            <MdWarning size={20} style={{fill:'#d0021b'}} /> This is an INACTIVE contact. Editing or Saving this contact will REACTIVATE the contact and grant them permission to access the community.
          </div>
        )
      }

    return null;
  }

  if (!_.isEmpty(activeContact)){
    return (
      <Fragment>
        <Modal
          show={show}
          onHide={() => {setShow(false)}}
          centered
          size="lg"
        >
          <Form
            onSubmit={handleCloseEditContactModal}
            noValidate
            validated={validated}
            className="sav-form"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Contact Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid>
                <ReactTooltip html={true} className="tooltip-issuetype" />
  
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <div className="input-container">
                      <Form.Control
                        required
                        type="text"
                        onChange={e => setContactFirstName(e.target.value)}
                        value={contactFirstName}
                        autoComplete="nope"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter first name.
                      </Form.Control.Feedback>
                      <label className={contactFirstName && 'filled'}>First Name</label>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <div className="input-container">
                      <Form.Control
                        required
                        type="text"
                        onChange={e => setContactLastName(e.target.value)}
                        value={contactLastName}
                        autoComplete="nope"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter last name
                      </Form.Control.Feedback>
                      <label className={contactLastName && 'filled'}>Last Name</label>
                    </div>
                  </Form.Group>
                </Form.Row>
  
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <div className="input-container">
                      <Form.Control
                        required
                        type="text"
                        onChange={e => setContactTitle(e.target.value)}
                        value={contactTitle}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter title
                      </Form.Control.Feedback>
                      <label className={contactTitle && 'filled'}>title</label>
                    </div>
                  </Form.Group>
                </Form.Row>
  
                <Form.Row>
                  <Form.Group as={Col} lg={4}>
                    <div className="input-container">
                      <Form.Control
                        required
                        type="email"
                        onChange={e => setContactEmail(e.target.value)}
                        value={contactEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter an email
                      </Form.Control.Feedback>
                      <label className={contactEmail && 'filled'}>email</label>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} lg={4}>
                    <div className="input-container">
                      <Form.Control
                        type="email"
                        onChange={e => setContactAltEmail(e.target.value)}
                        value={contactAltEmail}
                      />
                      <label className={contactAltEmail && 'filled'}>alternate email</label>
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} lg={4}>
                    <div className="input-container-mobile">
                      <InputGroup>
                        <InputGroup.Prepend>
                            <SavDropFilter val={contactMobileCountry} opts={Countries} click={setContactMobileCountry} clickKey="code" flag>
                            <ReactCountryFlag countryCode={contactMobileCountry} svg style={{height:15, width:20}} />
                            </SavDropFilter>
                        </InputGroup.Prepend>
                        <InputGroup.Text style={dialCodeStyle}>{contactMobileDialCode}</InputGroup.Text>
                        <Form.Control
                          type="text"
                          onChange={e => setContactMobile(e.target.value)}
                          value={contactMobile}
                          required
                        />
                        <label className={contactMobile && 'filled'}>Mobile Phone</label>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                          Please enter your mobile phone.
                        </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Form.Row>
  
                <Form.Row className="vertical-align" style={isElectrical ? hidden : null}>
                  <Form.Group as={Col} xs={1}>
                    <Form.Control
                      as={SavCheckForm}
                      checked={contactBetaAccess}
                      click={() => setContactBetaAccess(!contactBetaAccess)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={3}>
                    <Form.Label className="f12 text-uppercase m-b-0">Opt in for Savant Beta</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={8}>
                    <div className="input-container" style={contactBetaAccess ? null : {display:'none'}}>
                      <Form.Control
                        type="text"
                        onChange={e => setContactBetaUID(e.target.value)}
                        value={contactBetaUID}
                      />
                      <Form.Text className="f10" muted>
                        Please ensure this host is actively being used for Beta.
                      </Form.Text>
                      <label className={contactBetaUID && 'filled'}>Beta Host UID</label>
                    </div>
                  </Form.Group>
                </Form.Row>
  
  
                {/* ________ PERMISSIONS ________ */}
                <div className="med f14 m-b-20 text-uppercase text-center">
                  What can this person access on your account?
                </div>
                <Row className="vertical-align">
                  <Col xs={1}>
                    <Form.Control
                      as={SavCheckForm}
                      checked={true}
                    />
                  </Col>
                  <Col xs={2}>
                    <span className="f12 med text-uppercase">Default</span>
                  </Col>
                  <Col xs={9}>
                    <p className="book f10 dune m-b-0">
                    Contacts will have access to view and manage (where applicable) product documentation, technical support, Savant University, Savant's Beta program, the Savant Store & Design Tools (without pricing), and user settings such as notifications and email preferences by default.<br /><span className="med">Any permissions awarded below would be additive.</span>
                    </p>
                  </Col>
                </Row>
                <hr />
                <ReactTooltip html={true} className="tooltip-issuetype" />
  
                <Row className="vertical-align">
                  <Col xs={1}>
                    <span data-tip={isAdmin && isTeam ? 'Cannot remove permission. Admin includes all other permissions.' : (isBilling && isTeam && isStore ? 'Cannot remove permission. Billing includes the team permission' : '')}>
                      <Form.Control
                        as={SavCheckForm}
                        checked={isTeam}
                        click={() => handleClickPerm('team')}
                      />
                      </span>
                  </Col>
                  <Col xs={2}>
                    <span className="f12 med text-uppercase">Team</span>
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>Manage employee setup</li>
                          <li>Enroll team members in Savant University.</li>
                        </PermList>
                      </Col>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>Employee deactivation</li>
                          <li>Employee reactivation</li>
                        </PermList>
                      </Col>
                    </Row>
                  </Col>
                </Row>
  
                <hr style={nddPerms ? hidden : null} />
                <Row className="vertical-align" style={nddPerms ? hidden : null}>
                  <Col xs={1}>
                    <span data-tip={isAdmin && isStore ? 'Cannot remove permission. Admin includes all other permissions.' : (isBilling && isStore ? 'Cannot remove permission. Billing includes the store permission' : '')}>
                      <Form.Control
                        as={SavCheckForm}
                        checked={isStore}
                        click={() => handleClickPerm('store')}
                      />
                    </span>
                  </Col>
                  <Col as={Col} xs={2}>
                    <span className="f12 med text-uppercase">Store</span>
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>Pricing in Savant Store & Design Tools</li>
                          <li>Checkout Carts in the Savant Store</li>
                        </PermList>
                      </Col>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>View/Manage Order Defaults</li>
                          <li>View/Manage Shipping Address/Methods</li>
                        </PermList>
                      </Col>
                    </Row>
                  </Col>
                </Row>
  
                <hr style={nddPerms ? hidden : null} />
                <Row className="vertical-align" style={nddPerms ? hidden : null}>
                  <Col xs={1} >
                    <span data-tip={isAdmin && isBilling ? 'Cannot remove permission. Admin includes all other permissions.' : ''}>
                      <Form.Control
                        as={SavCheckForm}
                        checked={isBilling}
                        click={() => handleClickPerm('billing', !isBilling)}
                      />
                    </span>
                  </Col>
                  <Col xs={2}>
                    <span className="f12 med text-uppercase">Billing</span>
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          
                          <li>View/Pay Invoices</li>
                          <li>Run account statements</li>
                          <li>Update credit card information</li>
                        </PermList>
                      </Col>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>Includes all permissions in <span className="oj med">TEAM</span></li>
                          <li>Includes all permissions in <span className="oj med">STORE</span></li>
                        </PermList>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
                <Row className="vertical-align">
                  <Col xs={1}>
                    <Form.Control
                      as={SavCheckForm}
                      checked={isAdmin}
                      click={() => handleClickPerm('admin', !isAdmin)}
                    />
                  </Col>
                  <Col xs={2}>
                    <span className="f12 med text-uppercase">Admin</span>
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>View applicable sales programs</li>
                          <li>Manage savant.com listings</li>
                          <li>Savant Central Management settings</li>
                        </PermList>
                      </Col>
                      <Col sm={6} className="book f10 dune">
                        <PermList>
                          <li>Includes all permissions in <span className="oj med">TEAM</span></li>
                          <li>Includes all permissions in <span className="oj med">STORE</span></li>
                          <li>Includes all permissions in <span className="oj med">BILLING</span></li>
                          
                        </PermList>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                <div className="med f14 m-t-20 m-b-20 text-uppercase text-center" style={isElectrical ? hidden : null}>
                  Savant Central Management Access
                </div>
                <Row className="vertical-align" style={isElectrical ? hidden : null}>
                  <Col xs={1}>
                    <Form.Control
                      as={SavCheckRadioForm}
                      checked={isScmNone}
                      click={() => handleClickScm('none')}
                    />
                  </Col>
                  <Col as={Col} xs={2}>
                    <span className="f12 med text-uppercase">Default</span>
                  </Col>
                  <Col xs={9}>
                    <p className="book f10 dune m-b-0">
                    User will not be granted access to SCM
                    </p>
                  </Col>
                </Row>
                <hr style={isElectrical ? hidden : null} />
                <Row className="vertical-align" style={isElectrical ? hidden : null}>
                  <Col xs={1}>
                    <Form.Control
                      as={SavCheckRadioForm}
                      checked={isScmAccess}
                      click={() => handleClickScm('access')}
                    />
                  </Col>
                  <Col as={Col} xs={2}>
                    <span className="f12 med text-uppercase">Access</span>
                  </Col>
                  <Col xs={9}>
                    <p className="book f10 dune m-b-0">
                    Grants user access to SCM. All Users can add or modify Jobs; only Admin
                    users can add, modify, or remove Domains or other Users.
                    </p>
                    <Row style={isScmAccess ? null : hidden} className="vertical-align m-t-10">
                      <Col xs={1}>
                        <Form.Control
                          as={SavCheckRadioForm}
                          checked={isScmNoPurchasing}
                          click={() => handleClickScm('noPurchasing')}
                        />
                      </Col>
                      <Col xs={3}>
                        <span className="f12 med text-uppercase">No Purchasing</span>
                      </Col>
                      <Col xs={8}>
                        <p className="book f10 dune m-b-0">
                          User will <b>not</b> be able to purchase subscriptions within SCM.
                        </p>
                      </Col>
                    </Row>

                    <Row style={isScmAccess ? null : hidden} className="vertical-align m-t-10">
                      <Col xs={1}>
                        <Form.Control
                          as={SavCheckRadioForm}
                          checked={isScmPurchasing}
                          click={() => handleClickScm('purchasing')}
                        />
                      </Col>
                      <Col xs={3}>
                        <span className="f12 med text-uppercase">Purchasing</span>
                      </Col>
                      <Col xs={8}>
                        <p className="book f10 dune m-b-0">
                          Users with this permission enabled will be able to purchase subscriptions within SCM for any home in the domain.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr style={isElectrical ? hidden : null} />
                <Row className="vertical-align" style={isElectrical ? hidden : null}>
                  <Col xs={1}>
                    <Form.Control
                      as={SavCheckRadioForm}
                      checked={isScmAdmin}
                      click={() => handleClickScm('admin')}
                    />
                  </Col>
                  <Col as={Col} xs={2}>
                    <span className="f12 med text-uppercase">Admin</span>
                  </Col>
                  <Col xs={9}>
                    <p className="book f10 dune m-b-0">
                    Admin users can add, remove and manage users and jobs, as well as manage Profiles and Templates on SCM.
                    </p>
                    <Row style={isScmAdmin ? null : hidden} className="vertical-align m-t-10">
                      <Col xs={1}>
                        <Form.Control
                          as={SavCheckRadioForm}
                          checked={isScmNoPurchasing}
                          click={() => handleClickScm('noPurchasing')}
                        />
                      </Col>
                      <Col xs={3}>
                        <span className="f12 med text-uppercase">No Purchasing</span>
                      </Col>
                      <Col xs={8}>
                        <p className="book f10 dune m-b-0">
                          User will <b>not</b> be able to purchase subscriptions within SCM.
                        </p>
                      </Col>
                    </Row>

                    <Row style={isScmAdmin ? null : hidden} className="vertical-align m-t-10">
                      <Col xs={1}>
                        <Form.Control
                          as={SavCheckRadioForm}
                          checked={isScmPurchasing}
                          click={() => handleClickScm('purchasing')}
                        />
                      </Col>
                      <Col xs={3}>
                        <span className="f12 med text-uppercase">Purchasing</span>
                      </Col>
                      <Col xs={8}>
                        <p className="book f10 dune m-b-0">
                          Users with this permission enabled will be able to purchase subscriptions within SCM for any home in the domain.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr style={isElectrical ? hidden : null} />
                {renderMessage()}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <button className="sav-btn__gray" type="button" onClick={() => handleClose()}>
                Cancel
              </button>
              <button className="sav-btn__orange--fill" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Fragment>
    );
  }
  return null;

  
};



const mapStateToProps = state => {
  return {
    account: state.account.data,
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  createContact,
  fetchAccount,
  fetchUser,
  fetchContacts,
  updateContact,
  setSuccessToast
})(EditContactModal);
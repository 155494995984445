import { FETCH_GRID_SUCCESS, UPDATE_GRID_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const grid = (state = {}, action) => {
  switch (action.type) {
    case FETCH_GRID_SUCCESS:
      return success(state, action);
    case UPDATE_GRID_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default grid;

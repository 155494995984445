import { FETCH_EVENT_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const activeEvent = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EVENT_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default activeEvent;

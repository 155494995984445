import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import SavColumnSort from "../../components/SavColumnSort";
import SavPagination from "../../components/SavPagination";
import SavDrop from "../../components/SavDrop";
import {
  SubHeader,
  SavSearch,
  SavRow,
  SavSpanName,
  SavLink,
  SavLabel,
} from "../../components/Styled";
import { parseDate } from "../../components/Utilities";
import { fetchEvents, fetchEvent } from "../../actions";

const SavOutput = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;
const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  margin-bottom: 5px;
`;
const TitleRow = styled(Row)`
  font-size: 18px;
  background: #3f3f3f;
  padding: 15px;
  margin: 0;
`;
const TitleCol = styled(Col)`
  color: #ff5f00;
  font-family: "Gotham-Medium";
`;
const hidden = {
  display:"none"
};

const EventDetail = (props) => {
  const { events, activeEvent, fetchEvents, fetchEvent } = props;

  //const eventId = props.match.params.eventId;
  const [eventId, setEventId] = useState(props.match.params.eventId);
  const [event2, setEvent2] = useState(null);

  useEffect(() => {
    document.title = "Savant Community | Events Detail"
    window.scrollTo(0, 0);
  },[])

  useEffect(() => {
    if (_.isEmpty(events)) {
      fetchEvents();
    } else {
      events.forEach((e) => {
        if (e.sfid === eventId) {
          setEvent2(e);
        }
      });
    }
  }, [events, eventId]);

  const previousEvent = (eventIn) => {
    const currentEventIndex = _.findIndex(events, eventIn);
    let prevEvent;
    if (currentEventIndex > 0) {
      if(events[currentEventIndex - 1].type !== "Scheduler Event") {
        prevEvent = events[currentEventIndex - 1];
      }
    }
    if (prevEvent) {
      return (
        <Link
          className="hov oj med f12 linkHover"
          to={`/events/${prevEvent.sfid}`}
          onClick={() => setEventId(prevEvent.sfid)}
        >
          &#8249; {prevEvent.title}
        </Link>
      );
    }
    return <div></div>;
  };

  const nextEvent = (eventIn) => {
    const currentEventIndex = _.findIndex(events, eventIn);
    let nextEvent;
    if (currentEventIndex < events.length) {
      if(events[currentEventIndex + 1]) {
        if (events[currentEventIndex + 1].type !== "Scheduler Event") {
          nextEvent = events[currentEventIndex + 1];
        }
        
      }
      //nextEvent = events[currentEventIndex + 1];
    }
    if (nextEvent) {
      return (
        <Link
          className="hov oj med f12 linkHover"
          to={`/events/${nextEvent.sfid}`}
          onClick={() => setEventId(nextEvent.sfid)}
        >
          {nextEvent.title} &#8250;
        </Link>
      );
    }
    return <div></div>;
  };

  // const parseDate = (date) => {
  //   return new Date(JSON.parse(JSON.stringify(date))).toLocaleString();
  // };
  const createMarkup = (richText) => {
    if (richText) {
      return { __html: richText };
    }
  };

  const renderLocationData = () => {
    if (event2.locationType === 'Online'){
      return null;
    } 
    return (
      <Fragment>
        <SavOutput style={event2.locationName ? null : hidden}>
          {event2.locationName}
        </SavOutput>
        <SavOutput style={event2.locationStreet ? null : hidden}>
          {event2.locationStreet}
        </SavOutput>
        <SavOutput style={event2.locationCity && event2.locationState ? null : hidden}>
          {`${event2.locationCity}, ${event2.locationState} ${event2.locationPostalCode || ''}`}
        </SavOutput>
        <SavOutput style={event2.locationCountry ? null : hidden}>
          {event2.locationCountry }
        </SavOutput>
        <SavOutput style={event2.locationPhone ? null : hidden}>
          {event2.locationPhone}
        </SavOutput>
        <SavOutput style={event2.locationEmail ? null : hidden}>
          {event2.locationEmail}
        </SavOutput>
        <div style={!event2.locationDetailURL ? hidden : null} className="m-t-5">
          <a className="oj linkHover" href={`${event2.locationDetailURL}`} rel="noopener noreferrer" target="_blank">
            {event2.locationDetailLabel}
          </a> 
        </div>
      </Fragment>
    )

  }

  if (event2) {
    const endDate = new Date(JSON.parse(JSON.stringify(event2.startDate))).getTime() + event2.duration*60000;
    return (
      <div>
        <SavBanner text="Event Detail" padding="40px" />
        <Container style={{ marginTop: 40 }}>
          <div style={{ marginTop: 20 }}>
            <Link className="hov oj med f12 linkHover" to="/events/">
              &lt; BACK TO EVENTS
            </Link>
          </div>
          <Row style={{ marginTop: 20 }}>
            <Col sm={8}>
              <div style={{ position: "relative" }}>
                <Image src={event2.img} width="100%" />
                <TitleRow>
                  <TitleCol sm={7}>{event2.title}</TitleCol>
                  <TitleCol sm={5} className="text-right oj">
                    {parseDate(event2.startDate)}
                  </TitleCol>
                </TitleRow>
              </div>
              <div
                style={{ padding: 20 }}
                dangerouslySetInnerHTML={createMarkup(event2.description)}
              ></div>
              {/* <div className="text-center">
                <button className="sav-btn__orange--fill">+ iCal Export</button>
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: ".5px solid #f2f2f2",
                  borderBottom: ".5px solid #f2f2f2",
                  padding: 20,
                  marginTop: 30,
                }}
              >
                {previousEvent(event2)}
                {nextEvent(event2)}
              </div>
            </Col>
            <Col sm={4}>
              <div style={{ background: "#fafaf9", padding: 20 }}>
                <Header>DETAILS</Header>
                <SavOutput>
                  <SavLabel>Date</SavLabel>
                  {parseDate(event2.startDate)}
                  <br />
                  -
                  <br />
                  {parseDate(endDate)}
                </SavOutput>
                <SavOutput>
                  <SavLabel>Event Type</SavLabel>
                  {event2.type === "Scheduler Event" ? event2.workType : event2.eventType}
                </SavOutput>
                <Header style={event2.locationType === 'Online' ? hidden : null}>Location</Header>
                {renderLocationData()}
                <Header>Host</Header>
                <SavOutput>
                  {event2.organizerName || "Pending.."}
                  <div style={!event2.organizerEmail ? hidden : null} className="m-t-5">
                    <a className="oj linkHover" href={`mailto:${event2.organizerEmail}`} rel="noopener noreferrer" target="_blank">{event2.organizerEmail}</a> 
                  </div>
                  <div style={!event2.organizerPhone ? hidden : null} className="m-t-5">
                    <a className="oj linkHover" href={`tel:+${event2.organizerPhone}`}>{event2.organizerPhone}</a> 
                  </div>
                </SavOutput>
                <Header style={event2.type !== "Scheduler Event" ? hidden : null}>Attendees</Header>
                {event2.appointmentAttendee.map((attendee) => {
                  return (
                    <SavOutput key={attendee.sfid}>
                      {attendee.firstName} {attendee.lastName}
                      <br />
                      <a className="oj linkHover" href={`mailto:${attendee.email}`}>{attendee.email}</a> 
                    </SavOutput>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    events: state.events.data,
    activeEvent: state.activeEvent.data,
  };
};
export default connect(mapStateToProps, { fetchEvents, fetchEvent })(
  EventDetail
);

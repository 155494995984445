import React, {useState} from "react";
import {Dropdown, FormControl} from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import styled from "styled-components";

// const SavDropInput = styled.input`
//   outline: 0 none;
//   padding: 4px;
//   border: 1px solid #979797;
//   font-size: 12px;
//   width: ${props => props.width || "100%"};
// `;

const SavDropInput = {
  outline: "0 none",
  padding: 4,
  border: "1px solid #979797",
  fontSize:12,
  width:"100%"
}

const SavDropToggle = styled.div`
  display:flex;
  justify-content:space-between;
  cursor:pointer;
  padding:${props => props.flag ===  true ? "12px 6px" : "8px"};
  border:1px solid #979797;
  background-color:#fff;
  font-size: 12px;
  height: 42px;
`;

export const eliteSearchAlgorithm = (searchString, obj) => {
  const normalize = (data) => {
    return String(data)
      .toLowerCase()
      .trim()
      .replace(/-|_|#|\s|\.|\,|\?|%20/gi, "");
  };
  let predicate = false;
  const searchReg = normalize(searchString);
  const objReg = normalize(obj);
  if (objReg.includes(searchReg)) {
    predicate = true;
  }
  return predicate;
};

const CustomToggle = React.forwardRef(({children, onClick, flag}, ref) => (
  <SavDropToggle ref={ref} flag={flag ? true : false} onClick={(e) => {
    e.preventDefault();
    onClick(e);
  }}
   >
     {children}
     <FaCaretDown />
   </SavDropToggle>
))

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          style={SavDropInput}
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          type="search"
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              eliteSearchAlgorithm(value, child.props.children),
          )}
        </ul>
      </div>
    );
  },
);

const SavDropFilter = props => {
  const { val, opts, click, style, clickKey, children, flag, valid } = props;
  return (
    <Dropdown className="savDrop">
      <Dropdown.Toggle id="dropdownFilter" as={CustomToggle} flag valid={valid} >
        {children ? children : val}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu} flip={false}>
        {opts.map((o,i) => {
          return (
            <Dropdown.Item
              key={clickKey ? o.code : o.id}
              name={o}
              onClick={clickKey ? () => click(o[clickKey]) : () => click(o.id)}
              className="onHov"
              style={{ borderBottom: "1px solid #DBD9D6" }}
            >
              {clickKey ? `${o.name}, ${o.code}` : o.text}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
  
export default SavDropFilter;
import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FiRefreshCcw } from "react-icons/fi";
import _ from "lodash";
import Skeleton from 'react-loading-skeleton';
import SavPagination from "../../components/SavPagination";
import certStudio from "../../images/Education/certStudio.png";
import certShades from "../../images/Education/certShades.png";
import certSavant from "../../images/Education/certSavant.png";
import certPower from "../../images/Education/certPower.png";
import {StatusPill} from "../../components/Styled";
import {parseDateShort, chunkList} from "../../components/Utilities";
import construction from "../Account/Overview/construction.png"

const DetailContainer = styled(Container)``;

const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  color: #565551;
`;

const SettingRow = styled(Row)`
  background-color: #fafaf9;
  padding: 15px;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items:center;
`;

const Education = props => {
  const { user, education } = props;
  const [userEd, setUserEd] = useState({});
  const [isNDD, setIsNDD] = useState(user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'US Distribution');
  const [enrollmentsFiltered, setEnrollmentsFiltered] = useState([])
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  useEffect(() => {
    if (!_.isEmpty(education) && !_.isEmpty(user)){
      const userEdFound = _.find(education, (e) => e.contactId === user.contact.sfid);
      setUserEd(userEdFound);
    }
  },[education, user]);

  useEffect(() => {
    if (!_.isEmpty(userEd)){
      setEnrollmentList(userEd.enrollments);
    }
  },[userEd]);
  
  useEffect(() => {
    if (!_.isEmpty(enrollmentList)){
      setEnrollmentsFiltered(chunkList(enrollmentList, pageSize));
    }
  },[enrollmentList])

  const getCompletePill = () => {
    return <StatusPill bg="#4cd964" color="#fff" border="1px solid #4cd964">Complete</StatusPill>
  };
  const getWipPill = () => {
    return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>
  };

  const renderEnrolled = () => {
    if (userEd.enrollments.length === 0){
      return <div className="book f12 text-center m-t-20">No course enrollments yet.</div>;
    }
    if(!_.isEmpty(enrollmentsFiltered)){
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > enrollmentsFiltered.length) {
        page = 0;
        setCurrentPage(1);
      }
      return enrollmentsFiltered[page].map((e) => {
        if (e.courseId !== '182074' && e.courseId !== '223139' && e.courseId !== '100853'){
          return (
            <SettingRow key={e.sfid}>
              <Col sm={9}>
                <div className="med">{e.title}</div>
                <div>
                  {e.completed ? 
                  `Completed: ${parseDateShort(e.completed)}` : 
                  `Enrolled: ${parseDateShort(e.enrolled)}`}
                </div>
              </Col>
              <Col sm={3}>
                {e.completed ? getCompletePill() : getWipPill()}
              </Col>
            </SettingRow>
          )
        }
      })

    }
  }

  const renderCert = (courseId, completed ) => {

    const renderDate = (certIn) => {

      if (completed && certIn.completed) {
        return <div>Completed: {parseDateShort(certIn.completed)}</div>
      }else if (!completed && certIn.enrolled != null){
        return <div>Enrolled: {parseDateShort(certIn.enrolled)}</div>
      } else {
        return <div>Not yet started</div>
      }

    }
    const renderPill = (certIn) => {
      if (completed && certIn.completed) {
        return <StatusPill bg="#4cd964" color="#fff" border="1px solid #4cd964">Complete</StatusPill>;
      } else if (!completed && certIn.enrolled != null) {
        return <StatusPill bg="#ffcc5f" color="#fff" border="1px solid #ffcc5f">WIP&nbsp;&nbsp;<FiRefreshCcw style={{stroke:'#fff'}} /></StatusPill>;
      } else {
        let courseLink = '';
        if (courseId === "blueprint"){
          courseLink = 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=savantfundamentals'
        } else if (courseId === 'studio'){
          courseLink = 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=savantstudio';
        } else if (courseId === 'shades'){
          courseLink = 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=shadecert';
        } else if (courseId === 'power' && !isNDD){
          courseLink = 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=powercert';
        } else if (courseId === 'power' && isNDD){
          courseLink = 'https://community.savant.com/Customers/Savant_University?Destination=/savant/s/autoEnrollment?ID=powercertspl';
        }
        return (
          <a href={courseLink} target="_blank" rel="noreferrer">
            <StatusPill>+ Enroll&nbsp;&nbsp;</StatusPill>
          </a>
        )
      }
    }


    if (courseId === "blueprint"){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="blueprint" src={certSavant} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Fundamentals with Blueprint</div>
            {renderDate(userEd.blueprint)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.blueprint)}
          </Col>
        </SettingRow>
      )
    }
    if (courseId === "studio"){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="studio" src={certStudio} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Fundamentals with Studio</div>
            {renderDate(userEd.studio)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.studio)}
          </Col>
        </SettingRow>
      )
    }
    if (courseId === "shades"){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="shades" src={certShades} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Shades Certification</div>
            {renderDate(userEd.shade)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.shade)}
          </Col>
        </SettingRow>
      )
    }
    if (courseId === "power" && !isNDD){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="power" src={certPower} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Power System Certification with Blueprint</div>
            {renderDate(userEd.power)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.power)}
          </Col>
        </SettingRow>
      )
    }
    if (courseId === "power" && isNDD){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="power" src={certPower} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Power System Certification with Power & Light App</div>
            {renderDate(userEd.power)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.power)}
          </Col>
        </SettingRow>
      )
    }
    if (courseId === "core"){
      return(
        <SettingRow>
          <Col sm={4}>
            <img alt="core" src={certSavant} height={75} />
          </Col>
          <Col sm={5}>
            <div className="med">Savant Fundamentals</div>
            {renderDate(userEd.core)}
          </Col>
          <Col sm={3}>
            {renderPill(userEd.core)}
          </Col>
        </SettingRow>
      )
    }
    return null;
  }

  if (user && education && !_.isEmpty(userEd)) {
    return (
      <Fragment>
        <img src={construction} style={{width: '100%'}} />
        <DetailContainer>
        <SettingRow>
          <Col xs={12}>
            <Header>CERTIFICATION COURSES</Header>
          </Col>
        </SettingRow>
        {!isNDD ? renderCert("blueprint", userEd.completedBlueprint) : null}
        {renderCert("studio", userEd.completedStudio)}
        {renderCert("shades", userEd.completedShade)}
        {renderCert("power", userEd.completedPower)}
        <SettingRow className="m-t-20">
          <Col xs={12}>
            <Header>ENROLLED COURSES</Header>
          </Col>
        </SettingRow>
        {renderEnrolled()}
        <SavPagination
          data={enrollmentsFiltered}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataLength={enrollmentList.length}
        />
      </DetailContainer>
      </Fragment>
      
    );
  }
  if (user && education && _.isEmpty(userEd)){
    return (
      <div className="m-t-40 text-center f14 light-gray">
        We're having trouble fetching your Education information at the moment. Please try again later.
      </div>
    )
  }
  return (
    <Container>
      <Skeleton count={3} height={75} />
      <Skeleton count={1} height={250} />
    </Container>
  )
};
export default Education;

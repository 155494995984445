import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import SavBanner from "../../components/SavBanner";
import { Row, Col, Container, Tab, Nav } from "react-bootstrap";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchProfileRequests } from "../../actions";
import SavColumnSort from "../../components/SavColumnSort";
import { SavSearch } from "../../components/Styled";
import { Link } from "react-router-dom";
import SavPagination from "../../components/SavPagination";
import SavCheck from "../../components/SavCheck";

const tabsStyle = {
  fontSize: 14,
  backgroundColor: "#3f3f3f",
  textAlign: "center",
  maxHeight: 20,
  paddingBottom: 30,
  textTransform: "uppercase"
};

const tabActive = {
  color: "#ff5f00"
};

const InvoiceGridItem = styled.div`
  min-height: 175px;
  padding: 15px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Medium";
`;

const GridItemContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 16px;
`;

export const SavR = styled(Row)`
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: #ffffff;
  padding: 20px 0px;
  font-size: 12px;
  margin-bottom: 6px;
`;

const Profiles = props => {
  const { user, profiles, fetchProfileRequests } = props;

  const [profileList, setProfileList] = useState([]);

  const [privateList, setPrivateList] = useState([]);
  const [privateFilter, setPrivateFilter] = useState([]);

  const [publicList, setPublicList] = useState([]);
  const [publicFilter, setPublicFilter] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  const [privateCurrentPage, setPrivateCurrentPage] = useState(1);
  const [publicCurrentPage, setPublicCurrentPage] = useState(1);

  const [tabKey, setTabKey] = useState("private");
  const [tabKey2, setTabKey2] = useState("requests");

  const [searchString, setSearchString] = useState("");
  const [privateSearchString, setPrivateSearchString] = useState("");

  const [iFrameHeight, setiFrameHeight] = useState("");

  const [profileToolUsername, setProfileToolUserName] = useState("");
  const [SFTOKEN, setSFTOKEN] = useState("");
  const REF = localStorage.getItem("ref");

  const handleChange = e => {
    setSearchString(e.target.value);
  }
  const handlePrivateChange = e => {
    setPrivateSearchString(e.target.value);
  }

  useEffect(() => {
    document.title = "Savant Community | Profiles";
    fetchProfileRequests();
  },[])

  useEffect(() => {
    if (!_.isEmpty(profiles)) {
      setProfileList(profiles.data);
      setPrivateList(profiles.data.privateRequests);
      setPublicList(profiles.data.publicRequests);
    } else {
      fetchProfileRequests();
    }
  }, [profiles]);

  useEffect(() => {
    
    if (!_.isEmpty(profiles)) {
      if (privateSearchString !== "") {
        setPrivateCurrentPage(1);
        let tempProfiles = privateList.filter(
          profile =>
            profile.profileInfo.toLowerCase().includes(privateSearchString)
        );
        setPrivateList(tempProfiles);
      } else {
        setPrivateList(profileList.privateRequests);
      }
    }
    
  }, [privateSearchString]);

  useEffect(() => {
    if (!_.isEmpty(profiles)) {
      setPublicCurrentPage(1);
      let tempProfiles = publicList.filter(
        profile =>
          profile.profileInfo.toLowerCase().includes(searchString)

      );
      setPublicList(tempProfiles);
    }
    if (searchString === "") {
      setPublicList(profileList.publicRequests);
    }
  }, [searchString]);

  useEffect(() => {
    if (privateList) {
      let index = 0;
      const tempProfiles = [];
      for (index = 0; index < privateList.length; index += pageSize) {
        let chunk = privateList.slice(index, index + pageSize);
        tempProfiles.push(chunk);
      }
      setPrivateFilter(tempProfiles);
    }
  }, [pageSize, privateList]);

  useEffect(() => {
    if (publicList) {
      let index = 0;
      const tempProfiles = [];
      for (index = 0; index < publicList.length; index += pageSize) {
        let chunk = publicList.slice(index, index + pageSize);
        tempProfiles.push(chunk);
      }
      setPublicFilter(tempProfiles);
    }
  }, [pageSize, publicList]);

  const handleClearFilters = () => {
    setSearchString("");
    setPrivateSearchString("");
  };

  useEffect(() => {
    if (user) {
      setProfileToolUserName(user.username);
      setSFTOKEN(localStorage.getItem("sfsid"));
    }
  }, [user, profiles]);

  const renderHeaders = () => {
    if (!_.isEmpty(profiles)) {
      return (
        <Row>
          <Col sm={4} style={{ padding: "15px" }}>
            <InvoiceGridItem>
              Public Profile Requests
              <div style={{ paddingTop: "40px" }}>
                <GridItemContent>
                  {publicList.length}
                </GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
          <Col sm={4} style={{ padding: "15px" }}>
            <InvoiceGridItem>
              My Profile Requests
              <div style={{ paddingTop: "40px" }}>
                <GridItemContent>
                  {privateList.length}
                </GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
          <Col sm={4} style={{ padding: "15px" }}>
            <InvoiceGridItem>
              Helpful Links
              <div>
                <GridItemContent>
                  <Link to="/knowledge/Component-Profile-Developer-s-Guide-Part-1-1423469830419" className="med f12 oj linkHover">Component Profile Developer's Guide</Link>
                  <br />
                  <Link to="/latestSoftware" className="med f12 oj linkHover">Sam Version Info</Link>
                  <br />
                  <Link to="/knowledge/App-Note-Profile-Import-Tool-Setup-Guide-1423469833062" className="med f12 oj linkHover">Profile Import Info</Link>
                  <br />
                  <Link to="/knowledge/RacePoint-Blueprint-BACnet-Profile-Tool-Application-Note" className="med f12 oj linkHover">BACnet Profile Tool</Link>
                </GridItemContent>
              </div>
            </InvoiceGridItem>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderPrivateProfiles = () => {
    if (!_.isEmpty(privateFilter)) {
      let page = privateCurrentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > privateFilter.length) {
        page = 0;
        setPrivateCurrentPage(1);
      }
      return privateFilter[page].map(profile => {
        if (
          profile.targetDate == null &&
          profile.status !== "Work in Progress"
        ) {
          profile.targetDate = "Needs More Info";
        } else if (
          profile.targetDate == null &&
          profile.status === "Work in Progress"
        ) {
          profile.targetDate = "Work in Progress";
        }
        if (profile.targetDate.includes("Z")) {
          profile.targetDate = new Date(
            profile.targetDate
          ).toLocaleDateString();
        }
        return (
          <SavR key={profile.caseId}>
            <Col className="truncate" sm={4} xs={3}>{profile.profileInfo}</Col>
            <Col sm={4} xs={3}>{profile.targetDate}</Col>
            <Col sm={2} xs={3}>
              <a href={`${REF}/Customers/${profile.caseId}`} target="_blank">{profile.caseNumber}</a>
            </Col>
            <Col sm={2} xs={3} className="text-center">
              <SavCheck checked={profile.jiraStatus} center cursor="default" />
            </Col>
          </SavR>
        );
      });
    }
    if (privateList) {
      return (
        <Row>
          <Col
            xs={12}
            style={
              privateList.length > 0
                ? { display: "none" }
                : { textAlign: "center", marginTop: 30 }
            }
          >
            No Results found.
            <br />
            <br />
            <button
              className="sav-btn__orange--text"
              onClick={() => handleClearFilters()}
            >
              Clear Filters
            </button>
          </Col>
        </Row>
      );
    }
  };

  const renderPublicProfiles = () => {
    if (!_.isEmpty(publicFilter)) {
      let page = publicCurrentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > publicFilter.length) {
        page = 0;
        setPublicCurrentPage(1);
      }
      return publicFilter[page].map(profile => {
        if (profile.targetDate.includes("Z")) {
          profile.targetDate = new Date(
            profile.targetDate
          ).toLocaleDateString();
        }
        return (
          <SavR key={profile.profileInfo}>
            <Col className="truncate" sm={5} xs={5}>{profile.profileInfo}</Col>
            <Col sm={5} xs={4}>{profile.targetDate}</Col>
            <Col sm={2} xs={3} className="text-center">
              <SavCheck cursor="default" checked={profile.jiraStatus} center />
            </Col>
          </SavR>
        );
      });
    }
    if (publicList) {
      return (
        <Row>
          <Col
            xs={12}
            style={
              publicList.length > 0
                ? { display: "none" }
                : { textAlign: "center", marginTop: 30 }
            }
          >
            No Results found.
            <br />
            <br />
            <button
              className="sav-btn__orange--text"
              onClick={() => handleClearFilters()}
            >
              Clear Filters
            </button>
          </Col>
        </Row>
      );
    }
  };

  const renderFrame = () => {
    if (!_.isEmpty(profileToolUsername)){
      return (
        <iframe
          title="infoPage"
          id="infoPage"
          src={`https://auth.savantsystems.com/profileInfo/profiles.php?username=${profileToolUsername}&sessionid=${SFTOKEN}&profile=&page=`}
          style={{
            border: "black",
            borderStyle: "solid",
            borderWidth: "thin",
            //   maxWidth: "1300px",
            width: "100%",
            height: iFrameHeight
          }}
          onLoad={() => {
            document.getElementById("infoPage").height =
              window.innerHeight - 165;
          }}
          width="100%"
          height={iFrameHeight}
        ></iframe>
      )
    }
    return null;
  }

  // MAIN DIV
  if (publicList && privateList) {
    return (
      <div>
        <SavBanner text="Profile Tools" />
        <Tab.Container
          id="overview-tabs"
          activeKey={tabKey2}
          onSelect={k => setTabKey2(k)}
        >
          <Nav style={tabsStyle} className="justify-content-center">
            <Nav.Item>
              <Nav.Link
                className="tab-link"
                eventKey="requests"
                style={tabKey2 === "requests" ? tabActive : null}
              >
                Profile Requests Report
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="tab-link"
                eventKey="infoTool"
                style={tabKey2 === "infoTool" ? tabActive : null}
              >
                Profile Info Tool
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="requests">
              <Container>
                {renderHeaders()}
                <Tab.Container
                  id="overview-tabs"
                  activeKey={tabKey}
                  onSelect={k => setTabKey(k)}
                >
                  <Nav className="justify-content-center">
                    <Nav.Item>
                      <Nav.Link
                        className="tab-link-overview"
                        eventKey="private"
                        style={tabKey === "private" ? tabActive : null}
                      >
                        My Open Requests
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="tab-link-overview"
                        eventKey="public"
                        style={tabKey === "public" ? tabActive : null}
                      >
                        Open Public Requests
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="private">
                      <Container>
                        <Row>
                          <Col sm={6}>
                            <SavSearch
                              type="search"
                              placeholder="Search.."
                              width="90%"
                              value={privateSearchString}
                              onChange={handlePrivateChange}

                            />
                          </Col>
                        </Row>
                        <div
                          style={{ paddingBottom: "10px", paddingTop: "10px" }}
                        >
                          <SavColumnSort
                            data={privateList}
                            setData={setPrivateList}
                            columns={[
                              {
                                text: "Profile",
                                size: 4,
                                sizeXS: 3,
                                value: "profileInfo"
                              },
                              {
                                text: "Target Date",
                                size: 4,
                                sizeXS: 3,
                                value: "targetDate"
                              },
                              { text: "Case Number", size: 2, sizeXS: 3, value: "caseId" },
                              {
                                text: "Completed?",
                                size: 2,
                                sizeXS: 3,
                                value: "jiraStatus"
                              }
                            ]}
                          />
                        </div>
                        {renderPrivateProfiles()}
                        <SavPagination
                          data={privateFilter}
                          pageSize={pageSize}
                          currentPage={privateCurrentPage}
                          setCurrentPage={setPrivateCurrentPage}
                          dataLength={privateList.length}
                        />
                      </Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="public">
                      <Container>
                        <Row>
                          <Col sm={6}>
                            <SavSearch
                              type="search"
                              placeholder="Search.."
                              width="90%"
                              value={searchString}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                        <div
                          style={{ paddingBottom: "10px", paddingTop: "10px" }}
                        >
                          <SavColumnSort
                            data={publicList}
                            setData={setPublicList}
                            columns={[
                              {
                                text: "Profile",
                                size: 5,
                                sizeXS: 5,
                                value: "profileInfo"
                              },
                              {
                                text: "Target Date",
                                size: 5,
                                sizeXS: 4,
                                value: "targetDate"
                              },
                              {
                                text: "Completed?",
                                size: 2,
                                sizeXS: 3,
                                value: "jiraStatus"
                              }
                            ]}
                          />
                        </div>
                        {renderPublicProfiles()}
                        <SavPagination
                          data={publicFilter}
                          pageSize={pageSize}
                          currentPage={publicCurrentPage}
                          setCurrentPage={setPublicCurrentPage}
                          dataLength={publicList.length}
                        />
                      </Container>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="infoTool">
              <Container
                style={{
                  textAlign: "center",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                  paddingTop: "5%"
                }}
                fluid
              >
                {renderFrame()}
              </Container>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Skeleton count={1} height={160} />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Skeleton count={1} height={175} />
        </Col>
        <Col sm={4}>
          <Skeleton count={1} height={175} />
        </Col>
        <Col sm={4}>
          <Skeleton count={1} height={175} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Skeleton count={5} height={60} />
        </Col>
      </Row>
    </Container>
  )
};

const mapStateToProps = state => {
  return {
    profiles: state.profileRequests,
    user: state.user.data
  };
};

export default connect(mapStateToProps, { fetchProfileRequests })(Profiles);

export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const SET_TOAST_STATUS = "SET_TOAST_STATUS";
export const SET_FAIL_STATUS = "SET_FAIL_STATUS";

export const FETCH_GRID_SUCCESS = "FETCH_GRID_SUCCESS";
export const FETCH_GRID_REQUEST = "FETCH_GRID_REQUEST";
export const FETCH_GRID_FAILURE = "FETCH_GRID_FAILURE";

export const UPDATE_GRID_REQUEST = "UPDATE_GRID_REQUEST";
export const UPDATE_GRID_SUCCESS = "UPDATE_GRID_SUCCESS";
export const UPDATE_GRID_FAILURE = "UPDATE_GRID_FAILURE";

export const FETCH_CAROUSEL_REQUEST = "FETCH_CAROUSEL_REQUEST";
export const FETCH_CAROUSEL_SUCCESS = "FETCH_CAROUSEL_SUCCESS";

export const FETCH_FEED_REQUEST = "FETCH_FEED_REQUEST";
export const FETCH_FEED_SUCCESS = "FETCH_FEED_SUCCESS";

export const FETCH_NEWS_REQUEST = "FETCH_NEWS_REQUEST";
export const FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS";
export const FETCH_NEWS_FAILURE = "FETCH_NEWS_FAILURE";

export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNT_REQUEST = "FETCH_ACCOUNT_REQUEST";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";

export const FETCH_ADDRESSES_REQUEST = "FETCH_ADDRESSES_REQUEST";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILURE = "FETCH_ADDRESSES_FAILURE";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";

export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAILURE = "CREATE_ADDRESS_FAILURE";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";

export const FETCH_CONTACT_REQUEST = "FETCH_CONTACT_REQUEST";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_FAILURE = "FETCH_CONTACT_FAILURE";

export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILURE = "UPDATE_CONTACT_FAILURE";

export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";

export const FETCH_SERIALS_REQUEST = "FETCH_SERIALS_REQUEST";
export const FETCH_SERIALS_SUCCESS = "FETCH_SERIALS_SUCCESS";

export const FETCH_CREDITS_REQUEST = "FETCH_CREDITS_REQUEST";
export const FETCH_CREDITS_SUCCESS = "FETCH_CREDITS_SUCCESS";

export const FETCH_INVOICES_REQUEST = "FETCH_INVOICES_REQUEST";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";

export const FETCH_DOCUMENTS_REQUEST = "FETCH_DOCUMENTS_REQUEST";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const FETCH_DOCUMENTS_FAILURE = "FETCH_DOCUMENTS_FAILURE";

export const FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

export const CREATE_NOTIFICATION_REQUEST = "CREATE_NOTIFICATION_REQUEST";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE = "CREATE_NOTIFICATION_FAILURE";

export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_REQUEST = "FETCH_ARTICLES_REQUEST";
export const FETCH_ARTICLES_FAILURE = "FETCH_ARTICLES_FAILURE";

export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_REQUEST = "FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const FETCH_RMA_SUCCESS = "FETCH_RMA_SUCCESS";
export const FETCH_RMA_REQUEST = "FETCH_RMA_REQUEST";
export const FETCH_RMA_FAILURE = "FETCH_RMA_FAILURE";

export const UPDATE_SAVED_ARTICLES_SUCCESS = "UPDATE_SAVED_ARTICLES_SUCCESS";
export const UPDATE_SAVED_ARTICLES_REQUEST = "UPDATE_SAVED_ARTICLES_REQUEST";
export const UPDATE_SAVED_ARTICLES_FAILURE = "UPDATE_SAVED_ARTICLES_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const FETCH_AGREEMENTS_REQUEST = "FETCH_AGREEMENTS_REQUEST";
export const FETCH_AGREEMENTS_SUCCESS = "FETCH_AGREEMENTS_SUCCESS";
export const FETCH_AGREEMENTS_FAILURE = "FETCH_AGREEMENTS_FAILURE";

export const UPDATE_AGREEMENT_REQUEST = "UPDATE_AGREEMENT_REQUEST";
export const UPDATE_AGREEMENT_SUCCESS = "UPDATE_AGREEMENT_SUCCESS";
export const UPDATE_AGREEMENT_FAILURE = "UPDATE_AGREEMENT_FAILURE";

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export const FETCH_EVENT_REQUEST = "FETCH_EVENT_REQUEST";
export const FETCH_EVENT_SUCCESS = "FETCH_EVENT_SUCCESS";
export const FETCH_EVENT_FAILURE = "FETCH_EVENT_FAILURE";

export const FETCH_MARKETING_REQUEST = "FETCH_MARKETING_REQUEST";
export const FETCH_MARKETING_SUCCESS = "FETCH_MARKETING_SUCCESS";
export const FETCH_MARKETING_FAILURE = "FETCH_MARKETING_FAILURE";

export const FETCH_CASES_REQUEST = "FETCH_CASES_REQUEST";
export const FETCH_CASES_SUCCESS = "FETCH_CASES_SUCCESS";
export const FETCH_CASES_FAILURE = "FETCH_CASES_FAILURE";

export const FETCH_CASE_REQUEST = "FETCH_CASE_REQUEST";
export const FETCH_CASE_FAILURE = "FETCH_CASE_FAILURE";
export const FETCH_CASE_SUCCESS = "FETCH_CASE_SUCCESS";

export const FETCH_EDUCATION_REQUEST = "FETCH_EDUCATION_REQUEST";
export const FETCH_EDUCATION_SUCCESS = "FETCH_EDUCATION_SUCCESS";
export const FETCH_EDUCATION_FAILURE = "FETCH_EDUCATION_FAILURE";

export const FETCH_EDUCATION_DETAIL_REQUEST = "FETCH_EDUCATION_DETAIL_REQUEST";
export const FETCH_EDUCATION_DETAIL_SUCCESS = "FETCH_EDUCATION_DETAIL_SUCCESS";
export const FETCH_EDUCATION_DETAIL_FAILURE = "FETCH_EDUCATION_DETAIL_FAILURE";

export const FETCH_CREDIT_CARDS_REQUEST = "FETCH_CREDIT_CARDS_REQUEST";
export const FETCH_CREDIT_CARDS_SUCCESS = "FETCH_CREDIT_CARDS_SUCCESS";
export const FETCH_CREDIT_CARDS_FAILURE = "FETCH_CREDIT_CARDS_FAILURE";

export const DELETE_CREDIT_CARD_REQUEST = "DELETE_CREDIT_CARD_REQUEST";
export const DELETE_CREDIT_CARD_SUCCESS = "DELETE_CREDIT_CARD_SUCCESS";
export const DELETE_CREDIT_CARD_FAILURE = "DELETE_CREDIT_CARD_FAILURE";

export const CREATE_CREDIT_CARD_REQUEST = "CREATE_CREDIT_CARD_REQUEST";
export const CREATE_CREDIT_CARD_SUCCESS = "CREATE_CREDIT_CARD_SUCCESS";
export const CREATE_CREDIT_CARD_FAILURE = "CREATE_CREDIT_CARD_FAILURE";

export const FETCH_LATEST_SOFTWARE_REQUEST = "FETCH_LATEST_SOFTWARE_REQUEST";
export const FETCH_LATEST_SOFTWARE_SUCCESS = "FETCH_LATEST_SOFTWARE_SUCCESS";
export const FETCH_LATEST_SOFTWARE_FAILURE = "FETCH_LATEST_SOFTWARE_FAILURE";

export const FETCH_BETA_REQUEST = "FETCH_BETA_REQUEST";
export const FETCH_BETA_SUCCESS = "FETCH_BETA_SUCCESS";
export const FETCH_BETA_FAILURE = "FETCH_BETA_FAILURE";

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const FETCH_DISTRIBUTORS_REQUEST = "FETCH_DISTRIBUTORS_REQUEST";
export const FETCH_DISTRIBUTORS_SUCCESS = "FETCH_DISTRIBUTORS_SUCCESS";
export const FETCH_DISTRIBUTORS_FAILURE = "FETCH_DISTRIBUTORS_FAILURE";

export const UPDATE_DISTRIBUTOR_REQUEST = 'UPDATE_DISTIBUTOR_REQUEST';
export const UPDATE_DISTRIBUTOR_SUCCESS = 'UPDATE_DISTIBUTOR_SUCCESS';
export const UPDATE_DISTRIBUTOR_FAILURE = 'UPDATE_DISTIBUTOR_FAILURE';

export const CREATE_DISTRIBUTOR_REQUEST = 'CREATE_DISTRIBUTOR_REQUEST';
export const CREATE_DISTRIBUTOR_SUCCESS = 'CREATE_DISTRIBUTOR_SUCCESS';
export const CREATE_DISTRIBUTOR_FAILURE = 'CREATE_DISTRIBUTOR_FAILURE';

export const DELETE_DISTRIBUTOR_REQUEST = 'DELETE_DISTRIBUTOR_REQUEST';
export const DELETE_DISTRIBUTOR_SUCCESS = 'DELETE_DISTRIBUTOR_SUCCESS';
export const DELETE_DISTRIBUTOR_FAILURE = 'DELETE_DISTRIBUTOR_FAILURE';

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILURE = "CREATE_TASK_FAILURE";

export const FETCH_TASKS_REQUEST = "FETCH_TASKS_REQUEST";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILURE = "FETCH_TASKS_FAILURE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const FETCH_ORDER_PDF_REQUEST = "FETCH_ORDER_PDF_REQUEST";
export const FETCH_ORDER_PDF_FAILURE = "FETCH_ORDER_PDF_FAILURE";
export const FETCH_ORDER_PDF_SUCCESS = "FETCH_ORDER_PDF_SUCCESS";

export const CREATE_TAX_CERT_REQUEST = "CREATE_TAX_CERT_REQUEST";
export const CREATE_TAX_CERT_FAILURE = "CREATE_TAX_CERT_FAILURE";
export const CREATE_TAX_CERT_SUCCESS = "CREATE_TAX_CERT_SUCCESS";

export const UPDATE_TAX_CERT_REQUEST = "UPDATE_TAX_CERT_REQUEST";
export const UPDATE_TAX_CERT_FAILURE = "UPDATE_TAX_CERT_FAILURE";
export const UPDATE_TAX_CERT_SUCCESS = "UPDATE_TAX_CERT_SUCCESS";

export const DELETE_TAX_CERT_REQUEST = "DELETE_TAX_CERT_REQUEST";
export const DELETE_TAX_CERT_FAILURE = "DELETE_TAX_CERT_FAILURE";
export const DELETE_TAX_CERT_SUCCESS = "DELETE_TAX_CERT_SUCCESS";

export const CREATE_FEEDBACK_REQUEST = "CREATE_FEEDBACK_REQUEST";
export const CREATE_FEEDBACK_FAILURE = "CREATE_FEEDBACK_FAILURE";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";

export const FETCH_SOFTWARE_REQUEST = "FETCH_SOFTWARE_REQUEST";
export const FETCH_SOFTWARE_SUCCESS = "FETCH_SOFTWARE_SUCCESS";
export const FETCH_SOFTWARE_FAILURE = "FETCH_SOFTWARE_FAILURE";

export const FETCH_CONTENT_REQUEST = "FETCH_CONTENT_REQUEST";
export const FETCH_CONTENT_SUCCESS = "FETCH_CONTENT_SUCCESS";
export const FETCH_CONTENT_FAILURE = "FETCH_CONTENT_FAILURE";

export const FETCH_PAYMENTS_REQUEST = "FETCH_PAYMENTS_REQUEST";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

export const FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FETCH_PAYMENT_FAILURE";

export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILURE = "UPDATE_PAYMENT_FAILURE";

export const CREATE_PAYMENT_PDF_REQUEST = "CREATE_PAYMENT_PDF_REQUEST";
export const CREATE_PAYMENT_PDF_SUCCESS = "CREATE_PAYMENT_PDF_SUCCESS";
export const CREATE_PAYMENT_PDF_FAILURE = "CREATE_PAYMENT_PDF_FAILURE";

export const FETCH_PAYMENT_PDF_REQUEST = "FETCH_PAYMENT_PDF_REQUEST";
export const FETCH_PAYMENT_PDF_SUCCESS = "FETCH_PAYMENT_PDF_SUCCESS";
export const FETCH_PAYMENT_PDF_FAILURE = "FETCH_PAYMENT_PDF_FAILURE";

export const FETCH_BANK_REQUEST = "FETCH_BANK_REQUEST";
export const FETCH_BANK_SUCCESS = "FETCH_BANK_SUCCESS";
export const FETCH_BANK_FAILURE = "FETCH_BANK_FAILURE";

export const CREATE_BANK_REQUEST = "CREATE_BANK_REQUEST";
export const CREATE_BANK_SUCCESS = "CREATE_BANK_SUCCESS";
export const CREATE_BANK_FAILURE = "CREATE_BANK_FAILURE";

export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAILURE = "DELETE_BANK_FAILURE";

export const FETCH_SPEAKERS_REQUEST = "FETCH_SPEAKERS_REQUEST";
export const FETCH_SPEAKERS_SUCCESS = "FETCH_SPEAKERS_SUCCESS";
export const FETCH_SPEAKERS_FAILURE = "FETCH_SPEAKERS_FAILURE";

export const CREATE_SESSION_REGISTRATION_REQUEST = "CREATE_SESSION_REGISTRATION_REQUEST";
export const CREATE_SESSION_REGISTRATION_SUCCESS = "CREATE_SESSION_REGISTRATION_SUCCESS";
export const CREATE_SESSION_REGISTRATION_FAILURE = "CREATE_SESSION_REGISTRATION_FAILURE";

export const CREATE_SESSION_ATTENDANCE_REQUEST = "CREATE_SESSION_ATTENDANCE_REQUEST";
export const CREATE_SESSION_ATTENDANCE_SUCCESS = "CREATE_SESSION_ATTENDANCE_SUCCESS";
export const CREATE_SESSION_ATTENDANCE_FAILURE = "CREATE_SESSION_ATTENDANCE_FAILURE";

export const CREATE_REGISTRATION_REQUEST = "CREATE_REGISTRATION_REQUEST";
export const CREATE_REGISTRATION_SUCCESS = "CREATE_REGISTRATION_SUCCESS";
export const CREATE_REGISTRATION_FAILURE = "CREATE_REGISTRATION_FAILURE";

export const FETCH_SESSION_COMMENTS_REQUEST = "FETCH_SESSION_COMMENTS_REQUEST";
export const FETCH_SESSION_COMMENTS_SUCCESS = "FETCH_SESSION_COMMENTS_SUCCESS";
export const FETCH_SESSION_COMMENTS_FAILURE = "FETCH_SESSION_COMMENTS_FAILURE";

export const CREATE_COMMENT_REQUEST="CREATE_COMMENT_REQUEST";
export const CREATE_COMMENT_SUCCESS="CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE="CREATE_COMMENT_FAILURE";

export const FETCH_TRADESHOW_REQUEST = "FETCH_TRADESHOW_REQUEST";
export const FETCH_TRADESHOW_SUCCESS = "FETCH_TRADESHOW_SUCCESS";
export const FETCH_TRADESHOW_FAILURE = "FETCH_TRADESHOW_FAILURE"

export const FETCH_TRADESHOW_VIRTUAL_REQUEST = "FETCH_TRADESHOW_VIRTUAL_REQUEST";
export const FETCH_TRADESHOW_VIRTUAL_SUCCESS = "FETCH_TRADESHOW_VIRTUAL_SUCCESS";
export const FETCH_TRADESHOW_VIRTUAL_FAILURE = "FETCH_TRADESHOW_VIRTUAL_FAILURE";

export const CREATE_TRADESHOW_REGISTRATION_REQUEST = "CREATE_TRADESHOW_REGISTRATION_REQUEST";
export const CREATE_TRADESHOW_REGISTRATION_SUCCESS = "CREATE_TRADESHOW_REGISTRATION_SUCCESS";
export const CREATE_TRADESHOW_REGISTRATION_FAILURE = "CREATE_TRADESHOW_REGISTRATION_FAILURE";

export const CREATE_FORM_REQUEST = "CREATE_FORM_REQUEST";
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_FAILURE = "CREATE_FORM_FAILURE";

export const FETCH_SERVICES_PRODUCTS_REQUEST = "FETCH_SERVICES_PRODUCTS_REQUEST";
export const FETCH_SERVICES_PRODUCTS_SUCCESS = "FETCH_SERVICES_PRODUCTS_SUCCESS";
export const FETCH_SERVICES_PRODUCTS_FAILURE = "FETCH_SERVICES_PRODUCTS_FAILURE";
export const FETCH_SUBS_REQUEST = "FETCH_SUBS_REQUEST";
export const FETCH_SUBS_SUCCESS = "FETCH_SUBS_SUCCESS";
export const FETCH_SUBS_FAILURE = "FETCH_SUBS_FAILURE";

export const FETCH_HOMES_REQUEST = "FETCH_HOMES_REQUEST";
export const FETCH_HOMES_SUCCESS = "FETCH_HOMES_SUCCESS";
export const FETCH_HOMES_FAILURE = "FETCH_HOMES_FAILURE";

export const FETCH_HOME_REQUEST = "FETCH_HOME_REQUEST";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAILURE = "FETCH_HOME_FAILURE";








import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Form, Col, Container } from 'react-bootstrap';
import _ from "lodash";
import {FiThumbsDown, FiThumbsUp} from 'react-icons/fi';
import SavFormInput from '../../../components/SavFormInput';
import SavFormDrop from '../../../components/SavFormDrop';
import SavFormInputArea from '../../../components/SavFormInputArea';

const ModalBody = styled.div`
  padding: 20px;
`;

const PaymentFeedbackModal = ({show, hide, user, updateContact, fetchUser, createFeedback}) => {

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("Feedback");
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(1);
  const [ctf, setCtf] = useState("");
  const typeOpts = ['Feedback', 'Bug'];
  const [rating, setRating] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(user)){
      setEmail(user.username);
    }
  },[user]);

  useEffect(() => {
    setMessage("");
    setType("Feedback");
    setStep(1);
    setValidated(false);
    setCtf("");
  },[show]);

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const contactJSON = user.contact;
    contactJSON.ignorePaymentMessage = true;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    
    let ratingMessage = '';
    if (rating === 'up'){
      ratingMessage = "[Thumbs Up] - ";
    }
    if (rating === 'down'){
      ratingMessage = "[Thumbs Down] - ";
    }

    const messageWithRating = ratingMessage + message;

    const feedbackJSON = {
      message: messageWithRating,
      uri: '/payment',
      contactEmail: email,
      type: type,
      userAgent:'unknown',
      debugInfo:"",
    };
    createFeedback(feedbackJSON).then((res) => {
      setStep(2);
    });
    updateContact(contactJSON);
  };

  const handleDismiss = () => {
    const contactJSON = _.clone(user.contact);
    contactJSON.ignorePaymentMessage = true;
    updateContact(contactJSON);
    hide();
  }

  const renderBody = () => {
    if (step === 1){
      return (
        <Container>
          <Form className="sav-form" onSubmit={handleSave} noValidate validated={validated}>
            <Form.Row>
              <Form.Group as={Col} xs={6}>
                <SavFormInput value={email} setValue={setEmail} label="Email" />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <SavFormDrop value={type} setValue={setType} label="Type" opts={typeOpts} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <div className="book f12 text-center">
                  What did you think of the new payment experience?
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={6} className="onHov text-center" onClick={() => setRating('down')}>
                <FiThumbsDown size={50} style={rating === 'down' ? { stroke: "#ff5f00"} : null } />
              </Form.Group>
              <Form.Group as={Col} xs={6} className="onHov text-center" onClick={() => setRating('up')}>
                <FiThumbsUp size={50} style={rating === 'up' ? {stroke: "#ff5f00"} : null } />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <SavFormInputArea value={message} setValue={setMessage} label="Message" notRequired />
              </Form.Group>
            </Form.Row>
            <div className="m-t-20 text-center">
              <button className="sav-btn__gray m-r-10" onClick={() => handleDismiss()}>
                Dont Show Again
              </button>
              <button className="sav-btn__orange--fill" type="submit">
                Submit
              </button>
            </div>
          </Form>
        </Container>
      )
    }else {
      return (
        <Fragment>
        <div className="text-center f12 book">
          Your feedback has been submitted to Savant.
        </div>
        <div className="m-t-20 text-center">
        <button className="sav-btn__orange--fill" type="text" onClick={hide}>
          Close
        </button>
      </div>
      </Fragment>
      )
    }
  }

  return(
      <Modal
        show={show}
        onHide={hide}
        animation={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {step === 1 ? 'Submit Feedback' : 'Thank You!'}
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          {renderBody()}
        </ModalBody>
      </Modal>
  );
};

export default PaymentFeedbackModal;
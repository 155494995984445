import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import _ from "lodash";
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import {AiOutlineRightCircle} from 'react-icons/ai';
import {FiExternalLink} from 'react-icons/fi';

const CarouselCaption = styled.div`
  display:grid;
  align-content: space-between;
  justify-content: center;
  position: absolute;
  right: 15%;
  left: 15%;
  top: 35px;
  bottom: 35px;
  height: 156px;
  z-index: 10;
  text-align:center;
  color: #fff;
`;
const CarouselHeader = styled.div`
  font-size: 20px;
  font-family: "Gotham-Medium";
  color: #fff;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size:16px;
  }
`;
const CarouselBody = styled.div`
  font-size: 16px;
  font-family: "Gotham-Book";
  color: #fff;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size:12px;
  }
`;
const CarouselLink = styled.div`
  font-size: 16px;
  font-family: "Gotham-Book";
  color: #fff;
  text-transform: capitalize;
  cursor:pointer;
  & :hover {
    text-decoration:underline;
  }
  > a {
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
  };
  @media (max-width: 768px) {
    font-size:12px;
  }
`;
const InternalIcon = styled(AiOutlineRightCircle)`
  fill: #fff;
  height: 20px;
  width: 20px;
  @media (max-width: 768px) {
    height: 16px;
    width: 16px;
  }
`;
const ExternalIcon = styled(FiExternalLink)`
  stroke: #fff;
  height: 18px;
  width: 18px;
  @media (max-width: 768px) {
    height: 14px;
    width: 14px;
  }
`;

const CarouselImage = styled.img`
  object-fit: cover;
  height: 226px;
`;

const CarouselComponent = props => {
  const { items, page } = props;

  const relevantItems = [];

  if (items.data) {
    items.data.map(c => {
      if (c.location.includes(page)) {
        relevantItems.push(c);
      }
    });
  }

  const renderLink = (c) => {
    if (!_.isEmpty(c.linkSrc)){
      return (
        <a href={c.linkSrc} target={c.isExternal ? "_blank" : ""} rel="noopener noreferrer">
          {c.linkText}&nbsp;&nbsp;
          {!c.isExternal ? (<InternalIcon />) : (<ExternalIcon />) }
        </a>
      )
    }
    return null;
  }

  if (!_.isEmpty(items)){
    return (
      <Carousel>
        {relevantItems.map(c => {    
          return (
            <Carousel.Item key={c.sequence} interval={7000}>
              <CarouselImage src={c.img} className="d-block w-100" />
              <CarouselCaption>
                <CarouselHeader>
                  {c.headerText}
                </CarouselHeader>
                <CarouselBody>
                  {c.bodyText}
                </CarouselBody>
                <CarouselLink>
                  {renderLink(c)}
                  {/* <a href={c.linkSrc} target={c.isExternal ? "_blank" : ""} rel="noopener noreferrer">
                    {c.linkText}&nbsp;&nbsp;
                    {!c.isExternal ? (<InternalIcon />) : (<ExternalIcon />) }
                  </a> */}
                </CarouselLink>
              </CarouselCaption>

            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
  return (
    <Skeleton height={225} />
  )

  
};

export default CarouselComponent;

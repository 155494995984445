import React, { useEffect, useState } from "react";
// import GA4React, { useGA4React } from "ga-4-react";
import ReactGA from 'react-ga';

import { connect, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {visibilitySelector} from "./selectors";
import { BrowserRouter as Router, Route, Redirect, withRouter, useLocation, useHistory, Switch } from "react-router-dom";

import moment from 'moment';

import SavModalWarning from "./components/SavModalWarning";
import UserPreferences from "./containers/UserPreferences";
import Home from "./containers/Home";
import SCSHomes from "./containers/SCSHomes";
import Notification from "./containers/Notification";
import Account from "./containers/Account";
import AccountOrderDetails from './containers/Account/Orders/OrderDetails';
import AccountEducationDetail from "./containers/Account/Contacts/EducationDetail";
import AccountMakeAPayment from "./containers/Account/Billing/MakeAPayment";
import SavToast from "./components/SavToast";
import Knowledge from "./containers/Knowledge";
import Article from "./containers/Knowledge/Article";
import Beta from "./containers/Beta";
import News from "./containers/News";
import NewsDetail from "./containers/News/NewsDetail";
import Events from "./containers/Events";
import EventDetail from "./containers/Events/EventDetail";
import RMAs from "./containers/RMAs";
import Profiles from "./containers/Profiles";
import Cases from "./containers/Cases";
import CaseDetail from "./containers/Cases/CaseDetail";
import Agreements from "./containers/Agreements";
import LatestSoftware from './containers/LatestSoftware';
import MySupport from './containers/MySupport';
import Sales from "./containers/Sales";
import SpecialtySalesDetail from "./containers/Sales/SpecialtySalesDetail";
import DirectDealerPromotion from "./containers/Marketing/DirectDealerPromotion";
import Projects from "./containers/Projects";
import MyProjects from "./containers/MyProjects";
import Tradeshow from "./containers/Tradeshow";

import {
  fetchUser,
  fetchAgreements,
  fetchArticles,
  fetchArticle,
} from "./actions";
import _ from "lodash";
import "./App.css";

const AppWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px auto 250px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-width:375px;
`;
const ContentWrapper = styled.div`
  ${(props) => props.hash === '#subscriptions' || props.path === '/homes' || props.hash === '#overview' ? 'padding-bottom: 0px;' : 'padding-bottom: 100px'}
`;

const App = ({
  user,
  auth,
  fetchUser,
  agreements,
  fetchAgreements,
  articles,
  fetchArticles,
  history
  
}) => {
  const [redirect, setRedirect] = useState(false);
  const [lastLogin, setLastLogin] = useState(null);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [strict, setStrict] = useState(false);
  const refURL = localStorage.getItem("ref");

  const location = useLocation();
  const routerHistory = useHistory();

  useEffect(() => {
    if (_.isEmpty(user)) {
      fetchUser();
    } else {
      if (!initialized){
        const userId = user.data.contact.sfid;
        const accId = user.data.contact.accountId;
        const accType = user.data.contact.accType;
        const accSubType = user.data.contact.accSubType || 'None';
        const isAdmin = user.data.contact.permissions.accountAdmin;
        const isBilling = user.data.contact.permissions.ordersInvoices;
        const isTeam = user.data.contact.permissions.contactManagement;
        const isStore = user.data.contact.permissions.storePricing;
        const isAmbassador = user.data.isAmbassador;
        const programs = user.data.programs || 'None';
        const isOverride = user.data.isAccTypeOverride;

        ReactGA.initialize('UA-193655367-1', {
          gaOptions: {
            siteSpeedSampleRate: 100,
            userId: userId,
          }
        });
        ReactGA.set({userId: userId});
        ReactGA.set({'dimension1': userId});
        ReactGA.set({'dimension2': accType});
        ReactGA.set({'dimension3': accSubType});
        ReactGA.set({'dimension4': isAdmin});
        ReactGA.set({'dimension5': isBilling});
        ReactGA.set({'dimension6': isTeam});
        ReactGA.set({'dimension7': isStore});
        ReactGA.set({'dimension8': isAmbassador});
        ReactGA.set({'dimension9': programs});
        ReactGA.set({'dimension10': isOverride});
        ReactGA.set({'dimension11': accId});
        setInitialized(true);
      }
      
      setLastLogin(moment(user.data.lastLogin));
      if (user.data.needsAgreement) {
        setRedirect(true);
      } else {
        setRedirect(false);
      }
    }
    
  }, [user]);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search + location.hash);
    }
  },[initialized, location]);

  useEffect(() => {
    if (lastLogin){
      const now = new moment();
      const duration = moment.duration(now.diff(lastLogin));
      if (duration.hours() > 17){
        setNeedsRefresh(true);
      }
    }
  },[lastLogin]);

  useEffect(() => {
    if (auth){
      const now = new moment();
      const last = new moment(localStorage.getItem('lastLogin'));
      if (now.diff(last, 'seconds') < 100){
        setStrict(true);
      }
      routerHistory.push("/");
    }
  }, [auth]);

  useEffect(() => {
    if (_.isEmpty(agreements)) {
      fetchAgreements();
    }
  }, [agreements]);

  useEffect(() => {
    if (location.pathname === "/summit" && auth){
      routerHistory.push("/");
      localStorage.setItem("summitRedirect", "true")
    } else if (location.pathname === "/" && !auth && localStorage.getItem("summitRedirect") === "true") {
      routerHistory.push("/summit/agenda");
      localStorage.setItem("summitRedirect", "false");
    } else if (location.pathname === '/summit-virtual' && auth){
      routerHistory.push("/");
      localStorage.setItem("virtualRedirect", "true");
    } else if (location.pathname === "/" && !auth && localStorage.getItem("virtualRedirect") === "true"){
      routerHistory.push("/summit-virtual");
      localStorage.setItem("virtualRedirect", "false");
    }else if (location.pathname === '/tradeshow-qr' && auth){
      routerHistory.push("/");
      localStorage.setItem("tradeshowRedirect", "true");
      localStorage.setItem("tradeshowSession", location.search);
    } else if (location.pathname === "/" && !auth && localStorage.getItem("tradeshowRedirect") === "true"){
      routerHistory.push(`/tradeshow-qr${localStorage.getItem("tradeshowSession")}`);
      localStorage.setItem("tradeshowRedirect", "false");
    }

    if (location.pathname !== "/" && auth && location.pathname !== '/summit' && location.pathname !== '/summit-virtual' && location.pathName !== '/tradeshow-qr'){
      routerHistory.push("/");
    }
  },[location, auth])

  const AccountWithRouter = withRouter(Account);
  const NewsDetailWithRouter = withRouter(NewsDetail);
  const EventDetailWithRouter = withRouter(EventDetail);
  const EducationDetailWithRouter = withRouter(AccountEducationDetail);
  const OrderDetailWithRouter = withRouter(AccountOrderDetails);
  const MakeAPaymentWithRouter = withRouter(AccountMakeAPayment);
  const ArticleWithRouter = withRouter(Article);
  const HeaderWithRouter = withRouter(Header);
  const NotificationsWithRouter = withRouter(Notification);
  const SalesWithRouter = withRouter(Sales);
  const SpecialtySalesWithRouter = withRouter(SpecialtySalesDetail);
  const showDashboard = useSelector(visibilitySelector('account_dashboard'));

  return (
      <AppWrapper className="App">
        <HeaderWithRouter redirect={redirect} auth={auth} location={location} />
        <ContentWrapper hash={location.hash} path={location.pathname}>
          {/* ACTIVE ROUTES */}
          <Switch>
          <Route exact path="/agreements" component={Agreements} />
          <Route exact path="/">
            {redirect ? <Redirect to="/agreements" /> : <Home strict={strict} location={location} history={routerHistory} />}
          </Route>
          <Route exact path="/homes">
            {redirect ? <Redirect to="/agreements" /> : <SCSHomes />}
          </Route>
          <Route exact path="/knowledge">
            {redirect ? <Redirect to="/agreements" /> : <Knowledge />}
          </Route>
          <Route exact path="/knowledge/:articleId">
            {redirect ? <Redirect to="/agreements" /> : <ArticleWithRouter />}
          </Route>
          <Route exact path="/beta">
            {redirect ? <Redirect to="/agreements" /> : <Beta />}
          </Route>
          <Route exact path="/cases">
            {redirect ? <Redirect to="/agreements" /> : <Cases />}
          </Route>
          <Route exact path="/cases/:caseId">
            {redirect ? <Redirect to="/agreements" /> : <CaseDetail />}
          </Route>
          <Route exact path="/news">
            {redirect ? <Redirect to="/agreements" /> : <News />}
          </Route>
          <Route exact path="/news/:newsId">
            {redirect ? <Redirect to="/agreements" /> : <NewsDetailWithRouter />}
          </Route>
          <Route exact path="/events">
            {redirect ? <Redirect to="/agreements" /> : <Events />}
          </Route>
          <Route exact path="/events/:eventId">
            {redirect ? <Redirect to="/agreements" /> : <EventDetailWithRouter />}
          </Route>
          
          <Route exact path="/rma">
            {redirect ? <Redirect to="/agreements" /> : <RMAs />}
          </Route>
          <Route exact path="/account">
            {redirect ? <Redirect to="/agreements" /> : <AccountWithRouter />}
          </Route>
          <Route exact path="/orders/:orderId">
            {redirect ? <Redirect to="/agreements" /> : <OrderDetailWithRouter />}
          </Route>
          <Route exact path="/payment">
            {redirect ? <Redirect to="/agreements" /> : <MakeAPaymentWithRouter />}
          </Route>
          <Route exact path="/education/:contactId">
            {redirect ? <Redirect to="/agreements" /> : <EducationDetailWithRouter />}
          </Route>
          <Route exact path="/preferences">
            {redirect ? <Redirect to="/agreements" /> : <UserPreferences />}
          </Route>
          <Route exact path="/notifications">
            {redirect ? <Redirect to="/agreements" /> : <NotificationsWithRouter />}
          </Route>
          <Route exact path="/profiles">
            {redirect ? <Redirect to="/agreements" /> : <Profiles />}
          </Route>
          <Route exact path="/latestSoftware">
            {redirect ? <Redirect to="/agreements" /> : <LatestSoftware />}
          </Route>
          <Route exact path="/support">
            {redirect ? <Redirect to="/agreements" /> : <MySupport />}
          </Route>
          <Route exact path="/marketing/become-a-dealer">
            {redirect ? <Redirect to="/agreements" /> : <DirectDealerPromotion />}
          </Route>
          <Route exact path="/marketing">
            {redirect ? <Redirect to="/agreements" /> : <Sales />}
          </Route>
          <Route exact path="/marketing/:contentId">
            {redirect ? <Redirect to="/agreements" /> : <SpecialtySalesWithRouter />}
          </Route>
          <Route exact path="/services">
            {redirect ? <Redirect to="/agreements" /> : <Projects />}
          </Route>
          <Route exact path="/myprojects">
            {redirect ? <Redirect to="/agreements" /> : <MyProjects />}
          </Route>
          <Route exact path="/cedia">
            {redirect ? <Redirect to="/agreements" /> : <Tradeshow />}
          </Route>
          <Route render={() => <div className="text-center" style={{paddingTop: 200}}>Page not found</div>} />
          </Switch>
          {/* WIP */}
        </ContentWrapper>
        <Footer />
        
      </AppWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    agreements: state.agreements,
    knowledge: state.knowledge,
    auth: state.server.authError,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
  fetchAgreements,
  fetchArticles,
})(App);

import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import _ from "lodash";
import ReactGA from 'react-ga';
import {SavFlexBetween} from "../../components/Styled";

const ModalBody = styled.div`
  display: grid;
  grid-template-columns: 30% auto;
`;
const SideNavContainer = styled.div`
  box-shadow: 1px 5px 7px 3px rgba(20, 20, 20, 0.27);
  min-height: 600px;
  max-height: 100%;
  overflow-y: scroll;
`;
const SelectedContainer = styled.div`
  margin: 0px 40px 40px;
`;
const SelectedHeader = styled.div`
  font-family: 'Gotham-Medium';
  font-size: 36px;
  text-align: center;
  margin: 40px 0px 20px;
  text-transform: uppercase;
  color: #565551;
`;
const SelectedDesc = styled.div`
  font-size: 14px;
  margin: 10px 0px 0px;
`;
const SideNavItem = styled.div`
  font-family: 'Gotham-Medium';
  text-align: center;
  padding: 22px;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  text-transform: uppercase;
  & :hover {
    background-color: #f9f9f9;
  }
`;
const Close = styled.span`
  position: absolute;
  top: 10px;
  right: 16px;
  font-weight: 100;
  font-size: 40px;
  opacity: 0.2;
  color: #000;
  cursor: pointer;
`;
const active = {
  color: '#ff5f00',
  backgroundColor: '#f9f9f9',
};
const Tab = styled.div`
  font-family:"Gotham-Medium";
  color:#9b9b9b;
  text-transform:uppercase;
  font-size:14px;
  cursor:pointer
`;
const tabActive = {
  color:'#ff5f00'
}
const NoVideo = styled.div`
  font-size: 20px;
  text-align: center;
  margin: 190px 0px;
`;

const sideNavOpts = [
  {
    title: 'Community Dashboard Overview',
    url: 'https://player.vimeo.com/video/647554115?h=bf73cd6647',
    desc: [
      'Welcome to the new community.  Join us in this video to see the different parts to your new Community Dashboard.',
    ],
  },
  {
    title: 'Managing Your Settings',
    url: 'https://player.vimeo.com/video/647579277?h=bf8619dc2a',
    desc: [
      'Learn how to manage your personal community settings including Beta and notifications.',
    ],
  },
  {
    title: 'Managing Your Business/Account',
    url: 'https://player.vimeo.com/video/647569832?h=546c29bea5',
    desc: [
      'Take a tour of your accounts dashboard.  We will review how to view different aspects of the community as it pertains to your companies program status and global account settings.',
    ],
  },
  {
    title: 'Managing Your Team',
    url: 'https://player.vimeo.com/video/647585138?h=f9f089c01b',
    desc: [
      'In this video we will review how to manage your employees community access, Savant Cloud Management access and educational status.',
    ],
  },
  {
    title: 'Managing Your Orders',
    url: 'https://player.vimeo.com/video/647563442?h=675916957c',
    desc: [
      'This video will review all aspects of product ordering, Billings, and Making payments.  We will also review how you will be able to view warranty status, RMA’s that are in process and getting account statements.',
    ],
  },
  {
    title: 'Getting More Help',
    url: 'https://player.vimeo.com/video/647492542?h=8c4455a6fa',
    desc: [
      'This video will review how to find out who to reach out to for your Savant team, from Sales to support.',
    ],
  },
  {
    title: 'Savant Scheduler',
    url: 'https://player.vimeo.com/video/651806903?h=f6ad4e99bb',
    desc: ['Checkout the great new self service scheduling tool, that allows you to quickly and efficiently schedule virtual or in-person tours and design engagements.'],
  },
  {
    title: 'Signup for Beta',
    url: 'https://player.vimeo.com/video/651788527?h=1bba26cedf',
    desc: ['This video will walk you through the quick and easy steps to signup for the Savant Beta Software Program.'],
  },
];

const HelpModal = (props) => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(sideNavOpts[0]);
  const [tab, setTab] = useState();

  useEffect(() => {
    if (selectedItem.tabs) {
      setTab(selectedItem.tabs[0].title);
    }
  },[selectedItem]);

  useEffect(() => {
    if (show){
      ReactGA.event({
        category: 'Help',
        action: 'Help & Training View'
      });
    }
  },[show]);

  const renderItems = () => {
    return sideNavOpts.map((o, idx) => {
      return (
        <SideNavItem
          onClick={() => setSelectedItem(o)}
          style={selectedItem.title === o.title ? active : null}
          key={idx}
        >
          {o.title}
        </SideNavItem>
      );
    });
  };

  const renderVideo = (objIn) => {
    if (objIn.url) {
      return (
        <iframe
            width="100%"
            height="375px"
            src={objIn.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="video"
          ></iframe>
      );
    };
    return (
      <NoVideo>Video coming soon!</NoVideo>
    );
  };

  const renderDocs = (objIn) => {
    if (objIn.docs) {
      return objIn.docs.map((d, idx) => {
        return (
          <div key={idx}>
            <a className="med oj text-uppercase onHov" href={d.url} target="_blank">{d.title}</a>
          </div>
        )
      })
    }
    return null;
  }  

  const renderTabContent = () => {
    const tabObj = _.find(selectedItem.tabs, (t) => t.title === tab);
    if (tabObj) {
      return (
        <Fragment>
          {renderVideo(tabObj)}
          {tabObj.desc.map((d, idx) => {
            return (
              <SelectedDesc key={idx}>{d}</SelectedDesc>
            )
          })}
        </Fragment>
      )
    }
    return null;
  }

  const renderContent = () => {
    if (selectedItem.tabs) {
      return (
        <Fragment>
          <SavFlexBetween style={{width:'40%', margin:'0px auto 20px'}}>
            {selectedItem.tabs.map((t, idx) => {
              return (
                <Tab key={idx} style={tab=== t.title ? tabActive : null} onClick={() => setTab(t.title)}>{t.title}</Tab> 
              )
            })}
          </SavFlexBetween>
          {renderTabContent()}
        </Fragment>
      );
    };
    
    return (
      <Fragment>
        {renderVideo(selectedItem)}
        {selectedItem.desc.map((d, idx) => {
            return (
              <SelectedDesc key={idx}>{d}</SelectedDesc>
            )
          })}
        {renderDocs(selectedItem)}
      </Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="book" onClick={() => setShow(true)}>Help & Training</div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        className="savModal help"
        size="lg"
      >
        <Close onClick={() => setShow(false)}>&times;</Close>
        <ModalBody>
          <SideNavContainer>{renderItems()}</SideNavContainer>
          <SelectedContainer>
            <SelectedHeader>{selectedItem.title}</SelectedHeader>
            {renderContent()}
          </SelectedContainer>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );

}

export default HelpModal;
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Nav,
} from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import {AiOutlineDollarCircle} from 'react-icons/ai';
import ReactTooltip from "react-tooltip";
import {
  fetchContacts,
  updateContact,
  fetchUser,
  setSuccessToast
} from "../../../actions";
import {createLoadingSelector, visibilitySelector} from "../../../selectors";
import { SubHeader, SavRow, SavSearch } from "../../../components/Styled";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import SavAlphabetFilter from "../../../components/SavAlphabetFilter";
import SavDrop from "../../../components/SavDrop";
import CreateNewContact from "./CreateNewContact";
import EditContactModal from "./EditContactModal";
import Education from "./Education";
import SavToast from "../../../components/SavToast";
import SavToastSuccess from "../../../components/SavToastSuccess";
import SavToastFail from "../../../components/SavToastFail";
import SavSearchB from "../../../components/SavSearch";
import SavModalWarning from "../../../components/SavModalWarning";
import {FaCaretDown} from 'react-icons/fa';

const tabActive = {
  color: "#ff5f00"
};
const hidden = {
  display: 'none!important'
};
const SavHelpDiv = styled.div`
  text-align: end;
  font-size: 14px;
  text-decoration: underline
  cursor: pointer
  &:hover {
    color: #ff5f00 !important;
  }
`;

const FilterOpts = ["All", "Active", "Pending", "Inactive"];

const DefaultPill = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #fafaf9;
  color:#9b9b9b;
  border: 1px solid #9b9b9b;
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 80px;
  text-align:center;
  text-transform:uppercase;
  position: relative;
`;
const ActivePill = styled(DefaultPill)`
  background-color:#4cd964;
  color:#fff;
  border:1px solid #4cd964;
  cursor:pointer;
`;
const PendingPill = styled(DefaultPill)`
  background-color:#ffcc5f;
  color:#fff;
  border:1px solid #ffcc5f;
  cursor:default;
`;
const InactivePill = styled(DefaultPill)`
  background-color:#d0021b;
  color:#fff;
  border:1px solid #d0021b;
  cursor:pointer;
`;
const PurchasePill = styled(DefaultPill)`
  background-color:#fff;
  color:#4cd964;
  border:1px solid #4cd964;
  margin-top: 3px;
  position:relative;
`;
const purchasingIcon = {
  fill: '#4cd964',
  position:'absolute',
  top:'-10px',
  right: '-10px',
  backgroundColor: '#fff',
  cursor: 'help'
}

const Contacts = props => {
  const {
    contacts,
    updateContact,
    loading,
    fetchUser,
    education,
    user,
    location
  } = props;

  let param = location.search;

  const [contactList, setContactList] = useState([]);
  const [contactFilter, setContactFilter] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [alphabetChar, setAlphabetChar] = useState("All");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState("Active");
  const [isloading, setIsLoading] = useState(loading);
  const [isElectrical, setIsElectrical] = useState(false);

  const [showDeactivateContact, setShowDeactivateContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [activeContact, setActiveContact] = useState(false);

  const [showPermissionHelpModal, setShowPermissionHelpModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [showSameAccModal, setShowSameAccModal] = useState(false);
  const [existingContact, setExistingContact] = useState(null);

  useEffect(() => {
    document.title = "Savant Community | Contacts";
  }, []);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setContactList(contacts);
    if (searchString !== "") {
      setSearchString("");
    }
  }, [contacts]);

  useEffect(() => {
    if (contactList) {
      let index = 0;
      const tempContacts = [];
      for (index = 0; index < contactList.length; index += pageSize) {
        let chunk = contactList.slice(index, index + pageSize);
        tempContacts.push(chunk);
      }
      setContactFilter(tempContacts);
    }
  }, [contactList, pageSize]);


  useEffect(() => {
    if (!_.isEmpty(contacts)) {
      setCurrentPage(1);
      console.log('here')

      if (activeFilter !== 'All' && alphabetChar !== 'All'){
        let tempContacts = contacts.filter(contact =>
          SavSearchB(contact, ["name"], searchString) &&
          contact.status === activeFilter &&
          contact.lastName.toLowerCase().startsWith(alphabetChar)
        );
        setContactList(tempContacts);
      }
      if (activeFilter === 'All' && alphabetChar !== 'All'){
        let tempContacts = contacts.filter(contact =>
          SavSearchB(contact, ["name"], searchString) &&
          contact.lastName.toLowerCase().startsWith(alphabetChar)
        );
        setContactList(tempContacts);
      }
      if (activeFilter !== 'All' && alphabetChar === 'All'){
        let tempContacts = contacts.filter(contact =>
          SavSearchB(contact, ["name"], searchString) &&
          contact.status === activeFilter
        );
        setContactList(tempContacts);
      }
      if (activeFilter === 'All' && alphabetChar === 'All'){
        let tempContacts = contacts.filter(contact =>
          SavSearchB(contact, ["name"], searchString)
        );
        setContactList(tempContacts);
      }
    }

  },[searchString, activeFilter, alphabetChar, contacts]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (
        (user.contact.accType === 'Non-Direct' && user.contact.accSubType === 'Electrical Contractor') ||
        (user.contact.accType === 'Contractor' && user.contact.accSubType === 'Electrical/Solar')
        ){
        setIsElectrical(true);
      }
    }
  },[user]);

  const getContactById = (contactId) => {
    const temp = _.find(contacts, (c) => c.sfid === contactId);
    return temp;
  }

  const handleActivateContact = contact => {
    setActiveContact(contact);
    setShowEditModal(true)
  };

  const handleDeactivateContact = () => {
    const contactJSON = { ...selectedContact };
    contactJSON.communityAccess = false;
    updateContact(contactJSON);
    setShowDeactivateContact(false);
  };

  const handleClearFilters = () => {
    setAlphabetChar("All");
    setSearchString("");
  };

  const renderLoader = () => {
    if (isloading) {
      return (
        <div className="loaderDiv">
          <div className="loader"></div>
        </div>
      );
    }
  };

  const renderModalText = () => {
    return (
      <div>
        <div>
          <div className="med">General Permissions:</div>
          <div className="book">
            Contacts will have access to view and manage (where applicable)
            product documentation, technical support, Savant University,
            Savant's Beta program, the Savant Store and Design Tools (without
            pricing), and user settings such as notifications and email
            preferences by default. Any permissions awarded below would be
            additive.
          </div>
        </div>
        <br />
        <div>
          <div className="med">Admin:</div>
          <div className="book">
            Most permissioned setting. Contacts with this permission will have
            access to view and manage (where applicable) sales volumes and
            trends, accounting information such as credit terms and VIR payouts,
            pricing, team management, and account settings such as savant.com
            listings and Savant Central Management settings.
          </div>
        </div>
        <br />
        <div>
          <div className="med">Billing:</div>
          <div className="book">
            Contacts with this setting will have the ability to view invoices,
            pay invoices, run account statements, update credit card
            information.
          </div>
        </div>
        <br />
        <div>
          <div className="med">Team:</div>
          <div className="book">
            Contacts with this setting will have the ability to manage employee
            set up, employee deactivation, employee reactivation, as well as the
            ability to enroll members of the team in Savant University courses.
          </div>
        </div>
        <br />
        <div>
          <div className="med">Store:</div>
          <div className="book">
            Contacts with this setting will have the ability to see pricing in
            the Savant Store and Design Tools, to checkout Carts in the Savant
            Store, and to view and manage order default settings such as
            shipping address and ship methods.
          </div>
        </div>
      </div>
    );
  };

  const renderPerm = ({accountAdmin, ordersInvoices, contactManagement, storePricing}) => {
    if (accountAdmin && ordersInvoices && contactManagement && storePricing){
      return <DefaultPill>ADMIN</DefaultPill>
    }
    if (!accountAdmin && ordersInvoices && contactManagement && storePricing){
      return <DefaultPill>BILLING</DefaultPill>
    }
    if (!accountAdmin && !ordersInvoices && contactManagement && storePricing){
      return <DefaultPill>STORE</DefaultPill>
    }
    if (!accountAdmin && !ordersInvoices && !contactManagement && storePricing){
      return <DefaultPill>STORE</DefaultPill>
    }
    if (!accountAdmin && !ordersInvoices && contactManagement && !storePricing){
      return <DefaultPill>TEAM</DefaultPill>
    }
    if (!accountAdmin && !ordersInvoices && !contactManagement && !storePricing){
      return <DefaultPill>DEFAULT</DefaultPill>
    }
    return null;
  };
  const renderPurchasing = ({scmPurchasing}) => {
    if (scmPurchasing){
      return <AiOutlineDollarCircle size={20} style={purchasingIcon} data-tip="Subscription Management enabled" />
    }
    return null;
  }

  const renderStatusDrop = (contact) => {
    const renderPill = () => {
      if (contact.status === 'Active'){
        return (
          <ActivePill>
            Active <FaCaretDown style={{fill:'#fff', marginLeft:5}} />
          </ActivePill>
        )
      }
      if (contact.status === 'Pending'){
        return (
          <PendingPill>
            Pending <FaCaretDown style={{fill:'#fff', marginLeft:5}} />
          </PendingPill>
        )
      }
      if (contact.status === 'Inactive'){
        return (
          <InactivePill>
            Inactive <FaCaretDown style={{fill:'#fff', marginLeft:5}} />
          </InactivePill>
        )
      }
      return null;
    }
    if (contact.status !== "Pending"){
      return (
        <Dropdown className="savDrop">
          <Dropdown.Toggle as="div">
            {renderPill()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as="div"
              className="f10"
              onClick={() => {
                contact.status === "Inactive"
                  ? handleActivateContact(contact)
                  : setShowDeactivateContact(true);
                setSelectedContact(contact);
              }}
            >
              <span>
                {contact.status === "Inactive"
                  ? "Reactivate"
                  : "Deactivate"}
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )
    }

    return (
      <PendingPill>
        Pending 
      </PendingPill>
    )
    
  };

  const renderContacts = () => {
    if (!_.isEmpty(contactFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > contactFilter.length) {
        page = 0;
        setCurrentPage(1);
      }

      return contactFilter[page].map(contact => {
        return (
          <SavRow key={contact.sfid} className="vertical-align" style={{padding:'20px 0px'}}>
            <Col
              md={3}
              xs={5}
              className="med truncate u onHov"
              onClick={() => {setActiveContact(contact);setShowEditModal(true)}}
            >
              {contact.name}
            </Col>
            <Col md={3} className="truncate d-none d-md-block">
              {contact.email}
            </Col>
            <Col md={isElectrical ? 3 : 2} xs={3}>
              {renderStatusDrop(contact)}
            </Col>
            <Col md={isElectrical ? 3 : 2} xs={4}>
              {renderPerm(contact.permissions)}
            </Col>
            <Col md={2} className={!isElectrical ? "d-none d-md-block" : "d-none"}>
              <ReactTooltip html={true} className="tooltip-issuetype" />
              <DefaultPill>
                {contact.scmAccess}
                {renderPurchasing(contact.permissions)}
              </DefaultPill>
            </Col>
          </SavRow>
        );
      });
    }

    if (contactList) {
      return (
        <Row>
          <Col
            xs={12}
            style={
              contactList.length > 0
                ? { display: "none" }
                : { textAlign: "center", marginTop: 30 }
            }
          >
            No Results found.
            <br />
            <br />
            <button
              className="sav-btn__orange--text"
              onClick={() => handleClearFilters()}
            >
              Clear Filters
            </button>
          </Col>
        </Row>
      );
    }
  };

  const renderSubPage = () => {
    if (param === '?tab=personnel'){
      return (
        <Fragment>
          <div className="text-center pad8">
            <CreateNewContact
              activeContact={activeContact}
              selectedContact={selectedContact}
              showExistingModal={showExistingModal}
              setShowExistingModal={setShowExistingModal}
              setShowSameAccModal={setShowSameAccModal}
              setExistingContact={setExistingContact}
              isElectrical={isElectrical}
            />
            <EditContactModal
              activeContact={activeContact}
              show={showEditModal}
              setShow={setShowEditModal}
              isElectrical={isElectrical}
            />
            <SavModalWarning
              show={showDeactivateContact}
              hide={() => setShowDeactivateContact(false)}
              title="WARNING"
              text2="Deactivating the Contact will remove all permissions currently enabled. Once deactivated, if necessary at a later date, contacts can be re-enabled from the 'Inactive' Filter."
              buttonText="Continue"
              center
              click={handleDeactivateContact}
            />
            <SavModalWarning
              show={showExistingModal}
              hide={() => setShowExistingModal(false)}
              title="Email already in use"
              text2="This email is already in use in the Savant Community. This contact's status will be PENDING until we contact you for further direction. "
              buttonText="Okay"
              center
              click={!setShowExistingModal}
            />
            <SavModalWarning
              show={showSameAccModal}
              hide={() => setShowSameAccModal(false)}
              title="Email already in use"
              text2={`This email is already in use under this account by ${existingContact ? getContactById(existingContact).name : null}. This contact's status will be PENDING until we contact you for further direction.`}
              buttonText="Okay"
              center
              click={() => setShowSameAccModal(false)}
            />
            <SavModalWarning
              show={showPermissionHelpModal}
              hide={() => setShowPermissionHelpModal(false)}
              title="Understanding Contact Permissions"
              text2={renderModalText()}
              buttonText="Close"
              click={!setShowPermissionHelpModal}
              size="lg"
            />
          </div>

          <Row className="vertical-align">
            <Col md={8} className="d-none d-md-block">
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
            </Col>
            <Col sm={12} md={4} className="d-flex justify-content-lg-end justify-content-center mt-3 mt-md-0 ">
              <div className="d-flex align-items-center" style={{width:250}}>
                <div className="light-gray f12 med" style={{marginRight:5}}>FILTER</div>
                <SavDrop
                  opts={FilterOpts}
                  val={activeFilter}
                  click={setActiveFilter}
                  style={{
                    border: "1px solid #b4b4b4",
                    backgroundColor: "#fff",
                    width:200
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className='vertical-align m-t-20'>
            <Col md={6} className="d-none d-md-block">
              <SavAlphabetFilter
                click={setAlphabetChar}
                activeChar={alphabetChar}
              />
            </Col>
            <Col md={6} className="d-none d-md-block">
              <SavHelpDiv
                onClick={() => setShowPermissionHelpModal(true)}
              >
                Understanding Contact Permissions
              </SavHelpDiv>
            </Col>
          </Row>

          <hr />

          <div className="m-t-10 m-b-10">
            <SavColumnSort
              data={contactList}
              setData={setContactList}
              columns={! isElectrical ? [
                { text: "Name", size: 3, sizeXS: 5, value: "name" },
                { text: "Email", size: 3, hideMobile:true, value: "email" },
                { text: "Status", size: 2, sizeXS:3, value: "status" },
                {
                  text: "Max Role",
                  size: 2,
                  sizeXS:4,
                  value: null,
                  predicateList: ["permissions.accountAdmin", "permissions.ordersInvoices", "permissions.storePricing", "permissions.contactManagement"]
                },
                { 
                  text: "SCM Access", 
                  size: 2,
                  hideMobile:true,
                  value: "scmAccess"
                },
              ] : 
              [
                { text: "Name", size: 3, sizeXS: 5, value: "name" },
                { text: "Email", size: 3, hideMobile:true, value: "email" },
                { text: "Status", size: 3, sizeXS:3, value: "status" },
                {
                  text: "Max Role",
                  size: 3,
                  sizeXS:4,
                  value: null,
                  predicateList: ["permissions.accountAdmin", "permissions.ordersInvoices", "permissions.storePricing", "permissions.contactManagement"]
                }
              ]}
            />
          </div>
          
          {renderContacts()}
          <SavPagination
            data={contactFilter}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            dataLength={contactList.length}
          />
        </Fragment>
      )
    }
    if (param === '?tab=education'){
      return <Education education={education} user={user} />
    }
  }
  
  if (contactList && contacts && contactList) {
    return (
      <div>
        {renderLoader()}
        <SavToast />
        <SavToastSuccess message={param === "?tab=personnel" ? 'CREATE_CONTACT_SUCCESS' : 'CREATE_NOTIFICATION_SUCCESS'} />
        <SavToastFail />
        <SubHeader>Contacts</SubHeader>

        <Nav className="justify-content-center">
          <Nav.Item>
            <NavLink
              className="tab-link-overview"
              style={param === "?tab=personnel" ? tabActive : null}
              to={`/account?tab=personnel#contacts`}
            >
              Personnel
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              className="tab-link-overview"
              style={param === "?tab=education" ? tabActive : null}
              to={`/account?tab=education#contacts`}
            >
              Education
            </NavLink>
          </Nav.Item>
        </Nav>
        <Container>
          {renderSubPage()}
        </Container>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => {
  return {
    contacts: state.contacts.data,
    // loading: loadingSelector(state)
  };
};

export default connect(mapStateToProps, {
  fetchContacts,
  updateContact,
  fetchUser,
  setSuccessToast
})(Contacts);


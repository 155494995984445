import React, { useState, useEffect, useRef, Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { Container, Row, Col, Image, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import {fetchContent, fetchAccount, fetchAddresses, createForm} from "../../actions";
import { HashLink } from 'react-router-hash-link';
import {Link} from "react-router-dom";
import useSticky from "../../hooks/useSticky";
import {parseDateInt} from "../../components/Utilities";
import SavCheck from "../../components/SavCheck";
import SavFormInput from "../../components/SavFormInput";
import SavFormInputArea from "../../components/SavFormInputArea";
import SavFormDrop from "../../components/SavFormDrop";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {createLoadingSelector} from "../../selectors";

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
`;
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderTextOverlay = styled.div`
  position:absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display:flex;
  height: 100%;
  align-items:center;
  justify-content:center;
  padding: 10px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Line = styled.div`
  border-left: 2px solid #b4b4b4;
  height: 100px;
  width: 2px;
  margin:0 auto;
`;
const PageNav = styled.div`
  font-size: 14px;
  background: linear-gradient(180deg,#EEEFED 0%,#DCDCDC 100%);
  display:flex;
  align-items:center;
  justify-content: space-between;
  z-index: 1;
  width: 100%
  min-height: 50px;
`;
const PageNavItem = styled.div`
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  > a:hover {
    color:#ff5f00;
    cursor: pointer;
  }
  &:hover{
    color:#ff5f00;
    cursor: pointer;
  }
`;
const SavModalClose = styled.div`
  font-size: 36px;
  padding-top: 15px;
  padding-right: 15px;
  text-align: right;
  color: #979797;
  line-height: 0.5;
  cursor: pointer;
  position: absolute;
  font-family: "Gotham-Light";
  right: 0;
`;
const SectionHeader = styled.div`
  font-size:14px;
  font-family:"Gotham-Medium";
  margin: 10px 0px;
`;
const selectedStyle = {
  height: 100,
  border: '2px solid #ff5f00',
  borderRadius: 4,
}
const DateStyle = styled.div`
  padding: .375rem .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  max-height: 38px;
`;
const createMarkup = richText => {
  if (richText) {
    return { __html: richText};
  }
};
const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});
const SavDateForm = React.forwardRef((props, ref) => {
  return (
    <DateStyle >
      <DatePicker
        onChange={props.onChange}
        value={props.value}
        format="y-MM-dd"
        minDate={new Date()}
      />
    </DateStyle>
  )
});
const SpecialtySalesDetail = ({content, fetchContent, account, fetchAccount, match, location, user, addresses, fetchAddresses, createForm, loadingForm}) => {

  const [page, setPage] = useState('specialty-sales-juniper');
  const [contentId, setContentId] = useState(match.params.contentId);
  const [showUSAIModal, setShowUSAIModal] = useState(false);
  const { isSticky, element } = useSticky();
  const stickyRef = useRef(null);
  const [projectName, setProjectName] = useState('');
  const [projectDate, setProjectDate] = useState('');
  const [projectLocation, setProjectLocation] = useState('');
  const [projectCity, setProjectCity] = useState('');
  const [projectState, setProjectState] = useState('');
  const [projectStreet, setProjectStreet] = useState('');
  const [lightingDesigner, setLightingDesigner] = useState('');
  const [requestedFixtures, setRequestedFixtures] = useState('');
  const [otherText, setOthertext] = useState("");
  const [validated, setValidated] = useState(false);
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [step, setStep] = useState(1);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    fetchContent();
    fetchAccount();
    fetchAddresses();
  },[]);

  useEffect(() => {
    if (showUSAIModal){
      setStep(1);
    }
  },[showUSAIModal])

  const renderPage = () => {
    const pageFound = _.find(content, (c) => c.page === contentId);

    const renderSteps = () => {
      return pageFound.contentBlocks.map((b, idx) => {
        return (
          <div className="m-t-10" ref={element} key={b.sfid}>
            <Line />
            <div className="text-center f16 med p-t-10 p-b-10" id={b.sfid}>
              {`${b.sequence} - ${b.title}`}
            </div>
            <Line />
            <Container>
              <Row>
                <Col sm={idx % 2 === 0 ? { span: 6, order: 'first'} : { span: 6, order: 'last'}}>
                  <Image src={b.image} width="100%" />
                </Col>
                <Col sm={6}>
                  <div dangerouslySetInnerHTML={createMarkup(b.content)} />
                </Col>
              </Row>
            </Container>
            <div className="text-center m-t-10">
              {idx === 2 ? renderUSAI() : null}
            </div>
            <div className="text-center m-t-10">
              {idx === 1 ? renderKlus() : null}
            </div>
          </div>
        )
      })
    };
    const renderBlackNova = () => {

      if (pageFound.page === 'specialty-sales-black-nova' && !_.isEmpty(account) && !_.isEmpty(user) && !_.isEmpty(addresses)){

        const addrFound = _.find(addresses, (a) => account.defaultBillAddr === a.sfid);
        let uri = ''

        if (addrFound){
          uri = `https://share-eu1.hsforms.com/1jjqsspONQWKBXNY04_vMqQfipc9?name=${account.name}&savant_uid=${account.sfid}&firstname=${user.contact.firstName}&lastname=${user.contact.lastName}&email=${user.contact.email}&phone=${account.phone}&address=${addrFound.addressStreet}&city=${addrFound.addressCity}&state=${addrFound.addressState}&zip=${addrFound.addressZip}`;
          
        } else {
          uri = `https://share-eu1.hsforms.com/1jjqsspONQWKBXNY04_vMqQfipc9?name=${account.name}&savant_uid=${account.sfid}&firstname=${user.contact.firstName}&lastname=${user.contact.lastName}&email=${user.contact.email}&phone=${account.phone}`;
        }
        const encoded = encodeURI(uri)
        if (!account.programs.includes('Black Nova')){
          return (
            <a href={encoded} target="_blank" rel="noreferrer">
              <button className="sav-btn__orange--fill" style={{marginRight: 10}}>Register Now</button>
            </a>
          )
        }
      }
      return null;
      
    }
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }
      
      const formObject = {
        projectName: projectName,
        type: 'Fixture Request',
        projOtherDetail: 'Form submitted via '+ pageFound.page + '\n' + otherText,
        projectCity: projectCity,
        projectState: projectState,
        projectStreet: projectStreet,
        lightingDesigner: lightingDesigner,
        requestedFixtures: requestedFixtures,
        estStartDate: parseDateInt(projectDate) === "Invalid Date" ? null : moment(parseDateInt(projectDate)).format('M/DD/YYYY'),
        contactEmail: contactEmail,
        contactPhone: contactPhone,
      }
      createForm(formObject).then((val) => {
        if (val.data.success){
          setErrorText(null);
          setStep(2);
        } else {
          if (val.data.errors.length > 0){
            if (val.data.errors[0].includes('INVALID_EMAIL_ADDRESS')){
              setErrorText("Invalid Email Address")
            } else {
              setErrorText("Something went wrong, please try again.")
            }
            
          } else {
            setErrorText("Something went wrong, please try again.")
          }
        }
        
      })
      return;
    }

    const renderContact = () => {
      if (pageFound.page !== 'specialty-sales-black-nova' && pageFound.page !== 'specialty-sales-usai-custom' && pageFound.page !== 'specialty-sales-klus'){
        return(
          <Link to="/support">
            <button className="sav-btn__orange--fill">Contact Us</button>
          </Link>
        )
      }
      return null;
    }
    const renderUSAI = () => {
      if (pageFound.page === 'specialty-sales-usai-custom'){
        return (
          <button className="sav-btn__orange--fill" onClick={() => setShowUSAIModal(true)}>USAI Custom Quote</button>
        )
      }
      return null;
    };
    const renderKlus = () => {
      if (pageFound.page === 'specialty-sales-klus'){
        return (
          <button className="sav-btn__orange--fill" onClick={() => setShowUSAIModal(true)}>Klus Custom Quote</button>
        )
      }
      return null;
    };

    const renderStep = () => {
      if (loadingForm){
        return (
          <div className="savFlexBetweenCenter" style={{height: 400}}>
            <div className="loader" />
          </div>
        )
      }
      if (step === 1 && !loadingForm){
        return (
          <Fragment>
            <SectionHeader>Project Information</SectionHeader>
              <Form.Row>
                <Form.Group as={Col} lg={6} md={12}>
                  <SavFormInput value={projectName} label="Project Name" setValue={setProjectName}/>
                </Form.Group>
                <Form.Group as={Col} lg={6} md={12}>
                  <div className="input-container-date">
                    <Form.Control
                      as={SavDateForm}
                      onChange={setProjectDate}
                      value={projectDate}
                    />
                    <label className={projectDate}>Est. Install Date</label>
                  </div>
                  </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={contactPhone || ''} label="Contact Phone" setValue={setContactPhone} type="tel" />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={contactEmail || ''} label="Contact Email" setValue={setContactEmail} type="email" />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={lightingDesigner} setValue={setLightingDesigner} label="Lighting Designer" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={projectStreet} setValue={setProjectStreet} label="Project Street" />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={projectCity} setValue={setProjectCity} label="Project City" />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <SavFormInput value={projectState} setValue={setProjectState} label="Project State" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInputArea value={requestedFixtures} setValue={setRequestedFixtures} label="Requested Fixtures" notRequired  />
                  <Form.Text muted>
                    (example per line: Fixture ID - F1 (10) B3RDF-15X3-30KU-25.......)
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInputArea value={otherText} setValue={setOthertext} label="Additional Notes" notRequired  />
                </Form.Group>
              </Form.Row>
              <div className="m-t-20 text-center red">{errorText}</div>
          </Fragment>
        )
      }
      
      if (step === 2 && !loadingForm){
        return (
          <div>
            <div className="text-center f24 m-t-20 m-b-20">
              Thank you!
            </div>
            <div className="book text-center f14 m-t-20 m-b-20">
             A Savant Team Member will be in touch within the next 2 business days.<br />
            </div>
          </div>
        )
      }
      return null;
      
    }

    const renderUSAIModal = () => {
      return (
        <Modal show={showUSAIModal} onHide={() => setShowUSAIModal(false)} animation={false} centered size="xl">
          <Modal.Header closeButton style={{justifyContent: 'center'}}>
            {pageFound.page === 'specialty-sales-klus' ? 'Klus Custom Quote' : 'USAI Custom Quote'}
          </Modal.Header>
          <Form onSubmit={handleSubmit} noValidate validated={validated} className="sav-form">
          <Modal.Body>
            <Container style={{minHeight: 400}}>
              {renderStep()}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="sav-btn__orange--fill"
              type="submit"
              style={!loadingForm && step === 1 ? null : {display: 'none'}}
            >
              Submit Now
            </button>
            <button
              className="sav-btn__gray"
              type="button"
              style={step === 2 ? null : {display: 'none'}}
              onClick={() => setShowUSAIModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
          </Form>
        </Modal>
      )
    }

    if (pageFound){
      return (
        <div>
          {renderUSAIModal()}
          <HeroContainer>
            <Image src={pageFound.image || "https://sweb-img.s3.amazonaws.com/Carousel/New_USAI_Lighting_Carousel_Image_v4.png"} style={{width: '100%', objectFit: 'cover', maxHeight: 600}} />
            <HeroOverlay>
              <div className="book text-center">
                <div style={{width: '100%', margin: '0px auto 10px'}} className="wht med text-uppercase f24 m-t-10">
                  {pageFound.title}
                </div>
                <div style={{width: '100%', margin: '0px auto 10px'}} className="wht book text-uppercase f14 m-t-5">
                  {pageFound.subtitle}
                </div>
              </div>
            </HeroOverlay>
          </HeroContainer>

          <PageNav className={isSticky ? "nav-sticky" : null}>
            <Container fluid>
              <Row style={{justifyContent:'center', alignItems: 'center'}}>
                {pageFound.contentBlocks.map((b) => {
                  return (
                    <Col key={b.sfid}>
                      <PageNavItem>
                        <HashLink to={`#${b.sfid}`}>
                          {`${b.sequence}. ${b.title}`}
                        </HashLink>
                      </PageNavItem>
                    </Col>
                  )
                })}
                <Col style={{textAlign:'right', padding: 10}}>
                  {renderContact()}
                  {renderBlackNova()}
                  {renderUSAI()}
                  {renderKlus()}
                </Col>
              </Row>
            </Container>
          </PageNav>
          {renderSteps()}
        </div>
        
      )
    }
    return null;
  }

  if (!_.isEmpty(content)){
    return (
      <div>
        <Container fluid>
          <Row>
            <Col sm={12}>
              {renderPage()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Skeleton height={400} count={5} />
        </Col>
      </Row>
    </Container>
  )

  
};
const loadingForm = createLoadingSelector(["CREATE_FORM"]);

const mapStateToProps = state => {
  return {
    content: state.content.data,
    account: state.account.data,
    user: state.user.data,
    addresses: state.addresses.data,
    loadingForm: loadingForm(state),
  };
};
export default connect(mapStateToProps, {fetchContent, fetchAccount, fetchAddresses, createForm})(SpecialtySalesDetail);
import React, { useState, useEffect } from "react";
import {Redirect} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { save } from 'save-file'
import _ from "lodash";
import {AiOutlineDownload} from 'react-icons/ai'
import {fetchAgreements, updateAgreement, fetchUser, fetchDocument} from "../../actions";
import {createLoadingSelector} from "../../selectors";
import SavCheck from "../../components/SavCheck";

const AgreementWindow = styled.div`
  margin: 50px auto;
  height:500px;
  overflow-y:scroll;
  border: 4px inset lightgray;
`;

const disabled = {
  cursor:"not-allowed",
}

const Agreements = ({agreements, fetchAgreements, fetchDocument, updateAgreement, user, fetchUser, isUpdating}) => {

  const [hasRead, setHasRead] = useState(false);

  useEffect(() => {
    if (_.isEmpty(agreements)){
      fetchAgreements();
    }
  },[agreements]);

  useEffect(() => {
    if(_.isEmpty(user)){
      fetchUser()
    }
  },[user]);

  const createMarkup = richText => {
    if (richText) {
      return { __html: richText };
    }
  };

  const handleDownload = () => {
    fetchDocument(agreements.agreement.sfid, 'agreement').then((res) => {
      if(res.success){
        const data = res.payload;
        save(data, `${agreements.agreement.title}.pdf`);
      }
    });
  };

  const handleAccept = () => {
    if(hasRead){
      updateAgreement(agreements.agreement.sfid);
    }
    
  }

  if(isUpdating){
    return <div className="loader" style={{marginTop:200}}></div>;
  }

  if(!_.isEmpty(agreements) && !_.isEmpty(user)){
    if(user.needsAgreement){

      if (agreements.agreement !== null){
        return (
          <div>
            <Container>
              <h2>Important Legal Information</h2>
              <p>
                The documentation below outlines the complete set of legal agreements between your company and Savant Systems, Inc. Please thoroughly read the terms of all applicable paperwork and agree to each below.<br />
                <b><i>Note: You must be a legal representative of your company in order to accept these agreements.</i></b>
              </p>
              <p className="text-center">
                <button style={{width: 'unset'}} className="sav-btn__orange--text" onClick={() => handleDownload()}>
                  <AiOutlineDownload size={14} fill="#ff5f00" /> Download {agreements.agreement.title}
                </button>
              </p>
              <AgreementWindow id="agreement">
                <div style={{padding: '0px 20px'}} dangerouslySetInnerHTML={createMarkup(agreements.agreement.agreementText1 || "")}></div>
                <div style={{padding: '0px 20px'}} dangerouslySetInnerHTML={createMarkup(agreements.agreement.agreementText2 || "")}></div>
                <div style={{paddingBottom: 20, textAlign: 'center'}}>
                  I have read and agree to the agreement above.<br />
                  <SavCheck checked={hasRead} click={() => setHasRead(!hasRead)} center />
                </div>
              </AgreementWindow>
              <div className="book f12 light-gray text-center m-b-10" style={hasRead? {display: 'none'} : null}>
                You must scroll to the end of the agreement above to accept & continue.
              </div>
              <Row>
                <Col sm={12} className="text-center">
                  <button className={hasRead ? "sav-btn__orange--fill" : "sav-btn__gray--fill"} style={!hasRead ? disabled : null} onClick={() => handleAccept()}>Accept & Continue</button>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }else{
        return (
          <div>
            <Container>
              <Row style={{marginTop:400}}>
                <Col sm={12} className="text-center">
                  {agreements.message}
                </Col>
              </Row>
            </Container>
          </div>
        )
      }
      
    }else{
      return <Redirect to="/" />
    }
  }
  return null;
};


const loadingSelector = createLoadingSelector(["UPDATE_AGREEMENT"]);

const mapStateToProps = state => {
  return {
    agreements: state.agreements.data,
    user: state.user.data,
    isUpdating: loadingSelector(state)
  };
};
export default connect(mapStateToProps, {fetchAgreements, updateAgreement, fetchUser, fetchDocument})(Agreements);
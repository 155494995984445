import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Dropdown, Form, Modal } from "react-bootstrap";
import { save } from 'save-file'
import _ from "lodash";
import {
  SubHeader,
  SavLabel,
  SavOutputField,
  SavInput,
} from "../../../components/Styled";
import SavDropFilter from "../../../components/SavDropFilter";
import states from "../../../components/Utilities/states.json";
import "react-calendar/dist/Calendar.css";
import DatePicker from 'react-date-picker';

const DateStyle = styled.div`
padding: .375rem .75rem;
border: 1px solid #ced4da;
border-radius: .25rem;
max-height: 38px;
`;
const invalidStyle = {
  width:"100%",
  marginTop:".25rem",
  fontSize:"80%",
  color:"#dc3545"
}
const hidden = {
  display:'none'
}

const SavDateForm = React.forwardRef((props, ref) => {
  return (
    <DateStyle>
      <DatePicker
        onChange={props.onChange}
        value={props.value}
        format="y-MM-dd"
        minDate={props.minDate? new Date() : null}
        required={props.required}
      />
    </DateStyle>

  )
})


const EditTaxCertModal = ({taxCert, updateTaxCert, show, hide, setSuccessToast}) => {

  const [taxNumber, setTaxNumber] = useState("");
  const [taxState, setTaxState] = useState("");
  const [expDate, setExpDate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileString, setFileString] = useState("");
  const [fileName, setFileName] = useState("");
  const [validated, setValidated] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    if (taxCert) {
      setTaxNumber(taxCert.documentProperties.exemptNumber);
      setTaxState(taxCert.documentProperties.state);
      if (taxCert.documentProperties.expirationDate){
        setExpDate(new Date(taxCert.documentProperties.expirationDate));
      }
      if (taxCert.documentProperties.effectiveDate){
        setEffectiveDate(new Date(taxCert.documentProperties.effectiveDate));
      }
      if (taxCert.documentProperties.attachmentName){
        setFileName(taxCert.documentProperties.attachmentName);
      }
    }
    if (!taxCert.contentType){
      setFileToUpload(null);
      setFileString('');
      setFileName('');
    }

  },[taxCert])

  const handleSave = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    let newDate;
    let newDateEffective;

    if (expDate !== "" && expDate){
      const offset = expDate.getTimezoneOffset();
      const newDateOffset = new Date(expDate.getTime() - (offset*60*1000));
      newDate = newDateOffset.toISOString().split('T')[0];
    }
    if (effectiveDate !== "" && effectiveDate){
      const offset = effectiveDate.getTimezoneOffset();
      const newDateOffset = new Date(effectiveDate.getTime() - (offset*60*1000));
      newDateEffective = newDateOffset.toISOString().split('T')[0];
    }

    const taxCertJSON = taxCert;
    taxCertJSON.documentProperties.exemptNumber = taxNumber;
    taxCertJSON.documentProperties.state = taxState;
    taxCertJSON.documentProperties.expirationDate = newDate;
    taxCertJSON.documentProperties.effectiveDate = newDateEffective;
    taxCertJSON.contentBody = fileString;
    if (fileToUpload){
      taxCertJSON.contentType = fileToUpload.type;
    }
    hide();
    updateTaxCert(taxCertJSON).then(() => {
      setSuccessToast(true);
    });
  }

  const onFileChange = (event) => {
    if (event.target.files[0].type !== "application/pdf"){
      setInvalidFile(true);
      return;
    }else {
      setInvalidFile(false);
      setFileToUpload(event.target.files[0]);
    }
  }

  useEffect(() => {
    if (fileToUpload) {
      handleUpload();
    }
  },[fileToUpload]);

  const handleUpload = () => {    
    const fileType = fileToUpload.type
    setFileName(fileToUpload.name);
    const blob = new Blob([fileToUpload], {type: `'${fileType}'`});
    var base64String;
    var reader = new FileReader(); 
    reader.readAsDataURL(blob); 
    reader.onloadend = function () { 
      base64String = reader.result; 
      const text = base64String.substr(base64String.indexOf(',') + 1);
      setFileString(text);
    } 
  }



  return (
    <Modal show={show} onHide={hide} size="lg" centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          Edit {taxCert.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form className="sav-form" onSubmit={handleSave} noValidate validated={validated}>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
              <Form.File 
                id="custom-file-translate-html"
                label={fileName}
                custom
                onChange={onFileChange}
                isInvalid={invalidFile}
                feedback="File must be a PDF"
              />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <div className="input-container">
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) => setTaxNumber(e.target.value)}
                    value={taxNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a Tax Number.
                  </Form.Control.Feedback>
                  <label className={taxNumber && 'filled'}>Tax Number</label>
                </div>
              </Form.Group>
            </Form.Row>
            
            <Form.Row>
              
              <Form.Group as={Col} xs={4}>
                <div className="input-container input-container-drop">
                  <SavDropFilter val={taxState} opts={states} click={setTaxState} clickKey="code">
                  </SavDropFilter>
                  <label className={taxState && 'filled'}>State</label>
                </div>
              </Form.Group>

              <Form.Group as={Col} xs={4}>
              <div className="input-container-date">
                <Form.Control
                  required={true}
                  as={SavDateForm}
                  onChange={setEffectiveDate}
                  value={effectiveDate}
                />
                <label className={effectiveDate}>Effective Date</label>
              </div>
              </Form.Group>

              <Form.Group as={Col} xs={4}>
              <div className="input-container-date">
                <Form.Control
                  as={SavDateForm}
                  onChange={setExpDate}
                  value={expDate}
                  required={false}
                  minDate
                />
                <label className={expDate}>Expiration</label>
              </div>
              </Form.Group>
            </Form.Row>
            
            <div className="m-t-20 text-center">
              <button className="sav-btn__orange--fill" type="submit">
                Update
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default EditTaxCertModal;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import { createLoadingSelector } from "../../selectors";
import n from "../../images/notification.svg";
import { fetchNotifications, updateNotification } from "../../actions";
import { parseDate } from "../Utilities";
import {
  FaFileInvoiceDollar,
  FaBriefcase,
  FaRegEnvelope
} from "react-icons/fa";
import {AiOutlineCloudSync} from 'react-icons/ai';
import {GoPackage} from 'react-icons/go'

const DropTriangle = styled.div`
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 7px solid #fff !important;
  border-bottom-color: #fff !important;
  position: absolute;
  top: -6px;
  right: 10px;
`;
const NotSpan = styled.span`
  position: absolute;
  top: -2px;
  right: -4px;
  z-index: 999;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  text-align: center;
  font-weight: 900;
  font-size: 12px;
  background-color: red;
`;
const NotSpanDrop = styled.span`
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  padding: 2px 5px;
  text-align: center;
  font-weight: 900;
  font-size: 12px;
  background-color: red;
`;
const NotHeader = styled.span`
  font-size: 14px;
  font-family: "Gotham-Medium";
  line-height: 1;
  margin-right: 10px;
`;
const NotHeaderMark = styled.div`
  font-size: 8px;
  font-family: "Gotham-Medium";
  text-decoration: underline;
  color: #979797;
  line-height: 1;
  margin-left: 20px;
`;
const NotMessage = styled.div`
  font-size: 10px;
  font-family: "Gotham-Medium";
  white-space: normal;
  text-transform: none;
  // overflow: hidden;
  // text-overflow: ellipsis;
`;

const DropDiv = styled.div`
  display:flex;
  justify-content: flex-start;
  align-items:center;
`;
const notDateRead = {
  color: "#979797",
  fontSize: 10,
  marginTop: 5,
};
const notDateUnread = {
  color: "#696057",
  fontSize: 10,
  marginTop: 5,
};
const notDropUnread = {
  backgroundColor: "#dbd9d6",
};
const none = { display: "none" };

const HeaderNotifications = (props) => {
  const { notifications, fetchNotifications, updateNotification, history, fetchingNotifications } = props;
  const [notsRead, setNotsRead] = useState(0);

  const handleUpdateNotification = (notification) => {
    if (!notification.isRead) {
      let notificationList = [];
      let notificationJSON = {
        isRead: true,
        sfid: notification.sfid,
      };
      notificationList.push(notificationJSON);
      updateNotification(notificationList);
      
    }
    if (notification.target){
      if (!notification.isExternal){
        history.push(notification.target);
      }else{
        window.location.href = notification.target;
      }
    } else {
      history.push('/notifications');
    }
  };

  const markAllRead = () => {
    let notificationList = [];
    notifications.data.forEach((n) => {
      if (!n.isRead) {
        let notificationJSON = {
          isRead: true,
          sfid: n.sfid,
        };
        notificationList.push(notificationJSON);
      }
    });
    updateNotification(notificationList);
  };

  const stripHtml = (stringIn) => {
    return stringIn.replace(/(<([^>]+)>)/gi, "");
  }

  useEffect(() => {
    if (_.isEmpty(notifications) && !fetchingNotifications){
      fetchNotifications();
    }
  },[notifications]);

  useEffect(() => {
    if (!_.isEmpty(notifications)) {
      let count = 0;
      notifications.data.forEach((n) => {
        if (!n.isRead) {
          count += 1;
        }
      });
      setNotsRead(count);
    }
  }, [notifications]);

  if (!_.isEmpty(notifications)){

    const renderIcon = (n) => {
      switch(n.type){
        case 'Order' :
          return <GoPackage size={25} />
        case 'Billing' : 
         return <FaFileInvoiceDollar size={25} />
        case 'Support' :
          return <FaBriefcase size={25} />
        case 'Subscription':
          return <AiOutlineCloudSync size={25} />
        default :
          return <FaRegEnvelope size={25} />
      }
    }

    const renderNotifications = () => {
      return notifications.data.slice(0, 6).map((n) => {
        const split = n.message.split('<br/>');
        let message = ''
        if (split.length === 1){
          message = `${split[0]}`;
        } else if (split.length > 2){
          message = `${split[0]} (+${split.length-2} more)`;
        } else {
          message = n.message;
        }
        return (
          <Dropdown.Item
            as="div"
            key={n.sfid}
            style={n.isRead ? null : notDropUnread}
            onClick={() => handleUpdateNotification(n)}
          >
            <DropDiv>
              <div style={{paddingRight:15}}>
                {renderIcon(n)}
              </div>
              
              <div>
                <NotMessage>
                  {stripHtml(message)}
                </NotMessage>
                <div
                  style={n.isRead ? notDateRead : notDateUnread}
                  className="dropDate"
                >
                  {parseDate(n.dateSent)}
                </div>
              </div>
            </DropDiv>
            
          </Dropdown.Item>
        );
      });
    };
    return (
      <Dropdown className="savHeaderDrop">
        <Dropdown.Toggle as="span">
          <img src={n} width="40px" className="onHov" alt="notifications" />
          <NotSpan style={notsRead === 0 ? none : null}>{notsRead}</NotSpan>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DropTriangle />

          <Dropdown.Item
            as="div"
            className="vertical-align dropHeader"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <NotHeader>NOTIFICATIONS</NotHeader>
              <NotSpanDrop style={notsRead === 0 ? none : null}>
                {notsRead}
              </NotSpanDrop>
            </div>

            <NotHeaderMark onClick={() => markAllRead()}>
              Mark All As Read
            </NotHeaderMark>
          </Dropdown.Item>

          {renderNotifications()}
          
            <Dropdown.Item
              as="div"
              className="text-center"
              style={{ backgroundColor: "#fff", padding: 6 }}
            >
              <Link to="/notifications" className="book f10 u">
                <div>
                See More
                </div>
                
              </Link>
            </Dropdown.Item>
          
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return null;
};

const fetchingNotifications = createLoadingSelector(["FETCH_NOTIFICATIONS"]);

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  fetchingNotifications: fetchingNotifications(state),
  user: state.user.data,
});

export default connect(mapStateToProps, {
  fetchNotifications,
  updateNotification,
})(HeaderNotifications);

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Nav, Row, Col } from "react-bootstrap";
import { useSelector } from 'react-redux';
import styled from "styled-components";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import Dashboard from './Dashboard';
import SavToast from '../../../components/SavToast';
import {visibilitySelector} from '../../../selectors';
import { SubHeader, ZeroStateText } from "../../../components/Styled";
import {createMarkup, parseCurrency} from "../../../components/Utilities";
import { ItemHeader, GridItem, DetailButton, DetailButtonDiv, DetailLabel, Congrats, AwardRowAmb, AmbassadorBadge, AmbassadorParent, AmbassadorOpacity, AmbassadorBottom, StatusRow, AmbassadorPercent, ProgramIconDiv, AmbCardBottomGrid } from "../styles";
import { fakeAmbassador, fakeIntegrator } from "./placeholders";
import Integrator from "./integrator";
import VIRPerformance from "./vir";
import NewDashboard from "./NewDashboard";
import Azione from "../../../images/Programs/Azione.png";
import Bravas from "../../../images/Programs/Bravas.png";
import Guild from "../../../images/Programs/Guild.png";
import HTA from "../../../images/Programs/HTA.png";
import ProSource from "../../../images/Programs/ProSource.png";
import DoneIcon from "../../../images/Orders/done.svg";
import WipIcon from "../../../images/Orders/wip.svg";
import ErrorIcon from "../../../images/Orders/error.svg";

import Council from "../../../images/Programs/council.png";
import Ambassador from "../../../images/AmbassadorBadges/Ambassador.png";
import EliteAmbassador from "../../../images/AmbassadorBadges/EliteAmbassador.png";
import Authorized from "../../../images/AmbassadorBadges/Authorized.png";
import Contractor from "../../../images/AmbassadorBadges/Contractor.svg";
import Certified from "../../../images/AmbassadorBadges/Certified.svg";
import IntegratorHistory from "../../../components/AccountCharts/IntegratorHistory";
import IntegratorProgress from "../../../components/AccountCharts/IntegratorProgress";
import AnnualPerformance from "../../../components/AccountCharts/AnnualPerformance";
import AdoptionProgressQual from "../../../components/AccountCharts/AdoptionProgressQual";
import AdoptionProgress from "../../../components/AccountCharts/AdoptionProgress";
import ProgramTable from "../../../components/AccountCharts/ProgramTable";
import SalesYearToDate from "../../../components/AccountCharts/SalesYearToDate";
import VIRProgress from "../../../components/AccountCharts/VIRProgress";

const ToastDiv = styled.div`
  background-color:#ffcc5f;
  color:#fff;
  width:100%
  padding:20px 40px;
  font-family:"Gotham-Medium";
  font-size:14px;
  text-align:center;
  position: relative;
`;

const ToastClose = styled.span`
  color: white;
  float: right;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 40%;
  line-height: 24px;
`;

const DataCard = styled.div`
  background-color: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: rgb(0 0 0 / 2%) 10px 10px 10px;
`;
const DataCardHeader = styled.div`
  font-family:"Gotham-Medium";
  font-size: 18px;
  padding-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  >div {
    text-transform: none;
  }
`;
const Badge = styled.img`
  width: 50px;
  border-radius: 50%;
`;
const BigBadge = styled.img`
  width: 150px;
  border: 5px solid #9b9b9b;
  border-radius: 4px;
`;
const BigCertified = styled.img`
  width: 150px;
`;
const GoalHeader = styled.div`
  font-family:"Gotham-Medium";
  font-size:12px;
  text-align:center;
  padding: 5px;
  text-transform: uppercase;
`;

const DrawCircle = ({radius, stroke, fill}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  return (
    <svg height={radius * 2} width={radius *2}>
      <circle 
        stroke={fill}
        fill={fill}
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
};

const Overview = ({account, addresses, contacts, user, orders, location}) => {
  const [tabKey, setTabKey] = useState("dashboard");
  const [isElectrical, setIsElectrical] = useState(false);
  const [betaParticipation, setBetaParticipation] = useState(0);
  const [leadsWithSavant, setLeadsWithSavant] = useState(0);
  const showDashboard = useSelector(visibilitySelector('account_dashboard'));
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(account)){
      if (account.tierEarned !== account.tierActive){
        setShowToast(true);
      }
    }
  },[account])

  useEffect(() => {
    if (tabKey === "dashboard"){
      document.title = "Savant Community | Account Dashboard"
    }
  },[tabKey]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      if (
        (user.contact.accType === 'Contractor' && user.contact.accSubType === 'Electrical/Solar') ||
        (user.contact.accType === 'Dealer' && (user.contact.accSubType === 'Window Treatment' || user.contact.accSubType === 'Window Treatment - Full Line') )
      ){
        setIsElectrical(true);
      }
    }
  },[user])

  useEffect(() => {
    if (!showDashboard) {
      setTabKey("settings")
    }else {
      setTabKey("dashboard")
    }
  },[showDashboard]);

  let path = location.pathname;
  let hash = location.hash;

  const renderSubPage = () => {
    const date = new Date();
    const year = date.getFullYear();
    let integratorCurrent;
    let integratorNext;
    let ambassadorPrevious;
    let ambassadorCurrent;
    let ambassadorNext;

    if (account){
      integratorCurrent = _.find(account.salesProgramResults, (sp) => sp.year === year && sp.type === "Integrator Tier") || fakeIntegrator;
      integratorNext = _.find(account.salesProgramResults, (sp) => sp.year === year+1 && sp.type === "Integrator Tier") || fakeIntegrator;
      ambassadorPrevious = _.find(account.salesProgramResults, (sp) => sp.year === year - 1 && sp.type === "Ambassador") || fakeAmbassador;
      ambassadorCurrent = _.find(account.salesProgramResults, (sp) => sp.year === year && sp.type === "Ambassador") || fakeAmbassador;
      ambassadorNext = _.find(account.salesProgramResults, (sp) => sp.year === year+1 && sp.type === "Ambassador") || fakeAmbassador;
    }

    if (hash === ""){
      return (
        <Dashboard
          account={account}
          integratorCurrent={integratorCurrent}
          ambassadorCurrent={ambassadorCurrent}
          ambassadorPrevious={ambassadorPrevious}
          isElectrical={isElectrical}
        />
      )
    }
    
    if (hash === "#integrator"){
      return (
        <Integrator account={account} currentProgram={integratorCurrent} nextProgram={integratorNext} />
      )
    }
    if (hash === "#ambassador"){
      return (
        <Ambassador account={account} previousProgram={ambassadorPrevious} currentProgram={ambassadorCurrent} nextProgram={ambassadorNext} />
      )
    }
    if (hash === "#vir"){
      return (
        <VIRPerformance account={account} />
      )
    }
    if (hash === "#new"){
      return (
        <NewDashboard account={account} integratorCurrent={integratorCurrent} integratorNext={integratorNext} ambassadorPrevious={ambassadorPrevious} ambassadorCurrent={ambassadorCurrent} ambassadorNext={ambassadorNext}  />
      )
    }
    return null;
  }

  const renderHelpContacts = () => {
    if (!_.isEmpty(account)) {
      return account.accountContacts.map(c => {
        return (
          <Col sm={6} xs={12} className="pad12" key={c.sfid}>
            <DetailLabel>{c.title}</DetailLabel>
            <div className="f12 pad4">{c.name}</div>
            <div className="f12 pad4 ">
              <a className="oj" href={`tel:+${c.phone}`}>
                {c.phone}
              </a>
            </div>
            <div className="f12 pad4 ">
              <a className="oj" href={`mailto:${c.email}`} target="_blank">
                {c.email}
              </a>
            </div>
          </Col>
        );
      });
    }
    return null;
  };
  const renderPrograms = () => {
    const programs = [];
    if (account.programs.includes("HTA")){
      programs.push(HTA);
    }
    if (account.programs.includes("ProSource")){
      programs.push(ProSource);
    }
    if (account.programs.includes("AZIONE")){
      programs.push(Azione);
    }
    if (account.programs.includes("Bravas")){
      programs.push(Bravas);
    }
    if (account.programs.includes("Guild Alliance")){
      programs.push(Guild);
    }
    if (account.programs.includes('Advisory Council')){
      programs.push(Council);
    }
    return programs.map((p, idx) => {
      return (
        <img key={idx} src={p} height="50px" alt="program" style={p === Guild ? {backgroundColor:"#000", margin:10} : {margin:10}} />
      )
    })
    
  };

  const renderBadge = (big) => {
    if (big){
      if (account.tierActive === 'Certified'){
        return <BigCertified src={Certified} />
      }
      if (account.tierActive === 'Authorized'){
        return <BigBadge src={Authorized} />
      }
      if (account.tierActive === 'Ambassador'){
        return <BigBadge src={Ambassador} />
      }
      if (account.tierActive === 'Elite Ambassador'){
        return <BigBadge src={EliteAmbassador} />
      }
    } else {
      if (account.tierActive === 'Certified'){
        return <Badge src={Certified} />
      }
      if (account.tierActive === 'Authorized'){
        return <Badge src={Authorized} />
      }
      if (account.tierActive === 'Ambassador'){
        return <BigBadge src={Ambassador} />
      }
      if (account.tierActive === 'Elite Ambassador'){
        return <Badge src={EliteAmbassador} />
      }
    }
    
  };

  if (account && !_.isEmpty(user)) {
    const date = new Date();
    const year = date.getFullYear();
    const integratorCurrent = _.find(account.salesProgramResults, (sp) => sp.year === year && sp.type === "Ambassador Elite") || fakeIntegrator;
    let betaTemp = 0;
    let leadsTemp = 0;

    if (integratorCurrent.isBeta){
      betaTemp = 100;
    }
    if (integratorCurrent.leadsWithSavant){
      leadsTemp = 100;
    }


    const renderIcon = (type) => {
      if (integratorCurrent.earnedAmbassador){
        return <img src={DoneIcon} style={{width:25, height:25}} />;
      }
      if (type === 'beta') {
        if (integratorCurrent.isBeta) {
          return <img src={DoneIcon} style={{width:25, height:25}} />;
        }else {
          return <img src={ErrorIcon} style={{width:25, height:25}} />;
        }
      }
      if (type === 'leads') {
        if (integratorCurrent.leadsWithSavant) {
          return <img src={DoneIcon} style={{width:25, height:25}} />;
        }else {
          return <img src={ErrorIcon} style={{width:25, height:25}} />;
        }
      }
      if (type === 'enui') {
        if (integratorCurrent.adoptionEntertainmentNetworkUI >= 75000) {
          return <img src={DoneIcon} style={{width:25, height:25}} />;
        }else {
          return <img src={WipIcon} style={{width:25, height:25}} />;
        }
      }
      if (type === 'envp') {
        if (integratorCurrent.adoptionEnvironmentalPower >= 75000) {
          return <img src={DoneIcon} style={{width:25, height:25}} />;
        }else {
          return <img src={WipIcon} style={{width:25, height:25}} />;
        }
      }

      return null;
    };
    const renderPercent = (total, goal) => {
      const returnVal = Math.floor((total / goal) * 100);
      if (returnVal > 100){
        return 100;
      }else {
        return returnVal;
      }
    };


    if (isElectrical){
      return (
        <div style={{backgroundColor: '#f8f8f8', minHeight: 1000, paddingBottom: 200}}>
          <Container>
          <Row>

            <Col lg={{span: 8, order: 1}} md={{span: 12, order: 2}}>
              <Row>
                <Col xs={12}>
                  <GridItem style={{ minHeight: 330, paddingBottom: 0 }}>
                    <ItemHeader>Sales - Monthly YTD</ItemHeader>
                    <SalesYearToDate programData={account.salesProgramData} />
                  </GridItem>
                </Col>
              </Row>

              <Row style={account.programs.length <1 ? {display:'none'} : {marginTop:30}}>
                <Col sm={12}>
                  <GridItem style={{ minHeight: 125}}>
                    <ItemHeader style={{whiteSpace:"nowrap"}}>Program Memberships</ItemHeader>
                    <ProgramIconDiv>
                      {renderPrograms()}
                    </ProgramIconDiv>
                  </GridItem>
                </Col>
              </Row>

              
            </Col>

            <Col lg={{span: 4, order: 2}} md={{span: 12, order: 1}}>

              <Row style={{marginBottom:30}}>
                <Col xs={12}>
                  <GridItem style={{minHeight:125}}>
                    <StatusRow>
                      <div>
                        <DrawCircle radius={25} stroke={1} fill={account.accountStanding} />
                      </div>
                      <div className="f14 med text-uppercase">
                        {account.accountStandingReason}
                      </div>
                    </StatusRow>
                  </GridItem>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <GridItem style={{minHeight:125, paddingBottom: 35}}>
                    <ItemHeader style={{whiteSpace:"nowrap"}}>Account Details</ItemHeader>
                    <div style={{paddingLeft: 12}}>
                      <StatusRow style={{padding: 0, width: '100%'}}>
                        <div>
                          <img src={Contractor} height={50} />
                        </div>
                        <div className="f14 med">
                          <DetailLabel>Account Name/DBA</DetailLabel>
                          <div className="f12 m-b-5">{account.dba || account.name}</div>
                          {/* <DetailLabel>Website</DetailLabel>
                          <div className="f12 m-b-5">{account.website}</div> */}
                          <DetailLabel>Phone</DetailLabel>
                          <div className="f12">{account.phone || "N/A"}</div>
                        </div>
                      </StatusRow>
                    </div>
                    <DetailButtonDiv style={{textAlign:'center'}}>
                      <DetailButton
                        className="oj f10 pad12 linkHover med"
                        to="/account?tab=details#settings"
                      >
                        EDIT
                      </DetailButton>
                    </DetailButtonDiv>
                    

                  </GridItem>
                </Col>
              </Row>

            </Col>
            </Row>
            <Row className="m-t-30">
            <Col sm={12}>
              <GridItem style={{minHeight:385}}>
                <ItemHeader>Need more help?</ItemHeader>
                <Container>
                  <Row>{renderHelpContacts()}</Row>
                </Container>
              </GridItem>
            </Col>
            </Row>
            <div className="text-center light-gray m-t-20 f12">
            *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
            </div>
          </Container>
        </div>

      )

    }
    if (integratorCurrent && account.tierEarned){
      return (
        <div style={{backgroundColor: '#f8f8f8', minHeight: 800, paddingBottom: 200}}>
          <Container>
            <SubHeader>Overview</SubHeader>
            <Row>
              <Col md={8}>
                <DataCard>
                  <DataCardHeader>2024 Progress & Earned Tier</DataCardHeader>
                  <IntegratorProgress account={account} programResult={integratorCurrent} />
                </DataCard>
                <DataCard>
                  <DataCardHeader>Annual Performance</DataCardHeader>
                  <AnnualPerformance account={account} programResult={integratorCurrent} />
                </DataCard>
              </Col>
  
              <Col md={4}>
                <DataCard style={{height: 158}}>
                  <StatusRow style={{height:'100%'}}>
                    <div>
                      <DrawCircle radius={25} stroke={1} fill={account.accountStanding} />
                    </div>
                    <div className="f14 med text-uppercase" style={account.accountStandingReason.length > 50 ? {fontSize: 10} : null}>
                      {account.accountStandingReason}
                    </div>
                  </StatusRow>
                </DataCard>
  
                <DataCard style={{height: 259}}>
                  <DataCardHeader>Current Active Tier</DataCardHeader>
                  <div className="text-center">
                    {renderBadge(true)}
                  </div>
                  <div className="f14 med text-uppercase text-center m-t-5">
                    {account.tierActive} tier active<br />
                    {account.accountDiscountSpecific || 0}% Discount off MSRP
                  </div>
                </DataCard>
              </Col>
            </Row>
  
            <Row>
              <Col xs={12}>
                <DataCard>
                  <DataCardHeader>Program Requirements & Qualification Status</DataCardHeader>
                  <Container>
                    <Row>
                      <Col lg={3} md={6}>
                        <GoalHeader>Beta Participation</GoalHeader>
                        <AdoptionProgressQual progress={betaTemp} toGo={Math.abs(100 - betaTemp)} text={betaTemp> 0 ? "Complete!" : "Incomplete"} />
                        <AmbCardBottomGrid>
                          <div>
                            {renderIcon('beta')}
                          </div>
                          <div className="f12" style={{paddingLeft:10}}>
                            Hardware or software beta participation.
                          </div>
                        </AmbCardBottomGrid>
                      </Col>
                      <Col lg={3} md={6}>
                        <GoalHeader>Leads with Savant</GoalHeader>
                          <AdoptionProgressQual progress={leadsTemp} toGo={Math.abs(100 - leadsTemp)} text={leadsTemp> 0 ? "Complete!" : "Incomplete"} />
                        <AmbCardBottomGrid>
                          <div>
                            {renderIcon('leads')}
                          </div>
                          <div className="f12" style={{paddingLeft:10}}>
                            Leads with Savant as the primary source of Smart Home control.
                          </div>
                        </AmbCardBottomGrid>
                      </Col>
                      <Col lg={3} md={6}>
                        <GoalHeader>Entertainment, Network & UI</GoalHeader>
                        <AdoptionProgress
                          program={integratorCurrent}
                          type="adoptionEntertainmentNetworkUI"
                          goal={account.tierEarned.includes('Ambassador') ? 150000 : 75000}
                          isFuture={false}
                          text={parseCurrency(integratorCurrent.adoptionEntertainmentNetworkUI)}
                        />
                        <AmbCardBottomGrid>
                          <div>
                            {renderIcon('enui')}
                          </div>
                          <div className="f12 text-center">
                            {renderPercent(integratorCurrent.adoptionEntertainmentNetworkUI, account.tierEarned.includes('Ambassador') ? 150000 : 75000)}% of {account.tierEarned.includes("Ambassador") ? "$150,000" : "$75,000"} Goal
                          </div>
                        </AmbCardBottomGrid>
                      </Col>
                      <Col lg={3} md={6}>
                        <GoalHeader>Environmental & Power</GoalHeader>
                        <AdoptionProgress
                          program={integratorCurrent}
                          type="adoptionEnvironmentalPower"
                          goal={account.tierEarned.includes('Ambassador') ? 150000 : 75000}
                          isFuture={false}
                          // text={Math.floor((uiTotal / 10000) * 100) < 100 ? `${Math.floor((uiTotal / 10000) * 100)}%` : "Complete!"}
                          text={parseCurrency(integratorCurrent.adoptionEnvironmentalPower)}
                        />
                        <AmbCardBottomGrid>
                          <div>
                            {renderIcon('envp')}
                          </div>
                          <div className="f12 text-center">
                            {renderPercent(integratorCurrent.adoptionEnvironmentalPower, account.tierEarned.includes('Ambassador') ? 150000 : 75000)}% of {account.tierEarned.includes("Ambassador") ? "$150,000" : "$75,000"} Goal
                          </div>
                        </AmbCardBottomGrid>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="f12 light-gray">
                        *Program requirements & qualifications status displays progress towards next earned tier.
                      </Col>
                    </Row>
                  </Container>
                </DataCard>
  
                <DataCard style={integratorCurrent.tierEarned === 'Certified' ? {display: 'none'} : null}>
                  <DataCardHeader>
                    VIR Program
                  </DataCardHeader>
                  <VIRPerformance account={account} />
                </DataCard>
              </Col>
            </Row>
  
            <Row>
              <Col xs={12}>
                <DataCard>
                  <DataCardHeader>
                    History
                  </DataCardHeader>
                  <IntegratorHistory programData={account.salesProgramData} programResult={integratorCurrent} />
                </DataCard>
              </Col>
            </Row>
          </Container>
  
          <Container>
            <Row>
              <Col xs={12}>
                <DataCard>
                 <DataCardHeader>Program Memberships</DataCardHeader>
                  <ProgramIconDiv>
                    {renderPrograms()}
                  </ProgramIconDiv>
                </DataCard>
                
              </Col>
            </Row>
  
            <Row>
              <Col xs={12}>
                <DataCard>
                  <DataCardHeader>Need More Help?</DataCardHeader>
                  <Row>
                    {renderHelpContacts()}
                  </Row>
                </DataCard>
              </Col>
            </Row>
            <div className="text-center light-gray m-t-20 f12">
              *Information on this page is continuously updated. Please allow 24 hours for any new purchases or activity to be fully represented.
            </div>
          </Container>
        </div>
        
      )
    }
    
  }

  
  return (
    <Container>
      <Row>
        <Col lg={8}>
          <Skeleton count={3} height={300} />
        </Col>
        <Col lg={4}>
          <Skeleton count={1} height={150} />
          <Skeleton count={2} height={300} />
        </Col>
      </Row>
    </Container>
  )
}

export default Overview
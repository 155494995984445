import React from 'react';
import styled from "styled-components";


export const SavSearch = (obj, list, searchString) => {
    //console.log()
    const normalize = (data) => {
      return String(data)
        .toLowerCase()
        .trim()
        .replace(/-|_|#|\s|\.|\,|\?/gi, '');
    };
    const searchReg = normalize(searchString);
    let searchHolder = '';
    list.forEach((l) => {
      if (obj.name) {
        searchHolder += normalize(obj.name);
      } else {
        searchHolder += normalize(obj[l]);
      }
    });
    return searchHolder.includes(searchReg);
  };

  export default SavSearch;



import React, {Fragment} from "react";
import moment from 'moment';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import {parseCurrency, kFormatter} from "../Utilities";

const RADIAN = Math.PI / 180;

const CustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, payload } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Fragment>
      <text width={30} className="f10" size="10px" x={x} y={y-6} fill="white" textAnchor="middle" dominantBaseline="central">
        {payload.payload.name} '{payload.payload.year}
      </text>
      <text className="f10" size="10px" x={x} y={y+8} fill="white" textAnchor="middle" dominantBaseline="central">
        ${kFormatter(payload.payload.total)}
      </text>
    </Fragment>
    
  );
};

const CustomTooltip = (props) => {
  const { active, payload, goal } = props;
  if (active) {
    if (payload[0].name === 'Progress'){
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="med f12">
            {payload[0].value}%&nbsp;
          </span>
          <span className="book f12">
            of ${kFormatter(goal)} goal
          </span>
        </div>
      )
    }
    if (payload[0].name === 'To Go'){
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="med f12">
            {payload[0].value}%&nbsp;
          </span>
          <span className="book f12">
            to go of ${kFormatter(goal)} goal
          </span>
        </div>
      )
    }
    return (
      
      <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
        <span className="book f12">
          {payload[0].name} '{payload[0].payload.year} Sales:&nbsp;
        </span>
        <span className="med f12">
          {parseCurrency(payload[0].payload.total)}
        </span>
        <div className="red f10 med">
          {payload[0].payload.message}
        </div>
      </div>
    );
  }
  return null;
};

const AdoptionProgress = ({program, type, goal, isFuture, text }) => {
  const startDateMoment = isFuture ? moment('2022-01-01'): moment();
  const firstQuarter = startDateMoment.clone().subtract(3, 'quarters')
  const secondQuarter = startDateMoment.clone().subtract(2, 'quarters')
  const thirdQuarter = startDateMoment.clone().subtract(1, 'quarters')
  const fourthQuarter = startDateMoment.clone();

  let total = Math.floor((program[type] / goal) * 100);
  let totalMax = total;
  let toGo = 0;

  if (total > 100){
    totalMax = 100;
  }
  if (total < 100){
    toGo = 100 - total;
  }
  if (program.earnedAmbassador){
    toGo = 0;
    totalMax = 100;
  }

  const data02 = [
    { name: 'Progress', value: totalMax, color:"#4cd964"},
    { name: 'To Go', value: toGo, color:"#979797"}
  ];
  if (program){
    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart width={200} height={200} key={Math.random()}>
          {/* <Pie data={data} dataKey="value" cx="50%" cy="50%" outerRadius={60} fill="#4cd964" labelLine={false}
              label={<CustomizedLabel />}>
            {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.isRisk ? "#d0021b" : "#3f3f3f"} />
              ))}
          </Pie> */}
          <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90}>
            {data02.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label value={text} position="center" />
          </Pie>
          <Tooltip content={<CustomTooltip />} goal={goal} />
        </PieChart>
      </ResponsiveContainer>
    )

  }
  return null;

  
}

export default AdoptionProgress;
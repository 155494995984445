import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import {fetchAccount} from "../../actions"

const ItemHeader = styled.div`
  padding: 12px;
  color: #565551;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Gotham-Medium";
  text-align: left;
`;
const GridItem = styled.div`
  min-height: 400px;
  background-color: #fafaf9;
  padding: 15px;
`;

const DetailLabel = styled.div`
  font-size: 12px;
  color: #b4b4b4;
`;

const MySupport = ({account, fetchAccount}) => {

  useEffect(() => {
    document.title = "Savant Community | Contact Savant"
  },[])

  useEffect(() => {
    if (_.isEmpty(account)) {
      fetchAccount();
    }
  }, [account]);

  const renderHelpContacts = () => {
    if (!_.isEmpty(account.data)) {
      return account.data.accountContacts.map(c => {
        return (
          <Col sm={6} xs={12} className="pad12" key={c.email}>
            <DetailLabel>{c.title}</DetailLabel>
            <div className="f12 pad4">{c.name}</div>
            <div className="f12 pad4 ">
              <a className="oj" href={`tel:+${c.phone}`}>
                {c.phone}
              </a>
            </div>
            <div className="f12 pad4 ">
              <a className="oj" href={`mailto:${c.email}`} target="_blank">
                {c.email}
              </a>
            </div>
          </Col>
        );
      });
    }
    return null;
  };

  if(account.data) {
    return (
      <div>
        <SavBanner text="Contact Us" padding="40px" />
        <Container style={{ marginTop: 30 }}>
          <Row>
            <Col sm={12}>
              <GridItem>
                <ItemHeader>Need more help?</ItemHeader>
                <Container>
                  <Row>{renderHelpContacts()}</Row>
                </Container>
              </GridItem>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return null;
  
};

const mapStateToProps = state => {
  return {
    account: state.account,
  };
};
export default connect(mapStateToProps, {fetchAccount})(MySupport);
import { SET_AUTH_ERROR, SET_SERVER_ERROR } from "../actions/types";

const serverState = {
  authError: false
};

export function server(state = serverState, action) {
  switch (action.type) {
    case SET_AUTH_ERROR:
      return { ...state, authError: true };
    default:
      return state;
  }
}
export default server;

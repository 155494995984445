import React from "react";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  FaRegImages,
  FaCalendarAlt,
  FaFileInvoiceDollar,
  FaUsersCog,
  FaBookReader,
  FaFileCode,
  FaBriefcase,
  FaShieldAlt
} from "react-icons/fa";
import {GoPackage, GoLightBulb} from 'react-icons/go'
import {GiWindow, GiSummits} from 'react-icons/gi';
import {MdSchool} from 'react-icons/md';
import {parseDateShort, parseCurrency} from '../../../components/Utilities';
import {PlaceholderText} from '../../../components/Styled';
import VIRProgressMini from "../../../components/AccountCharts/VIRProgressMini";
import SupportIcon from "../../../images/SupportIcon.png";
import UniversityIcon from "../../../images/UniversityIcon.png";
import appIcon from "../../../images/appIcon.png";
import SavantIconBeta from '../../../images/SavantIconBeta.png';
import SavantPro from '../../../images/SavantPro.png';
import Soundbar from "../../../images/soundbar.png";
import SCM from "../../../images/SCM.png";
import FixtureIcon from "../../../images/FixtureIcon.png";
import Authorized from "../../../images/AmbassadorBadges/Authorized.png";
import Silver from "../../../images/AmbassadorBadges/Silver.png";
import Gold from "../../../images/AmbassadorBadges/Gold.png";
import Platinum from "../../../images/AmbassadorBadges/Platinum.png";
import Logo from '../../../images/savLogo.png';
import Shade from '../../../images/Shade.svg';
import DesignTools from '../../../images/designtools.png';

const CardDivIcon = styled(Link)`
  margin-top:30px;
  padding: 36px 12px;
  background-color: #fafaf9;
  display: grid;
  grid-template-rows: 25px 100px 25px;
  cursor: pointer;
  justify-items: center;
  text-align: center;
  border: 1px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  &:hover {
    text-decoration:none;
    color:unset;
    border: 1px solid #f9f9f9;
    opacity: 0.7;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const CardDivA = styled.a`
  margin-top:30px;
  padding: 36px 12px;
  background-color: #fafaf9;
  display: grid;
  grid-template-rows: 25px 100px 25px;
  cursor: pointer;
  justify-items: center;
  text-align: center;
  border: 1px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  &:hover {
    text-decoration:none;
    color:unset;
    border: 1px solid #f9f9f9;
    opacity: 0.7;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;
const CardHeader = styled.div`
  color: #565551;
  font-family: "Gotham-Medium";
  font-size: 16px;
  text-transform: uppercase;
  a {
    color: #565551;
    font-family: "Gotham-Medium";
  }
`;
const ImgIcon = styled.img`
  height:50px;
  margin: 25px 0px;
`;
const Badge = styled.img`
  cursor:pointer;
  width: 50px;
  border: ${props => props.active ? '5px solid #4cd964' : '5px solid #9b9b9b'};
  border-radius: 50%;
  margin-top:25px;
`;
const hidden = {
  display: 'none'
}

const Card = ({card, user, account}) => {
  const SFTOKEN = localStorage.getItem("sfsid");

  const getAccountTierIcon = (tier) => {
    if (tier === 'Authorized' || tier === 'AUTHORIZED') {
      return <Badge src={Authorized} />;
    }
    if (tier === 'Silver' || tier === 'SILVER') {
      return <Badge src={Silver} />;
    }
    if (tier === 'GOLD' || tier === 'Gold') {
      return <Badge src={Gold} />;
    }
    if (tier === 'Platinum' || tier === 'PLATINUM') {
      return <Badge src={Platinum} />;
    }
    return null;
  };

  const CardData = () => {
    switch (card.linkText) {

      case "Latest Software":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <ImgIcon src={SavantPro} alt="App Icon" />
            <div className="f12">
              <span className="med f12">{card.cardData.releaseName}</span>
              <br />
              Released: <span className="book f12">{parseDateShort(card.cardData.releaseDate)} </span>
            </div> 
          </CardDivIcon>
        );

        case "Savant Services":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <div>
              <ImgIcon src={Logo} alt="Services Icon" style={{height: 20, marginBottom: 0, marginTop: 30}} />
              <div className="text-center f10" style={{letterSpacing: 10}}>SERVICES</div>
            </div>
            
            <div className="f12">
              Energy, Smart & Training Services
            </div>
          </CardDivIcon>
        );

      case "Savant Beta":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <ImgIcon src={SavantIconBeta} alt="Beta Icon" />
            <div className="f12">
              <span className="med f12">{card.cardData.currentBetaVersion}</span>
              <br />
              Status: <span className={card.cardData.betaHostStatus === "Found Beta Host" ? "med f12 green" : "med f12 red"}>
                {card.cardData.betaHostStatus === "Found Beta Host" ? "Registered" : "Not Registered"}
              </span>
            </div> 
          </CardDivIcon>
        );

      case "Contacts":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaUsersCog fill="#3f3f3f" size="50px" style={{margin:25}} />
            <div className="f12">
              <span className="f12 oj med">{card.cardData.activeContacts}</span> Active Team Members
            </div> 
          </CardDivIcon>
        );

      case "Cases":
        return (
          <CardDivA href={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={SupportIcon} height="100px" alt="Cases" />

            <div className="f12" style={card.cardData.supportEligible === true ? null : hidden}>
              Estimated Wait: <span className="med f12 oj">{card.cardData.caseString}</span> {card.cardData.caseUnits}
            </div>

            <div className="f12" style={card.cardData.supportEligible === false && user.contact.accSubType === 'International Distribution'  ? null : hidden}>
              Open Case Count: <span className="med f12 oj">{card.cardData.caseString}</span>
            </div>
          </CardDivA>
        );
        case "Support":
          return (
            <CardDivA href={card.linkSrc} target="_blank">
              <CardHeader>
                {card.linkText}
              </CardHeader>
              <img src={SupportIcon} height="100px" alt="Cases" />
              <div className="f12">
                {card.cardData.caseString}
              </div>
            </CardDivA>
          );

      case "Savant Knowledge":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaBookReader fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              Recently Published Articles: <span className="med f12 oj">{card.cardData.countPublishedLast30}</span>
            </div>
          </CardDivIcon>
        );
      case "Resources":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaBookReader fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              Specs, Guides, Help Videos & More
            </div>
          </CardDivA>
        );

      case "Sales & Marketing":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaRegImages fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              New Resources Added: <span className="f12 oj med">{card.cardData.countPublishedLast30}</span>
            </div> 
          </CardDivIcon>
        );

      case "Orders":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <GoPackage fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              Needing Attention: <span className="f12 oj med">{card.cardData.ordersNeedingAttention}</span>
              <br />
              Pending Shipment: <span className="f12 oj med">{card.cardData.ordersPendingShipment}</span>
            </div> 
          </CardDivIcon>
        );

      case "Education":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={UniversityIcon} height="100px" alt="Education" />
            <div className="f12">
              Current Certified Team Members: <span className="f12 oj med">{card.cardData.certifiedTeamMembers}</span>
            </div> 
          </CardDivIcon>
        );
      case "Billing":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaFileInvoiceDollar fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              Open Invoices: <span className="f12 oj med">{card.cardData.openInvoices}</span>
              <br />
              Available Credits: <span className="f12 oj med">{card.cardData.availableCredits}</span>
            </div> 
          </CardDivIcon>
        );
      
      case "VIR":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <div style={{width:"100%", maxWidth: 200}}>
              <VIRProgressMini account={account} />
            </div>
            <div className="f12">
              Sales to go: <span className="med f12 oj">{parseCurrency(card.cardData.virSalesToGo)}</span>
            </div>

          </CardDivIcon>
        );

      case "RMAs":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaBriefcase fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12">
              <span className="f12 oj med">{card.cardData.numProductsToReturn}</span> RMAs pending return
            </div> 
          </CardDivA>
        );

      case "Programs":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            {getAccountTierIcon(card.cardData.currentTier)}
            <div className="f12 book">
              {card.cardData.nextTierAmount ? `Next Tier: $${card.cardData.nextTierAmount}` : 'You have achieved Platinum Integrator status!'} 
            </div>
          </CardDivIcon>
        );
      case "Warranty":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaShieldAlt fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivIcon>
        );

      case "Savant Scheduler":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaCalendarAlt fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12 book">
              {card.cardData.upcomingEvents > 0 ? "Upcoming Events" : "Schedule Today"}
              <span className="f12 med oj">
                &nbsp;&nbsp;{card.cardData.upcomingEvents}
              </span>
            </div>
          </CardDivA>
        );

      case "Profiles":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <FaFileCode fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12 text-center book ">
              View current profile requests
            </div>
          </CardDivIcon>
        );
      
      case "Savant University":
        return (
          <CardDivA href={`${card.linkSrc}?username=${user.username}&sessionId=${SFTOKEN}`} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <MdSchool fill="#3f3f3f" size="50px" style={{marginTop:25}} />
            <div className="f12 text-center book ">
              Get smart on the Savant Smart Home
            </div>
          </CardDivA>
        );

      case "Shade Designer":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={Shade} width="50px"  style={{margin:"20px auto" }} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivA>
        );
      case "Lighting & Shade Designer":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={DesignTools} width="50px"  style={{margin:"20px auto" }} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivA>
        );
      case "Grille Designer":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={Soundbar} width="100px" style={{margin:"30px auto" }} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivA>
        );
      case "USAI Fixtures":
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={FixtureIcon} width="100px" style={{margin:"20px auto" }} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivIcon>
        );
      case "Savant Store":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={appIcon} width="50px" style={{marginTop:25}} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivA>
        );
      case "Savant Central Management":
        return (
          <CardDivA href={card.linkSrc} target="_blank">
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <img src={SCM} width="50px" style={{marginTop:40}} />
            <div className="f12 text-center book">
              {card.bodyText}
            </div>
          </CardDivA>
        );
      default:
        return (
          <CardDivIcon to={card.linkSrc}>
            <CardHeader>
              {card.linkText}
            </CardHeader>
            <div />
            <PlaceholderText />
          </CardDivIcon>
        )
    }
  };

  return (
    <CardData />
  )

}

export default Card;
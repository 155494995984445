import { FETCH_TRADESHOW_VIRTUAL_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const tradeshowVirtual = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TRADESHOW_VIRTUAL_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default tradeshowVirtual;
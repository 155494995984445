import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Container, Dropdown, Table, Modal, Form } from "react-bootstrap";
import _ from "lodash";

const SavFormInputArea = ({value, setValue, feedback, label, disabled, notRequired}) => {
  return (
    <div className="input-container-area m-t-10 m-b-10">
      <Form.Control
        required={notRequired ? false : true}
        as="textarea"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        disabled={disabled}
        rows={4}
      />
      <Form.Control.Feedback type="invalid">
        {feedback}
      </Form.Control.Feedback>
      <label className={value && 'filled'}>{label}</label>
    </div>
  )
}

export default SavFormInputArea;
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const rowStyle = {
  marginBottom: "20px"
};

const EventsContainer = styled(Container)`
  padding: 15px 30px;
`;
const HeaderTextOverlay = styled.div`
  position:absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display:flex;
  height: 100%;
  align-items:center;
  justify-content:center;
  padding: 10px;
  z-index: 9999;
`;
const EventLink = styled.a`
  &:hover {
    text-decoration: none;
    background-color: #fafaf9;
  }
`;

const EventsComponent = props => {
  const { events } = props;

  const createMarkup = richText => {
    return { __html: richText };
  };

  const REF = localStorage.getItem('ref');

  const renderEventFeed = () => {
    if (!_.isEmpty(events)) {
      let eventsSliced = events.slice(0, 3);
      return eventsSliced.map((eventItem, index) => {
        return (
          <EventLink 
            href={
              eventItem.type === "Scheduler Event"
                ? `${REF}/Customers/apex/AppointmentDetail?svcApptId=${eventItem.sfid}`
                : `/events/${eventItem.sfid}`
              }
              key={index}
          >
          <Row style={rowStyle} >
            <Col>
              <Row className="m-b-5">
                <Col style={{ padding: 0 }}>
                  <div
                    className="event-img-container home-shadow"
                    style={{ height: 150 }}
                  >
                    <HeaderTextOverlay
                      className="f16 med text-center text-uppercase headerTextOverlay"
                      style={{ color: eventItem.textColor }}
                    >
                      {eventItem.headerText}
                    </HeaderTextOverlay>
                    <div
                      className="event-img-zoom"
                      style={{
                        backgroundImage: `url(${eventItem.img})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        display: "block",
                        height: '150px',
                      }}
                    />
                    
                    <span
                      className="f12 book text-uppercase"
                      style={{
                        position: "absolute",
                        left: "5%",
                        bottom: 10,
                        color: eventItem.textColor
                      }}
                    >
                      {eventItem.locationCity} {eventItem.locationType === 'Online' ? "Online" : ","} {eventItem.locationState}
                    </span>
                    <span
                      className="f10 book text-uppercase"
                      style={{
                        position: "absolute",
                        right: "5%",
                        bottom: 10,
                        color: eventItem.textColor
                      }}
                    >
                      {eventItem.localStartTime}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          </EventLink>
        );
      });
    }
  };

  if (!_.isEmpty(events)) {
    return (
      <div>
        <EventsContainer>{renderEventFeed()}</EventsContainer>
      </div>
    );
  }
  return (
    <Skeleton count={3} height={150} />
  )
  
};

const maptStateToProps = state => {
  return {
    events: state.events.data,
  };
};

export default connect(maptStateToProps, {})(EventsComponent);

import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDocument, setSuccessToast, fetchStatement, cancelPayment, fetchPaymentPDF, createTask, fetchTasks, fetchProducts } from "../../../actions";
import { SubHeader,ZeroStateText } from "../../../components/Styled";
import SavToast from "../../../components/SavToast";
import InvoiceTab from "./InvoiceTab";
import StatementsTab from "./StatementsTab";
import CreditMemosTab from "./CreditMemosTab";
import FinancingTab from "./FinancingTab";
import _ from "lodash";
const tabActive = {
  color: "#ff5f00"
};
const hidden = {
  display: 'none'
}

const Invoices = props => {
  const {
    credits,
    invoices,
    payments,
    account,
    fetchDocument,
    setSuccessToast,
    fetchStatement,
    cancelPayment,
    fetchPaymentPDF,
    location,
    fetchTasks,
    tasks,
    createTask,
    showBilling,
    fetchingUser
  } = props;

  const [tabKey, setTabKey] = useState("invoices");
  let param = location.search;

  useEffect(() => {
    document.title = "Savant Community | Billing"
  },[]);

  const renderSubPage = () => {
    if (param === '?tab=invoices' || !param){
      return (
        <InvoiceTab invoices={invoices} credits={credits} account={account} fetchDocument={fetchDocument} setSuccessToast={setSuccessToast} />
      )
    }
    if (param === '?tab=memos'){
      return (
        <CreditMemosTab credits={credits.data} account={account} fetchDocument={fetchDocument} setSuccessToast={setSuccessToast} />
      )
    }
    if (param === '?tab=history'){
      return (
        <StatementsTab invoices={invoices} account={account} fetchDocument={fetchDocument} setSuccessToast={setSuccessToast} fetchStatement={fetchStatement} payments={payments} cancelPayment={cancelPayment} fetchPaymentPDF={fetchPaymentPDF} />
      )
    }
    if (param === '?tab=financing'){
      return (
        <FinancingTab invoices={invoices} account={account} fetchDocument={fetchDocument} setSuccessToast={setSuccessToast} fetchStatement={fetchStatement} payments={payments} cancelPayment={cancelPayment} fetchPaymentPDF={fetchPaymentPDF} createTask={createTask} tasks={tasks} fetchTasks={fetchTasks} />
      )
    }
  }

  if (!showBilling && !fetchingUser){
    return (
      <ZeroStateText margin="200px">
        You do not have permission to view this page.
        <div className="text-center m-t-20">
          <Link to="/">
            <button className="sav-btn__orange">Return Home</button>
          </Link>
        </div>
      </ZeroStateText>
    )
  }

  if (account) {
    return (
      <div>
        <SavToast />
        <SubHeader>Billing</SubHeader>
        <Tab.Container
          id="invoice-tabs"
          activeKey={tabKey}
          onSelect={k => setTabKey(k)}
        >
          <Nav className="justify-content-center">
            <Nav.Item>
              <NavLink
                className="tab-link-overview"
                to={`/account?tab=invoices#billing`}
                style={param === "?tab=invoices" ? tabActive : null}
              >
                Invoices
              </NavLink>
            </Nav.Item>
            <Nav.Item style={account.type === 'Non-Direct' ? hidden : null}>
              <NavLink
                className="tab-link-overview"
                to={`/account?tab=memos#billing`}
                style={param === "?tab=memos" ? tabActive : null}
              >
                Credit Memos
              </NavLink>
            </Nav.Item>
            <Nav.Item style={account.type === 'Non-Direct' ? hidden : null}>
              <NavLink
                className="tab-link-overview"
                to={`/account?tab=history#billing`}
                style={param === "?tab=history" ? tabActive : null}
              >
                Payment History
              </NavLink>
            </Nav.Item>
            <Nav.Item style={account.type === 'Non-Direct' ? hidden : null}>
              <NavLink
                className="tab-link-overview"
                to={`/account?tab=financing#billing`}
                style={param === "?tab=financing" ? tabActive : null}
              >
                Financing
              </NavLink>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            {renderSubPage()}
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
  return null;
  
};

const mapStateToProps = state => {
  return {
    credits: state.credits,
    invoices: state.invoices.data,
    account: state.account.data,
    payments: state.payments.data,
    tasks: state.tasks.data
  };
};

export default connect(mapStateToProps, {
  fetchDocument,
  setSuccessToast,
  fetchStatement,
  cancelPayment,
  fetchPaymentPDF,
  fetchTasks,
  createTask
})(Invoices);

import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from 'moment';
import { Row, Col, Container, Table, Modal } from "react-bootstrap";
import _ from "lodash";
import ReactGA from 'react-ga'
import SavDatePicker from "../../../components/SavDatePicker";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import {eliteSearchAlgorithm, parseCurrency, parseDateInt} from "../../../components/Utilities"
import { SavSearch, SavRow,SavRowDiv, SavLabel } from "../../../components/Styled";
import { createLoadingSelector } from "../../../selectors";
import {AiOutlineCloudDownload, AiOutlineInfoCircle, AiOutlineBank, AiOutlineCreditCard} from "react-icons/ai";
import {FaFileInvoiceDollar} from "react-icons/fa";
import { save } from 'save-file'
import { setSuccessToast } from "../../../actions";

const TabHeader = styled.div`
  margin: 30px 0px 0px;
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 14px;
`;
const InvoiceGridItem = styled.div`
  min-height: 175px;
  padding: 50px 15px;
  background-color: #fafaf9;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: "Gotham-Book";
`;

const GridItemContent = styled.div`
  font-family: "Gotham-Medium";
  text-transform: uppercase;
  font-size: 30px;
`;
const hidden = {
  display:'none'
};
const cancelStyle = {
  width: '100%'
}

const StatementsTab = props => {
  const { invoices, account, fetchDocument, setSuccessToast, fetchStatement, payments, cancelPayment, fetchPaymentPDF, loadingCancel, loadingPDF, loadingStatement } = props;
  const [statements, setStatements] = useState([]);
  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentsFiltered, setPaymentsFiltered] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [pageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const startDateMoment = moment();
  const startDateDefault = startDateMoment.clone().subtract(1, 'month');
  const endDateDefault = new Date();
  const [startDate, setStartDate] = useState(new Date(startDateDefault));
  const [endDate, setEndDate] = useState(endDateDefault);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const pageOpts = [10, 25, 50, 100];

  useEffect(() => {
    if (!_.isEmpty(invoices)){
      const tempStatements = [];
      const today = moment();
      const thisYear = today.year();
      const thisMonth = today.month();
      const thisDay = today.date();

      let startDate2;
      if (thisDay >= 7 && thisMonth !== 0){
        startDate2 = moment(`${thisYear}-${thisMonth +1}-01`);
      }
      if (thisDay <= 7 && thisMonth !== 0){
        startDate2 = moment(`${thisYear}-${thisMonth}-01`);
      }
      if (thisDay >= 7 && thisMonth === 0){
        //startDate2 = new Date(`${thisYear-1}-12-01`);
        startDate2 = moment(`${thisYear-1}-12-01`);
      }
      if (thisDay <= 7 && thisMonth === 0){
        startDate2 = moment(`${thisYear-1}-11-01`);
      }

      const months = [];
      //const dateStart = moment(parseDateInt(startDate2));
      const dateStart = startDate2;
      const dateEnd = dateStart.clone().subtract(12, 'month');
      while (dateEnd.diff(dateStart, 'months') < 0) {
        months.push(dateStart.format('YYYY-MM-DD'));
        dateStart.subtract(1, 'month')
       
      }

      months.forEach((m) => {
        const mStart = moment(m);
        const mEnd = mStart.clone().add(1, 'month').subtract(1, 'day');
        const obj = {
          start: m,
          end: mEnd.format('YYYY-MM-DD')
        }
        tempStatements.push(obj);
      });
      setStatements(tempStatements);
    }

  },[invoices]);

  useEffect(() => {
    if (!_.isEmpty(payments)){
      setPaymentsList(payments);
    }
  },[payments]);

  useEffect(() => {
    if(!_.isEmpty(payments)){
      setCurrentPage(1);
      const results = payments.filter(
        payment => eliteSearchAlgorithm(searchString, payment)
      );
      setPaymentsList(results);
    }

  },[searchString]);

  useEffect(() => {
    if (paymentsList) {
      let index = 0;
      const tempPayments = [];
      for (index = 0; index < paymentsList.length; index += pageSize) {
        let chunk = paymentsList.slice(index, index + pageSize);
        tempPayments.push(chunk);
      }
      setPaymentsFiltered(tempPayments);
    }
  },[paymentsList]);


  const handleFetchOutstanding = () => {
    document.body.style.cursor = 'wait'
    ReactGA.event({
      category: 'Finance',
      action: 'Outstanding Statement Click',
    });
    fetchDocument(account.sfid, "outstandingStatement").then((res) => {
      document.body.style.cursor = 'default'
      if (res.success) {
        const data = res.payload; 
        save(data, `Savant Outstanding Statement.pdf`);
      }
      setSuccessToast(true);
    })
  };
  const handleCloseModal = () => {
    ReactGA.event({
      category: 'Finance',
      action: 'Custom Statement Click',
    });
    document.body.style.cursor = 'wait'
    fetchStatement(account.sfid, parseDateInt(startDate), parseDateInt(endDate)).then((res) => {
      document.body.style.cursor = 'default'
      if (res.success){
        const data = res.payload; 
        save(data, `Savant Activity Statement ${parseDateInt(startDate)}-${parseDateInt(endDate)}.pdf`);
      }
      setSuccessToast(true);
      setShowCustomModal(false);
    })
  };

  const handleClickIcon = (start, end) => {
    document.body.style.cursor = 'wait'
    fetchStatement(account.sfid, parseDateInt(new Date(start)), parseDateInt(new Date(end))).then((res) => {
      document.body.style.cursor = 'default'
      if (res.success){
        const data = res.payload; 
        save(data, `Savant Activity Statement ${parseDateInt(start)}-${parseDateInt(end)}.pdf`);
      }
      setSuccessToast(true);
      setShowCustomModal(false);
    })
  };

  const handleClickIconPDF = (payment) => {
    fetchPaymentPDF(payment.originalRequestSfid).then((res) => {
      if (res.success){
        const data = res.payload;
        const name = payment.orginalRequestName;
        save(data, `Savant Payment Receipt.pdf`);
      }
      setSuccessToast(true);
    });
  };

  const handleCancel = (paymentId) => {
    cancelPayment(paymentId);
  };
  const renderCustomModal = () => {
    return (
      <Fragment>
         <button className="sav-btn__orange" onClick={() => setShowCustomModal(true)}>Customized Statement</button>
        <Modal show={showCustomModal} onHide={() => setShowCustomModal(false)} centered animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              Generate Custom Statement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-t-20 m-b-20">
            <Container>
              <Row>
                <Col xs={12} style={{display:'flex', justifyContent:'center'}}>
                  <SavDatePicker
                    clickStart={setStartDate}
                    clickEnd={setEndDate}
                    start={startDate}
                    end={endDate}
                  />
                </Col>
              </Row>
                
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button className="sav-btn__orange--fill" onClick={() => handleCloseModal()}>
              Generate
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  };
  const renderHeaders = () => {
    if (!_.isEmpty(account)) {
      return (
        <Row>
          <Col md={4}>
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Current Balance
              </div>
              <GridItemContent>
                {parseCurrency(account.arBalance, 2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
          <Col md={4} >
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Past Due Balance
              </div>
              <GridItemContent className={account.pastDue === 0 ? "gray" : "red"}>
                {parseCurrency(account.pastDue, 2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
          
          <Col md={4}>
            <InvoiceGridItem>
              <div className="f18 m-b-10">
                Available Credit
              </div>
              <GridItemContent className={account.availableCredit === 0 ? "gray" : "green"}>
                {parseCurrency(account.availableCredit,2)}
              </GridItemContent>
            </InvoiceGridItem>
          </Col>
        </Row>
      );
    }
    return null;
  };
  const renderStatements = () => {
    return statements.map((s, idx) => {
      const startMoment = moment(s.start);
      return (
        <Col xs={6} sm={4} md={3} key={idx}>
          <SavRowDiv className="savFlexBetweenCenter" style={{minHeight: 78, cursor: 'pointer'}} onClick={() => handleClickIcon(s.start, s.end)}>
            <div>
              {startMoment.format('MMMM')} {moment(s.start).year()} Statement
            </div>
            <div>
              <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} onClick={() => handleClickIcon(s.start, s.end)} size={25} />
            </div>
          </SavRowDiv>
        </Col>
      )
    })
  };

  const renderPaymentIcon = (type) => {
    switch(type) {
      case "ACH":
        return <AiOutlineBank size={18} className="m-r-5" />;
      case "Credit Card":
        return <AiOutlineCreditCard size={18} className="m-r-5" />;
      case "Credit Memo":
        return <FaFileInvoiceDollar size={18} className="m-r-5" />;
      default:
        return null;
    };
  };

  const renderDownloadIcon = (payment) => {
    if (payment.canCancel && !payment.hasAttachment){
      return (
        <div className="text-center">
          <button className="sav-btn__red" style={{width: 100}} onClick={() => handleCancel(payment.sfid)}>Cancel</button>
        </div>
      )
    }
    if (payment.hasAttachment){
      return (
        <div className="text-center">
          <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} onClick={() => handleClickIconPDF(payment)} size={25} />
        </div>
      )
    } else {

      return (
        <div className="text-center">
          <AiOutlineCloudDownload style={{fill:"#ff5f00", cursor:"pointer"}} onClick={() => handleClickIcon(moment(payment.receiptDate), moment(payment.receiptDate))} size={25} />
        </div>
      )
    }
  }

  const renderPayments = () => {
    if (!_.isEmpty((paymentsFiltered))){
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > paymentsFiltered.length) {
        page = 0;
        setCurrentPage(1);
      }

      return paymentsFiltered[page].map((p, idx) => {

        return (
          <SavRow key={idx} className="vertical-align" style={{minHeight: 78, padding: "20px 0px"}}>
            <Col xs={3} md={2}>
              {p.originalRequestName || p.paymentReference}
            </Col>
            <Col xs={2} className="vertical-align d-none d-md-block">
              {renderPaymentIcon(p.type)}
              {p.type}
            </Col>
            <Col xs={3} md={2}>{p.receiptDate || "N/A"}</Col>
            <Col xs={2} className="d-none d-md-block">{parseCurrency(p.amount)}</Col>
            <Col xs={2} md={1}>
              {p.postingStatus}
            </Col>
            <Col xs={4} md={3}>
            {renderDownloadIcon(p)}
            </Col>
            
          </SavRow>
        )
      })
    }
    return null;
  };

  const renderLoader = () => {
    if (loadingCancel || loadingPDF || loadingStatement) {
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      );
    }
  };

  if (statements && account) {
    return (
      <Container>
        {renderLoader()}
        {renderHeaders()}
        <Row className="m-t-20">
          <Col xs={12} className="text-center">
            <button className="sav-btn__orange" onClick={() => handleFetchOutstanding()}>Outstanding Statement</button>
            &nbsp;&nbsp;
           {renderCustomModal()}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>  
            <TabHeader>Activity Statements</TabHeader>
            <Container className="m-t-20 pad-0">
              <Row>
                {renderStatements()}
              </Row>
            </Container>
          </Col>
          <Col xs={12}>
            <TabHeader>Payment History</TabHeader>
            <Container>
              <Row className="m-b-10 m-t-10">
                <Col xs={6} className="pad-0">
                  <SavSearch
                    type="search"
                    placeholder="Search.."
                    width="100%"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                  />
                </Col>
              </Row>
              
              <SavColumnSort
                data={paymentsList}
                setData={setPaymentsList}
                columns={[
                  {text: "Reference #", size: 2, sizeXS: 3, value: "paymentReference"},
                  {text: "Method", size: 2, hideMobile: true, value: "type"},
                  {text: "Date", size: 2, sizeXS: 3, value: "receiptDate"},
                  {text: "Amount", size: 2, hideMobile: true, value: "amount"},
                  {text: "Status", size: 4, sizeXS: 6, value: "postingStatus"},
                ]}
              
              />
              {renderPayments()}
            </Container>
            <SavPagination
              data={paymentsFiltered}
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              dataLength={paymentsList.length}
            />
          </Col>
        </Row>
        
      </Container>
    );
  }
  return <div>loading...</div>;
};

const loadingCancel = createLoadingSelector(["UPDATE_PAYMENT"]);
const loadingPDF = createLoadingSelector(["FETCH_PAYMENT_PDF"]);
const loadingStatement = createLoadingSelector(["FETCH_DOCUMENT"])

const mapStateToProps = state => {
  return {
    loadingCancel: loadingCancel(state),
    loadingPDF: loadingPDF(state),
    loadingStatement: loadingStatement(state),
  };
};

export default connect(mapStateToProps, null)(StatementsTab);

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import _ from "lodash";
import ReactGA from 'react-ga'
import Hamburger from "../../../images/hamburger.svg";

const ModalFooter = styled.div`
  text-align: center;
  padding: 12px;
`;
const ModalHeader = styled(Modal.Header)`
  padding: 12px;
  text-align: center;
  color: #565551;
  font-family: "Gotham-Medium";
  display: unset;
  border-bottom: unset;
  font-size: 20px;
  text-transform: uppercase;
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: unset;
  }
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  align-items: center;
`;

const CardDiv = styled.div`
  padding: 12px;
  background-color: #fafaf9;
  cursor: pointer;
  display: grid;
  grid-template-columns: 10% 90%;
  text-align: center;
  margin-bottom: 15px;
`;
const CardHeader = styled.div`
  color: #ff5f00;
  font-family: "Gotham-Medium";
  font-size: 18px;
`;
const CardLeft = styled.div`
  height: 100px;
  line-height: 100px;
`;
const CardRight = styled.div``;

const CardBody = styled.div`
  font-size: 18px;
  color: #565551;
  min-height: 50px;
  font-family: "Gotham-Medium";
  line-height: 6;
`;

const Card = ({ id, text, bodyText, index, moveCard, cards }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id, index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        cards.forEach((c, i) => {
          c.sequence = i;
        });
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <CardDiv ref={ref}>
      <CardLeft>
        <img src={Hamburger} />
      </CardLeft>
      <CardHeader>
        {/* {text} */}
        <CardBody>{text}</CardBody>
      </CardHeader>
    </CardDiv>
  );
};

const CustomizeModal = props => {
  const { grid, updateGrid, page } = props;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [cards, setCards] = useState(_.orderBy(grid, ["sequence"], ["asc"]));

  useEffect(() => {
    if (grid) {
      setCards(grid);
    }
  }, [grid]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      );
    },
    [cards]
  );

  const renderCard = (card, index) => {
    return (
      <Col lg={4} key={card.sfid}>
        <Card
          index={index}
          id={card.sfid}
          text={card.linkText || card.staticType}
          bodyText={card.bodyText}
          moveCard={moveCard}
          cards={cards}
        />
      </Col>
    );
  };

  const renderCards = () => {
    if (cards) {
      return (
        <Container>
          <Row>
            {cards.map((card, i) => renderCard(card, i))}
          </Row>
        </Container>
      );
    }
  };

  const updateCards = () => {
    updateGrid(cards);
    handleClose();
    ReactGA.event({
      category: 'Home',
      action: 'Customized Dashboard'
    });
  };

  return (
    <React.Fragment>
      <button
        className="sav-btn__orange--text"
        style={{ textAlign: "right", padding: 0 }}
        onClick={() => handleShow()}
      >
        Customize
      </button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <ModalHeader closeButton>{page} Customization</ModalHeader>
        <Modal.Body>
          <DndProvider backend={Backend}>{renderCards()}</DndProvider>
        </Modal.Body>
        <ModalFooter>
          <button className="sav-btn__gray" onClick={handleClose}>
            Close
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button className="sav-btn__orange--fill" onClick={() => updateCards()}>
            Save Changes
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CustomizeModal;
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container, Collapse, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import ReactCountryFlag from "react-country-flag";
import SavCheck from "../../../../components/SavCheck";
import SavDropFilter from "../../../../components/SavDropFilter";
import SavToast from "../../../../components/SavToast";
import Countries from "../../../../components/Utilities/countries";
import {fetchAddresses, updateAddress, setSuccessToast, deleteAddress, createAddress, createTask} from "../../../../actions";
import SavFormInput from "../../../../components/SavFormInput";
import SavButtonLoader from "../../../../components/SavButtonLoader";

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck bigCheck checked={props.checked} click={props.click} form center  />
  )
});

const SavDropFilterForm = React.forwardRef((props, ref) => {
  return (
    <SavDropFilter val={props.val} opts={props.opts} click={props.click} clickKey="code" />
  )
});
const hidden = {
  display: 'none'
};
const NewAddressModal = ({createAddress, setSuccessToast, isNDD, createTask, isInternational}) => {

  const [showNewModal, setShowNewModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [addressStreet, setAddressStreet] = useState('');
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressCountry, setAddressCountry] = useState('');
  const [addressCompanyName, setAddressCompanyName] = useState('');
  const [addressPhone, setAddressPhone] = useState("");
  const [addressEmail, setAddressEmail] = useState("");
  const [addressContactLast, setAddressContactLast] = useState("");
  const [addressContactFirst, setAddressContactFirst] = useState("");
  const [showRoom, setShowRoom] = useState(false);
  const [signatureRequired, setSignatureRequired] = useState(false);
  const [savantListing, setSavantListing] = useState(false);
  const [savantListingName, setSavantListingName] = useState("");
  const [savantListingPhone, setSavantListingPhone] = useState("");
  const [diffContact, setDiffContact] = useState(false);

  useEffect(() => {
    setAddressStreet("");
    setAddressCity("");
    setAddressState("");
    setAddressZip("");
    setAddressCountry('US');
    setAddressPhone('');
    setAddressEmail('');
    setAddressContactLast('');
    setAddressContactFirst('');
    setShowRoom(false);
    setSignatureRequired(false);
    if (isNDD){
      setSavantListing(true);
    } else {
      setSavantListing(false);
    }
    setSavantListingName('');
    setSavantListingPhone('');
    setDiffContact(false);
    setValidated(false);
  },[showNewModal])

  const handleSubmitNewAddress = (event) => {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }    
    event.preventDefault();
    event.stopPropagation();

    const addrJSON = {};
    addrJSON.addressStreet = addressStreet;
    addrJSON.addressCity = addressCity;
    addrJSON.addressState = addressState;
    addrJSON.addressZip = addressZip;
    addrJSON.addressCountry = addressCountry;
    addrJSON.companyNameOverride = addressCompanyName;
    addrJSON.phoneOverride = addressPhone;
    addrJSON.emailOverride = addressEmail;
    addrJSON.overrideLastName = addressContactLast;
    addrJSON.overrideFirstName = addressContactFirst;
    addrJSON.showroom = showRoom;
    addrJSON.signatureRequired = signatureRequired;
    if (isNDD){
      addrJSON.isSavantListing = true;
      addrJSON.pendingFindaDealerRequest = true;
    } else {
      addrJSON.isSavantListing = savantListing;
      if (savantListing){
        addrJSON.pendingFindaDealerRequest = true;
      }
    }
    
    addrJSON.savantListingNameOverride = savantListingName;
    addrJSON.savantListingPhoneOverride = savantListingPhone;
    addrJSON.noLongerUsed = false;
    addrJSON.alwaysTax = false;

    
    createAddress(addrJSON).then((res) => {
      setShowNewModal(false);
      setSuccessToast(true);
      if (res.success && addrJSON.isSavantListing){
        const taskJSON = {
          taskType: `Find a Dealer Listing Address Update for ${addrJSON.addressStreet.slice(0,200)}`,
          comments: `User requests address: https://savantsystems.my.salesforce.com/${res.payload} be added to their Find a Dealer listing`
        };
        createTask(taskJSON);
      }
    })
  }

  const renderSavantListingFields = () => {
      return (
        <Fragment>
            <Form.Group as={Col} lg={5}>
              <SavFormInput disabled={!savantListing} notRequired value={savantListingName} setValue={setSavantListingName} label="Displayed Name"/>
            </Form.Group>
            <Form.Group as={Col} lg={5}>
              <SavFormInput disabled={!savantListing} notRequired value={savantListingPhone} setValue={setSavantListingPhone} label="Displayed Phone"/>
            </Form.Group>
        </Fragment>
      )
  }
  const renderContactOverrideFields = () => {
    if (diffContact) {
      return (
        <Fragment>
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <SavFormInput validate value={addressContactFirst} setValue={setAddressContactFirst} label="First Name"/>
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={addressContactLast} setValue={setAddressContactLast} label="Last Name"/>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={addressEmail} setValue={setAddressEmail} label="Email"/>
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <SavFormInput value={addressPhone} setValue={setAddressPhone} label="Phone"/>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <SavFormInput value={addressCompanyName} setValue={setAddressCompanyName} label="Company"/>
            </Form.Group>
          </Form.Row>
        </Fragment>
      )
    }
    return null
  }

  return (
    <Fragment>
      <button style={{padding: 0, fontSize:30, width:30, lineHeight:1}} className="sav-btn__orange--text" onClick={() => setShowNewModal(true)}>+</button>
      <Modal show={showNewModal} onHide={() =>setShowNewModal(false)} animation={false} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{margin: "0 auto", width:"65%"}}>
            <Form onSubmit={handleSubmitNewAddress} noValidate validated={validated} className="sav-form">
              <Form.Row style={isNDD ? hidden : null}>
                <Form.Group as={Col} xs={12} className="text-center">
                  <Form.Label>Different contact than default?</Form.Label>
                  <Form.Control as={SavCheckForm} checked={diffContact} click={() => setDiffContact(!diffContact)} />
                </Form.Group>
              </Form.Row>
              {renderContactOverrideFields()}
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                 <SavFormInput value={addressStreet} setValue={setAddressStreet} label="Street Address"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg={4}>
                  <SavFormInput value={addressCity} setValue={setAddressCity} label="City"/>
                </Form.Group>
                <Form.Group as={Col} lg={4}>
                  <SavFormInput notRequired={addressCountry !== 'US' && addressCountry !== 'CA' && isNDD ? true : false} value={addressState} setValue={setAddressState} label="State/Province"/>
                </Form.Group>
                <Form.Group as={Col} lg={4}>
                  <SavFormInput notRequired={addressCountry !== 'US' && addressCountry !== 'CA' && isNDD ? true : false} value={addressZip} setValue={setAddressZip} label="Zip/Postal Code"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Country</Form.Label>
                  <Form.Control as={SavDropFilterForm}  val={addressCountry} opts={Countries} click={setAddressCountry} />
                </Form.Group>
                <Form.Group as={Col} lg={3} md={6} className="text-center">
                  <Form.Label>Showroom</Form.Label>
                  <Form.Control as={SavCheckForm} checked={showRoom} click={() => setShowRoom(!showRoom)} />
                </Form.Group>
                <Form.Group as={Col} lg={3} md={6} className="text-center" style={isNDD ? hidden : null}>
                  <Form.Label>Signature Req</Form.Label>
                  <Form.Control as={SavCheckForm} checked={signatureRequired} click={() => setSignatureRequired(!signatureRequired)} />
                </Form.Group>
              </Form.Row>

              <Form.Row style={{ alignItems: 'flex-end' }}>
                <Form.Group as={Col} md={2} className="text-center">
                  <Form.Label>Savant.com</Form.Label>
                  <Form.Control as={SavCheckForm} checked={savantListing} click={() => setSavantListing(!savantListing)} />
                </Form.Group>
                {renderSavantListingFields()}
              </Form.Row>
              <div className="book text-center f12 light-gray" style={!savantListing ? {display: 'none'} : null}>
                {isNDD ? "A request to show this listing on savant.com will be initiated with your distributor. Please allow 5 business days for changes to take into effect." : "This address may take time to display on savant.com."}
              </div>
              
              <div className="m-t-20 text-center">
                <button className="sav-btn__gray m-t-10" onClick={() => setShowNewModal(false)}>
                  Close
                </button>
                <SavButtonLoader
                  className="sav-btn__orange--fill m-t-10 m-l-5"
                  type="submit"
                />
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default connect(null, {fetchAddresses, updateAddress, setSuccessToast, deleteAddress, createAddress, createTask})(NewAddressModal);
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Dropdown, Table } from "react-bootstrap";
import { useRouteMatch, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { MdHelp } from "react-icons/md";
import _ from "lodash";
import SavDrop from "../../components/SavDrop";
import SavRadio from "../../components/SavRadio";
import SavCheck from "../../components/SavCheck";
import {
  SubHeader,
  SavSearch,
  SavRow,
  SavSpanName,
  SavLink,
  SavLabel,
  SavInput,
  SavInput2,
  SavInputDisabled,
} from "../../components/Styled";

const SavModalHeader = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Gotham-Medium";
  text-align: center;
`;
const SavModalClose = styled.div`
  font-size: 36px;
  padding-top: 15px;
  padding-right: 15px;
  text-align: right;
  color: #979797;
  line-height: 0.5;
  cursor: pointer;
  position: absolute;
  font-family: "Gotham-Light";
  right: 0;
`;
const ButtonDiv = styled.div`
  text-align: center;
  margin-top: 3rem;
`;
const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  background-color: #3f3f3f;
`;
const ProgressDiv = styled.div`
  background-color:${(props) => (props.bg ? props.bg : "#979797")}
  text-align:center;
  color: ${(props) => (props.color ? props.color : "#fff")}
  font-size:8px;
  border-radius:3px;
  width: ${(props) => (1 / props.totalSteps) * 100}%;
  border-bottom: ${(props) => (props.border ? props.border : "none")};
  padding:20px;
`;
const StepDiv = styled.div`
  font-family: "Gotham-Medium";
  font-size: 12px;
  color: #fff;
`;
const StepText = styled.div`
  font-family: "Gotham-Book";
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
`;
const SavLabel2 = styled(SavLabel)`
  font-family: "Gotham-Medium";
  font-size: 11px;
  color: #3e3f3e;
  margin-top: 10px;
`;
const ModalPageContainer = styled(Container)`
  min-height: 300px;
`;
const SavTextArea = styled.textarea`
  font-family: "Gotham-Medium";
  width: 100%;
  height: 240px;
  &:focus {
    border: none;
    outline: 2px solid #ff5f00 !important;
  }
`;

const dropStyle = {
  backgroundColor: "#fff",
  border: "1px solid #979797",
  padding: 4,
};

const CreateCaseModal = (props) => {
  const { showModal, setShowModal, user } = props;
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);
  const [caseType, setCaseType] = useState("");
  const [contactMethod, setContactMethod] = useState("Phone");
  const [issueType, setIssueType] = useState("");
  const [beta, setBeta] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [software, setSoftware] = useState("");
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [newOrExisting, setNewOrExisting] = useState("");
  const [appUse, setAppUse] = useState("");
  const [uiUse, setUiUse] = useState("");
  const [affectedHardware, setAffectedHardware] = useState("");
  const [thirdPartyHelp, setThirdPartyHelp] = useState("");
  const [controlType, setControlType] = useState("");
  const [profileCategory, setProfileCategory] = useState("");

  const projectTypeOptions = [
    "Residential",
    "Commercial",
    "Demo/Showroom",
    "Test Bench",
  ];
  const softwareOptions = [
    "Racepoint Blueprint",
    "Savant Studio App",
    "Not Applicable",
  ];
  const versionOptions = [
    "9.3.1 Smart",
    "9.3.1 Pro",
    "9.3 Smart",
    "9.3 Pro",
    "9.2.2 Smart",
    "9.2.2 Pro",
    "9.2 Smart",
    "9.2 Pro",
    "9.1.3 Smart",
    "9.1.3 Pro",
    "9.1.1 Smart",
    "9.1.1 Pro",
    "9.1 Smart",
    "9.1 Pro",
    "9.0.2 Smart",
    "9.0.2 Pro",
    "9.0 Smart",
    "9.0 Pro",
    "8.10.2 Smart",
    "8.10.2 Pro",
    "8.10.1 Smart",
    "8.10.1 Pro",
    "8.10 Smart",
    "8.10 Pro",
    "8.9.2 Smart",
    "8.9.2 Pro",
    "8.9.1 Smart",
    "8.9.1 Pro",
    "8.9 Smart",
    "8.9 Pro",
    "8.8.3 Smart",
    "8.8.3 Pro",
    "8.8.2 Smart",
    "8.8.2 Pro",
    "8.8 Smart",
    "8.8 Pro",
    "8.7.1 Smart",
    "8.7.1 Pro",
    "8.7 Smart",
    "8.7 Pro",
    "8.6 Smart",
    "8.6 Pro",
    "8.6 Simple",
    "8.5 Smart",
    "8.5 Pro",
    "8.5 Simple",
    "8.4 Smart",
    "8.4 Pro",
    "8.4 Simple",
    "8.3 Smart",
    "8.3 Pro",
    "8.3 Simple",
    "8.2.1 Smart",
    "8.2.1 Pro",
    "8.2 Smart",
    "8.2 Pro",
    "8.1 Smart",
    "8.1 Pro",
    "8.0.2 Smart",
    "8.0.2 Pro",
    "8.0.1 Smart",
    "8.0.1 Pro",
    "8.0 Smart",
    "8.0 Pro",
  ];
  const appOptions = [
    "Savant Pro (Android)",
    "Savant Pro (iOS)",
    "Savant Studio App",
    "TrueControl II",
    "Savant Lighting TrueImage",
    "SmartConnect",
    "Climate Config",
    "Not Applicable/Other",
  ];
  const uiOptions = [
    "CLI-W200/W210",
    'ITP 5"/8"',
    "REM-1000/1100/2000",
    "REM-4000",
    "Savant Button Remote",
  ];
  const thirdPartyOptions = [
    "Profile for new device not in Savant Library",
    "Feature Request/enhancement for device in Savant Library",
    "Troubleshooting existing profile in Savant Library",
    "Lighting fixture evaluation/testing",
  ];
  const controlTypeOptions = [
    "IR",
    "IP",
    "RS232",
    "RS422/485",
    "Trigger",
    "Contact Closure",
    "Serial PWM",
  ];
  const profileCategoryOptions = [
    "A/V Receiver",
    "A/V Switch/Matrix",
    "Amp",
    "Appliance",
    "Audio Mixer",
    "BACnet",
    "Blu-ray/DVD/CD PLayer",
    "Cable/Sat Box (with or without DVR)",
    "Conferencing System A/V",
    "Display/Projector",
    "Door Station/Keypad",
    "Generic Profile Question",
    "HVAC System",
    "Irrigation",
    "KNX",
    "Lighting System",
    "Media Converter/Scaler",
    "Media Player",
    "Motor/Relay/Lift",
    "Other",
    "Pool/Spa Controller",
    "Power Monitor/Energy Monitor",
    "UPS/Smart Power Strip",
    "Radio Tuner",
    "Security Camera",
    "Security DVR",
    "Security System",
    "Shades",
    "Smart Board/Interactive Display",
    "Speakers/Sound Bar",
    "Weather",
    "Z-Wave",
  ];

  const handleNext = () => {
    if (step + 1 <= totalSteps) {
      setStep(step + 1);
    }
  };
  const handleBack = () => {
    if (step - 1 !== 0) {
      setStep(step - 1);
    }
  };
  const handleClose = () => {
    setStep(1);
    setShowModal(false);
  };
  const handleSumbit = () => {
    setShowModal(false);
    setCaseType("");
    setAffectedHardware("");
    setAppUse("");
    setBeta(false);
    setContactMethod("");
    setIssueType("");
    setNewOrExisting("");
    setProjectType("");
    setSoftware("");
    setSoftwareVersion("");
    setUiUse("");
    setStep(1);
  };
  const renderStepText = (currentStep) => {
    if (currentStep === 1) {
      return "Contact Information";
    }
    if (currentStep === 2) {
      return "Essential Information";
    }
    if (currentStep === 3) {
      return "Additional Information";
    }
  };
  const renderProgress = () => {
    return (
      <ProgressBar>
        {_.times(totalSteps, (i) => {
          return (
            <ProgressDiv
              key={i}
              bg="#3f3f3f"
              border={step === i + 1 ? "5px solid #ff5f00" : null}
              color={step >= i + 1 ? "#ff5f00" : "#fff"}
              totalSteps={totalSteps}
            >
              <StepDiv>STEP {i + 1}</StepDiv>
              <StepText>{renderStepText(i + 1)}</StepText>
            </ProgressDiv>
          );
        })}
      </ProgressBar>
    );
  };
  const renderPage2 = () => {
    if (issueType === "Software") {
      return (
        <ModalPageContainer>
          <div className="med f14">ESSENTIAL INFORMATION</div>
          <div className="f12 book">
            Please provide us with some initial information regarding the
            project. All fields are required. Click NEXT to continue.
          </div>
          <Row>
            <Col sm={6}>
              <SavLabel2>Case Description</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Case Detail</SavLabel2>
              <SavTextArea />
            </Col>
            <Col sm={6}>
              <SavLabel2>Beta</SavLabel2>
              <SavCheck checked={beta} click={() => setBeta(!beta)} />
              <SavLabel2>Host UID</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Programming Software</SavLabel2>
              <SavDrop
                opts={softwareOptions}
                click={setSoftware}
                val={software}
                style={dropStyle}
              />
              <SavLabel2>Programming Software Version</SavLabel2>
              <SavDrop
                opts={versionOptions}
                click={setSoftwareVersion}
                val={softwareVersion}
                style={dropStyle}
              />
              <SavLabel2>Project Name</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Project Type</SavLabel2>
              <SavDrop
                opts={projectTypeOptions}
                click={setProjectType}
                val={projectType}
                style={dropStyle}
              />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    if (issueType === "Hardware") {
      return (
        <ModalPageContainer>
          <div className="med f14">ESSENTIAL INFORMATION</div>
          <div className="f12 book">
            Please provide us with some initial information regarding the
            project. All fields are required. Click NEXT to continue.
          </div>
          <Row>
            <Col sm={6}>
              <SavLabel2>Case Description</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Case Detail</SavLabel2>
              <SavTextArea />
            </Col>
            <Col sm={6}>
              <SavLabel2>Beta</SavLabel2>
              <SavCheck checked={beta} click={() => setBeta(!beta)} />
              <SavLabel2>Host UID</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Programming Software</SavLabel2>
              <SavDrop
                opts={softwareOptions}
                click={setSoftware}
                val={software}
                style={dropStyle}
              />
              <SavLabel2>Programming Software Version</SavLabel2>
              <SavDrop
                opts={versionOptions}
                click={setSoftwareVersion}
                val={softwareVersion}
                style={dropStyle}
              />
              <SavLabel2>Project Name</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Project Type</SavLabel2>
              <SavDrop
                opts={projectTypeOptions}
                click={setProjectType}
                val={projectType}
                style={dropStyle}
              />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    if (issueType === "3rd Party or Profile") {
      return (
        <ModalPageContainer>
          <div className="med f14">ESSENTIAL INFORMATION</div>
          <div className="f12 book">
            Please provide us with some initial information regarding the
            project. All fields are required. Click NEXT to continue.
          </div>
          <Row>
            <Col sm={6}>
              <SavLabel2>Case Description</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Case Detail</SavLabel2>
              <SavTextArea />
            </Col>
            <Col sm={6}>
              <SavLabel2>Beta</SavLabel2>
              <SavCheck checked={beta} click={() => setBeta(!beta)} />
              <SavLabel2>Host UID</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Programming Software</SavLabel2>
              <SavDrop
                opts={softwareOptions}
                click={setSoftware}
                val={software}
                style={dropStyle}
              />
              <SavLabel2>Programming Software Version</SavLabel2>
              <SavDrop
                opts={versionOptions}
                click={setSoftwareVersion}
                val={softwareVersion}
                style={dropStyle}
              />
              <SavLabel2>Project Name</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Project Type</SavLabel2>
              <SavDrop
                opts={projectTypeOptions}
                click={setProjectType}
                val={projectType}
                style={dropStyle}
              />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    if (issueType === "Basic Contact") {
      return (
        <ModalPageContainer>
          <div className="med f14">ESSENTIAL INFORMATION</div>
          <div className="f12 book">
            Please provide us with some initial information regarding the
            project. All fields are required. Click NEXT to continue.
          </div>
          <Row>
            <Col sm={6}>
              <SavLabel2>Case Description</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Case Detail</SavLabel2>
              <SavTextArea />
            </Col>
            <Col sm={6}>
              <SavLabel2>Host UID</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Programming Software</SavLabel2>
              <SavDrop
                opts={softwareOptions}
                click={setSoftware}
                val={software}
                style={dropStyle}
              />
              <SavLabel2>Programming Software Version</SavLabel2>
              <SavDrop
                opts={versionOptions}
                click={setSoftwareVersion}
                val={softwareVersion}
                style={dropStyle}
              />
              <SavLabel2>Project Name</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>Project Type</SavLabel2>
              <SavDrop
                opts={projectTypeOptions}
                click={setProjectType}
                val={projectType}
                style={dropStyle}
              />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    return null;
  };
  const renderPage3 = () => {
    const radioOptions = [
      {
        text: "New Install",
        checked: newOrExisting === "New Install",
      },
      {
        text: "Existing Site",
        checked: newOrExisting === "Existing Site",
      },
    ];

    if (
      issueType === "Software" ||
      issueType === "Hardware" ||
      issueType === "Basic Contact"
    ) {
      return (
        <ModalPageContainer>
          <div className="med f14">ADDITIONAL INFORMATION</div>
          <div className="f12 book">
            Providing additional information will allow our team to better
            triage any issues you may be experiencing in the field and make the
            overall support experience more efficient.
            <br />
            <br />
            You may also opt to submit the case immediately, and we can gather
            this information from you when we make contact.
          </div>
          <br />
          <Row>
            <Col sm={6}>
              <SavLabel2>Apps in Use</SavLabel2>
              <SavDrop
                opts={appOptions}
                val={appUse}
                click={setAppUse}
                style={dropStyle}
              />
              <SavLabel2>UI Devices in Use</SavLabel2>
              <SavDrop
                opts={uiOptions}
                val={uiUse}
                click={setUiUse}
                style={dropStyle}
              />
            </Col>
            <Col sm={6}>
              <SavLabel2>Affected Non-Host Hardware Models (if any)</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>New Install or Existing Site?</SavLabel2>
              <SavRadio options={radioOptions} click={setNewOrExisting} />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    if (issueType === "3rd Party or Profile") {
      return (
        <ModalPageContainer>
          <div className="med f14">3RD PARTY INFORMATION</div>
          <div className="f12 book">
            Providing additional information will allow our team to better
            triage any issues you may be experiencing in the field and make the
            overall support experience more efficient.
            <br />
            <br />
            You may also opt to submit the case immediately, and we can gather
            this information from you when we make contact.
          </div>
          <br />
          <Row>
            <Col sm={6}>
              <SavLabel2>Requested 3rd Party Help</SavLabel2>
              <SavDrop
                opts={thirdPartyOptions}
                val={thirdPartyHelp}
                click={setThirdPartyHelp}
                style={dropStyle}
              />
              <SavLabel2>Control Type</SavLabel2>
              <SavDrop
                opts={controlTypeOptions}
                val={controlType}
                click={setControlType}
                style={dropStyle}
              />
              <SavLabel2>Profile Category</SavLabel2>
              <SavDrop
                opts={profileCategoryOptions}
                val={profileCategory}
                click={setProfileCategory}
                style={dropStyle}
              />
              <SavLabel2>3rd Party Manufacturer</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>3rd Party Model Number</SavLabel2>
              <SavInput></SavInput>
            </Col>
            <Col sm={6}>
              <SavLabel2>Apps in Use</SavLabel2>
              <SavDrop
                opts={appOptions}
                val={appUse}
                click={setAppUse}
                style={dropStyle}
              />
              <SavLabel2>UI Devices in Use</SavLabel2>
              <SavDrop
                opts={uiOptions}
                val={uiUse}
                click={setUiUse}
                style={dropStyle}
              />
              <SavLabel2>Affected Non-Host Hardware Models (if any)</SavLabel2>
              <SavInput></SavInput>
              <SavLabel2>New Install or Existing Site?</SavLabel2>
              <SavRadio options={radioOptions} click={setNewOrExisting} />
            </Col>
          </Row>
        </ModalPageContainer>
      );
    }
    return null;
  };

  const renderPages = () => {
    if (!_.isEmpty(user)) {
      switch (step) {
        case 1:
          const radioOptions = [
            {
              text: "Phone",
              checked: contactMethod === "Phone",
            },
            {
              text: "Email",
              checked: contactMethod === "Email",
            },
          ];
          const issueOptions = [
            "Software",
            "Hardware",
            "3rd Party or Profile",
            "Basic Contact",
          ];
          return (
            <ModalPageContainer>
              <div className="med f14">CONTACT INFORMATION</div>
              <div className="f12 book">
                Before we start, please review your contact information below.
                If it is incorrect, please edit and save before continuing onto
                the next screen.
              </div>
              <ReactTooltip multiline={true} className="tooltip-issuetype" />
              <Row>
                <Col sm={6}>
                  <SavLabel2>Contact</SavLabel2>
                  <SavInput value={user.contact.name}></SavInput>
                  <SavLabel2>Contact Email</SavLabel2>
                  <SavInput value={user.contact.email}></SavInput>
                  <SavLabel2>Contact Phone</SavLabel2>
                  <SavInput value={user.contact.phone}></SavInput>
                  <SavLabel2>Issue Type</SavLabel2>
                  <SavDrop
                    opts={issueOptions}
                    val={issueType}
                    click={setIssueType}
                    style={dropStyle}
                  />
                </Col>
                <Col sm={6}>
                  <SavLabel2>Account</SavLabel2>
                  <SavInputDisabled value="Avix"></SavInputDisabled>
                  <SavLabel2>
                    Preferred Email&nbsp;&nbsp;
                    <MdHelp data-tip="If different, Savant Support will use this email to contact you for this case" />
                  </SavLabel2>
                  <SavInput placeholder={user.contact.email}></SavInput>
                  <SavLabel2>
                    Preferred Phone&nbsp;&nbsp;
                    <MdHelp data-tip="If different, Savant Support will use this phone to contact you for this case" />
                  </SavLabel2>
                  <SavInput placeholder={user.contact.phone}></SavInput>

                  <SavLabel2>
                    Preferred Contact Method&nbsp;&nbsp;
                    <MdHelp
                      style={{ color: "#fff" }}
                      data-tip="Savant Support will contact you via the method selected"
                    />
                  </SavLabel2>
                  <SavRadio options={radioOptions} click={setContactMethod} />
                </Col>
              </Row>
            </ModalPageContainer>
          );
        case 2:
          return renderPage2();
        case 3:
          return renderPage3();
        default:
          return null;
      }
    }
    return null;
  };

  const renderBackButton = () => {
    if (step !== 1) {
      return (
        <button className="sav-btn__gray" onClick={() => handleBack()}>
          Back
        </button>
      );
    } else {
      return null;
    }
  };
  const renderSubmitButton = () => {
    if (step === 3) {
      return (
        <button className="sav-btn__orange--fill" onClick={() => handleSumbit()}>
          Submit Now
        </button>
      );
    }
    return null;
  };
  const renderNextButton = () => {
    if (step !== 3) {
      return (
        <button
          className="sav-btn__orange"
          onClick={() => handleNext()}
          disabled={issueType === "" ? true : false}
          style={issueType === "" ? { cursor: "not-allowed" } : null}
        >
          Next
        </button>
      );
    }
    return null;
  };

  return (
    <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
      {renderProgress()}
      <SavModalClose onClick={() => handleClose()}>&times;</SavModalClose>
      <SavModalHeader>Create a New Case</SavModalHeader>
      <Modal.Body>
        <Container style={{ padding: "0px 10%" }}>
          {renderPages()}
          <ButtonDiv>
            <button className="sav-btn__gray" onClick={() => handleClose()}>
              Cancel
            </button>
            &nbsp;&nbsp;
            {renderBackButton()}
            &nbsp;&nbsp;
            {renderNextButton()}
            {renderSubmitButton()}
          </ButtonDiv>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default CreateCaseModal;

import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Image, Modal, Carousel} from "react-bootstrap";
import { connect } from "react-redux";
import { HashLink as Link } from 'react-router-hash-link';
import styled from "styled-components";
import _ from "lodash";
// import moment from "moment";
import { fetchTradeshow, fetchUser, fetchAccount, fetchContacts, registerSession } from "../../actions";
import {createLoadingSelector} from "../../selectors";
import {BsCalendarPlus} from 'react-icons/bs';
import Truncate from 'react-truncate-html';
import CediaImage from './cediaFloorBig.png';
import SavantCedia from './cediaExpoFinal.png'
import HeroCedia from './heroCedia.png'
import SavToggle from "../../components/SavToggle";
import panelImage from './panels.png';
import trainingImage from './trainingImage.png';
import brewImage from './brew.jpg';
import cedia23hero from './cedia2023.jpg';
import cedia23map from './cedia2023map2.png';

import { save } from 'save-file'
// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  // > img: {
  //   object-fit: cover;
  //   height: 226px;
  // }
`;
const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeroRow = styled(Row)`
  color: #fff;
  background-color: #000;
  font-size: 16px;
  // margin-top: -8px;
  > div {
    color: #fff;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    border: 1px solid #fff;
  }
`;
const SectionHeader = styled.div`
  text-align:center;
  text-transform: uppercase;
  margin-top: 40px;
  font-family: 'Gotham-Medium';
  font-size: 24px;
`;
const DefaultPill = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #979797 ;
  color:#fff;
  border: 1px solid #979797;
  border-radius: 6px;
  line-height:1;
  padding: 6px;
  font-size:10px;
  font-family:"Gotham-Medium";
  width: 100%;
  text-align:center;
  text-transform:uppercase;
`;
const GrayPill = styled(DefaultPill)`
  background-color: #fff;
  color:#696057;
  border:1px solid #696057;
`;
const GreenPill = styled(DefaultPill)`
  background-color:#00c7cc;
  color:#fff;
  border:1px solid #00c7cc;
`;
const YellowPill = styled(DefaultPill)`
  background-color:#00c7cc;
  color:#fff;
  border:1px solid #00c7cc;
`;
const RedPill = styled(DefaultPill)`
  background-color:#d0021b;
  color:#fff;
  border:1px solid #d0021b;
`;
const OrangePill = styled(DefaultPill)`
  background-color:#ff5f00;
  color:#fff;
  border:1px solid #ff5f00;
`;
const BluePill = styled(DefaultPill)`
  background-color:#00c7cc;
  color:#fff;
  border:1px solid #00c7cc;
`;
const BlackPill = styled(DefaultPill)`
  background-color:#696057;
  color:#fff;
  border:1px solid #696057;
`;
const CarouselImage = styled.img`
  object-fit: cover;
  height: 250px;
  border: .5px solid #fff;
`;
const CarouselText = styled.div`
  border: .5px solid #fff;
  padding: 10px;
  text-align: center;
  background-color: #000;
  min-height: 170px;
  display:flex;
  align-items:baseline;
  & div {
    color: #fff;
  }
  font-size: 14px;
`;
const hidden = {
  display: 'none'
};
const AgendaRow = styled(Row)`
  border: 1px solid #b4b4b4;
  border-radius: 4px;
  padding: 10px;
  align-items: center;
`;
const ics = require("ics");
var moment = require('moment-timezone');


const Tradeshow = ({fetchTradeshow, tradeshow, account, user, contacts, fetchAccount, fetchContacts, loadingRegister, registerSession}) => {
  const [dates, setDates] = useState([]);
  const [agendaDay, setAgendaDay] = useState(1);
  const [sessionIds, setSessionIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const REF = localStorage.getItem("ref");

  useEffect(() => {
    if (_.isEmpty(tradeshow)){
      fetchTradeshow('cedia2022');
    } else {
      const dateList = [];
      const idList = [];
      tradeshow.sessions.forEach((sesh) => {
        if (sesh.isRegistered){
          idList.push(sesh.sfid);
        }
        if (!dateList.includes(sesh.startDate)){
          dateList.push(sesh.startDate);
        }
      });
      setSessionIds(idList);
      setDates(dateList);
      setAgendaDay(dateList[0]);
    }
  },[tradeshow]);

  useEffect(() => {
    if (_.isEmpty(contacts)) {
      fetchContacts();
    }
  }, [contacts]);

  useEffect(() => {
    if (_.isEmpty(account)) {
      fetchAccount();
    }
  }, [account]);

  const handleClickSession = (sfid) => {
    registerSession(tradeshow.sfid, sfid, 'cedia2022');
  };
  const exportIcal = (sesh) => {
    let relativeUrl;
    if (REF.includes('int-savantsystems') || REF.includes('uat-savantsystems')){
      relativeUrl = "https://uat-community.savant.com/cedia";
    } else {
      relativeUrl = 'https://beta-community.savant.com/cedia';
    }
    let strStart = moment(sesh.startTime).format("YYYY-MM-DD HH:mm:ss");
    let strEnd = moment(sesh.endTime).format("YYYY-MM-DD HH:mm:ss");
    let startDate = moment.tz(strStart, "America/Chicago").utc();
    let endDate = moment.tz(strEnd, "America/Chicago").utc();
    const year = startDate.get('year');
    const month = startDate.get('month') +1;
    const day = startDate.get('date');
    const hour = startDate.get('hour');
    const minute = startDate.get('minute');
    
    const event = {
      title: sesh.name,
      description: sesh.description,
      location: sesh.location,
      url: relativeUrl,
      start: [year, month, day, hour, minute],
      startInputType: 'utc',
      end: [endDate.year(), endDate.month() + 1, endDate.date(), endDate.hour(), endDate.minutes()],
      endInputType:'utc',
      startOutputType: 'utc',
      endOutputType: 'utc',
      geo: "",
      status: "CONFIRMED",
      busyStatus: "BUSY"
    };
    console.log(event);
    ics.createEvent(event, (error, value) => {
      if (error) {
        console.log(error);
        return;
      }
      save(value, `${sesh.name}/event.ics`);
    });
  };

  const renderModal = () => {
    if (showModal){
      return (
        <Modal className="modal-100w" show={showModal} onHide={ () => setShowModal(false) } size="xl" animation={false} centered style={{width: '100%'}}>
          <Modal.Header closeButton>
            <Modal.Title>
              KAY BAILEY HUTCHISON CONVENTION CENTER
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{cursor: 'zoom-out'}} onClick={() => setShowModal(false)}>
            <img src={cedia23map} width="100%" alt="cedia floor" />
          </Modal.Body>
        </Modal>
      )
    }
    return null;
  }


  const renderHighlights = () => {

    return (
      <Row className="vertical-align">
        <Col lg={4}>
          <div className="border-gray-rounded m-t-10">
            <CarouselImage src={panelImage} className="d-block w-100" />
            <CarouselText>
              <div>
                <div className="f20 text-uppercase m-b-10 med">INDUSTRY PANELS</div>
                <div className="">
                  Join the Savant Team and friends of the industry for important discussions on the future of the smart home.
                </div>
              </div>
            </CarouselText>
          </div>
        </Col>
        <Col lg={4}>
          <div className="border-gray-rounded m-t-10">
            <CarouselImage src={trainingImage} className="d-block w-100" />
            <CarouselText>
              <div>
                <div className="f20 text-uppercase m-b-10 med">LIVE TRAINING</div>
                <div>
                  Register for deep dive sessions on the latest technologies, best practices and new ways to deliver a robust smart home experience.
                </div>
              </div>
            </CarouselText>
          </div>
        </Col>
        <Col lg={4}>
          <div className="border-gray-rounded m-t-10">
            <CarouselImage src={brewImage} className="d-block w-100" />
            <CarouselText>
              <div>
                <div className="f20 text-uppercase m-b-10 med">BREW LOUNGE</div>
                <div>
                  Pull off the floor and chill with us in the Savant Lounge. We’ll be crafting coffee in our booth during the morning and cracking the taps in the afternoon.
                </div>
              </div>
            </CarouselText>
          </div>
        </Col>
      </Row>
      
    )

  };
  const renderAgendaDay = () => {

    const renderTime = (sesh, idx) => {
      const startMoment = moment(sesh.startTime).format("h:mm A");
      const endMoment = moment(sesh.endTime);


      if (idx !== 0){
        const prevStartMoment = moment(tradeshow.sessions[idx-1].startTime).format("h:mm A");

        if (startMoment !== prevStartMoment){
          return (
            <OrangePill
              className="m-b-5"
              style={{maxWidth: 100}}
            >
              {startMoment} CDT
            </OrangePill>
          )
        }
      } else {
        return (
          <OrangePill
            className="m-b-5"
            style={{maxWidth: 100}}
          >
            {startMoment} CDT
          </OrangePill>
        )
      }

    };
    return tradeshow.sessions.map((sesh, idx) => {
      return (
         <Row className="m-t-20" key={sesh.sfid}>
            <Col md={1} style={{paddingLeft: 0, paddingRight: 5}}>
              <DefaultPill
              className="m-b-5"
              style={{maxWidth: 100}}
            >
              {moment(sesh.startTime).format("ddd MMMM Do")}
            </DefaultPill>
            {renderTime(sesh, idx)}
            </Col>
            <Col md={11}>
              <AgendaRow>
                <Col xs={2}>
                  <Image src={sesh.imageUrl} width="100%" />
                </Col>
                <Col xs={10}>
                  <Row>
                    <Col xs={12} md={9} className="med f16 m-b-10">
                      {sesh.name}
                    </Col>
                    <Col md={3} className="med f16 onHov" style={!sesh.showOnHighlights ? {display: 'flex', alignItems: 'flex-start' , justifyContent: 'flex-end'} : hidden}>
                      <span className="book f12 m-r-10" onClick={() => handleClickSession(sesh.sfid)}>Planning to Attend</span>
                      <SavToggle checked={sessionIds.includes(sesh.sfid)} click={() => handleClickSession(sesh.sfid)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={9} className="book f12">
                      <Truncate
                        lines={3}
                        dangerouslySetInnerHTML={{ __html: sesh.description }}
                        />
                    </Col>
                    <Col md={3} className="med f16">
                      <DefaultPill>
                        {sesh.location}
                      </DefaultPill>
                      <BluePill className="m-t-10 onHov" style={sesh.isRegistered || sesh.showOnHighlights ? null : hidden} onClick={() => exportIcal(sesh)}>Add To Calendar&nbsp;&nbsp;<BsCalendarPlus style={{fill: '#fff'}} /></BluePill>
                    </Col>
                  </Row>
                </Col>
              </AgendaRow>
            </Col>
          </Row>
        
      )
    })
  };

  const renderLoader = () => {
    if (loadingRegister){
      return (
        <div className='loaderDiv'>
          <div className='loader'></div>
        </div>
      )
    }
    return null;
  }

  if (!_.isEmpty(tradeshow)){
    return (
      <div>
        {renderLoader()}
        {renderModal()}
        <HeroContainer>
          <Image src={cedia23hero} style={{width: '100%', objectFit: 'cover', maxHeight: 800}} />
          <HeroOverlay>
          <div className="book text-center">
              <div style={{width: '100%', margin: '0px auto 10px'}}>
                <Image src={SavantCedia} fluid />
              </div>
              <div className="wht med text-uppercase f24 m-t-10">
                Live in Denver | Sep 7 - Sep 9
              </div>
              <div className="m-t-10">
                <Link to="#schedule">
                  <button className="sav-btn__orange--fill">Show Schedule</button>
                </Link>
                
              </div>
            </div>
          </HeroOverlay>
        </HeroContainer>

        <Container fluid>
          <HeroRow>
            <Col md={6}>
              <div className="med f32 wht">14</div>
              Live Training Events
            </Col>
            {/* <Col md={4}>
              <div className="med f32 wht">4</div>
              Industry-Moving Panels
            </Col> */}
            <Col md={6}>
              <div className="med f32 wht">2000</div>
               sq.ft. of Innovation
            </Col>
          </HeroRow>
        </Container>

        <Container>
          <Row>
            <Col md={6}>
              <Image src={cedia23map} width="100%" className="m-t-40" style={{cursor:'zoom-in	'}} onClick={() => setShowModal(true)} />
              <div className="med f16 m-t-10 text-center">
                Colorado Convention Center
              </div>
            </Col>
            <Col md={6}>
              <div className="med f24 text-center text-uppercase m-t-40">
                VISIT SAVANT AT BOOTH 2159
              </div>
              <div className="book f16 text-center m-t-20">
                We are counting down to CEDIA EXPO 2023 and excited to celebrate this passion we share for smart home. The momentum has been building all year on exciting new technologies and experiences that you won’t want to miss. Seeing is believing, and have we got a show for you.
                <div className="med f18 text-uppercase m-b-10 m-t-20">COME FOR THE TECH, STAY FOR THE FUN</div>
                Plan to see Savant across the show with unique training events that build your toolkit of smart solutions. Not only that, but we welcome you to kick back and relax at your home away from home, Booth 2159. Grab some coffee and conversation with the Savant Team.
                <br /><br />
                Not registered for CEDIA EXPO yet? Grab a <a className="med oj" target="_blank" rel="noreferrer" href="https://registration.experientevent.com/ShowHTP231?flowcode=ATTENDEE&marketingcode=EXIV840142">free pass </a>and see you in Dallas!
              </div>
            </Col>
          </Row>
      </Container>

      <Container>
        <SectionHeader id="agenda" className="m-t-40 m-b-40">
          Event Highlights
        </SectionHeader>
        {renderHighlights()}

          <SectionHeader id="schedule" className="m-t-40">
            SHOW SCHEDULE
          </SectionHeader>
          <div className="book f16 text-center m-t-20">
            Experience Savant beyond the booth at CEDIA EXPO 2023. Mark your calendar below for panel discussions at the Convention Center, and register for featured trainings right in the convention center on level 1 in rooms 704 and 706. As an added bonus, attendees from each session will qualify to win Savant gear and prizes following the show.
            <br /><br />
            *Note: All times shown are in Mountain Daylight Time (MDT).
          </div>
          {renderAgendaDay()}
      </Container>

      
      </div>
    )
  }
  return <div>loading</div>
}


const loadingRegister = createLoadingSelector(["CREATE_SESSION_REGISTRATION"]);
const mapStateToProps = state => {
  return {
    tradeshow: state.tradeshowSessions.data,
    account: state.account.data,
    user: state.user.data,
    contacts: state.contacts.data,
    loadingRegister: loadingRegister(state),
  };
};
export default connect(mapStateToProps, {fetchTradeshow, fetchAccount, fetchContacts, registerSession})(Tradeshow);
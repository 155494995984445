import { FETCH_PAYMENTS_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const payments = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PAYMENTS_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default payments;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import SavBanner from "../../components/SavBanner";
import SavColumnSort from "../../components/SavColumnSort";
import SavPagination from "../../components/SavPagination";
import SavDrop from "../../components/SavDrop";
import CreateCaseModal from "./CreateCaseModal";
import { SavSearch, SavRow, SavSpanName } from "../../components/Styled";
import { fetchContacts, fetchCases, fetchUser } from "../../actions";

const filterOpts = ["All", "My Cases", "Open Cases", "Closed Cases"];
const Cases = (props) => {
  const { cases, fetchCases, user } = props;
  const [showModal, setShowModal] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const [caseList, setCaseList] = useState([]);
  const [caseFilter, setCaseFilter] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [activeFilter, setActiveFilter] = useState("Open Cases");

  useEffect(() => {
    if (_.isEmpty(cases)) {
      fetchCases();
    }
    if (_.isEmpty(user)) {
      fetchUser();
    }
    setCaseList(cases);
  }, [cases]);

  useEffect(() => {
    if (caseList) {
      let index = 0;
      const tempCases = [];
      for (index = 0; index < caseList.length; index += pageSize) {
        let chunk = caseList.slice(index, index + pageSize);
        tempCases.push(chunk);
      }
      setCaseFilter(tempCases);
    }
  }, [caseList, pageSize]);

  useEffect(() => {
    if (!_.isEmpty(cases)) {
      setCurrentPage(1);

      let tempCases = cases.filter((c) =>
        String(c.subject).includes(searchString)
      );

      setCaseList(tempCases);
    }

    if (searchString === "") {
      setCaseList(cases);
    }
  }, [searchString]);

  useEffect(() => {
    if (!_.isEmpty(cases)) {
      if (activeFilter !== "All") {
        if (activeFilter === "Open Cases") {
          let tempCases = cases.filter(
            (c) => c.status !== "Closed" && c.status !== "Solution Suggested"
          );
          setCaseList(tempCases);
        } else {
          let tempCases = cases.filter(
            (c) => c.status === "Closed" || c.status === "Solution Suggested"
          );
          setCaseList(tempCases);
        }
      } else {
        setCaseList(cases);
      }
    }
  }, [activeFilter, cases]);

  const renderCases = () => {
    if (!_.isEmpty(caseFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > caseFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return caseFilter[page].map((c) => {
        const date = new Date(
          JSON.parse(JSON.stringify(c.created))
        ).toLocaleString();
        return (
          <SavRow key={c.sfid}>
            <Col xs={3} sm={2}>
              <SavSpanName className="truncate">
                <Link to={`/cases/${c.sfid}`}>{c.caseNumber}</Link>
              </SavSpanName>
            </Col>
            <Col xs={3} sm={3} className="truncate">
              {c.subject}
            </Col>
            <Col sm={2} className="truncate d-sm-block d-none">
              {c.type}
            </Col>
            <Col xs={3} sm={2} className="truncate">
              {c.status}
            </Col>
            <Col xs={3} sm={3} className="truncate">
              {date}
            </Col>
          </SavRow>
        );
      });
    }
  };

  if (!_.isEmpty(caseList)) {
    return (
      <div>
        <SavBanner text="My Cases" padding="40px" />
        <CreateCaseModal
          showModal={showModal}
          setShowModal={setShowModal}
          user={user}
        />
        <div style={{ margin: 40, textAlign: "center" }}>
          <button className="sav-btn__orange--fill" onClick={() => setShowModal(true)}>
            Create New Case
          </button>
        </div>
        <Container>
          <Row style={{ alignItems: "flex-end" }}>
            <Col sm={6}>
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Col>
            <Col sm={6}>
              <Row style={{ alignItems: "center" }}>
                <Col
                  xs={2}
                  sm={6}
                  style={{ textAlign: "right", paddingRight: 0 }}
                >
                  <span className="light-gray f12 med">FILTER</span>
                </Col>
                <Col xs={10} sm={6}>
                  <SavDrop
                    opts={filterOpts}
                    val={activeFilter}
                    click={setActiveFilter}
                    style={{
                      border: "1px solid #b4b4b4",
                      backgroundColor: "#fff",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <SavColumnSort
            data={caseList}
            setData={setCaseList}
            columns={[
              { text: "Case Number", size: 2, value: "caseNumber" },
              { text: "Subject", size: 3, value: "subject" },
              { text: "Type", size: 2, value: "type" },
              { text: "Status", size: 2, value: "status" },
              { text: "Created", size: 3, value: "created" },
            ]}
          />
          {renderCases()}
          <SavPagination
            data={caseFilter}
            dataLength={caseList.length}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </div>
    );
  }
  return (
    <div className="loaderDiv">
      <div className="loader"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts,
    cases: state.cases.data,
    user: state.user.data,
  };
};
export default connect(mapStateToProps, {
  fetchContacts,
  fetchCases,
  fetchUser,
})(Cases);

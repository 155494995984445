import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
`;
const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + .slider {
    background-color: #ff5f00;
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #ff5f00;
  }
  &:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
`;
const Slider = styled.span`
  position: absolute;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 17px;
  &:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SavToggle = ({checked, click, disabled}) => {

  return (
    <Switch className="switch">
      <Checkbox
        type="checkbox"
        checked={checked || false}
        readOnly
        onClick={click}
      />
      <Slider className="slider" disabled={disabled}></Slider>
    </Switch>
  )

}
export default SavToggle;


import React, {Fragment} from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Dropdown } from "react-bootstrap";
import {AiOutlineMenu} from "react-icons/ai";
import styled from "styled-components";
import HeaderNotifications from "./HeaderNotifications";
import HeaderSettings from "./HeaderSettings";
import savLogo from "../../images/savLogo.png";
import savLogoWhite from "../../images/savant-white.png";
import {visibilitySelector} from '../../selectors';

const SavNavbar = styled(Container)`
  //background-color: #dbd9d6;
  background: linear-gradient(180deg, #EEEFED 0%, #DCDCDC 100%);
  text-transform: uppercase;
  font-family: "Gotham-Medium";
`;
const DropLink = styled.a`
  font-size: 12px;
  font-family: "Gotham-Book";
  color:#fff;
  cursor:pointer;
  &:hover {
    color:#ff5f00!important;
    text-decoration: none!important;
  }
  >div {
    &:hover {
      color:#ff5f00!important;
      text-decoration: none!important;
    }
  }
`;
const DropLink2 = styled(Link)`
  font-size: 12px;
  font-family: "Gotham-Book";
  color:#fff;
  cursor:pointer;
  &:hover {
    color:#ff5f00!important;
    text-decoration: none!important;
  }
`;
const DropTriangle = styled.div`
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 7px solid #3f3f3f !important;
  border-bottom-color: #3f3f3f !important;
  position: absolute;
  top: -6px;
  left: 10px;
`;
const DropTriangleRight = styled.div`
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 7px solid #3f3f3f !important;
  border-bottom-color: #3f3f3f !important;
  position: absolute;
  top: -6px;
  right: 2px;
`;

const hidden = {
  display: 'none'
}
const inactiveStyle = { padding: 0, marginRight: 20, color: '#fff' };
const activeStyle = { padding: 0, marginRight: 20, color: '#ff5f00' };

const Header = (props) => {
  const { redirect, user, history, auth, location } = props;

  const refURL = localStorage.getItem('ref');
  const SFTOKEN = localStorage.getItem("sfsid");
  const showCases = useSelector(visibilitySelector('cases'));
  const showRma = useSelector(visibilitySelector('rma'));
  const showProfile = useSelector(visibilitySelector('profileInfo'));
  const showTools = useSelector(visibilitySelector('tools_menu'));
  const showKnowledge = useSelector(visibilitySelector('knowledge'));
  const showBeta = useSelector(visibilitySelector('savantBeta'));
  const showOrders = useSelector(visibilitySelector('account_orders'));
  const showContacts = useSelector(visibilitySelector('account_contacts_personnel'));
  const showBilling = useSelector(visibilitySelector('account_billing'));
  const showDocuments = useSelector(visibilitySelector('account_documents'));
  const showDashboard = useSelector(visibilitySelector('account_dashboard'));
  const showWarranty = useSelector(visibilitySelector('account_assets'));
  const showSettings = useSelector(visibilitySelector('account_settings_details_company'));
  const showSoftware = useSelector(visibilitySelector('latestSoftware'));

  if(redirect){
    return <div />;
  }

  if (user && !auth){
    let hideAccount = false;
    let hideTools = false;
    let isWindow = false;
    if (!showDashboard && !showContacts && !showOrders && !showBilling && !showDocuments && !showWarranty && !showSettings){
      hideAccount = true;
    }
    if (user.contact.accType === 'Non-Direct'){
      hideTools = true;
    }
    if (user.contact.accSubType){
      if (user.contact.accSubType.includes('Window')){
        isWindow = true;
      }
    }
    

    const renderNavBar = () => {
      if (location.pathname.includes('/cedia') || location.pathname.includes('/tradeshow-qr')){
        return (
          <SavNavbar fluid style={{background: '#000'}}>
            <Row className="vertical-align" style={{ height: "100%" }}>
              <Col md={4} xs={4}>
                <Link to="/">
                  <Image src={savLogoWhite} height={35} />
                </Link>
              </Col>
              <Col md={4} className="text-center d-none d-md-block">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center'}}>
                  <Dropdown className="savnav-drop">
                    <Dropdown.Toggle as="a" style={{backgroundColor: "#000"}}>
                      <span className="linkstyle" style={{fontSize:14, cursor:'pointer', color:"#fff"}}>HOME</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropTriangle />
                      <Dropdown.Item as={DropLink2}to={`/`}>
                        Home
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/beta`} style={!showBeta ? hidden : null}>
                        Savant Beta
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/knowledge`} style={!showKnowledge ? hidden : null}>
                        Savant Knowledge
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/services`}>
                        Savant Services
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/marketing`}>
                        Sales & Marketing
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/profiles`} style={!showProfile ? hidden : null}>
                        Profiles
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/latestSoftware`} style={!showSoftware ? hidden : null}>
                        Software & Apps
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/support`}>
                        Contact Us
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="savnav-drop">
                    <Dropdown.Toggle as="a" style={{backgroundColor: "#000"}}>
                      <span className="linkstyle" style={{fontSize:14, cursor:'pointer', color:"#fff"}}>TOOLS</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropTriangle />
                      
                      <Dropdown.Item as={DropLink} href="https://central.savant.com">
                        Savant Central Management
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`${refURL}/Customers/SavRedirect?r=combined`}>
                        Lighting & Shade Designer
                      </Dropdown.Item>
                      {/* <Dropdown.Item as={DropLink} href={`${refURL}/Customers/shadeTool`} style={hideTools ? hidden : null}>
                        Shade Designer
                      </Dropdown.Item> */}
                      <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__CCPage?pageKey=myGrilles`}>
                        Grille Designer
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href="https://community.savant.com/Customers/SavRedirect?r=fixture" style={hideTools ? hidden : null}>
                        Fixture Designer
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`${refURL}/Customers/AppointmentScheduling`} target="_blank">
                        Savant Scheduler (Beta)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <a className="linkStyle wht" 
                    href={`https://community.savant.com/Customers/Savant_University?username=${user.username}&sessionid=${SFTOKEN}`}
                    >
                    UNIVERSITY
                  </a>
                  <a className="linkStyle wht" href={`${refURL}/DefaultStore/ccrz__HomePage`}>
                    STORE
                  </a>
                </div>
              </Col>
              <Col md={4} className="d-none d-md-block">
                <div style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }} >
                  <Link
                    className="linkStyle wht"
                    to="/account#overview"
                    style={hideAccount ? hidden : { padding: 0, marginRight: 20 }}
                  >
                    ACCOUNT
                  </Link>
                  <HeaderNotifications history={history} />
                  <HeaderSettings user={user} />
                </div>
              </Col>
              <Col xs={8} className="d-block d-md-none text-right">
                <div className="savFlexBetween">
                <HeaderNotifications history={history} />
                <HeaderSettings user={user} />
                <Dropdown className="savnav-drop">
                  <Dropdown.Toggle as="a" style={{backgroundColor: "#000"}}>
                    <AiOutlineMenu size={30} className="fillHover m-l-10" style={{fill: "#fff"}}  />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropTriangleRight />
                    <Dropdown.Item as={DropLink2}to={`/`}>
                      Home
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2}to={`/account#overview`} style={hideAccount ? hidden : null}>
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/beta`} style={!showBeta ? hidden : null}>
                      Savant Beta
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/knowledge`} style={!showKnowledge ? hidden : null}>
                      Savant Knowledge
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/services`}>
                      Savant Services
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/marketing`}>
                      Sales & Marketing
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/profiles`} style={!showProfile ? hidden : null}>
                      Profiles
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/latestSoftware`} style={!showSoftware ? hidden : null}>
                      Software & Apps
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/support`}>
                      Contact Us
                    </Dropdown.Item>
                    <Dropdown.Header style={showTools ? {borderBottom: "1px solid #979797"}  : hidden}>TOOLS</Dropdown.Header>
                    <Dropdown.Item as={DropLink} href={`${refURL}/Customers/AppointmentScheduling`} style={showTools ? null : hidden}>
                      Savant Scheduler (Beta)
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href="https://central.savant.com" style={showTools ? null : hidden}>
                      Savant Central Management
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/Customers/SavRedirect?r=combined`} style={showTools ? null : hidden}>
                      Lighting & Shade Designer
                    </Dropdown.Item>
                    {/* <Dropdown.Item as={DropLink} href={`${refURL}/Customers/shadeTool`} style={hideTools ? hidden : null}>
                      Shade Designer
                    </Dropdown.Item> */}
                    <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__CCPage?pageKey=myGrilles`} style={showTools ? null : hidden}>
                      Grille Designer
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href="https://community.savant.com/Customers/SavRedirect?r=fixture" style={hideTools ? hidden : null}>
                      Fixture Designer
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} 
                      href={`https://community.savant.com/Customers/Savant_University?username=${user.username}&sessionid=${SFTOKEN}`}>
                      University
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__HomePage`}>
                      Store
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </div>
                
              </Col>
            </Row>
          </SavNavbar>
        )
      } else {
        return (
          <SavNavbar fluid>
            <Row className="vertical-align" style={{ height: "100%" }}>
              <Col md={4} xs={4}>
                <Link to="/">
                  <Image src={savLogo} height={20} />
                </Link>
              </Col>
              <Col md={4} className="text-center d-none d-md-block">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center'}}>
                  <Dropdown className="savnav-drop">
                    <Dropdown.Toggle as="a">
                      <span className="linkstyle" style={{fontSize:14, cursor:'pointer', color:"#3f3f3f"}}>HOME</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropTriangle />
                      <Dropdown.Item as={DropLink2}to={`/`}>
                        Home
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/beta`} style={!showBeta ? hidden : null}>
                        Savant Beta
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/knowledge`} style={!showKnowledge ? hidden : null}>
                        Savant Knowledge
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/services`}>
                        Savant Services
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`https://support.savant.com/power/`} style={showKnowledge ? hidden : null}>
                        Resources
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/marketing`}>
                        Sales & Marketing
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/profiles`} style={!showProfile ? hidden : null}>
                        Profiles
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/latestSoftware`} style={!showSoftware ? hidden : null}>
                        Software & Apps
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/support`}>
                        Contact Us
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="savnav-drop" style={showTools ? null : hidden}>
                    <Dropdown.Toggle as="a">
                      <span className="linkstyle" style={{fontSize:14, cursor:'pointer', color:"#3f3f3f"}}>TOOLS</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <DropTriangle />
                      
                      <Dropdown.Item as={DropLink} href="https://central.savant.com" target="_blank" style={isWindow ? hidden : null}>
                        Savant Central Management
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`${refURL}/Customers/SavRedirect?r=combined`} target="_blank">
                        {isWindow ? 'Shade Designer' : 'Lighting & Shade Designer'}
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__CCPage?pageKey=myGrilles`} target="_blank" style={isWindow ? hidden : null}>
                        Grille Designer
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink2} to={`/marketing/specialty-sales-usai-custom`} style={hideTools || isWindow ? hidden : null}>
                        USAI Fixtures
                      </Dropdown.Item>
                      <Dropdown.Item as={DropLink} href={`${refURL}/Customers/AppointmentScheduling`} target="_blank" style={isWindow ? hidden : null}>
                        Savant Scheduler (Beta)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <a className="linkStyle" 
                    href={`https://community.savant.com/Customers/Savant_University?username=${user.username}&sessionid=${SFTOKEN}`}
                    target="_blank"
                    rel="noreferrer"
                    >
                    UNIVERSITY
                  </a>
                  <a
                    className="linkStyle" href={`${refURL}/DefaultStore/ccrz__HomePage`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    STORE
                  </a>
                </div>
              </Col>
              <Col md={4} className="d-none d-md-block">
                <div style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }} >
                  <Link
                    className="linkStyle"
                    to="/account#overview"
                    style={hideAccount ? hidden : { padding: 0, marginRight: 20 }}
                  >
                    ACCOUNT
                  </Link>
                  <HeaderNotifications history={history} />
                  <HeaderSettings user={user} />
                </div>
              </Col>
              <Col xs={8} className="d-block d-md-none text-right">
                <div className="savFlexBetween">
                <HeaderNotifications history={history} />
                <HeaderSettings user={user} />
                <Dropdown className="savnav-drop">
                  <Dropdown.Toggle as="a">
                    <AiOutlineMenu size={30} className="fillHover m-l-10" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropTriangleRight />
                    <Dropdown.Item as={DropLink2}to={`/`}>
                      Home
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2}to={`/account#overview`} style={hideAccount ? hidden : null}>
                      Account
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/beta`} style={!showBeta ? hidden : null}>
                      Savant Beta
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/knowledge`} style={!showKnowledge ? hidden : null}>
                      Savant Knowledge
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`https://support.savant.com/power/`} style={showKnowledge ? hidden : null}>
                      Resources
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/marketing`}>
                      Sales & Marketing
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/profiles`} style={!showProfile ? hidden : null}>
                      Profiles
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/latestSoftware`} style={!showSoftware ? hidden : null}>
                      Software & Apps
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/support`}>
                      Contact Us
                    </Dropdown.Item>
                    <Dropdown.Header style={showTools ? {borderBottom: "1px solid #979797"} : hidden}>TOOLS</Dropdown.Header>
                    
                    <Dropdown.Item as={DropLink} href="https://central.savant.com" style={showTools && !isWindow ? null : hidden} target="_blank">
                      Savant Central Management
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/Customers/SavRedirect?r=combined`} style={showTools ? null : hidden} target="_blank">
                      {isWindow ? 'Shade Designer' : 'Lighting & Shade Designer'}
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__CCPage?pageKey=myGrilles`} style={showTools && !isWindow ? null : hidden} target="_blank">
                      Grille Designer
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink2} to={`/marketing/specialty-sales-usai-custom`} style={hideTools || isWindow ? hidden : null}>
                     USAI Fixtures
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/Customers/AppointmentScheduling`} style={showTools && !isWindow ? null : hidden} target="_blank">
                      Savant Scheduler (Beta)
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} 
                      href={`https://community.savant.com/Customers/Savant_University?username=${user.username}&sessionid=${SFTOKEN}`} target="_blank">
                      University
                    </Dropdown.Item>
                    <Dropdown.Item as={DropLink} href={`${refURL}/DefaultStore/ccrz__HomePage`} target="_blank">
                      Store
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </div>
                
              </Col>
            </Row>
          </SavNavbar>
        )
      }
    }

    return (
      <Fragment>
        {renderNavBar()}
      </Fragment>
    );
  }
  return (
    <SavNavbar fluid>
      <Row className="vertical-align" style={{ height: "100%" }}>
        <Col md={12} xs={12}>
          <Link to="/">
            <Image src={savLogo} height={20} />
          </Link>
        </Col>
      </Row>
    </SavNavbar>
  );

  
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps, null)(Header);

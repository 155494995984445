import { FETCH_ORDER_PDF_SUCCESS } from "../actions/types";
import { success } from "./utlities";

const activeOrderPDF = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ORDER_PDF_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default activeOrderPDF;
import React from "react";
import styled from "styled-components";
import _ from 'lodash';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label
} from "recharts";
import {parseCurrency, kFormatter} from "../Utilities";

const AnnualPerformance = props => {
  const { account, programResult } = props;

  const date = new Date();
  const year = date.getFullYear();

  let bigMoney = false;
  if (programResult.revenue > 300000 || programResult.revenuePriorYear > 300000){
    bigMoney = true;
  }

  const data = [
    {
      year: programResult.year,
      Sales: programResult.revenue,
    },
    {
      year: programResult.year-1,
      Sales: programResult.revenuePriorYear
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="book f12">
            {payload[0].payload.year} Sales:&nbsp;
          </span>
          <span className="med f12">
            {parseCurrency(payload[0].payload.Sales, 2)}
          </span>
        </div>
      );
    }
    return null;
  };

  const CustomAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x} y={y+4} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan x={x} dy="0.71em" >${kFormatter(payload.value)}</tspan>
      </text>
    )
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" domain={[0, 500000]} tick={false}  />
          <YAxis type="category" dataKey="year" axisLine={false} />
          <Tooltip content={<CustomTooltip />} />

          <Bar
            dataKey="Sales" 
            fill="#565551"
            stackId="a"
            // label={{
            //   fill: "white",
            //   position: "center",
            //   zIndex: 9999,
            //   fontSize: 10
            // }}
          />
          {/* <Bar dataKey="tier" fill="#ff5f00" stackId="a" /> */}
          <ReferenceLine x={50000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value="Authorized"
              offset={5}
              fontSize={12}
              fill="#979797"
            />
            <Label
              position="bottom"
              value="$50k"
              offset={5}
              fontSize={12}
              fill="#979797"
            />
          </ReferenceLine>
          <ReferenceLine x={150000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value="Ambassador"
              offset={5}
              fontSize={12}
              fill="#979797"
              style={bigMoney ? {display: 'none'} : null}
            />
            <Label
              position="bottom"
              value="$150k"
              offset={5}
              fontSize={12}
              fill="#979797"
            />
          </ReferenceLine>
          <ReferenceLine x={300000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value="Elite Ambassador"
              offset={5}
              fontSize={12}
              fill="#979797"
            />
            <Label
              position="bottom"
              value="$300k"
              offset={5}
              fontSize={12}
              fill="#979797"
            />
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default AnnualPerformance;

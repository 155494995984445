import React, { useState, useEffect } from "react";
import { Row, Col, Container, Popover, OverlayTrigger } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { fetchSerials } from "../../../actions";
import SavDatePicker from "../../../components/SavDatePicker";
import SavAlphabetFilter from "../../../components/SavAlphabetFilter";
import SavPagination from "../../../components/SavPagination";
import SavColumnSort from "../../../components/SavColumnSort";
import SavDrop from "../../../components/SavDrop";
import {eliteSearchAlgorithm} from "../../../components/Utilities";
import {
  SubHeader,
  SavSearch,
  SavLink,
  SavLabel,
  SavRow
} from "../../../components/Styled";

const pageOpts = [10, 25, 50, 100];
const padZero = {
  padding: 0
};

const Warranty = props => {
  const { serials, fetchSerials } = props;

  const startDateDefault = new Date(2012, 12, 1);
  const endDateDefault = new Date();

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [serialList, setSerialList] = useState([]);
  const [serialFilter, setSerialFilter] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [alphabetChar, setAlphabetChar] = useState("All");

  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(endDateDefault);

  const refURL = localStorage.getItem("ref");

  useEffect(() => {
    document.title = "Savant Community | Warranty"
  },[])

  // Set serialList state from serial props
  useEffect(() => {
    setSerialList(serials);    
  }, [serials]);

  //When serialList changes, paginate it into serialFilter
  useEffect(() => {
    if (serialList) {
      let index = 0;
      const tempSerials = [];
      for (index = 0; index < serialList.length; index += pageSize) {
        let chunk = serialList.slice(index, index + pageSize);
        tempSerials.push(chunk);
      }
      setSerialFilter(tempSerials);
    }
  }, [serialList, pageSize]);

  //When Start/End Date changes, filter by date
  useEffect(() => {
    if (!_.isEmpty(serials)) {
      const results = serials.filter(
        serial =>
          Date.parse(serial.warrantyStart) >= startDate &&
          Date.parse(serial.warrantyStart) <= endDate
      );
      setSerialList(results);
    }
  }, [serials, startDate, endDate]);

  //When searchString changes, filter serialList by searchString
  useEffect(() => {
    if (!_.isEmpty(serials)) {
      setCurrentPage(1);
      let tempSerials = serials.filter(
        serial => eliteSearchAlgorithm(searchString, serial)
      );
      setSerialList(tempSerials);
    }
    if (searchString === "") {
      setSerialList(serials);
    }
  }, [searchString]);

  //When alphabetChar changes, filter SerialList by alphabetChar
  useEffect(() => {
    if (!_.isEmpty(serials)) {
      if (alphabetChar !== "All") {
        setCurrentPage(1);
        let tempSerials = serials.filter(
          serial =>
            serial.sku.startsWith(alphabetChar) ||
            serial.sku.startsWith(alphabetChar.toUpperCase())
        );
        setSerialList(tempSerials);
      } else {
        setSerialList(serials);
      }
    }
  }, [alphabetChar]);

  const handleClearFilters = () => {
    setAlphabetChar("All");
    setSearchString("");
    setStartDate(startDateDefault);
    setEndDate(endDateDefault);
  };

  const renderSerials = () => {

    const renderLink = (serial) => {
      if (serial.storeQualified) {
        return (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={
              <Popover id={`popover-${serial.sfid}`} className="sav-pop">
                <Popover.Title
                  as="div"
                  className="f12 med bg-white text-center"
                >{`${serial.sku}`}</Popover.Title>
                <Popover.Content>
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#fafaf9",
                      fontSize: 12
                    }}
                  >
                    <img src={serial.img} width={100} />
                    <br />
                    {serial.prodName}
                    <br />
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <SavLink 
            href={`${refURL}/DefaultStore/ccrz__ProductDetails?sku=${serial.sku}`}
            target="_blank"
            >
              {serial.sku}
            </SavLink>
          </OverlayTrigger>
        )
      }
      return (
        <span>
          {serial.sku}
        </span>
      )
    }
    if (!_.isEmpty(serialFilter)) {
      let page = currentPage - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > serialFilter.length) {
        page = 0;
        setCurrentPage(1);
      }
      return serialFilter[page].map(serial => {
        return (
          <SavRow key={serial.sfid} className="vertical-align">
            <Col xs={4} md={2} style={{padding: 0}}>
              {renderLink(serial)}
            </Col>
            <Col xs={4} md={2} className="truncate" style={{padding: 0}}>
              {serial.serialNumber}
            </Col>
            <Col xs={4} md={2} className="truncate" style={{padding: 0}}>{serial.warrantyStatus}</Col>
            <Col xs={2} md={2} className="d-none d-md-block truncate" style={{padding: 0}}>{serial.warrantyEnd}</Col>
            <Col xs={2} md={2} className="d-none d-md-block truncate" style={{padding: 0}}>{serial.purchaseDate}</Col>
            <Col xs={2} md={1} className="d-none d-md-block truncate" style={{padding: 0}}>{serial.customerPo}</Col>
            <Col xs={2} md={1} className="d-none d-md-block truncate" style={{padding: 0}}>
              <Link className="oj u linkHover f12" to={`/orders/${serial.orderId}`}>
                {serial.orderNumber}
              </Link>
            </Col>
          </SavRow>
        );
      });
    }
  };

  const renderTable = () => {
    if (serialList.length > 0){
      return (
        renderSerials()
      )
    }
    return (
      <Row>
        <Col
          xs={12}
          style={
            serialList.length > 0
              ? { display: "none" }
              : { textAlign: "center", marginTop: 30 }
          }
        >
          No Results found.
          <br />
          <br />
          <button
            className="sav-btn__orange--text"
            onClick={() => handleClearFilters()}
          >
            Clear Filters
          </button>
        </Col>
      </Row>
    )
  }


  if(serialList){
    return (
      <div>
        <SubHeader>Warranty</SubHeader>
        <Container>
          <Row style={{ alignItems: "flex-end" }}>
            <Col sm={6}>
              <SavSearch
                type="search"
                placeholder="Search.."
                width="80%"
                value={searchString}
                onChange={e => setSearchString(e.target.value)}
              />
            </Col>
            <Col sm={6} className="d-none d-md-block">
              <SavDatePicker  
                style={{ float: "right" }}
                clickStart={setStartDate}
                clickEnd={setEndDate}
                start={startDate}
                end={endDate}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }} className="vertical-align" >
            <Col sm={2} className="d-none d-md-block">
              <SavLabel>Page Size</SavLabel>
              <SavDrop opts={pageOpts} val={pageSize} click={setPageSize} />
            </Col>
            <Col sm={10} className="d-none d-md-block">
              <SavAlphabetFilter
                style={{ float: "right" }}
                click={setAlphabetChar}
                activeChar={alphabetChar}
              />
            </Col>
          </Row>
          <hr />
          <SavColumnSort
            data={serialList}
            setData={setSerialList}
            columns={[
              { text: "Product", size: 2, sizeXS: 4, value: "sku", style: padZero },
              { text: "Serial #", size: 2, sizeXS: 4, value: "serialNumber", style: padZero },
              { text: "Status", size: 2, sizeXS: 4, value: "warrantyStatus", style: padZero},
              { text: "Expiration", size: 2, value: "warrantyEnd", hideMobile: true, style: padZero },
              { text: "Purchased", size: 2, value: "purchaseDate", hideMobile: true, style: padZero },
              { text: "P.O.", size: 1, value: "customerPo", hideMobile: true, style: padZero },
              { text: "Order #", size: 1, value: "orderNumber", hideMobile: true, style: padZero },
            ]}
          />
          {renderTable()}
          <SavPagination
            data={serialFilter}
            dataLength={serialList.length}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-center">
            <a className="med f14 oj onHov" href='https://sav-documentation.s3.amazonaws.com/Product%20Warranty%20and%20Return%20Information/009-0591-11%20New%20Savant%20Pro%20Product%20Limited%20Warranty.pdf' target="_blank" rel="noreferrer">
              New Savant Product Limited Warranty Info
            </a>
            <br />
            <a className="med f14 oj onHov" href="https://sav-documentation.s3.amazonaws.com/Product%20Warranty%20and%20Return%20Information/009-0648-11%20Savant%20Return%20Merchandise%20Authorization%20%5BRMA%5D%20Policy%20and%20Procedure.pdf" target="_blank" rel="noreferrer">
              Savant Return Merchandise Authorization (RMA) Policy and Procedure
            </a>
          </div>
          
        </Container>
      </div>
    );
  }
  return null;
  
};

const mapStateToProps = state => {
  return {
    serials: state.serials.data
  };
};

export default connect(mapStateToProps, { fetchSerials })(Warranty);

import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import CarouselComponent from "../../components/CarouselComponent";
import NewsComponent from "../../components/NewsComponent";
import EventsComponent from "../../components/EventsComponent";
import SocialComponent from "../../components/SocialComponent";
import {
  fetchGrid,
  fetchCarousel,
  fetchFeed,
  fetchEvents,
  fetchInvoices,
  fetchAccount,
  updateGrid,
} from "../../actions";
import _ from "lodash";
import { createLoadingSelector } from "../../selectors";
import {SavRouterLink, SectionHeader} from "../../components/Styled";
import Dashboard from './Dashboard';

const Home = (props) => {
  const {
    grid,
    feed,
    carousel,
    events,
    invoices,
    account,
    user,
    fetchGrid,
    updateGrid,
    fetchCarousel,
    fetchFeed,
    fetchEvents,
    fetchInvoices,
    fetchAccount,
    isFetching,
    server,
    strict,
  } = props;

  useEffect(() => {
    document.title = "Savant Community"
    fetchGrid();
  },[]);

  useEffect(() => {
    if (_.isEmpty(grid) && !isFetching) {
      fetchGrid();
    }
  }, [grid]);

  useEffect(() => {
    if (_.isEmpty(carousel)) {
      fetchCarousel();
    }
  }, [carousel]);

  useEffect(() => {
    if (_.isEmpty(feed)) {
      fetchFeed();
    }
  }, [feed]);

  useEffect(() => {
    if (_.isEmpty(events)) {
      fetchEvents();
    }
  }, [events]);

  useEffect(() => {
    if (_.isEmpty(invoices)) {
      fetchInvoices();
    }
  }, [invoices]);

  useEffect(() => {
    if (_.isEmpty(account)) {
      fetchAccount();
    }
  }, [account]);


  const REF = localStorage.getItem("ref");
  let refURL = REF;

  if (_.isEmpty(refURL)){
    if (window.location.href.includes("localhost")){
      refURL = "https://int-savantsystems.cs216.force.com";
    }
    if (window.location.href.includes("uat-community")){
      refURL = "https://uat-savantsystems.cs210.force.com";
    }
    if (window.location.href.includes("beta-community")){
      refURL = "https://community.savant.com";
    }
  }

  const renderStrictWarning = () =>  {
    if (strict && navigator.userAgent.includes('Firefox')){
      return (
        <div className="light-gray book f12 m-t-20">
          * If you have Enhanced Tracking Protection set to "Strict" in Firefox, <br />please disable for the community to load properly. <a className="med oj linkHover" href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop#w_what-to-do-if-a-site-seems-broken">
            More Info
          </a>
        </div>
      )
    }
  }

  if (server.authError) {
    return (
      <div className="minPageHeight text-center">
        <div style={{marginTop: 200}}>You are not logged in</div>
        <div className="m-t-20">
          <a href={`${refURL}/Customers`} className="med oj linkHover">
            Click here to login to the Savant Community
          </a>
        </div>
        {renderStrictWarning()}
      </div>
    );
  }

  if (server.serverError) {
    return (
      <div style={{ textAlign: "center", marginTop: 200 }}>
        <div>{server.serverError}</div>
      </div>
    );
  }

  return (
    <div className="minPageHeight">
      <CarouselComponent items={carousel} page="Home" />
      <Container className="minPageHeight" style={{maxWidth: 1440}}>
        <Row>
          <Col md={8}>
            {<Dashboard
              grid={grid}
              updateGrid={updateGrid}
              loading={isFetching}
              invoices={invoices}
              account={account.data}
              user={user.data}
            /> || <Skeleton height={500} />}
          </Col>
          <Col md={4}>
            <SectionHeader>
              News
              <SavRouterLink to={`/news`}>View All</SavRouterLink>
            </SectionHeader>
              <NewsComponent feed={feed} page="Home" header="NEWS" />
            <SectionHeader>
              Events
              <SavRouterLink to={`/events`}>View All</SavRouterLink>
            </SectionHeader>
            <EventsComponent events={events} />
            <SectionHeader>Social</SectionHeader>
            <SocialComponent />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const loadingSelector = createLoadingSelector(["FETCH_GRID"]);

const mapStateToProps = (state) => {
  return {
    account: state.account,
    grid: state.grid,
    user: state.user,
    carousel: state.carousel,
    feed: state.feed,
    events: state.events,
    isFetching: loadingSelector(state),
    server: state.server,
    invoices: state.invoices,
  };
};

export default connect(mapStateToProps, {
  fetchGrid,
  fetchCarousel,
  fetchFeed,
  fetchEvents,
  fetchInvoices,
  fetchAccount,
  updateGrid
})(Home);

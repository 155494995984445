import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import { Row, Col, Container, Collapse, Dropdown } from "react-bootstrap";
import _ from "lodash";
import SavCheck from "../../../components/SavCheck";
import SavDropFilter from "../../../components/SavDropFilter";
import SavDrop from "../../../components/SavDrop";
import {SettingRow, SavInput} from "../../../components/Styled";
import {updateAccount, setSuccessToast, fetchAddresses} from "../../../actions";
import {createLoadingSelector} from "../../../selectors";

const Header = styled.div` 
  font-family: "Gotham-Medium";
  font-size: 14px;
  text-transform:uppercase;
`;

const SavLabel = styled.span`
  font-family:"Gotham-Book";
  font-size:12px;
  color:#b4b4b4;
`;

const EditSpan = styled.span`
  font-size:10px;
  text-transform:uppercase;
  color:#ff5f00;
  cursor:pointer;
`;

const dropStyle = {
  backgroundColor: "#fff",
  border: "1px solid #979797",
  color: "#3f3f3f",
  padding: 10
}
const cancelStyle = {
  width: '100%',
  border: '1px solid #696057',
  marginTop: 5,
}
const cancelStyle2 = {
  width: '150px',
  border: '1px solid #696057',
  marginTop: 5,
}

const addressId2Name = (addressId, addresses) => {
  if (!_.isEmpty(addresses)) {
    const addressFound = addresses.find(({ sfid }) => sfid === addressId);
    if (addressFound){
      return (
        <div>
          {addressFound.addressStreet}<br />
          {addressFound.addressCity}, {addressFound.addressState} {addressFound.addressZip}<br />
          {addressFound.addressCountry}
        </div>
      )
    }
  }
  return addressId;
};

const contactId2Name = (contactId, contacts) => {
  if (!_.isEmpty(contacts)) {
    const contactFound = contacts.find(({ sfid }) => sfid === contactId);
    if (contactFound){
      return contactFound.name;
    }
    
  }
  return contactId;
};

const savantShipOpts = [
  "Ground",
  "2 Day",
  "3 Day Select",
  "Intl Economy",
  "Intl Priority",
  "Priority Overnight",
  "Standard Overnight",
];
const fedexOpts = [
  "Ground",
  "2 Day",
  "2 Day AM",
  "Express Saver",
  "First Overnight",
  "Freight Economy",
  "Freight Priority",
  "Intl Economy",
  "Intl Economy Frt",
  "Intl First",
  "Intl Priority"
];
const upsOpts = [
  "Ground",
  "2nd Day Air",
  "2nd Day Air AM",
  "3 Day Select",
  "Express Critical",
  "Next Day Air",
  "Next Day Air EarlyAM",
  "Next Day Air Saver",
  "WW Expedited",
  "WW Express",
  "WW Express Plus",
  "WW Saver"
];

const AccountSettings = props => {
  const { account, addresses, contacts, updateAccount, setSuccessToast, loading, fetchAddresses } = props;

  const [openOrderContact, setOpenOrderContact] = useState(false);
  const [openShipAddress, setOpenShipAddress] = useState(false);
  const [openShipMethod, setOpenShipMethod] = useState(false);
  const [openBillContact, setOpenBillContact] = useState(false);
  const [openBillAddress, setOpenBillAddress] = useState(false);

  const [shippingAddress, setShippingAddress] = useState(account.defaultShipAddr);
  const [billingAdress, setBillingAddress] = useState(account.defaultBillAddr);
  const [orderContact, setOrderContact] = useState(account.defaultOrderContact);
  const [billContact, setBillContact] = useState(account.defaultBillingContact);

  const [shipBilling, setShipBilling] = useState("");

  const [fedexAccount, setFedexAccount] = useState("");
  const [upsAccount, setUpsAccount] = useState("");
  // const [shipMethod, setShipMethod] = useState("");
  const [savShipMethod, setSavShipMethod] = useState("Ground");
  const [fedexShipMethod, setFedexShipMethod] = useState("Ground");
  const [upsShipMethod, setUpsShipMethod] = useState("Ground");



  useEffect(() => {
    if (account) {
      if (account.defShipBilling === "Via Savant") {
        setShipBilling("Savant")
        setSavShipMethod(account.defShipMethod);
      }
      if (account.defShipBilling === "Customer Account" && account.customerFedexAcctNum) {
        setShipBilling("Fedex")
        setFedexShipMethod(account.defShipMethod);
      }
      if (account.defShipBilling === "Customer Account" && account.customerUpsAcctNum) {
        setShipBilling("UPS");
        setUpsShipMethod(account.defShipMethod);
      }
      setFedexAccount(account.customerFedexAcctNum || "");
      setUpsAccount(account.customerUpsAcctNum || "");
      setShippingAddress(account.defaultShipAddr);
      setBillingAddress(account.defaultBillAddr);
      setOrderContact(account.defaultOrderContact);
      setBillContact(account.defaultBillingContact);
    }
  },[account]);

  useEffect(() => {
    if(openOrderContact){
      setOrderContact(account.defaultOrderContact);
    }
  },[openOrderContact]);

  useEffect(() => {
    if(openShipAddress){
      setShippingAddress(account.defaultShipAddr);
    }
  },[openShipAddress]);

  useEffect(() => {
    if(openShipMethod){
      if (account.defShipBilling === "Via Savant") {
        setShipBilling("Savant")
        setSavShipMethod(account.defShipMethod);
      }
      if (account.defShipBilling === "Customer Account" && account.customerFedexAcctNum) {
        setShipBilling("Fedex")
        setFedexShipMethod(account.defShipMethod);
      }
      if (account.defShipBilling === "Customer Account" && account.customerUpsAcctNum) {
        setShipBilling("UPS");
        setUpsShipMethod(account.defShipMethod);
      }
      setFedexAccount(account.customerFedexAcctNum || "");
      setUpsAccount(account.customerUpsAcctNum || "");
    }

  },[openShipMethod]);

  useEffect(() => {
    if(openBillAddress){
      setBillingAddress(account.defaultBillAddr);
    }

  },[openBillAddress]);

  useEffect(() => {
    if(openBillContact){
      setBillContact(account.defaultBillingContact);
    }

  },[openBillContact]);


  
  const addressOpts = [];
  if(!_.isEmpty(addresses)){
    addresses.forEach((a) => {
      const addrObj = {
        id:'',
        text:''
      }
      if(!a.noLongerUsed){
        addrObj.text = a.addressStreet+", "+a.addressCity + " " + a.addressState;
        addrObj.id = a.sfid
        addressOpts.push(addrObj);
      }
      
    })
  }

  const contactOpts = [];
  if(!_.isEmpty(contacts)){
    contacts.forEach((c) => {
      const contactObj = {
        id:'',
        text:''
      }
      if(c.status === "Active"){
        contactObj.text = c.name;
        contactObj.id = c.sfid;
        contactOpts.push(contactObj);
      }
    })
  }

  const handleUpdatePo = () => {
    const accJSON = {...account};
    accJSON.poRequired = !accJSON.poRequired;
    updateAccount(accJSON)
    .then( () => {
      setSuccessToast(true);
    })
  }

  const handleUpdateAccount = (field) => {
    const accJSON = {...account};

    if (field === 'defaultShipAddr') {
      accJSON.defaultShipAddr = shippingAddress;
    }
    if (field === 'defaultBillAddr') {
      accJSON.defaultBillAddr = billingAdress;
    }
    if (field === 'defaultOrderContact') {
      accJSON.defaultOrderContact = orderContact;
    }
    if (field === 'defaultBillingContact') {
      accJSON.defaultBillingContact = billContact;
    }
    updateAccount(accJSON)
    .then( () => {
      setSuccessToast(true);
      if (field === 'defaultShipAddr' || field === 'defaultBillAddr'){
        fetchAddresses();
      }
    })
    setOpenShipAddress(false);
    setOpenBillAddress(false);
    setOpenOrderContact(false);
    setOpenBillContact(false);
  }

  const handleUpdateShipMethod = () => {
    const accJSON = {...account};

    if (shipBilling === "Savant") {
      accJSON.defShipBilling = "Via Savant";
      accJSON.defShipCarrier = "Savant Flat Rate";
      accJSON.defShipMethod = savShipMethod;
      accJSON.customerFedexAcctNum = fedexAccount;
      accJSON.customerUpsAcctNum = upsAccount;
    };
    if (shipBilling === "Fedex") {
      accJSON.defShipBilling = "Customer Account";
      accJSON.defShipCarrier = "FedEx";
      accJSON.defShipMethod = fedexShipMethod;
      accJSON.customerFedexAcctNum = fedexAccount;
      accJSON.customerUpsAcctNum = upsAccount;
    };
    if (shipBilling === "UPS") {
      accJSON.defShipBilling = "Customer Account";
      accJSON.defShipCarrier = "UPS";
      accJSON.defShipMethod = upsShipMethod;
      accJSON.customerFedexAcctNum = fedexAccount;
      accJSON.customerUpsAcctNum = upsAccount;
    };
    updateAccount(accJSON)
    .then( () => {
      setSuccessToast(true);
      setOpenShipMethod(false);
    })
  }

  const renderLoader = (loading) => {
    if(loading === true){
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      )
    }
    return null;
  }

  const renderOpenMethod = () => {
    return (
      <Fragment>
        <Col xs={10}></Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenShipMethod(!openShipMethod)} aria-controls="openDBA" aria-expanded={openShipMethod}>{openShipMethod ? "Close" : "Edit"}</EditSpan>
        </Col>
      </Fragment>
    )
  };

  const renderClosedMethod = () => {
    return (
      <Fragment>
        <Col xs={4}>
          <SavLabel>Shipping Method</SavLabel>
        </Col>
        <Col xs={6}>
          {account.defShipBilling}<br />{account.defShipCarrier}<br />{account.defShipMethod}
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenShipMethod(!openShipMethod)} aria-controls="openDBA" aria-expanded={openShipMethod}>{openShipMethod ? "Close" : "Edit"}</EditSpan>
        </Col>
      </Fragment>
      
    )
  }


  return (
    <Container>
      {renderLoader(loading)}
      <SettingRow>
        <Col xs={12}>
          <Header>Order Defaults</Header>
        </Col>
      </SettingRow>
      <SettingRow style={openOrderContact ? {borderLeft:"4px solid #ff5f00"}: null}>
        <Col xs={4}>
          <SavLabel>Order Contact</SavLabel>
          <div className="book f10 gray m-t-20" style={!openOrderContact ? {display:'none'} : null}>
            *Changes to this default setting will NOT update any in-process Carts or Orders.
          </div>
        </Col>
        <Col xs={6}>
          {openOrderContact ? null : contactId2Name(account.defaultOrderContact, contacts)}
          <Collapse in={openOrderContact}>
            <div id="openOrderContact" className="text-center">
              <SavDropFilter val={contactId2Name(orderContact, contacts)} opts={contactOpts} click={setOrderContact} />
              <br /><br />
              <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('defaultOrderContact')}>Save</button>
              <br />
              <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenOrderContact(false)}>Cancel</button>
            </div>
          </Collapse>
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenOrderContact(!openOrderContact)} aria-controls="openDBA" aria-expanded={openOrderContact}>{openOrderContact ? "Close" : "Edit"}</EditSpan>
        </Col>
      </SettingRow>
      <SettingRow>
        <Col xs={4}>
          <SavLabel>PO Required</SavLabel>
        </Col>
        <Col xs={8}>
          <SavCheck checked={account.poRequired} click={handleUpdatePo} />
        </Col>
      </SettingRow>
      <SettingRow style={{marginTop:20}}>
        <Col xs={12}>
          <Header>Shipping Defaults</Header>
        </Col>
      </SettingRow>
      <SettingRow style={openShipAddress ? {borderLeft:"4px solid #ff5f00"}: {alignItems:"center"}}>
        <Col xs={4}>
          <SavLabel>Shipping Address</SavLabel>
          <div className="book f10 gray m-t-20" style={!openShipAddress ? {display:'none'} : null}>
            *Changes to this default setting will NOT update any in-process Carts or Orders.
          </div>
        </Col>
        <Col xs={6}>
          {openShipAddress ? null : addressId2Name(account.defaultShipAddr, addresses)}
          <Collapse in={openShipAddress}>
            <div id="openShipAddress" >
              <SavDropFilter val={addressId2Name(shippingAddress, addresses)} opts={addressOpts} click={setShippingAddress} />
              <br /><br />
              <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('defaultShipAddr')}>Save</button>
              <br />
              <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenShipAddress(false)}>Cancel</button>
            </div>
          </Collapse>
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenShipAddress(!openShipAddress)} aria-controls="openDBA" aria-expanded={openShipAddress}>{openShipAddress ? "Close" : "Edit"}</EditSpan>
        </Col>
      </SettingRow>
      <SettingRow style={openShipMethod ? {borderLeft:"4px solid #ff5f00"}: {alignItems:"center"}}>
        {openShipMethod ? renderOpenMethod() : renderClosedMethod()}
          <Collapse in={openShipMethod}>
            <Container fluid>
              <Row className="m-t-10" style={{alignItems:'center'}}>
                <Col xs={2} className="text-center" style={{paddingLeft:0}}>
                  <SavLabel className="f10">Default</SavLabel>
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  <SavLabel className="f10">Shipping Method</SavLabel>
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  <SavLabel className="f10">Account #</SavLabel>
                </Col>
                <Col xs={4} style={{paddingLeft:0}}>
                  <SavLabel className="f10">Standard</SavLabel>
                </Col>
              </Row>
              <Row className="m-t-10" style={{alignItems:'center'}}>
                <Col xs={2} style={{paddingLeft:0}}>
                  <SavCheck center checked={shipBilling === "Savant"} click={() => setShipBilling("Savant")} />
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  Ship via Savant
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  <SavInput value="N/A" disabled />
                </Col>
                <Col xs={4} style={{paddingLeft:0}}>
                  <SavDrop style={dropStyle} opts={savantShipOpts} val={savShipMethod} click={setSavShipMethod} />
                </Col>
              </Row>
              <Row className="m-t-10" style={{alignItems:'center'}}>
                <Col xs={2} style={{paddingLeft:0}}>
                  <SavCheck center checked={shipBilling === "Fedex"} click={() => setShipBilling("Fedex")} />
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  Ship via My Fedex
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  <SavInput value={fedexAccount} onChange={(e) => setFedexAccount(e.target.value)} />
                </Col>
                <Col xs={4} style={{paddingLeft:0}}>
                  <SavDrop style={dropStyle} opts={fedexOpts} val={fedexShipMethod} click={setFedexShipMethod} />
                </Col>
              </Row>
              <Row className="m-t-10" style={{alignItems:'center'}}>
                <Col xs={2} style={{paddingLeft:0}}>
                  <SavCheck center checked={shipBilling === "UPS"} click={() => setShipBilling("UPS")} />
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  Ship via My UPS
                </Col>
                <Col xs={3} style={{paddingLeft:0}}>
                  <SavInput value={upsAccount} onChange={(e) => setUpsAccount(e.target.value)} />
                </Col>
                <Col xs={4} style={{paddingLeft:0}}>
                  <SavDrop style={dropStyle} opts={upsOpts} val={upsShipMethod} click={setUpsShipMethod} />
                </Col>
              </Row>
              <div className="book f10 gray m-t-20" style={!openShipMethod ? {display:'none'} : null}>
                *Changes to this default setting will NOT update any in-process Carts or Orders.
              </div>
              <Row className="m-t-20">
                <Col xs={12} className="text-center">
                  <button className="sav-btn__orange--fill" onClick={() => handleUpdateShipMethod()}>Save</button>
                  <br />
                  <button className="sav-btn__gray" style={cancelStyle2} onClick={() => setOpenShipMethod(false)}>Cancel</button>
                </Col>
              </Row>
            </Container>
          </Collapse>
      </SettingRow>
      
      <SettingRow style={!account.freightForwarderCompany? {display:'none'} : null}>
        <Col xs={4}>
          <SavLabel>Freight Forwarder</SavLabel>
        </Col>
        <Col xs={8}>
          {account.freightForwarderCompany}
        </Col>
      </SettingRow>

      <SettingRow style={{marginTop:20}}>
        <Col xs={12}>
          <Header>Billing Defaults</Header>
        </Col>
      </SettingRow>
      <SettingRow style={openBillAddress ? {borderLeft:"4px solid #ff5f00"}: {alignItems:"center"}}>
        <Col xs={4}>
          <SavLabel>Billing Address</SavLabel>
          <div className="book f10 gray m-t-20" style={!openBillAddress ? {display:'none'} : null}>
            *Changes to this default setting will NOT update any in-process Carts or Orders.
          </div>
        </Col>
        <Col xs={6}>
          {openBillAddress ? null :addressId2Name(account.defaultBillAddr, addresses)}
          <Collapse in={openBillAddress}>
            <div id="openBillAddress" >
              <SavDropFilter val={addressId2Name(billingAdress, addresses)} opts={addressOpts} click={setBillingAddress} />
              <br /><br />
              <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('defaultBillAddr')}>Save</button>
              <br />
              <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenBillAddress(false)}>Cancel</button>
            </div>
          </Collapse>
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenBillAddress(!openBillAddress)} aria-controls="openBillAddress" aria-expanded={openBillAddress}>{openBillAddress ? "Close" : "Edit"}</EditSpan>
        </Col>
      </SettingRow>

      <SettingRow style={openBillContact ? {borderLeft:"4px solid #ff5f00"}: {alignItems:"center"}}>
        <Col xs={4}>
          <SavLabel>Billing Contact</SavLabel>
          <div className="book f10 gray m-t-20" style={!openBillContact ? {display:'none'} : null}>
            *Changes to this default setting will NOT update any in-process Carts or Orders.
          </div>
        </Col>
        <Col xs={6}>
          {openBillContact ? null : contactId2Name(account.defaultBillingContact, contacts)}
          <Collapse in={openBillContact}>
            <div id="openBillContact" className="text-center">
              <SavDropFilter val={contactId2Name(billContact, contacts)} opts={contactOpts} click={setBillContact} />
              <br /><br />
              <button className="sav-btn__orange--fill" style={{width:"100%"}} onClick={() => handleUpdateAccount('defaultBillingContact')}>Save</button>
              <br />
              <button className="sav-btn__gray" style={cancelStyle} onClick={() => setOpenBillContact(false)}>Cancel</button>
            </div>
          </Collapse>
        </Col>
        <Col xs={2} className="text-right">
          <EditSpan onClick={()=> setOpenBillContact(!openBillContact)} aria-controls="openBillContact" aria-expanded={openBillContact}>{openBillContact ? "Close" : "Edit"}</EditSpan>
        </Col>
      </SettingRow>
    </Container>
  );
};

const loadingSelector = createLoadingSelector(["UPDATE_ACCOUNT"]);

const mapStateToProps = state => {
  return {
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {updateAccount, setSuccessToast, fetchAddresses})(AccountSettings);

import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import _ from "lodash";
import {AiOutlineDollarCircle, AiOutlineWifi, AiOutlineArrowUp} from 'react-icons/ai';
import {TbRefreshAlert} from 'react-icons/tb';
import { MdHelp, MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import {FiExternalLink} from 'react-icons/fi';
import ReactTooltip from "react-tooltip";
import { SubHeader, ZeroStateText } from "../../../components/Styled";
import {parseCurrency} from "../../../components/Utilities";
import {paginate} from "../../../components/Utilities";
import proHost from "../../../images/SCS/pro-host.svg";
import smartHost from "../../../images/SCS/smart-host.svg";
import shr2000 from "../../../images/SCS/SHR-2000-00.svg";
import director from "../../../images/SCS/HST-Director.svg";
import sipa from "../../../images/SCS/HST-SIPA1SM.svg";
import soundbar from "../../../images/SCS/HST-SOUNDBAR.svg";
import essentials from "../../../images/SCS/essential-plan.svg";
import expiring from "../../../images/SCS/essential-plan-expiring.svg";
import superPro from "../../../images/SCS/superPro.svg";
import SavPagination from "../../../components/SavPagination";
import SavFormDrop from "../../../components/SavFormDrop";
import SavDrop from "../../../components/SavDrop";
import ctcImage from "./contacts.png";
import {HomeRow, DataContainer, DataCard, DataCardHeader, DataCardNumber, DataCardFooter} from "./styles"
const hidden = {
  display: 'none'
}
const SubscriptionHomes = ({account, contacts, activeSubs, expiredSubs, homes, fetchHomeHistory, virInc}) => {

  const [purchasingContacts, setPurchasingContacts] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderContacts, setOrderContacts] = useState("asc");
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageContacts, setCurrentPageContacts] = useState(1);
  const [contactsFilter, setContactsFilter] = useState([]);
  const [unsubbedHomes, setUnsubbedHomes] = useState([]);
  const [homesVersion10, setHomesVersion10] = useState([]);
  const [homesVersionOld, setHomesVersionOld] = useState([])
  const [allHomes, setAllHomes] = useState([]);
  const [scsHomes, setScsHomes] = useState([]);
  const [scsHomesFilter, setScsHomesFilter] = useState([]);
  const [subFilter, setSubFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [hostFilter, setHostFilter] = useState('All')
  const [hostOpts, setHostOpts] = useState([]);
  const REF = localStorage.getItem("ref");
  
  const SUB_FILTER_OPTS = ['All', 'Savant Essentials', 'Smart Network', 'None'];
  const STATUS_FILTER_OPTS = ['All', 'Active', 'Inactive', 'Expired'];
  const PAGE_OPTS = [5, 10, 25, 50];

  useEffect(() => {
    if (!_.isEmpty(contacts)){
      const temp = _.filter(contacts, (c) => c.permissions.scmPurchasing);
      setPurchasingContacts(temp);
    }
  },[contacts]);

  useEffect(() => {
    if (!_.isEmpty(homes)){
      const tempHomes = [];
      const tempHosts = ['All'];
      const tempUnsubbed = [];
      const temp10 = [];
      const tempOld = [];
      if (homes.data.payload){
        homes.data.payload.forEach((p) => {
          p.homes.forEach((h) => {
            const tempHome = {...h};
  
            if (!tempHome.hostInfo){
              const hostInfoObj = {
                hostProfileModel: 'N/A'
              };
              tempHome.hostInfo = hostInfoObj;
            }
  
            const foundEssentials = _.find(tempHome.subscriptionInfo, (s) => s.category === 'ESSENTIALS');
            const foundNetwork = _.find(tempHome.subscriptionInfo, (s) => s.category === 'NETWORK');
            let foundActiveLegacy;
            if (!_.isEmpty(activeSubs)){
              foundActiveLegacy = _.find(activeSubs, (s) => s.homeId === h.id && s.isLegacy);
            }
            const today = moment(new Date());
  
            if (foundEssentials){
              const startDate = moment(new Date(foundEssentials.beginsAt)).format('YYYY-MM-DD');
              const renewDate = moment(new Date(foundEssentials.expiresAt)).format('YYYY-MM-DD');
              tempHome.startDateEssentials = startDate;
              tempHome.renewDateEssentials = renewDate;
              if (today.isBetween(startDate, renewDate, undefined, '[)')){
                tempHome.isActiveEssentials = true;
              } else {
                tempHome.isActiveEssentials = false;
                tempHome.hasExpiredEssentials = true;
              }
              if (foundEssentials.autoRenew){
                tempHome.isInactive = false;
              } else {
                tempHome.isInactive = true;
              }
            }
            if (foundNetwork){
              const startDate = moment(new Date(foundNetwork.beginsAt)).format('YYYY-MM-DD');
              const renewDate = moment(new Date(foundNetwork.expiresAt)).format('YYYY-MM-DD');
              tempHome.startDateNetwork = startDate;
              tempHome.renewDateNetwork = renewDate;
              if (today.isBetween(startDate, renewDate, undefined, '[)')){
                tempHome.isActiveNetwork = true;
              } else {
                tempHome.isActiveNetwork = false;
                tempHome.hasExpiredNetwork = false;
              }
              if (foundNetwork.autoRenew){
                tempHome.isInactive = false;
              } else {
                tempHome.isInactive = true;
              }
  
            }
            if (foundActiveLegacy){
              tempHome.isLegacy = true;
              tempHome.isInactive = true;
              tempHome.startDateEssentials = foundActiveLegacy.startDateEssentials;
              tempHome.renewDateEssentials = foundActiveLegacy.renewDateEssentials;
            }
  
            if (!tempHosts.includes(tempHome.hostInfo.hostProfileModel) && tempHome.hostInfo.hostProfileModel){
              tempHosts.push(tempHome.hostInfo.hostProfileModel);
            }
  
            if (tempHome.softwareInfo.version.major >=10){
              if (!foundNetwork && !foundEssentials && !foundActiveLegacy){
                tempUnsubbed.push(tempHome);
              }
              temp10.push(tempHome);
            } else {
              tempOld.push(tempHome);
            }
  
            tempHomes.push(tempHome);
          })
        })
      }
     
      
      setScsHomes(tempHomes);
      setAllHomes(tempHomes);
      setHostOpts(tempHosts);
      setUnsubbedHomes(tempUnsubbed);
      setHomesVersion10(temp10);
      setHomesVersionOld(tempOld);

      // console.log('SUBS ACTIVE---', activeSubs)
      // console.log('SUBS INACTIVE---', expiredSubs)
      //console.log('HOMES ALL', tempHomes);
    }
  },[homes, activeSubs]);

  useEffect(() => {
    if(scsHomes){
      let index = 0;
      const tempHomes = [];
      for (index = 0; index < scsHomes.length; index += pageSize) {
        let chunk = scsHomes.slice(index, index + pageSize);
        tempHomes.push(chunk);
      }
      setScsHomesFilter(tempHomes);
    }
  },[scsHomes, allHomes, pageSize]);

  useEffect(() => {
    if (subFilter === 'All' && statusFilter === 'All' && hostFilter === 'All'){
      setScsHomes(allHomes);
    }

    if (subFilter === 'All' && statusFilter === 'All' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }

    if (subFilter === 'All' && statusFilter === 'Active' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.isActiveEssentials || h.isActiveNetwork) && !h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'All' && statusFilter === 'Inactive' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'All' && statusFilter === 'Expired' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.hasExpiredEssentials || h.hasExpiredNetwork));
      setScsHomes(homesFound)
    }


    if (subFilter === 'All' && statusFilter === 'Active' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.isActiveEssentials || h.isActiveNetwork) && !h.isInactive && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'All' && statusFilter === 'Inactive' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isInactive && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'All' && statusFilter === 'Expired' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.hasExpiredEssentials || h.hasExpiredNetwork) && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }


    if (subFilter === 'Savant Essentials' && statusFilter === 'All' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveEssentials || h.isLegacy);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Active' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveEssentials && !h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Inactive' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveEssentials && h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Expired' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.hasExpiredEssentials);
      setScsHomes(homesFound)
    }

    if (subFilter === 'Savant Essentials' && statusFilter === 'All' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.isActiveEssentials || h.isLegacy) && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Active' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveEssentials && !h.isInactive && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Inactive' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveEssentials && h.isInactive && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Savant Essentials' && statusFilter === 'Expired' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.hasExpiredEssentials && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    

    if (subFilter === 'Smart Network' && statusFilter === 'All' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveNetwork);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Active' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveNetwork && !h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Inactive' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveNetwork && h.isInactive);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Expired' && hostFilter === 'All'){
      const homesFound = _.filter(allHomes, (h) => h.hasExpiredNetwork);
      setScsHomes(homesFound)
    }

    if (subFilter === 'Smart Network' && statusFilter === 'All' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.isActiveNetwork) && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Active' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => (h.isActiveNetwork && !h.isInactive) && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Inactive' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.isActiveNetwork && h.isInactive && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }
    if (subFilter === 'Smart Network' && statusFilter === 'Expired' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, (h) => h.hasExpiredNetwork && h.hostInfo.hostProfileModel === hostFilter);
      setScsHomes(homesFound)
    }

    if (subFilter === 'None' && hostFilter !== 'All'){
      const homesFound = _.filter(allHomes, h => !h.isActiveNetwork && !h.isActiveEssentials && !h.isLegacy && h.hostInfo.hostProfileModel === hostFilter );
      setScsHomes(homesFound)
    }
    if (subFilter === 'None' && hostFilter === 'All'){
      console.log('hey')
      const homesFound = _.filter(allHomes, h => !h.isActiveNetwork && !h.isActiveEssentials && !h.isLegacy);
      setScsHomes(homesFound)
    }
  },[allHomes, subFilter, statusFilter, hostFilter])

  useEffect(() => {
    if (purchasingContacts) {
      let index = 0;
      const tempInvovices = [];
      for (index = 0; index < purchasingContacts.length; index += pageSize) {
        let chunk = purchasingContacts.slice(index, index + pageSize);
        tempInvovices.push(chunk);
      }
      setContactsFilter(tempInvovices);
    }
  }, [purchasingContacts, pageSize]);

  const getScmUrl = () => {
    if (REF.includes('savantsystems--int')){
      return "https://cdev2-rms-v2.savantcs.com"
    } else if (REF.includes('savantsystems--uat')){
      return "https://calpha1-rms-v2.savantcs.com"
    } else {
      return "https://central.savant.com"
    }
  };
  const renderEssentialsIcon= () => {
    return(
      <div>
        <Image src={essentials} height={30} width={20} style={{marginRight: 5}} />
        <span className="book f12">SAVANT ESSENTIALS</span>
      </div>
    )
  };
  const renderNetworkIcon = () => {
    return (
      <div>
        <AiOutlineWifi size={20} fill="#4cd964" style={{marginRight: 5}} />
        <span className="book f12">SMART NETWORK</span>
      </div>
    )
  };
  const renderNetworkExpired = () => {
    return (
      <div>
        <Image src={expiring} height={30} width={20} style={{marginRight: 5}} />
        <span className="book f12">SMART NETWORK</span>
      </div>
    )
  };
  const renderEssentialsExpired = () => {
    return (
      <div>
        <Image src={expiring} height={30} width={20} style={{marginRight: 5}} />
        <span className="book f12">SAVANT ESSENTIALS</span>
      </div>
    )
  };
  const handleColumnSort = predicate => {
    let tempOrder;
    if (order === "asc") {
      tempOrder = "desc";
    } else {
      tempOrder = "asc";
    }
    setOrder(tempOrder);
    setScsHomes(_.orderBy(scsHomes, [predicate], [tempOrder]));
  };
  const handleColumnSortContacts = predicate => {
    let tempOrder;
    if (orderContacts === "asc") {
      tempOrder = "desc";
    } else {
      tempOrder = "asc";
    }
    setOrderContacts(tempOrder);
    const tempSerials = _.orderBy(purchasingContacts, [predicate], [tempOrder]);
    setPurchasingContacts(tempSerials);
  };
  const getLicenseIcon = (hostType) => {
    if (hostType === 'Pro Host'){
      return proHost
    } else if ( hostType === 'SHR-2000 Host' || hostType === 'S2SM Host'){
      return shr2000
    } else if (hostType === 'HST-DIR Director'){
      return director
    } else if (hostType === 'HST-STUDIO55WS-SUR' || hostType === 'HST-STUDIO'){
      return soundbar
    } else if (hostType === 'Smart Host with Control'){
      return smartHost
    } else if (hostType === 'HST-SIPA1SM'){
      return sipa
    } else if (hostType === 'Super Pro Host'){
      return superPro
    }
    return proHost
  };
  const renderContacts = () => {
    if (!_.isEmpty(contactsFilter)){
      let page = currentPageContacts - 1;
      if (page < 1) {
        page = 0;
      }
      if (page > contactsFilter.length) {
        page = 0;
        setCurrentPageContacts(1);
      }
      return contactsFilter[page].map((contact) => {
        return (
          <HomeRow style={{borderTop: '1px solid #e9ecef'}} key={contact.sfid}>
            <Col sm={1} className="med f12" style={{padding: 0}}>
              <AiOutlineDollarCircle size={30} style={{fill: '#4cd964'}} />
            </Col>
            <Col sm={5} className="book f12 truncate">
              {contact.name}
            </Col>
            <Col sm={6} className="book f12 truncate">
              {contact.email}
            </Col>
          </HomeRow>
        )
      })
    }
    return <ZeroStateText>No contacts with SCM purchasing enabled.</ZeroStateText>
  };
  const renderHomes = () => {

    const renderSubStatus = (sub) =>{
      if (!_.isEmpty(sub)){
        return (
          <Fragment>
            {sub.isActiveEssentials || sub.isLegacy ? renderEssentialsIcon() : !sub.hasExpiredEssentials ? "---" : null}
            {sub.isActiveNetwork ? renderNetworkIcon() : null}
            {sub.hasExpiredEssentials ? renderEssentialsExpired() : null}
            {sub.hasExpiredNetwork ? renderNetworkExpired() : null}
          </Fragment>
        )
      }
      return "---";
    }

    const renderSubRenewal = (sub) => {
      if (sub.isLegacy || sub.isInactive){
        return (
          <Fragment>
            <ReactTooltip id={sub.id} className="tooltip-issuetype" effect='solid' style={{width: 100}}>
              <p className="wht" style={{textTransform: 'none', paddingTop: 10}}>This subscription will not automatically renew.</p>
            </ReactTooltip>
            <div>
              {sub.renewDateEssentials || sub.renewDateNetwork}
            </div>
            
            <TbRefreshAlert size={30} style={{marginLeft: 5, stroke: '#ffcc5f', cursor: 'help'}} data-tip data-for={sub.id} />
          </Fragment>
        )
      } else {
        return <Fragment>{sub.renewDateEssentials || sub.renewDateNetwork || "---"}</Fragment>
      }
    }

    if (!_.isEmpty(scsHomesFilter) && !_.isEmpty(account)){

     let page = paginate(currentPage, setCurrentPage, scsHomesFilter);
      return scsHomesFilter[page].map((sub) => {
        return (
          <HomeRow style={{borderTop: '1px solid #e9ecef', minHeight: 75}} key={sub.id}>
            <Col xs={1} className="d-none d-md-block">
              <Image src={getLicenseIcon(sub.hostInfo.hostProfileModel)} height="30px" width="100%" />
            </Col>
            <Col xs={5} md={3}>
              {sub.name}<br />
              <span className="med f12">{sub.uid}</span>
            </Col>
            <Col md={2} className="f12 d-none d-md-block">
              {sub.softwareInfo.version.name}
            </Col>
            <Col xs={3} md={3} className="f12">
              {renderSubStatus(sub)}
            </Col>
            <Col xs={3} md={2} className="f12 savFlexBetweenCenter">
              {renderSubRenewal(sub)}
            </Col>
            <Col xs={1} className="text-center">
              <a href={`${getScmUrl()}/${account.scsDomainId}/home/${sub.id}/status`} target="_blank" className="med oj u f14" rel="noreferrer">
                <FiExternalLink size={25} style={{stroke: '#ff5f00'}} />
              </a>
              
            </Col>
          </HomeRow>
        )
      })
    }
    return <ZeroStateText>No homes found.</ZeroStateText>
  };
  const renderPagination = () => {
    if (!_.isEmpty(scsHomes)){
      return (
        <Row style={{borderTop: '1px solid #e9ecef', padding: '10px 0px 10px', margin: '0px'}}>
            <Col xs={2} style={{display:'flex', alignItems: 'center'}}>
              <SavDrop
                opts={PAGE_OPTS}
                val={pageSize}
                click={setPageSize}
                style={{backgroundColor: '#fff', cursor: 'pointer', border: '1px solid #b4b4b4', width: 50}}
                label="Page Size"
              />
              &nbsp;&nbsp;/&nbsp;&nbsp;<span className="med f14">{scsHomes.length}</span>
            </Col>
            <Col xs={10}>
              <SavPagination
                data={scsHomesFilter}
                pageSize={pageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                dataLength={scsHomes.length}
              />
            </Col>
          </Row>
      )
    }
    return null;
  };

  return (
    <Row>
      <Col xs={12}>
        <SubHeader>Home Subscription Status</SubHeader>
        <DataContainer>
          <Form className="sav-form" style={{padding: '20px 10px 0px'}}>
            <Form.Row>
              <Form.Group as={Col} xs={4}>
                <SavFormDrop
                  opts={SUB_FILTER_OPTS}
                  value={subFilter}
                  setValue={setSubFilter}
                  label="Subscription Type"
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <SavFormDrop
                  opts={STATUS_FILTER_OPTS}
                  value={statusFilter}
                  setValue={setStatusFilter}
                  label="Subscription Status"
                />
              </Form.Group>
              <Form.Group as={Col} xs={4}>
                <SavFormDrop
                  opts={hostOpts}
                  value={hostFilter}
                  setValue={setHostFilter}
                  label="Host Model"
                />
              </Form.Group>
            </Form.Row>
          </Form>
          <HomeRow style={{cursor: 'pointer'}}>
            <Col xs={5} md={4} onClick={() => handleColumnSort('name')}>
              Home Name
              <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>
            <Col md={2} onClick={() => handleColumnSort('softwareInfo.version.name')} className="d-none d-md-block truncate">
              da Vinci Version
              <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>
            <Col xs={3} md={3}  onClick={() => handleColumnSort('isActiveEssentials')} className="truncate">
              Subscriptions
              <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>
            <Col xs={4} md={2} onClick={() => handleColumnSort('renewDateEssentials')} className="truncate">
              Renewal Date
              <MdArrowDropDown
                style={order === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={order === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>
          </HomeRow>
          {renderHomes()}
          {renderPagination()}
        </DataContainer>
      </Col>
      <Col lg={6}>
        
        <Row>
          <Col>
            <SubHeader>
              Home Subscription Stats
            </SubHeader>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <DataCard>
              <DataCardHeader>
                Total
              </DataCardHeader>
              <DataCardNumber className="light-gray">
                {allHomes.length}
              </DataCardNumber>
              <DataCardFooter>
                Homes
              </DataCardFooter>
            </DataCard>
          </Col>
          <Col sm={6}>
            <DataCard>
              <DataCardHeader className="truncate">
                10.0+ Not subscribed
              </DataCardHeader>
              <DataCardNumber className="light-gray">
                {unsubbedHomes.length}
              </DataCardNumber>
              <DataCardFooter>
                Homes
              </DataCardFooter>
            </DataCard>
          </Col>
          
          {/* <Col sm={12} style={account.type === 'Non-Direct' ? hidden : null}>
            <DataCard style={account.ambassadorActive ? {display: 'none'} : null}>
              <DataCardHeader>
                Non-Ambassador Status
              </DataCardHeader>
              <DataCardNumber className="wip">
                <AiOutlineArrowUp size={35} style={{fill:'#ffcc5f'}} />{parseCurrency(virInc * 1.5 - virInc)}
              </DataCardNumber>
              <DataCardFooter>
                Missed Additional Rebate
              </DataCardFooter>
            </DataCard>
          </Col>
          <Col sm={12} style={account.type === 'Non-Direct' ? hidden : null}>
            <DataCard style={!account.ambassadorActive ? {display: 'none'} : null}>
              <DataCardHeader>
                Elite Ambassador Status
              </DataCardHeader>
              <DataCardNumber className="green">
                <AiOutlineArrowUp size={35} style={{fill:'#4cd964'}} />{parseCurrency(virInc * .5)}
              </DataCardNumber>
              <DataCardFooter>
                Additional Rebate
              </DataCardFooter>
            </DataCard>
          </Col> */}
          <Col sm={6}>
            <DataCard>
              <DataCardHeader>
                da Vinci &gt; 10.0
              </DataCardHeader>
              <DataCardNumber className="light-gray">
                {homesVersion10.length}
              </DataCardNumber>
              <DataCardFooter>
                Homes
              </DataCardFooter>
            </DataCard>
          </Col>
          <Col sm={6}>
            <DataCard>
              <DataCardHeader>
                da Vinci &lt; 10.0
              </DataCardHeader>
              <DataCardNumber className="light-gray">
                {homesVersionOld.length}
              </DataCardNumber>
              <DataCardFooter>
                Homes
              </DataCardFooter>
            </DataCard>
          </Col>
        </Row>
      </Col>

      <Col lg={6}>
        <SubHeader>
          SCM Purchasing Contacts
          <ReactTooltip id="scm" className="tooltip-issuetype" effect='solid' style={{width: 100}}>
            <p className="wht" style={{textTransform: 'none', paddingTop: 10}}>Manage purchasing permissions under the Contacts tab.</p>
            <img src={ctcImage} width={350} alt="ctc" style={{paddingBottom: 10}} />
          </ReactTooltip>
          <MdHelp size={25}
            style={{ color: "#fff", textTransform: 'none', cursor: 'help', marginLeft: 10, float: 'right' }}
            data-tip data-for="scm"
          />
        </SubHeader>
        <DataContainer>
          <HomeRow style={{cursor: 'pointer'}}>
            <Col xs={6} onClick={() => handleColumnSortContacts("name")}>
              User
              <MdArrowDropDown
                style={ orderContacts === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={orderContacts === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>
            <Col xs={6} onClick={() => handleColumnSortContacts("email")}>
              Email
              <MdArrowDropDown
                style={ orderContacts === "asc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
              <MdArrowDropUp
                style={orderContacts === "desc" ? { display: "none" } : { fill: "#9b9b9b" }}
                size="2em"
              />
            </Col>

          </HomeRow>
          {renderContacts()}
          <SavPagination
            data={contactsFilter}
            pageSize={pageSize}
            currentPage={currentPageContacts}
            setCurrentPage={setCurrentPageContacts}
            dataLength={purchasingContacts.length}
          />
        </DataContainer>
        <div className="text-center">
          <Link to="/account?tab=personnel#contacts">
            <button className="sav-btn__orange">Manage Contacts</button>
          </Link>
        </div>
        
      </Col>
    </Row>
  )

}

export default SubscriptionHomes;
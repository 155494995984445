import axios from "axios";
import moment from 'moment';
import config from "../sf-config.json";

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.has("sfsid")) {
  let ref;
  if (urlParams.has("ref")) {
    ref = urlParams
      .get("ref")
      .replace("/Customers", "")
      .replace("/DefaultStore", "");
  } else ref = "https://store.savant.com";

  localStorage.setItem("ref", ref);
  localStorage.setItem("sfsid", urlParams.get("sfsid"));
  localStorage.setItem("lastLogin", new moment());
  window.history.replaceState({}, document.title, "/");
}

const SFTOKEN = localStorage.getItem("sfsid");
const REF = localStorage.getItem("ref");
let refURL;

if (REF){
  if (REF.includes('savantsystems--int')){
    refURL = config.SF_API_INT_URL;
  } else if (REF.includes('savantsystems--devfull')){
    refURL = config.SF_API_DEV_URL
  } else if (REF.includes('savantsystems--uat')){
    refURL = config.SF_API_UAT_URL
  }  else {
    refURL = config.SF_API_URL
  }
}else {
  refURL = config.SF_API_URL
}

const sfAPI = axios.create({
  baseURL: refURL,
  headers: {
    Authorization: `Bearer ${SFTOKEN}`,
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin': '*'
  }
});
export default sfAPI;
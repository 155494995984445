import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Container, Collapse, Modal, Form } from "react-bootstrap";
import _ from "lodash";
import {FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex} from "react-icons/fa";
import { connect } from "react-redux";
import SavOutput from "../../../components/SavOutput";
import {SavInput} from "../../../components/Styled";
import SavToast from "../../../components/SavToast";
import SavCheck from "../../../components/SavCheck";
import SavDropFilter from "../../../components/SavDropFilter";
import SavModalWarning from "../../../components/SavModalWarning";
import Countries from "../../../components/Utilities/countries";
import {deleteCreditCard, createCreditCard, createBankAccount, deleteBankAccount, setSuccessToast} from "../../../actions";
import {createLoadingSelector} from "../../../selectors";
import SavFormInput from "../../../components/SavFormInput";
import SavFormDrop from "../../../components/SavFormDrop";

const DetailContainer = styled(Container)`
  // background-color: #fafaf9;
  // padding: 30px 40px;
`;
const Header = styled.div`
  font-family: "Gotham-Medium";
  font-size: 14px;
  color: #565551;
`;
const SettingRow = styled(Row)`
  background-color:#fafaf9;
  padding:15px;
  font-size:12px;
  border-bottom:1px solid rgba(0,0,0,.1);
  align-items:center;
`;
const SideHeader = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  padding: 10px;
  background-color: #3f3f3f;
  position: absolute;
  height: 61px;
  left: -15px;
  top: -18px;
  > div {
    color: #fff;
    font-family: 'Gotham-Medium';
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
`;

const SavCheckForm = React.forwardRef((props, ref) => {
  return (
    <SavCheck checked={props.checked} click={props.click} form center  />
  )
});
const SavDropFilterForm = React.forwardRef((props, ref) => {
  return (
    <SavDropFilter val={props.val} opts={props.opts} click={props.click} clickKey="code" />
  )
});

const getIcon = (type) => {
  switch (type) {
    case "Visa" :
      return <FaCcVisa size={25} />
    case "Mastercard" :
      return <FaCcMastercard size={25} />
    case "Discover" :
      return <FaCcDiscover size={25} />
    case "AMEX" :
      return <FaCcAmex size={25} />
    default:
      return null;
  }
}

const AccountSavedCredit = props => {
  const {creditCards, deleteCreditCard, createCreditCard, loadingCreateBank, loadingDeleteBank, deleteBankAccount, createBankAccount, setSuccessToast, account, addresses, bankAccounts, user} = props;

  const [showNewCCModal, setShowNewCCModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);

  const [showNewBankModal, setShowNewBankModal] = useState(false);
  const [showDeleteBankModal, setShowDeleteBankModal] = useState(false);
  const [bankToDelete, setBankToDelete] = useState(null);

  const [validated, setValidated] = useState(false);
  const [validatedBank, setValidatedBank] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [ccType, setCcType] = useState("Visa");
  const [ccCode, setCcCode] = useState("");
  const [expMonth, setExpMonth] = useState("1");
  const [expYear, setExpYear] = useState("2022");
  
  const [isDefault, setIsDefault] = useState(false);
  const [addressStreet, setAddressStreet] = useState('');
  const [addressCity, setAddressCity] = useState('')
  const [addressState, setAddressState] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [addressCountry, setAddressCountry] = useState('');
  const [addressContactLast, setAddressContactLast] = useState("");
  const [addressContactFirst, setAddressContactFirst] = useState("");

  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankContactName, setBankContactName] = useState("Username");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankRouting, setBankRouting] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");

  useEffect(() => {
    setValidated(false);
    setCreditCardNumber("");
    setCcCode("");
    setAddressContactLast("");
    setAddressContactFirst("");
    setExpYear("2021");
    setExpMonth("1");
    setIsDefault(false);
  },[showNewCCModal]);

  useEffect(() => {
    setValidatedBank(false);
    setBankAccountNumber("")
    setBankName("");
    setBankContactName("");
    setBankAccountType("Checking");
    setBankRouting("");
    setBankAccountName("");
  },[showNewBankModal]);

  useEffect(() => {
    if (account && addresses){
      const addressFound = _.find(addresses, (a) => a.sfid === account.defaultBillAddr );
      if (addressFound){
        setAddressStreet(addressFound.addressStreet);
        setAddressCity(addressFound.addressCity);
        setAddressState(addressFound.addressState);
        setAddressZip(addressFound.addressZip);
        setAddressCountry(addressFound.addressCountry);
        setAddressContactFirst(addressFound.overrideFirstName || "");
        setAddressContactLast(addressFound.overrideLastName || "");
      }
    }
  },[account]);

  useEffect(() => {
    if (!_.isEmpty(user)){
      setBankContactName(user.contact.name);
    }
  },[user]);


  const handleCloseNewCCModal = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const cardJSON = {
      cardNumber: creditCardNumber,
      expYear: expYear,
      expMonth: expMonth,
      cvv: ccCode,
      type: ccType,
      isDefault: isDefault,
      billingAddress: addressStreet,
      billingCity: addressCity,
      billingCountry: addressCountry,
      billingFirst: addressContactFirst,
      billingLast: addressContactLast,
      billingState: addressState,
      billingZip: addressZip
    }

    createCreditCard(cardJSON).then((val) => {
      setSuccessToast(true);
    });
    setShowNewCCModal(false);
  };
  const handleCloseNewBankModal = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidatedBank(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const bankJSON = {
      type: bankAccountType,
      bankName: bankName,
      bankContactName: bankContactName,
      accountNumber: bankAccountNumber,
      routingNumber: bankRouting,
      name: bankAccountName
    }
    createBankAccount(bankJSON);
    setShowNewBankModal(false);

  } 

  const handleClickDelete = (ccId) => {
    setCardToDelete(ccId);
    setShowDeleteModal(true);
  }
  const handleClickDeleteInModal = () => {
    deleteCreditCard(cardToDelete);
    setShowDeleteModal(false);
  }
  const handleClickDeleteBank = (baId) => {
    setBankToDelete(baId);
    setShowDeleteBankModal(true);
  }
  const handleClickDeleteBankInModal = () => {
    deleteBankAccount(bankToDelete);
    setShowDeleteBankModal(false);
  }

  const renderDefaultLabel = (cc) => {
    if (cc.isDefault) {
      return (
        <SideHeader>
          <div>Default</div>
        </SideHeader>
      )
    }
  }

  const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const years = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"];
  const cardTypes = ["Visa", "Mastercard", "Discover", "AMEX"];

  const renderNewCCModal = () => {
    return (
      <Fragment>
        <button style={{padding: 0, fontSize:30}} className="sav-btn__orange--text" onClick={() => setShowNewCCModal(true)}>+</button>
        <Modal show={showNewCCModal} onHide={() =>setShowNewCCModal(false)} animation={false} centered size="lg">
          
          <Modal.Header closeButton>
            <Modal.Title>
              Add New Credit Card
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="" style={{margin: "0 auto", width:"65%"}}>
            <Form onSubmit={handleCloseNewCCModal} noValidate validated={validated} className="sav-form">
                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={creditCardNumber} setValue={setCreditCardNumber} label="Credit Card Number"/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <SavFormDrop value={expMonth} setValue={setExpMonth} label="Expiration Month" opts={months} />
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <SavFormDrop value={expYear} setValue={setExpYear} label="Expiration year" opts={years} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <SavFormDrop value={ccType} setValue={setCcType} label="Card Type" opts={cardTypes} />
                  </Form.Group>
                  <Form.Group as={Col} sm={4}>
                   <SavFormInput value={ccCode} setValue={setCcCode} label="Security Code"/>
                  </Form.Group>
                  <Form.Group as={Col} sm={2} className="text-center">
                    <Form.Label>Default</Form.Label>
                    <Form.Control as={SavCheckForm} checked={isDefault} click={() => setIsDefault(!isDefault)} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <SavFormInput value={addressContactFirst} setValue={setAddressContactFirst} label="First Name"/>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <SavFormInput value={addressContactLast} setValue={setAddressContactLast} label="Last Name"/>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <SavFormInput value={addressStreet} setValue={setAddressStreet} label="Street Address"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={addressCity} setValue={setAddressCity} label="City"/>
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={addressState} setValue={setAddressState} label="State/Province/Region"/>
                </Form.Group>
                <Form.Group as={Col} sm={4}>
                  <SavFormInput value={addressZip} setValue={setAddressZip} label="Zip/Postal Code"/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Control as={SavDropFilterForm}  val={addressCountry} opts={Countries} click={setAddressCountry} />
                </Form.Group>
              </Form.Row>
              <div className="m-t-20 text-center">
              <button className="sav-btn__orange--fill" type="submit">
                Save Changes
              </button>
            </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    )
  };
  const renderNewBankModal = () => {
    return (
      <Fragment>
        <button style={{padding: 0, fontSize:30}} className="sav-btn__orange--text" onClick={() => setShowNewBankModal(true)}>+</button>
        <Modal show={showNewBankModal} onHide={() =>setShowNewBankModal(false)} animation={false} centered size="lg">
          
          <Modal.Header closeButton>
            <Modal.Title>
              Add New Bank Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="" style={{margin: "0 auto", width:"65%"}}>
            <Form onSubmit={handleCloseNewBankModal} noValidate validated={validatedBank} className="sav-form">

                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={bankAccountName} setValue={setBankAccountName} label="Bank Account Nickname"/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} xs={12}>
                    <SavFormInput value={bankContactName} setValue={setBankContactName} label="Contact Name on Bank Account"/>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankName} setValue={setBankName} label="Name of Bank"/>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <SavFormDrop value={bankAccountType} setValue={setBankAccountType} label="Type" opts={["Checking", "Savings"]} />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankAccountNumber} setValue={setBankAccountNumber} label="Bank Account #"/>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <SavFormInput value={bankRouting} setValue={setBankRouting} label="Bank Routing #"/>
                  </Form.Group>
                </Form.Row>
                <div className="m-t-20 text-center">
                  <button className="sav-btn__orange--fill" type="submit">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    )
  }

  const renderCards = () => {
    if (!_.isEmpty(creditCards)){
      return creditCards.map((cc) => {
        return (
          <SettingRow key={cc.sfid}>
            <Col xs={1} md={1}>
            <SideHeader style={!cc.isDefault ? {display:'none'} : null}>
              <div>Default</div>
            </SideHeader>
              {renderDefaultLabel(cc)}
              {getIcon(cc.type)}
            </Col>
            <Col xs={7} md={5}>
              {cc.type} ending in {cc.lastfour}
            </Col>
            <Col xs={3} md={3} className="d-none d-md-block">
              Exp: {cc.expYear} - {cc.expMonth}
            </Col>
            <Col xs={4} md={3}>
              <button style={{width: "unset"}} className="sav-btn__orange--text" onClick={() => handleClickDelete(cc.sfid)}>Delete</button>
            </Col>
          </SettingRow>
        )
      });
    }
    return null;
    
  }
  const renderBanks = () => {
    if (!_.isEmpty(bankAccounts)){
      return bankAccounts.map((ba) => {
        return (
          <SettingRow key={ba.sfid}>
            <Col xs={5}>
            <span className="med f12">Nickname: </span>{ba.name}
              <br />
              <span className="med f12">Bank: </span>{ba.bankName}
            </Col>
            <Col xs={4}>
              {ba.type} ({ba.accountLast4})
            </Col>
            <Col xs={3}>
              <button style={{width: "unset"}} className="sav-btn__orange--text" onClick={() => handleClickDeleteBank(ba.sfid)}>Delete</button>
            </Col>
          </SettingRow>
        )
      });
    }
    return null;
    
  };
  const renderLoader = (loading) => {
    if(loadingCreateBank === true || loadingDeleteBank === true){
      return (
        <div className="loaderDiv">
          <div className="loader" />
        </div>
      )
    }
    return null;
  };
  return (
    <DetailContainer>
      {renderLoader()}
      <SettingRow>
        <Col xs={12}>
          <Header>SAVED CREDIT CARDS</Header>
        </Col>
      </SettingRow>
      {renderCards()}
      <Row style={{padding:0, justifyContent:'center', backgroundColor:'#fafaf9', fontSize:12, borderBottom:'1px solid rgba(0,0,0,.1)', alignItems:'center'}}>
        {renderNewCCModal()}
      </Row>
      <div className="m-t-20 text-center book f12 lightGray">
        *Cards cannot be edited, only added, or deleted, due to in process orders. In process orders will not be updated and will continue to use the originally selected card.
      </div>
      <SettingRow className="m-t-20">
        <Col xs={12}>
          <Header>SAVED BANK ACCOUNTS</Header>
        </Col>
      </SettingRow>
      {renderBanks()}
      <Row style={{padding:0, justifyContent:'center', backgroundColor:'#fafaf9', fontSize:12, borderBottom:'1px solid rgba(0,0,0,.1)', alignItems:'center'}}>
        {renderNewBankModal()}
      </Row>
      <div className="m-t-20 text-center book f12 lightGray">
        *Only U.S based personal checking, savings, and business checking account may be used for processing payments via ACH. 
      </div>
      <SavModalWarning 
        show={showDeleteModal}
        hide={() => setShowDeleteModal(false)}
        title="Delete Credit Card"
        text="Are you sure you want to delete this card?"
        text2="*Note that any orders using this card that have not been fully processed will finish processing using this card."
        buttonText="delete"
        center
        click={handleClickDeleteInModal}
      />
      <SavModalWarning 
        show={showDeleteBankModal}
        hide={() => setShowDeleteBankModal(false)}
        title="Delete Bank Account"
        text="Are you sure you want to delete this Bank Account?"
        text2="*Note that any payments using this Account for ACH payments that have not been fully processed will finish processing using this account."
        buttonText="delete"
        center
        click={handleClickDeleteBankInModal}
      />
    </DetailContainer>
  );
};
const loadingBank = createLoadingSelector(["CREATE_BANK"]);
const deletingBank = createLoadingSelector(["DELETE_BANK"]);
const mapStateToProps = state => {
  return {
    loadingCreateBank: loadingBank(state),
    loadingDeleteBank: deletingBank(state),
  };
};

export default connect(mapStateToProps, {deleteCreditCard, createCreditCard, deleteBankAccount, createBankAccount, setSuccessToast})(AccountSavedCredit);

import React from "react";
import styled from "styled-components";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label
} from "recharts";
import _ from "lodash";
import {parseCurrency, kFormatter} from "../Utilities";
import {AiOutlineWarning} from 'react-icons/ai';

const AlertDiv = styled.div`
  background-color:#ffcc5f;
  color:#fff;
  width:100%
  padding:10px;
  font-family:"Gotham-Medium";
  font-size:14px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const VIRProgress = props => {
  const { account } = props;

  const today = new Date();
  const quarter = Math.floor(today.getMonth() / 3) + 1;
  const year = today.getFullYear();
  let currentQuarter = _.find(account.salesProgramResults, (q) => q.programQuarter === quarter && q.year === year && q.type === "VIR" );
  let fakeQuarter = {
    year: year,
    virPercentEarned: 0,
    virNextTierPercent: 0,
    virNextTierGoal: 0,
    virEarned: 0,
    validTier: null,
    type: 'VIR',
    tierEarned: null,
    summaryStart: '2021-04-01',
    summaryEnd: '2021-06-30',
    revenuePriorYear: 0,
    revenue: 0,
    programQuarter: quarter,
    program: 'VIR 2021',
    message: '$25,000 to go till next tier!',
    leadsWithSavant: null,
    isBeta: null,
    isActive: false,
    inGoodStanding: false,
    earnedAmbassador: null,
    coreTrainings: null,
    adoptionUiHstLicCtrl: 0,
    adoptionQuarters: null,
    adoptionELSClimate: 0,
    adoptionAudioVideoSpeaker: 0
  }
  if (!currentQuarter){
    currentQuarter = fakeQuarter;
  }

  const virSales = `salesQ${quarter}`;
  const data = [
    {
      Sales: currentQuarter.revenue,
      name: `Q${quarter}`
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{backgroundColor:'#fff', padding:20, boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)'}}>
          <span className="book f12">
            Q{quarter} Sales:&nbsp;
          </span>
          <span className="med f12">
            {parseCurrency(payload[0].payload.Sales, 2)}
          </span>
        </div>
      );
    }
    return null;
  };
  
  const CustomAxisTick = ({x, y, payload}) => {
    return (
      <text type="number" orientation="bottom" width="450" height="30" x={x} y={y+4} size="12px" stroke="none" fill="#666" className="recharts-text recharts-cartesian-axis-tick-value book f12" textAnchor="middle" style={{fontSize:12}}>
        <tspan x={x} dy="0.71em" >${kFormatter(payload.value)}</tspan>
      </text>
    )
  }

  const renderValue = (num) => {
    if (currentQuarter.tierEarned === 'Authorized'){
      if (num === 50){
        return '1%'
      }
      if (num === 100){
        return '3%'
      }
      if (num === 200){
        return '5%'
      }
      if (num === 250){
        return '7%'
      }
    }
    if (currentQuarter.tierEarned === 'Ambassador'){
      if (num === 50){
        return '3%'
      }
      if (num === 100){
        return '5%'
      }
      if (num === 200){
        return '7%'
      }
      if (num === 250){
        return '10%'
      }
    }
    if (currentQuarter.tierEarned === 'Elite Ambassador'){
      if (num === 50){
        return '5%'
      }
      if (num === 100){
        return '7%'
      }
      if (num === 200){
        return '10%'
      }
      if (num === 250){
        return '12%'
      }
    }
    return null
  }

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis type="number" domain={[0, 200000]} tickCount={8} tick={CustomAxisTick} /> */}
          <XAxis type="number" domain={[0, 250000]} tick={false} />
          <YAxis type="category" dataKey="name" />
          <Tooltip content={<CustomTooltip />} />

          <Bar
            dataKey="Sales"
            fill="#565551"
            isAnimationActive={false}
          />
          <ReferenceLine x={50000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={renderValue(50)}
              offset={5}
              fontSize={14}
              fill={
                currentQuarter.revenue >= 50000 && currentQuarter.revenue < 100000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
            <Label
              position="bottom"
              value="$50k"
              offset={5}
              fontSize={14}
              fill="#979797"
            />
          </ReferenceLine>
          <ReferenceLine x={100000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={renderValue(100)}
              offset={5}
              fontSize={14}
              fill={
                currentQuarter.revenue >= 100000 && currentQuarter.revenue < 200000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
            <Label
              position="bottom"
              value="$100k"
              offset={5}
              fontSize={14}
              fill="#979797"
            />
          </ReferenceLine>
          <ReferenceLine x={200000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={renderValue(200)}
              offset={5}
              fontSize={14}
              fill={
                currentQuarter.revenue >= 200000 && currentQuarter.revenue < 250000
                  ? "#ff5f00"
                  : "#979797"
              }
            />
            <Label
              position="bottom"
              value="$200k"
              offset={5}
              fontSize={14}
              fill="#979797"
            />
          </ReferenceLine>
          <ReferenceLine x={250000} stroke="#979797" isFront={true}>
            <Label
              position="top"
              value={renderValue(250)}
              offset={5}
              fontSize={14}
              fill={currentQuarter.revenue >= 250000 ? "#ff5f00" : "#979797"}
            />
            <Label
              position="bottom"
              value="$250k"
              offset={5}
              fontSize={14}
              fill="#979797"
            />
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
      {/* <AlertDiv>
        <div style={{width: '15%'}}>
          <AiOutlineWarning fill="#fff" className="m-r-10" size={20} />
        </div>
        <div className="med f14 wht">
        Due to the unexpected shipment issues in February/March, Savant is calculating Q1 2022 Integrator VIR Tier off of sales order total instead of the traditional shipped order total. VIR credits will be paid against shipped orders. Contact your Inside Sales Associate for details.
        </div>
      </AlertDiv> */}
    </React.Fragment>
  );
};

export default VIRProgress;
